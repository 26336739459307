
  import type { SingleSelectFieldValue } from '@app/models/question-response-types/single-select-field-value';
  import type { AxiosPromise } from 'axios';
  import { filter, map } from 'rxjs/operators';
  import { Component, Emit, Ref } from 'vue-property-decorator';
  import type { BaseEntity } from '@app/models/base-entity';
  import { Typeahead } from 'uiv';
  import type { SelectOption } from '@app/models/question-options/shared';
  import type { FieldType } from '@app/models/sub-form-question';
  import type { DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';
  import { safeEvaluate } from '@app/utils/safe-evaluate';
  import { select2ResponseTemplate } from '@app/utils/select2-response-template';

  import Select2 from '../select2.vue';
  import EntitySelector from '../entity-selector.vue';

  import BaseField from './base-field';

  type SomeData = any; // eslint-disable-line @typescript-eslint/no-explicit-any

  type ExtendedSelectOption = SelectOption & { _source?: unknown };

  @Component({ components: { EntitySelector, Select2, Typeahead } })
  export default class SingleSelectField extends BaseField<FieldType.single_select> {
    @Ref() readonly selfReferencingEntitySelector?: EntitySelector<SomeData>;

    localValue: SingleSelectFieldValue = { value: '' };

    options: ExtendedSelectOption[] = [];

    get isApiRequest(): boolean {
      return this.question.config.mode === 'api_request';
    }

    get selfReferencing(): boolean {
      return this.isApiRequest && this.question.config.api_request?.self_referencing === 'true';
    }

    get allowClearResponse(): boolean {
      return this.defaultTemplating || this.question.config.allow_clear_response === 'true';
    }

    get dependentQuestionCodes(): string {
      return (this.question.config.api_request?.dependent_question_codes || []).join('|');
    }

    get apiRequestShouldOverride(): boolean {
      const mode = this.question.config.api_request?.data_replace_mode || 'suggest_on_edit';
      if (mode === 'suggest_on_edit') {
        return !this.completion?.id;
      }
      return mode === 'override';
    }

    @Emit('input')
    updateValue(value?: string): SingleSelectFieldValue {
      if (value !== this.localValue.value) {
        let newValue: SingleSelectFieldValue = { value };
        if (this.isApiRequest) {
          if (value) {
            const finalOptions = (this.selfReferencing ? this.selfReferencingEntitySelector?.results || [] : this.options)
              .map((o) => o._source)
              .filter(Boolean);

            const selectedItem = finalOptions.find((o) => `${o[this.question.config.api_request?.value_key || '']}` === `${value}`);
            if (selectedItem) {
              newValue = { ...newValue, label: selectedItem[this.question.config.api_request?.label_key || ''] || value };
            }
            this.$nextTick(() => this.formObservers.apiLookupResult$.next({ [this.question.code]: selectedItem }));
          } else {
            this.$nextTick(() => this.formObservers.apiLookupResult$.next({ [this.question.code]: null }));
          }
        }

        this.localValue = newValue;
        this.sendUpdate(this.localValue);
      }
      return this.localValue;
    }

    // TODO fix typings
    performApiRequest(params: DonesafeIndexApiOptions<BaseEntity>): AxiosPromise {
      const formDataByCode = this.dataByCode;
      return this.$api
        .executeApiRequest(
          {
            sub_form_question_ids: [this.question.id],
            data: {
              ...formDataByCode,
              page: params.page,
              per_page: params.per_page,
              [this.question.code]: params.filters?.search,
            },
          },
          { cache: true }
        )
        .then(({ data, headers }) => {
          const resultArray = this.transformIntoOptions(data[this.question.id]);
          const paginationHeaders = {
            total: headers['total'] || resultArray.length,
            'per-page': headers['per-page'] || 25, // TODO
          };
          return {
            data: resultArray,
            headers: paginationHeaders,
          } as any; // eslint-disable-line @typescript-eslint/no-explicit-any
        });
    }

    beforeMount(): void {
      const value = this.value?.value || '';
      this.localValue = { ...this.value, value };

      this.options = this.isApiRequest ? [] : Object.values(this.question.options.values || {});
      if (value) {
        const currentOption = this.options.find((o) => o.key == value);
        if (!currentOption && this.question.options.historical_values) {
          const historicalOptions = Object.values(this.question.options.historical_values);
          const historicalOption = historicalOptions.find((o) => o.key == value);
          if (historicalOption) {
            if (historicalOption.value !== this.value.label) {
              historicalOption.value = String(this.$t('app.labels.archived_name', { name: historicalOption.value }));
            }
            this.options.push(historicalOption);
          }
        }
      }
    }

    templateResult(result: { [key: string]: string } = {}): JQuery {
      return select2ResponseTemplate(result, {
        secondaryAttribute: this.question.config.api_request?.secondary_key,
      });
    }

    mounted(): void {
      if (this.isApiRequest) {
        if (this.selfReferencing) {
          this.selfReferencingEntitySelector?.setOptions(this.options);
        } else {
          this.addSubscription(
            this.formObservers.apiRequestResult$
              .pipe(
                map((lookupResult) => lookupResult[this.question.id]),
                filter((result) => !!result)
              )
              .subscribe((result) => {
                this.options = this.transformIntoOptions(result);
                if (this.options.length === 1 && (!this.localValue.value || this.apiRequestShouldOverride)) {
                  this.updateValue(this.options[0].key);
                }
              })
          );
        }
      }
    }

    transformIntoOptions(input: SomeData): ExtendedSelectOption[] {
      const data = safeEvaluate(input, this.question.config.api_request?.result_path) || [];
      if (!Array.isArray(data)) {
        return [];
      }
      const valueKey = this.question.config.api_request?.value_key;
      const labelKey = this.question.config.api_request?.label_key || valueKey;

      let transformedData = data.map((r) => {
        const key = safeEvaluate(r, valueKey);
        const value = safeEvaluate(r, labelKey);
        return { key, value, _source: r };
      });

      if (this.localValue.value && this.localValue.label && !transformedData.some((d) => d.key === this.localValue.value)) {
        transformedData = [{ key: this.localValue.value, value: this.localValue.label, _source: undefined }, ...transformedData];
      }

      return transformedData;
    }
  }
