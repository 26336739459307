import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import { DocumentService } from './document-service';
import type { Attachment } from '@app/models/attachment';

type AttachableType = 'Document' | 'SubFormResponse';

export class AttachmentService extends BaseService<Attachment> {
  async fetchDetails({ version }: ServiceRequest<Attachment>): Promise<DetailsServiceResponse> {
    const attachableId = this.extractValue<number>('attachable_id', version);
    if (!attachableId) return {};

    const attachableType = this.extractValue<AttachableType>('attachable_type', version);
    let mainText: Maybe<string>;
    try {
      switch (attachableType) {
        case 'Document':
          const document = await DocumentService.fetchEntity(this.api, attachableId);
          mainText = `${this.t('app.labels.document')}: ${document.name} [${document.id}]`;
          break;
        case 'SubFormResponse':
          mainText = `${this.t('app.labels.attached_to')}: ${attachableType} [${attachableId}]`;
          break;
      }
    } catch {
      mainText = `${this.t('app.labels.attached_to')}: ${attachableType} [${attachableId}] (Deleted)`;
    }

    return { mainText };
  }

  async fetchEntity({ itemId }: ServiceRequest<Attachment>): Promise<Partial<Attachment>> {
    const { data: attachment } = await this.api.getAttachment(itemId, { only: ['id', 'file_name', 'uuid'] }, { cache: true, join: true });
    return attachment;
  }

  entityToText({}: ServiceRequest<Attachment>, entity?: Attachment): string {
    return entity ? `${decodeURI(entity.file_name)} [${entity.id}]` : `${this.t('app.labels.file_deleted')}`;
  }
}
