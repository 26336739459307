import type { BaseEntity } from '@app/models/base-entity';
import { debounce } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import type { ListManagerStatic } from '@app/services/list-manager/list-manager-static';
import type { ListManager } from '@app/services/list-manager/list-manager';

@Component
export default class WithSortingHandle<T extends BaseEntity<number | string>> extends Vue {
  @Prop(Boolean) sortable?: boolean;
  @Prop(String) sortableHandle?: string;

  manager!: ListManager<T> | ListManagerStatic<T>;
  finishDraggingDebounce = debounce(this.finishDragging, this.managerDebounceTime);

  get managerDebounceTime(): number {
    return 'debounceTime' in this.manager ? this.manager.debounceTime : 0;
  }

  updateSorting(): void {
    const cssMapping = {
      bodyClass: '.simple-grid-tbody',
      rowClass: '.simple-grid-tr',
    };

    if (this.sortable) {
      this.$nextTick(() => {
        const $body = $(this.$el).find(cssMapping.bodyClass);

        if (!$body.sortable) return; // specs

        $body.sortable({
          items: `> ${cssMapping.rowClass}`,
          handle: this.sortableHandle,
          start: (e: Event, ui: { item: JQuery }) => {
            ui.item.attr('data-prev-index', ui.item.index());
          },
          stop: (event: Event, ui: { item: JQuery }) => {
            const newIndex = ui.item.index();
            const oldIndex = parseInt(ui.item.attr('data-prev-index') as string);
            ui.item.removeAttr('data-prev-index');
            const originalIndex = ui.item[0].getAttribute('item-index') as unknown as number;
            const item = this.manager.items[originalIndex];
            this.$emit('drag-update', { event, ui, newIndex, oldIndex, item });
            this.manager.items.splice(newIndex, 0, this.manager.items.splice(oldIndex, 1)[0]);
            $body.sortable('cancel');
            this.manager.setData(this.manager.items);
            this.finishDraggingDebounce();
          },
        });
      });
    }
  }

  finishDragging(): void {
    this.$emit('drag-finish');
  }

  mounted(): void {
    this.updateSorting();
  }
}
