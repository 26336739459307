import { render, staticRenderFns } from "./toocs-quick-sub-picker.vue?vue&type=template&id=5e8bec07&scoped=true&lang=pug"
import script from "./toocs-quick-sub-picker.vue?vue&type=script&lang=ts"
export * from "./toocs-quick-sub-picker.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e8bec07",
  null
  
)

export default component.exports