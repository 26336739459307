import type { SubFormQuestion, QuestionTypeMap, ResponseValue } from '@app/models/sub-form-question';
import type { CalculationSelectFieldValue, CalculationTextFieldValue } from '@app/models/question-response-types';
import { FieldType } from '@app/models/sub-form-question';
import type { SubFormData } from '@app/services/api/sub-form-completions-api';
import {
  convertToDisplayValue,
  generateCalculationTextConfig,
  getCalculationSelectCalculationValue,
  getCalculationTextCalculationValue,
  getCalculationTextDefaultValue,
  processValue,
} from '@app/services/calculation';
import type { BehaviorSubject } from 'rxjs';
import type { QuestionSfcOnly } from '../utils';
import { isNil } from 'lodash';

export class CalculationHandler {
  calculationValues$: BehaviorSubject<Record<string, string | number>>;
  questions: Pick<SubFormQuestion, QuestionSfcOnly>[];
  questionsById: Map<number, Pick<SubFormQuestion, QuestionSfcOnly>>;

  calcValueUpdateCounter = 0;

  constructor(
    questions: Pick<SubFormQuestion, QuestionSfcOnly>[],
    questionsById: Map<number, Pick<SubFormQuestion, QuestionSfcOnly>>,
    calculationValues$: BehaviorSubject<Record<string, string | number>>
  ) {
    this.questions = questions;
    this.questionsById = questionsById;
    this.calculationValues$ = calculationValues$;
  }

  setCalculationValue = (questionId: number, value?: ResponseValue) => {
    const q = this.questionsById.get(questionId);
    if (!q) return;

    const newCalcValues = { ...this.calculationValues$.value };

    switch (q.field_type) {
      case FieldType.calculation_text:
        const calcValue = value as CalculationTextFieldValue;
        const calculationTextConfig = this.calculationTextConfig[q.id];

        const dbValue = calcValue?.value == null ? '' : calcValue?.value;
        const defaultValue = getCalculationTextDefaultValue(dbValue, calculationTextConfig);

        const resultValue = processValue(
          this.isInitialUpdate ? defaultValue || '' : convertToDisplayValue(dbValue as string, calculationTextConfig.formatValidation),
          calculationTextConfig
        );

        newCalcValues[questionId] = getCalculationTextCalculationValue(
          q as SubFormQuestion<QuestionTypeMap<FieldType.calculation_text>['options']>,
          { value: resultValue } as CalculationTextFieldValue
        );
        break;
      case FieldType.calculation_select:
        newCalcValues[questionId] = getCalculationSelectCalculationValue(
          q as SubFormQuestion<QuestionTypeMap<FieldType.calculation_select>['options']>,
          value as CalculationSelectFieldValue
        );
        break;
    }

    this.calculationValues$.next(newCalcValues);
  };

  updateCalcValues = (form: SubFormData) => {
    this.calculationSelectAndTextIds.forEach((id) => {
      this.setCalculationValue(id, form[id] || undefined);
    });
    this.calcValueUpdateCounter++;
  };

  getFilteredQuestionIds(fieldType: FieldType): Set<number> {
    return new Set(this.questions.filter((q) => q.field_type === fieldType).map((q) => q.id));
  }

  get calculationSelectAndTextIds(): Set<number> {
    const resultSet = new Set(this.calculationTextIds);
    this.calculationSelectIds.forEach((id) => resultSet.add(id));
    return resultSet;
  }

  get calculationSelectIds(): Set<number> {
    return this.getFilteredQuestionIds(FieldType.calculation_select);
  }

  get calculationTextIds(): Set<number> {
    return this.getFilteredQuestionIds(FieldType.calculation_text);
  }

  get blankValues() {
    return this.questions
      .filter((question) => question.config.blank_value || !isNil(question.config.blank_value_amount))
      .reduce((memo, question) => {
        memo[question.id] = question.config.blank_value_amount || '';
        return memo;
      }, {} as Record<string, string>);
  }

  get calculationTextConfig() {
    return this.questions
      .filter(
        (q): q is Pick<SubFormQuestion<QuestionTypeMap<FieldType.calculation_text>['options']>, QuestionSfcOnly> =>
          q.field_type === FieldType.calculation_text
      )
      .reduce((memo, q) => {
        memo[q.id] = generateCalculationTextConfig(q);
        return memo;
      }, {} as Record<string, ReturnType<typeof generateCalculationTextConfig>>);
  }

  get isInitialUpdate() {
    //TODO: this needs to be a bit more precise since we can remount questions
    return this.calcValueUpdateCounter === 0;
  }
}
