import type { ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { EmploymentType } from '@app/models/employment-type';

export class EmploymentTypeService extends BaseService<EmploymentType> {
  async fetchEntity({ itemId }: ServiceRequest<EmploymentType>): Promise<EmploymentType> {
    const { data } = await this.api.getEmploymentType(itemId, { only: ['id', 'name'] }, { join: true, cache: true });
    return data;
  }

  entityToText({}: ServiceRequest<EmploymentType>, entity?: EmploymentType): string {
    return entity ? `${entity.name} [${entity.id}]` : '';
  }
}
