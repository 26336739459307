import type { Confidentiality } from '@app/models/confidentiality';
import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';

export class ConfidentialityService extends BaseService<Confidentiality> {
  async fetchDetails({ version }: ServiceRequest<Confidentiality>): Promise<DetailsServiceResponse> {
    const confidentialityTypeId = this.extractValue('confidentiality_type_id', version);
    if (!confidentialityTypeId) throw Error('Required confidentiality_type_id was missed');

    const { data: confidentialityType } = await this.api.getConfidentialityType(
      confidentialityTypeId,
      { only: ['id', 'name'] },
      { join: true, cache: true }
    );
    return {
      links: [],
      mainText: `${this.t('app.labels.confidentiality_type')}: ${confidentialityType.name} [${confidentialityType.id}]`,
    };
  }
}
