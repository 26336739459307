
  import BaseField from '@app/components/form-fields/base-field';
  import { hasQuestionInFilter } from '@app/utils/has-question-in-filter';
  import { differenceWith, isEmpty, isEqual } from 'lodash';
  import { Component, Ref } from 'vue-property-decorator';
  import type RecordSelector from '@app/components/record-selector.vue';
  import type EntitySelector from '@app/components/entity-selector.vue';
  import type { DonesafeModuleRecordExtraFilters } from '@app/services/api/module-records-api';
  import type AutoSelectToggle from '@app/components/record-auto-select/auto-select-toggle.vue';
  import { debounceTime, filter } from 'rxjs';
  import type { FieldType } from '@app/models/sub-form-question';
  import type { ModuleRecord } from '@app/models/module-record';
  import type { SubFormCompletion } from '@app/models/sub-form-completion';
  import { ConfiguratorFilterSource } from '@app/models/configurator-filter';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import type { VisibilityStateUpdate } from '@app/utils/types/visibility-state';
  import { convertInFormFilters } from '@app/utils/convert-in-form-filters';
  import { excludeHiddenFilters } from '@app/utils/exclude-hidden-filters';
  import {
    hasIncompleteNotMandatoryFilters,
    incompleteMandatoryFilters,
    incompleteNotMandatoryFilters,
  } from '@app/utils/mandatory-filters';
  import { hasVisibilityStateUpdateInFilter } from '@app/utils/has-visibility-state-update-in-filter';
  import { mergeVisibilityStates } from '@app/utils/merge-visibility-states';
  import { noResultsTemplate } from '@app/utils/no-results-template';
  import { RE_FETCH_DEBOUNCE_TIME, CLEAR_FILTERS_ID } from '@app/constants';

  type AutoRecordSelectFieldTypes = FieldType.main_form_relation | FieldType.multi_main_form_relation | FieldType.sub_form_relation;
  @Component
  export default class WithRecordAutoSelect<T extends AutoRecordSelectFieldTypes> extends BaseField<T> {
    @Ref() readonly recordSelector?: T extends FieldType.sub_form_relation ? EntitySelector<SubFormCompletion> : RecordSelector;
    @Ref() readonly autoSelectToggle!: AutoSelectToggle;

    autoRecordSelectEnabled = false;
    autoSelectInProgress = false;
    warnings: Record<string, string> = {};

    visibilityStates: VisibilityStateUpdate = [];
    clearFiltersPressed = false;

    get clearFiltersEnabled(): boolean {
      return this.question.config.out_of_scope_selection === 'true';
    }

    get isReadonly(): boolean {
      return this.readonly || this.isPublic;
    }

    get hasAutoSelect(): boolean {
      return !this.defaultTemplating && this.question.config.auto_select_enabled === 'true';
    }

    get autoRecordSelectLocked(): boolean {
      return this.isReadonly || this.autoSelectInProgress;
    }

    get hasWarnings(): boolean {
      return this.autoRecordSelectEnabled && !isEmpty(this.warnings);
    }

    clearWarnings(): void {
      this.warnings = {};
    }

    onAutoSelectInit(): void {
      this.autoRecordSelectEnabled = !(
        (this.response?.id && !this.params?.auto_save) ||
        (this.question.config.mode === 'lookup' && this.question.config.lookup?.readonly === 'false')
      );
      this.reFetchOrClear();
    }

    reFetchOrClear(): void {
      this.$nextTick(() => {
        if (this.autoRecordSelectEnabled) {
          this.reFetchData();
        } else {
          this.clearIncompleteFilters();
        }
      });
    }

    onSelect(event: { params: { args: { data: { id: string } } } }): boolean {
      if (event.params.args.data.id === CLEAR_FILTERS_ID) {
        this.clearFilters();
        return false;
      }

      return true;
    }

    clearFilters(): void {
      this.clearFiltersPressed = true;
      this.recordSelector?.reFetch();
    }

    clearFiltersItem(): { id: string; text: string } | undefined {
      if (!this.clearFiltersEnabled || this.clearFiltersPressed) {
        return;
      }
      return {
        id: CLEAR_FILTERS_ID,
        text: `${this.$t('app.labels.search_other_records')}...`,
      };
    }

    noRecords(): JQuery {
      return noResultsTemplate(
        this.$t('app.labels.search_other_records') as string,
        this.clearFiltersEnabled && !this.clearFiltersPressed,
        this.clearFilters
      );
    }

    // get, check and set filters

    setAndValidateFilters(filters: DonesafeFilterOptions<ModuleRecord>, validate: boolean) {
      this.setIncompleteFilters(incompleteMandatoryFilters(this.question.config.filters, filters));

      // Parsley re-validation
      validate && !!this.autoSelectToggle?.fetchingError && $(this.autoSelectToggle.fetchingError).parsley().validate();
    }

    checkIncompleteFilters(filters: DonesafeFilterOptions<ModuleRecord>, validate = true): boolean {
      let allowAutoSelect: boolean;
      this.setAndValidateFilters(filters, validate);

      const hasNoIncompleteFilters =
        !this.incompleteFilters.length && hasIncompleteNotMandatoryFilters(this.question.config.filters, filters);

      if (this.autoRecordSelectEnabled && hasNoIncompleteFilters) {
        const mandatoryFilters = incompleteNotMandatoryFilters(this.question.config.filters, filters);
        allowAutoSelect = !mandatoryFilters.some(
          (filter) => filter?.source === ConfiguratorFilterSource.current_record || filter?.source === ConfiguratorFilterSource.current_user
        );
      } else {
        allowAutoSelect = true;
      }

      return allowAutoSelect;
    }

    getFilters(): DonesafeFilterOptions<ModuleRecord, DonesafeModuleRecordExtraFilters> {
      let filters = this.question.config.filters;
      if (this.clearFiltersEnabled && this.clearFiltersPressed) {
        filters = filters?.filter((f) => f.required);
      }
      return convertInFormFilters(excludeHiddenFilters(filters || [], this.visibilityStates), {
        record: this.record,
        completion: this.completion,
        user: this.currentUserStore.data,
        getCurrentFormValueByQuestion: this.getCurrentFormValueByQuestion,
      });
    }

    // auto-select subscriptions

    initAutoSelectSubscriptions(): void {
      this.addSubscription(
        this.formObservers.fieldUpdate$
          .pipe(
            filter(([question]) => hasQuestionInFilter(question, this.question.config.filters)),
            debounceTime(RE_FETCH_DEBOUNCE_TIME)
          )
          .subscribe(() => {
            this.reFetchOrClear();
          })
      );
      this.addSubscription(
        this.formObservers.visibilityStateUpdate$
          .pipe(
            filter(
              (update) =>
                hasVisibilityStateUpdateInFilter(update, this.question.config.filters) &&
                !!differenceWith(this.visibilityStates, update, isEqual).length
            ),
            debounceTime(RE_FETCH_DEBOUNCE_TIME)
          )
          .subscribe((update) => {
            this.visibilityStates = [...mergeVisibilityStates(this.visibilityStates, update)];
            this.reFetchOrClear();
          })
      );
    }

    // TODO: rewrite to abstract method and class once it's supported
    reFetchData(): void {
      throw new Error('reFetchData must be implemented in the component');
    }
  }
