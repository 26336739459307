
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { DetailsLink } from './models';
  import PaperTrailsDetailLink from './paper-trails-detail-link.vue';

  @Component({ components: { PaperTrailsDetailLink } })
  export default class PaperTrailsDetailLinks extends Vue {
    @Prop(Array) readonly links!: DetailsLink[];
    @Prop(Array) readonly queries!: string[];

    showDivider(link: DetailsLink): boolean {
      return this.links.length > 1 && this.links.indexOf(link) !== this.links.length - 1;
    }
  }
