
  import { Component } from 'vue-property-decorator';
  import BaseFieldDisplay from './base-field-display';
  import type { FieldType } from '@app/models/sub-form-question';
  import type { Signature } from '@app/models/signature';

  @Component({ components: {} })
  export default class MarkableDisplay extends BaseFieldDisplay<FieldType.markable> {
    signature: Nullable<Signature> = null;

    get backgroundImageUrl(): string | undefined {
      return this.question?.attachments?.[this.question?.attachments.length - 1]?.url;
    }

    get signatureId(): Maybe<number> {
      const signatureIdNumber = Number(this.response?.value);
      return isNaN(signatureIdNumber) ? undefined : signatureIdNumber;
    }

    beforeMount(): void {
      if (this.signatureId && !this.isPublic) {
        this.$api.getSignature(this.signatureId, {}, { cache: true }).then(({ data }) => {
          this.signature = data;
        });
      }
    }
  }
