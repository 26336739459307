
  import { debounce } from 'lodash';
  import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component({ components: {} })
  export default class SubFormCompletionFormNavPanel extends Vue {
    @Prop(Boolean) readonly fixed?: boolean;
    @Prop(Boolean) readonly modal?: boolean;
    @Prop(Boolean) readonly mobile?: boolean;

    static = false;
    handleScrollDebounced = debounce(this.handleScroll, 25);

    get addStaticClass(): boolean {
      return (this.fixed || this.static) && !this.modal;
    }

    mounted(): void {
      document.addEventListener('scroll', this.handleScrollDebounced, true);
      this.handleScroll();
    }

    handleScroll(): void {
      const fixBarContainerOffset = $('.sub-form-completion-form-nav-panel').offset() || { top: 0 };
      const additionalHeight = this.mobile ? 0 : 20;
      const scrollParentHeight = (($(this.$el) as JQuery).scrollParent().height() || 0) + additionalHeight;
      this.static = scrollParentHeight <= fixBarContainerOffset.top;
    }

    beforeDestroy(): void {
      document.removeEventListener('scroll', this.handleScrollDebounced);
    }
  }
