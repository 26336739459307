
  import { Component, Model, Prop, Ref } from 'vue-property-decorator';
  import EntitySelector from './entity-selector.vue';
  import type { ModuleRecord } from '@app/models/module-record';
  import type { DonesafeFilterOptions, OnlyOptions } from '@app/services/donesafe-api-utils';
  import { select2ResponseTemplate } from '@app/utils/select2-response-template';
  import WithSelect2Accessibility from '@app/mixins/with-select2-accessibility';

  type RecordSelectorValue = number | string | number[] | string[];

  @Component({ components: { EntitySelector } })
  export default class RecordSelector extends WithSelect2Accessibility {
    @Model('input') value!: RecordSelectorValue;
    @Prop(String) placeholder?: string;
    @Prop(Boolean) multiple?: boolean;
    @Prop(Boolean) allowClear?: boolean;
    @Prop(Boolean) readonly?: boolean;
    @Prop([Object, Function]) filters?: DonesafeFilterOptions<ModuleRecord> | (() => DonesafeFilterOptions<ModuleRecord>);
    @Prop([Object, Function]) requiredFilters!: DonesafeFilterOptions<ModuleRecord> | (() => DonesafeFilterOptions<ModuleRecord>);
    @Prop(String) readonly sort?: string;
    @Prop(Boolean) readonly sortable?: boolean;
    @Prop(Boolean) readonly autoFocus?: boolean;
    @Prop(Function) readonly openMethod?: () => boolean;
    @Prop(Function) readonly noResultsMethod?: () => JQuery;
    @Prop(Function) readonly templateSelection?: (selection: object) => JQuery;
    @Prop(Function) readonly footerItem?: () => ModuleRecord;
    @Prop(Function) readonly selectMethod?: () => boolean;
    @Prop({ type: Array, default: () => ['id', 'title', 'secondary_information'] }) readonly only!: OnlyOptions<ModuleRecord>;
    @Prop(Boolean) readonly followSortingOnInit?: boolean;
    @Ref() readonly selector?: EntitySelector<ModuleRecord>;
    @Prop(String) readonly dropdownParent?: string;
    @Prop(String) readonly inputId?: string;

    toggle(value: boolean): void {
      this.selector?.toggle(value);
    }

    setOptions(options: ModuleRecord[] = []): void {
      this.selector?.setOptions(options);
    }

    addOption(id: string, text: string, selected = false): void {
      this.selector?.addOption(id, text, selected);
    }

    templateResult(result: { [key: string]: string } = {}): JQuery {
      return select2ResponseTemplate(result, { secondaryAttribute: 'secondary_information' });
    }

    templateSelectionLink(result: { [key: string]: string } = {}): JQuery<HTMLElement> {
      const hrefTarget = {
        href: `/module_records/${result.id}`,
        target: '_blank',
      };
      const $link = $('<a>', {
        ...hrefTarget,
        text: result.text,
        click: (e: JQuery.ClickEvent) => {
          e.preventDefault();
          window.open(hrefTarget.href, hrefTarget.target);
        },
        mousedown: (e: JQuery.ClickEvent) => {
          e.preventDefault();
          return false;
        },
        keydown: (e: JQuery.KeyDownEvent) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            window.open(hrefTarget.href, hrefTarget.target);
          }
        },
      });
      return $link;
    }

    reFetch(): void {
      this.selector?.reFetch();
    }

    selectAll() {
      this.selector?.selectAll();
    }

    clearAll() {
      this.selector?.clearAll();
    }
  }
