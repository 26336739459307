import type { UserInvolvement } from '@app/models/user-involvement';
import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';

export class UserInvolvementService extends BaseService<UserInvolvement> {
  async fetchDetails({ version }: ServiceRequest<UserInvolvement>): Promise<DetailsServiceResponse> {
    const involvementId = this.extractValue('involvement_id', version);
    if (!involvementId) throw Error('Required involvement_id was missed');

    const { data: involvement } = await this.api.getInvolvement(involvementId, { only: ['id', 'name'] }, { cache: true, join: true });
    return {
      mainText: `${this.t('app.labels.involvement_type')}: ${involvement.name} [${involvement.id}]`,
      links: [],
    };
  }
}
