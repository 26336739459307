import { Vue, Component } from 'vue-property-decorator';
import bootbox from 'bootbox';

@Component
export default class WithBootbox extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  confirm(message: string, options: any = { buttons: {} }): Promise<boolean> {
    const { buttons: overrideButtons, ...rest } = options;

    const buttons = {
      confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
      cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
      ...overrideButtons,
    };

    return new Promise((resolve) =>
      bootbox.confirm({
        message: message
          .split('\n')
          .map((line) => `<p>${line}</p>`)
          .join(''),
        size: 'small',
        buttons,
        callback: resolve,
        ...rest,
      })
    );
  }
}
