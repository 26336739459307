import type { UpdateRemotePaySystemData } from '@app/services/api/table-calculations-api';
import { camelCase, upperFirst } from 'lodash';
import { evaluate } from 'mathjs';
import type { CombinedRecord } from '@app/models/table-calculation';
import type { PostConfig, TableCalculationColumnEditable } from '@app/models/question-options/table-calculation-question-options';

export const BULK_EDIT_IGNORE_VALUE = '—';
export type VariableData = Record<string, string>;
export type BulkEditUpdateParams = Record<string, { type: TableCalculationColumnEditable['type']; value: string | number | null }>;
export const filterFunction = (
  filter: string,
  scope: {
    iterated?: CombinedRecord;
    record?: CombinedRecord;
  }
) => {
  const result = evaluate(filter, { ...scope });
  if (Array.isArray(result)) return !!result.length;
  else return !!result;
};

export const saferEvaluate = (
  formula: string,
  scope: Record<string, string | Record<string, string> | unknown>,
  fallback?: string | number
) => {
  const result = evaluate(formula, scope);
  switch (result) {
    case 'Invalid date':
    case Infinity:
    case null:
    case NaN:
    case undefined:
    case '-Infinity':
    case 'failed to calculate':
    case 'Infinity':
      return fallback !== undefined && fallback !== null ? fallback : 'failed to calculate';
    default:
      return result;
  }
};

export const getEditableColumnComponent = (type: TableCalculationColumnEditable['type']) => {
  return `${upperFirst(camelCase(type))}EditableColumn`;
};

export const substituteScope = (formula: string, scope: Record<string, string | Record<string, string>>): string => {
  // Replace all instances of variables in the formula string
  // with their corresponding values from the scope object
  const result = formula.replace(/\b[a-zA-Z_][a-zA-Z_0-9]*(?:\.[a-zA-Z_][a-zA-Z_0-9]*)*\b/g, (match) => {
    // Split the match into parts separated by "." characters
    const parts = match.split('.');

    // Try to access the property of the object using square bracket notation
    let value: string | Record<string, string | Record<string, string>> = scope;
    for (const part of parts) {
      if (typeof value !== 'object') break;
      value = value[part];
    }

    if (value !== undefined && typeof value === 'string') return value;
    return match;
  });

  return result;
};

export const constructTcfPostBody = (
  configBody: PostConfig['frontend_body'],
  opts: {
    additionalScope: Maybe<Record<string, unknown>>;
    calculated: Record<string, string>;
    computedRecords: CombinedRecord[];
  }
): UpdateRemotePaySystemData => {
  const { calculated, computedRecords, additionalScope } = opts;
  const body: UpdateRemotePaySystemData = {};

  if (!configBody) {
    return body;
  }

  for (const keyValuePair of configBody) {
    const { filter, value, type } = keyValuePair;
    const scope = { ...additionalScope, calculated };

    if (typeof value === 'string') {
      body[keyValuePair.key] = saferEvaluate(value, scope);
    } else if (Array.isArray(value)) {
      let evaluatedValue = [];

      if (type === 'object') {
        evaluatedValue = value.map((nestedValue) => {
          const nestedKey = nestedValue.key;
          const nestedVal = saferEvaluate(nestedValue.value, scope);
          return { [nestedKey]: nestedVal };
        });
      } else if (type === 'array') {
        const filteredRecords = filter ? computedRecords.filter((r) => filterFunction(filter, { iterated: r, ...scope })) : computedRecords;
        evaluatedValue = filteredRecords.map((record) =>
          value.reduce((acc, nestedValue) => {
            const nestedKey = nestedValue.key;
            const nestedVal = saferEvaluate(nestedValue.value, { ...record.data, ...scope });
            return { ...acc, [nestedKey]: nestedVal };
          }, {})
        );
      } else {
        evaluatedValue = [];
      }
      body[keyValuePair.key] = evaluatedValue;
    }
  }
  return { ...body };
};
