
  import { isInfected } from '@app/services/model-helpers';
  import bootbox from 'bootbox';
  import { Component, Prop } from 'vue-property-decorator';
  import WithRelatedObjects from '@app/mixins/with-related-objects';
  import type { Attachment } from '@app/models/attachment';
  import type { SubFormQuestion } from '@app/models/sub-form-question';

  @Component({ methods: { isInfected } })
  export default class ResponseRelatedObjects extends WithRelatedObjects {
    @Prop(Object) readonly question!: Pick<SubFormQuestion, 'id' | 'config'>;

    get canAddAttachment(): boolean {
      return this.question.config.add?.attachment === 'true';
    }

    deleteAttachment(attachment: Pick<Attachment, 'id'>) {
      bootbox.confirm(this.$t('app.labels.delete_this_attachment'), (result: boolean) => {
        result &&
          this.$api.deleteAttachment(attachment.id).then(() => {
            this.relatedAttachments = this.relatedAttachments.filter((a) => a.id !== attachment.id);
          });
      });
    }
  }
