
  import type { WorkflowStateFieldValue } from '@app/models/question-response-types';
  import { Component, Emit } from 'vue-property-decorator';
  import type { ModuleName } from '@app/models/module-name';
  import type { Workflow } from '@app/models/workflow';
  import type { FieldType } from '@app/models/sub-form-question';
  import type { SubFormCompletion } from '@app/models/sub-form-completion';
  import type { OnlyOptions } from '@app/services/donesafe-api-utils';

  import Select2 from '../select2.vue';

  import BaseField from './base-field';

  type Completion = Pick<SubFormCompletion, 'id' | 'workflow_id' | 'workflow' | 'record_id'>;

  @Component({ components: { Select2 } })
  export default class WorkflowStateField extends BaseField<FieldType.workflow_state> {
    approvalCompletion: Nullable<Completion> = null;
    workflows: Workflow[] = [];
    moduleName: Pick<ModuleName, 'id' | 'start_workflow'> | null = null;
    fetching = false;
    localValue: WorkflowStateFieldValue = {};
    recordWorkflow: Nullable<Workflow> = null;

    get workflowOptions(): [string, string][] {
      const options: [string, string][] = [];
      this.workflows.forEach((workflow) => {
        const key = !this.isMainForm ? `${workflow.id}` : workflow.code;
        options.push([key, workflow?.name || '']);
      });
      if (this.approvalCompletion?.workflow) {
        options.unshift([`${this.approvalCompletion.workflow.id}`, this.approvalCompletion.workflow.name]);
      } else {
        if (this.recordWorkflow && !options.find((option) => option[0] === this.recordWorkflow?.code)) {
          options.unshift([this.recordWorkflow.code, this.recordWorkflow.name || '']);
        }
      }
      return options;
    }

    get isMainForm(): boolean {
      return !this.completion?.sub_form_list_id;
    }

    @Emit('input')
    updateValue(value?: string): WorkflowStateFieldValue {
      this.localValue = { value: value };
      this.sendUpdate(this.localValue);
      return this.localValue;
    }

    fetchActiveLinks(workflowId: number, recordId?: number): void {
      this.$api
        .getWorkflowActiveLinks(
          workflowId,
          {
            filters: {
              record_id: recordId,
              record_type: 'ModuleRecord',
            },
            only: ['id', 'to'],
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.workflows = data.reduce((memo, link) => {
            return [...memo, ...(link.to ? [link.to] : [])];
          }, [] as Workflow[]);
        })
        .finally(() => (this.fetching = false));
    }

    getRecordWorkflow(moduleNameStartWorkflow?: Workflow, recordWorkflowId?: number): Promise<Maybe<Workflow>> {
      return new Promise((resolve) => {
        if (recordWorkflowId) {
          this.$api.getWorkflow(recordWorkflowId, { cache: true }).then(({ data }) => {
            resolve(data);
          });
        } else {
          resolve(moduleNameStartWorkflow);
        }
      });
    }

    beforeMount(): void {
      const only: OnlyOptions<ModuleName> = ['id', 'start_workflow'];
      const include = ['main_form', 'start_workflow'];

      this.localValue = { ...this.value };
      if (this.completion?.approval_for_sub_form_completion_id) {
        this.fetching = true;
        this.$api
          .getSubFormCompletion(
            this.completion.approval_for_sub_form_completion_id,
            {
              only: ['id', 'workflow_id', 'record_id', { workflow: ['id', 'name'] }],
            },
            { cache: true }
          )
          .then(({ data }) => {
            this.approvalCompletion = data;
            if (this.approvalCompletion.workflow) {
              this.updateValue(`${this.approvalCompletion.workflow.id}`);
              if (this.approvalCompletion.record_id) {
                this.fetchActiveLinks(this.approvalCompletion.workflow.id, this.approvalCompletion.record_id);
              }
            }
          });
      } else if (this.defaultTemplating) {
        if (!this.record?.module_name_id) {
          return;
        }
        this.fetching = true;
        this.$api.getModuleName(this.record.module_name_id, { only, include }, { cache: true }).then(({ data }) => {
          this.moduleName = data;
          if (!this.moduleName) {
            return;
          }
          this.$api
            .getWorkflows(
              {
                filters: {
                  module_name_id: this.moduleName.id,
                  active: true,
                },
                only: ['name', 'code'],
              },
              { cache: true }
            )
            .then(({ data }) => {
              this.workflows = data;
            })
            .finally(() => (this.fetching = false));
        });
      } else if (this.isMainForm) {
        if (!this.record?.module_name_id) {
          return;
        }
        this.fetching = true;
        this.$api.getModuleName(this.record.module_name_id, { only, include }, { cache: true }).then(({ data }) => {
          this.moduleName = data;
          if (!this.moduleName) {
            return;
          }

          const recordWorkflowId = this.record && 'workflow_id' in this.record ? this.record?.workflow_id : undefined;
          this.getRecordWorkflow(this.moduleName.start_workflow, recordWorkflowId).then((workflow?: Workflow) => {
            if (workflow) {
              this.recordWorkflow = workflow;
              this.fetchActiveLinks(workflow.id, this.record?.id);
              this.updateValue(workflow.code);
            }
          });
        });
      }
    }
  }
