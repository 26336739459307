
  import { Component, Prop } from 'vue-property-decorator';
  import BaseQuestionResponse from './base-question-response';
  import TextHighlight from 'vue-text-highlight';
  import type { Signature } from '@app/models/signature';

  @Component({ components: { TextHighlight } })
  export default class SignatureFieldResponse extends BaseQuestionResponse {
    @Prop(Object) readonly signature?: Signature;
    @Prop(String) readonly signatureText?: string;
    @Prop(String) readonly content?: string; // Base64 encoded string
  }
