import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { AuditReportTemplate } from '@app/models/audit-report-template';

export class AuditReportTemplateService extends BaseService<AuditReportTemplate> {
  static readonly allowedAttributes = [
    'id',
    'index',
    'module_name_id',
    'name',
    'purview',
    'report_format',
    'report_type',
    'row_limit',
    'sub_form_list_id',
    'user_collection_id',
    'visibility',
  ];

  async fetchDetails({ itemId }: ServiceRequest<AuditReportTemplate>): Promise<DetailsServiceResponse> {
    const { data: template } = await this.api.getAuditReportTemplate(
      itemId,
      { only: ['id', 'name', 'module_name_id'] },
      { join: true, cache: true }
    );
    return {
      links: [
        {
          link: `/admin/settings/module_names/${template.module_name_id}/audit_report_templates/${template.id}/edit`,
          prefix: this.t('app.labels.export_template'),
          title: template.name,
          id: template.id,
        },
      ],
    };
  }
}
