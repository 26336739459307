import type { ChangesResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { PaperTrailVersion } from '@app/models/paper-trail-version';
import type { RecordSignature } from '@app/models/record-signature';
import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';

export class RecordSignatureService extends BaseService<RecordSignature> {
  entityToText(_req: ServiceRequest<RecordSignature>, entity?: RecordSignature): string {
    const signatureUser = entity?.signature?.user;
    if (!signatureUser) {
      return '';
    }

    return `${this.t('app.labels.signed_by')}: ${signatureUser.full_name} [${signatureUser.id}]`;
  }

  async fetchChanges(key: keyof RecordSignature, version: PaperTrailVersion<RecordSignature>): Promise<ChangesResponse<RecordSignature>> {
    if (key !== 'id') {
      return {};
    }
    const changes = this.changes(version);
    const before = changes[key][0] as number;
    const after = changes[key][1] as number;
    let record: Maybe<RecordSignature>;
    let beforeRecord: Maybe<RecordSignature>;
    let text = '';
    let beforeText = '';

    const signableId = this.extractValue('signable_id', version);
    const signableType = this.extractValue('signable_type', version);
    const filters = { signable_id: signableId, signable_type: signableType };
    if (before) {
      beforeRecord = await this.fetchRecordSignature(before, filters);
      beforeText = this.entityToText({ itemId: before, version }, beforeRecord);
    }
    if (after) {
      record = await this.fetchRecordSignature(after, filters);
      text = this.entityToText({ itemId: after, version }, record);
    }
    return { entity: { entities: [beforeRecord || null, record || null], itemIds: [before, after], texts: [beforeText, text] } };
  }

  async fetchRecordSignature(id: RecordSignature['id'], filters: DonesafeFilterOptions<RecordSignature>): Promise<Maybe<RecordSignature>> {
    const { data: recordSignatures } = await this.api.getRecordSignaturesWithSharedFilter(
      { filters, include: ['signature', 'user'] },
      'id',
      id,
      { cache: true }
    );
    return recordSignatures[0];
  }
}
