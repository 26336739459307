
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { ValidationProvider } from 'vee-validate';
  import ValidationProviderStub from '@app/components/admin/questions/edit/validation-provider-stub.vue';

  // TODO rename to ds-field and move to root
  @Component({ components: { ValidationProvider, ValidationProviderStub } })
  export default class FormField extends Vue {
    @Prop(String) label?: string;
    @Prop(String) help?: string;
    @Prop(String) name?: string;
    @Prop(String) forId?: string;
    @Prop(String) vid?: string;
    @Prop(String) alert?: string;
    @Prop(Boolean) withColon?: boolean;
    @Prop(Boolean) required?: boolean;
    @Prop(Boolean) flex?: boolean;
    @Prop(Boolean) disabled?: boolean;
    @Prop({ type: Boolean, default: false }) static!: boolean;
    @Prop(Number) ratio?: number;
    @Prop(Object) rules?: Record<string, object>;
    @Prop(Object) errorMessages?: Record<string, string>;
    @Prop(String) validationMode?: 'aggressive' | 'passive' | 'lazy' | 'eager' | '';
    @Prop(Number) debounce?: number;

    get labelClass() {
      if (this.flex) return 'd-flex w-50';
      if (!this.ratio) return this.hasLabel ? 'w-100' : null;

      return `col-sm-${this.labelSize}`;
    }

    get inputClass() {
      if (this.flex) return 'd-flex w-100';
      if (!this.ratio) return 'w-100';

      return `col-sm-${this.inputSize}`;
    }

    get inputSize(): number {
      if (!this.ratio) return 12;

      return Math.round(12 / (this.ratio + 1));
    }

    get labelSize(): number {
      if (!this.ratio) return 12;

      return 12 - this.inputSize;
    }

    get validationRules() {
      return { required: this.required, ...(this.rules || {}) };
    }

    get horizontal() {
      return !!this.ratio;
    }

    get hasLabel(): boolean {
      return !!this.label || !!this.$slots['label'];
    }

    formGroupClasses(classes: { invalid: boolean }) {
      const flexClasses = this.flex
        ? { 'd-flex': true, 'align-items-center': true, 'justify-content-start': true, 'm-l-sm': true, 'w-50': true }
        : {};
      return { ...classes, ...flexClasses, 'has-error': classes.invalid };
    }
  }
