import type { ModuleName } from '@app/models/module-name';
import type { DonesafeApi } from '@app/services/donesafe-api';
import type { OnlyOptions } from '@app/services/donesafe-api-utils';
import { MAIN_FORM_MODULE_NAME } from '@app/constants';

export const getRelatedModuleNameFromSubForm = (
  subFormId: number,
  api: DonesafeApi,
  only: OnlyOptions<ModuleName>
): Promise<ModuleName | void> => {
  return api.getSubForm(subFormId).then(({ data }) => {
    const filters = data.module_name === MAIN_FORM_MODULE_NAME ? { sub_form_id: data.id } : { name: data.module_name };
    return api.getModuleNames({ filters, only }, { cache: true }).then(({ data }) => data[0]);
  });
};
