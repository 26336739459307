
  import { isEqual } from 'lodash';
  import { Component } from 'vue-property-decorator';
  import PayCalculatorTable from '../calculator/pay-calculator-table.vue';
  import BaseField from './base-field';
  import Select2 from '../select2.vue';
  import type { PayCalculatorFieldValue } from '@app/models/question-response-types';
  import type { FieldType } from '@app/models/sub-form-question';
  import { extraPayDetailStrings } from '@app/utils/extra-pay-detail-strings';
  import { extraPayItems } from '@app/constants';

  @Component({ components: { PayCalculatorTable, Select2 } })
  export default class PayCalculatorField extends BaseField<FieldType.pay_calculator> {
    editPayDetailsVisibility = false;
    localValue: PayCalculatorFieldValue = { value: {} };

    beforeMount(): void {
      this.editPayDetailsVisibility = this.question.config.expanded === 'true';
      if (!this.record || this.defaultTemplating) {
        return;
      }
    }

    mounted(): void {
      this.localValue = {
        // TODO: migrate all pay_calculator response without value wrap
        value: {
          pay_detail_avg_hourly_rate: '0',
          pay_detail_avg_hours_worked_per_week: '0',
          pay_detail_overtime: '0',
          pay_detail_shift_allowance: '0',
          pay_detail_commission: '0',
          pay_detail_piece_tally_bonus: '0',
          pay_detail_motor_vehicle_allowance: '0',
          pay_detail_residential_allowance: '0',
          pay_detail_health_insurance: '0',
          pay_detail_education_fees: '0',
          ...this.value?.value,
        },
      };
      this.sendUpdate(this.value, true);
    }

    updateValue(): void {
      const newValue = {
        value: {
          ...this.localValue.value,
          pay_detail_on_screen_total: `${this.screenTotal}`,
        },
      };
      if (!isEqual(this.localValue, newValue)) {
        this.localValue = newValue;
        this.sendUpdate(newValue);
        this.$emit('input', newValue);
      }
    }

    itemTitle(itemKey: string): string | undefined {
      return this.extraPayDetailStrings[itemKey];
    }

    get extraPayDetailStrings(): Record<string, string> {
      return extraPayDetailStrings(this.accountStore.data);
    }

    get inputOverrideTotalVisibility(): boolean {
      return !!this.localValue.value.override_total || this.localValue.value.override_total === '';
    }

    get overrideTotalVisibility(): boolean {
      return !!this.localValue.value.override_total && !isNaN(Number(this.localValue.value.override_total));
    }

    get total(): number {
      const { pay_detail_avg_hourly_rate, pay_detail_avg_hours_worked_per_week } = this.localValue.value;
      const extraTotal = this.extraPayItems.reduce<number>(
        (accum, item) => accum + Number(this.localValue.value[item.key as keyof PayCalculatorFieldValue['value']] || '0'),
        0
      );
      const amount = Number(pay_detail_avg_hourly_rate) * Number(pay_detail_avg_hours_worked_per_week);
      if (!isNaN(extraTotal)) {
        return amount + extraTotal;
      }

      return amount;
    }

    get overrideTotal(): number {
      return (this.localValue.value.override_total && Number(this.localValue.value.override_total)) || 0;
    }

    get screenTotal(): number {
      return this.overrideTotalVisibility ? this.overrideTotal : this.total;
    }

    get extraPayItems(): { key: string; title: string }[] {
      return extraPayItems.map((item) => ({
        key: `pay_detail_${item}`,
        title: this.itemTitle(item) || 'N/A',
      }));
    }

    get hiddenKeys(): string[] {
      return Object.keys(this.localValue.value).filter((key) => key !== 'pay_detail_on_screen_total');
    }
  }
