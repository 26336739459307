import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { ModuleName } from '@app/models/module-name';
import type { DonesafeApi } from '@app/services/donesafe-api';

export class ModuleNameService extends BaseService<ModuleName> {
  static readonly allowedAttributes = [
    'active',
    'config',
    'custom_title',
    'id',
    'index',
    'index_options',
    'location_sub_form_question_id',
    'module_type',
    'name',
    'organization_sub_form_question_id',
    'record_link_behaviour',
    'record_prefix',
    'scheme_sub_form_question_id',
    'show_in_app',
    'show_options',
    'start_workflow_id',
    'sub_form_id',
    'use_location_permissions',
    'use_organization_permissions',
    'score_band_profile_id',
    'solution_type',
    'hierarchy_type',
    'description',
    'menu_collection_id',
  ];

  async fetchDetails(itemId: ServiceRequest<ModuleName>): Promise<DetailsServiceResponse> {
    const moduleName = await this.fetchEntity(itemId);
    if (!moduleName.display) return {};

    return {
      links: [
        {
          link: `/admin/settings/module_names/${moduleName.id}`,
          prefix: this.t('app.labels.module'),
          title: moduleName.display,
          id: moduleName.id,
        },
      ],
    };
  }

  async fetchEntity({ itemId }: ServiceRequest<ModuleName>): Promise<ModuleName> {
    return await ModuleNameService.fetchEntity(this.api, itemId);
  }

  entityToText({}: ServiceRequest<ModuleName>, entity?: ModuleName): string {
    return entity ? ModuleNameService.toText(entity) : '';
  }

  static async fetchEntity(api: DonesafeApi, itemId: ModuleName['id']): Promise<ModuleName> {
    const { data: moduleName } = await api.getModuleName(itemId, { only: ['id', 'display'], show_all: true }, { cache: true, join: true });
    return moduleName;
  }

  static toText(moduleName: Pick<ModuleName, 'id' | 'display'>): string {
    return `${moduleName.display} [${moduleName.id}]`;
  }
}
