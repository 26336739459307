import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import type { TableCalculationColumnEditable } from '@app/models/question-options/table-calculation-question-options';

@Component
export default class BaseEditableColumn<T> extends Vue {
  @Model('input', { type: [String, Number] }) readonly value!: string | number | null;
  @Prop(Object) readonly column!: T & TableCalculationColumnEditable;
  @Prop(Number) readonly index!: number;
  @Prop(Boolean) readonly readonly?: boolean;
  @Prop(Boolean) readonly bulkEdit?: boolean;
}
