import { ResponseType } from '../models';
import { versionTime } from '../utils';
import { BaseService } from './base-service';
import type { PaperTrailChange } from '@app/models/paper-trail-version';
import type { Shift } from '@app/models/shift';

const DATETIME_ATTRIBUTES = ['start_at', 'end_at'];

export class ShiftService extends BaseService<Shift> {
  static readonly allowedAttributes = ['end_at', 'start_at'];

  normalizeValue(change: Nullable<PaperTrailChange>, key: keyof Shift): Nullable<PaperTrailChange> {
    if (DATETIME_ATTRIBUTES.includes(key)) {
      return change ? versionTime(this.account, change as string) : '';
    }
    return super.normalizeText(change, key);
  }

  responseType(key: keyof Shift): ResponseType {
    if (DATETIME_ATTRIBUTES.includes(key)) return ResponseType.DateTime;

    return super.responseType(key);
  }
}
