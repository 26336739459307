
  import { Component, Model, Prop } from 'vue-property-decorator';
  import type { TenantUser } from '@app/models/tenant-user';
  import DsModal from '@app/components/ds-modal.vue';
  import UserSettingsPage from '@app/components/admin/users/user-settings-page.vue';
  import { useAccountStore } from '@app/stores/account';
  import { mixins } from 'vue-class-component';
  import WithUser from '@app/mixins/with-user';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import TabMenu from '@app/components/tab-menu.vue';
  import UserActions from '@app/components/admin/users/user-actions.vue';
  import UserDetailsTab from '@app/components/admin/users/user-details-tab.vue';
  import UserRelatedRecordsPage from '@app/components/admin/users/user-related-records-page.vue';
  import UserAcknowledgementsPage from '@app/components/admin/users/user-acknowledgements-page.vue';
  import UserEnrollmentsTab from '@app/components/admin/users/user-enrollments-tab.vue';
  import UserDocumentsTable from '@app/components/user/user-documents-table.vue';
  import UserPersonalDetailsTab from '@app/components/admin/users/user-personal-details-tab.vue';
  import UserNotificationSubscriptions from '@app/components/admin/users/user-notification-subscriptions.vue';
  import UserPayDetailsTab from '@app/components/admin/users/user-pay-details-tab.vue';
  import UserTimesheetsPage from '@app/components/admin/users/user-timesheets-page.vue';
  import UserPreferencesTab from '@app/components/admin/users/user-preferences-tab.vue';
  import UserDynamicDashboardSettings from '@app/components/user/user-dynamic-dashboard-settings.vue';
  import UserTagsPage from '@app/components/admin/users/user-tags-page.vue';
  import UserAvatar from '@app/components/user-avatar.vue';

  @Component({
    components: {
      DsModal,
      UserSettingsPage,
      UserAvatar,
      TabMenu,
      UserActions,
      UserDetailsTab,
      UserRelatedRecordsPage,
      UserAcknowledgementsPage,
      UserEnrollmentsTab,
      UserDocumentsTable,
      UserPersonalDetailsTab,
      UserNotificationSubscriptions,
      UserPayDetailsTab,
      UserTimesheetsPage,
      UserPreferencesTab,
      UserDynamicDashboardSettings,
      UserTagsPage,
    },
  })
  export default class UserProfileModal extends mixins(WithUser) {
    @Model('input') readonly value!: boolean;
    @Prop(String) readonly tabName?: string;

    user: Nullable<TenantUser> = null;
    activeTab: string = '';
    allTabs = {
      details: { key: 'details', name: this.$t('app.labels.details'), component: UserDetailsTab },
      related_records: {
        key: 'related_records',
        name: this.$t('app.labels.related_records'),
        component: UserRelatedRecordsPage,
      },
      tags: { key: 'tags', name: this.$t('app.labels.tags'), component: UserTagsPage },
      acknowledgments: {
        key: 'acknowledgments',
        name: this.$t('app.labels.acknowledgments'),
        component: UserAcknowledgementsPage,
      },
      enrollments: {
        key: 'enrollments',
        name: this.$t('tenant.users.tabs.course_enrollments'),
        component: UserEnrollmentsTab,
      },
      documents: {
        key: 'documents',
        name: this.$t('app.labels.documents'),
        component: UserDocumentsTable,
      },
      personal_details: {
        key: 'personal_details',
        name: this.$t('app.labels.personal_details'),
        component: UserPersonalDetailsTab,
      },
      notification_subscriptions: {
        key: 'notification_subscriptions',
        name: this.$t('tenant.users.tabs.notification_subscriptions'),
        component: UserNotificationSubscriptions,
      },
      pay_details: { key: 'pay_details', name: this.$t('app.labels.pay_details'), component: UserPayDetailsTab },
      timesheets: { key: 'timesheets', name: this.$t('app.labels.timesheets'), component: UserTimesheetsPage },
      preferences: {
        key: 'preferences',
        name: this.$t('app.labels.preferences_and_security'),
        component: UserPreferencesTab,
      },
      dynamic_dashboards: {
        key: 'dynamic_dashboards',
        name: this.$t('tenant.users.tabs.dynamic_dashboard'),
        component: UserDynamicDashboardSettings,
      },
    };

    get permissions() {
      if (this.currentUserStore.data?.id === this.userId) {
        return this.currentUserStore.data?.role?.profile_permissions?.self;
      } else {
        return this.currentUserStore.data?.role?.profile_permissions?.others;
      }
    }

    get tabs() {
      const tabs = [];
      const permissions = this.permissions;

      if (permissions?.basic || permissions?.permission_based) tabs.push(this.allTabs.details);
      if (permissions?.basic) tabs.push(this.allTabs.related_records);
      if (permissions?.tags) tabs.push(this.allTabs.tags);
      if (permissions?.learning) tabs.push(this.allTabs.acknowledgments);
      tabs.push(this.allTabs.enrollments);
      if (permissions?.documents) tabs.push(this.allTabs.documents);
      if (permissions?.personal_details) tabs.push(this.allTabs.personal_details);
      if (permissions?.notification_subscriptions) tabs.push(this.allTabs.notification_subscriptions);
      if (this.accountStore.data.show_pay_details_tab && permissions?.pay_details) {
        tabs.push(this.allTabs.pay_details);
        tabs.push(this.allTabs.timesheets);
      }
      if (this.currentUserStore.data?.id === this.userId) tabs.push(this.allTabs.preferences);
      if (
        this.user?.role?.dynamic_dashboard_customisation &&
        this.user.has_active_panes &&
        this.currentUserStore.data?.id === this.userId
      ) {
        tabs.push(this.allTabs.dynamic_dashboards);
      }

      return tabs;
    }

    get currentComponent() {
      return this.tabs.find((tab) => tab.key === this.activeTab)?.component;
    }

    get currentProps() {
      if (this.activeTab !== 'dynamic_dashboards') {
        return { userId: this.userId };
      }
      return {};
    }

    get accountStore() {
      return useAccountStore();
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    beforeMount(): void {
      this.$api
        .getTenantUser(this.userId, { only: ['full_name', 'role', 'has_active_panes'], include: 'role' }, { cache: true })
        .then(({ data }) => {
          this.user = data;
          this.activeTab = this.tabName || this.tabs[0].key;
        });
    }
  }
