// Add a question type and the field ID type to read from
// for each type of value within that question type.
// Format of field id must be: responses_{questionId}_{fieldOption} and
// the value attribute of that field must update for observer to detect a change.
import type { VariableQuestion } from '../models/types';
import type { Dictionary } from '@app/models/dictionary';

const FIELD_TYPES = {
  calculation_text: ['value'],
  date: ['value'],
  datetime: ['value'],
  calculator: ['value'],
  timesheet_summary: ['total_hours', 'total_pay'],
  calculation_select: ['value'],
  pay_calculator: [
    'value_pay_detail_avg_hourly_rate',
    'value_pay_detail_avg_hours_worked_per_week',
    'value_pay_detail_overtime',
    'value_pay_detail_shift_allowance',
    'value_pay_detail_commission',
    'value_pay_detail_piece_tally_bonus',
    'value_pay_detail_motor_vehicle_allowance',
    'value_pay_detail_residential_allowance',
    'value_pay_detail_health_insurance',
    'value_pay_detail_education_fees',
    'value_pay_detail_on_screen_total',
  ],
};

// FIXME: remove undefined
function generateFieldId(id: number | undefined, fieldOption: string): string {
  return `responses_${id}_${fieldOption}`;
}

export default function (questionDetailObject?: VariableQuestion): Dictionary<Dictionary<string>> {
  let questionMappingObject: Dictionary<Dictionary<string>> = {};

  Object.entries(FIELD_TYPES).forEach(([key, value]) => {
    questionMappingObject = { ...questionMappingObject, [key]: {} };

    value.map((fieldOption: string) => {
      questionMappingObject[key][fieldOption] = generateFieldId(questionDetailObject?.id, fieldOption);
    });
  });

  return questionMappingObject;
}
