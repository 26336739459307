
  import MatrixField from '@app/components/form-fields/matrix-field.vue';
  import { Component, Prop } from 'vue-property-decorator';
  import BaseQuestionResponse from './base-question-response';
  import type { MatrixQuestionOptions } from '@app/models/question-options/matrix-question-options';
  import type { MatrixFieldValue } from '@app/models/question-response-types';
  import DsPopover from '@app/components/ds-popover.vue';
  import type { Matrix } from '@app/models/matrix';

  @Component({ components: { MatrixField, DsPopover } })
  export default class MatrixFieldResponse extends BaseQuestionResponse<MatrixQuestionOptions> {
    @Prop(Object) readonly matrix?: MatrixFieldValue;

    actualMatrix: Nullable<Matrix> = null;

    async beforeMount() {
      const { data: matrix } = await this.$api.getMatrix(this.question.config.matrix_id, {}, { cache: true, join: true });

      this.actualMatrix = matrix;
    }

    get hasResponse(): boolean {
      return !!this.matrixCellOutcome?.score;
    }

    get matrixCellOutcome() {
      const { matrix_cell_outcome_id: rspId } = this.matrix || {};

      return this.actualMatrix?.outcomes?.find(({ matrix_cell_outcome_id }) => `${matrix_cell_outcome_id}` === `${rspId}`);
    }

    get matrixResponseText(): string {
      const { outcome, score } = this.matrixCellOutcome || {};

      return this.$t('app.labels.matrix_with_score_and_outcome', { score, outcome });
    }
  }
