
  import { SimpleGridTableElement } from '@app/components/simple-grid-table/simple-grid-table-element';
  import type { BaseEntity } from '@app/models/base-entity';
  import { Component, Prop } from 'vue-property-decorator';
  import BaseTableFieldCheckbox from '../base-table/base-table-field-checkbox.vue';
  import type { ListManagerField } from '@app/services/list-manager/types';

  @Component({ components: { BaseTableFieldCheckbox } })
  export default class SimpleGridTableBody<T extends BaseEntity<number | string>> extends SimpleGridTableElement<T> {
    @Prop(String) readonly noDataTemplate?: string;
    @Prop([Function, String]) rowClass?: string | ((item: T, index: number) => string);

    get trackBy() {
      return this.manager.trackBy;
    }

    get tbodyAttributes() {
      return {
        ...(this.scrollName && { name: this.scrollName }),
      };
    }

    isFieldComponent(name: string | typeof Component): boolean {
      return name instanceof Object;
    }

    onRowClicked(item: T, index: number, event: MouseEvent) {
      this.$emit('row-clicked', { data: item, event, index });
    }

    itemRowClass(item: T, index: number) {
      const uniqId = item?.[this.trackBy] || item;
      const baseClass = `simple-grid-tr-${uniqId}`;

      if (typeof this.rowClass === 'function') {
        return [baseClass, this.rowClass(item, index)];
      } else {
        return [baseClass, this.rowClass];
      }
    }

    tdFieldClasses(field: ListManagerField, item: T) {
      const filedClass = field.evaluateClass ? field.evaluateClass(field, item) : field.dataClass;
      return [filedClass, `simple-grid-td-${field.name}`, ...(field.sticky ? ['column-sticky'] : [])];
    }
  }
