
  import { Component, Emit } from 'vue-property-decorator';
  import type { SelectOption } from '@app/models/question-options/shared';
  import type { FieldType } from '@app/models/sub-form-question';

  import Select2 from '../select2.vue';

  import BaseField from './base-field';

  interface Select2OptionWithGroup {
    group?: string;
    label: string;
    value: string;
  }

  @Component({ components: { Select2 } })
  export default class MainFormQuestionListField extends BaseField<FieldType.main_form_question_list> {
    localValue: string[] = [];
    options: SelectOption[] = [];

    get groupBy(): string | undefined {
      if (this.question.config?.group_options === 'true') {
        return 'group';
      }
    }

    get selectOptions(): Select2OptionWithGroup[] {
      return Object.values(this.options).map((selectOption: SelectOption & { group?: string }) => {
        return {
          group: selectOption?.group,
          value: selectOption.key,
          label: selectOption.value,
        } as Select2OptionWithGroup;
      });
    }

    @Emit('input')
    onInput(value: string[]): string[] {
      this.localValue = value;
      this.sendUpdate(this.localValue);
      return this.localValue;
    }

    beforeMount(): void {
      this.localValue = this.value || [];

      this.options = Object.values(this.question.options.values || {});
      const historicalOptions = Object.values(this.question.options.historical_values || {});

      if (this.value) {
        this.value.forEach((item) => {
          const currentOption = this.options.find((o) => o.key == item);
          if (!currentOption && historicalOptions.length > 0) {
            const historicalOption = historicalOptions.find((o) => o.key == item);
            if (historicalOption) {
              this.options.push(historicalOption);
            }
          }
        });
      }
    }
  }
