import { WithLinksDetails, TranslationWithLinksDetails } from './index';

export const DETAILS_COMPONENTS = {
  TranslationWithLinksDetails,
  WithLinksDetails,
};

export const DETAILS_COMPONENTS_MAPPING: Record<string, string> = {
  // Override section, by default will be WithLinksDetails
};

export const DETAILS_TRANSLATION_COMPONENTS_MAPPING: Record<string, string> = {
  ...DETAILS_COMPONENTS_MAPPING,
  // Override section
};
