
  import { Component } from 'vue-property-decorator';
  import TextHighlight from 'vue-text-highlight';
  import BaseDetails from '../details/base-details';
  import { DETAILS_COMPONENTS, DETAILS_TRANSLATION_COMPONENTS_MAPPING } from '../details/components';
  import { deleteTranslationPostfix } from '../mapping-utils';
  import type { DetailsLink } from '../models';

  @Component({ components: { TextHighlight, ...DETAILS_COMPONENTS } })
  export default class PaperTrailsTranslationDetails extends BaseDetails {
    get detailsComponentName(): Nullable<string> {
      return DETAILS_TRANSLATION_COMPONENTS_MAPPING[this.baseType] || 'TranslationWithLinksDetails';
    }

    get baseType(): string {
      return deleteTranslationPostfix(this.type);
    }

    get detailsProps(): { links: DetailsLink[]; mainText: string; subText?: string } {
      return {
        links: this.links,
        mainText: this.mainText,
        subText: this.subText,
      };
    }
  }
