
  import { Component } from 'vue-property-decorator';
  import BaseField from './base-field';
  import UuidField from './uuid-field.vue';
  import type { FieldType } from '@app/models/sub-form-question';

  @Component({ components: { UuidField } })
  export default class ExpenseBudgetUuidField extends BaseField<FieldType.expense_budget_uuid> {
    get uuidFieldValue(): Maybe<string> {
      return this.value?.value;
    }
  }
