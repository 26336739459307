import type { AutomationEventGroup } from '@app/models/automation-event-group';

import { BaseService } from './base-service';

export class AutomationEventGroupService extends BaseService<AutomationEventGroup> {
  static readonly allowedAttributes = ['id', 'name', 'parent_id'];

  static attributeNames($t: (key: string) => string): Partial<Record<keyof AutomationEventGroup, string>> {
    return {
      parent_id: $t('app.labels.parent_group_id'),
    };
  }
}
