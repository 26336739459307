import type { BaseEntity } from '../base-entity';
import type { FrequencyData } from './frequency-data';

export enum Frequency {
  hourly = 'hourly',
  daily = 'daily',
  weekly = 'weekly',
  monthlyDayOfWeek = 'monthly_day_of_week',
  monthlyDayOfMonth = 'monthly_day_of_month',
}

export interface RecurringSetup extends BaseEntity {
  enabled: boolean;
  end_at?: Date;
  frequency: Frequency;
  frequency_data: FrequencyData;
  recurrable_id?: number;
  recurrable_type?: string;
  start_at: Date;
}
