import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { Location } from '@app/models/location';
import { AdminPermissionFeature } from '@app/models/admin-permission';
import type { DonesafeApi } from '@app/services/donesafe-api';
import { featureEnabled } from '@app/utils/feature-enabled';

export class LocationService extends BaseService<Location> {
  static readonly allowedAttributes = [
    'id',
    'location_id',
    'name',
    'suburb',
    'state',
    'postcode',
    'country',
    'active',
    'address_line_1',
    'address_line_2',
    'timezone',
    'external_uuid',
  ];

  async fetchDetails(itemId: ServiceRequest<Location>): Promise<DetailsServiceResponse> {
    const location = await this.fetchEntity(itemId);
    return {
      links: [
        {
          link: this.locationEditLink(location),
          prefix: this.t('app.labels.location'),
          title: location.name,
          id: location.id,
        },
      ],
    };
  }

  async fetchEntity({ itemId }: ServiceRequest<Location>): Promise<Location> {
    return await LocationService.fetchEntity(this.api, itemId);
  }

  entityToText({}: ServiceRequest<Location>, entity?: Location): string {
    return entity ? LocationService.toText(entity) : '';
  }

  static async fetchEntity(api: DonesafeApi, itemId: Location['id']): Promise<Location> {
    const { data: location } = await api.getLocation(itemId, { only: ['id', 'name'] }, { cache: true, join: true });
    return location;
  }

  static toText(location: Pick<Location, 'id' | 'name'>): string {
    return `${location.name} [${location.id}]`;
  }

  private locationEditLink(location: Pick<Location, 'id' | 'name'>): string | undefined {
    if (featureEnabled(this.currentUser, AdminPermissionFeature.users_locations_organizations)) {
      return `/admin/settings/locations/${location.id}/edit`;
    }
  }
}
