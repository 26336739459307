import { useCurrentUserStore } from '@app/stores/currentUser';
import { useAccountStore } from '@app/stores/account';
import { compact, groupBy, isInteger, snakeCase, sortBy } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import type { BaseEntity } from '@app/models/base-entity';
import { ATTRIBUTES_MAPPING, eventNameMapping, SERVICES_MAPPING } from './mapping';
import {
  allowedAttribute,
  allowedAttributes,
  attributeLabel,
  deleteTranslationPostfix,
  displayedAttributeOverrides,
  isTranslation,
  typeName,
} from './mapping-utils';
import type { AttributeValues, AttributeValuesMap, DetailsLink, VersionSearchMetaData } from './models';
import { UserService } from './services';
import type { DetailsServiceResponse } from './services/base-service';
import { BaseService } from './services/base-service';
import { extractPaperTrailVersionValue, linksToString } from './utils';
import type { PaperTrailAttribute, PaperTrailChange, PaperTrailItemType, PaperTrailVersion } from '@app/models/paper-trail-version';
import type { TenantUser } from '@app/models/tenant-user';
import { select2ResponseTemplate } from '@app/utils/select2-response-template';

export type CustomFiltersValue = (string | [string, string] | undefined) & string[] & ('create' | 'update' | 'destroy')[] & '';

@Component
export default class PaperTrailsBase extends Vue {
  services: Record<string, BaseService> = {};

  versions: PaperTrailVersion[] = [];

  // Maps
  userLinksMap: Record<string, DetailsLink> = {};
  detailsMap: Record<string, DetailsServiceResponse> = {};
  metaDataMap: Record<number | string, VersionSearchMetaData> = {};
  attributeValuesMap: AttributeValuesMap = {};

  users: Pick<TenantUser, 'id' | 'full_name' | 'secondary_information'>[] = [];
  attributes: PaperTrailAttribute[] = [];
  itemTypes: PaperTrailItemType[] = [];
  whodunnits: string[] = [];
  attributeScope?: string[];

  get currentUserStore() {
    return useCurrentUserStore();
  }

  get accountStore() {
    return useAccountStore();
  }

  get userOptions(): { key: string; label: string }[] {
    const users = this.whodunnits.map((whodunnit) => {
      let title: string;
      const authorType = UserService.authorType(whodunnit);
      if (authorType === 'user' && whodunnit) {
        const user = this.users.find((user) => `${user.id}` === whodunnit);
        title = UserService.whodunnit(whodunnit, { user });
      } else if (authorType === 'raw') {
        title = this.$t('app.labels.system').toString();
      } else {
        title = UserService.whodunnit(whodunnit);
      }

      return { key: whodunnit, label: title };
    });
    const groups = groupBy(users, 'label');
    return sortBy(
      Object.keys(groups).map((label) => {
        const values = groups[label];
        return { key: values.map((item) => item.key).join('|'), label };
      }),
      'label'
    );
  }

  userTemplateResult(result: { id: string; text: string }): JQuery {
    const user = this.users.find((user) => `${user.id}` === result.id);
    return select2ResponseTemplate(user, {
      secondaryAttribute: 'secondary_information',
      primaryAttribute: () => result.text,
    });
  }

  get eventOptions(): [string, string][] {
    return Object.entries(eventNameMapping(this.$t));
  }

  get typeOptions(): { key: string; label: string }[] {
    return sortBy(
      this.itemTypes.map(({ item_type }) => ({ key: item_type, label: typeName(item_type, this.$t) })),
      'label'
    );
  }

  get attributesOptions(): { group: string; key: string; label: string }[] {
    let attributes: { group: string; key: string; label: string }[] = [];

    this.typeOptions.forEach(({ key: itemType, label: groupLabel }) => {
      const attribute_keys = this.itemTypes.find((t) => t.item_type === itemType)?.attribute_keys;
      if (!attribute_keys) return;

      const typeAttributes = attribute_keys
        .filter((attributeKey) => supportedAttribute(itemType, attributeKey, this.attributeScope))
        .map((key) => ({
          key: `${itemType}:${key}`,
          label: attributeLabel(itemType, key, this.$t),
        }));
      const groups = groupBy(typeAttributes, 'label');
      const compactedAttributes = Object.keys(groups).map((label) => ({
        key: groups[label].map((label) => label.key).join('|'),
        label: groups[label][0].label,
        group: groupLabel,
      }));
      attributes = [...attributes, ...compactedAttributes];
    });
    return sortBy(attributes, 'group label');
  }

  getService(type: string): BaseService<BaseEntity> {
    if (!this.services[type]) {
      const initAttributes = { api: this.$api, t: this.$t, currentUser: this.currentUserStore.data, account: this.accountStore.data };
      const Service = SERVICES_MAPPING[type] || BaseService;
      this.services[type] = new Service(initAttributes);
    }

    return this.services[type];
  }

  fetchVersionDetails(version: PaperTrailVersion, service: BaseService, type: string): void {
    const key = `${snakeCase(type)}_id` as keyof BaseEntity;
    const itemId = isTranslation(version) ? (extractPaperTrailVersionValue(key, version) as number) : version.item_id;
    service.fetchDetails({ itemId, version }).then((response) => {
      this.onMetaDataLoaded([
        version,
        { details: compact([response.mainText || '', response.subText || '', linksToString(response.links), `ID: ${version.item_id}`]) },
      ]);
      this.detailsMap = {
        ...this.detailsMap,
        [version.id]: response,
      };
    });
    const userService = this.getService('User') as UserService;
    if (!userService) return;

    userService.fetchWhodunnitDetails(version).then((response) => {
      const link = response.links?.[0];
      link && this.onUserLinkLoaded(version, link);
    });
  }

  handleRawAttribute(version: PaperTrailVersion, service: BaseService, changeKey: string, attributeResponseKey = 'raw'): void {
    service.normalizeChanges(changeKey as keyof BaseEntity, version).then(({ texts, changes, responseType }) => {
      this.onMetaDataLoaded([version, { [changeKey]: [...texts, attributeLabel(version.item_type, changeKey, this.$t)] }]);
      this.onAttributeValuesLoaded(responseType, version, changeKey, attributeResponseKey, {
        texts,
        changes,
      });
    });
  }

  fetchVersionAttributes(version: PaperTrailVersion, service: BaseService, type: string): void {
    const objectChanges = version.object_changes;
    if (!objectChanges) return;

    const attributes = ATTRIBUTES_MAPPING[type] || {};
    Object.keys(objectChanges).forEach((changeKey) => {
      if (!allowedAttributes(version.item_type)?.length) {
        return this.handleRawAttribute(version, service, changeKey);
      }
      if (!allowedAttribute(version.item_type, changeKey)) {
        return;
      }

      const attributeType = attributes[changeKey];
      if (attributeType) {
        const attributeService = this.getService(attributeType);
        if (!attributeService) return;

        attributeService.fetchChanges(changeKey as keyof BaseEntity, version).then((changesResponse) => {
          Object.entries(changesResponse).forEach(([attributeResponseKey, { entities, texts }]) => {
            this.onMetaDataLoaded([version, { [changeKey]: [...texts, attributeLabel(version.item_type, changeKey, this.$t)] }]);
            this.onAttributeValuesLoaded(attributeType, version, changeKey, attributeResponseKey, {
              entities,
              texts,
            });
          });
        });
      } else {
        this.handleRawAttribute(version, service, changeKey);
      }
    });
  }

  onMetaDataLoaded(update: [PaperTrailVersion, VersionSearchMetaData]): void {
    const key = update[0].id;
    this.metaDataMap = {
      ...this.metaDataMap,
      [key]: {
        ...this.metaDataMap[key],
        ...update[1],
      },
    };
  }

  onAttributeValuesLoaded(
    type: string,
    version: PaperTrailVersion,
    changeKey: string, // 'id' | 'created_at' etc
    attributeKey: string, // 'raw' | 'entity' | 'user' etc
    values: {
      changes?: [Nullable<PaperTrailChange>, Nullable<PaperTrailChange>];
      entities?: [Nullable<Partial<BaseEntity>>, Nullable<Partial<BaseEntity>>];
      texts: [Nullable<string>, Nullable<string>];
    }
  ): void {
    const versionId = version.id;
    const previousValues = this.attributeValuesMap[versionId] || {};
    if (attributeKey === 'raw' && values.changes) {
      this.attributeValuesMap = {
        ...this.attributeValuesMap,
        [versionId]: {
          ...previousValues,
          [changeKey]: {
            ...(previousValues[changeKey] || {}),
            [attributeKey]: {
              ...(previousValues[changeKey]?.[attributeKey] || {}),
              changes: [values.changes[0], values.changes[1]],
              texts: [values.texts[0], values.texts[1]],
              responseType: type,
            },
          },
        },
      };
      return;
    }
    this.attributeValuesMap = {
      ...this.attributeValuesMap,
      [versionId]: {
        ...previousValues,
        [changeKey]: {
          ...(previousValues[changeKey] || {}),
          [attributeKey]: {
            ...(previousValues[changeKey]?.[attributeKey] || {}),
            entities: [values.entities?.[0] || null, values.entities?.[1] || null],
            texts: [values.texts?.[0] || null, values.texts?.[1] || null],
            responseType: type,
          },
        },
      },
    };
  }

  onUserLinkLoaded(version: PaperTrailVersion, link: DetailsLink): void {
    this.userLinksMap = {
      ...this.userLinksMap,
      [version.whodunnit]: link,
    };
  }

  attributeValues(versionId: string): AttributeValues {
    return this.attributeValuesMap[versionId] || {};
  }

  fetchAttributes(recordId: string | number | string[] | number[], recordType: string): void {
    this.$api
      .getPaperTrailAttributes(
        {
          item_id: recordId,
          item_type: recordType,
          with_children: true,
        },
        { cache: true }
      )
      .then(({ data: { attributes, item_types, whodunnits } }) => {
        this.attributes = attributes;
        this.itemTypes = item_types;
        this.whodunnits = whodunnits;
        this.$api
          .getTenantUsers(
            {
              per_page: -1,
              sort: 'full_name',
              include: ['secondary_information'],
              only: ['id', 'full_name', 'secondary_information'],
              filters: { id: whodunnits.filter((whodunnit) => isInteger(whodunnit)) },
            },
            { cache: true }
          )
          .then(({ data: users }) => (this.users = users));
      });
  }

  fetchVersionData(version: PaperTrailVersion) {
    const type = deleteTranslationPostfix(version.item_type);
    const service = this.getService(type);
    if (service) {
      this.fetchVersionDetails(version, service, type);
      this.fetchVersionAttributes(version, service, type);
    } else {
      this.onMetaDataLoaded([version, { details: [`ID: ${version.item_id}`] }]);
    }
  }

  attributesTemplateSelection(result: { id: string; text: string }): JQuery {
    const attribute = this.attributesOptions.find((i) => i.key === result.id);
    const text = attribute ? `${attribute.group}: ${attribute.label}` : result.text;

    return $(
      `<div style="display:inline-block;float:left;padding-right:3px">
            <p style="margin-bottom:0;">${text}</p>
         </div>`
    );
  }
}

export const supportedAttribute = (itemType: string, attributeKey: string, attributeScope?: string[]): boolean => {
  const attributes = attributeScope || displayedAttributeOverrides(itemType) || [];
  return attributes.includes(attributeKey);
};
