
  import FileUploader from '@app/components/file-uploader/file-uploader.vue';
  import type { Document } from '@app/models/document';
  import type { Blob } from '@rails/activestorage';
  import moment from 'moment';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import { Component, Model, Ref, Vue } from 'vue-property-decorator';
  import DateTimePicker from '@app/components/date-time-picker.vue';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({
    components: {
      FileUploader,
      DsModal,
      ValidationObserver,
      ValidationProvider,
      DateTimePicker,
    },
  })
  export default class UserDocumentFormModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    document: Partial<Document> = {};

    form: Partial<Omit<Document, 'valid_until'> & { file: string; valid_until?: Date | string }> = {};
    fileName = '';

    get title(): string {
      return this.document?.id ? this.$t('tenant.documents.edit.edit_document') : this.$t('tenant.documents.new.add_document');
    }

    get action(): string {
      return this.document?.id ? this.$t('app.buttons.update') : this.$t('app.buttons.save');
    }

    createBlob(blob?: Blob): void {
      this.setFileName(blob?.filename);
      this.form = { ...this.form, file: blob?.signed_id };
    }

    reset(document?: Partial<Document>): void {
      this.document = { ...document };
      this.form = {
        ...this.document,
        valid_until: (this.document?.valid_until && moment(this.document.valid_until).toDate()) || undefined,
        attachment: this.document?.attachment,
      };
      this.setFileName(document?.attachment?.file_name);
      this.validator?.reset();
    }

    setFileName(name?: string): void {
      this.fileName = name || '';
    }

    submit(): void {
      this.validator?.validate().then((result: boolean) => {
        result && this.$emit('submit', { ...this.form, valid_until: this.form.valid_until || null });
      });
    }
  }
