
  import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component({ components: {} })
  export default class Copy extends Vue {
    @Prop([String, Number]) value!: string | number;

    copy() {
      this.$copyText('' + this.value);
    }
  }
