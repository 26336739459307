import type { StringBoolean } from '@app/utils/types/string-boolean';

import type { AddRelatedConfig, LookupConfig } from './shared';

export enum ShowHideSource {
  BaseForm = 'base_form',
  MainForm = 'main_form',
}

export interface BaseQuestionOptions {
  add?: AddRelatedConfig;
  hide_if_public?: StringBoolean;
  hide_question?: StringBoolean;
  horizontal_divider?: 'above_title' | 'below_title' | 'below_response';
  lookup?: LookupConfig;
  mode?: 'api_request' | 'api_lookup' | 'lookup' | 'default';
  read_view_mode?: 'normal' | 'hidden' | 'show_description';
  scored?: StringBoolean;
  show?: {
    // NOTE this is a system_code of the question
    id: string | number; // number is just in case as there is old data with numbers in it
    source?: ShowHideSource;
    value: string[] | string;
  };
  use_type?: 'hidden' | 'normal' | 'readonly';
  weighting?: string;
}
