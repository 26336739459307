
  import { Component } from 'vue-property-decorator';
  import { BULK_EDIT_IGNORE_VALUE } from '../../utils';
  import BaseEditableColumn from './base-editable-column';
  import Select2 from '@app/components/select2.vue';
  import type { TableCalculationColumnSelect } from '@app/models/question-options/table-calculation-question-options';

  @Component({ components: { Select2 } })
  export default class SelectEditableColumn extends BaseEditableColumn<TableCalculationColumnSelect> {
    get selectOptions(): (string | string[])[] {
      return [
        ...('options' in this.column ? this.column.options || [] : []),
        ...(this.bulkEdit ? [[BULK_EDIT_IGNORE_VALUE, BULK_EDIT_IGNORE_VALUE]] : []),
      ];
    }

    get allowClear(): boolean {
      return !!this.column?.allow_clear;
    }
  }
