import { API_VERSION_HEADER } from '@app/constants';
import { getCsrfToken } from '@app/utils/get-csrf-token';
import { SANITIZE_ALLOWED_TAGS } from '@app/vue/with-sanitize';

const moreRich = { buttons: ['insertLink', 'insertTable', 'emoticons', 'specialCharacters', 'insertHR'] };

if (SANITIZE_ALLOWED_TAGS.indexOf('iframe') > -1) {
  moreRich.buttons.push('insertVideo');
}

if (SANITIZE_ALLOWED_TAGS.indexOf('img') > -1) {
  moreRich.buttons.push('insertImage');
}

const froalaConfig = ({ complex = false, public: public_ = false } = {}, options = {}, events = {}) => ({
  key: window.DONESAFE.FROALA_EDITOR,
  toolbarSticky: false,
  attribution: false,
  placeholderText: '',
  emoticonsUseImage: false,
  toolbarButtons: {
    moreText: {
      buttons: [
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        'fontSize',
        'textColor',
        ...(complex ? ['inlineClass', 'inlineStyle'] : []),
        'clearFormatting',
      ],
    },
    moreParagraph: {
      buttons: [
        ...(complex ? ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify'] : []),
        'formatOLSimple',
        'formatOL',
        'formatUL',
        'paragraphFormat',
        'paragraphStyle',
        'lineHeight',
        'outdent',
        'indent',
        'quote',
      ],
    },
    moreRich,
    moreMisc: {
      buttons: ['undo', 'redo', 'fullscreen', 'print', 'spellChecker', 'selectAll', 'help', 'html'],
      align: 'right',
      buttonsVisible: 2,
    },
  },
  events: {
    'image.uploaded': function () {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.image.get().attr('loading', 'lazy');
    },
    ...events,
  },
  requestHeaders: {
    'X-CSRF-Token': getCsrfToken(),
    'Accept-Version': API_VERSION_HEADER,
  },
  videoUpload: false,
  imageUploadParams: { public: public_ },
  imageUploadURL: '/api/utils/file_upload',
  ...options,
});

export default froalaConfig;
