import { merge } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';

import type { PaginationData } from './utils';
import { CssSemanticUI, paginationCss } from './utils';

@Component
export default class BaseTablePaginationMixin extends Vue {
  @Prop(Object) readonly css!: Record<string, string>;
  @Prop({ type: Number, default: () => 2 }) readonly onEachSide!: number;
  @Prop({ type: Number, default: () => 1 }) readonly firstPage!: number;
  @Prop(Object) readonly pagination?: PaginationData;

  get $_css(): Record<string, unknown> {
    return merge(paginationCss || {}, CssSemanticUI);
  }

  get tablePagination(): PaginationData | null {
    return this.pagination || null;
  }

  get totalPage() {
    return this.tablePagination === null ? 0 : this.tablePagination?.last_page - this.firstPage + 1;
  }
  get lastPage() {
    return this.tablePagination === null ? 0 : this.tablePagination.last_page;
  }
  get isOnFirstPage() {
    return this.tablePagination === null ? false : this.tablePagination.current_page === this.firstPage;
  }
  get isOnLastPage() {
    return this.tablePagination === null ? false : this.tablePagination.current_page === this.lastPage;
  }
  get notEnoughPages() {
    return this.totalPage < this.onEachSide * 2 + 4;
  }
  get windowSize() {
    return this.onEachSide * 2 + 1;
  }
  get windowStart() {
    if (!this.tablePagination || this.tablePagination.current_page <= this.onEachSide) {
      return 1;
    } else if (this.tablePagination.current_page >= this.totalPage - this.onEachSide) {
      return this.totalPage - this.onEachSide * 2;
    }

    return this.tablePagination.current_page - this.onEachSide;
  }

  get isSmallPagination() {
    if (this.pagination?.to && this.pagination?.from) {
      const numberOfItems = this.pagination.to - this.pagination.from + 1;
      return numberOfItems < 5;
    }

    return false;
  }

  loadPage(page: number) {
    this.$emit('change-page', page);
  }
}
