import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import { UserService } from './user-service';
import type { UserLocation } from '@app/models/user-location';

export class UserLocationService extends BaseService<UserLocation> {
  static readonly allowedAttributes = ['location_id'];
  static attributeNames($t: (key: string) => string): Partial<Record<keyof UserLocation, string>> {
    return {
      location_id: $t('app.labels.location'),
      user_id: $t('app.labels.user'),
    };
  }
  static readonly attributeServices: Partial<Record<keyof UserLocation, string>> = {
    location_id: 'Location',
  };

  async fetchDetails({ itemId, version }: ServiceRequest<UserLocation>): Promise<DetailsServiceResponse> {
    const userId = this.extractValue<number>('user_id', version);
    if (!userId) return {};

    const userService = new UserService(this.config);
    const userDetails = await userService.fetchDetailsById(userId);
    return { ...userDetails, subText: `ID: ${itemId}` };
  }
}
