
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { castArray } from 'lodash';
  import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';
  import type { AxiosPromise } from 'axios';
  import type { BaseEntity } from '@app/models/base-entity';

  type Id = number | string;

  @Component({})
  export default class JoinEntities<T extends BaseEntity> extends Vue {
    @Prop([Array, Number, String]) readonly ids!: Id | Id[];
    @Prop(Object) readonly params!: DonesafeIndexApiOptions<T>;
    @Prop({ type: Boolean, default: false }) readonly comma!: boolean;
    @Prop({ type: Boolean, default: false }) readonly showErrors!: boolean;

    @Prop(Function) readonly fetch!: (
      id: T['id'],
      params?: DonesafeIndexApiOptions<T>,
      config?: ApiRequestConfig & ApiRequestJoinConfig
    ) => AxiosPromise<T>;

    results: { data?: T; error?: unknown; id: T['id'] }[] = [];

    get entities() {
      return this.results.filter(({ data }) => !!data).map(({ data }) => data);
    }

    async beforeMount() {
      this.results = await Promise.all(
        castArray(this.ids).map(async (id) => {
          id = Number(id);

          try {
            return { id, ...(await this.fetch(id, this.params || {}, { cache: true, join: true })) };
          } catch (error) {
            if (!this.showErrors) {
              console.warn(error);
            }

            return { id, error };
          }
        })
      );
    }
  }
