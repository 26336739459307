
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import TextHighlight from 'vue-text-highlight';
  import type { PaperTrailVersion } from '@app/models/paper-trail-version';

  import PaperTrailsDetailLink from '../paper-trails-detail-link.vue';
  import type { DetailsLink } from '../models';
  import { toText } from '../utils';

  @Component({ components: { TextHighlight, PaperTrailsDetailLink } })
  export default class PaperTrailsUser extends Vue {
    @Prop(Object) readonly version!: PaperTrailVersion;
    @Prop(Array) readonly queries!: string[];
    @Prop(Object) readonly link!: DetailsLink;

    get transactionId(): string | undefined {
      return this.version?.event_group_uuid;
    }

    get eventGroupLabel(): string {
      if (!this.version.event_group_uuid) return '';

      return toText(
        {
          link: this.transactionId,
          prefix: this.$t('activerecord.models.event_occurrences.event_group_id'),
          title: this.version.event_group_uuid,
        },
        true
      );
    }

    get transactionIdLink(): string {
      return `/admin/settings/event_occurrences?filters[event_group_uuid]=${this.transactionId}`;
    }
  }
