import type { BaseEntity } from '@app/models/base-entity';
import type { ConfiguratorFilter } from '@app/models/configurator-filter';
import { ConfiguratorFilterSource } from '@app/models/configurator-filter';
import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';

const isFilterKeyExist = (filter: ConfiguratorFilter): boolean => {
  return !!filter.key;
};

const isFilterRequired = (filter: ConfiguratorFilter): boolean => {
  if (filter.source === ConfiguratorFilterSource.range) return !!filter?.value?.some((i) => i?.required === 'true');
  else return filter.required === 'true';
};

function isFilterValueNotSet<T extends BaseEntity>(filter: ConfiguratorFilter, filters: DonesafeFilterOptions<T> = {}): boolean {
  const key = filter?.invert === 'true' ? `!${filter.key}` : filter.key;
  const value = filters[key as keyof T];

  if (filter.source === ConfiguratorFilterSource.range) {
    return !filter.value?.every((f, ind) => (f?.required === 'true' ? !!(value as string[])?.[ind] : true));
  } else {
    return Array.isArray(value) ? !value.length : !value;
  }
}

export function incompleteNotMandatoryFilters<T extends BaseEntity>(
  filterConfigs: ConfiguratorFilter[] = [],
  filters: DonesafeFilterOptions<T> = {}
): ConfiguratorFilter[] {
  return filterConfigs.filter((f) => isFilterKeyExist(f) && !isFilterRequired(f) && isFilterValueNotSet(f, filters));
}

export function hasIncompleteNotMandatoryFilters<T extends BaseEntity>(
  filterConfigs: ConfiguratorFilter[] = [],
  filters: DonesafeFilterOptions<T> = {}
): boolean {
  return filterConfigs.some((f) => isFilterKeyExist(f) && !isFilterRequired(f) && isFilterValueNotSet<T>(f, filters));
}

export function incompleteMandatoryFilters<T extends BaseEntity>(
  filterConfigs: ConfiguratorFilter[] = [],
  filters: DonesafeFilterOptions<T> = {}
): ConfiguratorFilter[] {
  return filterConfigs
    .filter((f) => isFilterKeyExist(f) && isFilterRequired(f))
    .filter((filter) => isFilterValueNotSet<T>(filter, filters));
}
