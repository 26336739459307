
  import type { BaseEntity } from '@app/models/base-entity';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { ListManagerField } from '@app/services/list-manager/types';

  @Component({ components: {} })
  export default class BaseTableCell<T extends BaseEntity> extends Vue {
    @Prop(Object) field!: ListManagerField<T>;
    @Prop(Object) rowData!: T;

    get cellKey(): string {
      return `${this.field.name}_${this.rowData.id}`;
    }
  }
