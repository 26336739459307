import BaseResponse from '../base-response';
import type { SubFormQuestion } from '@app/models/sub-form-question';
import type { BaseQuestionOptions } from '@app/models/question-options/base-question-options';

export default class BaseQuestionResponse<Q extends BaseQuestionOptions = BaseQuestionOptions> extends BaseResponse<
  SubFormQuestion,
  SubFormQuestion<Q>
> {
  get question(): SubFormQuestion<Q> {
    return this.entity;
  }
}
