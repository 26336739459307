import { Vue, Component } from 'vue-property-decorator';

@Component
export default class WithAuditTrailModal extends Vue {
  paperTrailsModalVisibility = false;

  openPaperTrailsModal(): void {
    this.paperTrailsModalVisibility = true;
  }
}
