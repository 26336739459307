import type { ConfiguratorFilter, ConfiguratorFilterValue, DateRangeConfiguratorFilter } from '@app/models/configurator-filter';
import { ConfiguratorFilterOperator, ConfiguratorFilterSource } from '@app/models/configurator-filter';
import type { ModuleRecord } from '@app/models/module-record';
import type { ResponseValue } from '@app/models/sub-form-question';
import type { SubFormResponse } from '@app/models/sub-form-response';
import type { DonesafeFilterOptions, FilterValue } from '@app/services/donesafe-api-utils';
import type { CurrentUserStoreData } from '@app/stores/currentUser';
import moment from 'moment';
import type { ConvertInFormFiltersOptions } from './types/convert-in-form-filter-options';
import type { KeyOfType } from './types/key-of-type';
import { valueInResponse } from './value-in-response';

const getFilterValueBasedOnSource = (filter: ConfiguratorFilter, opts: ConvertInFormFiltersOptions): Maybe<FilterValue> => {
  const { useRecordDbResponses, completion, record, visitResponseValue, user, getCurrentFormValueByQuestion } = opts;

  switch (filter.source) {
    case ConfiguratorFilterSource.current_user:
      return getValueFromUser(filter, user);
    case ConfiguratorFilterSource.current_record:
      return getValueFromEntity(filter, record);
    case ConfiguratorFilterSource.current_completion:
      return getValueFromEntity(filter, completion);
    case ConfiguratorFilterSource.question_id:
      return getValueFromQuestionId(filter, useRecordDbResponses, record, getCurrentFormValueByQuestion);
    case ConfiguratorFilterSource.question_code:
      return getValueFromQuestionCode(filter, useRecordDbResponses, record, getCurrentFormValueByQuestion);
    case ConfiguratorFilterSource.registration_default:
      return getValueFromRegistrationDefault(filter, visitResponseValue);
    case ConfiguratorFilterSource.range:
      return getValueFromRange(filter, opts);
    default:
      return filter.value;
  }
};

const getValueFromUser = (filter: ConfiguratorFilter, user?: ConvertInFormFiltersOptions['user']) => {
  const userKey = filter.value as KeyOfType<
    CurrentUserStoreData,
    | 'id'
    | 'manager_id'
    | 'home_location_id'
    | 'home_organization_id'
    | 'date_of_birth'
    | 'start_date'
    | 'end_date'
    | 'organization_ids'
    | 'location_ids'
  >;

  if (userKey === 'location_ids' && user?.skip_explicit_location_restrictions) {
    return;
  }
  if (userKey === 'organization_ids' && user?.skip_explicit_organization_restrictions) {
    return;
  }
  return user?.[userKey] || undefined;
};

function getValueFromEntity<T>(filter: ConfiguratorFilter, entity?: Partial<T>) {
  return entity?.[filter.value as keyof T] || undefined;
}

const getValueFromQuestionId = (
  filter: ConfiguratorFilter,
  useRecordDbResponses: ConvertInFormFiltersOptions['useRecordDbResponses'],
  record: ConvertInFormFiltersOptions['record'],
  getCurrentFormValueByQuestion: ConvertInFormFiltersOptions['getCurrentFormValueByQuestion']
) => {
  if (useRecordDbResponses) {
    const responses = findMainFormResponses(filter, record);
    return responses?.length ? responses : undefined;
  } else {
    const currentFormValue = getCurrentFormValueByQuestion?.(filter.value as string, 'id');
    const filteredValue =
      currentFormValue && Array.isArray(currentFormValue) ? currentFormValue.filter(Boolean) : valueInResponse(currentFormValue);
    const parentValue = !filteredValue && findMainFormResponses(filter, record);
    const value = filteredValue || parentValue || [];
    const fixedValue = getFixedValue(value);
    return (Array.isArray(fixedValue) || typeof fixedValue === 'string') && !!fixedValue.length ? fixedValue : undefined;
  }
};

const getValueFromQuestionCode = (
  filter: ConfiguratorFilter,
  useRecordDbResponses: ConvertInFormFiltersOptions['useRecordDbResponses'],
  record: ConvertInFormFiltersOptions['record'],
  getCurrentFormValueByQuestion: ConvertInFormFiltersOptions['getCurrentFormValueByQuestion']
) => {
  if (useRecordDbResponses) {
    const responses = findMainFormResponses(filter, record, 'sub_form_question_code');
    return responses?.length ? responses : undefined;
  } else {
    const value = findVisibleQuestionValue(filter, record, getCurrentFormValueByQuestion);
    return value || undefined;
  }
};

const getValueFromRegistrationDefault = (
  filter: ConfiguratorFilter,
  visitResponseValue: ConvertInFormFiltersOptions['visitResponseValue']
) => {
  const visitResponseItem = visitResponseValue?.find((res) => res.id === filter.value);
  if (visitResponseItem) {
    return visitResponseItem.value;
  } else {
    return filter.value;
  }
};

const getValueFromRange = (filter: DateRangeConfiguratorFilter, opts: ConvertInFormFiltersOptions): Maybe<FilterValue> => {
  return filter.value?.reduce((memo, f) => {
    const newValue = getFilterValueBasedOnSource({ ...f, key: filter.key }, opts);
    // if the value is array, pick only the first value
    const fixedValue = Array.isArray(newValue) ? newValue[0] : newValue;
    if (typeof fixedValue === 'string') {
      if (f?.source === ConfiguratorFilterSource.current_user) {
        // correctly format the date if it's a current user value
        const formattedValue = moment(fixedValue, 'YYYY-MM-DD').format(window.DONESAFE.account?.datetimepicker_date_format);
        return [...memo, operatorDateAdjustment(formattedValue, f.operator)];
      }
      return [...memo, operatorDateAdjustment(fixedValue, f?.operator)];
    } else {
      return [...memo, ''];
    }
  }, [] as string[]);
};

export const operatorDateAdjustment = (value: FilterValue, operator?: ConfiguratorFilterOperator): string => {
  if (!!value && typeof value === 'string' && operator) {
    const dateOrDatetime = value.includes('AM') || value.includes('PM') ? 'datetime' : 'date';
    const format =
      dateOrDatetime === 'date'
        ? window.DONESAFE.account?.datetimepicker_date_format
        : window.DONESAFE.account?.datetimepicker_datetime_format;

    if (dateOrDatetime === 'date') {
      switch (operator) {
        case ConfiguratorFilterOperator.greater_than:
          return moment(value, format).add(1, 'day').format(format);
        case ConfiguratorFilterOperator.less_than:
          return moment(value, format).subtract(1, 'day').format(format);
        case ConfiguratorFilterOperator.less_than_or_equal:
        case ConfiguratorFilterOperator.greater_than_or_equal:
          return moment(value, format).format(format);
        default:
          return '';
      }
    } else {
      switch (operator) {
        case ConfiguratorFilterOperator.less_than_or_equal:
          return moment(value, format).add(1, 'minute').format(format);
        case ConfiguratorFilterOperator.greater_than:
        case ConfiguratorFilterOperator.less_than:
        case ConfiguratorFilterOperator.greater_than_or_equal:
          return moment(value, format).format(format);
        default:
          return '';
      }
    }
  } else {
    return '';
  }
};

function findVisibleQuestionValue(
  filter: ConfiguratorFilter,
  record?: Partial<ModuleRecord>,
  getCurrentFormValueByQuestion?: (filterValue: string, key: 'code') => Maybe<ResponseValue>
): ConfiguratorFilterValue | undefined {
  const currentFormValue = getCurrentFormValueByQuestion?.(filter.value as string, 'code');
  const parentValue = !currentFormValue && findMainFormResponses(filter, record, 'sub_form_question_code');
  const value = currentFormValue || parentValue;
  if (!value) {
    return;
  }
  return getFixedValue(value);
}

const findMainFormResponses = (
  filter: ConfiguratorFilter,
  record?: Partial<ModuleRecord>,
  key: keyof SubFormResponse = 'sub_form_question_id'
): string[] => {
  if (!record?.sub_form_completion?.sub_form_responses) {
    return [];
  }
  const response = record.sub_form_completion.sub_form_responses.find((r) => `${r[key]}` === `${filter.value}`);
  if (!response) {
    return [];
  }
  const array = Array.isArray(response?.response) ? response?.response : [`${valueInResponse(response?.response)}`];
  return array?.filter(Boolean) || [];
};

const getFixedValue = (value: ResponseValue): Maybe<ConfiguratorFilterValue> => {
  if (Array.isArray(value)) {
    return value.filter(Boolean);
  } else if (typeof value === 'string') {
    return value.split('|').filter(Boolean);
  } else if (typeof value === 'number') {
    return [`${value}`];
  }
};

// TODO: pass current question to prevent recursive filters
export function convertInFormFilters<T, F = {}>(
  filters: ConfiguratorFilter[] = [],
  opts: ConvertInFormFiltersOptions
): DonesafeFilterOptions<T, F> {
  const useRecordDbResponses = opts?.useRecordDbResponses || false;
  const visitResponseValue = opts?.visitResponseValue || [];

  return filters.reduce((memo, filter) => {
    const key = filter.invert === 'true' ? `!${filter.key}` : filter.key;
    const value = getFilterValueBasedOnSource(filter, {
      ...opts,
      useRecordDbResponses,
      visitResponseValue,
    });

    if (value) return { ...memo, [key]: value };
    else return memo;
  }, {}) as DonesafeFilterOptions<T, F>;
}
