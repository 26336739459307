import { BaseService } from './base-service';
import type { UserModuleRecord } from '@app/models/user-module-record';

export class UserModuleRecordService extends BaseService<UserModuleRecord> {
  static readonly allowedAttributes = ['module_name_id', 'module_record_id', 'user_id'];
  static attributeNames($t: (key: string) => string): Partial<Record<keyof UserModuleRecord, string>> {
    return {
      module_name_id: $t('app.labels.module'),
      module_record_id: $t('app.labels.record'),
      user_id: $t('app.labels.user'),
    };
  }
  static readonly attributeServices: Partial<Record<keyof UserModuleRecord, string>> = {
    module_name_id: 'ModuleName',
    module_record_id: 'ModuleRecord',
    user_id: 'User',
  };
}
