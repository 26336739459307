
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import TextHighlight from 'vue-text-highlight';
  import { attributeLabel } from '../mapping-utils';
  import type { AttributeValue } from '../models';
  import PaperTrailsAttributeValue from './paper-trails-attribute-value.vue';
  import type { PaperTrailChange, PaperTrailVersion } from '@app/models/paper-trail-version';

  @Component({ components: { TextHighlight, PaperTrailsAttributeValue } })
  export default class PaperTrailsAttribute extends Vue {
    @Prop(String) readonly field!: string;
    @Prop(Array) readonly change?: [PaperTrailChange, PaperTrailChange];
    @Prop(Array) readonly queries?: string[];
    @Prop(Object) readonly version!: PaperTrailVersion;
    @Prop(Object) readonly attributeValue!: Record<string, AttributeValue>;

    get attributeName(): string {
      return attributeLabel(this.version.item_type, this.field, this.$t);
    }

    get responseType(): string | null {
      return this.attributeValue?.entity?.responseType || this.attributeValue?.raw?.responseType || null;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    entityAttributeValueProps(index: number): Record<string, any> {
      const attributeValueProps = Object.keys(this.attributeValue).reduce((accum, attributeKey) => {
        return {
          ...accum,
          [attributeKey]:
            attributeKey === 'raw'
              ? this.attributeValue[attributeKey].changes?.[index]
              : this.attributeValue[attributeKey].entities?.[index],
          [`${attributeKey}-text`]: this.attributeValue[attributeKey].texts[index],
        };
      }, {});

      // TODO: remove this when we rewrite 'response-service' to return the same data structure as other services
      const attributeKeyLength = Object.keys(this.attributeValue).length;
      const fullResponseText = Object.entries(attributeValueProps).map(([key, value]) => {
        // include 'entity-text' if it's the only 'attributeValue' key
        if ((attributeKeyLength === 1 || key !== 'entity-text') && key.endsWith('-text')) {
          return value;
        }
      });

      return {
        ...attributeValueProps,
        fullResponseText: fullResponseText.join(''),
      };
    }
  }
