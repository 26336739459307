
  import { filter } from 'rxjs/operators';
  import { Component, Emit, Ref } from 'vue-property-decorator';
  import { hasQuestionInFilter } from '@app/utils/has-question-in-filter';
  import type {
    MultiPersonSelectorTempFieldValue,
    MultiPersonSelectorTempField,
    MultiPersonSelectorFieldValue,
  } from '@app/models/question-response-types';
  import type { UserType, TenantUser } from '@app/models/tenant-user';
  import type { RestrictionDefault } from '@app/models/restriction-default';
  import type { FieldType } from '@app/models/sub-form-question';
  import { AUTH_LOGIN_TYPES } from '@app/models/restriction-default';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { convertInFormFilters } from '@app/utils/convert-in-form-filters';
  import { incompleteMandatoryFilters } from '@app/utils/mandatory-filters';

  import ContactUserFormModal from '../user/contact-user-form-modal.vue';
  import UserSelector from '../user/user-selector.vue';
  import FilterErrors from '../admin/questions/filter-configurator/filter-errors.vue';

  import BaseField from './base-field';

  @Component({ components: { UserSelector, ContactUserFormModal, FilterErrors } })
  export default class MultiPersonSelectorField extends BaseField<FieldType.multi_person_selector> {
    @Ref() readonly userSelector!: UserSelector;

    localValue: MultiPersonSelectorFieldValue = [];
    openNewUserModal = false;
    defaultRestrictions: RestrictionDefault[] = [];

    get userType(): UserType | undefined {
      const type = this.question.config.person_type;
      if (type !== 'All') {
        return type as UserType;
      }
    }

    get isReadonly(): boolean {
      return this.readonly || this.isPublic;
    }

    get enableAddNewUser(): boolean {
      return this.question.config.enable_add_new_user === 'true';
    }

    get userLimitReached(): boolean {
      return !this.accountStore.canAddUsers && !!this.userType && !this.authRestrictedUserType;
    }

    get authRestrictedUserType(): boolean {
      return this.defaultRestrictions.length === AUTH_LOGIN_TYPES.length;
    }

    get showAddNewButton(): boolean {
      return !this.userLimitReached && !this.readonly && (this.isPublic || this.enableAddNewUser);
    }

    get randomSelectionEnabled(): boolean {
      return this.question.config.random_selection === 'true' && !this.isPublic && !this.readonly;
    }

    get mainClass(): string {
      let cols = 12;
      if (this.showAddNewButton) {
        cols -= 2;
      }
      if (this.randomSelectionEnabled) {
        cols -= 2;
      }
      return `col-md-${cols}`;
    }

    get localValueIds(): string[] {
      return this.localValue.reduce((values: string[], field: MultiPersonSelectorTempField) => {
        if (typeof field == 'string') {
          return [...values, field];
        }

        if (field.user && field.user.id) {
          return [...values, field.user.id.toString()];
        }

        return values;
      }, []);
    }

    get newUsersToBeCreated(): MultiPersonSelectorTempFieldValue[] {
      return this.localValue.reduce((values: MultiPersonSelectorTempFieldValue[], field) => {
        if (typeof field != 'string') {
          return [...values, field];
        }

        return values;
      }, []);
    }

    @Emit('input')
    onInput(value: string[]): MultiPersonSelectorTempField[] {
      this.localValue = [...value, ...this.newUsersToBeCreated];
      this.sendUpdate(this.localValue);

      return this.localValue;
    }

    @Emit('input')
    onUserInput(value: MultiPersonSelectorTempField[]): MultiPersonSelectorTempField[] {
      this.localValue = value;
      this.sendUpdate(this.localValue);

      return this.localValue;
    }

    addNewUser(): void {
      this.openNewUserModal = true;
    }

    fetchDefaultRestrictions(cache = true): Promise<void> {
      return this.$api
        .getRestrictionDefaults(
          {
            filters: { restriction: AUTH_LOGIN_TYPES, action: 'auth', restrict: true, restrict_on: this.userType },
            only: ['id', 'restrict_on', 'restriction', 'restrict', 'action'],
          },
          { cache }
        )
        .then(({ data }) => {
          this.defaultRestrictions = data;
        });
    }

    getFilters(): DonesafeFilterOptions<TenantUser> {
      const filters = {
        ...this.question.config.required_filters,
        ...convertInFormFilters<TenantUser>(this.question.config.filters, {
          record: this.record,
          user: this.currentUserStore.data,
          getCurrentFormValueByQuestion: this.getCurrentFormValueByQuestion,
        }),
        type: this.userType,
        active: true,
      };

      return filters;
    }

    onOpen(): boolean {
      !this.defaultTemplating && this.setIncompleteFilters(incompleteMandatoryFilters(this.question.config.filters, this.getFilters()));
      return !this.incompleteFilters.length;
    }

    randomChanged(value: number): void {
      if (value) {
        this.$api
          .getTenantUsers({
            per_page: value,
            sort: 'random',
            include: ['secondary_information'],
            only: ['id', 'full_name', 'secondary_information'],
            filters: { type: this.userType },
          })
          .then(({ data }) => {
            this.onInput(data.map((u: Pick<TenantUser, 'id' | 'full_name' | 'secondary_information'>) => `${u.id}`));
            this.userSelector?.setOptions(data);
          });
      } else {
        this.onInput([]);
      }
    }

    beforeMount(): void {
      this.localValue = (Array.isArray(this.value) && this.value) || [];

      if (this.localValue.length) {
        this.onUserInput(this.localValue);
      }

      this.fetchDefaultRestrictions();
    }

    mounted(): void {
      this.addSubscription(
        this.formObservers.fieldUpdate$
          .pipe(filter(([question]) => hasQuestionInFilter(question, this.question.config.filters)))
          .subscribe(() => this.clearIncompleteFilters())
      );
      this.sendUpdate(this.localValue, true);
    }

    onCreateUser(user: TenantUser): void {
      this.userSelector?.addOption(`${user.id}`, user.full_name, true);
      this.onUserInput([...this.localValue, { user: { ...user, type: this.userType } }]);
    }
  }
