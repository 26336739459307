
  import { snakeCase } from 'lodash';
  import TextHighlight from 'vue-text-highlight';
  import { Component } from 'vue-property-decorator';
  import type { BaseEntity } from '@app/models/base-entity';
  import { deleteTranslationPostfix } from '../mapping-utils';
  import PaperTrailsDetailLinks from '../paper-trails-detail-links.vue';
  import { extractPaperTrailVersionValue } from '../utils';
  import BaseDetails from './base-details';

  @Component({ components: { TextHighlight, PaperTrailsDetailLinks } })
  export default class TranslationWithLinksDetails extends BaseDetails {
    get baseType(): string {
      return deleteTranslationPostfix(this.type);
    }

    get itemId(): BaseEntity['id'] {
      const key = `${snakeCase(this.baseType)}_id` as keyof BaseEntity;
      return extractPaperTrailVersionValue(key, this.version) as BaseEntity['id'];
    }
  }
