
  import type { Signature } from '@app/models/signature';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import Select2 from '@app/components/select2.vue';
  import { useAccountStore } from '@app/stores/account';
  import { MessageBox } from 'uiv';
  import { toaster } from '@app/utils/toaster';
  import type { TenantUser } from '@app/models/tenant-user';

  @Component({
    components: { Select2 },
  })
  export default class UserPrimaryLanguage extends Vue {
    @Prop() readonly user!: TenantUser;

    currentSignature: Nullable<Signature> = null;
    language: string | undefined = '';
    submitting = false;

    get languageOptions(): [string, string][] {
      return this.accountStore.languages.map((languageId) => [languageId, `${window.DONESAFE.LANGUAGES[languageId]} [${languageId}]`]);
    }

    get accountStore() {
      return useAccountStore();
    }

    onLanguageChange() {
      if (this.user.language !== this.language) {
        MessageBox.confirm({
          title: this.$t('app.user_details.language'),
          content: `${this.$t('app.user_details.language_confirmation')}`,
        })
          .then(() => {
            this.onLanguageConfirmed();
          })
          .catch(() => {
            this.language = this.user.language;
          });
      }
    }

    onLanguageConfirmed() {
      const params = { language: this.language } as Partial<TenantUser>;
      this.submitting = true;
      if (this.user) {
        this.$api
          .updateTenantUser(this.user.id, { ...params })
          .then(() => {
            this.$api.cache.clear();
            toaster({
              text: this.$t('portal.users.update.user_successfully_updated'),
              position: 'top-right',
            });
            window.location.reload();
          })
          .catch(({ data }) => {
            toaster({ text: data?.error, position: 'top-right', icon: 'error' });
          })
          .finally(() => {
            this.submitting = false;
          });
      }
    }

    mounted(): void {
      this.language = this.user.language;
    }
  }
