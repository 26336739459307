
  import type { DatetimeFieldValue } from '@app/models/question-response-types';
  import moment from 'moment';
  import { filter } from 'rxjs/operators';
  import { mixins } from 'vue-class-component';
  import { Component, Emit } from 'vue-property-decorator';
  import { WithDateCalculations } from '@app/mixins/with-date-calculations';
  import type { Timezone } from '@app/models/timezone';
  import { FieldType } from '@app/models/sub-form-question';
  import { valueInResponse } from '@app/utils/value-in-response';

  import Select2 from '../select2.vue';
  import DateTimePicker from '../date-time-picker.vue';

  import BaseField from './base-field';

  @Component({ components: { Select2, DateTimePicker } })
  export default class DatetimeField extends mixins<BaseField<FieldType.datetime>, WithDateCalculations<FieldType.datetime>>(
    BaseField,
    WithDateCalculations
  ) {
    localValue: DatetimeFieldValue = { value: '' };
    limits: [Nullable<Date>, Nullable<Date>] = [null, null];

    timezones: Timezone[] = [];

    get dateTimeFormat(): Maybe<string> {
      return this.accountStore.data.datetimepicker_datetime_format;
    }

    get dateTimeValue(): Maybe<Date> {
      return this.stringToDateTime(this.localValue.value);
    }

    get dateTimeZone(): Maybe<string> {
      return this.localValue.timezone;
    }

    get minDate(): Maybe<Date> {
      return this.limits[0] || undefined;
    }

    get maxDate(): Maybe<Date> {
      return this.limits[1] || undefined;
    }

    get currentAccountTimezone(): Maybe<string> {
      return this.accountStore.data.timezone_identifier;
    }

    get isReadonly(): boolean {
      return this.readonly || (!(this.minDate || this.maxDate) && this.isRestricted);
    }

    get isRestricted(): boolean {
      return !!this.question.config.restrict_to?.restrict_type && this.question.config.restrict_to?.if_empty === 'disable';
    }

    get inFormRestrictedByCode(): Maybe<string> {
      if (this.question.config.restrict_to?.restrict_type === 'in_form') {
        return this.question.config.restrict_to.sub_form_question_code;
      }
    }

    get syncWithTimezone(): boolean {
      return this.question.config.sync_with_timezone === 'true';
    }

    @Emit('input')
    onDateTimeChange(date: Maybe<Date>): DatetimeFieldValue {
      const value = date && moment(date).format(this.dateTimeFormat);
      this.localValue = { value, timezone: this.localValue.timezone };
      this.formObservers.dateUpdate$.next([this.question, date]);
      return this.sendUpdate(this.localValue);
    }

    @Emit('input')
    changeTimezone(timezone: Maybe<string>): DatetimeFieldValue {
      let value = this.localValue.value;
      if (value && this.syncWithTimezone) {
        const timezoneObject = this.timezones.find((tz) => tz.name === timezone);
        const previousTimezoneObject = this.timezones.find((tz) => tz.name === (this.localValue.timezone || this.currentAccountTimezone));
        if (previousTimezoneObject && timezoneObject) {
          const diff = timezoneObject.utc_offset - previousTimezoneObject.utc_offset;
          const dateTimeMoment = moment(this.dateTimeValue).add(diff, 'seconds');
          value = dateTimeMoment.format(this.dateTimeFormat);
          diff !== 0 && !!this.question.config.restrict_to?.restrict_type && this.setLimits(this.stringToDateTime(value));
        }
      }
      this.localValue = { value, timezone };
      return this.sendUpdate(this.localValue);
    }

    stringToDateTime(value?: string): Maybe<Date> {
      return (value && moment(value, this.dateTimeFormat).toDate()) || undefined;
    }

    onDateCalculationsChange(date: Maybe<Date>): void {
      this.onDateTimeChange(date);
      this.calculationInProgress = false;
    }

    beforeMount(): void {
      this.localValue = { ...this.value };
    }

    setLimits(date?: Date): void {
      this.limits = [null, null];
      if (date) {
        switch (this.question.config.restrict_to?.operator) {
          case '<':
            this.limits = [null, moment(date).subtract(1, 'second').toDate()];
            break;
          case '<=':
            this.limits = [null, date];
            break;
          case '>':
            this.limits = [moment(date).add(1, 'second').toDate(), null];
            break;
          case '>=':
            this.limits = [date, null];
            break;
        }
      }
    }

    mounted(): void {
      if (this.inFormRestrictedByCode) {
        this.addSubscription(
          this.formObservers.dateUpdate$
            .pipe(filter(([question]) => question.code == this.inFormRestrictedByCode))
            .subscribe(([, date]) => this.setLimits(date))
        );
        const value = valueInResponse(this.dataByCode?.[this.inFormRestrictedByCode]) as string;
        this.setLimits(this.stringToDateTime(value));
      } else {
        this.$api
          .getLimits(
            {
              sub_form_completion_id: this.completion?.id,
              record_id: this.completion?.record_id,
              record_type: this.completion?.record_type,
              sub_form_id: this.subFormId,
              sub_form_list_id: this.completion?.sub_form_list_id,
              approval_for_sub_form_completion_id: this.completion?.approval_for_sub_form_completion_id,
            },
            { cache: true }
          )
          .then(({ data }) => {
            const limits = data[this.question.id] || [null, null];
            this.limits = [(limits[0] && moment(limits[0]).toDate()) || null, (limits[1] && moment(limits[1]).toDate()) || null];
          });
      }

      this.$nextTick(() => this.formObservers.dateUpdate$.next([this.question, this.dateTimeValue]));
      this.sendUpdate(this.localValue, true);

      if (this.accountStore.complexTimezonesEnabled) {
        this.fetchTimezones().then(() => {
          if (this.question.config.sub_form_question_system_code) {
            const locationId = this.$form
              .find(`[data-question-system-code="${this.question.config.sub_form_question_system_code}"`)
              .val() as string;
            if (locationId) {
              this.fillTimezoneFromLocationId(Number(locationId));
            } else if (!this.localValue.timezone) {
              this.changeTimezone(this.currentAccountTimezone);
            }

            this.addSubscription(
              this.formObservers.fieldUpdate$
                .pipe(
                  filter(
                    ([question, value]) =>
                      question.field_type === FieldType.location &&
                      `${question.system_code}` === this.question.config.sub_form_question_system_code &&
                      value?.value
                  )
                )
                .subscribe(([, { value }]) => this.fillTimezoneFromLocationId(value))
            );
          } else if (!this.localValue.timezone) {
            this.changeTimezone(this.currentAccountTimezone);
          }
        });
      }

      this.initDateCalculations(this.subscriptions, this.formObservers);
    }

    fetchTimezones(): Promise<void> {
      return this.$api.getTimezones({ only: ['name', 'title', 'utc_offset'] }, { cache: true }).then(({ data }) => {
        this.timezones = data;
      });
    }

    fillTimezoneFromLocationId(locationId: number): void {
      this.$api.getLocation(Number(locationId), { only: ['id', 'timezone'] }, { cache: true }).then(({ data }) => {
        const timezone = data.timezone || this.currentAccountTimezone;
        this.changeTimezone(timezone);
      });
    }
  }
