
  import { useAccountStore } from '@app/stores/account';
  import DsLabel from '@app/components/ds-label.vue';
  import { entityPathPrefix } from '@app/services/helpers';
  import type { AxiosPromise } from 'axios';
  import { Component, Prop, Watch } from 'vue-property-decorator';
  import type { QuestionSfcOnly } from '../sub-form-completion/utils';
  import type { FieldType, QuestionTypeMap, SubFormQuestion } from '@app/models/sub-form-question';
  import type { ModuleEntity } from '@app/models/module-entity';
  import type { ModuleName } from '@app/models/module-name';
  import type { ModuleRecord } from '@app/models/module-record';
  import { HARDCODED_MODULE_CODES } from '@app/models/module-name';
  import type { OnlyOptions } from '@app/services/donesafe-api-utils';
  import type { StrictUnion } from '@app/utils/types/strict-union';
  import { Tuple } from '@app/utils/types/tuple';
  import { mixins } from 'vue-class-component';
  import Blocking from '@app/mixins/blocking';

  const SUPPORTED_HARDCODED_MODULES = ['Audit', 'Hazard', 'Incident'];

  type Filter = { endDate?: string; startDate?: string };

  @Component({ components: { DsLabel } })
  export default class ReportTable extends mixins(Blocking) {
    @Prop(Object) readonly question!: Pick<SubFormQuestion<QuestionTypeMap<FieldType.report>['options']>, QuestionSfcOnly>;
    @Prop(Object) readonly filter!: Filter;
    @Prop({ type: [String, Number] }) readonly locationId?: string | number | null; // legacy modules only

    records: StrictUnion<ModuleRecord | ModuleEntity>[] = [];
    relatedModuleName: Nullable<Pick<ModuleName, 'plural_display'>> = null;
    loading = false;

    get accountStore() {
      return useAccountStore();
    }

    get configModuleName(): string {
      return this.question.config.module_name;
    }

    get supportedHardcodedModule(): boolean {
      return SUPPORTED_HARDCODED_MODULES.includes(this.configModuleName);
    }

    get noRecordsMessage(): Nullable<string> {
      return this.relatedModuleName
        ? `${this.$t('tenant.sub_form_completions.form_fields.show.report.there_were_no')} ${
            this.relatedModuleName.plural_display
          } ${this.$t('tenant.sub_form_completions.form_fields.show.report.within_the_selected_dates')}`
        : null;
    }

    get showReport(): boolean {
      return (!HARDCODED_MODULE_CODES.includes(this.configModuleName) || this.supportedHardcodedModule) && this.filterIsSet;
    }

    get filterIsSet(): boolean {
      const { startDate, endDate } = this.filter;

      return !!startDate && !!endDate;
    }

    get onlyOptions(): OnlyOptions<ModuleEntity> | OnlyOptions<ModuleRecord> {
      const base = Tuple(['id', 'title', 'created_at'] as const);

      if (this.configModuleName === 'Hazard') {
        return [...base, 'hazard_type_name', 'workflow'];
      } else if (this.configModuleName === 'Incident') {
        return [...base, 'incident_type_name', 'workflow'];
      } else if (this.configModuleName === 'Audit') {
        return [...base, 'observe_template_name', 'workflow'];
      }
      return [...base, 'workflow', 'uniq_id', { module_name: ['id', 'display'] }];
    }

    @Watch('filter')
    onStartDateToWatchChanged(filter: Filter): void {
      if (!!filter.startDate && !!filter.endDate) {
        this.fetchRecords();
      }
    }

    getHardcodedModuleUniqId(id: number): string {
      return `${this.configModuleName.slice(0, 3).toUpperCase()}${`${id}`.padStart(4, '0')}`;
    }

    printLink(record: ModuleRecord | ModuleEntity): string {
      return `/${entityPathPrefix(this.supportedHardcodedModule ? this.configModuleName : 'ModuleRecord')}/${record.id}`;
    }

    printDate(record: ModuleRecord | ModuleEntity) {
      return this.accountStore.dateFormat(record.created_at, { serverTimezoneAware: true });
    }

    printType(record: StrictUnion<ModuleRecord | ModuleEntity>): string {
      if (this.configModuleName === 'Hazard') {
        return record?.hazard_type_name || '';
      } else if (this.configModuleName === 'Incident') {
        return record?.incident_type_name || '';
      } else if (this.configModuleName === 'Audit') {
        return record?.observe_template_name || 'N/A';
      } else {
        return record?.module_name?.display || '';
      }
    }

    fetchRecords(): void {
      this.blocking(async () => {
        const { data: records } = await this.fetchRecordsPromise();
        this.records = records;
      });
    }

    fetchRecordsPromise(): AxiosPromise<StrictUnion<ModuleEntity | ModuleRecord>[]> {
      const { startDate: date_start, endDate: date_end, ...rest } = this.filter;
      const created_at = [date_start, date_end];

      if (this.supportedHardcodedModule) {
        return this.$api.getModuleEntities(
          {
            filters: {
              record_type: this.configModuleName,
              date_start,
              date_end,
              ...(!!this.locationId ? { location_id: this.locationId } : {}),
            },
            only: this.onlyOptions as OnlyOptions<ModuleEntity>,
            per_page: -1,
          },
          { cache: true }
        );
      } else {
        return this.$api.getModuleRecords(
          {
            filters: {
              module_name: {
                name: this.configModuleName,
              },
              created_at,
              ...rest,
            },
            only: this.onlyOptions as OnlyOptions<ModuleRecord>,
            per_page: -1,
          },
          { cache: true }
        );
      }
    }

    async beforeMount(): Promise<void> {
      if (this.question.config?.module_name) {
        const {
          data: [moduleName],
        } = await this.$api.getModuleNames({ filters: { name: this.configModuleName }, only: 'plural_display' }, { cache: true });

        this.relatedModuleName = moduleName;
      }

      if (this.showReport) {
        this.fetchRecords();
      }
    }
  }
