
  import BaseFieldDisplay from './base-field-display';
  import MatrixField from '@app/components/form-fields/matrix-field.vue';
  import type { FieldType } from '@app/models/sub-form-question';
  import { Component } from 'vue-property-decorator';
  import DsPopover from '@app/components/ds-popover.vue';
  import type { Matrix } from '@app/models/matrix';

  @Component({ components: { MatrixField, DsPopover } })
  export default class MatrixDisplay extends BaseFieldDisplay<FieldType.matrix> {
    matrix: Nullable<Matrix> = null;

    async beforeMount() {
      const { data: matrix } = await this.$api.getMatrix(this.question.config.matrix_id, {}, { cache: true, join: true });

      this.matrix = matrix;
    }

    get matrixCellOutcome() {
      const { matrix_cell_outcome_id: rspId } = this.subFormResponse.response;

      return this.matrix?.outcomes?.find(({ matrix_cell_outcome_id }) => `${matrix_cell_outcome_id}` === `${rspId}`);
    }

    get hasResponse(): boolean {
      return !!this.matrixCellOutcome?.score;
    }

    get responsePrintValue(): string {
      const { score, outcome } = this.matrixCellOutcome || {};

      return this.hasResponse ? this.$t('app.labels.matrix_with_score_and_outcome', { score, outcome }) : this.$t('app.labels.na');
    }
  }
