
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import BaseTable from '@app/components/base-table/base-table.vue';
  import FilterSelect from '@app/components/filter-select.vue';
  import LocationSelector from '@app/components/location/location-selector.vue';
  import LocationTagSelector from '@app/components/location/location-tag-selector.vue';
  import UserLocationTagFormModal from '@app/components/user-location-tags/user-location-tag-form-modal.vue';
  import type { UserLocationTag } from '@app/models/user-location-tag';
  import type { DonesafeLocationsApiExtraOptions } from '@app/services/api/locations-api';
  import bootbox from 'bootbox';
  import { Component, Ref } from 'vue-property-decorator';
  import type { Location } from '@app/models/location';
  import { AdminPermissionFeature } from '@app/models/admin-permission';
  import type { DonesafeFilterOptions, OnlyOptions } from '@app/services/donesafe-api-utils';
  import type { ListManagerField } from '@app/services/list-manager/types';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';
  import DsLabel from '@app/components/ds-label.vue';
  import { mixins } from 'vue-class-component';
  import WithUser from '@app/mixins/with-user';

  @Component({
    components: {
      DsLabel,
      UserLocationTagFormModal,
      FilterSelect,
      LocationSelector,
      LocationTagSelector,
      BaseTable,
    },
  })
  export default class UserTagsPage extends mixins(WithUser) {
    @Ref() readonly table?: BaseTable<UserLocationTag>;

    modalVisible = false;
    newUserLocationTag: Partial<UserLocationTag> = {};

    manager = new ListManager<UserLocationTag>({
      fetchDataFunction: (params) => {
        const only: OnlyOptions<UserLocationTag> = ['id', 'location_id', 'location_tag_id', 'location_tag', 'location'];
        const filters = { ...params.filters, user_id: this.userId };
        return this.$api.getUserLocationTags({ ...params, only, filters }, { cache: true });
      },
      per_page: 25,
      fields: this.fields,
      allowFilters: true,
    });

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get locationFilters(): DonesafeFilterOptions<Location, DonesafeLocationsApiExtraOptions> {
      if (this.currentUserStore.hasUsersLocationsOrganizationsPermission) {
        return {};
      }
      return { active: true };
    }

    get canEditUserTags(): boolean {
      return (
        this.currentUserStore.hasUsersLocationsOrganizationsPermission ||
        this.currentUserStore.checkProfilePermission({ id: this.userId }, 'tags', 'edit')
      );
    }

    get fields(): ListManagerField[] {
      const base = [
        {
          title: 'Tag',
          name: 'location_tag',
          sortField: 'location_tag.name',
          filter: true,
        },
        {
          title: 'Tag Type',
          name: 'location_tag_type',
          sortField: 'location_tag.name',
        },
        {
          title: 'Location',
          name: 'location',
          sortField: 'location.name',
          filter: true,
        },
      ];

      if (this.canEditUserTags) {
        return [...base, { title: '', name: 'operations' }];
      }
      return base;
    }

    createNewUserLocationTag(form: Pick<UserLocationTag, 'user_id' | 'location_tag_id' | 'location_id'>): void {
      this.$api
        .createUserLocationTag({ ...form, location_id: form.location_id || null })
        .then(() => {
          this.$api.cache.clear();
          this.table?.reload();
          this.modalVisible = false;
        })
        .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
    }

    showModal(): void {
      this.newUserLocationTag = { user_id: this.userId };
      this.modalVisible = true;
    }

    removeUserLocationTag(userLocationTag: UserLocationTag): void {
      bootbox.confirm({
        backdrop: false,
        size: 'small',
        message: this.$t('app.labels.are_you_sure'),
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          if (result) {
            this.$api
              .deleteUserLocationTag(userLocationTag.id)
              .then(() => {
                this.$api.cache.clear();
                this.table?.reload();
              })
              .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
          }
        },
      });
    }

    canEditTag(userLocationTag: UserLocationTag): boolean {
      return (
        this.currentUserStore.featureEnabled(AdminPermissionFeature.system_administration) || !userLocationTag.location_tag?.admin_only
      );
    }
  }
