
  import { Component } from 'vue-property-decorator';
  import TextHighlight from 'vue-text-highlight';
  import BaseResponse from './base-response';
  import type { Dashboard } from '@app/models/dashboard';

  @Component({ components: { TextHighlight } })
  export default class DashboardResponse extends BaseResponse<Dashboard> {
    get link(): Nullable<string> {
      if (this.currentUserStore.hasReportingPermission) {
        return `/admin/settings/dashboards/${this.entity?.id}`;
      } else {
        return null;
      }
    }
  }
