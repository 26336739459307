
  import type { SelectOption } from '@app/models/question-options/shared';
  import { Component, Prop } from 'vue-property-decorator';
  import BaseQuestionResponse from './base-question-response';
  import TextHighlight from 'vue-text-highlight';

  @Component({ components: { TextHighlight } })
  export default class SingleSelectFieldResponse extends BaseQuestionResponse {
    @Prop(Object) readonly value?: SelectOption & { score?: string };
    @Prop(String) readonly valueText?: string;
  }
