
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { useAccountStore } from '@app/stores/account';
  import DateTimePicker from '@app/components/date-time-picker.vue';
  import EntitySelector from '@app/components/entity-selector.vue';
  import Select2 from '@app/components/select2.vue';
  import DatePicker from '@app/components/date-picker.vue';
  import UserSelector from '@app/components/user/user-selector.vue';
  import type { ExtraUserApiOptions } from '@app/services/api/tenant-users-api';
  import moment from 'moment';
  import { ValidationProvider, ValidationObserver } from 'vee-validate';
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import type { ActionPriority } from '@app/models/action-priority';
  import type { Activity } from '@app/models/activity';
  import type { SubFormCompletion } from '@app/models/sub-form-completion';
  import type { TenantUser } from '@app/models/tenant-user';
  import { ActionType, HazardControlCategory, HazardControlType } from '@app/models/activity';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { enumToOptions } from '@app/utils/enumToOptions';

  import formatPriorityOption from './format-priority-option';
  import { canEditContent } from './utils';

  @Component({
    components: {
      DateTimePicker,
      EntitySelector,
      Select2,
      UserSelector,
      DatePicker,
      ValidationProvider,
      ValidationObserver,
    },
  })
  export default class ActivityForm extends Vue {
    @Prop(Object) readonly activity?: Partial<Activity>;
    @Prop(Boolean) readonly hideCompletionSelector?: boolean;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    bulkAdd = false;
    submitting = false;
    priorities: ActionPriority[] = [];
    form: Partial<Omit<Activity, 'date' | 'closed_at'> & { assignee_ids: number[]; closed_at?: Date; date?: Date }> = {};

    get disableApprovalField(): boolean {
      return !(!this.form.id || canEditContent(this.form.user_id) || (this.form.can_edit && this.isCreator) || !this.approvalSystemEnabled);
    }

    get approvalSystemEnabled(): boolean {
      return !!this.activity?.requires_approval || this.accountStore.data.enable_action_approvals;
    }

    get canApprove(): boolean {
      return !!this.form.can_approve;
    }

    get isCreator(): boolean {
      return this.form.user_id === this.currentUserStore.data?.id;
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get accountStore() {
      return useAccountStore();
    }

    get allowBulkAdd(): boolean {
      return this.accountStore.data.action_options.bulk_add === 'true';
    }

    get showPrioritiesSelector(): boolean {
      if (!this.prioritiesVisible) return false;

      if (!this.priorities.length) return false;

      if (!this.prioritiesEditable) return !!this.form.action_priority_id;

      return true;
    }

    get prioritiesEditable(): boolean {
      return this.accountStore.data.action_options.allow_editing_action_priorities === 'true';
    }

    get prioritiesVisible(): boolean {
      return this.accountStore.data.action_options.show_action_priorities === 'true';
    }

    get currentUser() {
      return useCurrentUserStore().data;
    }

    get recordExists(): boolean {
      return !!this.form.id;
    }

    get disableContentFields(): boolean {
      return this.recordExists && !canEditContent(this.form.user_id);
    }

    get userSelectorFilters(): DonesafeFilterOptions<TenantUser, ExtraUserApiOptions> {
      const filters = { active: true };
      switch (this.accountStore.data.user_selector_filter_restrictions) {
        case 'accessible_by_active_user':
          return {
            ...filters,
            home_location_accessible_by_user_id: this.currentUserStore.data?.id,
            home_organization_accessible_by_user_id: this.currentUserStore.data?.id,
          };
        case 'accessible_to_active_user':
          return {
            ...filters,
            accessible_location_id: this.currentUserStore.data?.home_location_id,
            accessible_organization_id: this.currentUserStore.data?.home_organization_id,
          };
        default:
          return filters;
      }
    }

    get subFormCompletionsFilter(): DonesafeFilterOptions<SubFormCompletion> {
      if (this.linkToSubFormCompletion && this.form.actionable_id && this.form.actionable_type) {
        return {
          record_id: this.form.actionable_id,
          record_type: this.form.actionable_type,
        };
      }
      return {};
    }

    get linkToSubFormCompletion(): boolean {
      return (
        !this.hideCompletionSelector &&
        this.form.actionable_type === 'ModuleRecord' &&
        this.accountStore.data.action_options.link_to_sub_form_completion === 'true'
      );
    }

    get defaultRequireCompletionComment(): boolean {
      return this.accountStore.data.action_options.completion_comment?.default === 'true';
    }

    get canChangeRequireCompletionComment(): boolean {
      return this.accountStore.data.action_options.completion_comment?.hide !== 'true';
    }

    get isTypeIncident(): boolean {
      return this.form.actionable_type === 'Incident';
    }

    get extraControls(): boolean {
      return this.form.actionable_type === 'Hazard';
    }

    get actionTypeOptions(): [string, string][] {
      return enumToOptions(ActionType);
    }

    get categoryTypeOptions(): [string, string][] {
      return enumToOptions(HazardControlCategory);
    }

    get controlTypeOptions(): [string, string][] {
      return enumToOptions(HazardControlType);
    }

    async beforeMount(): Promise<void> {
      this.reset();
      await this.loadPriorities();
      this.reset();
    }

    async loadPriorities(): Promise<ActionPriority[]> {
      const { data: priorities } = await this.$api.getActionPriorities(
        {
          sort: '-active,index',
          filters: { active: true },
          per_page: -1,
        },
        { cache: true }
      );

      this.priorities = priorities;
      return this.priorities;
    }

    formatPriorityOption(option?: ActionPriority): JQuery<HTMLElement> {
      return formatPriorityOption({ $t: this.$t, showColor: true })(option) as JQuery<HTMLElement>;
    }

    reset(): void {
      const defaultActionPriority = this.priorities.find(({ is_default }) => is_default);

      this.form = {
        require_completion_comment: this.defaultRequireCompletionComment,
        action_priority_id: defaultActionPriority?.id,
        assignee_id: this.currentUserStore.data?.id,
        ...(this.activity || {}),
        date: (this.activity?.date && moment(this.activity.date).toDate()) || undefined,
        closed_at: (this.activity?.closed_at && moment(this.activity.closed_at).toDate()) || undefined,
      };
      this.validator?.reset();
    }

    onContributingTypeChange(): void {
      this.form = {
        ...this.form,
        contributing_subtype_id: undefined,
      };
    }

    submit(): void {
      if (this.validator && !this.submitting) {
        this.submitting = true;
        this.validator
          .validate()
          .then((result) => {
            result &&
              this.$emit('submit', {
                ...this.form,
                action_priority_id: this.form.action_priority_id || null,
                sub_form_completion_id: this.form.sub_form_completion_id || null,
                date: this.form.date && moment(this.form.date).format('YYYY-MM-DD'),
                closed_at: this.form.closed_at && moment(this.form.closed_at).toDate(),
                assigned_approver_id: this.form.assigned_approver_id || null,
              });
          })
          .finally(() => {
            this.submitting = false;
          });
      }
    }

    toggleBulkAdd(): void {
      this.bulkAdd = !this.bulkAdd;
      this.form = {
        ...this.form,
        assignee_id: undefined,
        assignee_ids: undefined,
      };
    }
  }
