
  import type { SingleSelectFieldValue } from '@app/models/question-response-types';
  import { Component, Emit } from 'vue-property-decorator';
  import { mixins } from 'vue-class-component';
  import HighlightColours from '@app/mixins/highlight-colours';
  import type { FieldType, SubFormQuestion } from '@app/models/sub-form-question';
  import { ExpensingMethod } from '@app/models/sub-form-question';

  import BaseField from './base-field';

  @Component
  class BaseButtonSelectField extends BaseField<FieldType.button_select> {}

  @Component({ components: {} })
  export default class ButtonSelectField extends mixins(BaseButtonSelectField, HighlightColours) {
    localValue: SingleSelectFieldValue = { value: '' };

    get questionId(): string {
      return `button-select${this.question.id}`;
    }

    get values(): SubFormQuestion['options']['values'] {
      return this.question.options.values;
    }

    get showClearButton(): boolean {
      return (
        (this.defaultTemplating || (!this.readonly && this.question.config.allow_clear_response === 'true')) && !!this.localValue.value
      );
    }

    get expensableMethodSelect(): boolean {
      return this.question.code === ExpensingMethod.question_code;
    }

    @Emit('input')
    onSelectKey(key?: string): SingleSelectFieldValue {
      if (this.expensableMethodSelect && this.localValue.value != key) {
        // clear expensable MFR and in turn clear ExpenseBudgetSelect and clear ExpenseBudgetCategory to
        // avoid a problem with multiple expensable MFR(s) doing show/hide
        this.$nextTick(() => this.formObservers.clearExpensableMainFormRelation$.next());
      }
      this.localValue = { value: key };
      this.sendUpdate(this.localValue);
      // BUG5372
      $(this.$el)
        .find(`${this.name}[value]`)
        .each((i, e) => $(e).parsley().reset());
      return this.localValue;
    }

    beforeMount(): void {
      this.localValue = { value: this.value?.value };
    }

    mounted(): void {
      if (this.expensableMethodSelect) {
        this.addSubscription(
          this.formObservers.requestExpensableMainFormRelationBroadcast$.subscribe(() =>
            this.$nextTick(() => this.sendUpdate(this.localValue))
          )
        );
        this.$nextTick(() => this.formObservers.requestExpensableMainFormRelationBroadcast$.next());
      }
      this.listenOnCodeValueUpdate((value) => this.onSelectKey(`${value}`));
    }
  }
