import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { Saml } from '@app/models/saml';

export class SamlService extends BaseService<Saml> {
  static readonly allowedAttributes = [
    'service_name',
    'system_default',
    'issuer',
    'name_identifier_format',
    'request_attributes_field',
    'force_downcase_request_attributes_field',
    'active',
    'idp_sso_target_url',
    'idp_slo_target_url',
    'idp_cert',
    'sp_signed',
    'user_key',
    'sso_clock_drift_in_seconds',
    'skip_destination_validation',
  ];

  static attributeNames($t: (key: string) => string): Partial<Record<keyof Saml, string>> {
    return {
      service_name: $t('tenant.admin.settings.samls.form.service_name'),
      system_default: $t('tenant.admin.settings.samls.form.system_default'),
      issuer: $t('tenant.admin.settings.samls.form.issuer'),
      name_identifier_format: $t('tenant.admin.settings.samls.form.name_identifier_format'),
      request_attributes_field: $t('tenant.admin.settings.samls.form.saml_response_value_to_use'),
      force_downcase_request_attributes_field: $t('tenant.admin.settings.samls.form.force_downcase_request_attributes_field'),
      active: $t('tenant.admin.settings.samls.form.active'),
      idp_sso_target_url: $t('tenant.admin.settings.samls.form.idp_sso_target_url'),
      idp_slo_target_url: $t('tenant.admin.settings.samls.form.idp_slo_target_url'),
      idp_cert: $t('tenant.admin.settings.samls.form.idp_cert'),
      sp_signed: $t('tenant.admin.settings.samls.form.sp_signed'),
      user_key: $t('tenant.admin.settings.samls.form.user_key'),
      sso_clock_drift_in_seconds: $t('tenant.admin.settings.samls.form.sso_clock_drift_in_seconds'),
      skip_destination_validation: $t('tenant.admin.settings.samls.form.skip_destination_validation'),
    };
  }

  async fetchDetails({ itemId }: ServiceRequest<Saml>): Promise<DetailsServiceResponse> {
    const { data: saml } = await this.api.getSaml(itemId, { only: ['id', 'service_name'] }, { cache: true });
    return {
      // TODO: prefix?
      links: [
        {
          link: `/admin/settings/samls/${saml.id}/edit`,
          title: saml.service_name,
          id: saml.id,
        },
      ],
    };
  }
}
