
  import UserProfileModalInstance from '@app/components/admin/users/user-profile-modal-instance.vue';
  import type { TenantUser } from '@app/models/tenant-user';
  import { useAccountStore } from '@app/stores/account';
  import moment from 'moment';
  import { Component, Emit } from 'vue-property-decorator';
  import type { TimesheetFieldValue, TimesheetTime } from '@app/models/question-response-types';
  import type { FieldType } from '@app/models/sub-form-question';
  import type { Involvement } from '@app/models/involvement';
  import type { UserInvolvement } from '@app/models/user-involvement';
  import { ShiftType } from '@app/models/shift';

  import ActualTimesheet from '../timesheet-shifts/actual-timesheet.vue';
  import ExpectedTimesheet from '../timesheet-shifts/expected-timesheet.vue';
  import UserImageLink from '../user-image-link.vue';
  import DatePicker from '../date-picker.vue';

  import BaseField from './base-field';

  const VALID_DATE_FORMAT = 'DD/MM/YYYY';
  const VALID_DATEPICKER_FORMAT = 'dd/mm/yyyy';

  @Component({
    components: { UserImageLink, DatePicker, ExpectedTimesheet, ActualTimesheet },
  })
  export default class TimesheetField extends BaseField<FieldType.timesheet> {
    userInvolvement: UserInvolvement | null = null;
    involvement: Involvement | null = null;
    localValue: TimesheetFieldValue = {};
    expectedTimesheetKey = 0;
    actualTimesheetKey = 0;

    get dateFormat(): string {
      return VALID_DATE_FORMAT;
    }

    get datepickerFormat(): string {
      return VALID_DATEPICKER_FORMAT;
    }

    get isActual(): boolean {
      return this.question.config.timesheet_type === ShiftType.actual;
    }

    get accountStore() {
      return useAccountStore();
    }
    get canCreateTimesheet(): boolean {
      return (
        this.accountStore.data.show_pay_details_tab &&
        this.currentUserStore.checkProfilePermission({ id: this.userInvolvement?.user_id || 0 }, 'pay_details', 'edit') &&
        !!this.userInvolvement?.user?.can_be_opened
      );
    }

    get isOkForFetching(): boolean {
      return !this.defaultTemplating && !!this.record?.id && !!this.localValue.user_id;
    }

    get showTimesheet(): boolean {
      return !this.isActual && this.isOkForFetching && !!this.localValue.start_date && !!this.localValue.end_date;
    }

    get showAvailableDays(): boolean {
      return this.isActual && this.isOkForFetching && !!this.localValue.start_date;
    }

    @Emit('input')
    onDateChange(date: Date | undefined, key: 'start_date' | 'end_date'): TimesheetFieldValue {
      const dateString = date && moment(date).format(this.dateFormat);
      this.localValue = { ...this.localValue, [key]: dateString };
      this.sendUpdate(this.localValue);
      this.updateTimesheet();
      return this.localValue;
    }

    @Emit('input')
    onActualDateChange(date: Date | undefined, key: 'start_date' | 'end_date'): TimesheetFieldValue {
      const dateString = date && moment(date).format(this.dateFormat);
      this.localValue = { ...this.localValue, [key]: dateString };
      this.sendUpdate(this.localValue);
      this.updateAvailableDays();
      return this.localValue;
    }

    @Emit('input')
    onShiftsChanged(shifts: Record<string, TimesheetTime>): TimesheetFieldValue {
      this.localValue = { ...this.localValue, shifts: shifts };
      this.sendUpdate(this.localValue);
      return this.localValue;
    }

    actualDateEnabled(date: Date): boolean {
      if (this.userInvolvement?.user?.schedule?.starts_on) {
        const interval = this.userInvolvement.user.schedule.interval;
        const intervalSplit = this.userInvolvement.user.schedule.interval_split;
        const startsOn = new Date(this.userInvolvement.user.schedule.starts_on);
        switch (interval) {
          case 'daily':
            return this.differenceInDays(date, startsOn) % intervalSplit === 0;
          case 'weekly':
            return this.differenceInDays(date, startsOn) % (intervalSplit * 7) === 0;
          case 'monthly':
            return this.matchDateOfMonth(date, startsOn);
          case 'yearly':
            return date.getMonth() === startsOn.getMonth() && date.getDate() === startsOn.getDate();
        }
      }
      return false;
    }

    createTimesheet(user: TenantUser): void {
      new UserProfileModalInstance({ parent: this, propsData: { userId: user.id, tabName: 'timesheets' } }).$mount();
    }

    differenceInDays(endDate: Date, startDate: Date): number {
      return Math.round(((endDate as unknown as number) - (startDate as unknown as number)) / (1000 * 60 * 60 * 24));
    }

    matchDateOfMonth(date: Date, startsOn: Date): boolean {
      switch (date.getMonth()) {
        // account for February
        case 1:
          const endOfMonth = this.isLeapYear(date.getFullYear()) ? 29 : 28;
          if (startsOn.getDate() > endOfMonth) {
            return date.getDate() === endOfMonth;
          }
          break;
        // account for months without 31 days
        case 3:
        case 5:
        case 8:
        case 10:
          if (startsOn.getDate() === 31) {
            return date.getDate() === 30;
          }

          break;
      }

      return date.getDate() === startsOn.getDate();
    }

    // TODO: use moment instead
    isLeapYear(year: number): boolean {
      return new Date(year, 1, 29).getDate() === 29;
    }

    updateTimesheet(): void {
      // TODO: add support for fully-readonly fields in the future
      if (this.showTimesheet) {
        this.expectedTimesheetKey++;
      }
    }

    updateAvailableDays(): void {
      if (this.showAvailableDays) {
        this.actualTimesheetKey++;
      }
    }

    dateValue(key: 'start_date' | 'end_date'): Date | undefined {
      return (this.localValue[key] && moment(this.localValue[key], this.dateFormat).toDate()) || undefined;
    }

    beforeMount(): void {
      this.localValue = this.value || {};
      this.$api.getInvolvement(this.question.config.involvement_id, { only: ['id', 'name'] }, { cache: true }).then(({ data }) => {
        this.involvement = data;
      });
      if (this.record?.id) {
        this.$api
          .getUserInvolvements(
            {
              filters: {
                record_id: this.record.id,
                record_type: this.recordType,
                involvement_id: this.question.config.involvement_id,
              },
              include: ['user', 'schedule', 'can_be_opened', 'avatar_url', 'shifts', 'shift_hours'],
              only: ['id', 'user_id', 'user'],
            },
            { cache: true }
          )
          .then(({ data }) => {
            if (data[0]) {
              this.userInvolvement = data[0];
              this.localValue = {
                ...this.localValue,
                user_id: `${data[0].user_id}`,
              };
              this.updateTimesheet();
              this.updateAvailableDays();
            }
          });
      }
    }
  }
