import { BaseService } from './base-service';
import type { RoleModuleName } from '@app/models/role-module-name';

export class RoleModuleNameService extends BaseService<RoleModuleName> {
  static serviceTitle = ($t: (key: string) => string): string => $t('app.labels.role_module_name');
  static readonly allowedAttributes = ['configuration_type', 'module_name_id', 'role_id', 'role_profile_id'];
  static attributeNames($t: (key: string) => string): Partial<Record<keyof RoleModuleName, string>> {
    return {
      module_name_id: $t('app.labels.module'),
      role_profile_id: $t('app.labels.role_profile'),
      role_id: $t('app.labels.role'),
    };
  }
  static readonly attributeServices: Partial<Record<keyof RoleModuleName, string>> = {
    module_name_id: 'ModuleName',
    role_profile_id: 'RoleProfile',
    role_id: 'Role',
  };
}
