import { mapKeys, pick, startCase } from 'lodash';
import type { BaseEntity } from '@app/models/base-entity';
import type { Account } from '@app/models/account';
import type { PaperTrailChange, PaperTrailChanges, PaperTrailsFilters, PaperTrailVersion } from '@app/models/paper-trail-version';

import { eventNameMapping, typeTranslationMapping } from './mapping';
import type { AttributeOverrides, VersionSearchMetaData } from './models';
import { TRANSLATION_POSTFIX } from './models';
import {
  AccountService,
  ActivityService,
  AuditReportTemplateService,
  AutomatedEventService,
  AutomationEventGroupService,
  AutomationDefinitionService,
  DashboardPaneService,
  DocumentService,
  FeatureSetService,
  InvolvementService,
  LocationService,
  ModuleNameService,
  ModuleTabService,
  NotificationUserService,
  ProcedureAcknowledgmentService,
  ProfileService,
  RecordCalculationResultService,
  RecordCalculationService,
  RecordCalculationSubFormListService,
  RecordCalculationSubFormService,
  RecordRelationService,
  RecurringSetupService,
  RoleModuleNameService,
  RoleProfilePermissionService,
  RoleProfileService,
  RoleService,
  RuleSetService,
  SamlService,
  ScoreBandProfileService,
  ScoringGroupService,
  ShiftService,
  SignatureService,
  SubFormListService,
  SubFormListSubFormService,
  SubFormListsTabSectionGroupService,
  SubFormQuestionService,
  SubFormSectionService,
  SubFormService,
  SuperReportService,
  UserCollectionService,
  UserLocationService,
  UserLocationTagService,
  UserModuleRecordService,
  UserOrganizationService,
  UserService,
  WorkflowLinkService,
  WorkflowService,
} from './services';
import { versionTime } from './utils';

export const ATTRIBUTE_OVERRIDES: AttributeOverrides = {
  ActionPriority: ['id', 'code', 'color', 'index', 'is_default', 'active'],
  Notification: ['active'],
  PlaceholderProfile: ['id', 'handle', 'active', 'name'],
  PlaceholderVariable: ['handle', 'value', 'description', 'restricted'],
  RoleConfidentialityType: ['confidentiality_type_id'],
  Location: LocationService.allowedAttributes,
  Organization: ['id', 'organization_id', 'name', 'external_uuid', 'level', 'hours_worked', 'active'],
  Account: AccountService.allowedAttributes,
  UserInvolvement: ['user_id'],
  ModuleRecord: ['external_id', 'location_id', 'organization_id', 'sub_form_completion_id', 'user_id', 'workflow_id'],
  Confidentiality: ['user_id'],
  SubFormCompletion: ['external_id', 'sub_form_id', 'sub_form_list_id', 'user_id', 'uuid', 'id', 'stage', 'workflow_id'],
  SubFormResponse: ['response'],
  Follow: ['user_id'],
  RecordIndicator: ['indicator_id'],
  Activity: ActivityService.allowedAttributes,
  Comment: ['user_id', 'comment'],
  RecordRelation: ['from_id', 'to_id'],
  Attachment: ['id'],
  RecordSignature: ['id'],
  AutomationDefinition: AutomationDefinitionService.allowedAttributes,
  AutomatedEvent: AutomatedEventService.allowedAttributes,
  AutomationEventGroup: AutomationEventGroupService.allowedAttributes,
  RuleSet: RuleSetService.allowedAttributes,
  Event: ['name', 'concept_name', 'enable_retries', 'event_type', 'id', 'notification_email', 'retries'],
  SubFormQuestion: SubFormQuestionService.allowedAttributes,
  Workflow: WorkflowService.allowedAttributes,
  SubFormSection: SubFormSectionService.allowedAttributes,
  Involvement: InvolvementService.allowedAttributes,
  Indicator: ['active', 'color', 'default', 'hide_empty', 'id', 'index', 'indicator_set_id', 'indicator_type'],
  IndicatorSet: ['active', 'multi', 'hidden', 'hide_title', 'display_only', 'id', 'index', 'indicator_type'],
  Relationship: ['active', 'code', 'from_module', 'id', 'name', 'to_module'],
  TabSectionGroup: ['active', 'code', 'module_name', 'id', 'name', 'sub_form_list_ids'],
  ScoringGroup: ScoringGroupService.allowedAttributes,
  ScoreBandProfile: ScoreBandProfileService.allowedAttributes,
  ModuleName: ModuleNameService.allowedAttributes,
  ConfidentialityType: ['active', 'code', 'id', 'module_name_id', 'system', 'uuid'],
  DefaultTemplate: ['active', 'id', 'module_name_id', 'show_ui', 'system_code', 'index'],
  AuditReportTemplate: AuditReportTemplateService.allowedAttributes,
  RecordCalculation: RecordCalculationService.allowedAttributes,
  RecordCalculationSubForm: RecordCalculationSubFormService.allowedAttributes,
  RecordCalculationSubFormList: RecordCalculationSubFormListService.allowedAttributes,
  SubFormList: SubFormListService.allowedAttributes,
  SubForm: SubFormService.allowedAttributes,
  SubFormListRestriction: ['id', 'permission_type', 'relationship', 'sub_form_list_id', 'rule_set_id'],
  SubFormListsTabSectionGroup: SubFormListsTabSectionGroupService.allowedAttributes,
  SubFormListRole: ['id', 'permission_type', 'sub_form_list_id', 'rule_id'],
  ExtendedPermission: ['name', 'concept_name'],
  PermissionRole: ['permission_id'],
  Role: RoleService.allowedAttributes,
  AccessibleDashboard: ['role_id', 'dashboard_id'],
  DashboardPaneRole: ['role_id', 'dashboard_pane_id'],
  User: UserService.allowedAttributes,
  AdminPermission: ['feature'],
  ScheduledEvent: ['active', 'collection_description', 'event_id', 'id', 'name', 'schedule_id', 'status'],
  'Recurring::Setup': RecurringSetupService.allowedAttributes,
  'Expensable::RecordBudget': ['ready'],
  'Expensable::RecordBudgetCategory': ['active'],
  'Expensable::RecordBudgetCategoryValue': ['expected_value'],
  Schedule: ['interval', 'interval_split', 'starts_on'],
  UserLocation: UserLocationService.allowedAttributes,
  FeatureSet: FeatureSetService.allowedAttributes,
  UserLocationTag: UserLocationTagService.allowedAttributes,
  Document: DocumentService.allowedAttributes,
  UserModuleRecord: UserModuleRecordService.allowedAttributes,
  NotificationUser: NotificationUserService.allowedAttributes,
  Shift: ShiftService.allowedAttributes,
  Profile: ProfileService.allowedAttributes,
  UserOrganization: UserOrganizationService.allowedAttributes,
  Signature: SignatureService.allowedAttributes,
  ModuleTab: ModuleTabService.allowedAttributes,
  SuperReport: SuperReportService.allowedAttributes,
  SuperReportRole: ['role_id', 'super_report_id'],
  ProcedureAcknowledgment: ProcedureAcknowledgmentService.allowedAttributes,
  UserCollection: UserCollectionService.allowedAttributes,
  WorkflowLink: WorkflowLinkService.allowedAttributes,
  SAML: SamlService.allowedAttributes,
  RoleProfile: RoleProfileService.allowedAttributes,
  RoleProfilePermission: RoleProfilePermissionService.allowedAttributes,
  FormComponent: ['id', 'component_id', 'component_type', 'order', 'can_skip', 'complete_multiple', 'user_collection_id', 'rule_set_id'],
  RoleModuleName: RoleModuleNameService.allowedAttributes,
  SubFormListSubForm: SubFormListSubFormService.allowedAttributes,
  RecordCalculationResult: RecordCalculationResultService.allowedAttributes,
  DashboardPane: DashboardPaneService.allowedAttributes,
};

export const ATTRIBUTE_TRANSLATION_OVERRIDES: AttributeOverrides = {
  ActionPriority: ['title', 'locale'],
  Event: ['id', 'event_id', 'locale', 'options'],
  SubFormQuestion: ['description', 'id', 'options', 'locale', 'question', 'sub_form_question_id'],
  Workflow: ['id', 'name', 'next_steps_text', 'locale', 'workflow_id'],
  Dashboard: ['name', 'locale'],
  Widget: ['title', 'locale'],
  SubFormSection: ['description', 'id', 'locale', 'sub_form_section_id', 'title'],
  Involvement: ['name', 'id', 'locale', 'involvement_id'],
  Indicator: ['description', 'name', 'id', 'locale', 'indicator_id'],
  IndicatorSet: ['name', 'id', 'locale', 'indicator_set_id'],
  ModuleName: ['display', 'plural_display', 'wizard_labels', 'id', 'locale', 'module_name_id'],
  ConfidentialityType: ['name', 'confidentiality_type_id', 'id', 'locale'],
  SubFormList: ['add_text', 'approval_button_text', 'title', 'sub_form_list_id', 'id', 'locale'],
  SubForm: ['description', 'title', 'sub_form_id', 'id', 'locale'],
  SuperReport: ['name', 'description', 'locale'],
  DefaultTemplate: ['name', 'locale'],
  ModuleTab: ['title', 'locale'],
  DashboardPane: ['name', 'description', 'locale'],
};

export const displayedAttributeOverrides = (type: string): string[] | undefined => {
  const overrides = {
    ...ATTRIBUTE_OVERRIDES,
    ...mapKeys(ATTRIBUTE_TRANSLATION_OVERRIDES, (_, key) => `${key}${TRANSLATION_POSTFIX}`),
  };

  return overrides[type];
};

export const allowedAttribute = (type: string, key: string): boolean => {
  return !!allowedAttributes(type)?.some((k) => k === key);
};

export const allowedAttributes = (type: string): Maybe<string[]> => {
  return displayedAttributeOverrides(type);
};

export const deleteTranslationPostfix = (type: string): string => {
  return type.replace(TRANSLATION_POSTFIX, '');
};

export const isTranslation = (version: PaperTrailVersion): boolean => {
  return version.item_type.endsWith(TRANSLATION_POSTFIX);
};

export const attributeLabel = (itemType: string, attribute: string, $t: (key: string) => string): string => {
  const attributeTypeNameMapping: Record<string, Record<string, string>> = {
    Activity: ActivityService.attributeNames($t),
    AutomatedEvent: AutomatedEventService.attributeNames($t),
    AutomationEventGroup: AutomationEventGroupService.attributeNames($t),
    RecordRelation: RecordRelationService.attributeNames($t),
    Attachment: { id: $t('app.labels.attachment') },
    RecordSignature: { id: $t('app.labels.signature') },
    Role: { dashboard_id: $t('app.labels.default_dashboard') },
    Location: { location_id: $t('app.labels.parent') },
    Organization: { organization_id: $t('app.labels.parent') },
    DefaultTemplate: {
      show_ui: $t('tenant.admin.default_templates.form.allow_select_on_create'),
    },
    AccessibleDashboard: {
      role_id: $t('app.labels.role'),
      dashboard_id: $t('app.labels.dashboard'),
    },
    User: UserService.attributeNames($t),
    UserModuleRecord: UserModuleRecordService.attributeNames($t),
    NotificationUser: NotificationUserService.attributeNames($t),
    UserOrganization: UserOrganizationService.attributeNames($t),
    UserLocation: UserLocationService.attributeNames($t),
    Signature: SignatureService.attributeNames($t),
    SuperReportRole: {
      role_id: $t('app.labels.role'),
      super_report_id: $t('app.labels.report'),
    },
    ModuleName: {
      name: $t('tenant.admin.module_names.sections.general.system_name'),
    },
    SuperReport: SuperReportService.attributeNames($t),
    ModuleTab: ModuleTabService.attributeNames($t),
    ProcedureAcknowledgment: ProcedureAcknowledgmentService.attributeNames($t),
    SAML: SamlService.attributeNames($t),
    RoleProfile: RoleProfileService.attributeNames($t),
    RoleProfilePermission: RoleProfilePermissionService.attributeNames($t),
    RoleModuleName: RoleModuleNameService.attributeNames($t),
    SubFormListSubForm: SubFormListSubFormService.attributeNames($t),
    RecordCalculationSubForm: RecordCalculationSubFormService.attributeNames($t),
    RecordCalculationSubFormList: RecordCalculationSubFormListService.attributeNames($t),
  };

  const attributeNameMapping: Record<string, string> = {
    assignee_id: $t('app.labels.assigned_to'),
    audit_report_template_id: $t('app.labels.export_template'),
    confidentiality_type_id: $t('app.labels.confidentiality_type'),
    event_id: $t('app.labels.event'),
    external_id: $t('app.labels.external_uuid'),
    external_uuid: $t('app.labels.external_uuid'),
    from_id: $t('app.labels.from_state'),
    id: 'ID',
    permission_id: $t('app.labels.permission'),
    role_id: $t('app.labels.role'),
    dashboard_id: $t('app.labels.dashboard'),
    indicator_id: $t('app.labels.indicator'),
    indicator_set_id: $t('app.labels.indicator_set'),
    involvement_id: $t('app.labels.involvement'),
    location_id: $t('app.labels.location'),
    location_sub_form_question_id: $t('app.labels.question_location'),
    module_name_id: $t('app.labels.module'),
    organization_id: $t('app.labels.organization'),
    organization_sub_form_question_id: $t('app.labels.question_organization'),
    record_calculation_id: $t('app.labels.record_calculation'),
    rule_set_id: $t('app.labels.rule_set'),
    scheme_sub_form_question_id: $t('app.labels.question_scheme'),
    score_band_profile_id: $t('app.labels.scoring_profile'),
    show_options: $t('app.labels.visual_options'),
    source_uuid: 'Source UUID',
    start_workflow_id: $t('app.labels.workflow_state'),
    sub_form_completion_id: $t('app.labels.completion'),
    sub_form_id: $t('app.labels.form_name'),
    sub_form_list_id: $t('app.labels.tab_section'),
    sub_form_list_ids: $t('app.labels.tab_sections'),
    sub_form_question_id: $t('app.labels.question'),
    sub_form_section_id: $t('app.labels.section'),
    to_id: $t('app.labels.to_state'),
    user_id: $t('app.labels.user'),
    uuid: 'UUID',
    workflow_id: $t('app.labels.workflow'),
    approval_sub_form_id: $t('app.labels.sub_form'),
    default_template_id: $t('app.labels.default_template'),
    module_tab_id: $t('app.labels.module_tab'),
    record_relation_question_id: $t('app.labels.question'),
    user_collection_id: $t('app.labels.user_collection'),
    schedule_id: $t('app.labels.setup'),
    recurrable_id: $t('app.labels.target'),
    location_tag_id: $t('app.labels.location_tag'),
    response: $t('app.labels.response'),
    state: $t('app.labels.state'),
  };

  return attributeTypeNameMapping[itemType]?.[attribute] || attributeNameMapping[attribute] || startCase(attribute);
};

const columnsForItemType = (itemType: string, attributeScope?: string[]) => {
  if (!attributeScope) return displayedAttributeOverrides(itemType);

  return (
    attributeScope
      .map((s) => {
        const split = s.split('.');
        return split[1] ? split : [itemType, ...split];
      })
      .filter(([type]) => type === itemType)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(([_type_, attribute]) => attribute)
  );
};

export const processObjectChanges = <ChangeType extends BaseEntity = BaseEntity, ChangeValue = PaperTrailChange>(
  version: PaperTrailVersion<ChangeType, ChangeValue>,
  attributeScope?: string[]
): Maybe<PaperTrailChanges<ChangeType, ChangeValue>> => {
  const columns = columnsForItemType(version.item_type, attributeScope);

  if (!columns) return version.object_changes;

  return pick(version.object_changes, columns) as PaperTrailChanges<ChangeType, ChangeValue>;
};

export const typeName = (type: string, $t: (key: string) => string): string => {
  const typeMapping: Record<string, string> = {
    AdminPermission: $t('app.labels.admin_permission'),
    LocationTag: $t('app.labels.tag'),
    LocationTagCount: $t('app.labels.minimum_tag_count'),
    PlaceholderProfile: $t('app.labels.placeholder_profile'),
    PlaceholderVariable: $t('app.labels.placeholder_variable'),
    ModuleRecord: $t('app.labels.record'),
    SubFormCompletion: $t('app.labels.form_completion'),
    SubFormResponse: $t('app.labels.form_response'),
    RecordIndicator: $t('app.labels.indicator'),
    UserInvolvement: $t('app.labels.involvement'),
    Confidentiality: $t('app.labels.confidentiality'),
    Activity: $t('app.labels.action'),
    Comment: $t('app.labels.comment'),
    RecordRelation: $t('app.labels.record_relation'),
    Attachment: $t('app.labels.attachment'),
    RecordSignature: $t('app.labels.signature'),
    AutomationDefinition: $t('app.labels.automation'),
    AutomatedEvent: $t('app.labels.automated_event'),
    AutomationEventGroup: $t('app.labels.group'),
    ModuleName: $t('app.labels.module'),
    WorkflowLink: $t('app.labels.workflow_link'),
    Workflow: $t('app.labels.workflow_state'),
    TabSectionGroup: $t('app.labels.tab_section_group'),
    ScoreBandProfile: $t('app.labels.scoring_profile'),
    ScoringGroup: $t('app.labels.scoring_group'),
    ConfidentialityType: $t('app.labels.confidentiality_type'),
    FeatureSet: $t('app.labels.feature_set'),
    DefaultTemplate: $t('app.labels.default_template'),
    AuditReportTemplate: $t('app.labels.export_template'),
    RecordCalculation: $t('app.labels.record_calculation'),
    IndicatorSet: $t('app.labels.indicator_set'),
    SubFormList: $t('app.labels.tab_section'),
    SubFormSection: $t('app.labels.section'),
    SubForm: $t('app.labels.sub_form'),
    SubFormQuestion: $t('app.labels.question'),
    SubFormListRestriction: $t('app.labels.restriction'),
    SubFormListRole: $t('app.labels.tab_section_role'),
    PermissionRole: $t('app.labels.permission_role'),
    ExtendedPermission: $t('app.labels.extended_permission'),
    AccessibleDashboard: $t('app.labels.role_dashboard'),
    'Recurring::Setup': RecurringSetupService.serviceTitle($t),
    'Expensable::RecordBudget': $t('app.labels.budget'),
    'Expensable::RecordBudgetCategory': $t('app.labels.budget_category'),
    'Expensable::RecordBudgetCategoryValue': $t('app.labels.budget_category_value'),
    ScheduledEvent: $t('app.labels.schedule'),
    Schedule: $t('app.labels.setup'),
    SuperReportRole: $t('app.labels.report_role'),
    UserLocation: $t('app.labels.user_location'),
    UserModuleRecord: $t('app.labels.user_module_record'),
    UserLocationTag: $t('app.labels.tag'),
    NotificationUser: $t('app.labels.notification_user'),
    Shift: $t('app.labels.timesheet'),
    UserOrganization: $t('app.labels.user_organization'),
    UserRecordCalendar: $t('app.labels.user_record_calendar'),
    ModuleTab: ModuleTabService.serviceTitle($t),
    ProcedureAcknowledgment: ProcedureAcknowledgmentService.serviceTitle($t),
    UserCollection: UserCollectionService.serviceTitle($t),
    RuleSet: RuleSetService.serviceTitle($t),
    RoleProfile: RoleProfileService.serviceTitle($t),
    RoleProfilePermission: RoleProfilePermissionService.serviceTitle($t),
    RoleModuleName: RoleModuleNameService.serviceTitle($t),
    SubFormListSubForm: SubFormListSubFormService.serviceTitle($t),
    RecordCalculationSubForm: RecordCalculationSubFormService.serviceTitle($t),
    RecordCalculationSubFormList: RecordCalculationSubFormListService.serviceTitle($t),
    SubFormListsTabSectionGroup: SubFormListsTabSectionGroupService.serviceTitle($t),
  };

  if (type.endsWith(TRANSLATION_POSTFIX)) {
    const baseType = deleteTranslationPostfix(type);
    const overrideTitle = typeTranslationMapping($t)[baseType];
    if (overrideTitle) return overrideTitle;

    const baseTitle = typeMapping[baseType] || baseType;
    return `${baseTitle} Translation`;
  }

  return typeMapping[type] || type;
};

export const recordName = (version: PaperTrailVersion, $t: (key: string) => string): string => typeName(version.item_type, $t);

export const eventName = (version: PaperTrailVersion, $t: (key: string) => string): string => eventNameMapping($t)[version.event];

const searchableArray = (
  version: PaperTrailVersion,
  account: Pick<Account, 'timezone' | 'datetimepicker_datetime_format' | 'datetimepicker_date_format' | 'datetimepicker_time_format'>,
  whodunnitFunction: () => string[],
  searchMetaDataFunction: () => VersionSearchMetaData,
  $t: (key: string) => string
): string[] => {
  const createdAt = versionTime(account, version.created_at);
  return [
    createdAt.date,
    createdAt.tz,
    ...whodunnitFunction(),
    recordName(version, $t),
    `${version.item_id}`,
    eventName(version, $t),
    ...Object.values(searchMetaDataFunction() || {})
      .flat()
      .map((item) => {
        if (typeof item === 'object' && !Array.isArray(item) && item !== null) return Object.values(item);
        return item;
      })
      .map((v) => `${v}`),
  ]
    .filter(Boolean)
    .map((s) => s.toLowerCase());
};

export const showVersion = (
  version: PaperTrailVersion,
  customFilters: PaperTrailsFilters,
  account: Pick<Account, 'timezone' | 'datetimepicker_datetime_format' | 'datetimepicker_date_format' | 'datetimepicker_time_format'>,
  whodunnitFunction: () => string[],
  searchMetaDataFunction: () => VersionSearchMetaData,
  $t: (key: string) => string
): boolean => {
  const lowerSearch = customFilters.search.toLowerCase();
  return (
    !lowerSearch || searchableArray(version, account, whodunnitFunction, searchMetaDataFunction, $t).some((s) => s.includes(lowerSearch))
  );
};
