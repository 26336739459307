export const isUserCollection = (value?: Nullable<string | number>): boolean => {
  const valueAsString = `${value || ''}`;
  return (
    !!valueAsString &&
    (valueAsString === 'new_user_collection' ||
      valueAsString.startsWith('#records_~users_') ||
      valueAsString.startsWith('~users_') ||
      valueAsString.startsWith('#records_~user_involvements_') ||
      valueAsString.startsWith('~user_involvements_') ||
      Number.isInteger(parseInt(valueAsString)))
  );
};
