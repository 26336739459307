
  import { Component, Prop } from 'vue-property-decorator';
  import BaseQuestionResponse from './base-question-response';
  import TextHighlight from 'vue-text-highlight';
  import type { ModuleRecord } from '@app/models/module-record';

  @Component({ components: { TextHighlight } })
  export default class MultiRecordFieldResponse extends BaseQuestionResponse {
    @Prop(Array) readonly records?: ModuleRecord[];
    @Prop(Array) readonly recordsText?: string[];
  }
