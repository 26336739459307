
  import { Component, Prop, Vue } from 'vue-property-decorator';
  @Component({})
  export default class RecordIndexPageFiltersLayout extends Vue {
    @Prop(Object) readonly wrapperClasses!: Record<'search-input' | 'buttons', Maybe<Record<string, boolean>>>;

    get searchInputWrapperClasses(): Record<string, boolean> {
      return {
        'col-custom': true,
        ...(this.wrapperClasses?.['search-input'] || {}),
      };
    }

    get buttonsWrapperClasses(): Record<string, boolean> {
      return {
        'col-custom': true,
        buttons: true,
        ...(this.wrapperClasses?.['buttons'] || {}),
      };
    }
  }
