import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { Workflow } from '@app/models/workflow';

export class WorkflowService extends BaseService<Workflow> {
  static readonly allowedAttributes = [
    'active',
    'code',
    'color',
    'exclude_creator',
    'id',
    'locked_sub_form_question_ids',
    'module_name_id',
    'sub_form_list_id',
    'user_collection_id',
  ];

  async fetchDetails(itemId: ServiceRequest<Workflow>): Promise<DetailsServiceResponse> {
    const workflow = await this.fetchEntity(itemId);
    return {
      links: [
        {
          link: `/admin/settings/module_names/${workflow.module_name_id}?tab=workflow`,
          prefix: this.t('app.labels.workflow_state'),
          title: workflow?.name || '',
          id: workflow.id,
        },
      ],
    };
  }

  async fetchEntity({ itemId }: ServiceRequest<Workflow>): Promise<Workflow> {
    const { data: workflow } = await this.api.getWorkflow(itemId, { only: ['id', 'name', 'module_name_id'] }, { join: true, cache: true });
    return workflow;
  }

  entityToText({ itemId }: ServiceRequest<Workflow>, entity?: Workflow): string {
    return entity ? `${entity.name} [${entity.id}]` : `${itemId}`;
  }
}
