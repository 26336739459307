import {
  AddressField,
  ApprovalExpenseField,
  ApprovalStateField,
  AreaField,
  ButtonSelectField,
  CalculationSelectField,
  CalculationTextField,
  CalculatorField,
  CompanyRegisterField,
  DateField,
  DatetimeField,
  DetailField,
  DisplayImageField,
  DisplayTextField,
  ExpenseBudgetCategorySelectField,
  ExpenseBudgetCategoryUuidField,
  ExpenseBudgetSelectField,
  ExpenseBudgetUuidField,
  ExpenseField,
  FileUploadField,
  KnowledgeBaseField,
  LocationField,
  MainFormQuestionListField,
  MainFormRelationField,
  MarkableField,
  MatrixField,
  MultiCheckboxField,
  MultiMainFormRelationField,
  MultiPersonSelectorField,
  MultiSelectField,
  OrganizationField,
  PayCalculatorField,
  RadioField,
  RecordRelationField,
  ReportField,
  SchemeField,
  ScormField,
  SignatureField,
  SinglePersonSelectorField,
  SingleSelectField,
  SubFormRelationField,
  SvgSelectorField,
  TextField,
  TextareaField,
  TimesheetField,
  TimesheetSummaryField,
  ToocsField,
  VideoField,
  WorkflowStateField,
  TableCalculationField,
} from './index';

export const FORM_FIELD_COMPONENTS = {
  AddressField,
  ApprovalExpenseField,
  ApprovalStateField,
  AreaField,
  ButtonSelectField,
  CalculationSelectField,
  CalculationTextField,
  CalculatorField,
  CompanyRegisterField,
  DateField,
  DatetimeField,
  DetailField,
  DisplayImageField,
  DisplayTextField,
  ExpenseBudgetCategorySelectField,
  ExpenseBudgetCategoryUuidField,
  ExpenseBudgetSelectField,
  ExpenseBudgetUuidField,
  ExpenseField,
  FileUploadField,
  KnowledgeBaseField,
  LocationField,
  MainFormQuestionListField,
  MainFormRelationField,
  MarkableField,
  MatrixField,
  MultiCheckboxField,
  MultiMainFormRelationField,
  MultiPersonSelectorField,
  MultiSelectField,
  OrganizationField,
  PayCalculatorField,
  RadioField,
  RecordRelationField,
  ReportField,
  SchemeField,
  ScormField,
  SignatureField,
  SinglePersonSelectorField,
  SingleSelectField,
  SubFormRelationField,
  SvgSelectorField,
  TextField,
  TextareaField,
  TimesheetField,
  TimesheetSummaryField,
  ToocsField,
  VideoField,
  WorkflowStateField,
  TableCalculationField,
};
