
  import { Component, Emit } from 'vue-property-decorator';
  import type { SingleSelectFieldValue } from '@app/models/question-response-types';
  import type { ExpensableRecordBudgetCategory } from '@app/models/expensable-record-budget-category';
  import type { ExpensableExpensingTableCategory } from '@app/models/expensable-expensing-table-category';
  import type { FieldType } from '@app/models/sub-form-question';
  import type { OnlyOptions } from '@app/services/donesafe-api-utils';

  import Select2 from '../select2.vue';

  import BaseField from './base-field';

  @Component({ components: { Select2 } })
  export default class ExpenseBudgetCategorySelectField extends BaseField<FieldType.expense_budget_category_select> {
    localValue: SingleSelectFieldValue = {};
    loading = false;

    options: string[][] = [];

    @Emit('input')
    updateValue(value?: string): SingleSelectFieldValue {
      if (value !== this.localValue.value) {
        this.localValue = { ...this.localValue, value };
        this.sendUpdate(this.localValue);
      }
      return this.localValue;
    }

    getOptions(recordBudgetSource?: number, expensingTableName?: string): void {
      this.parsleyReset();
      if (recordBudgetSource && expensingTableName) {
        this.loading = true;
        const only: OnlyOptions<ExpensableRecordBudgetCategory> = [{ category: ['name', 'code'] }];
        this.$api
          .getExpensableRecordBudgets(
            {
              per_page: -1,
              only: ['id'],
              filters: {
                budgetable_id: recordBudgetSource,
                budgetable_type: 'ModuleRecord',
                expensing_table: {
                  active: true,
                  name: expensingTableName,
                },
              },
            },
            { cache: true }
          )
          .then(
            ({ data }) =>
              data[0] &&
              this.$api
                .getExpensableRecordBudgetCategories(
                  {
                    only,
                    per_page: -1,
                    filters: {
                      active: true,
                      expensable_record_budget_id: data[0].id,
                    },
                  },
                  { cache: true }
                )
                .then(({ data }) => {
                  this.options = data.map(({ category }) => [`${category?.code}`, `${category?.name}`]);
                })
                .finally(() => {
                  this.loading = false;
                })
          );
      } else {
        this.options = [];
      }
    }

    initSubscription(): void {
      this.addSubscription(
        this.formObservers.expensableBudgetSelectUpdate$.subscribe(({ recordBudgetSource, expensingTableName }) => {
          this.getOptions(recordBudgetSource, expensingTableName);
        })
      );
    }

    beforeMount(): void {
      this.localValue = this.value || {};
    }

    mounted(): void {
      if (this.defaultTemplating) {
        this.loading = true;
        const only: OnlyOptions<ExpensableExpensingTableCategory> = [{ expensing_table: ['name'], category: ['name', 'code'] }];
        this.$api.getExpensingTableCategories({ only, per_page: -1 }, { cache: false }).then(({ data }) => {
          this.options = data.map((expensingTableCategory) => [
            `${expensingTableCategory.category?.code}`,
            `${expensingTableCategory.expensing_table?.name}: ${expensingTableCategory.category?.name} (${expensingTableCategory.category?.code})`,
          ]);
          this.loading = false;
        });
      } else {
        this.initSubscription();
      }
    }
  }
