import { flatten, uniq } from 'lodash';
import { bufferTime, filter, groupBy, map, mergeMap, switchMap } from 'rxjs';
import type { FormObservers } from './types/form-observers';
import type { DonesafeUtilsApi } from '@app/services/api/utils-api';

export function handleApiRequest(
  requestQueue$: FormObservers['apiRequestQueue$'],
  multiQuestionApiRequest: (ids: string[]) => ReturnType<DonesafeUtilsApi['executeApiRequest']>,
  resultQueue$: FormObservers['apiRequestResult$']
) {
  return requestQueue$
    .pipe(
      // Buffer at the beginning for 1 second
      bufferTime(500),
      // Filter out empty buffers
      filter((idsArrayBuffered) => !!idsArrayBuffered.length && idsArrayBuffered.some((arr) => arr.length > 0)),
      // Flatten and deduplicate
      map((idsArrayBuffered) => {
        const flattenIds = uniq(flatten(idsArrayBuffered).map((id) => id.toString()));
        return flattenIds;
      }),
      // Group by the unique combination of ids
      groupBy((ids) => JSON.stringify(ids)),
      // For each group (distinct ids)
      mergeMap((group$) =>
        group$.pipe(
          switchMap(async (ids: string[]) => {
            const { data } = await multiQuestionApiRequest(ids);
            return data;
          })
        )
      )
    )
    .subscribe((data) => {
      data && resultQueue$.next(data);
    });
}
