
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import { saferEvaluate } from './utils';
  import type { ApiResponseValidation, ValidationErrorButtonAction } from '@app/models/question-options/table-calculation-question-options';

  @Component({ components: { DsModal } })
  export default class ValidationErrorModal extends Vue {
    @Model('input') readonly value!: boolean;

    @Prop(Number) readonly index!: number;
    @Prop(Object) readonly validation!: ApiResponseValidation;
    @Prop(Object) readonly scope!: Record<string, string>;

    url(action: ValidationErrorButtonAction): string | null {
      if (action.type === 'navigate') {
        if (!action.url) return null;
        return saferEvaluate(action.url, this.scope);
      } else {
        return null;
      }
    }

    buttonClick(action: ValidationErrorButtonAction): void {
      switch (action.type) {
        case 'filter':
          this.$emit('filter', action.formula);
          break;
        case 'close':
          this.$emit('close');
          break;
        case 'skip':
          this.$emit('next', this.index + 1);
          break;
        case 'insert':
          this.$emit('insert', action.data || {});
          break;
        case 'navigate':
          break;
        default:
          this.$emit('input', false);
      }
    }
  }
