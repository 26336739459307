
  import { Component } from 'vue-property-decorator';
  import BaseFieldDisplay from './base-field-display';
  import type { FieldType } from '@app/models/sub-form-question';
  import type { ModuleRecord } from '@app/models/module-record';
  import type { RecordRelation, RelationForeignKey } from '@app/models/record-relation';
  import type { Relationship } from '@app/models/relationship';
  import { RecordRelationMode } from '@app/models/record-relation';
  import { convertInFormFilters } from '@app/utils/convert-in-form-filters';

  @Component({ components: {} })
  export default class RecordRelationDisplay extends BaseFieldDisplay<FieldType.record_relation> {
    relationship: Nullable<Relationship> = null;
    records: ModuleRecord[] = [];
    recordRelations: RecordRelation[] = [];

    get relationshipCode(): string {
      return this.question.config.relationship_code;
    }

    get mode(): RecordRelationMode {
      return this.question.config.relationship_mode;
    }

    get foreignKey(): RelationForeignKey {
      return this.mode === RecordRelationMode.from ? 'from_id' : 'to_id';
    }

    get relatedRecordIds(): number[] {
      return this.recordRelations.map((rr) => rr[this.relatedForeignKey]);
    }

    get relatedForeignKey(): RelationForeignKey {
      return this.mode === RecordRelationMode.from ? 'to_id' : 'from_id';
    }

    async beforeMount(): Promise<void> {
      if (!this.mode || this.isPublic) return;

      const { data: recordRelations } = await this.$api.getRecordRelationsWithSharedFilters(
        {
          filters: { relationship_code: this.relationshipCode },
          only: ['from_id', 'to_id', 'relationship_code'],
          per_page: -1,
        },
        this.foreignKey,
        this.record.id,
        { cache: true }
      );

      this.recordRelations = recordRelations;

      if (!this.relatedRecordIds.length) return;

      const filters = convertInFormFilters(this.question.config.filters, {
        record: this.record,
        user: this.currentUserStore.data,
      });

      const { data: records } = await this.$api.getModuleRecordsWithSharedFilter(
        {
          filters,
          only: ['id', 'title'],
          per_page: -1,
        },
        'id',
        this.relatedRecordIds,
        { cache: true }
      );

      this.records = records;
    }
  }
