
  import { Component, Emit } from 'vue-property-decorator';
  import { mixins } from 'vue-class-component';
  import HighlightColours from '@app/mixins/highlight-colours';
  import type { SingleSelectFieldValue } from '@app/models/question-response-types';
  import type { FieldType, SubFormQuestion } from '@app/models/sub-form-question';

  import BaseField from './base-field';

  @Component
  class BaseRadioField extends BaseField<FieldType.radio> {}

  @Component
  export default class RadioField extends mixins(BaseRadioField, HighlightColours) {
    localValue: SingleSelectFieldValue = { value: '' };

    get values(): SubFormQuestion['options']['values'] {
      return this.question.options.values;
    }

    get showClearButton(): boolean {
      return (
        (this.defaultTemplating || (!this.readonly && this.question.config.allow_clear_response === 'true')) && !!this.localValue.value
      );
    }

    @Emit('input')
    onSelectKey(key?: string): SingleSelectFieldValue {
      this.localValue = { value: key };
      this.sendUpdate(this.localValue);
      return this.localValue;
    }

    beforeMount(): void {
      this.localValue = { value: this.value?.value || '' };
    }
  }
