import type { StringBoolean } from '@app/utils/types/string-boolean';

export type ConfiguratorFilterValue = string[] | string | number | number[];

export interface DateRangeFilterValue extends Omit<BaseConfiguratorFilter, 'key'> {
  operator?: ConfiguratorFilterOperator;
  value?: string;
}

export enum ConfiguratorFilterSource {
  current_completion = 'current_completion',
  current_record = 'current_record',
  current_user = 'current_user',
  date_select = 'date_select',
  question_code = 'question_code',
  question_id = 'question_id',
  range = 'range',
  registration_default = 'registration_default',
}

export enum ConfiguratorFilterOperator {
  greater_than = '>',
  greater_than_or_equal = '>=',
  less_than = '<',
  less_than_or_equal = '<=',
}

export type ConfiguratorFilter = BaseConfiguratorFilter | DateRangeConfiguratorFilter;

export interface BaseConfiguratorFilter {
  invert?: StringBoolean;
  key: string;
  label?: string;
  required?: StringBoolean;
  source?: Exclude<ConfiguratorFilterSource, ConfiguratorFilterSource.range>;
  value?: ConfiguratorFilterValue;
}

export interface DateRangeConfiguratorFilter {
  invert?: StringBoolean;
  key: string;
  required?: StringBoolean;
  source?: ConfiguratorFilterSource.range;
  value?: DateRangeFilterValue[];
}
