import { useCurrentUserStore } from '@app/stores/currentUser';
import { useAccountStore } from '@app/stores/account';
import { valueInResponse } from '@app/utils/value-in-response';
import { Component, Emit, Model, Prop, Vue } from 'vue-property-decorator';
import { v4 as generateUUID } from 'uuid';
import { LOOKUP_TYPE_MAPPING } from '@app/models/sub-form-question';
import type { Subscription } from 'rxjs';
import type { SubFormCompletion } from '@app/models/sub-form-completion';
import type { SubFormResponse } from '@app/models/sub-form-response';
import type { ModuleRecord } from '@app/models/module-record';
import type { FieldType, QuestionTypeMap, ResponseValue, SubFormQuestion } from '@app/models/sub-form-question';
import type { ConfiguratorFilter } from '@app/models/configurator-filter';
import { filter } from 'rxjs';
import type { SubFormData } from '@app/services/api/sub-form-completions-api';
import type { FormObservers } from '@app/utils/types/form-observers';

import type { SubFormCompletionFormRecord, QuestionSfcOnly, LookupRequest } from '../sub-form-completion/utils';

@Component
export default class BaseField<F extends FieldType> extends Vue {
  @Prop(Object) readonly question!: Pick<SubFormQuestion<QuestionTypeMap<F>['options']>, QuestionSfcOnly>;
  @Prop(Object) readonly completion?: Pick<
    SubFormCompletion,
    'id' | 'record_id' | 'record_type' | 'sub_form_id' | 'sub_form_list_id' | 'approval_for_sub_form_completion_id' | 'stage'
  >;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop(Object) readonly params?: { [key: string]: any };
  @Prop(Object) readonly record?: SubFormCompletionFormRecord;
  // TODO: find a way to get rid of this
  @Prop(Object) readonly response?: Pick<SubFormResponse<F>, 'id' | 'response'>;
  @Prop(String) readonly recordType!: string;
  @Prop(Boolean) readonly readonly!: boolean;
  @Prop(Boolean) readonly isPublic!: boolean;
  @Prop(Boolean) readonly compactView?: boolean;
  @Prop(Object) readonly observers!: FormObservers;
  @Prop(Boolean) readonly defaultTemplating?: boolean;
  @Prop(String) readonly name!: string;
  @Prop(Boolean) readonly modal?: boolean;
  @Prop(Object) readonly autoSelectRecord?: Nullable<ModuleRecord>;
  @Prop(Number) readonly mainFormIdFromModal?: number;
  @Prop(Object) readonly dataById!: SubFormData;
  @Prop(Object) readonly dataByCode!: SubFormData;
  @Prop({ type: String, default: () => '' }) readonly formUuid!: string;
  @Prop(Object) readonly lookupHistory!: Record<string, string>;

  @Model('input') readonly value!: QuestionTypeMap<F>['value'];

  subscriptions: Subscription[] = [];
  uuid: string = generateUUID();
  incompleteFilters: ConfiguratorFilter[] = [];
  loadingState = false;

  get currentUserStore() {
    return useCurrentUserStore();
  }

  get accountStore() {
    return useAccountStore();
  }

  get formObservers(): FormObservers {
    return this.observers;
  }

  get $form(): JQuery<Element> {
    // TODO: remove this
    return $(this.$el).closest('.completion-form');
  }

  get subFormId(): number | undefined {
    return this.completion?.sub_form_id;
  }

  get parsleyErrorsContainerId(): string {
    return `${this.uuid}-parsley-message-holder-${this.question.id}`;
  }

  get mainFormId(): Maybe<number | string> {
    return this.mainFormIdFromModal || `${this.params?.main_form_id}` || undefined;
  }

  get isModal(): boolean {
    return !!this.modal || this.params?.modal === 'true';
  }

  get editMode(): boolean {
    return !!this.completion?.id;
  }

  get newMode(): boolean {
    return !this.completion?.id;
  }

  get lookupId(): Maybe<string> {
    return this.lookupHistory[this.question.id];
  }

  get labelledBy(): string {
    return `label-${this.question.id}`;
  }

  get isRequired(): boolean {
    if (this.defaultTemplating) {
      return false;
    }

    return this.question.required;
  }

  @Emit('update-loading-count')
  updateLoadingCount(type: 'start' | 'stop'): 'start' | 'stop' {
    return type;
  }

  @Emit('update-loading-state')
  updateLoadingState(loadingState: boolean) {
    this.loadingState = loadingState;
    return loadingState;
  }

  getCurrentFormValueByQuestion(filterValue: string, key: 'id' | 'code'): Maybe<ResponseValue> {
    if (key === 'id') {
      return this.dataById?.[filterValue];
    } else {
      const codeValue = this.dataByCode?.[filterValue];
      const value = valueInResponse(codeValue);
      const formattedCodeValue = value ? `${value}` : undefined;
      return Array.isArray(codeValue) ? codeValue.filter(Boolean) : formattedCodeValue;
    }
  }

  lookupRequest(request: LookupRequest): void {
    const type = LOOKUP_TYPE_MAPPING[this.question.field_type as string];
    type &&
      this.subFormId &&
      this.formObservers?.lookupRequest$.next({
        subFormId: this.subFormId,
        lookupRecordId: !!request.lookupRecordId ? request.lookupRecordId : undefined,
        questionCode: this.question.code,
        recordId: this.record?.id,
        subFormCompletionId: this.completion?.id,
        type,
        readOnlyLookup: !!request.readOnlyLookup,
        modal: this.isModal,
      });
  }

  parsleyReset(): void {
    return $(`[type!="hidden"][name="responses[${this.question.id}][value]"]`)?.parsley()?.reset();
  }

  clearIncompleteFilters(): void {
    this.setIncompleteFilters();
  }

  setIncompleteFilters(filters: ConfiguratorFilter[] = []): void {
    this.incompleteFilters = [...filters];
  }

  sendUpdate(newValue: QuestionTypeMap<F>['value'], isInitialValue = false): QuestionTypeMap<F>['value'] {
    this.$nextTick(() => this.formObservers?.fieldUpdate$.next([this.question, newValue, isInitialValue]));
    return newValue;
  }

  detailFieldRequest(recordId?: string): void {
    recordId && this.$nextTick(() => this.formObservers.detailsRequest$.next([this.question, recordId]));
  }

  addSubscription(sub: Subscription): void {
    this.subscriptions.push(sub);
  }

  removeAllSubscriptions(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  listenOnCodeValueUpdate(callback: (value: number | string) => void): void {
    this.addSubscription(
      this.formObservers.codeValueData$.pipe(filter((data) => data[this.question.code] != null)).subscribe((data) => {
        this.$nextTick(() => {
          callback(data[this.question.code]);
        });
      })
    );
  }

  onBeforeDestroy(): void {
    this.removeAllSubscriptions();
  }

  beforeDestroy() {
    this.onBeforeDestroy();
  }
}
