
  import { filter } from 'rxjs/operators';
  import { Component, Emit } from 'vue-property-decorator';
  import BaseField from './base-field';
  import type { DetailFieldValue } from '@app/models/question-response-types';
  import type { FieldType } from '@app/models/sub-form-question';

  @Component({})
  export default class DetailField extends BaseField<FieldType.detail> {
    localValue: DetailFieldValue = {};

    @Emit('input')
    onInput(value: string): DetailFieldValue {
      this.localValue = { ...this.localValue, value };
      this.sendUpdate(this.localValue);
      return this.localValue;
    }

    beforeMount(): void {
      this.localValue = this.value || {};
    }

    mounted(): void {
      if (this.question.config.sub_form_question_system_code && this.question.config.method) {
        this.addSubscription(
          this.formObservers.detailsResponse$
            .pipe(filter(([questionSystemCode]) => `${questionSystemCode}` === `${this.question.config.sub_form_question_system_code}`))
            .subscribe(([, data]) => this.onInput(data[this.question.config.method]))
        );
      }
    }
  }
