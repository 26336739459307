
  import moment from 'moment';
  import { filter } from 'rxjs/operators';
  import { mixins } from 'vue-class-component';
  import { Component, Emit } from 'vue-property-decorator';
  import { WithDateCalculations } from '@app/mixins/with-date-calculations';
  import type { DateFieldValue } from '@app/models/question-response-types';
  import type { FieldType } from '@app/models/sub-form-question';
  import { valueInResponse } from '@app/utils/value-in-response';

  import DatePicker from '../date-picker.vue';

  import BaseField from './base-field';

  @Component({ components: { DatePicker } })
  export default class DateField extends mixins<BaseField<FieldType.date>, WithDateCalculations<FieldType.date>>(
    BaseField,
    WithDateCalculations
  ) {
    limits: [Nullable<Date>, Nullable<Date>] = [null, null];
    localValue: DateFieldValue = { value: '' };

    get dateFormat(): Maybe<string> {
      return this.accountStore.data.datetimepicker_date_format;
    }

    get dateValue(): Maybe<Date> {
      return this.stringToDate(this.localValue.value);
    }

    get endDate(): Maybe<Date> {
      return this.limits[1] || undefined;
    }

    get inFormRestrictedByCode(): Maybe<string> {
      if (this.question.config.restrict_to?.restrict_type === 'in_form') {
        return this.question.config.restrict_to.sub_form_question_code;
      }
    }

    get isReadonly(): boolean {
      return this.readonly || (!(this.startDate || this.endDate) && this.isRestricted);
    }

    get isRestricted(): boolean {
      return !!this.question.config.restrict_to?.restrict_type && this.question.config.restrict_to?.if_empty === 'disable';
    }

    get startDate(): Maybe<Date> {
      return this.limits[0] || undefined;
    }

    @Emit('input')
    onDateChange(date: Maybe<Date>): DateFieldValue {
      const value = date && moment(date).format(this.dateFormat);
      this.localValue = { ...this.localValue, value };
      this.sendUpdate(this.localValue);
      this.formObservers.dateUpdate$.next([this.question, date]);
      (this.$refs.datePicker as DatePicker).update(date);
      return this.localValue;
    }

    onDateCalculationsChange(date: Maybe<Date>): void {
      this.onDateChange(date);
      this.calculationInProgress = false;
    }

    setLimits(date?: Date): void {
      this.limits = [null, null];
      if (date) {
        switch (this.question.config.restrict_to?.operator) {
          case '<':
            this.limits = [null, moment(date).subtract(1, 'day').toDate()];
            break;
          case '<=':
            this.limits = [null, date];
            break;
          case '>':
            this.limits = [moment(date).add(1, 'day').toDate(), null];
            break;
          case '>=':
            this.limits = [date, null];
            break;
        }
      }
    }

    beforeMount(): void {
      this.localValue = { ...this.value };
    }

    mounted(): void {
      if (this.inFormRestrictedByCode) {
        this.addSubscription(
          this.formObservers.dateUpdate$
            .pipe(filter(([question]) => question.code == this.inFormRestrictedByCode))
            .subscribe(([, date]) => this.setLimits(date))
        );
        const value = valueInResponse(this.dataByCode?.[this.inFormRestrictedByCode]) as string;
        this.setLimits(this.stringToDate(value));
      } else {
        this.$api
          .getLimits(
            {
              sub_form_completion_id: this.completion?.id,
              record_id: this.completion?.record_id,
              record_type: this.completion?.record_type,
              sub_form_id: this.subFormId,
              sub_form_list_id: this.completion?.sub_form_list_id,
              approval_for_sub_form_completion_id: this.completion?.approval_for_sub_form_completion_id,
            },
            { cache: true }
          )
          .then(({ data }) => {
            const limits = data[this.question.id] || [null, null];
            this.limits = [(limits[0] && moment(limits[0]).toDate()) || null, (limits[1] && moment(limits[1]).toDate()) || null];
          });
      }

      this.$nextTick(() => this.formObservers.dateUpdate$.next([this.question, this.dateValue]));
      this.sendUpdate(this.localValue, true);

      this.initDateCalculations(this.subscriptions, this.formObservers);
    }
  }
