
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import type TabRoute from '@app/utils/types/tab-route';
  import TabMenu from '@app/components/tab-menu.vue';
  import UserActions from '@app/components/admin/users/user-actions.vue';

  @Component({ components: { TabMenu, UserActions } })
  export default class UserSettingsPage extends Vue {
    @Prop(Number) readonly userId!: number;
    @Prop(String) readonly initialTab?: string;
    @Prop(Array) readonly tabs!: TabRoute[];
    @Prop(Boolean) readonly darkTheme?: boolean;

    activeTab = this.initialTab || this.tabs[0]?.key;

    @Watch('$route.name')
    onRouteChanged(routeName: string) {
      const currentTab = this.tabs.find((tab) => tab.route.name === routeName);
      if (currentTab && this.activeTab !== currentTab?.key) {
        this.activeTab = currentTab?.key;
      }
    }

    mounted() {
      this.switchTab(this.initialTab || this.tabs[0]?.key);
    }

    tabChanged(tabKey: string) {
      this.activeTab = tabKey;
      this.switchTab(tabKey);
    }

    switchTab(tabKey: string) {
      const newTab = this.tabs.find((tab) => tab.key === tabKey);
      if (newTab && this.$router.currentRoute.name !== newTab.route.name) {
        this.activeTab = newTab?.key;
        this.$router.push(newTab.route);
      }
    }
  }
