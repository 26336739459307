import type { ModuleName } from '@app/models/module-name';

import type { BaseEntity } from './base-entity';
import type { SubFormSection } from './sub-form-section';
import type { SubFormOptions } from './sub-form-options';
import type { SubFormSectionGroup } from './sub-form-section-group';
import type { SubFormList } from './sub-form-list';
import type { ScoreBandProfile } from './score-band-profile';
import type { SubFormQuestion } from './sub-form-question';

export enum ScoringViewOption {
  both = 'both',
  hide_scoring_view_option = 'hide_scoring_view_option',
  number = 'number',
  percentage = 'percentage',
  score_band = 'score_band',
}

export enum ScoringType {
  addition = 'addition',
  multiplication = 'multiplication',
}

export enum SubFormType {
  approval = 'approval',
  basic = 'basic',
  workflow = 'workflow',
}

export interface SubForm extends BaseEntity {
  active: boolean;
  approval_sub_form_list?: SubFormList;
  description: string;
  free_navigation: boolean;
  module_name: string;
  options: SubFormOptions;
  score_band_profile?: ScoreBandProfile;
  score_band_profile_id: number | null;
  scoring: boolean;
  scoring_group_id: number | null;
  scoring_type: ScoringType;
  scoring_view_option: ScoringViewOption;
  sub_form_lists?: SubFormList[];
  sub_form_module_name?: ModuleName;
  sub_form_questions?: SubFormQuestion[];
  sub_form_section_groups?: SubFormSectionGroup[];
  sub_form_sections?: SubFormSection[];
  sub_form_type: SubFormType;
  system_code: string;
  title: string;
}
