
  import { Component } from 'vue-property-decorator';
  import TextHighlight from 'vue-text-highlight';
  import BaseResponse from './base-response';
  import type { SuperReport } from '@app/models/super-report';

  @Component({ components: { TextHighlight } })
  export default class SuperReportResponse extends BaseResponse<SuperReport> {
    get link(): Nullable<string> {
      if (this.currentUserStore.hasReportingPermission) {
        return `/admin/settings/reports/${this.entity?.id}`;
      }

      return null;
    }
  }
