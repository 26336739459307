
  import { useAccountStore } from '@app/stores/account';
  import BaseTable from '@app/components/base-table/base-table.vue';
  import { entityPathPrefix } from '@app/services/helpers';
  import { keyBy, uniq } from 'lodash';
  import { Component } from 'vue-property-decorator';
  import type { Procedure } from '@app/models/procedure';
  import type { ProcedureAcknowledgement } from '@app/models/procedure-acknowledgement';
  import type { ListManagerField } from '@app/services/list-manager/types';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { mixins } from 'vue-class-component';
  import WithUser from '@app/mixins/with-user';

  @Component({ components: { BaseTable } })
  export default class UserAcknowledgementsPage extends mixins(WithUser) {
    fetchingProcedures = false;
    proceduresHash: Record<number, Pick<Procedure, 'id' | 'name'>> = {};
    manager: Nullable<ListManager<ProcedureAcknowledgement>> = null;
    fields: ListManagerField[] = [
      {
        title: 'ID',
        name: 'procedure_id',
        sortField: 'procedure_id',
      },
      {
        title: 'Knowledge Title',
        name: 'procedure',
      },
      {
        title: 'Date / Time Acknowledged',
        name: 'updated_at',
        sortField: 'updated_at',
      },
    ];

    get accountStore() {
      return useAccountStore();
    }

    procedurePath(procedureId: number): string {
      return `/${entityPathPrefix('Procedure')}/${procedureId}`;
    }

    getManager(): ListManager<ProcedureAcknowledgement> {
      return new ListManager<ProcedureAcknowledgement>({
        fetchDataFunction: (params) => {
          const filters = { ...params.filters, user_id: this.userId };
          return this.$api.getProcedureAcknowledgements({ ...params, filters }, { cache: true });
        },
        afterFetch: (data) => {
          if (data.length) {
            this.fetchingProcedures = true;
            const procedureIds = uniq(data.map((ack) => ack.procedure_id));
            this.$api
              .getProcedures({ filters: { id: procedureIds }, only: ['id', 'name'], per_page: -1 }, { cache: true })
              .then(({ data }) => {
                this.proceduresHash = { ...this.proceduresHash, ...keyBy(data, 'id') };
              })
              .finally(() => (this.fetchingProcedures = false));
          }
        },
        per_page: 25,
        fields: this.fields,
      });
    }

    beforeMount(): void {
      this.manager = this.getManager();
    }
  }
