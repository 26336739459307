import { useAccountStore } from '@app/stores/account';
import { Vue, Component } from 'vue-property-decorator';
import ModalInstance from '@app/components/module-record/modal-instance.vue';
import type { ModalListeners, ModalModes } from '@app/pages/module-records/utils';
import { changeLocation } from '@app/utils/change-location';

interface ModalProps {
  defaultTemplateId?: string;
  hideFooterLinks?: boolean;
  listeners?: ModalListeners;
  mainFormId?: number;
  mode: ModalModes;
  moduleNameId?: number;
  recordId?: number;
  relationships?: Record<string, number>;
  showEdit?: boolean;
  subFormCompletionId?: number;
  title: string;
}
interface OpenModalOrLinkParams {
  event: MouseEvent;
  /**
   * must be a valid relative path
   */
  link: string;
  modal: boolean;
  modalProps?: ModalProps;
  mountToParent?: boolean;
}
@Component
export default class ModuleRecordModals extends Vue {
  get accountStore() {
    return useAccountStore();
  }

  spawnVueModal(params: OpenModalOrLinkParams): void {
    const { modalProps, mountToParent } = params;

    const modalInstance = new ModalInstance({
      parent: !!mountToParent && this.$parent?.$parent ? this.$parent?.$parent : this,
      propsData: { ...modalProps },
    });

    modalInstance.$mount();
  }

  moduleRecordEditTitle(moduleNameDisplay?: string) {
    return moduleNameDisplay
      ? this.$t('app.labels.new_name', { name: moduleNameDisplay })
      : this.$t('tenant.sub_form_completions.show.main_form_completion');
  }

  openModalOrLink(params: OpenModalOrLinkParams): Promise<void | boolean> {
    const { modal, link, event } = params;

    return new Promise((resolve, reject) => {
      if (!link || !event) {
        return reject();
      }

      if (event.ctrlKey || event.metaKey) {
        return resolve(); // event.metaKey for mac cmd + click or other mapped key + click
      }

      event.preventDefault();

      if (modal) {
        this.spawnVueModal(params);
        resolve();
      } else if (link) {
        changeLocation(link);
        resolve();
      } else {
        reject();
      }
    });
  }
}
