export enum DayOfMonth {
  first = 1,
  last = -1,
}

export interface StringOption {
  code: string;
  label: string;
}

export interface NumberOption {
  code: number;
  label: string;
}
