import type { VariableFieldType, DateOrDatetimeFieldType, DateOrDatetimeFieldValue, DaysFieldValue } from '@app/models/date-calculation';
import { FieldType } from '@app/models/sub-form-question';
import type { QuestionTypeMap, SubFormQuestion } from '@app/models/sub-form-question';
import { differenceWith, isEqual, toNumber } from 'lodash';
import moment from 'moment/moment';
import { debounceTime, filter } from 'rxjs/operators';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import type { QuestionSfcOnly } from '../components/sub-form-completion/utils';
import { useAccountStore } from '../stores/account';
import { mergeVisibilityStates } from '../utils/merge-visibility-states';
import type { SubFormDataAndId, SubFormDataValue } from './with-previous-completion-form';
import type { SubFormCompletion } from '@app/models/sub-form-completion';
import type { SubFormData } from '@app/services/api/sub-form-completions-api';
import type { VisibilityStateUpdate } from '@app/utils/types/visibility-state';
import type { DateCalculation } from '@app/components/admin/questions/settings/models';
import type { DateFieldValue } from '@app/models/question-response-types';
import type { Subscription } from 'rxjs';
import type { FormObservers } from '@app/utils/types/form-observers';
import { RE_FETCH_DEBOUNCE_TIME } from '@app/constants';

interface CalculationState {
  hidden?: boolean;
  init?: boolean;
  lookupId?: string;
}

interface TargetCalculationState extends CalculationState {
  type?: DateOrDatetimeFieldType;
  value: DateOrDatetimeFieldValue;
}

interface VariableCalculationState extends CalculationState {
  fieldValue?: DaysFieldValue;
  type?: VariableFieldType;
  value?: string | number;
}

@Component
export class WithDateCalculations<F extends FieldType.datetime | FieldType.date> extends Vue {
  @Ref() readonly calculationError?: HTMLElement;

  @Prop(Object) readonly completion?: Nullable<Partial<SubFormCompletion>>;
  @Prop(Object) readonly lookupHistory!: Record<string, string>;
  @Prop(Object) readonly previousCompletionForm?: SubFormDataAndId;
  @Prop(Object) readonly defaultValues?: SubFormData;
  @Prop(Object) readonly initValues?: SubFormData;
  @Prop(Object) readonly systemCodeToIdMap?: Record<string, string>;
  @Prop(Object) readonly dataById!: SubFormData;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop(Object) readonly params?: { [key: string]: any };

  question!: Pick<SubFormQuestion<QuestionTypeMap<F>['options']>, QuestionSfcOnly>;
  calculationInProgress = false;
  visibilityStates: VisibilityStateUpdate = [];
  currentDatetimeSourceState: Nullable<TargetCalculationState> = null;
  currentDaysSourceState: Nullable<VariableCalculationState> = null;

  get previousValue(): Nullable<SubFormDataValue> {
    return this.previousCompletionForm?.[this.question.id] || null;
  }

  get defaultValue(): Nullable<SubFormData> {
    return this.defaultValues?.[this.question.id] || null;
  }

  get initValue(): Nullable<SubFormData> {
    return this.initValues?.[this.question.id] || null;
  }

  get accountStore() {
    return useAccountStore();
  }

  get calculationDateEnabled(): boolean {
    return !!this.question.config.date_calculation?.source_type;
  }

  get calculationPlaceholder(): Nullable<string> {
    return this.calculationInProgress ? this.$t('components.form_fields.date_field.calculation') : null;
  }

  get creationMode(): boolean {
    return !this.previousValue?.id || this.params?.auto_save === 'true';
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  onDateCalculationsChange(_value: Maybe<Date>): void {}

  stringToDate(date?: string, type?: DateOrDatetimeFieldType): Maybe<Date> {
    return (
      (date &&
        moment(
          date,
          type === FieldType.date
            ? this.accountStore.data.datetimepicker_date_format
            : this.accountStore.data.datetimepicker_datetime_format
        ).toDate()) ||
      undefined
    );
  }

  async evaluateNewDate(config: DateCalculation, dateTimeState: TargetCalculationState, days?: number): Promise<Maybe<Date>> {
    let date: Maybe<Date>;
    const previousValue = this.previousValue;
    if (!dateTimeState.value.value && config?.if_empty === 'reinitialise') {
      const defaultValue = (this.defaultValue as DateFieldValue)?.value;
      const initValue = (this.initValue as DateFieldValue)?.value;
      if (previousValue?.response) {
        date = this.stringToDate((previousValue.response as DateFieldValue)?.value, dateTimeState.type);
      } else if (initValue) {
        date = this.stringToDate(initValue, dateTimeState.type);
      } else if (defaultValue) {
        date = this.stringToDate(defaultValue, dateTimeState.type);
      }
    } else {
      date = this.stringToDate(dateTimeState.value.value, dateTimeState.type);
      if (!!date && days !== undefined) {
        if (this.question.field_type === 'date') {
          date.setDate(date.getDate() + days);
        } else if (this.question.field_type === 'datetime') {
          const millisecondsPerDay = 24 * 60 * 60 * 1000;
          date.setTime(date.getTime() + days * millisecondsPerDay);
        }
      } else {
        date = undefined;
      }
    }
    return date;
  }

  async performCalculation(targetState: TargetCalculationState, variableState: VariableCalculationState): Promise<void> {
    const config = this.question.config.date_calculation;
    if (!config) return;

    const noLookupAndInitDateTime = targetState.init && !targetState.lookupId;
    const noLookupAndInitDays = variableState.init && !variableState.lookupId;
    if (noLookupAndInitDateTime && noLookupAndInitDays && !this.creationMode) return;
    if (variableState.init && !variableState.value) return;
    if ((noLookupAndInitDateTime || noLookupAndInitDays) && this.defaultValue && !this.previousValue?.id) return;

    this.calculationInProgress = true;
    const daysNumber = toNumber(variableState.value);
    const days = variableState.value !== '' && this.hasDays(daysNumber) ? daysNumber : undefined;
    const date = await this.evaluateNewDate(config, targetState, days);
    if (!this.needToRecalculate(date, daysNumber, config)) {
      this.calculationInProgress = false;
      return;
    }

    this.onDateCalculationsChange(date);
  }

  hasDays(value: number): boolean {
    return value === 0 || !isNaN(value);
  }

  needToRecalculate(date: Maybe<Date>, days: number, config: DateCalculation): boolean {
    if (date && this.hasDays(days)) return true;

    switch (config.if_empty) {
      case 'clear':
      case 'reinitialise':
      case undefined:
        return true;
      case 'disabled':
        return false;
    }
  }

  calculationRequest(): void {
    const config = this.question.config.date_calculation;
    if (!this.currentDatetimeSourceState) return;
    if (!config) return;

    if (config.source_type === 'custom') {
      this.performCalculation(this.currentDatetimeSourceState, {
        init: !!this.previousValue?.id,
        ...this.currentDaysSourceState,
        value: config.days,
      });
    } else if (config.source_type === 'question') {
      if (!this.currentDaysSourceState) return;

      const history =
        !!this.currentDaysSourceState.fieldValue &&
        'history' in this.currentDaysSourceState.fieldValue &&
        !!this.currentDaysSourceState.fieldValue.history &&
        JSON.parse(this.currentDaysSourceState.fieldValue.history);
      // TODO: find a way to skip date calculations if days variable is calculator and field value has error in history first time.
      const value = history && history.status === 'error' ? undefined : this.currentDaysSourceState.value;

      this.performCalculation(this.currentDatetimeSourceState, { ...this.currentDaysSourceState, value });
    }
  }

  performCalculationFromDatetime(value: DateOrDatetimeFieldValue, type?: DateOrDatetimeFieldType, init = false, lookupId?: string) {
    this.currentDatetimeSourceState = {
      value,
      lookupId,
      init,
      type,
    };

    const questionLookupId = this.lookupHistory[this.question.id];
    if (!!questionLookupId && init && questionLookupId === lookupId) return;

    this.calculationRequest();
  }

  performCalculationFromDays(
    fieldValue: DaysFieldValue,
    value?: number | string,
    type?: VariableFieldType,
    init = false,
    lookupId?: string
  ) {
    this.currentDaysSourceState = {
      fieldValue,
      value,
      lookupId,
      init,
    };

    const questionLookupId = this.lookupHistory[this.question.id];
    if (!!questionLookupId && init && questionLookupId === lookupId) return;

    this.calculationRequest();
  }

  initCalculationRequest() {
    const config = this.question.config.date_calculation;
    if (!config) return;
    if (!!this.previousValue?.id) return;

    const dateTimeQuestionId = this.systemCodeToIdMap?.[config.datetime_question_code];
    const dateTimeValue = dateTimeQuestionId && this.dataById[dateTimeQuestionId];
    if (!dateTimeValue) return;

    if (config.source_type === 'custom') {
      this.performCalculationFromDatetime(dateTimeValue as DateOrDatetimeFieldValue);
    } else if (config.source_type === 'question') {
      const daysQuestionId = this.systemCodeToIdMap?.[config.days_question_code];
      const daysValue = daysQuestionId && this.dataById[daysQuestionId];
      if (!daysValue) return;

      this.currentDatetimeSourceState = {
        ...this.currentDatetimeSourceState,
        value: dateTimeValue as DateOrDatetimeFieldValue,
      };
      this.performCalculationFromDays(daysValue as DaysFieldValue);
    }
  }

  initDateCalculationStates(config: DateCalculation) {
    const dateTimeQuestionId = this.systemCodeToIdMap?.[config.datetime_question_code];
    const dateTimeValue = dateTimeQuestionId && (this.dataById[dateTimeQuestionId] as DateOrDatetimeFieldValue);
    if (dateTimeValue && dateTimeValue.value) {
      this.currentDatetimeSourceState = {
        init: true,
        value: dateTimeValue,
      };
    }

    if (config.source_type === 'question') {
      const daysQuestionId = this.systemCodeToIdMap?.[config.days_question_code];
      const daysValue = daysQuestionId && (this.dataById[daysQuestionId] as DaysFieldValue);
      if (!daysValue) return;

      this.currentDaysSourceState = {
        value: 'calculation_value' in daysValue ? (daysValue.calculation_value as number) : daysValue.value,
        init: true,
      };
    }
  }

  initDateCalculations(subscriptions: Subscription[], formObservers: FormObservers): void {
    if (!this.calculationDateEnabled) return;
    if (!this.question.config?.date_calculation) return;

    this.initDateCalculationStates(this.question.config.date_calculation);
    this.initSubscriptions(this.question.config.date_calculation, subscriptions, formObservers);
    this.$nextTick(() => this.initCalculationRequest());
  }

  initSubscriptions(config: DateCalculation, subscriptions: Subscription[], formObservers: FormObservers) {
    const dateTime$ = formObservers.fieldUpdate$.pipe(
      filter(([question]) => config.datetime_question_code === question.system_code),
      debounceTime(RE_FETCH_DEBOUNCE_TIME)
    );
    const visibility$ = formObservers.visibilityStateUpdate$.pipe(
      filter((update) => !!differenceWith(update, this.visibilityStates, isEqual).length),
      debounceTime(RE_FETCH_DEBOUNCE_TIME)
    );
    const dateTimeVisibility$ = visibility$.pipe(filter((update) => update.some((u) => config.datetime_question_code === u.system_code)));
    subscriptions.push(
      dateTime$.subscribe(([question, value, init]) => {
        this.performCalculationFromDatetime(value, question.field_type as DateOrDatetimeFieldType, init, this.lookupHistory[question.id]);
      })
    );
    subscriptions.push(
      dateTimeVisibility$.subscribe((update) => {
        this.visibilityStates = [...mergeVisibilityStates(this.visibilityStates, update)];
        const state = update.find((u) => u.system_code === config.datetime_question_code);
        const hidden = !state?.state;
        if (this.currentDatetimeSourceState) this.currentDatetimeSourceState = { ...this.currentDatetimeSourceState, hidden };
        if (hidden) {
          this.performCalculationFromDatetime({ value: undefined });
        } else {
          this.currentDatetimeSourceState &&
            this.performCalculationFromDatetime(
              { value: this.currentDatetimeSourceState.value?.value },
              this.currentDatetimeSourceState.type
            );
        }
      })
    );
    if (config.source_type === 'custom') {
      // nothing
    } else if (config.source_type === 'question') {
      const daysQuestionCode = config.days_question_code;
      const days$ = formObservers.fieldUpdate$.pipe(
        filter(([question]) => daysQuestionCode === question.system_code),
        debounceTime(RE_FETCH_DEBOUNCE_TIME)
      );
      subscriptions.push(
        days$.subscribe(([question, fieldValue, init]) => {
          let value: number | string | undefined = fieldValue.value;
          const type = question.field_type;
          if (type === 'calculation_select') {
            const option = question.options.values && Object.values(question.options.values).find((o) => o.key === fieldValue.value);
            value = (option && 'calculation_value' in option && (option.calculation_value as number)) || 0;
          }
          this.performCalculationFromDays(fieldValue, value, type as VariableFieldType, init, this.lookupHistory[question.id]);
        })
      );
      const daysVisibility$ = visibility$.pipe(filter((update) => update.some((u) => daysQuestionCode === u.system_code)));
      subscriptions.push(
        daysVisibility$.subscribe((daysUpdate) => {
          if (!this.currentDatetimeSourceState && !this.currentDaysSourceState) return;

          this.visibilityStates = [...mergeVisibilityStates(this.visibilityStates, daysUpdate)];
          const state = daysUpdate.find((u) => u.system_code === daysQuestionCode);
          const hidden = state?.state;
          if (this.currentDaysSourceState) this.currentDaysSourceState = { ...this.currentDaysSourceState, hidden };
          const fieldValue = hidden ? { value: undefined } : { ...this.currentDaysSourceState?.fieldValue };
          const value = hidden ? undefined : this.currentDaysSourceState?.value;
          this.performCalculationFromDays(fieldValue as DaysFieldValue, value, this.currentDaysSourceState?.type);
        })
      );
    }
  }
}
