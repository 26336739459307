import type { FormComponent } from '@app/models/form-component';
import type { WithWorkflows } from '@app/models/mixins';
import type { Relationship } from '@app/models/relationship';
import type { Workflow } from '@app/models/workflow';
import type { RoleProfile } from '@app/models/role-profile';
import type { StringBoolean } from '@app/utils/types/string-boolean';
import type { ExpensableModuleBudget } from '@app/models/expensable-module-budget';

import type { BaseEntity } from './base-entity';
import type { FeatureSet } from './feature-set';
import type { SubForm } from './sub-form';
import type { IndicatorSet } from './indicator-set';
import type { Involvement } from './involvement';
import type { RecordCalculation } from './record-calculation';
import type { ModuleTab } from './module-tab';
import type { SubFormList } from './sub-form-list';
import type { ScoreBandProfile } from './score-band-profile';

export const HARDCODED_MODULE_CODES = [
  'Audit',
  'Hazard',
  'Incident',
  'Observation',
  'Procedure',
  'LearningRecord',
  'ChemicalRegister',
  'VisitorRegister',
  'WorkerCompensation',
  'CompanyRegister',
  'Kpi',
  'JobManagementRegister',
  'SafetyDataSheet',
  'GhsChemicalRegister',
  'LmsCourseEnrollment',
  'Report',
];

export const isLegacyModule = (moduleName: Pick<Partial<ModuleName>, 'name'>, exclude: Array<string> = []): boolean => {
  const modulesToCheck = HARDCODED_MODULE_CODES.filter((x) => !exclude.includes(x));
  return !!moduleName.name && modulesToCheck.includes(moduleName.name);
};

export interface BaseIndexOption {
  hide_label?: boolean;
  key: string;
}

export interface ModuleNameIndexOption extends BaseIndexOption {
  custom_secondary_information?: boolean;
  restrict_user_selector_to_users_have_access_to_active_user?: boolean;
  secondary_information?: string[];
  show_secondary_information?: boolean;
  with_restrictions?: boolean;
  wrap_text?: boolean;
}

export interface AvailableIndexOption {
  filterable: boolean;
  name: string;
  type?: 'location' | 'organization' | 'user' | 'record' | 'actions';
}

export enum RecordLinkBehaviour {
  GoToRecord = 'go_to_record',
  QuickEdit = 'quick_edit',
  QuickView = 'quick_view',
}

export interface ShowIdOption {
  exclude?: boolean;
  id: number;
  type: 'sub_form_question' | 'record_calculation' | 'indicator_set' | 'involvement';
  warning?: string;
}

export interface ShowOptions {
  actions?: boolean;
  active_follow_icon?: string;
  attachments?: boolean;
  comments?: boolean;
  follow?: boolean;
  follow_icon?: string;
  quick_add_own_signature?: boolean;
  record_quick_display_config?: ShowIdOption[];
  record_quick_display_field_limit?: number;
  relations?: boolean;
  secondary_information_options?: string[];
  show_indicators?: boolean;
  show_involvements?: boolean;
  show_location?: boolean;
  show_more?: boolean;
  show_organization?: boolean;
  show_qr_code?: boolean;
  show_scoring_groups?: boolean;
  show_total_scoring?: 'none' | 'percent' | 'fraction' | 'score_band';
  signatures?: boolean;
  sub_form_show_ids?: (number | string)[];
  tabs?: boolean;
  workflow?: boolean;
}

export interface WizardShowLinkOptions {
  add_another?: boolean;
  add_another_related?: boolean;
  back_to?: boolean;
  back_to_list?: boolean;
  continue_to?: boolean;
  return_to_dashboard?: boolean;
}

export interface WizardOptionsItem {
  active: boolean;
  custom_link?: {
    active: boolean;
    address?: string;
    icon?: string;
    open_in_new_tab?: boolean;
    text?: string;
  };
  description?: string;
  icon?: string;
  show_links?: WizardShowLinkOptions;
  text?: string;
}

export interface WizardOptions {
  public?: WizardOptionsItem;
  related_tab?: WizardOptionsItem;
  standard?: WizardOptionsItem;
}

// Translatable text data
export interface WizardLabels {
  public_description?: string;
  public_link_text?: string;
  public_text?: string;

  related_tab_description?: string;
  related_tab_link_text?: string;
  related_tab_text?: string;

  standard_description?: string;
  standard_link_text?: string;
  standard_text?: string;
}

export interface ModuleNameConfig {
  index_url?: string;
  use_jwt_hsi?: StringBoolean;
  wizard_options?: WizardOptions;
}

export enum ModuleType {
  form_managed = 'form_managed',
  integrated = 'integrated',
  standard = 'standard',
  transactor = 'transactor',
}
export interface TranslatedType extends BaseEntity {
  description?: string;
  display: string;
  locale?: string;
  system_code: string;
}

export const moduleTypeOptions = ($t: (key: string) => string): Record<ModuleType, string> => ({
  [ModuleType.integrated]: $t('tenant.admin.module_names.sections.general.type_integrated'),
  [ModuleType.form_managed]: $t('tenant.admin.module_names.sections.general.type_form_managed'),
  [ModuleType.standard]: $t('tenant.admin.module_names.sections.general.type_standard'),
  [ModuleType.transactor]: $t('tenant.admin.module_names.sections.general.type_transactor'),
});

export const UNCATEGORISED = 'uncategorised';

export interface ModuleName extends BaseEntity, WithWorkflows {
  active: boolean;
  adhoc_as_template_option?: boolean;
  allow_editing_for_settings_admin?: boolean;
  available_index_options?: { [key: string]: AvailableIndexOption };
  can_add_contacts_from_form?: boolean;
  config: ModuleNameConfig;
  custom_title: string;
  default: string;
  description?: string;
  display: string;
  feature_set?: FeatureSet;
  form_components?: FormComponent[];
  from_relationships?: Relationship[];
  hard_delete_attachments: boolean;
  hard_delete_records: boolean;
  hierarchy_type: string;
  hybrid_question_code: string;
  index_option_labels: Record<string, string>;
  index_options: ModuleNameIndexOption[];
  indicator_sets?: IndicatorSet[];
  involvements?: Involvement[];
  location_sub_form_question_id?: number;
  main_form?: SubForm;
  menu_collection_id?: number;
  module_budgets?: ExpensableModuleBudget[];
  module_tabs?: ModuleTab[];
  module_type: ModuleType;
  name: string;
  organization_sub_form_question_id?: number;
  plural_display: string;
  published_module_version: {
    changelog?: string;
    identifier?: string;
    installation_date?: string;
    internal_name?: string;
    locally_sourced?: boolean;
    version_number?: string;
  };
  record_calculations?: RecordCalculation[];
  record_link_behaviour: RecordLinkBehaviour;
  record_prefix: string;
  role_profiles?: RoleProfile[];
  scheme_sub_form_question_id?: number;
  score_band_profile?: ScoreBandProfile;
  score_band_profile_id?: number | null;
  show_in_app: boolean;
  show_options: ShowOptions;
  solution_type: string;
  start_workflow?: Workflow;
  sub_form_id?: number;
  sub_form_lists?: SubFormList[];
  sub_forms?: SubForm[];
  to_relationships?: Relationship[];
  use_location_permissions: boolean;
  use_organization_permissions: boolean;
  wizard_labels: WizardLabels;
}

enum BaseFilterOptions {
  actions = 'actions',
  created_at = 'created_at',
  created_by = 'created_by',
  follow = 'follow',
  follow_count = 'follow_count',
  followed_at = 'followed_at',
  location = 'location',
  module_name = 'module_name',
  organization = 'organization',
  parent = 'parent',
  score = 'score',
  score_band = 'score_band',
  state = 'state',
  title = 'title',
  uniq_id = 'uniq_id',
}

export const BASE_FILTER_OPTIONS: Record<BaseFilterOptions, AvailableIndexOption> = {
  [BaseFilterOptions.followed_at]: {
    name: 'Followed at',
    filterable: true,
  },
  [BaseFilterOptions.module_name]: {
    name: 'Module Name',
    filterable: true,
  },
  [BaseFilterOptions.uniq_id]: {
    name: 'Record ID',
    filterable: false,
  },
  [BaseFilterOptions.title]: {
    name: 'Title',
    filterable: false,
  },
  [BaseFilterOptions.state]: {
    name: 'State',
    filterable: true,
  },
  [BaseFilterOptions.created_at]: {
    name: 'Created Date',
    filterable: false,
  },
  [BaseFilterOptions.created_by]: {
    name: 'Created By',
    filterable: false,
  },
  [BaseFilterOptions.location]: {
    name: 'Location',
    filterable: true,
    type: 'location',
  },
  [BaseFilterOptions.organization]: {
    name: 'Organization',
    filterable: true,
    type: 'organization',
  },
  [BaseFilterOptions.score]: {
    name: 'Score',
    filterable: false,
  },
  [BaseFilterOptions.parent]: {
    name: 'Parent',
    filterable: false,
    type: 'record',
  },
  [BaseFilterOptions.score_band]: {
    name: 'Score Band',
    filterable: true,
  },
  [BaseFilterOptions.follow]: {
    name: 'Follow',
    filterable: false,
  },
  [BaseFilterOptions.follow_count]: {
    name: 'Follow Count',
    filterable: false,
  },
  [BaseFilterOptions.actions]: {
    name: 'Actions',
    filterable: false,
  },
};
