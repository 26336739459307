
  import UserLocationTagForm from '@app/components/user-location-tags/user-location-tag-form.vue';
  import type { UserLocationTag } from '@app/models/user-location-tag';
  import { Component, Model, Prop, Ref, Vue } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { UserLocationTagForm, DsModal } })
  export default class UserLocationTagFormModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Prop(String) readonly title?: string;
    @Prop(Object) readonly userLocationTag?: Partial<UserLocationTag>;
    @Prop(Boolean) readonly noUserSelection?: boolean;
    @Prop(Boolean) readonly noLocationSelection?: boolean;

    @Ref() readonly form?: UserLocationTagForm;

    reset(): void {
      this.form?.reset();
    }
  }
