
  import { Component, Emit } from 'vue-property-decorator';
  import { Youtube, getIdFromUrl } from 'vue-youtube';
  import { vueVimeoPlayer } from 'vue-vimeo-player';
  import type { VideoFieldValue } from '@app/models/question-response-types';
  import type { FieldType } from '@app/models/sub-form-question';

  import BaseField from './base-field';

  @Component({ components: { Youtube, vueVimeoPlayer } })
  export default class VideoField extends BaseField<FieldType.video> {
    defaultVimeoWidth = 640;
    localValue = {};

    get isVimeo(): boolean {
      return this.question.config.video_url.indexOf('vimeo') > -1;
    }

    get youtubeVideoId(): string {
      return getIdFromUrl(this.question.config.video_url);
    }

    get vimeoVideoId(): string {
      return this.question.config.video_url;
    }

    @Emit('input')
    onComplete(): VideoFieldValue {
      this.localValue = { value: 'complete' };
      return this.localValue;
    }

    beforeMount(): void {
      this.localValue = { ...this.value };
    }

    onLoaded(): void {
      const width = $(this.$el).closest(':visible').width();
      $(this.$el)
        .find('iframe')
        .attr('width', width || this.defaultVimeoWidth);
    }
  }
