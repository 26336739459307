import { debounce } from 'lodash';
import type { VNodeDirective } from 'vue';

const handlers = new Map<HTMLElement, ReturnType<typeof debounce>>();

export default {
  inserted: (el: HTMLElement, binding: VNodeDirective) => {
    if (shouldAbort(el)) return;
    setTimeout(() => {
      const handler = debounce(() => updateHeaderWrapping(el, binding), 100);
      handlers.set(el, handler);
      window.addEventListener('resize', handler);
      handler();
    }, 1);
  },
  update: (el: HTMLElement, binding: VNodeDirective) => {
    if (shouldAbort(el)) return;
    const handler = handlers.get(el);
    if (handler) handler();

    // Check if the trigger value has changed
    if (binding.value?.trigger !== binding.oldValue?.trigger) {
      updateHeaderWrapping(el, binding);
    }
  },
  unbind: (el: HTMLElement) => {
    const handler = handlers.get(el);
    if (handler) {
      window.removeEventListener('resize', handler);
      handlers.delete(el);
    }
  },
};

const updateHeaderWrapping = (el: HTMLElement, binding: VNodeDirective) => {
  if (!shouldAbort(el)) {
    const columnIndex = binding.value.index;
    let shouldAdjustHeaderWidth = false;
    const relatedCells = document.querySelectorAll(`.simple-grid-td[data-index="${columnIndex}"]`);

    const cellWidths = Array.from(relatedCells).map((cell) => widthWithoutPadding(cell));
    const maxCellWidth = Math.max(...cellWidths);
    const elementWidth = widthWithoutPadding(el);
    relatedCells.forEach((cell) => {
      if (widthWithoutPadding(cell) > elementWidth) {
        shouldAdjustHeaderWidth = true;
      }
    });
    if (shouldAdjustHeaderWidth) {
      el.style.maxWidth = maxCellWidth + 'px';
      el.style.width = 'auto';
    }
  }
};

const widthWithoutPadding = (el: Element) => {
  const style = window.getComputedStyle(el);
  const widthWithoutPadding = style.getPropertyValue('width');
  return parseFloat(widthWithoutPadding);
};

const shouldAbort = (el: HTMLElement) => {
  return !el.classList.contains('simple-grid-th') || !el.classList.contains('wrap-column-title');
};
