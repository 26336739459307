
  import { Component } from 'vue-property-decorator';
  import TextHighlight from 'vue-text-highlight';
  import BaseResponse from './base-response';
  import type { SubFormCompletion } from '@app/models/sub-form-completion';

  @Component({ components: { TextHighlight } })
  export default class SubFormCompletionResponse extends BaseResponse<SubFormCompletion> {
    get link(): string {
      return `/sub_form_completions/${this.$attrs.raw}`;
    }
  }
