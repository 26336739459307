import moment from 'moment/moment';
import { ResponseType } from '../models';
import { versionTime } from '../utils';
import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import { UserService } from './user-service';
import type { Document } from '@app/models/document';
import type { PaperTrailChange } from '@app/models/paper-trail-version';
import type { DonesafeApi } from '@app/services/donesafe-api';

export class DocumentService extends BaseService<Document> {
  static readonly allowedAttributes = ['name', 'description', 'user_id', 'valid_until'];
  static readonly attributeServices: Partial<Record<keyof Document, string>> = {
    user_id: 'User',
  };

  async fetchDetails({ itemId, version }: ServiceRequest<Document>): Promise<DetailsServiceResponse> {
    const userId = this.extractValue<number>('user_id', version);
    if (!userId) return {};

    const userService = new UserService(this.config);
    const userDetails = await userService.fetchDetailsById(userId);
    return { ...userDetails, subText: `ID: ${itemId}` };
  }

  async fetchEntity({ itemId }: ServiceRequest<Document>): Promise<Document> {
    return DocumentService.fetchEntity(this.api, itemId);
  }

  static async fetchEntity(api: DonesafeApi, itemId: Document['id']): Promise<Document> {
    const { data } = await api.getDocument(itemId, { only: ['id', 'name'] }, { join: true, cache: true });
    return data;
  }

  entityToText({}: ServiceRequest<Document>, entity?: Document): string {
    return entity ? `${entity.name} [${entity.id}]` : '';
  }

  normalizeText(change: Nullable<PaperTrailChange>, key: keyof Document): Nullable<string> {
    if (change) {
      if (key === 'valid_until') {
        return moment(change).format(this.account?.datetimepicker_datetime_format);
      }
    }
    return super.normalizeText(change, key);
  }

  normalizeValue(change: Nullable<PaperTrailChange>, key: keyof Document): Nullable<PaperTrailChange> {
    if (key === 'valid_until') {
      return change ? versionTime(this.account, change as string) : '';
    }
    return super.normalizeValue(change, key);
  }

  responseType(key: keyof Document): ResponseType {
    if (key === 'valid_until') return ResponseType.DateTime;

    return super.responseType(key);
  }
}
