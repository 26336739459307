import type { ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { RoleProfile } from '@app/models/role-profile';
import type { DonesafeApi } from '@app/services/donesafe-api';

export class RoleProfileService extends BaseService<RoleProfile> {
  static serviceTitle = ($t: (key: string) => string): string => $t('app.labels.role_profile');
  static readonly allowedAttributes = ['active', 'code', 'description', 'module_name_id', 'name', 'notes', 'required'];
  static attributeNames($t: (key: string) => string): Partial<Record<keyof RoleProfile, string>> {
    return {
      module_name_id: $t('app.labels.module'),
    };
  }
  static readonly attributeServices: Partial<Record<keyof RoleProfile, string>> = {
    module_name_id: 'ModuleName',
  };

  entityToText({}: ServiceRequest<RoleProfile>, entity?: RoleProfile): string {
    return entity ? RoleProfileService.toText(entity) : '';
  }

  async fetchEntity({ itemId }: ServiceRequest<RoleProfile>): Promise<Partial<RoleProfile>> {
    return await RoleProfileService.fetchEntity(this.api, itemId);
  }

  static async fetchEntity(api: DonesafeApi, itemId: RoleProfile['id']): Promise<Partial<RoleProfile>> {
    const { data } = await api.getRoleProfile(itemId, { only: ['id', 'name'] }, { cache: true, join: true });
    return data;
  }

  static toText(entity: Pick<RoleProfile, 'id' | 'name'>): string {
    return `${entity.name} [${entity.id}]`;
  }
}
