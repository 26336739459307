import type { OnlyOptions } from '@app/services/donesafe-api-utils';
import type { AutomatedEvent } from '@app/models/automated-event';

import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';

export const AUTOMATED_EVENT_ONLY: OnlyOptions<AutomatedEvent> = [
  'id',
  'index',
  'event_id',
  'automation_definition_id',
  'automation_event_group_id',
];

export class AutomatedEventService extends BaseService<AutomatedEvent> {
  static readonly allowedAttributes = ['index', 'event_id', 'automation_definition_id', 'automation_event_group_id'];

  static attributeNames($t: (key: string) => string): Partial<Record<keyof AutomatedEvent, string>> {
    return {
      index: $t('app.labels.order'),
      automation_event_group_id: $t('app.labels.group_id'),
    };
  }

  async fetchDetails({ itemId }: ServiceRequest<AutomatedEvent>): Promise<DetailsServiceResponse> {
    const { data: automated_event } = await this.api.getAutomatedEvent(itemId, { only: AUTOMATED_EVENT_ONLY }, { join: true, cache: true });

    return {
      links: [
        {
          link: `/admin/settings/automation_definitions/${automated_event.automation_definition_id}/edit?event_id=${automated_event.event_id}`,
          prefix: this.t('app.labels.event'),
          title: '',
          id: automated_event.event_id,
        },
      ],
    };
  }
}
