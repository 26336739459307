import type { BaseEntity } from './base-entity';
import type { WithTenantUser } from './mixins';

export enum RecordRelationMode {
  from = 'from',
  to = 'to',
}

export type RelationForeignKey = 'from_id' | 'to_id';

export interface RecordRelation extends BaseEntity, WithTenantUser {
  relationship_code: string;
  from_id: number;
  to_id: number;
}
