
  import { Component, Vue, Model, Emit, Prop } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import type { BulkEditUpdateParams } from './utils';
  import { BULK_EDIT_IGNORE_VALUE, getEditableColumnComponent } from './utils';
  import { EDITABLE_COLUMNS_COMPONENTS } from './table/editable-columns/components';
  import type {
    BaseTableCalculationColumnConfig,
    TableCalculationColumnConfig,
    TableCalculationColumnEditable,
  } from '@app/models/question-options/table-calculation-question-options';

  @Component({ components: { DsModal, ...EDITABLE_COLUMNS_COMPONENTS } })
  export default class BulkEditModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Prop(Array) readonly columns!: TableCalculationColumnConfig[];

    @Emit('update')
    update(): BulkEditUpdateParams {
      return Object.entries(this.valuesToUpdate).reduce((acc, [code, { value, type }]) => {
        // ignore certain fields from bulk edit based on their value and type
        if (
          !(
            (type === 'select' && value === BULK_EDIT_IGNORE_VALUE) ||
            (type === 'text' && value === '') ||
            (type === 'date' && value === null)
          )
        ) {
          acc[code] = { value, type };
        }
        return acc;
      }, {} as BulkEditUpdateParams);
    }

    @Emit('input')
    close() {
      return false;
    }

    getEditableColumnComponent = getEditableColumnComponent;
    valuesToUpdate: BulkEditUpdateParams = {};

    get title(): string {
      return 'Quick Edit';
    }

    updateValue(value: string | number | null, code: string) {
      this.valuesToUpdate = {
        ...this.valuesToUpdate,
        [code]: {
          ...(this.valuesToUpdate?.[code] || {}),
          value,
        },
      };
    }

    defaultValue(column: BaseTableCalculationColumnConfig & TableCalculationColumnEditable): typeof BULK_EDIT_IGNORE_VALUE | '' | null {
      if (column?.type === 'select') {
        return BULK_EDIT_IGNORE_VALUE;
      } else if (column?.type === 'date') {
        return null;
      } else {
        return '';
      }
    }

    initDefaults(): void {
      this.valuesToUpdate = this.columns.reduce((memo, column) => {
        if ('type' in column) memo[column.code] = { type: column.type, value: this.defaultValue(column) };
        return memo;
      }, {} as BulkEditUpdateParams);
    }

    beforeMount() {
      this.initDefaults();
    }
  }
