
  import { Component, Prop, Model, Watch } from 'vue-property-decorator';
  import { get, extend } from 'lodash';
  import { v4 as generateUUID } from 'uuid';
  import type { Dictionary } from '@app/models/dictionary';
  import { isSafariBrowser } from '@app/utils/is-safari-browser';
  import WithSelect2Accessibility from '@app/mixins/with-select2-accessibility';
  import { isIos } from '@app/utils/is-ios';

  interface SelectOption {
    disabled?: boolean;
    group?: string;
    id: string | number;
    text: string;
  }

  interface GroupedOption {
    children: SelectOption[];
    text: string;
  }

  type SelectValue = string | string[] | number;

  type Select2Option = Record<string, unknown> | string[] | string;

  const DISALLOWED_SELECT2_PARENTS = [
    '[role=cell]',
    '[role=row]',
    '[role=rowgroup]',
    '[role=table]',
    'td',
    'tr',
    'tbody',
    'table',
    'thead',
    'tfoot',
    '.base-table',
  ];

  @Component({})
  export default class Select2 extends WithSelect2Accessibility {
    @Model('input', { type: [String, Number, Array] }) readonly value!: SelectValue;
    @Prop(Array) readonly options?: Select2Option[];
    @Prop(Boolean) readonly multiple?: boolean;
    @Prop(Boolean) readonly sortable?: boolean;
    @Prop(Boolean) readonly allowClear?: boolean;
    @Prop(Boolean) readonly readonly?: boolean;
    @Prop(Boolean) readonly disabled?: boolean;
    @Prop(Boolean) readonly required?: boolean;
    @Prop(Boolean) readonly loading?: boolean;
    @Prop(Boolean) readonly appendSelection?: boolean;
    @Prop(Boolean) readonly autoFocus?: boolean;
    @Prop([String, Array]) readonly labelKey?: string | string[];
    @Prop([String, Array]) readonly valueKey?: string | string[];
    @Prop(String) readonly dropdownParent?: string;
    @Prop(String) readonly url?: string;
    @Prop(Number) readonly minimumInputLength?: number;
    @Prop(String) readonly placeholder?: string;
    @Prop(String) readonly blankValue?: string;
    @Prop(Object) readonly ajax?: object;
    @Prop([String, Array]) readonly groupBy?: string | string[];
    @Prop(Function) readonly templateResult?: (result: object) => JQuery;
    @Prop(Function) readonly templateSelection?: (result: object) => JQuery;
    @Prop(Function) readonly matcher?: (params: object, data: object) => JQuery;
    @Prop(Function) readonly openMethod?: () => boolean;
    @Prop(Function) readonly noResultsMethod?: () => JQuery;
    @Prop(Function) readonly selectMethod?: () => boolean;
    @Prop(Function) readonly sorterMethod?: (options: SelectOption[]) => SelectOption[];

    uuid: string = generateUUID();

    get dataOptions(): SelectOption[] {
      return (
        this.options?.map((o) => {
          return {
            id: `${this.optionValue(o)}`,
            text: `${this.optionLabel(o)}`,
            group: this.optionGroup(o),
            disabled: get(o, 'disabled'),
          };
        }) || []
      );
    }

    get ajaxSettings(): Maybe<object> {
      if (this.ajax) {
        return this.ajax;
      } else if (this.url) {
        return {
          url: this.url,
          delay: 250,
          dataType: 'json',
        };
      }
    }

    get groupedDataOptions(): GroupedOption[] {
      return Object.values(
        this.dataOptions.reduce<Dictionary<GroupedOption>>((memo, option) => {
          const key = option.group || '';
          if (!memo[key]) {
            memo[key] = {
              text: key,
              children: [],
            };
          }
          memo[key].children.push(option);
          return memo;
        }, {})
      );
    }

    get isDisabled() {
      return !!(this.disabled || this.readonly);
    }

    @Watch('loading', { immediate: true })
    checkLoading(value: boolean): void {
      $(this.$el).toggleClass('loading', value);
    }

    @Watch('value')
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    onValueChanged(value: any): void {
      this.populateSelect2AndEmitValue(value, false);
    }

    @Watch('options')
    onOptionsChanged(): void {
      const $el = $(this.$el);
      const emptyPlaceholder = this.placeholder || this.$t('app.labels.select_placeholder');
      const emptyOption = new Option(emptyPlaceholder, '');

      $el.empty();

      if (this.groupBy) {
        const groups = this.groupedDataOptions;

        if (this.allowClear && isSafariBrowser()) {
          $el.append(emptyOption);
        }

        groups.forEach((group) => {
          const $group = $(`<optgroup label="${group.text}" />`);
          group.children.forEach((o) => {
            $group.append(this.createOptionElement(o));
          });
          $el.append($group);
        });
      } else {
        let options = this.dataOptions;
        if (this.multiple) {
          const arrayValue = Array.isArray(this.value) ? this.value : [this.value];
          (arrayValue as string[])?.forEach((v) => {
            const option = options.find((o) => o.id === v);
            if (option) {
              $el.append(this.createOptionElement(option));
              options = options.filter((o) => o.id !== v);
            }
          });
        } else if (this.allowClear && isSafariBrowser()) {
          $el.append(emptyOption);
        }
        options.forEach((o) => $el.append(this.createOptionElement(o)));
      }
      $el.val(this.value as string).trigger('change');
      this.$nextTick(() => $el.trigger('change'));
    }

    optionLabel(option: Select2Option): string {
      // eslint-disable-line @typescript-eslint/no-explicit-any
      if (typeof option === 'string') {
        return option;
      } else if (Array.isArray(option)) {
        return option[1] || option[0]; // label first if array is passed: ['value', 'label']
      } else {
        return get(option, this.labelKey || 'label');
      }
    }

    optionValue(option: Select2Option): string | number {
      // eslint-disable-line @typescript-eslint/no-explicit-any
      if (typeof option === 'string') {
        return option;
      } else if (Array.isArray(option)) {
        return option[0]; // value last if array is passed: ['value', 'label']
      } else {
        return get(option, this.valueKey || 'value');
      }
    }

    open(): void {
      $(this.$el).select2('open');
    }

    close(): void {
      $(this.$el).select2('close');
    }

    toggle(value: boolean): void {
      value ? this.open() : this.close();
    }

    focusSearch(): void {
      $(this.$el).siblings('.select2').find('input.select2-search__field').trigger('focus');
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    populateSelect2AndEmitValue(value: any, emitNewValue: boolean): void {
      $(this.$el).val(value);
      this.$nextTick(() => $(this.$el).trigger('change'));
      emitNewValue && this.$emit('input', value);
    }

    createOptionElement(option: SelectOption, selected = false): HTMLOptionElement {
      const optionElement = new Option(option.text, option.id as string, selected, selected);
      if (option.disabled) {
        optionElement.disabled = true;
      }
      return optionElement;
    }

    defaultGroupMatcher(params: { term: string }, data: GroupedOption): GroupedOption | null {
      //  If there are no search terms, return all of the data
      if (!params.term?.trim()) return data;

      // Skip if there is no 'children' property
      if (typeof data?.children === 'undefined') return null;

      // `data.children` contains the actual options that we are matching against
      let filteredChildren: SelectOption[] = [];
      data.children.forEach((child: SelectOption) => {
        if ([child.text.toUpperCase(), data.text.toUpperCase()].some((i) => i.indexOf(params.term.toUpperCase()) !== -1)) {
          filteredChildren = [...filteredChildren, child];
        }
      });

      // If we matched any of the group's children or group header text, then set the matched children on the group
      // and return the group object
      if (filteredChildren.length) {
        let modifiedData = extend({}, data);
        modifiedData.children = filteredChildren;
        // You can return modified objects from here
        // This includes matching the `children` how you want in nested data sets
        return modifiedData;
      }

      // Return `null` if the term should not be displayed
      return null;
    }

    select2Settings(): object {
      const dropdownParent = this.dropdownParent
        ? $(this.dropdownParent)
        : $(this.$el)
            .parent()
            .closest(`:not(${DISALLOWED_SELECT2_PARENTS.join(', ')})`);
      return {
        placeholder: this.placeholder || (this.allowClear && this.$t('app.labels.select_placeholder')) || undefined,
        allowClear: this.allowClear,
        templateResult: this.templateResult,
        templateSelection: this.templateSelection,
        minimumInputLength: this.minimumInputLength,
        ajax: this.ajaxSettings,
        matcher:
          typeof this.matcher === 'function' ? this.matcher : typeof this.groupBy !== 'undefined' ? this.defaultGroupMatcher : undefined,
        dropdownParent,
        language: { noResults: this.noResultsMethod },
        sorter: this.sorterMethod,
        labelledByBefore: this.labelledByBefore,
        labelledByAfter: this.labelledByAfter,
        describedBy: this.describedBy,
        disabled: this.isDisabled,
      };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    optionGroup(option: any): string | undefined {
      // eslint-disable-line @typescript-eslint/no-explicit-any
      return this.groupBy && get(option, this.groupBy);
    }

    addOption(id: string, text: string, selected = false): void {
      const newOption = this.createOptionElement({ text, id }, selected);
      this.appendOption(newOption);
    }

    appendOption(newOption: HTMLOptionElement): void {
      const $select = $(this.$el);
      $select.append(newOption);
    }

    initSelect2(): void {
      const $select = $(this.$el);
      $select.select2(this.select2Settings()).val(this.value).trigger('change');

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      $select.on('select2:select', (e: any) => {
        // eslint-disable-line @typescript-eslint/no-explicit-any
        this.$emit('select', e.params.data);
        this.appendSelection && this.appendOption(e.params.data.element);
        this.toggleValue();
      });
      $select.on('select2:close', () => this.$nextTick(() => this.$emit('close')));
      $select.on('select2:open', () => this.$nextTick(() => this.$emit('open')));
      $select.on('focus', () => this.open());
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      $select.on('select2:unselect', (e: any) => {
        !e.params?.clearing && this.toggleValue();
        e.params.originalEvent?.stopPropagation();
      });

      $select.on('select2:clear', () => {
        this.toggleValue();
        $select.on('select2:opening.cancelOpen', (e) => {
          e.preventDefault();
          $select.off('select2:opening.cancelOpen');
        });
      });

      this.openMethod && $select.on('select2:opening', this.openMethod);
      this.selectMethod && $select.on('select2:selecting', this.selectMethod);

      if (this.multiple && this.sortable) {
        const Utils = ($.fn.select2 as any).amd.require('select2/utils'); // eslint-disable-line @typescript-eslint/no-explicit-any
        $select
          .siblings('.select2')
          .find('ul.select2-selection__rendered')
          .sortable({
            items: '> .select2-selection__choice',
            update: (event: Event, ui: { item: JQuery }) => {
              ui.item
                .siblings('.select2-selection__choice')
                .addBack()
                .each((n, el) => {
                  const option = $select.children('[value="' + Utils.GetData(el, 'data').id + '"]');
                  option.detach();
                  $select.append(option);
                });
              $select.trigger('change');
              this.$emit('input', $select.val());
            },
          });
      }
      if (this.autoFocus) {
        this.focusSearch();
      }

      if (!this.multiple && (isSafariBrowser() || isIos())) {
        $select.siblings('.select2').on('click', this.triggerSearchFocusOnOpen);
      }
    }

    triggerSearchFocusOnOpen(): void {
      $(this.$el).select2('isOpen') && $(this.$el).siblings('.select2-container').find('input.select2-search__field').trigger('focus');
    }

    mounted(): void {
      this.onOptionsChanged();
      $(this.initSelect2);
    }

    toggleValue(): void {
      this.$nextTick(() => this.$emit('input', $(this.$el).val() || this.blankValue));
    }

    reFetch(): void {
      $(this.$el).select2('reFetch');
    }

    beforeDestroy(): void {
      const $el = $(this.$el);
      $el.off();
      $(this.$el).siblings('.select2').off('click', this.triggerSearchFocusOnOpen);
      // close select2 if it's open, otherwise scroll can be broken for the whole page
      if ($el.data('select2')?.isOpen()) $el.select2('close');
      const instance = $el.data('select2');
      instance && $el.select2('destroy');
    }
  }
