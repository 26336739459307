
  import { Component } from 'vue-property-decorator';
  import BaseResponse from './base-response';
  import TextHighlight from 'vue-text-highlight';
  import type { ModuleName } from '@app/models/module-name';

  @Component({ components: { TextHighlight } })
  export default class ModuleNameResponse extends BaseResponse<ModuleName> {
    get moduleNameLink(): Nullable<string> {
      if (this.currentUserStore.hasModuleConfigPermission) {
        return `/admin/settings/module_names/${this.entity.id}`;
      }

      return null;
    }
  }
