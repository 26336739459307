import { Vue, Component } from 'vue-property-decorator';

const INPUT_TOGGLE = 'input.v-switch-input';

@Component
export default class WithToggleButtonDomEvents extends Vue {
  toggleKeyDownEvent(e: KeyboardEvent) {
    if (e.key === 'Enter') e.preventDefault();
  }

  mounted() {
    this.$el.querySelector<HTMLInputElement>(INPUT_TOGGLE)?.addEventListener('keydown', this.toggleKeyDownEvent);
  }
  beforeDestroy() {
    this.$el.querySelector<HTMLInputElement>(INPUT_TOGGLE)?.removeEventListener('keydown', this.toggleKeyDownEvent);
  }
}
