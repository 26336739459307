
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { MaterialIconStyle } from '@app/utils/types/material-icon-style';

  @Component({ components: {} })
  export default class DsIcon extends Vue {
    @Prop(String) readonly icon?: string;
    @Prop({ type: String, default: 'default' }) readonly iconStyle?: MaterialIconStyle;
    @Prop(String) readonly color?: string;
    @Prop(Boolean) readonly fa?: boolean;
    @Prop({ default: 'sm' }) readonly size!: 'sm' | 'md' | 'lg' | 'xl';

    get iconStyleClasses() {
      if (!!this.fa) {
        return {
          fa: true,
          [`fa-${this.icon}`]: true,
        };
      } else {
        return {
          'material-icons': this.iconStyle === MaterialIconStyle.Default,
          'material-icons-outlined': this.iconStyle === MaterialIconStyle.Outlined,
          'material-icons-round': this.iconStyle === MaterialIconStyle.Round,
          'material-icons-sharp': this.iconStyle === MaterialIconStyle.Sharp,
          'material-icons-two-tone': this.iconStyle === MaterialIconStyle.TwoTone,
        };
      }
    }

    get iconSize() {
      switch (this.size) {
        case 'sm':
          return '16px';
        case 'md':
          return '24px';
        case 'lg':
          return '32px';
        case 'xl':
          return '48px';
      }
    }
  }
