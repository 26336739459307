
  import { isEmpty } from 'lodash';
  import DropdownSelect from './dropdown-select.vue';
  import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component({ components: { DropdownSelect } })
  export default class FilterSelect extends Vue {
    @Prop([Array, String]) readonly filter?: string[];
    @Prop({ type: Boolean, default: true }) menuRight!: boolean;
    @Prop(Boolean) readonly disabled?: boolean;
    @Prop(Boolean) readonly datetimeSelector?: boolean;

    get badgeValue(): number {
      if (!this.filter) {
        return 0;
      }
      // handle ranges as a single value
      if (this.datetimeSelector) {
        return isEmpty(this.filter) ? 0 : 1;
      }
      return Array.isArray(this.filter) ? this.filter.length : 1;
    }
  }
