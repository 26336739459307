
  import I18n from '@app/i18n';
  import DsModal from '@app/components/ds-modal.vue';
  import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
  import { Component, Model, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
  import type { QuestionSfcOnly } from '../sub-form-completion/utils';
  import type { ContactUserData } from '@app/models/contact-user-data';
  import type { MultiPersonSelectorTempFieldValue } from '@app/models/question-response-types';
  import type { FieldType, QuestionTypeMap, SubFormQuestion } from '@app/models/sub-form-question';
  import { v4 as generateUUID } from 'uuid';

  @Component({ components: { DsModal, ValidationObserver, ValidationProvider } })
  export default class ContactUserFormModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Prop(Object) readonly question!: Pick<SubFormQuestion<QuestionTypeMap<FieldType.multi_person_selector>['options']>, QuestionSfcOnly>;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;
    @Prop(Boolean) readonly isPublic!: boolean;
    @Prop(Array) readonly newUsers!: MultiPersonSelectorTempFieldValue[];

    init = false;
    user: Partial<ContactUserData> = { profile: {} };

    @Watch('value', { immediate: true })
    watchModalVisible(value: boolean): void {
      value && this.initForm();
      this.init = value;
      this.validator?.reset();
    }

    initForm(): void {
      this.user = { profile: {} };
    }

    beforeMount(): void {
      extend('uniq_email', {
        validate: (value: string) => {
          if (this.newUsers.some((newUser) => newUser.user?.email === value)) {
            return { valid: false };
          }

          return this.$api
            .getTenantUsers({ filters: { email: value }, only: ['id'], per_page: 1 }, { cache: true })
            .then(({ data }) => {
              return { valid: !data.length };
            })
            .catch(({ data }) => {
              return {
                valid: false,
                data: data?.error || I18n.t('app.labels.something_went_wrong'),
              };
            });
        },
        message: this.$t('errors.contact_users.email_uniqueness'),
      });
    }

    submitForm(): void {
      this.validator?.validate().then((result: boolean) => {
        if (result) {
          const fullName = `${this.user.first_name} ${this.user.last_name}`;

          this.$emit('input', false);
          this.$emit('create', { ...this.user, id: generateUUID(), full_name: fullName });
        }
      });
    }
  }
