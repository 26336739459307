import type { DateRestrictTo } from '../../components/admin/questions/settings/models';
import type { BaseQuestionOptions } from './base-question-options';
import type { WithDateCalculation } from './shared';

export enum DatePickerUIOptions {
  date = 'date',
  year = 'year',
}

export interface DateQuestionOptions extends BaseQuestionOptions, WithDateCalculation {
  date_picker_ui?: DatePickerUIOptions;
  default: {
    sub_form_question_code: string;
    // number as a string
    transform: string;
    type: 'no_default' | 'main_form' | 'days_after' | 'most_recent_form' | 'highest_form' | 'lowest_form';
  };
  restrict_to?: DateRestrictTo;
}
