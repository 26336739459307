import type { ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { ConfidentialityType } from '@app/models/confidentiality-type';

export class ConfidentialityTypeService extends BaseService<ConfidentialityType> {
  fetchEntity({ itemId }: ServiceRequest<ConfidentialityType>): Promise<ConfidentialityType> {
    return this.api
      .getConfidentialityType(itemId, { only: ['id', 'name', { module_name: ['id', 'name', 'display'] }] }, { join: true, cache: true })
      .then(({ data }) => data);
  }

  entityToText({}: ServiceRequest<ConfidentialityType>, entity?: ConfidentialityType): string {
    return entity ? `${entity.module_name?.display}: ${entity.name}` : '';
  }
}
