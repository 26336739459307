import type { ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import { ModuleRecordService } from './module-record-service';
import type { Activity } from '@app/models/activity';
import type { ModuleEntity } from '@app/models/module-entity';
import { HARDCODED_MODULE_CODES } from '@app/models/module-name';

type Actionable = ModuleEntity & { type: string };
export class ActionableService extends BaseService<Activity, Actionable> {
  async fetchEntity(request: ServiceRequest<Activity>): Promise<Partial<Actionable>> {
    const type = this.extractValue('actionable_type', request.version);
    const id = this.extractValue<number>('actionable_id', request.version);
    const title = await this.entityTitle(type, id);
    return { type, id, title };
  }

  entityToText({}: ServiceRequest<Activity>, entity?: Partial<Actionable>): string {
    return entity?.title ? `${entity.title} [${entity.id}]` : '';
  }
  async entityTitle(type: Maybe<string>, id: Maybe<number>): Promise<string> {
    if (type === 'ModuleRecord' && id) {
      const moduleRecordService = new ModuleRecordService(this.config);
      const entity = await moduleRecordService.fetchModuleRecord(id);
      return entity.title;
    } else if (type && HARDCODED_MODULE_CODES.includes(type)) {
      const { data } = await this.config.api.getModuleNames({
        filters: { name: type },
        only: ['id', 'display'],
      });
      return data[0]?.display;
    } else {
      return '';
    }
  }
}
