import { useCurrentUserStore } from '@app/stores/currentUser';
import { Component, Prop, Vue } from 'vue-property-decorator';
import type { BaseEntity } from '@app/models/base-entity';
import type { PaperTrailVersion } from '@app/models/paper-trail-version';

@Component
export default class BaseResponse<VersionEntity extends BaseEntity = BaseEntity, Entity extends BaseEntity = VersionEntity> extends Vue {
  @Prop(Array) readonly queries!: string[];
  @Prop(Object) readonly version!: PaperTrailVersion<VersionEntity>;
  @Prop(Object) readonly entity!: Entity;
  @Prop(String) readonly entityText!: string;

  get currentUserStore() {
    return useCurrentUserStore();
  }

  get itemType(): string {
    return this.version.item_type;
  }

  get itemId(): string | number {
    return this.version.item_id;
  }
}
