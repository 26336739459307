import { upperFirst } from 'lodash';
import type { SubFormListRole } from '../../../models/sub-form-list-role';
import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import { SUB_FORM_LIST_DETAILS_ONLY } from './sub-form-list-restriction-service';

export class SubFormListRoleService extends BaseService {
  async fetchDetails({ itemId }: ServiceRequest<SubFormListRole>): Promise<DetailsServiceResponse> {
    const { data: role } = await this.api.getSubFormListRole(
      itemId,
      { only: ['id', 'permission_type', 'sub_form_list_id'] },
      { join: true, cache: true }
    );
    const { data: list } = await this.api.getSubFormList(
      role.sub_form_list_id,
      { only: SUB_FORM_LIST_DETAILS_ONLY },
      { join: true, cache: true }
    );
    const mainText = `${this.t('app.labels.restriction_type')}: ${upperFirst(role.permission_type)}`;
    return {
      links: [{ prefix: this.t('app.labels.tab_section'), title: list.title, id: list.id }],
      mainText,
    };
  }
}
