
  import { isBoolean, isEmpty, isNumber } from 'lodash';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { RESPONSES_MAPPING } from '../mapping';
  import type { ResponseType } from '../models';
  import QuestionResponse from '../responses/question-response.vue';
  import * as RESPONSE_COMPONENTS from '../responses/components';
  import type { BaseEntity } from '@app/models/base-entity';
  import type { PaperTrailChange, PaperTrailVersion } from '@app/models/paper-trail-version';

  const MAXIMUM_RESPONSE_LENGTH = 300;

  @Component({ components: { ...RESPONSE_COMPONENTS, QuestionResponse } })
  export default class PaperTrailsAttributeValue extends Vue {
    @Prop(Object) readonly version!: PaperTrailVersion;
    @Prop(Array) readonly queries?: string[];
    @Prop(String) readonly field!: string;
    @Prop(String) readonly responseType?: ResponseType;
    @Prop(Object) readonly entity?: BaseEntity;
    @Prop(String) readonly entityText?: string;
    @Prop() readonly raw?: PaperTrailChange;
    @Prop(String) readonly rawText?: string;
    @Prop(String) readonly fullResponseText?: string;

    collapsed = false;

    mounted(): void {
      this.collapsed = this.isMaximumLengthReached;
    }

    get componentStyles(): string {
      return this.isMaximumLengthReached && this.collapsed ? 'max-height: 100px;overflow-y: hidden;' : '';
    }

    get changeExists(): boolean {
      return !isEmpty(this.entity) || !isEmpty(this.entityText) || isBoolean(this.raw) || isNumber(this.raw) || !!this.raw;
    }

    get responseComponent(): string {
      return (this.responseType && RESPONSES_MAPPING[this.responseType]) || 'DirectResponse';
    }

    get responseLength(): number {
      const contentToCheck = this.responseComponent === 'QuestionResponse' ? this.fullResponseText : this.entityText;
      return contentToCheck?.length || this.rawText?.length || 0;
    }

    get isMaximumLengthReached(): boolean {
      return this.responseLength > MAXIMUM_RESPONSE_LENGTH;
    }
  }
