import type { TenantUser } from '@app/models/tenant-user';

export const userInitials = (user?: Partial<Pick<TenantUser, 'first_name' | 'last_name'>>): string => {
  if (user) {
    const first = user.first_name;
    const last = user.last_name;

    return [first && first[0], last && last[0]].filter(Boolean).join(' ');
  }
  return '';
};
