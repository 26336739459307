
  import { useAccountStore } from '@app/stores/account';
  import type { TranslateOptions } from 'i18n-js/typings/typing';
  import { Model, Prop, Emit, Component, Vue, Ref } from 'vue-property-decorator';
  import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
  import { flatten } from 'lodash';
  import type { NumberOption, StringOption } from './models';
  import { DayOfMonth } from './models';
  import Select2 from '../select2.vue';
  import DateTimePicker from '../date-time-picker.vue';
  import DatePicker from '../date-picker.vue';
  import { buildRecurringSetup, parseFrequency, parseFrequencyData } from './helper';
  import type { RecurringSetup } from '@app/models/recurring/recurring-setup';
  import { Frequency } from '@app/models/recurring/recurring-setup';
  import { Week } from '@app/models/recurring/frequency-data';

  const WEEKS = [Week.monday, Week.tuesday, Week.wednesday, Week.thursday, Week.friday, Week.saturday, Week.sunday];

  const MAX_DAYS_IN_MONTH = 28;

  const DAY_OF_MONTH = [DayOfMonth.first, DayOfMonth.last];

  const FREQUENCIES = [Frequency.hourly, Frequency.daily, Frequency.weekly, Frequency.monthlyDayOfWeek, Frequency.monthlyDayOfMonth];

  @Component({ components: { Select2, DateTimePicker, DatePicker, ValidationObserver, ValidationProvider } })
  export default class SetupForm extends Vue {
    @Model('input') readonly value?: RecurringSetup;
    @Prop(String) readonly prefixName!: string;
    @Prop(String) readonly format?: string;
    @Prop(Boolean) readonly lockHourlyFrequency?: boolean;
    @Prop(Boolean) readonly showStartAndEndRange?: boolean;
    @Prop(Number) readonly defaultHour!: number;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<RecurringSetup> = buildRecurringSetup(Frequency.daily, parseFrequencyData());
    selectedFrequency: string = Frequency.daily;
    isEveryReadonly = false;
    isFrequencyReadonly = false;
    isEndDateEnabled = false;
    focusToEndDate = false;

    beforeMount(): void {
      this.resetForm();

      // TODO: add this once
      extend('required_if_other_blank', {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        validate: (value, args: any): boolean => {
          if (!args) {
            return false;
          }
          if (value && value.length) {
            return true;
          }

          const dayOfWeek = this.form.frequency_data?.dayOfWeek;
          if (!dayOfWeek) return false;

          const values = args.map((name: 'first' | 'second' | 'third' | 'last') => {
            switch (name) {
              case 'first':
                return dayOfWeek.first && dayOfWeek.first.length;
              case 'second':
                return dayOfWeek.second && dayOfWeek.second.length;
              case 'third':
                return dayOfWeek.third && dayOfWeek.third.length;
              case 'last':
                return dayOfWeek.last && dayOfWeek.last.length;
            }
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const result = values.filter((value: any) => value);
          return !!result.length;
        },
        computesRequired: true,
      });
    }

    resetForm(): void {
      const frequency = this.value ? parseFrequency(this.value.frequency) : Frequency.daily;
      const frequencyData = parseFrequencyData(this.value?.frequency_data);
      this.form = buildRecurringSetup(frequency, frequencyData, {
        start_date: this.value?.start_at,
        end_date: this.value?.end_at,
        enabled: this.value?.enabled,
        id: this.value?.id,
        default_hour: this.defaultHour,
      });
      this.onFrequencyChanged(frequency);
      this.isEndDateEnabled = !!this.value?.end_at;
      this.focusToEndDate = !this.isEndDateEnabled;
    }

    @Emit('input')
    updateValue(): Partial<RecurringSetup> {
      return this.form;
    }

    formName(fieldName: string): string {
      return `${this.prefixName}[${fieldName}]`;
    }

    formLabel(fieldName: string): string {
      const key = `tenant.reports.recurring_setup.form.${fieldName}`;
      return this.$t(key).toString();
    }

    localizeWeeks(code: string): string {
      return this.$t(`tenant.reports.recurring_setup.weeks.${code}`).toString();
    }

    normalizedWeeks(prefix: string) {
      return (code: string): string => `${prefix} ${this.localizeWeeks(code)}`;
    }

    t(key: string, args?: TranslateOptions): string {
      return this.$t(`tenant.reports.recurring_setup.${key}`, args).toString();
    }

    preventNumericInput(event: KeyboardEvent): void {
      if (!/^\d+$/.test(event.key)) {
        event.preventDefault();
      }
    }

    onFrequencyChanged(frequency: string): void {
      this.selectedFrequency = frequency;
      this.isEveryReadonly = false;
      this.isFrequencyReadonly = false;
      if (frequency === Frequency.hourly.toString() && this.lockHourlyFrequency) {
        this.isEveryReadonly = true;
        this.isFrequencyReadonly = true;
      }

      this.updateValue();
    }

    onDayOfWeekChanged(): void {
      this.validator?.validate();
      this.updateValue();
    }

    showErrors(errors: string[]): boolean {
      return Object.values(errors).some((e) => e.length);
    }

    get frequencies(): Frequency[] {
      if (this.lockHourlyFrequency) {
        if (this.selectedFrequency === Frequency.hourly.toString()) {
          return FREQUENCIES.filter((item) => item === Frequency.hourly);
        }

        return FREQUENCIES.filter((item) => item !== Frequency.hourly);
      }

      return FREQUENCIES;
    }

    get frequencyOptions(): StringOption[] {
      return this.frequencies.map((code): StringOption => {
        return { code: code, label: this.t(`frequencies.${code}`) };
      });
    }

    get weekOptions(): StringOption[] {
      return WEEKS.map((code): StringOption => {
        return { code: code, label: this.t(`weeks.${code}`) };
      });
    }

    get dayOfMonthOptions(): NumberOption[] {
      const shortcuts = DAY_OF_MONTH.map((code): NumberOption => {
        return { code: code.valueOf(), label: this.t(`day_of_month.${DayOfMonth[code]}`) };
      });
      const days = [...Array.from(Array(MAX_DAYS_IN_MONTH - 1).keys())].map((index): NumberOption => {
        const day = index + 2;
        return { code: day, label: this.t('formats.on_day', { day: day }) };
      });
      return [...shortcuts, ...days];
    }

    get on(): string {
      const data = this.form.frequency_data;
      if (!data) return '';

      switch (this.form.frequency) {
        case Frequency.monthlyDayOfMonth:
          if (!data.dayOfMonths) {
            return '';
          }

          const allDays = data.dayOfMonths.map((code: string) => {
            const dayNumber = parseInt(code, 10);
            if (![1, -1].includes(dayNumber)) {
              return this.t('formats.on_day', { day: code });
            }

            return this.t(`day_of_month.${DayOfMonth[dayNumber]}`);
          });
          return this.t('formats.on_days', {
            postfix: allDays.join(` ${this.t('days.delimiter')} `),
          });
        case Frequency.monthlyDayOfWeek:
          if (!data.dayOfWeek) {
            return '';
          }

          const monthlyDayOfWeeks = [];
          if (data.dayOfWeek.first) {
            monthlyDayOfWeeks.push(data.dayOfWeek.first.map(this.normalizedWeeks('1st')));
          }

          if (data.dayOfWeek.second) {
            monthlyDayOfWeeks.push(data.dayOfWeek.second.map(this.normalizedWeeks('2nd')));
          }

          if (data.dayOfWeek.third) {
            monthlyDayOfWeeks.push(data.dayOfWeek.third.map(this.normalizedWeeks('3rd')));
          }

          if (data.dayOfWeek.last) {
            monthlyDayOfWeeks.push(data.dayOfWeek.last.map(this.normalizedWeeks('last')));
          }
          if (!monthlyDayOfWeeks.length) {
            return '';
          }

          const allWeeks = flatten(monthlyDayOfWeeks).join(` ${this.t('months.delimiter')} `);
          return this.t('formats.on_weeks', { postfix: allWeeks });
        case Frequency.weekly:
          if (!data.weeks) {
            return '';
          }

          const weekDays = data.weeks.map((code: Week) => this.localizeWeeks(code)).join(` ${this.t('weeks.delimiter')} `);
          return this.t('formats.on_something', { postfix: weekDays });
        case Frequency.hourly:
        case Frequency.daily:
        default:
          return '';
      }
    }

    get when(): string {
      const postfix = this.$t(`tenant.reports.recurring_setup.plurals.${this.form.frequency}`, {
        count: this.form.frequency_data?.every,
      });
      return this.t('formats.every_something', { postfix: postfix });
    }

    get description(): string {
      return this.t('formats.description', { when: this.when, on: this.on });
    }

    get accountStore() {
      return useAccountStore();
    }
  }
