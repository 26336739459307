
  import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
  import type { Blob } from '@rails/activestorage';
  import AttachmentsUploader from '@app/components/attachment/attachments-uploader.vue';
  import QuestionAddRelated from './question-add-related.vue';
  import ResponseRelatedObjects from './response-related-objects.vue';
  import type { RelatedItem, RelatedKey } from '@app/services/api/sub-form-completions-api';
  import type { SubFormCompletionFormRecord, QuestionSfcOnly } from '../utils';
  import type { Activity } from '@app/models/activity';
  import type { ModuleRecord } from '@app/models/module-record';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { SubFormResponse } from '@app/models/sub-form-response';
  import type { BaseQuestionOptions } from '@app/models/question-options/base-question-options';
  import type { MainFormRelationQuestionOptions } from '@app/models/question-options/main-form-relation-question-options';
  import type { MultiMainFormRelationQuestionOptions } from '@app/models/question-options/multi-main-form-relation-question-options';
  import type { StrictUnion } from '@app/utils/types/strict-union';

  @Component({ components: { AttachmentsUploader, QuestionAddRelated, ResponseRelatedObjects } })
  export default class QuestionBlockRelated extends Vue {
    @Prop(Object) readonly question!: Pick<
      SubFormQuestion<StrictUnion<BaseQuestionOptions | MainFormRelationQuestionOptions | MultiMainFormRelationQuestionOptions>>,
      QuestionSfcOnly
    >;
    @Prop(Object) readonly record?: SubFormCompletionFormRecord;
    @Prop(Boolean) readonly modal?: boolean;
    @Prop(Object) readonly response!: SubFormResponse;
    @Prop(Boolean) readonly canAddAnything?: boolean;
    @Prop(Boolean) readonly canAddAttachment?: boolean;
    @Prop(Boolean) readonly mainForm?: boolean;

    blobs: Blob[] = [];
    relatedActivities: Activity[] = [];
    relatedRecords: ModuleRecord[] = [];

    @Emit('auto-select')
    autoSelect(record: ModuleRecord): ModuleRecord {
      return record;
    }

    @Emit('update-related')
    updateRelated(
      key: RelatedKey,
      item: RelatedItem,
      mode: 'add' | 'remove'
    ): { item: RelatedItem; key: RelatedKey; mode: 'add' | 'remove' } {
      return { key, item, mode };
    }

    onActivityCreated(activity: Activity): void {
      this.relatedActivities = [...this.relatedActivities, activity];
      this.updateRelated('related_activities', activity.id, 'add');
    }

    onRecordCreated(record: ModuleRecord): void {
      const mainFormRelationAutoSelectCreated = `${this.question.config.main_form_id}` === `${record.module_name_id}`;

      this.relatedRecords = [...this.relatedRecords, record];

      this.updateRelated('related_records', record.id, 'add');

      mainFormRelationAutoSelectCreated && this.autoSelect(record);
    }

    createBlob(blob: Blob): void {
      this.blobs = [...this.blobs, blob];
      this.updateRelated('attachments', blob.signed_id, 'add');
    }

    deleteBlob(blob: Blob): void {
      this.blobs = this.blobs.filter((b) => b.signed_id === blob.signed_id);
      this.updateRelated('attachments', blob.signed_id, 'remove');
    }
  }
