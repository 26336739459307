
  import type { SinglePersonSelectorTempFieldValue } from '@app/models/question-response-types';
  import moment from 'moment';
  import { filter } from 'rxjs/operators';
  import { Component, Emit, Ref } from 'vue-property-decorator';
  import type { ExtraUserApiOptions } from '@app/services/api/tenant-users-api';
  import { hasQuestionInFilter } from '@app/utils/has-question-in-filter';
  import type { TenantUser } from '@app/models/tenant-user';
  import type { Dictionary } from '@app/models/dictionary';
  import type { RestrictionDefault } from '@app/models/restriction-default';
  import type { FieldType } from '@app/models/sub-form-question';
  import { UserType } from '@app/models/tenant-user';
  import { AUTH_LOGIN_TYPES } from '@app/models/restriction-default';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { convertInFormFilters } from '@app/utils/convert-in-form-filters';
  import { incompleteMandatoryFilters } from '@app/utils/mandatory-filters';

  import NewPersonForm from '../user/new-person-form.vue';
  import UserSelector from '../user/user-selector.vue';
  import FilterErrors from '../admin/questions/filter-configurator/filter-errors.vue';

  import BaseField from './base-field';

  @Component({ components: { NewPersonForm, UserSelector, FilterErrors } })
  export default class SinglePersonSelectorField extends BaseField<FieldType.single_person_selector> {
    @Ref() readonly userSelector?: UserSelector;

    showForm = !!this.paramUser;
    localValue: SinglePersonSelectorTempFieldValue = {};
    newUserDefaultData: Partial<TenantUser> = {
      ...this.paramUser,
      type: this.userType, // only pass it to show what type of user is being created on UI, backend uses question config
    };
    initialized = false;
    defaultRestrictions: RestrictionDefault[] = [];

    get dateFormat(): Maybe<string> {
      return this.accountStore.data.datetimepicker_date_format;
    }

    get paramUser(): Partial<TenantUser> | null {
      if (!this.params?.responses || this.params.add_another === 'true') {
        return null;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response = this.params?.responses[this.question.id] as any;
      const userResponse = response && response['user'];

      if (!userResponse) {
        return null;
      }

      const result = {
        ...userResponse,
        home_location_id: `${userResponse.home_location_id}`,
        home_organization_id: `${userResponse.home_organization_id}`,
      };

      delete result.start_date;
      delete result.end_date;
      delete result.date_of_birth;

      if (userResponse.start_date?.length) {
        result['start_date'] = moment(userResponse.start_date, this.dateFormat).toDate();
      }

      if (userResponse.end_date?.length) {
        result['end_date'] = moment(userResponse.end_date, this.dateFormat).toDate();
      }

      if (userResponse.date_of_birth?.length) {
        result['date_of_birth'] = moment(userResponse.date_of_birth, this.dateFormat).toDate();
      }

      return result;
    }

    get userType(): UserType {
      return this.question.config.new_user_type || UserType.ContactUser;
    }

    get onlyCreate(): boolean {
      return this.question.config.add_new_user_only === 'true' || this.isPublic;
    }

    get showPicker(): boolean {
      return !this.onlyCreate ? !this.showForm : !!this.localValue?.value;
    }

    get userLimitReach(): boolean {
      return !this.accountStore.canAddUsers && !this.authRestrictedUserType;
    }

    get showAddNewButton(): boolean {
      return !this.readonly && !this.onlyCreate && !this.userLimitReach && this.enableAddNewUser;
    }

    get enableAddNewUser(): boolean {
      return this.question.config.enable_add_new_user === 'true' || this.isPublic;
    }

    get parsleyUniqInvolvementDataJson(): string {
      const data: { [key: string]: string | number | undefined } = {
        current_value: this.response?.response?.value,
        record: this.record?.id,
      };

      if (this.question.config.involvement_id) {
        data.involvement = this.question.config.involvement_id;
      }

      return JSON.stringify(data);
    }

    get authRestrictedUserType(): boolean {
      return this.defaultRestrictions.filter((res) => res.restrict_on === this.userType).length === AUTH_LOGIN_TYPES.length;
    }

    @Emit('input')
    onInput(userId: string): SinglePersonSelectorTempFieldValue {
      this.localValue = { value: userId?.toString() || '' };
      this.triggerUpdate(userId);

      return this.localValue;
    }

    @Emit('input')
    onUserInput(user?: Partial<TenantUser>): SinglePersonSelectorTempFieldValue {
      this.localValue = { ...(user ? { user } : { value: '' }) };
      this.triggerUpdate();

      return this.localValue;
    }

    getFilters(): DonesafeFilterOptions<TenantUser, ExtraUserApiOptions> {
      return {
        ...this.question.config.required_filters,
        ...convertInFormFilters<TenantUser, ExtraUserApiOptions>(this.question.config.filters, {
          record: this.record,
          user: this.currentUserStore.data,
          getCurrentFormValueByQuestion: this.getCurrentFormValueByQuestion,
        }),
      };
    }

    onOpen(): boolean {
      !this.defaultTemplating && this.setIncompleteFilters(incompleteMandatoryFilters(this.question.config.filters, this.getFilters()));
      return !this.incompleteFilters.length;
    }

    triggerUpdate(userId?: string): void {
      this.$nextTick(() => {
        this.sendUpdate(this.localValue);
        this.lookupRequest({ lookupRecordId: userId });
        !!userId && this.detailFieldRequest(userId);
      });
    }

    userFormChange(user: Partial<TenantUser>): void {
      this.requestDetails(user as Dictionary<string>).then((updatedUser) => {
        this.formObservers?.detailsResponse$.next([this.question.system_code, this.decorateUser(updatedUser)]);
      });

      this.onUserInput(user);
    }

    async requestDetails(user: Dictionary<string>): Promise<Dictionary<string>> {
      type ApiRequest = (id: string) => Promise<string | null>;
      const requestMap: Dictionary<ApiRequest> = {
        manager_id: (id) =>
          this.$api
            .getTenantUser(Number(id), { only: 'full_name' }, { cache: true })
            .then(({ data }) => data.full_name)
            .catch(() => null),
        home_location_id: (id) =>
          this.$api
            .getLocation(Number(id), { only: 'name' }, { cache: true })
            .then(({ data }) => data.name)
            .catch(() => null),
        home_organization_id: (id) =>
          this.$api
            .getOrganization(Number(id), { only: 'name' }, { cache: true })
            .then(({ data }) => data.name)
            .catch(() => null),
      };
      const fieldsMap: Dictionary<string> = {
        manager_id: 'manager_full_name',
        home_location_id: 'home_location_name',
        home_organization_id: 'home_organization_name',
      };

      const promises = Object.keys(requestMap).map((key) => {
        return user[key] ? requestMap[key](user[key]) : null;
      }, []);

      const values = await Promise.all(promises);
      return Object.keys(fieldsMap).reduce((memo, key, index) => {
        return { ...memo, [fieldsMap[key]]: values[index] } as Dictionary<string>;
      }, user);
    }

    decorateUser(user: Dictionary<string>): Dictionary<string> {
      type ComplexConverter = (value: string) => Dictionary<string>;
      const dateConverter: (value: string) => string = (dateString) => moment(dateString).format(this.dateFormat);

      const fieldsMap: Dictionary<string | ComplexConverter> = {
        suburb: 'profile_suburb',
        address_line_1: 'profile_residential_address_1',
        address_line_2: 'profile_residential_address_2',
        postcode: 'profile_postcode',
        date_of_birth: (str: string) => ({ formatted_date_of_birth: dateConverter(str) }),
      };

      return Object.keys(user).reduce((memo, key) => {
        const mapping = fieldsMap[key];
        if (mapping) {
          switch (typeof mapping) {
            case 'string':
              return { ...memo, [mapping]: user[key] };
            case 'function':
              return { ...memo, ...mapping(user[key]) };
          }
        } else {
          return { ...memo, [key]: user[key] };
        }
      }, {});
    }

    initSubscription(): void {
      this.addSubscription(
        this.formObservers.fieldUpdate$
          .pipe(
            filter(([question]) => {
              const shouldFilter =
                this.showForm &&
                !!question.config.lookup?.related_record_question_code &&
                `${question.config.lookup.related_record_question_code}` === `${this.question.code}`;
              return !shouldFilter;
            })
          )
          .subscribe((result) => {
            const question = result[0];
            const value = result[1];

            if (`${question.id}` === `${this.question.config?.home_location_question_id}`) {
              this.newUserDefaultData = {
                ...this.newUserDefaultData,
                home_location_id: value.value,
              };
            } else if (`${question.id}` === `${this.question.config?.home_organization_question_id}`) {
              this.newUserDefaultData = {
                ...this.newUserDefaultData,
                home_organization_id: value.value,
              };
            }
          })
      );
      if (!this.showPicker) {
        return;
      }

      this.addSubscription(
        this.formObservers.fieldUpdate$
          .pipe(filter(([question]) => hasQuestionInFilter(question, this.question.config.filters)))
          .subscribe(() => {
            this.clearIncompleteFilters();
          })
      );
    }

    async fetchDefaultRestrictions(cache = true): Promise<void> {
      const { data } = await this.$api.getRestrictionDefaults(
        {
          filters: { restriction: AUTH_LOGIN_TYPES, action: 'auth', restrict: true, restrict_on: this.userType },
          only: ['id', 'restrict_on', 'restriction', 'restrict', 'action'],
        },
        { cache }
      );
      this.defaultRestrictions = data;
    }

    beforeMount(): void {
      this.localValue = this.value || { value: '' };
      this.fetchDefaultRestrictions();
    }

    mounted(): void {
      const userId = this.localValue?.value;
      this.detailFieldRequest(userId);

      if (this.isPublic) {
        this.showForm = true;
      }

      this.initSubscription();
      this.sendUpdate(this.localValue, true);
      this.initialized = true;
    }

    toggleForm(value: boolean): void {
      this.showForm = value;

      this.resetDetails(this.newUserDefaultData);
      if (!value && this.localValue?.value) {
        this.detailFieldRequest(this.localValue.value);
      }
    }

    resetDetails(resetData: Partial<TenantUser> = {}): void {
      this.formObservers?.detailsResponse$.next([this.question.system_code, resetData as Dictionary<string>]);
      this.onUserInput();
    }
  }
