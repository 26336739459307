
  import { Component } from 'vue-property-decorator';
  import BaseResponse from './base-response';
  import TextHighlight from 'vue-text-highlight';
  import { userEditLink } from '../utils';
  import type { TenantUser } from '@app/models/tenant-user';

  @Component({ components: { TextHighlight } })
  export default class UserResponse extends BaseResponse<TenantUser> {
    get userEditLink(): Maybe<string> {
      return this.entity && userEditLink(this.entity, this.currentUserStore.data);
    }
  }
