import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import { EventService, SCHEDULED_DETAILS_ONLY } from './event-service';
import type { ScheduledEvent } from '@app/models/scheduled-event';
import type { DonesafeApi } from '@app/services/donesafe-api';

export class ScheduledEventService extends BaseService<ScheduledEvent> {
  async fetchDetails(request: ServiceRequest<ScheduledEvent>): Promise<DetailsServiceResponse> {
    const scheduledEvent = await this.fetchEntity(request);
    if (!scheduledEvent.name) return {};

    return {
      links: [
        {
          link: `/admin/settings/scheduled_events/${scheduledEvent.id}/edit`,
          prefix: this.t('app.labels.schedule'),
          title: scheduledEvent.name,
          id: scheduledEvent.id,
        },
      ],
      mainText: this.mainText(scheduledEvent),
    };
  }

  async fetchEntity({ itemId }: ServiceRequest<ScheduledEvent>): Promise<Partial<ScheduledEvent>> {
    return ScheduledEventService.fetchEntity(this.api, itemId);
  }

  entityToText({}: ServiceRequest<ScheduledEvent>, entity?: Partial<ScheduledEvent>): string {
    return entity ? `${entity.name} [${entity.id}]` : '';
  }

  private mainText(scheduledEvent: Partial<ScheduledEvent>): string {
    return EventService.mainText(this, scheduledEvent);
  }

  static async fetchEntity(api: DonesafeApi, itemId: ScheduledEvent['id']): Promise<ScheduledEvent> {
    const { data: scheduledEvent } = await api.getScheduledEvent(
      itemId,
      { only: SCHEDULED_DETAILS_ONLY, include: ['event'] },
      { join: true, cache: true }
    );
    return scheduledEvent;
  }
}
