import type { AutomationDefinition } from '@app/models/automation-definition';
import type { OnlyOptions } from '@app/services/donesafe-api-utils';

import { automationRecordId, automationRecordPath } from '../utils';

import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';

export const AUTOMATION_DETAILS_ONLY: OnlyOptions<AutomationDefinition> = [
  'concept_name',
  'description',
  'event_ids',
  'id',
  'involvement_id',
  'name',
  'regulatory_report_config_id',
  'sub_form_id',
  { events: ['event_type'] },
];

export class AutomationDefinitionService extends BaseService<AutomationDefinition> {
  static readonly allowedAttributes = [
    'active',
    'concept_name',
    'description',
    'display',
    'event_type',
    'id',
    'index',
    'involvement_id',
    'name',
    'regulatory_report_config_id',
    'relationship_code',
    'rule_set_id',
    'source_uuid',
    'state',
    'sub_form_id',
    'trigger_on',
    'trigger_when',
    'triggering_fields',
    'uuid',
  ];

  async fetchDetails({ itemId }: ServiceRequest<AutomationDefinition>): Promise<DetailsServiceResponse> {
    const { data: automation } = await this.api.getAutomationDefinition(
      itemId,
      { only: AUTOMATION_DETAILS_ONLY },
      { join: true, cache: true }
    );
    return {
      links: [
        {
          link: `/admin/settings/automation_definitions/${automation.id}/edit`,
          prefix: this.t('app.labels.automation'),
          title: automation.name,
          id: automation.id,
        },
        {
          link: automationRecordPath(automation),
          prefix: this.t('app.labels.triggered_from'),
          title: automation.concept_name,
          id: automationRecordId(automation),
        },
      ],
      mainText: `${this.t('app.labels.type')}: ${automation.events?.map((e) => e.event_type)?.join(', ')}`,
    };
  }
}
