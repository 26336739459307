
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { useAccountStore } from '@app/stores/account';
  import BaseTableCell from '@app/components/base-table/base-table-cell.vue';
  import BaseTable from '@app/components/base-table/base-table.vue';
  import EntitySelector from '@app/components/entity-selector.vue';
  import FilterSelect from '@app/components/filter-select.vue';
  import type { DonesafeModuleRecordExtraFilters } from '@app/services/api/module-records-api';
  import { Component, Ref } from 'vue-property-decorator';
  import type { Involvement } from '@app/models/involvement';
  import type { ModuleRecord } from '@app/models/module-record';
  import type { TenantUser } from '@app/models/tenant-user';
  import type { UserInvolvement } from '@app/models/user-involvement';
  import type { DonesafeFilterOptions, OnlyOptions } from '@app/services/donesafe-api-utils';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { mixins } from 'vue-class-component';
  import WithUser from '@app/mixins/with-user';

  @Component({
    components: { EntitySelector, FilterSelect, BaseTableCell, BaseTable },
  })
  export default class UserRelatedRecordsPage extends mixins(WithUser) {
    @Ref() readonly table?: BaseTable<ModuleRecord>;

    manager: Nullable<ListManager<ModuleRecord>> = null;
    user: Nullable<TenantUser> = null;
    involvementIds: number[] = [];

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get accountStore() {
      return useAccountStore();
    }

    get filters(): DonesafeFilterOptions<ModuleRecord, DonesafeModuleRecordExtraFilters> {
      return {
        user_involvements: {
          user_id: this.userId,
          involvement_id: this.involvementIds,
        },
      };
    }

    get involvementsFilter(): DonesafeFilterOptions<Involvement> {
      return {
        related_module_name: {
          active: true,
          name: this.availableModuleNames,
        },
      };
    }

    get availableModuleNames(): string[] {
      const modulesAcl = this.currentUserStore.data?.acl?.module || {};
      return Object.keys(modulesAcl).reduce((memo: string[], key) => {
        return modulesAcl[key].view || modulesAcl[key].mha ? [...memo, key] : memo;
      }, []);
    }

    get only(): OnlyOptions<ModuleRecord> {
      return [
        'id',
        'title',
        {
          module_name: ['id', 'display'],
          user_involvements: ['id', 'created_at', 'user_id', { involvement: ['id', 'name'] }],
        },
      ];
    }

    userInvolvements(record: ModuleRecord): UserInvolvement[] {
      return (record.user_involvements || []).filter((ui) => ui.user_id === this.userId);
    }

    getManager(): ListManager<ModuleRecord> {
      return new ListManager<ModuleRecord, DonesafeModuleRecordExtraFilters>({
        fetchDataFunction: (params) => {
          return this.$api.getModuleRecords(
            { ...params, filters: { ...params.filters, ...this.filters }, only: this.only },
            { cache: true }
          );
        },
        per_page: 25,
        sortOrder: [{ direction: 'desc', field: 'id', sortField: 'id' }],
        fields: [
          {
            title: this.$t('app.labels.ID'),
            name: 'id',
            sortField: 'id',
            link: (moduleRecord: ModuleRecord): string => `/module_records/${moduleRecord.id}`,
          },
          {
            title: this.$t('app.labels.title'),
            name: 'title',
            sortField: 'title',
            link: (moduleRecord: ModuleRecord): string => `/module_records/${moduleRecord.id}`,
          },
          { title: 'Module', name: 'module_name', filter: true, sortField: 'module_name' },
          { title: 'Details', name: 'involvements' },
        ],
        allowFilters: true,
      });
    }

    mounted(): void {
      this.manager = this.getManager();
    }
  }
