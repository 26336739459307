import type { BaseEntity } from './base-entity';

export enum ShiftType {
  actual = 'actual',
  expected = 'expected',
}

export interface Shift extends BaseEntity {
  end_at: string;
  hours_worked?: number;
  shift_hours?: ShiftHour[];
  shift_type: ShiftType;
  start_at: string;
  user_id: number;
}

export interface ShiftHour extends BaseEntity {
  hour_type_id: number;
  hours: number;
  shift_id: number;
}
