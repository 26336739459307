
  import { Component } from 'vue-property-decorator';
  import TextHighlight from 'vue-text-highlight';
  import type { BaseEntity } from '@app/models/base-entity';
  import { RESPONSES_QUESTION_MAPPING } from '../mapping';
  import { extractPaperTrailVersionValue } from '../utils';
  import BaseResponse from './base-response';
  import * as RESPONSE_COMPONENTS from './components';
  import { FIELD_RESPONSE_COMPONENTS } from './question-responses/components';
  import type { FieldType, SubFormQuestion } from '@app/models/sub-form-question';
  import type { PaperTrailVersion } from '@app/models/paper-trail-version';
  import type { SubFormResponse } from '@app/models/sub-form-response';
  import { ResponseService } from '../services';

  @Component({ components: { ...RESPONSE_COMPONENTS, ...FIELD_RESPONSE_COMPONENTS, TextHighlight } })
  export default class QuestionResponse extends BaseResponse<SubFormResponse, SubFormQuestion> {
    get responseProps(): {
      entity: BaseEntity;
      entityText: string;
      queries: string[];
      version: PaperTrailVersion<SubFormResponse>;
    } {
      return {
        version: this.version,
        queries: this.queries,
        entity: this.entity,
        entityText: this.entityText,
        ...this.$attrs,
      };
    }

    get isHandledType(): boolean {
      return ResponseService.isHandledType(this.subFormQuestionFieldType);
    }

    get subFormQuestionFieldType(): Maybe<FieldType> {
      if (this.itemType === 'SubFormResponse') {
        return extractPaperTrailVersionValue<SubFormResponse>('sub_form_question_field_type', this.version) as FieldType;
      }
    }

    get responseComponent(): string | undefined {
      return (this.subFormQuestionFieldType && RESPONSES_QUESTION_MAPPING[this.subFormQuestionFieldType]) || 'DirectResponse';
    }
  }
