import type { ScoringGroup } from '@app/models/scoring-group';
import type { DetailsLink } from '../models';
import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';

export class ScoringGroupService extends BaseService<ScoringGroup> {
  static readonly allowedAttributes = [
    'active',
    'code',
    'default',
    'display_method',
    'id',
    'index',
    'module_name',
    'score_band_profile_id',
    'source_uuid',
    'title',
    'uuid',
  ];

  async fetchDetails({ itemId }: ServiceRequest<ScoringGroup>): Promise<DetailsServiceResponse> {
    const { data: scoringGroup } = await this.api.getScoringGroup(
      itemId,
      {
        only: [
          'id',
          'title',
          'code',
          'score_band_profile_id',
          { score_band_profile: ['module_name_id', 'name', 'code'], related_module_name: ['id'] },
        ],
        include: ['score_band_profile', 'related_module_name'],
      },
      { join: true, cache: true }
    );
    let links: DetailsLink[] = [
      {
        link:
          scoringGroup.related_module_name &&
          `/admin/settings/module_names/${scoringGroup.related_module_name.id}/scoring/scoring_groups/${scoringGroup.id}/edit`,
        prefix: this.t('app.labels.scoring_group'),
        title: scoringGroup.title,
        id: scoringGroup.id,
      },
    ];
    let mainText = `${this.t('app.labels.scoring_band_code')}: [${scoringGroup.code}]`;
    const profile = scoringGroup.score_band_profile;
    if (profile) {
      links = [
        ...links,
        {
          link: `/admin/settings/module_names/${profile.module_name_id}/scoring/score_bands/${profile.id}/edit`,
          prefix: this.t('app.labels.scoring_profile'),
          title: profile.name,
          id: profile.id,
        },
      ];
      mainText = `${mainText}, ${this.t('app.labels.scoring_profile_code')}: [${profile.code}]`;
    }
    return {
      links,
      mainText,
    };
  }
}
