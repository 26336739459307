import type { ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { GenderIdentity } from '@app/models/gender-identity';

export class GenderIdentityService extends BaseService<GenderIdentity> {
  async fetchEntity({ itemId }: ServiceRequest<GenderIdentity>): Promise<GenderIdentity> {
    const { data } = await this.api.getGenderIdentity(itemId, { only: ['id', 'identity'] }, { join: true, cache: true });
    return data;
  }

  entityToText({}: ServiceRequest<GenderIdentity>, entity?: GenderIdentity): string {
    return entity ? `${entity.identity} [${entity.id}]` : '';
  }
}
