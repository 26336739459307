import type { UserType } from '@app/models/tenant-user';
import type { BaseEntity } from './base-entity';

export interface RestrictionDefault extends BaseEntity {
  action: 'auth';
  restrict: boolean;
  restrict_on: UserType;
  restriction: 'authentication_token' | 'saml' | 'email';
}

export const AUTH_LOGIN_TYPES = ['email', 'saml'];
