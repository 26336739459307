import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { SuperReport } from '@app/models/super-report';
import type { DonesafeApi } from '@app/services/donesafe-api';

export class SuperReportService extends BaseService<SuperReport> {
  static readonly allowedAttributes: (keyof SuperReport)[] = [
    'datetime_output_format',
    'encrypted',
    'is_public',
    'machine_name',
    'report_blob',
  ];

  static attributeNames($t: (key: string) => string): Partial<Record<keyof SuperReport, string>> {
    return {
      datetime_output_format: $t('tenant.admin.reports.form.datetime_output_format'),
      encrypted: $t('tenant.admin.reports.form.encrypt_report'),
      is_public: $t('tenant.admin.reports.form.is_public'),
      machine_name: $t('tenant.admin.reports.form.machine_name'),
    };
  }

  async fetchDetails(itemId: ServiceRequest<SuperReport>): Promise<DetailsServiceResponse> {
    const report = await this.fetchEntity(itemId);
    return {
      links: [
        {
          link: `/admin/settings/reports/${report.id}`,
          prefix: this.t('app.labels.report'),
          title: report.name,
          id: report.id,
        },
      ],
    };
  }

  async fetchEntity({ itemId }: ServiceRequest<SuperReport>): Promise<SuperReport> {
    return await SuperReportService.fetchEntity(this.api, itemId);
  }

  static async fetchEntity(api: DonesafeApi, itemId: SuperReport['id']): Promise<SuperReport> {
    const { data: report } = await api.getSuperReport(itemId, { only: ['id', 'name'] }, { cache: true });
    return report;
  }

  entityToText({}: ServiceRequest<SuperReport>, entity?: SuperReport): string {
    return entity ? SuperReportService.toText(entity) : '';
  }

  static toText(superReport: Pick<SuperReport, 'id' | 'name'>): string {
    return `${superReport.name} [${superReport.id}]`;
  }
}
