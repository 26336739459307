import type { BaseQuestionOptions } from './base-question-options';
import type { ApiLookupOptions, ApiRequestOptions } from './shared';
import type { StringBoolean } from '@app/utils/types/string-boolean';

export enum CalculationTextFormatValidation {
  auto = 'auto',
  currency = 'currency',
  integer = 'integer',
  number = 'number',
  percentage = 'percentage',
  text = 'text',
}

export enum CalculationTextDecimalHandlingBehaviour {
  Floor = 'floor',
  Round = 'round',
}

export type VisibilityOverride = 'override' | 'hide' | 'show';

export interface CalculationTextQuestionOptions extends BaseQuestionOptions, ApiRequestOptions, ApiLookupOptions {
  blank_value_amount?: string;
  currency_symbol?: string;
  decimal_handling_behaviour?: CalculationTextDecimalHandlingBehaviour;
  decimal_places?: string;
  default?: {
    type?: 'no_default' | 'value' | 'calculation_value' | 'record_calculation' | 'random_text';
    value?: string;
  };
  default_value_amount?: string;
  format_validation: CalculationTextFormatValidation;
  max_value?: string;
  min_value?: string;
  remove_trailing_decimal_zeros: StringBoolean;
  validate?: {
    uniq?: StringBoolean;
  };
  visibility_override?: VisibilityOverride;
}
