export const customDispatchEvent = (element: Node, eventName: string | number): void => {
  let event;
  if (typeof Event === 'function') {
    event = new Event(`${eventName}`);
  } else {
    event = document.createEvent('Event');
    event.initEvent(`${eventName}`, true, true);
  }

  element.dispatchEvent(event);
};
