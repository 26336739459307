
  import type { OptionalSignatureData } from '@app/services/api/record-signatures-api';
  import { Component, Emit, Ref } from 'vue-property-decorator';
  import { mixins } from 'vue-class-component';
  import type { SignatureFieldValue } from '@app/models/question-response-types';
  import WithSignatureEventListeners from '@app/mixins/with-signature-event-listeners';
  import type { FieldType } from '@app/models/sub-form-question';
  import type { Signature } from '@app/models/signature';
  import { toaster } from '@app/utils/toaster';
  import SignatureDisplayMethods from '@app/mixins/signature-display-methods';

  import SignatureFormModal from '../signature/signature-form-modal.vue';
  import SignaturePad from '../signature/signature-pad.vue';

  import BaseField from './base-field';

  @Component
  class BaseSignatureField extends BaseField<FieldType.signature> {}

  @Component({ components: { SignaturePad, SignatureFormModal } })
  export default class SignatureField extends mixins(BaseSignatureField, WithSignatureEventListeners, SignatureDisplayMethods) {
    @Ref() readonly signature?: SignaturePad;

    height = '150px';
    localSignature: Signature | null = null;
    localValue: SignatureFieldValue = { value: '' };
    modalVisible = false;
    signaturePadEmpty = true;
    width = '300px';

    get quickAddOwnSignature(): boolean {
      return this.question.config.quick_add_own_signature === 'true';
    }

    get showInsertSignature(): boolean {
      return this.quickAddOwnSignature && this.signaturePadEmpty;
    }

    @Emit('input')
    onUpdate(value: string): SignatureFieldValue {
      this.signaturePadEmpty = !!this.signature?.isEmpty;
      this.localValue = { value };
      this.sendUpdate(this.localValue);
      return this.localValue;
    }

    clearSignature(): void {
      this.localSignature = null;
      this.localValue = { value: '' };
      this.$nextTick(() => this.signature?.clear());
    }

    insert(params: OptionalSignatureData): void {
      const include = ['user'];
      if (params.signature_data) {
        this.$api
          .createSignature({ signature_data: params.signature_data }, { include })
          .then(({ data }) => this.onSignatureCreated(data))
          .catch(({ data }) => this.onSignatureError(data?.error));
      } else if (params.signature_id) {
        this.$api
          .getSignature(params.signature_id, { include })
          .then(({ data }) => this.onSignatureCreated(data))
          .catch(({ data }) => this.onSignatureError(data?.error));
      }
    }

    onSignatureCreated(signature: Signature): void {
      this.localSignature = signature;
      this.onUpdate(this.localSignature.id.toString());
      this.toggleModal(false);
    }

    onSignatureError(error?: string): void {
      error && toaster({ text: error, icon: 'error' });
    }

    toggleModal(value: boolean): void {
      this.modalVisible = value;
    }

    beforeMount(): void {
      this.localValue = { ...this.localValue, ...this.value };
      if (!this.localValue.value) {
        return;
      }
      const include = ['user'];
      this.$api
        .getSignature(Number(this.localValue.value), { include })
        .then(({ data }) => {
          this.localSignature = data;
        })
        .catch((err) => {
          console.error(err);
          this.localSignature = null;
        });
    }
  }
