
  import { filter, map } from 'rxjs/operators';
  import { Component, Emit, Ref } from 'vue-property-decorator';
  import froalaConfig from '@app/froala-config';
  import { Tooltip } from 'uiv';
  import type { TextareaFieldValue } from '@app/models/question-response-types';
  import type { FieldType } from '@app/models/sub-form-question';
  import { safeEvaluate } from '@app/utils/safe-evaluate';

  import EntitySelector from '../entity-selector.vue';

  import BaseField from './base-field';

  const minTextareaHeight = 50;
  const extraSpacing = 20;

  @Component({ components: { EntitySelector, Tooltip } })
  export default class TextareaField extends BaseField<FieldType.textarea> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Ref() readonly textarea!: any;

    collapsed = true;
    froalaConfig = froalaConfig({ complex: true });
    loading = true;
    localValue: TextareaFieldValue = { value: '' };
    savedValue = '';
    showSavedValue = false;
    showToggle = false;

    get apiRequestShouldOverride(): boolean {
      const mode = this.question.config.api_request?.data_replace_mode || 'suggest_on_edit';
      if (mode === 'suggest_on_edit') {
        return !this.completionId;
      }
      return mode === 'override';
    }

    get completionId(): Maybe<number> {
      return this.completion?.id;
    }

    get dependentQuestionCodes(): string {
      return (this.question.config.api_request?.dependent_question_codes || []).join('|');
    }

    get isApiLookup(): boolean {
      return this.question.config.mode === 'api_lookup';
    }

    get isApiRequest(): boolean {
      return this.question.config.mode === 'api_request';
    }

    get richEditor(): boolean {
      return this.question.config.rich_text === 'true';
    }

    get showValue(): string {
      return this.showSavedValue ? this.savedValue : this.localValue.value || '';
    }

    get validateUniq(): boolean {
      return !this.isPublic && this.question.config.validate?.uniq === 'true';
    }

    @Emit('input')
    updateValue(value?: string): TextareaFieldValue {
      if (value !== this.localValue.value) {
        // TODO check ''
        this.localValue = { value: value ? this.sanitizeCSS(value) : value || '' };
        this.sendUpdate(this.localValue);
      }
      this.textAreaAdjust();
      return this.localValue;
    }

    applySavedValue(): void {
      this.updateValue(this.savedValue);
      this.savedValue = '';
      this.showSavedValue = false;
    }

    sanitizeCSS(value: string): string {
      if (!this.richEditor) return value;

      return value.replaceAll('!important', '');
    }

    textAreaAdjust(state?: boolean): void {
      const $textarea = $(this.textarea);
      const elem = $textarea[0];
      if (!elem) {
        return;
      }
      const clone = elem.cloneNode(true);
      // TODO: full vuejs
      clone.style.height = '1px';
      clone.style.position = 'absolute';
      clone.style.right = '100000%';
      const parent = elem.parentElement;
      parent && parent.appendChild(clone);
      const height = clone.scrollHeight > minTextareaHeight ? clone.scrollHeight : minTextareaHeight;
      parent && parent.removeChild(clone);
      const newHeight = height + extraSpacing;
      elem.style.height = newHeight + 'px';
      this.showToggle = elem.scrollHeight > minTextareaHeight + extraSpacing;
      state !== undefined && this.textAreaToggle(state);
    }

    textAreaToggle(value?: boolean): void {
      const $textarea = $(this.textarea);
      if (value !== undefined && this.collapsed !== value) {
        return;
      }
      const nextState = value === undefined ? this.collapsed : value;
      // TODO: full vuejs
      if (!nextState) {
        $textarea.css({ height: minTextareaHeight + 'px' });
      } else {
        $textarea.css({ height: '1px' });
        $textarea.css({ height: $textarea[0].scrollHeight + extraSpacing + 'px' });
      }
      this.collapsed = !nextState;
    }

    beforeMount() {
      this.$nextTick(() => {
        const value = this.value?.value || '';
        this.localValue = { value };
        this.loading = false;
      });
    }

    mounted(): void {
      if (!this.richEditor) {
        this.textAreaAdjust(false);
      }

      if (this.isApiRequest) {
        this.addSubscription(
          this.formObservers.apiRequestResult$
            .pipe(
              map((lookupResult) => lookupResult[this.question.id]),
              // distinctUntilChanged(),
              filter((result) => !!result)
            )
            .subscribe((result) => {
              const apiValue = safeEvaluate(result, this.question.config.api_request?.result_path);
              if (this.response?.id && !this.apiRequestShouldOverride) {
                this.savedValue = apiValue;
              } else {
                this.updateValue(apiValue);
              }
            })
        );
      }
      if (this.isApiLookup) {
        const lookupQuestionCode = this.question.config.api_lookup?.api_request_question_code;
        if (lookupQuestionCode) {
          this.addSubscription(
            this.formObservers.apiLookupResult$.subscribe((lookupResult) => {
              if (lookupQuestionCode in lookupResult) {
                const rawSelection = lookupResult[lookupQuestionCode];
                const value = rawSelection ? safeEvaluate(rawSelection, this.question.config.api_lookup?.result_path) : undefined;
                this.updateValue(value);
              }
            })
          );
        }
      }
    }
  }
