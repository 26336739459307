import type { DynamicDashboardSettings } from '@app/models/account';
import type DashboardPane from '@app/models/dashboard-pane';
import { DashboardPaneSize } from '@app/models/dashboard-pane';
import type { Widget } from '@app/models/widget';
import type { GridStackOptions, GridStackWidget } from 'gridstack';
import { pick } from 'lodash';

export type SaveDashboardResult = { subGridWidgets?: GridStackWidget[]; widgetsToSave: GridStackWidget[] };

export const convertDBWidgetToGridStackWidget = (widget: Widget): GridStackWidget => {
  return {
    ...pick(widget, ['x', 'y']),
    id: String(widget.id),
    w: widget.width,
    h: widget.height,
  };
};

export const isSubGridWidget = (w: GridStackWidget) => 'subGridOpts' in w;

export const MAX_SIX_BY_SIX_WIDGET_BOUNDING_HEIGHT = 6;
export const MAX_SIX_BY_SIX_SUB_GRID_HEIGHT = MAX_SIX_BY_SIX_WIDGET_BOUNDING_HEIGHT + 1;

export const ONE_COLUMN_BREAKPOINT = 880;

export const GRID_STACK_ITEM_SELECTOR = '.grid-stack-item';
export const GRID_STACK_ITEM_CONTENT_SELECTOR = '.grid-stack-item-content';
export const GRID_STACK_SUB_GRID_SELECTOR = `${GRID_STACK_ITEM_SELECTOR}.grid-stack-sub-grid`;

export const DEFAULT_DYNAMIC_DASHBOARD_SETTINGS: DynamicDashboardSettings = {
  white_space: 'priority_order',
  stretch_panes: true,
  hide_display_names: false,
};

const baseSubGridOpts: GridStackOptions = {
  disableResize: true,
  disableDrag: true,
  cellHeight: 60,
  margin: 10,
  referenceWidthForOneColumnMode: 'window-inner-width',
};

function shouldHideWhiteSpace(
  pane: DashboardPane,
  index: number,
  hasStackedSixBySixPanes: boolean,
  hideWhiteSpace: boolean,
  panes: DashboardPane[]
) {
  return (
    hideWhiteSpace &&
    pane.size === DashboardPaneSize.half &&
    (hasStackedSixBySixPanes || lastPaneOrNextNotSixBySix(index, panes)) &&
    window.innerWidth > ONE_COLUMN_BREAKPOINT
  );
}

function shouldStretchPane(pane: DashboardPane, index: number, stretchPanes: boolean, panes: DashboardPane[]) {
  return stretchPanes && pane.size === DashboardPaneSize.half && lastPaneOrNextNotSixBySix(index, panes);
}

function lastPaneOrNextNotSixBySix(index: number, panes: DashboardPane[]) {
  return index === panes.length - 1 || nextNotSixBySix(index, panes);
}

function nextNotSixBySix(index: number, panes: DashboardPane[]) {
  return panes[index + 1]?.size !== DashboardPaneSize.half;
}

function nextSixBySix(index: number, panes: DashboardPane[]) {
  return panes[index + 1]?.size === DashboardPaneSize.half;
}

function shouldKeepPriority(pane: DashboardPane, index: number, panes: DashboardPane[], priorityOrder: boolean) {
  return priorityOrder && pane.size === DashboardPaneSize.half && index < panes.length - 1 && nextNotSixBySix(index, panes);
}

function getConfigMaxFixedHeight(config: GridStackWidget[], hideDisplayNames: boolean) {
  const heights = config.map((node) => (node?.y || 0) + (node?.h || 0));
  const max = Math.max(...heights) || 1;
  return max + +!hideDisplayNames + +(max === 1 && !hideDisplayNames);
}

export const generatePersonalDashboardConfig = (
  panes: DashboardPane[],
  opts: {
    hideDisplayNames: boolean;
    hideWhiteSpace: boolean;
    priorityOrder: boolean;
    sixBySixSubGridHeight: number;
    stretchPanes: boolean;
  }
) => {
  const { sixBySixSubGridHeight, stretchPanes, hideWhiteSpace, priorityOrder, hideDisplayNames } = opts;
  const grid: GridStackWidget[] = [];

  let shouldSkip = false;

  const sixBySixPanes: DashboardPane[] = [];

  // each iteration will add new 'row' to the grid
  panes?.forEach((pane, index) => {
    if (!pane.config) return;
    if (shouldSkip) {
      shouldSkip = false;
      return;
    }

    const id = `${pane.dashboard_pane_group_id}_${pane.id}`;
    const blankSixBySixPane = { w: 6, h: sixBySixSubGridHeight, subGridOpts: { ...baseSubGridOpts, children: [] } };

    const shouldHide = shouldHideWhiteSpace(pane, index, !!sixBySixPanes.length, hideWhiteSpace, panes);

    // hide white space 6x6 behaviour
    if (shouldHide) {
      if (sixBySixPanes.length) {
        [sixBySixPanes.shift(), pane].forEach(
          (p) =>
            p &&
            grid.push({
              id: `${p.dashboard_pane_group_id}_${p.id}`,
              w: 6,
              h: sixBySixSubGridHeight,
              subGridOpts: { ...baseSubGridOpts, children: p.config },
            })
        );

        return;
      }

      sixBySixPanes.push(pane);
      return;
    }

    // regardless of white space and stretch settings
    // if there are no 6x6 panes in stack and the next one is 6x6
    // just place two 6x6 panes next to each other if we have two
    if (pane.size === DashboardPaneSize.half && nextSixBySix(index, panes)) {
      [pane, panes[index + 1]].forEach(
        (p) =>
          p &&
          grid.push({
            id: `${p.dashboard_pane_group_id}_${p.id}`,
            w: 6,
            h: sixBySixSubGridHeight,
            subGridOpts: { ...baseSubGridOpts, children: p.config },
          })
      );

      shouldSkip = true;
      return;
    }

    // all other cases
    if (pane.size === DashboardPaneSize.half) {
      const shouldStretch = shouldStretchPane(pane, index, stretchPanes, panes);

      grid.push({ id, w: shouldStretch ? 12 : 6, h: sixBySixSubGridHeight, subGridOpts: { ...baseSubGridOpts, children: pane.config } });

      if (!shouldStretch && shouldKeepPriority(pane, index, panes, priorityOrder)) {
        grid.push(blankSixBySixPane);
      }
    } else {
      grid.push({
        id,
        w: 12,
        h: getConfigMaxFixedHeight(pane.config, hideDisplayNames),
        subGridOpts: { ...baseSubGridOpts, children: pane.config },
      });
    }
  });

  // add it last if we have any sixBySixPanes
  if (sixBySixPanes.length) {
    const pane = sixBySixPanes.pop();
    pane &&
      grid.push({
        id: `${pane.dashboard_pane_group_id}_${pane.id}`,
        w: stretchPanes ? 12 : 6,
        h: sixBySixSubGridHeight,
        subGridOpts: { ...baseSubGridOpts, children: pane.config },
      });
  }

  return grid;
};
