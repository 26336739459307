import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { ModuleTab } from '@app/models/module-tab';

export class ModuleTabService extends BaseService<ModuleTab> {
  static readonly allowedAttributes = ['active', 'code', 'index', 'module_name', 'system', 'title', 'rule_set_id', 'user_collection_id'];
  static attributeNames($t: (key: string) => string): Partial<Record<keyof ModuleTab, string>> {
    return {
      module_name: $t('app.labels.module'),
      rule_set_id: $t('app.labels.rule_set'),
      user_collection_id: $t('app.labels.user_collection'),
    };
  }
  static readonly attributeServices: Partial<Record<keyof ModuleTab, string>> = {};
  static serviceTitle = ($t: (key: string) => string) => $t('app.labels.tab');

  async fetchEntity({ itemId }: ServiceRequest<ModuleTab>): Promise<Pick<ModuleTab, 'id' | 'title'>> {
    const { data } = await this.api.getModuleTab(itemId, { only: ['id', 'title'] }, { join: true, cache: true });
    return data;
  }

  async fetchDetails(request: ServiceRequest<ModuleTab>): Promise<DetailsServiceResponse> {
    const moduleTab = await this.fetchEntity(request);
    return {
      links: [
        {
          link: `/admin/settings/module_tabs/${moduleTab.id}`,
          prefix: this.t('app.labels.tab'),
          title: moduleTab.title,
          id: moduleTab.id,
        },
      ],
    };
  }
}
