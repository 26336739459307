
  import PaperTrailsItem from '@app/components/paper-trails/table/paper-trails-item.vue';
  import { Tooltip } from 'uiv';
  import DsModal from '@app/components/ds-modal.vue';
  import { mixins } from 'vue-class-component';
  import { Component, Model, Prop, Watch } from 'vue-property-decorator';
  import { debounce, isEmpty, compact } from 'lodash';
  import type { PaperTrailsFilters, PaperTrailVersion } from '@app/models/paper-trail-version';
  import { DEFAULT_FILTERS } from '@app/models/paper-trail-version';

  import FilterSelect from '../filter-select.vue';
  import Select2 from '../select2.vue';
  import GlobalDateFilter from '../filters/global-date-filter.vue';
  import RecordIndexPageFiltersLayout from '../record-index-page-filters-layout.vue';
  import SearchInput from '../search-input.vue';
  import DateTimePicker from '../date-time-picker.vue';
  import DsDropdown from '../ds-dropdown.vue';

  import { processObjectChanges, showVersion } from './mapping-utils';
  import type { CustomFiltersValue } from './paper-trails-base';
  import PaperTrailsBase from './paper-trails-base';
  import PaperTrailsPagination from './paper-trails-pagination.vue';

  const MAX_VERSION_RECORDS = 500; // TODO: Implement recycle view to remove it
  type Version = PaperTrailVersion;

  @Component({
    components: {
      PaperTrailsItem,
      DsModal,
      FilterSelect,
      Select2,
      GlobalDateFilter,
      RecordIndexPageFiltersLayout,
      SearchInput,
      DateTimePicker,
      DsDropdown,
      Tooltip,
      PaperTrailsPagination,
    },
  })
  export default class PaperTrailsModal extends mixins(PaperTrailsBase) {
    @Model('input') readonly value!: boolean;
    @Prop([String, Number, Array]) readonly recordId!: string | number | string[] | number[];
    @Prop(String) readonly recordType!: string;
    @Prop(String) readonly modalTitle?: string;
    @Prop({ type: Number, default: () => MAX_VERSION_RECORDS }) readonly limitPerPage!: number;
    @Prop({ type: Object, default: () => DEFAULT_FILTERS }) readonly filters!: PaperTrailsFilters;
    @Prop(Array) readonly attributeScope?: string[];

    visibleVersions: Version[] = [];
    debouncedCallback: ((key: string, value: string) => void) | null = null;
    loading = true;
    searching = false;
    customFilters: PaperTrailsFilters = DEFAULT_FILTERS;

    currentPage = 1;
    totalPage: Nullable<number> = null;
    totalCount: Nullable<number> = null;

    sortDirection: 'asc' | 'desc' = 'desc';

    get presentResult(): boolean {
      return !!this.visibleVersions.length;
    }

    @Watch('value')
    onModalValueChanged(newValue: boolean): void {
      if (newValue) {
        this.onPageChanged();
      } else {
        this.$api.cache.clear();
        this.versions = [];
        this.customFilters = { ...DEFAULT_FILTERS, ...this.filters };
      }
    }

    async predicate(version: PaperTrailVersion): Promise<boolean> {
      const userLink = this.userLinksMap[version.whodunnit];
      return showVersion(
        version,
        this.customFilters,
        this.accountStore.data,
        () => compact([userLink?.title, userLink?.subTitle]),
        () => this.metaDataMap[version.id],
        this.$t
      );
    }

    onSortDirectionChanged(): void {
      if (this.sortDirection === 'asc') {
        this.sortDirection = 'desc';
      } else {
        this.sortDirection = 'asc';
      }
      this.onPageChanged();
    }

    fetchVersions(): void {
      this.fetchAttributes(this.recordId, this.recordType);
      const pagination = this.limitPerPage ? { per_page: this.limitPerPage, page: this.currentPage } : {};
      const whodunnit = this.customFilters.user.map((u) => u.split('|')).flat();

      this.$api
        .getPaperTrails(
          {
            item_id: this.recordId,
            item_type: this.recordType,
            filters: {
              whodunnit,
              event: this.customFilters.event,
              item_type: this.customFilters.item_type,
              created_at: this.customFilters.created_at,
              '@object_changes': this.customFilters.attributes,
            },
            sort: this.sortDirection === 'asc' ? 'created_at' : '-created_at',
            with_children: true,
            ...pagination,
          },
          { cache: true }
        )
        .then(({ data: versions, headers }) => {
          this.totalCount = +headers.total;
          this.totalPage = Math.ceil(+headers.total / +headers['per-page']);
          // Checks if we can show the version and filter unsupported attributes
          this.versions = versions.filter((version) => !isEmpty(processObjectChanges(version, this.attributeScope)));
          // Pre-fetch all data relate to search queries
          this.versions.forEach((version) => {
            this.fetchVersionData(version);
          });
          this.rebuildVisibleVersions();
        })
        .finally(() => {
          this.loading = false;
        });
    }

    beforeMount(): void {
      this.customFilters = { ...DEFAULT_FILTERS, ...this.filters };

      this.debouncedCallback = debounce((...args) => {
        this.searching = true;
        const filterKey = args[1];
        const filterValue = args[0];
        this.customFilters = {
          ...this.customFilters,
          [filterKey]: filterValue,
        };
        this.rebuildVisibleVersions();
      }, 350);
    }

    initForm(): void {
      this.loading = true;
      this.currentPage = 1;
    }

    onPageChanged(page = 1): void {
      this.initForm();
      this.currentPage = page;
      this.fetchVersions();
    }

    onCustomFiltersChanged(key: keyof PaperTrailsFilters, value: CustomFiltersValue): void {
      this.$nextTick(() => {
        this.initForm();
        this.customFilters[key] = value;
        this.fetchVersions();
      });
    }

    onSearch(text: string): void {
      this.debouncedCallback && this.debouncedCallback(text, 'search');
    }

    reFetchData(): void {
      this.$api.cache.clear();
      this.initForm();
      this.fetchVersions();
    }

    async applySearch(): Promise<Version[]> {
      const versions = this.versions;
      const results = await Promise.all(versions.map(this.predicate));
      return versions.filter((_v, index) => results[index]);
    }

    rebuildVisibleVersions(): void {
      this.applySearch().then((versions) => {
        this.visibleVersions = versions;
        this.searching = false;
      });
    }
  }
