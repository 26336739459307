import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { RecordRelation } from '@app/models/record-relation';

export class RecordRelationService extends BaseService<RecordRelation> {
  static attributeNames($t: (key: string) => string): Partial<Record<keyof RecordRelation, string>> {
    return {
      from_id: $t('app.labels.from_record'),
      to_id: $t('app.labels.to_record'),
    };
  }

  async fetchDetails({ version }: ServiceRequest<RecordRelation>): Promise<DetailsServiceResponse> {
    const relationshipCode = this.extractValue('relationship_code', version);
    if (!relationshipCode) throw Error('Required relationship_code was missed');

    const { data: relationships } = await this.api.getRelationshipsWithSharedFilter(
      { only: ['id', 'name', 'code'] },
      'code',
      relationshipCode,
      {
        cache: true,
      }
    );
    let mainText = '';
    if (relationships.length) {
      const relationship = relationships[0];
      mainText = `${this.t('app.labels.relationship')}: ${relationship.name} [${relationship.code}]`;
    }
    return {
      links: [],
      mainText,
    };
  }
}
