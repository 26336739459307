
  import type { TimesheetTime } from '@app/models/question-response-types';
  import { orderBy } from 'lodash';
  import moment from 'moment';
  import { Component, Prop } from 'vue-property-decorator';
  import BaseFieldDisplay from './base-field-display';
  import type { FieldType } from '@app/models/sub-form-question';

  interface ShiftItem {
    date: string;
    time_hash: TimesheetTime;
  }

  @Component({ components: {} })
  export default class TimesheetDisplay extends BaseFieldDisplay<FieldType.timesheet> {
    @Prop(String) readonly action?: string;

    get shifts(): ShiftItem[] {
      const shiftsArray = Object.keys(this.response.shifts || {}).reduce((memo, date) => {
        if (this.response.shifts?.[date]) {
          return [
            ...memo,
            {
              date: date,
              time_hash: this.response.shifts[date],
            },
          ];
        }
        return memo;
      }, [] as ShiftItem[]);

      return orderBy(shiftsArray, (i) => moment(i.date), 'desc');
    }
  }
