export enum Week {
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
  sunday = 'sunday',
}

export interface FrequencyData {
  every: number;
  weeks?: Week[];
  dayOfWeek: {
    first?: Week[];
    second?: Week[];
    third?: Week[];
    last?: Week[];
  };
  dayOfMonths?: string[];
}
