import { Vue, Component } from 'vue-property-decorator';
import type { SelectOption } from '@app/models/question-options/shared';
import type { Dictionary } from '@app/models/dictionary';
import type { SubFormQuestion } from '@app/models/sub-form-question';
import type { BaseSelectQuestionOptions } from '@app/models/question-options/base-select-question-options';
import type { ColorViewModeQuestionOptions } from '@app/models/question-options/color-view-mode-question-options';

const TEXT_COLOR = '#2D3A41';
const NONE = 'none';

type HighlightedQuestionOptions = SubFormQuestion<BaseSelectQuestionOptions & ColorViewModeQuestionOptions>;

@Component
export default class HighlightColours extends Vue {
  highlightBackgroundFor(option?: SelectOption): string {
    return option?.highlight_background || NONE;
  }

  highlightTextFor(option?: SelectOption): string {
    return option?.highlight_text || TEXT_COLOR;
  }

  isOptionHighlighted(
    question: HighlightedQuestionOptions,
    mode: ColorViewModeQuestionOptions['color_view_mode'],
    option?: SelectOption
  ): boolean {
    if (!option) {
      return false;
    }
    if (!this.highlightedFor(mode, question)) {
      return false;
    }

    return !!option.highlight_background || !!option.highlight_text;
  }

  optionForKey(values: Record<string, SelectOption>, key?: string): SelectOption | undefined {
    return Object.values(values).find((options) => key === options.key);
  }

  highlightedFor(mode: ColorViewModeQuestionOptions['color_view_mode'], question: HighlightedQuestionOptions): boolean {
    const viewMode = question.config.color_view_mode;
    if (viewMode === 'all') {
      return true;
    }

    return viewMode === mode || (mode === 'read' && !viewMode);
  }

  outlineStyle(question: HighlightedQuestionOptions, option: SelectOption): Dictionary<string> | undefined {
    if (this.isOptionHighlighted(question, 'edit', option)) {
      return { 'outline-color': this.highlightBackgroundFor(option) };
    }
  }

  textStyle(
    mode: ColorViewModeQuestionOptions['color_view_mode'],
    question: HighlightedQuestionOptions,
    option?: SelectOption
  ): Dictionary<string> | undefined {
    if (this.isOptionHighlighted(question, mode, option)) {
      return { color: this.highlightTextFor(option) };
    }
  }

  backgroundStyle(
    mode: ColorViewModeQuestionOptions['color_view_mode'],
    question: SubFormQuestion<BaseSelectQuestionOptions & ColorViewModeQuestionOptions>,
    option?: SelectOption
  ): Dictionary<string> | undefined {
    const optionHighlighted = this.isOptionHighlighted(question, mode, option);
    if (!optionHighlighted) {
      return;
    }

    return { 'background-color': this.highlightBackgroundFor(option) };
  }

  textAndBackgroundStyles(
    mode: ColorViewModeQuestionOptions['color_view_mode'],
    question: HighlightedQuestionOptions,
    option?: SelectOption
  ): Dictionary<string> {
    const textStyle = this.textStyle(mode, question, option);
    const backgroundStyle = this.backgroundStyle(mode, question, option);

    return { ...textStyle, ...backgroundStyle };
  }
}
