
  import { useAccountStore } from '@app/stores/account';
  import bootbox from 'bootbox';
  import { Component, Emit } from 'vue-property-decorator';
  import DatePicker from '../date-picker.vue';
  import BaseField from './base-field';
  import type { TableCalculationFieldValue } from '@app/models/question-response-types';
  import { TableCalculationFieldStage } from '@app/models/question-response-types';
  import TableCalculationModal from '../table-calculation/table-calculation-modal.vue';
  import { isEmpty, isEqual } from 'lodash';
  import type { VariableData } from '../table-calculation/utils';
  import { saferEvaluate } from '../table-calculation/utils';
  import moment from 'moment';
  import type { SubFormCompletion } from '@app/models/sub-form-completion';
  import type { FieldType } from '@app/models/sub-form-question';
  import { SubFormCompletionStage } from '@app/models/sub-form-completion';
  import { TCF_SAVE_AND_INSERT_BROADCAST_TIME } from '@app/constants';

  @Component({ components: { DatePicker, TableCalculationModal } })
  export default class TableCalculationField extends BaseField<FieldType.table_calculation> {
    @Emit('input')
    onDraftSave({
      sourceRecordIdsHash,
      value,
      calculated,
    }: {
      calculated: Record<string, string>;
      sourceRecordIdsHash: Record<string, string>;
      value: string;
    }): TableCalculationFieldValue {
      this.localValue = {
        ...this.localValue,
        calculated,
        table_calculation_source_record_id_hash: sourceRecordIdsHash,
        value,
        stage: TableCalculationFieldStage.draft,
        variable_data: this.variableData,
      };
      return this.localValue;
    }

    onBroadcast(data: Record<string, string>): void {
      this.formObservers.codeValueData$.next(data);
    }

    @Emit('input')
    onCalculationSave({
      calculated,
      saveAndInsertBroadcastsCalculated,
      sourceRecordIdsHash,
      value,
      posted,
    }: {
      calculated: Record<string, string>;
      posted?: true;
      saveAndInsertBroadcastsCalculated: Record<string, string>;
      sourceRecordIdsHash: Record<string, string>;
      value: string;
    }): TableCalculationFieldValue {
      this.localValue = {
        ...this.localValue,
        calculated,
        value,
        table_calculation_source_record_id_hash: sourceRecordIdsHash,
        stage: TableCalculationFieldStage.complete,
        variable_data: this.variableData,
        posted,
      };

      if (posted && this.completion?.id && this.completion?.stage !== SubFormCompletionStage.Draft) {
        this.updateResponseOnPost(this.completion);
      }

      this.formObservers.codeValueData$.next(saveAndInsertBroadcastsCalculated);
      setTimeout(() => {
        this.formObservers.codeValueData$.next(calculated);
      }, TCF_SAVE_AND_INSERT_BROADCAST_TIME);
      return this.localValue;
    }

    @Emit('input')
    onReset(): TableCalculationFieldValue {
      this.localValue = { stage: TableCalculationFieldStage.empty };
      this.variableData = {};
      this.inputVariablesChanged = false;
      this.modalOpen = false;
      return this.localValue;
    }

    modalOpen = false;
    variableData: VariableData = {};
    inputVariablesChanged = false;
    localValue: TableCalculationFieldValue = { stage: TableCalculationFieldStage.empty };

    get accountStore() {
      return useAccountStore();
    }

    get additionalScope(): Maybe<Record<string, unknown>> {
      return {
        moment,
        $account: this.accountStore.data,
      };
    }

    updateResponseOnPost(completion: Pick<SubFormCompletion, 'id'>): void {
      this.$api.updateSubFormCompletion(completion.id, { responses: { [this.question.id]: this.localValue }, form_uuid: this.formUuid });
    }

    liveVariableData(): VariableData {
      return (this.question.config.table_calculation.variables || []).reduce((acc, variable) => {
        let value = $(`[name="response_by_code[${variable.source}]"]`).val() as string;
        if (variable.formula) {
          value = saferEvaluate(variable.formula, { [variable.code]: value, ...this.additionalScope });
        }
        return { ...acc, [variable.code]: value };
      }, {});
    }

    openModal(): void {
      const liveInputVars = this.liveVariableData();
      if (this.localStage === TableCalculationFieldStage.empty) {
        this.variableData = liveInputVars;
      } else if ([TableCalculationFieldStage.draft, TableCalculationFieldStage.complete].includes(this.localStage)) {
        this.variableData = this.value.variable_data || {};
      }
      this.inputVariablesChanged = !isEqual(liveInputVars, this.variableData);
      if (this.localStage === TableCalculationFieldStage.empty) {
        const missingVariables = (this.question.config.table_calculation.variables || []).filter(
          (variable) => variable.required && (!liveInputVars[variable.code] || liveInputVars[variable.code] === 'failed to calculate')
        );
        if (missingVariables.length) {
          bootbox.alert({
            buttons: {
              ok: { label: this.$t('app.buttons.cancel') },
            },
            title: 'Warning: Cannot proceed, missing input variables:',
            message: `<ul class="m-b-none">${missingVariables.map((v) => `<li>'${v.label}' is required</li>`)} </ul>`,
          });
        } else {
          this.modalOpen = true;
        }
      } else {
        this.modalOpen = true;
      }
    }

    get completionId(): number | undefined {
      return this.completion?.id;
    }

    get recordId(): number | undefined {
      return this.record?.id;
    }

    get localStage(): TableCalculationFieldStage {
      const hasCalculated = !isEmpty(this.localValue?.calculated);
      const hasResponseInComplete = this.response?.response?.stage === TableCalculationFieldStage.complete;
      const hasUUID = !!this.localValue?.value;

      if (hasResponseInComplete && hasCalculated) {
        return TableCalculationFieldStage.complete;
      } else if (hasUUID) {
        return TableCalculationFieldStage.draft;
      } else {
        return TableCalculationFieldStage.empty;
      }
    }

    get calculatedValue() {
      return this.localValue?.calculated || {};
    }

    get isReadonly() {
      return this.readonlyAfterPersistence || !!this.localValue?.posted;
    }

    get readonlyAfterPersistence(): boolean {
      return !!this.question.config.table_calculation.readonly_after_persistence && this.localStage === TableCalculationFieldStage.complete;
    }

    get buttonText(): string {
      const defaultText = 'View Calculations';
      if (this.readonlyAfterPersistence) {
        return this.question.config.table_calculation?.labels?.read_only_open_modal_button_label || defaultText;
      }
      if (this.localStage === TableCalculationFieldStage.empty) {
        return this.question.config.table_calculation?.labels?.open_modal_button_label?.empty;
      } else if (this.localStage === TableCalculationFieldStage.draft) {
        return this.question.config.table_calculation?.labels?.open_modal_button_label?.draft;
      } else if (this.localStage === TableCalculationFieldStage.complete) {
        return this.question.config.table_calculation?.labels?.open_modal_button_label?.complete;
      } else {
        return defaultText;
      }
    }

    get modalTitle(): string {
      return this.question.config.table_calculation?.labels?.modal_title || 'Data';
    }

    get valueToPersist(): Nullable<string> {
      return JSON.stringify(this.localValue);
    }

    get requiredValidationPass(): boolean {
      return !this.question.required || (this.localValue?.stage === TableCalculationFieldStage.complete && !!this.localValue.value);
    }

    beforeMount(): void {
      this.localValue = { ...this.value };
    }
  }
