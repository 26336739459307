import type { SelectOption } from '@app/models/question-options/shared';
import type {
  AddressFieldValue,
  ApprovalExpenseFieldValue,
  AreaFieldValue,
  CalculationSelectFieldValue,
  CalculationTextFieldValue,
  CalculatorFieldValue,
  CompanyRegisterFieldValue,
  DateFieldValue,
  DatetimeFieldValue,
  DetailFieldValue,
  ExpenseBudgetCategoryUuidFieldValue,
  ExpenseBudgetUuidFieldValue,
  ExpenseFieldValue,
  FileUploadFieldValue,
  KnowledgeBaseFieldValue,
  LocationFieldValue,
  MainFormQuestionListFieldValue,
  MainFormRelationFieldValue,
  MarkableFieldValue,
  MatrixFieldValue,
  MultiMainFormRelationFieldValue,
  MultiPersonSelectorFieldValue,
  MultiSelectFieldValue,
  OrganizationFieldValue,
  PayCalculatorFieldValue,
  RecordRelationFieldValue,
  ReportFieldValue,
  SchemeFieldValue,
  ScormFieldValue,
  SignatureFieldValue,
  SinglePersonSelectorFieldValue,
  SingleSelectFieldValue,
  SubFormRelationFieldValue,
  SvgSelectorFieldValue,
  TableCalculationFieldValue,
  TextFieldValue,
  TextareaFieldValue,
  TimesheetFieldValue,
  TimesheetSummaryFieldValue,
  ToocsFieldValue,
  VideoFieldValue,
  WorkflowStateFieldValue,
} from '@app/models/question-response-types';
import type { TrackEntry } from '@app/models/field-integration/track-entry';
import type { StrictUnion } from '@app/utils/types/strict-union';
import { Tuple } from '@app/utils/types/tuple';

import type { BaseEntity } from './base-entity';
import type { Attachment } from './attachment';
import type { SubFormSection } from './sub-form-section';
import type { AddressQuestionOptions } from './question-options/address-question-options';
import type { ExpenseQuestionOptions } from './question-options/expense-question-options';
import type { ApprovalStateQuestionOptions } from './question-options/approval-state-question-options';
import type { AreaQuestionOptions } from './question-options/area-question-options';
import type { BaseSelectQuestionOptions } from './question-options/base-select-question-options';
import type { ColorViewModeQuestionOptions } from './question-options/color-view-mode-question-options';
import type { CalculationSelectQuestionOptions } from './question-options/calculation-select-question-options';
import type { CalculationTextQuestionOptions } from './question-options/calculation-text-question-options';
import type { CalculatorQuestionOptions } from './question-options/calculator-question-options';
import type { CompanyRegisterQuestionOptions } from './question-options/company-register-question-options';
import type { DateQuestionOptions } from './question-options/date-question-options';
import type { DatetimeQuestionOptions } from './question-options/datetime-question-options';
import type { DetailQuestionOptions } from './question-options/detail-question-options';
import type { DisplayImageQuestionOptions } from './question-options/display-image-question-options';
import type { BaseQuestionOptions } from './question-options/base-question-options';
import type { FileUploadQuestionOptions } from './question-options/file-upload-question-options';
import type { KnowledgeBaseQuestionOptions } from './question-options/knowledge-base-question-options';
import type { LocationQuestionOptions } from './question-options/location-question-options';
import type { MainFormQuestionListOptions } from './question-options/main-form-question-list-options';
import type { MainFormRelationQuestionOptions } from './question-options/main-form-relation-question-options';
import type { MatrixQuestionOptions } from './question-options/matrix-question-options';
import type { MultiMainFormRelationQuestionOptions } from './question-options/multi-main-form-relation-question-options';
import type { MultiPersonSelectorQuestionOptions } from './question-options/multi-person-selector-question-options';
import type { MultiSelectQuestionOptions } from './question-options/multi-select-question-options';
import type { OrganizationQuestionOptions } from './question-options/organization-question-options';
import type { PayCalculatorQuestionOptions } from './question-options/pay-calculator-options';
import type { ReportQuestionOptions } from './question-options/report-question-options';
import type { RecordRelationQuestionOptions } from './question-options/record-relation-question-options';
import type { ScormQuestionOptions } from './question-options/scorm-question-options';
import type { SignatureQuestionOptions } from './question-options/signature-question-options';
import type { SinglePersonSelectorQuestionOptions } from './question-options/single-person-selector-question-options';
import type { SingleSelectQuestionOptions } from './question-options/single-select-question-options';
import type { SubFormRelationQuestionOptions } from './question-options/sub-form-relation-question-options';
import type { SvgSelectorQuestionOptions } from './question-options/svg-selector-question-config';
import type { TableCalculationQuestionOptions } from './question-options/table-calculation-question-options';
import type { TextQuestionOptions } from './question-options/text-question-options';
import type { TextareaQuestionOptions } from './question-options/textarea-question-options';
import type { TimesheetQuestionOptions } from './question-options/timesheet-question-options';
import type { TimesheetSummaryQuestionOptions } from './question-options/timesheet-summary-question-options';
import type { VideoQuestionOptions } from './question-options/video-question-options';
import type { MarkableQuestionOptions } from './question-options/markable-question-options';

export enum ExpensingMethod {
  multi_level = 'multi_level',
  question_code = 'expensable_method_select',
  single_level = 'single_level',
}

export enum FieldType {
  address = 'address',
  approval_expense = 'approval_expense',
  approval_state = 'approval_state',
  area = 'area',
  button_select = 'button_select',
  calculation_select = 'calculation_select',
  calculation_text = 'calculation_text',
  calculator = 'calculator',
  company_register = 'company_register',
  date = 'date',
  datetime = 'datetime',
  detail = 'detail',
  display_image = 'display_image',
  display_text = 'display_text',
  expense = 'expense',
  expense_budget_category_select = 'expense_budget_category_select',
  expense_budget_category_uuid = 'expense_budget_category_uuid',
  expense_budget_select = 'expense_budget_select',
  expense_budget_uuid = 'expense_budget_uuid',
  file_upload = 'file_upload',
  knowledge_base = 'knowledge_base',
  location = 'location',
  main_form_question_list = 'main_form_question_list',
  main_form_relation = 'main_form_relation',
  markable = 'markable',
  matrix = 'matrix',
  multi_checkbox = 'multi_checkbox',
  multi_main_form_relation = 'multi_main_form_relation',
  multi_person_selector = 'multi_person_selector',
  multi_select = 'multi_select',
  organization = 'organization',
  pay_calculator = 'pay_calculator',
  radio = 'radio',
  record_relation = 'record_relation',
  report = 'report',
  scheme = 'scheme',
  scorm = 'scorm',
  signature = 'signature',
  single_person_selector = 'single_person_selector',
  single_select = 'single_select',
  sub_form_relation = 'sub_form_relation',
  svg_selector = 'svg_selector',
  table_calculation = 'table_calculation',
  text = 'text',
  textarea = 'textarea',
  timesheet = 'timesheet',
  timesheet_summary = 'timesheet_summary',
  toocs = 'toocs',
  video = 'video',
  workflow_state = 'workflow_state',
}

export type LookupTypes = 'module_record' | 'user' | 'sub_form_completion' | 'location' | 'organization';

export const LOOKUP_TYPE_MAPPING: Record<string, LookupTypes> = {
  [FieldType.main_form_relation]: 'module_record',
  [FieldType.sub_form_relation]: 'sub_form_completion',
  [FieldType.location]: 'location',
  [FieldType.single_person_selector]: 'user',
  [FieldType.organization]: 'organization',
};

export type QuestionOptions = StrictUnion<FieldTypeDefinition[keyof FieldTypeDefinition]['options']>;

export interface SubFormQuestion<
  T extends BaseQuestionOptions = QuestionOptions,
  S = SelectOption & { calculation_value?: number; score?: string }
> extends BaseEntity {
  active: boolean;
  attachments?: Attachment[];
  code: string;
  config: T;
  description: string;
  field_type: FieldType;
  index: number;
  is_code_matched?: boolean;
  options: {
    historical_values?: Record<string, S>;
    values?: Record<string, S>;
  };
  question: string;
  required: boolean;
  short_title: string | null;
  sub_form_id: number;
  sub_form_section?: SubFormSection;
  sub_form_section_id: number;
  supports_mfql: boolean;
  system_code: string;
  title: string;
  tracked_usages?: TrackEntry[];
  uuid: string;
}

export const SELF_LOOKUP_KEY = '@record';

export const CALCULATE_COMMAND = '[CALCULATE]';
export const RUN_AUTO_SELECT_COMMAND = '[RUN_AUTO_SELECT]';

export const EXPENSABLE_TYPES = [FieldType.expense_budget_select, FieldType.expense_budget_category_select] as const;

export const BASE_SINGLE_SELECT_TYPES = Tuple([
  FieldType.button_select,
  FieldType.single_select,
  FieldType.radio,
  FieldType.calculation_select,
] as const);

export const SINGLE_SELECT_TYPES = Tuple([
  FieldType.approval_state,
  FieldType.expense,
  FieldType.workflow_state,
  ...BASE_SINGLE_SELECT_TYPES,
  ...EXPENSABLE_TYPES,
] as const);

export const FILTERABLE_TYPES = [
  FieldType.button_select,
  FieldType.single_select,
  FieldType.radio,
  FieldType.calculation_select,
  FieldType.multi_select,
  FieldType.multi_checkbox,
  // TODO: add this back
  // FieldType.main_form_question_list,
  FieldType.main_form_relation,
  FieldType.multi_main_form_relation,
  FieldType.location,
  FieldType.scheme,
  FieldType.organization,
  FieldType.single_person_selector,
  FieldType.multi_person_selector,
  FieldType.date,
  FieldType.datetime,
  FieldType.expense_budget_select,
  FieldType.expense_budget_category_select,
];

export const BASE_MULTI_SELECT_FIELD_TYPES = Tuple([FieldType.multi_select, FieldType.multi_checkbox] as const);

export const MULTI_SELECT_FIELD_TYPES = [...BASE_MULTI_SELECT_FIELD_TYPES, FieldType.main_form_question_list] as const;
export const ARRAY_FIELD_TYPES = Tuple([
  ...MULTI_SELECT_FIELD_TYPES,
  FieldType.multi_person_selector,
  FieldType.file_upload,
  FieldType.multi_main_form_relation,
  FieldType.svg_selector,
] as const);

export const RULES_APPLICABLE_TYPES = [
  ...SINGLE_SELECT_TYPES,
  ...MULTI_SELECT_FIELD_TYPES,
  FieldType.location,
  FieldType.organization,
  FieldType.calculator,
  FieldType.svg_selector,
  FieldType.toocs,
];

export const SCORABLE_TYPES = [
  FieldType.button_select,
  FieldType.knowledge_base,
  FieldType.multi_checkbox,
  FieldType.multi_select,
  FieldType.radio,
  FieldType.scorm,
  FieldType.single_select,
  FieldType.text,
  FieldType.textarea,
  FieldType.video,
];

export const NOT_LOCKABLE_BY_WORKFLOW = [
  FieldType.calculator,
  FieldType.detail,
  FieldType.display_image,
  FieldType.display_text,
  FieldType.scorm,
  FieldType.table_calculation,
  FieldType.video,
];

export const AUTO_SAVE_IGNORE_TYPES = [FieldType.record_relation, FieldType.timesheet];

export const DEFAULT_APPROVAL_STATE_CONFIG = {
  stages: ['Awaiting Approval', 'Approved', 'Rejected'],
  approved_step: 'Approved',
};

export const CUSTOM_RESPONSE_TEMPLATE_TYPES = [
  FieldType.address,
  FieldType.display_image,
  FieldType.display_text,
  FieldType.file_upload,
  FieldType.main_form_relation,
  FieldType.markable,
  FieldType.multi_main_form_relation,
  FieldType.record_relation,
  FieldType.report,
  FieldType.signature,
  FieldType.sub_form_relation,
  FieldType.svg_selector,
  FieldType.table_calculation,
  FieldType.textarea,
  FieldType.timesheet,
  FieldType.matrix,
];

export const APP_SUPPORTED_FIELD_TYPES = [
  FieldType.text,
  FieldType.textarea,
  FieldType.multi_select,
  FieldType.signature,
  FieldType.radio,
  FieldType.multi_checkbox,
  FieldType.button_select,
  FieldType.single_select,
  FieldType.matrix,
  FieldType.single_person_selector,
  FieldType.multi_person_selector,
  FieldType.organization,
  FieldType.location,
  FieldType.file_upload,
  FieldType.date,
  FieldType.datetime,
  FieldType.display_text,
  FieldType.display_image,
  FieldType.toocs,
];

export const SUBFORM_FILTERABLE_TYPES = [...BASE_SINGLE_SELECT_TYPES, ...BASE_MULTI_SELECT_FIELD_TYPES];

export type ResponseValue = FieldTypeDefinition[keyof FieldTypeDefinition]['value'];

type FieldTypeDefinition = {
  [FieldType.address]: {
    options: AddressQuestionOptions;
    value: AddressFieldValue;
  };
  [FieldType.approval_expense]: {
    options: ExpenseQuestionOptions;
    value: ApprovalExpenseFieldValue;
  };
  [FieldType.approval_state]: {
    options: ApprovalStateQuestionOptions;
    value: SingleSelectFieldValue;
  };
  [FieldType.area]: {
    options: AreaQuestionOptions;
    value: AreaFieldValue;
  };
  [FieldType.button_select]: {
    options: BaseSelectQuestionOptions & ColorViewModeQuestionOptions;
    value: SingleSelectFieldValue;
  };
  [FieldType.calculation_select]: {
    options: CalculationSelectQuestionOptions;
    value: CalculationSelectFieldValue;
  };
  [FieldType.calculation_text]: {
    options: CalculationTextQuestionOptions;
    value: CalculationTextFieldValue;
  };
  [FieldType.calculator]: {
    options: CalculatorQuestionOptions;
    value: CalculatorFieldValue;
  };
  [FieldType.company_register]: {
    options: CompanyRegisterQuestionOptions;
    value: CompanyRegisterFieldValue;
  };
  [FieldType.date]: {
    options: DateQuestionOptions;
    value: DateFieldValue;
  };
  [FieldType.datetime]: {
    options: DatetimeQuestionOptions;
    value: DatetimeFieldValue;
  };
  [FieldType.detail]: {
    options: DetailQuestionOptions;
    value: DetailFieldValue;
  };
  [FieldType.display_image]: {
    options: DisplayImageQuestionOptions;
    value: {};
  };
  [FieldType.display_text]: {
    options: BaseQuestionOptions;
    value: {};
  };

  [FieldType.expense_budget_category_select]: {
    options: BaseQuestionOptions;
    value: SingleSelectFieldValue;
  };
  [FieldType.expense_budget_category_uuid]: {
    options: BaseQuestionOptions;
    value: ExpenseBudgetCategoryUuidFieldValue;
  };
  [FieldType.expense_budget_select]: {
    options: BaseQuestionOptions;
    value: SingleSelectFieldValue;
  };
  [FieldType.expense_budget_uuid]: {
    options: BaseQuestionOptions;
    value: ExpenseBudgetUuidFieldValue;
  };
  [FieldType.expense]: {
    options: ExpenseQuestionOptions;
    value: ExpenseFieldValue;
  };
  [FieldType.file_upload]: {
    options: FileUploadQuestionOptions;
    value: FileUploadFieldValue;
  };
  [FieldType.knowledge_base]: {
    options: KnowledgeBaseQuestionOptions;
    value: KnowledgeBaseFieldValue;
  };
  [FieldType.location]: {
    options: LocationQuestionOptions;
    value: LocationFieldValue;
  };
  [FieldType.main_form_question_list]: {
    options: MainFormQuestionListOptions;
    value: MainFormQuestionListFieldValue;
  };
  [FieldType.main_form_relation]: {
    options: MainFormRelationQuestionOptions;
    value: MainFormRelationFieldValue;
  };
  [FieldType.markable]: {
    options: MarkableQuestionOptions;
    value: MarkableFieldValue;
  };
  [FieldType.matrix]: {
    options: MatrixQuestionOptions;
    value: MatrixFieldValue;
  };
  [FieldType.multi_main_form_relation]: {
    options: MultiMainFormRelationQuestionOptions;
    value: MultiMainFormRelationFieldValue;
  };
  [FieldType.multi_person_selector]: {
    options: MultiPersonSelectorQuestionOptions;
    value: MultiPersonSelectorFieldValue;
  };
  [FieldType.multi_select]: {
    options: MultiSelectQuestionOptions;
    value: MultiSelectFieldValue;
  };
  [FieldType.multi_checkbox]: {
    options: MultiSelectQuestionOptions;
    value: MultiSelectFieldValue;
  };
  [FieldType.organization]: {
    options: OrganizationQuestionOptions;
    value: OrganizationFieldValue;
  };
  [FieldType.pay_calculator]: {
    options: PayCalculatorQuestionOptions;
    value: PayCalculatorFieldValue;
  };
  [FieldType.radio]: {
    options: BaseSelectQuestionOptions & ColorViewModeQuestionOptions;
    value: SingleSelectFieldValue;
  };
  [FieldType.report]: {
    options: ReportQuestionOptions;
    value: ReportFieldValue;
  };
  [FieldType.record_relation]: {
    options: RecordRelationQuestionOptions;
    value: RecordRelationFieldValue;
  };
  [FieldType.scheme]: {
    options: BaseQuestionOptions;
    value: SchemeFieldValue;
  };
  [FieldType.scorm]: {
    options: ScormQuestionOptions;
    value: ScormFieldValue;
  };
  [FieldType.signature]: {
    options: SignatureQuestionOptions;
    value: SignatureFieldValue;
  };
  [FieldType.single_person_selector]: {
    options: SinglePersonSelectorQuestionOptions;
    value: SinglePersonSelectorFieldValue;
  };
  [FieldType.single_select]: {
    options: SingleSelectQuestionOptions;
    value: SingleSelectFieldValue;
  };
  [FieldType.sub_form_relation]: {
    options: SubFormRelationQuestionOptions;
    value: SubFormRelationFieldValue;
  };
  [FieldType.svg_selector]: {
    options: SvgSelectorQuestionOptions;
    value: SvgSelectorFieldValue;
  };
  [FieldType.table_calculation]: {
    options: TableCalculationQuestionOptions;
    value: TableCalculationFieldValue;
  };
  [FieldType.text]: {
    options: TextQuestionOptions;
    value: TextFieldValue;
  };
  [FieldType.textarea]: {
    options: TextareaQuestionOptions;
    value: TextareaFieldValue;
  };
  [FieldType.timesheet]: {
    options: TimesheetQuestionOptions;
    value: TimesheetFieldValue;
  };
  [FieldType.timesheet_summary]: {
    options: TimesheetSummaryQuestionOptions;
    value: TimesheetSummaryFieldValue;
  };
  [FieldType.toocs]: {
    options: BaseQuestionOptions;
    value: ToocsFieldValue;
  };
  [FieldType.video]: {
    options: VideoQuestionOptions;
    value: VideoFieldValue;
  };
  [FieldType.workflow_state]: {
    options: BaseQuestionOptions;
    value: WorkflowStateFieldValue;
  };
};

export type QuestionTypeMap<F extends FieldType = FieldType> = FieldTypeDefinition[F extends keyof FieldTypeDefinition ? F : never];
