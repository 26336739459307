import type { BaseEntity } from './base-entity';

export enum SpecialPermissionName {
  allow_commenting_without_edit = 'allow_commenting_without_edit',
  approve_editable_actions = 'approve_editable_actions',
  approve_editable_standalone_actions = 'approve_editable_standalone_actions',
  audit_trail = 'audit_trail',
  confidentiality = 'confidentiality',
  edit_activity_content = 'edit_activity_content',
  standalone_activity_creation = 'standalone_activity_creation',
}

export enum RecordActivityExtendedPermissions {
  record_activity_edit = 'record_activity_edit',
  record_activity_view = 'record_activity_view',
}

export enum GlobalAccess {
  Create = 'global_create_access',
  Delete = 'global_delete_access',
  Edit = 'global_edit_access',
  View = 'global_view_access',
}

export enum UiToggles {
  HideOnMainMenu = 'hide_on_main_menu',
  HideQuickAddButton = 'hide_quick_add_button',
}

export type SpecialPermission = SpecialPermissionName | RecordActivityExtendedPermissions | GlobalAccess | UiToggles;

export interface ExtendedPermission extends BaseEntity {
  concept_name: string;
  name: SpecialPermission | string;
  permittable_id: number;
  permittable_type: string;
}

export const CONFIDENTIALITY_BYPASS_PREFIX = 'confidentiality_restriction_bypass_';
