import type { ConfiguratorFilter } from '@app/models/configurator-filter';
import { hasQuestionCodesInFilter } from './has-question-codes-in-filter';
import { hasQuestionIdsInFilter } from './has-question-ids-in-filter';
import type { VisibilityStateUpdate } from './types/visibility-state';

export const hasVisibilityStateUpdateInFilter = (update: VisibilityStateUpdate, filterConfigs: ConfiguratorFilter[] = []): boolean => {
  return (
    hasQuestionIdsInFilter(
      update.map((q) => `${q.id}`),
      filterConfigs
    ) ||
    hasQuestionCodesInFilter(
      update.map((q) => q.code),
      filterConfigs
    )
  );
};
