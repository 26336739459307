
  import { Component } from 'vue-property-decorator';
  import DatePicker from '@app/components/date-picker.vue';
  import EntitySelector from '@app/components/entity-selector.vue';
  import Select2 from '@app/components/select2.vue';
  import UserSelector from '@app/components/user/user-selector.vue';
  import LocationSelector from '@app/components/location/location-selector.vue';
  import OrganizationSelector from '@app/components/organization/organization-selector.vue';
  import RecordSelector from '@app/components/record-selector.vue';
  import { ValidationProvider, ValidationObserver } from 'vee-validate';
  import type { BaseEntity } from '@app/models/base-entity';
  import UserDetails from '@app/mixins/user-details';
  import { mixins } from 'vue-class-component';
  import type { TenantUser } from '@app/models/tenant-user';
  import type { IncludeOption } from '@app/services/donesafe-api-utils';
  import { isPiiField } from '@app/utils/isPiiField';
  import { toaster } from '@app/utils/toaster';
  import { POSSIBLE_PII_FIELDS } from '@app/constants';
  import type { PartialNullBy } from '@app/utils/types/partial';

  @Component({
    components: {
      DatePicker,
      EntitySelector,
      Select2,
      UserSelector,
      LocationSelector,
      OrganizationSelector,
      RecordSelector,
      ValidationProvider,
      ValidationObserver,
    },
  })
  export default class UserDetailsTab extends mixins(UserDetails) {
    compPrefix = 'details';

    get allowToSave(): boolean {
      return this.basicAccess === 'edit' || this.permissionBasedAccess === 'edit';
    }

    get userGenderIdentity(): Nullable<string> {
      return this.genderIdentities.find((item) => item.id === this.user?.gender_identity_id)?.identity || null;
    }

    get userEmploymentType(): Nullable<string> {
      return this.employmentTypes.find((item) => item.id === this.user?.employment_type_id)?.name || null;
    }

    get basicAccess(): 'edit' | 'view' | null {
      if (!this.userId) return null;
      if (this.currentUserStore.checkProfilePermission({ id: this.userId }, 'basic', 'edit')) return 'edit';
      if (this.currentUserStore.checkProfilePermission({ id: this.userId }, 'basic', 'view')) return 'view';
      return null;
    }

    get permissionBasedAccess(): 'edit' | 'view' | null {
      if (!this.userId) return null;
      if (this.currentUserStore.checkProfilePermission({ id: this.userId }, 'permission_based', 'edit')) return 'edit';
      if (this.currentUserStore.checkProfilePermission({ id: this.userId }, 'permission_based', 'view')) return 'view';
      return null;
    }

    get userToUpdateParams(): Partial<TenantUser> {
      const basicAccessParams = {
        title: this.form.title,
        first_name: this.form.first_name,
        email: this.form.email,
        last_name: this.form.last_name,
        payroll_identifier: this.form.payroll_identifier,
        timezone: this.form.timezone ? this.form.timezone : null,
      };

      const permissionBasedAccess: Partial<
        PartialNullBy<
          TenantUser,
          'manager_id' | 'home_location_id' | 'home_organization_id' | 'location_ceiling_id' | 'organization_ceiling_id'
        >
      > & { related_module_records?: Record<string, number | null> } = {
        role_id: this.form.role_id!,
        position: this.form.position || '',
        manager_id: this.form.manager_id || null,
        home_location_id: this.form.home_location_id || null,
        home_organization_id: this.form.home_organization_id || null,
        related_module_records: this.pickRelatedRecords(this.form),
      };

      if (this.accountStore.data.limit_permissions_by_organization) {
        permissionBasedAccess.organization_ceiling_id = this.form.organization_ceiling_id || null;
      }

      if (this.accountStore.data.limit_permissions_by_location) {
        permissionBasedAccess.location_ceiling_id = this.form.location_ceiling_id || null;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let userToUpdateParams: { [key: string]: any } = {};

      if (this.basicAccess === 'edit') userToUpdateParams = { ...basicAccessParams };
      if (this.permissionBasedAccess === 'edit') userToUpdateParams = { ...userToUpdateParams, ...permissionBasedAccess };
      POSSIBLE_PII_FIELDS.forEach((field) => {
        if (!isPiiField(field, this.accountStore.data)) userToUpdateParams[field] = this.form[field];
      });

      return userToUpdateParams;
    }

    submit(): void {
      this.validator?.validate().then((result) => {
        if (result) {
          this.submitting = true;
          const include: IncludeOption = ['user_module_records'];
          if (!this.userId) {
            return;
          }

          this.$api
            .updateTenantUser(this.userId, { ...this.userToUpdateParams, include })
            .then(({ data }) => {
              toaster({
                text: this.$t('portal.users.update.user_successfully_updated'),
                position: 'top-right',
              });
              this.$api.cache.clear();
              this.setExistingUserRelatedRecords(data);
              const fields = this.validator?.fields;
              const requiredReloadWholePage = !!fields?.role_id?.dirty;
              requiredReloadWholePage && this.$router.go(0);
            })
            .catch(({ data }) => {
              toaster({ text: data?.error, position: 'top-right', icon: 'error' });
            })
            .finally(() => {
              this.submitting = false;
            });
        }
      });
    }

    entityName(entity: BaseEntity | undefined, key: keyof BaseEntity): string | number {
      return entity?.[key] || '';
    }

    fieldId(field: string) {
      return `${this.compPrefix}_${field}`;
    }

    beforeMount(): void {
      if (this.userGeneratedEmail && this.basicAccess === 'edit') {
        this.form.email = '';
      }

      const moduleRecordIds = this.user?.user_module_records?.map((userModuleRecord) => userModuleRecord.module_record_id) || [];
      const relatedRecordsPromises =
        this.permissionBasedAccess === 'view' && moduleRecordIds.length ? [this.getRelatedRecords(moduleRecordIds)] : [];

      this.loading = true;
      const promises = this.permissionBasedAccess
        ? [this.fetchUser(), this.getRelatedModules(), ...relatedRecordsPromises, this.getGenderIdentities(), this.getEmploymentTypes()]
        : [this.fetchUser(), this.getGenderIdentities(), this.getEmploymentTypes()];
      Promise.all(promises).finally(() => {
        if (this.user) {
          this.initForm(this.user);
          this.checkHomeLocationCeiling(this.form.home_location_id);
          this.checkHomeOrganizationCeiling(this.form.home_organization_id);
        }
        this.loading = false;
      });
    }
  }
