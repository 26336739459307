import type { SubFormListsTabSectionGroup } from '@app/models/sub-form-lists-tab-section-groups';

import { BaseService } from './base-service';

export class SubFormListsTabSectionGroupService extends BaseService<SubFormListsTabSectionGroup> {
  static readonly allowedAttributes = ['id', 'sub_form_list_id'];

  static readonly attributeServices: Partial<Record<keyof SubFormListsTabSectionGroup, string>> = {
    sub_form_list_id: 'SubFormList',
  };

  static serviceTitle = ($t: (key: string) => string): string => $t('app.labels.sub_form_lists_tab_section_group');

  static attributeNames($t: (key: string) => string): Partial<Record<keyof SubFormListsTabSectionGroup, string>> {
    return {
      sub_form_list_id: $t('app.labels.tab_section'),
    };
  }
}
