
  import { filter, map } from 'rxjs/operators';
  import { Component, Ref } from 'vue-property-decorator';
  import { get } from 'lodash';
  import { Typeahead, Tooltip } from 'uiv';
  import type { TextFieldValue } from '@app/models/question-response-types';
  import type { FieldType } from '@app/models/sub-form-question';
  import { safeEvaluate } from '@app/utils/safe-evaluate';

  import EntitySelector from '../entity-selector.vue';

  import BaseField from './base-field';

  @Component({ components: { EntitySelector, Typeahead, Tooltip }, methods: { safeEvaluate } })
  export default class TextField extends BaseField<FieldType.text> {
    @Ref() readonly typeaheadInput!: HTMLInputElement;

    loading = false;
    // eslint-disable-line @typescript-eslint/no-explicit-any
    localValue: TextFieldValue = { value: '' };
    savedValue = '';
    selectedValue = this.localValue.value;
    showSavedValue = false;
    typeAheadValue = '';

    get apiRequestShouldOverride(): boolean {
      const mode = this.question.config.api_request?.data_replace_mode || 'suggest_on_edit';
      if (mode === 'suggest_on_edit') {
        return !this.completionId;
      }
      return mode === 'override';
    }

    get completionId(): Maybe<number> {
      return this.completion?.id;
    }

    get dependentQuestionCodes(): string {
      return (this.question.config.api_request?.dependent_question_codes || []).join('|');
    }

    get isApiLookup(): boolean {
      return this.question.config.mode === 'api_lookup';
    }

    get isApiRequest(): boolean {
      return this.question.config.mode === 'api_request';
    }

    get needRefreshButton(): boolean {
      return !!this.savedValue && this.savedValue !== this.localValue.value;
    }

    get requireSelection(): boolean {
      return this.isApiRequest && this.question.config.api_request?.require_selection === 'true';
    }

    get selfReferencing(): boolean {
      return this.isApiRequest && this.question.config.api_request?.self_referencing === 'true';
    }

    get validateUniq(): boolean {
      return !this.isPublic && this.question.config?.validate?.uniq === 'true';
    }

    applySavedValue(): void {
      this.updateValue(this.savedValue);
      this.savedValue = '';
      this.showSavedValue = false;
    }

    asyncFunction(query: string, done: (options: unknown[]) => void): void {
      this.loading = true;
      const data = {
        ...this.dataByCode,
        [this.question.code]: query,
      };
      this.$api
        .executeApiRequest({ sub_form_question_ids: [this.question.id], data }, { cache: true })
        .then(({ data }) => {
          done(safeEvaluate(data[this.question.id], this.question.config.api_request?.result_path) || []);
        })
        .finally(() => (this.loading = false));
    }

    highlightItem(item: unknown): string {
      const value = safeEvaluate(item, this.question.config.api_request?.value_key);
      // TODO: value should be a string
      const inputValue = this.typeaheadInput.value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
      const regexOptions = 'ig';
      return value.replace(new RegExp(`${inputValue}`, regexOptions), '<b>$&</b>');
    }

    onTypeaheadInput(data: unknown): void {
      if (!data || typeof data === 'string') {
        this.updateValue(data as string);
      } else if (data && this.question.config.api_request?.value_key) {
        const value = get(data, this.question.config.api_request.value_key);
        this.updateValue(value);
        this.selectedValue = value;
        this.$nextTick(() => this.formObservers.apiLookupResult$.next({ [this.question.code]: data }));
      }
    }

    updateValue(value?: string): void {
      if (value !== this.localValue.value) {
        this.localValue = { value };
        this.sendUpdate(this.localValue);
        this.$emit('input', this.localValue);
      }
    }

    beforeMount(): void {
      const value = this.value?.value || '';
      this.localValue = { value };
      this.typeAheadValue = value;
      this.selectedValue = this.localValue.value;
    }

    mounted(): void {
      if (this.isApiRequest && !this.selfReferencing) {
        this.addSubscription(
          this.formObservers.apiRequestResult$
            .pipe(
              map((lookupResult) => lookupResult[this.question.id]),
              // distinctUntilChanged(),
              filter((result) => !!result)
            )
            .subscribe((result) => {
              const apiValue = safeEvaluate(result, this.question.config.api_request?.result_path)?.toString();
              if (this.localValue.value && !this.apiRequestShouldOverride) {
                this.savedValue = apiValue;
              } else {
                this.updateValue(apiValue);
              }
            })
        );
      }
      if (this.isApiLookup) {
        const lookupQuestionCode = this.question.config.api_lookup?.api_request_question_code;
        if (lookupQuestionCode) {
          this.addSubscription(
            this.formObservers.apiLookupResult$.subscribe((lookupResult) => {
              if (lookupQuestionCode in lookupResult) {
                const rawSelection = lookupResult[lookupQuestionCode];
                const value = rawSelection ? safeEvaluate(rawSelection, this.question.config.api_lookup?.result_path) : undefined;
                this.updateValue(value);
              }
            })
          );
        }
      }
      this.addSubscription(
        this.formObservers.codeValueData$.pipe(filter((data) => data[this.question.code] != null)).subscribe((data) => {
          this.updateValue(`${data[this.question.code]}`);
        })
      );
    }
  }
