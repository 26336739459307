
  import FilterErrors from '@app/components/admin/questions/filter-configurator/filter-errors.vue';
  import SubFormResponseOutput from '@app/components/sub-form-completion/sub-form-response-output.vue';
  import { isResponseEmpty } from '@app/services/model-helpers';
  import { Component, Emit, Ref } from 'vue-property-decorator';
  import type { SubFormRelationFieldValue } from '@app/models/question-response-types';
  import { omit } from 'lodash';
  import type { FieldType, SubFormQuestion } from '@app/models/sub-form-question';
  import type { SubFormCompletion } from '@app/models/sub-form-completion';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { wrapOrderOptions } from '@app/components/admin/questions/order-options/with-order-options';
  import type { SubFormResponse } from '@app/models/sub-form-response';

  import AutoSelectToggle from '../record-auto-select/auto-select-toggle.vue';
  import AutoSelectWarnings from '../record-auto-select/auto-select-warnings.vue';
  import WithRecordAutoSelect from '../../mixins/with-record-auto-select.vue';
  import EntitySelector from '../entity-selector.vue';

  @Component({ components: { SubFormResponseOutput, FilterErrors, EntitySelector, AutoSelectToggle, AutoSelectWarnings } })
  export default class SubFormRelationField extends WithRecordAutoSelect<FieldType.sub_form_relation> {
    @Ref() readonly autoSelectToggle!: AutoSelectToggle;

    localValue: SubFormRelationFieldValue = { value: '' };

    selectedCompletionForDetails: Nullable<SubFormCompletion> = null;
    selectedBaseCompletionForDetails: Nullable<SubFormCompletion> = null;

    get filters(): object {
      if (this.defaultTemplating) {
        return { id: 0 };
      }
      return { active: true };
    }

    get requiredFilters(): DonesafeFilterOptions<SubFormCompletion> {
      if (this.record?.id) {
        return {
          record_id: this.record.id,
          record_type: this.recordType,
          sub_form_id: this.question.config.sub_form_id,
          sub_form_list_id: this.question.config.sub_form_list_id,
        };
      }
      return {};
    }

    get fetchData(): boolean {
      return !this.defaultTemplating && !!this.record?.id;
    }

    get showDetails(): boolean {
      return !!this.question.config.question_ids?.filter(Boolean)?.length && !!this.record?.id;
    }

    get selectedCompletionResponsesHash() {
      return this.completionToResponsesHash(this.selectedCompletionForDetails);
    }

    get selectedBaseCompletionResponsesHash() {
      return this.completionToResponsesHash(this.selectedBaseCompletionForDetails);
    }

    get responsesToShow(): SubFormResponse[] {
      const questionIds = this.question.config.question_ids || [];
      return questionIds
        .filter(Boolean)
        .map((id) => {
          const stringId = `${id}`; // just in case
          return stringId.startsWith('^')
            ? this.selectedBaseCompletionResponsesHash[stringId.slice(1)]
            : this.selectedCompletionResponsesHash[stringId];
        })
        .filter((r) => r && this.filterResponsesToShow(r)) as SubFormResponse[];
    }

    get sort() {
      const { order_options, sort } = this.question.config;

      return !!order_options ? wrapOrderOptions(order_options) : sort;
    }

    @Emit('input')
    updateValue(value?: string): SubFormRelationFieldValue {
      this.localValue = { value };
      this.sendUpdate(this.localValue);
      this.updateDetails(value);
      this.lookupRequest({ lookupRecordId: this.localValue.value, autoSelectInProgress: this.autoSelectInProgress });
      return this.localValue;
    }

    filterResponsesToShow(r: SubFormResponse): boolean {
      if (!r.sub_form_question) return false;

      return r.sub_form_question.active || !isResponseEmpty(r.sub_form_question, r.response);
    }

    hiddenQuestion(question: SubFormQuestion): boolean {
      return question.config?.hide_question === 'true';
    }

    onOpen(): boolean {
      !this.defaultTemplating && this.checkIncompleteFilters(this.getFilters());
      return !this.incompleteFilters.length;
    }

    completionToResponsesHash(completion: Nullable<SubFormCompletion>): Record<string, SubFormResponse> {
      return completion?.sub_form_responses.reduce((m, r) => ({ ...m, [`${r.sub_form_question_id}`]: r }), {}) || {};
    }

    async updateDetails(subFormCompletionId?: number | string) {
      if (this.showDetails) {
        if (subFormCompletionId) {
          try {
            const { data: selectedCompletionForDetails } = await this.$api.getSubFormCompletionsWithSharedFilters(
              {
                filters: {
                  record_id: this.record?.id,
                  record_type: this.recordType,
                },
                only: ['id', 'sub_form_responses'],
                include: ['sub_form_question', 'attachments', 'print_value'],
              },
              'id',
              Number(subFormCompletionId),
              { cache: true }
            );
            this.selectedCompletionForDetails = selectedCompletionForDetails[0];
            if (
              this.selectedCompletionForDetails.approval_for_sub_form_completion_id &&
              this.question.config.question_ids?.some((id) => `${id}`.startsWith('^'))
            ) {
              const { data: selectedBaseCompletionForDetails } = await this.$api.getSubFormCompletion(
                this.selectedCompletionForDetails.approval_for_sub_form_completion_id,
                { only: ['sub_form_responses'], include: ['sub_form_question'] },
                { cache: true }
              );
              this.selectedBaseCompletionForDetails = selectedBaseCompletionForDetails;
            }
          } catch (e) {
            this.selectedCompletionForDetails = null;
            this.selectedBaseCompletionForDetails = null;
          }
        } else {
          this.selectedCompletionForDetails = null;
          this.selectedBaseCompletionForDetails = null;
        }
      }
    }

    beforeMount(): void {
      this.localValue = this.value || { value: '' };
    }

    onInit(): void {
      this.record?.id && this.hasAutoSelect && this.onAutoSelectInit();
      this.updateDetails(this.localValue.value);

      if (this.hasAutoSelect) this.initAutoSelectSubscriptions();
    }

    mounted(): void {
      this.onInit();
    }

    reFetchData(): void {
      const filters = this.getFilters();
      const allowAutoSelect = this.checkIncompleteFilters(filters, false);

      if (this.incompleteFilters.length || !allowAutoSelect) {
        this.updateValue();
        return;
      }

      this.autoSelectInProgress = true;
      this.updateLoadingState(true);
      this.$api
        .getSubFormCompletions(
          {
            only: ['id', 'title'],
            per_page: 1,
            sort: this.sort,
            filters: {
              ...this.requiredFilters,
              ...filters,
            },
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.recordSelector?.setOptions(data);
          const firstRecord = data?.[0];
          if (!!firstRecord?.id) {
            this.updateValue(String(firstRecord.id));
            this.warnings = omit(this.warnings, 'not_found');
          } else {
            this.updateValue();
            this.warnings = { ...this.warnings, not_found: this.$t('app.labels.no_records_found') };
          }
        })
        .finally(() => {
          this.autoSelectInProgress = false;
          this.updateLoadingState(false);
        });
    }
  }
