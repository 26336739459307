import I18n from '../i18n';

export const noResultsTemplate = (linkTitle: string, showNotFoundLink: boolean, noResultsClickLinkFunction: () => void): JQuery => {
  if (showNotFoundLink) {
    const $root = $(`<div><div>${I18n.t('app.labels.no_results_found')}</div></div>`);
    const $wrap = $(`<div class="m-t-xs"></div>`);
    const $link = $(`<a role="button">${linkTitle}...</a>`);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $link.on('click', (e: any) => {
      noResultsClickLinkFunction();
      e.stopPropagation();
    });
    $wrap.append($link);
    $root.append($wrap);
    return $root;
  }

  return $(
    `<div>
        <div>${I18n.t('app.labels.no_results_found')}</div>
     </div>`
  );
};
