
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';

  @Component({ components: {} })
  export default class SearchInput extends Vue {
    @Model('input') readonly value!: string;
    @Prop(Boolean) readonly autoFocus?: boolean;
    @Prop(String) readonly inputClass?: string;
    @Prop(String) readonly placeholder?: string;
    @Prop(Boolean) readonly disabled?: boolean;

    get searchPlaceholder(): string {
      return this.placeholder || this.$t('app.labels.search');
    }

    mounted(): void {
      this.autoFocus && this.$nextTick(() => (this.$refs.searchInput as HTMLInputElement).focus());
    }

    clearInput(): void {
      this.$emit('input', '');
    }
  }
