import type { Account } from '@app/models/account';
import { isEmpty } from 'lodash';

export const extraPayDetailStrings = (account: Account): Record<string, string> => {
  const extraPayDetailStrings = {
    overtime: 'Overtime',
    shift_allowance: 'Shift allowance',
    commission: 'Commission',
    piece_tally_bonus: 'Piece/tally bonus',
    motor_vehicle_allowance: 'Motor vehicle allowance',
    residential_allowance: 'Residential allowance',
    health_insurance: 'Health insurance',
    education_fees: 'Education fees',
  };
  if (!isEmpty(account?.custom_extra_pay_details)) {
    return {
      ...extraPayDetailStrings,
      ...account?.custom_extra_pay_details,
    };
  }
  return extraPayDetailStrings;
};
