import type { QuestionSfcOnly } from '@app/components/sub-form-completion/utils';
import type { Attachment } from '@app/models/attachment';
import { AvScanStatus } from '@app/models/attachment';
import type {
  CalculationTextFieldValue,
  CalculatorFieldValue,
  ExpenseFieldValue,
  SinglePersonSelectorTempFieldValue,
  SvgSelectorFieldValue,
  ToocsFieldValue,
} from '@app/models/question-response-types';
import { intersection, isEqual } from 'lodash';
import type { ModuleName } from '@app/models/module-name';
import type { QuestionTypeMap, ResponseValue, SubFormQuestion } from '@app/models/sub-form-question';
import type { SubFormResponse } from '@app/models/sub-form-response';
import {
  ARRAY_FIELD_TYPES,
  BASE_MULTI_SELECT_FIELD_TYPES,
  BASE_SINGLE_SELECT_TYPES,
  FieldType,
  FILTERABLE_TYPES,
} from '@app/models/sub-form-question';
import { ModuleType } from '@app/models/module-name';
import { TOOCS_FIELDS } from '@app/models/toocs/toocs-constants';
import type { CalculationSelectQuestionOptions } from '@app/models/question-options/calculation-select-question-options';
import type { CalculationTextQuestionOptions } from '@app/models/question-options/calculation-text-question-options';
import { ShowHideSource } from '@app/models/question-options/base-question-options';
import { valueInResponse } from '@app/utils/value-in-response';
import { Tuple } from '@app/utils/types/tuple';
import { isNilOrEmpty } from '@app/utils/is-null-or-empty';

import type { SubFormData } from '../api/sub-form-completions-api';
import { getCalculationTextCalculationValue } from '../calculation';

export const canDelete = (question: SubFormQuestion): boolean => {
  return (
    canEdit(question) &&
    ![FieldType.approval_state, FieldType.workflow_state, FieldType.main_form_question_list, FieldType.table_calculation].includes(
      question.field_type
    )
  );
};

export const canEdit = ({ code }: Pick<SubFormQuestion, 'code'>): boolean => !isFormManagedQuestion({ code });

export const isFormManagedQuestion = ({ code }: Pick<SubFormQuestion, 'code'>): boolean =>
  ['course_type', 'form_select', 'audit_type'].includes(code);

export const canBeReadonly = (question: Pick<SubFormQuestion, 'field_type'>): boolean => {
  return ![
    FieldType.approval_state,
    FieldType.calculator,
    FieldType.detail,
    FieldType.display_image,
    FieldType.display_text,
    FieldType.scorm,
    FieldType.video,
  ].includes(question.field_type);
};

export const hiddenQuestion = (question: Pick<SubFormQuestion, 'config'>): boolean => {
  return question.config.use_type === 'hidden';
};

export const hiddenCalculation = (
  question: Pick<SubFormQuestion<CalculationTextQuestionOptions | CalculationSelectQuestionOptions>, 'field_type' | 'config'>
): boolean => {
  return (
    [FieldType.calculation_select, FieldType.calculation_text].indexOf(question.field_type) > -1 &&
    question.config.visibility_override === 'hide'
  );
};

export const supportsApiRequest = (question: Pick<SubFormQuestion, 'field_type'>): boolean => {
  return [FieldType.text, FieldType.textarea, FieldType.single_select, FieldType.calculation_text].indexOf(question.field_type) > -1;
};

export const supportsApiLookup = (question: Pick<SubFormQuestion, 'field_type'>): boolean => {
  return [FieldType.text, FieldType.textarea, FieldType.calculation_text].indexOf(question.field_type) > -1;
};

export const SORTABLE_TYPES = [
  FieldType.text,
  FieldType.textarea,
  FieldType.button_select,
  FieldType.single_select,
  FieldType.date,
  FieldType.datetime,
  FieldType.single_person_selector,
  FieldType.approval_state,
  FieldType.matrix,
  FieldType.location,
  FieldType.organization,
  FieldType.main_form_relation,
  FieldType.radio,
  FieldType.calculator,
  FieldType.calculation_text,
  FieldType.workflow_state,
  FieldType.calculation_select,
  FieldType.address,
  FieldType.detail,
  FieldType.expense_budget_select,
  FieldType.expense_budget_category_select,
];

export const isReadonlyLookup = (question: Pick<SubFormQuestion, 'config'>): boolean => {
  return question.config?.mode === 'lookup' && question.config.lookup?.readonly === 'true';
};

export const isSortable = (question: SubFormQuestion): boolean => {
  return SORTABLE_TYPES.indexOf(question.field_type) > -1 && !isReadonlyLookup(question);
};

export const isFilterable = (question: SubFormQuestion): boolean =>
  FILTERABLE_TYPES.indexOf(question.field_type) > -1 && !(question.config.mode === 'lookup' && question.config.lookup?.readonly === 'true');

export const isSingleSelect = (question: Pick<SubFormQuestion, 'field_type'>): boolean => {
  return (BASE_SINGLE_SELECT_TYPES as FieldType[]).indexOf(question.field_type) > -1;
};
export const isMultiSelect = (question: Pick<SubFormQuestion, 'field_type'>): boolean =>
  (BASE_MULTI_SELECT_FIELD_TYPES as FieldType[]).indexOf(question.field_type) > -1;

export const isSelect = (question: SubFormQuestion): boolean => isSingleSelect(question) || isMultiSelect(question);

export const isArrayTypeQuestion = (question: Pick<SubFormQuestion, 'field_type'>): boolean =>
  (ARRAY_FIELD_TYPES as FieldType[]).indexOf(question.field_type) > -1;

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const isCompleted = (question: Pick<SubFormQuestion, 'field_type'>, value: any): boolean => {
  if (!value) {
    return false;
  } else if (question.field_type === FieldType.timesheet) {
    return value.start_date && value.end_date;
  } else if (question.field_type === FieldType.matrix) {
    return value.outcome;
  } else if (isArrayTypeQuestion(question)) {
    return value.length;
  } else {
    return value.value;
  }
};

export const isHybridModule = (moduleName: Pick<ModuleName, 'module_type'>): boolean => {
  return moduleName.module_type === ModuleType.form_managed;
};

export const hiddenByMfql = (
  question: Pick<SubFormQuestion, 'id' | 'supports_mfql'>,
  mfqlResponse: Maybe<SubFormResponse<FieldType.main_form_question_list>>
): boolean => {
  if (!question.supports_mfql || !mfqlResponse) {
    return false;
  }

  return !mfqlResponse?.response.map(String).includes(`${question.id}`);
};

type IsQuestionVisibleOpts = {
  allQuestionsBySystemCode: Map<string, Pick<SubFormQuestion, QuestionSfcOnly>>;
  baseFormResponsesBySystemCode: Record<string, SubFormResponse>;
  form: SubFormData;
  mainFormResponsesBySystemCode: Record<string, SubFormResponse>;
  mfqlResponse: Maybe<SubFormResponse<FieldType.main_form_question_list>>;
};

type IsQuestionVisibleFn = (question: Pick<SubFormQuestion, QuestionSfcOnly>, opts: IsQuestionVisibleOpts) => boolean;

const getArrayValue = (fieldType: FieldType, fieldValue: ResponseValue) => {
  if (fieldType === FieldType.toocs) {
    const value = fieldValue as ToocsFieldValue;
    return [value?.type ? `type:${value.type}` : null]
      .concat(...TOOCS_FIELDS.map((field) => Object.values(value[field as keyof ToocsFieldValue] || {}).map((x) => `${field}:${x}`)))
      .filter(Boolean)
      .map(String);
  }

  if (fieldType === FieldType.svg_selector) {
    return (fieldValue as SvgSelectorFieldValue).map((r) => r.value).map(String);
  }

  if (fieldType === FieldType.expense) {
    return [(fieldValue as ExpenseFieldValue).category];
  }

  return ((isArrayTypeQuestion({ field_type: fieldType }) ? fieldValue : [valueInResponse(fieldValue)]) as string[]).map(String);
};

const isVisibleInSubForm = (question: Pick<SubFormQuestion, QuestionSfcOnly>, opts: IsQuestionVisibleOpts) => {
  const { form, allQuestionsBySystemCode } = opts;
  if (!question.config.show?.id) return false;

  const baseQuestion = allQuestionsBySystemCode.get(`${question.config.show.id}`);
  if (!baseQuestion) return false;

  if (!isQuestionVisible(baseQuestion, opts)) return false;

  const formValue = form[`${baseQuestion.id}`];
  if (!formValue) return false;

  const arrayValue = getArrayValue(baseQuestion.field_type, formValue);
  const configValue = Array.isArray(question.config.show?.value) ? question.config.show?.value : [question.config.show?.value];
  return !!question.config.show?.value && !!intersection(configValue, arrayValue).length;
};

const isVisibleInMainForm = (
  question: Pick<SubFormQuestion, QuestionSfcOnly>,
  { mainFormResponsesBySystemCode }: IsQuestionVisibleOpts
) => {
  if (!question.config.show?.id) return false;

  const mainFormResponse = mainFormResponsesBySystemCode[`${question.config.show.id}`];
  if (!mainFormResponse?.response) return false;

  const arrayValue = getArrayValue(mainFormResponse.sub_form_question_field_type, mainFormResponse.response);
  return !!intersection(question.config.show?.value, arrayValue).length;
};

const isVisibleInBaseForm = (
  question: Pick<SubFormQuestion, QuestionSfcOnly>,
  { baseFormResponsesBySystemCode }: IsQuestionVisibleOpts
) => {
  if (!question.config.show?.id) return false;

  const baseFormResponse = baseFormResponsesBySystemCode[`${question.config.show.id}`];
  if (!baseFormResponse?.response) return false;

  const arrayValue = getArrayValue(baseFormResponse.sub_form_question_field_type, baseFormResponse.response);
  return !!intersection(question.config.show?.value, arrayValue).length;
};

export const isQuestionVisible: IsQuestionVisibleFn = (question, opts): boolean => {
  const { mfqlResponse } = opts;

  if (!question.config.show?.id && !hiddenByMfql(question, mfqlResponse)) return true;
  if (hiddenByMfql(question, mfqlResponse) || !question.config.show?.id) return false;

  if (question.config.show.source === ShowHideSource.BaseForm) {
    return isVisibleInBaseForm(question, opts);
  } else if (question.config.show.source === ShowHideSource.MainForm) {
    return isVisibleInMainForm(question, opts);
  } else {
    return isVisibleInSubForm(question, opts);
  }
};

type MappedValue<T extends FieldType> = QuestionTypeMap<T>['value'];
type FieldTypeToExclude =
  | (typeof ARRAY_FIELD_TYPES)[number]
  | (typeof IGNORE_UNSAVED_FIELD_TYPES)[number]
  | FieldType.expense
  | FieldType.matrix
  | FieldType.report
  | FieldType.timesheet
  | FieldType.toocs
  | FieldType.display_image
  | FieldType.display_text;

export function isResponseEmpty(q: Pick<SubFormQuestion, QuestionSfcOnly>, value: Maybe<ResponseValue>): boolean {
  if (isNilOrEmpty(value)) return true;

  if (isArrayTypeQuestion(q)) {
    return isNilOrEmpty(value as MappedValue<(typeof ARRAY_FIELD_TYPES)[number]>);
  }

  const fieldValue = q.field_type;

  switch (fieldValue) {
    case FieldType.expense:
      return isNilOrEmpty((value as MappedValue<FieldType.expense>).category);
    case FieldType.matrix:
      return isNilOrEmpty((value as MappedValue<FieldType.matrix>).matrix_cell_outcome_id);
    case FieldType.report:
      const reportValue = value as MappedValue<FieldType.report>;
      return isNilOrEmpty(reportValue.start_date) && isNilOrEmpty(reportValue.end_date);
    case FieldType.timesheet:
      return isNilOrEmpty((value as MappedValue<FieldType.timesheet>).shifts);
    case FieldType.toocs:
      return isNilOrEmpty((value as MappedValue<FieldType.toocs>).type);
    default:
      return isNilOrEmpty((value as MappedValue<Exclude<FieldType, FieldTypeToExclude>>).value);
  }
}

export const areResponsesEqual = (
  q: Pick<SubFormQuestion, QuestionSfcOnly>,
  value1: Maybe<ResponseValue>,
  value2: Maybe<ResponseValue>
) => {
  if (isResponseEmpty(q, value1) && isResponseEmpty(q, value2)) return true;

  if (isArrayTypeQuestion(q)) {
    const arrayValue1 = (value1 as QuestionTypeMap<(typeof ARRAY_FIELD_TYPES)[number]>['value']) || [];
    const arrayValue2 = (value2 as QuestionTypeMap<(typeof ARRAY_FIELD_TYPES)[number]>['value']) || [];
    return Array.isArray(arrayValue1) && Array.isArray(arrayValue2) && isEqual(arrayValue1.sort(), arrayValue2.sort());
  }

  if (q.field_type === FieldType.calculation_text) {
    return isEqual(
      getCalculationTextCalculationValue(
        q as SubFormQuestion<QuestionTypeMap<FieldType.calculation_text>['options']>,
        value1 as CalculationTextFieldValue
      ),
      getCalculationTextCalculationValue(
        q as SubFormQuestion<QuestionTypeMap<FieldType.calculation_text>['options']>,
        value2 as CalculationTextFieldValue
      )
    );
  }

  if (q.field_type === FieldType.calculator) {
    return isEqual((value1 as CalculatorFieldValue)?.value, (value2 as CalculatorFieldValue)?.value);
  }

  if (q.field_type === FieldType.single_person_selector) {
    // ignore use cases when adding a new user
    if ('user' in (value2 as SinglePersonSelectorTempFieldValue)) {
      return true;
    }
  }

  return isEqual(value1, value2);
};

export const isInfected = (attachment: Pick<Attachment, 'metadata'>) => {
  return attachment.metadata?.av_scan_status === AvScanStatus.virus_detected;
};

export const IGNORE_UNSAVED_FIELD_TYPES = Tuple([FieldType.record_relation] as const);
