
  import { Component, Model, Prop, Ref, Watch } from 'vue-property-decorator';
  import type { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
  import axios from 'axios';
  import { isEmpty, castArray, groupBy, sortBy, uniqBy, get } from 'lodash';
  import type { DonesafeFilterOptions, DonesafeIndexApiOptions, OnlyOptions } from '@app/services/donesafe-api-utils';
  import WithSelect2Accessibility from '@app/mixins/with-select2-accessibility';

  import type { BaseEntity } from '../models/base-entity';

  import Select2 from './select2.vue';

  interface Select2GroupedData<T> {
    children: T[];
    text: String;
  }

  type EntitySelectorValue = number | string | number[] | string[];
  const HARDCODED_GROUP_PROPERTY_NAME = 'special_group';

  @Component({ components: { Select2 } })
  export default class EntitySelector<T extends BaseEntity> extends WithSelect2Accessibility {
    @Model('input', { type: [String, Number, Array] }) value!: EntitySelectorValue;
    @Prop([Object, Function]) readonly filters?: DonesafeFilterOptions<T> | (() => DonesafeFilterOptions<T>);
    @Prop(Object) readonly fetchFilters?: DonesafeFilterOptions<T>;
    @Prop([Object, Function]) readonly requiredFilters!: DonesafeFilterOptions<T> | (() => DonesafeFilterOptions<T>);
    @Prop(Array) readonly include?: string[];
    @Prop(Number) readonly perPage?: number;
    @Prop(Boolean) readonly allowClear?: boolean;
    @Prop(Boolean) readonly fetchAll?: boolean;
    @Prop(Boolean) readonly followSortingOnInit?: boolean;
    @Prop(Boolean) readonly multiple?: boolean;
    @Prop(Boolean) readonly readonly?: boolean;
    @Prop(Boolean) readonly noCache?: boolean;
    @Prop(Boolean) readonly autoFocus?: boolean;
    @Prop(String) readonly sort?: string;
    @Prop(String) readonly dropdownParent?: string;
    @Prop(String) readonly inputId?: string;
    @Prop(Array) readonly only?: OnlyOptions<T>;
    @Prop([String, Array]) readonly labelKey!: keyof T;
    @Prop([String, Array]) readonly valueKey!: keyof T;
    @Prop(String) readonly placeholder?: string;
    @Prop(String) readonly missingPlaceholder?: string;
    @Prop(String) readonly errorPlaceholder?: string;
    @Prop(Function) readonly templateResult?: (result: T) => JQuery;
    @Prop(Function) readonly templateSelection?: (selection: T) => JQuery;
    @Prop(Function) readonly fetchMethod?: (options: DonesafeIndexApiOptions<T>, config?: object) => AxiosPromise<T[]>;
    @Prop(Function) readonly openMethod?: () => boolean;
    @Prop([String, Array, Function]) readonly groupBy?: string[] | ((option: T) => string);
    @Prop(Boolean) readonly sortable?: boolean;
    @Prop(Function) readonly noResultsMethod?: () => JQuery;
    @Prop(Function) readonly footerItem?: () => T;
    @Prop(Function) readonly selectMethod?: () => boolean;
    @Prop({ type: Array }) readonly staticOptions?: T[];

    @Ref() readonly select2!: Select2;

    options: T[] = [];
    results: T[] = [];

    get groupByForSelect2(): Maybe<string> {
      if (this.groupBy !== undefined && this.fetchAll) {
        return HARDCODED_GROUP_PROPERTY_NAME;
      }
      return undefined;
    }

    get allFilters(): DonesafeFilterOptions<T> {
      return {
        ...this.getFilters(),
        ...this.getRequiredFilters(),
      } as DonesafeFilterOptions<T>;
    }

    get cacheResults(): boolean {
      return !this.noCache;
    }

    get ajax(): null | object {
      if (this.fetchAll) {
        return null;
      }
      return {
        transport: (
          params: { data: { page?: number; term: string | null } },
          success: (params: { pagination?: { more: boolean }; results: T[] | Select2GroupedData<T>[] }) => void,
          failure: () => void
        ): { abort?: () => void } => {
          if (!this.fetchMethod) {
            return {};
          } else {
            const page = params.data.page || 1;
            const perPage = this.perPage || 25; // TODO: upgrade when have time as if less than 6 load more will stuck
            const filters = {
              search: params.data.term,
              ...this.getFilters(),
              ...this.getRequiredFilters(),
            } as DonesafeFilterOptions<T>;
            const cancelTokenSource = axios.CancelToken.source();
            const { sort, only, include } = this;

            this.fetchMethod(
              { filters, page, per_page: perPage, sort, include, only },
              { cancelToken: cancelTokenSource.token, cache: this.cacheResults }
            )
              .then(({ data, headers }: AxiosResponse<T[]>) => {
                const defaultResults = [...this.applySearch(params.data.term, this.staticOptions || []), ...data].map((d) => ({
                  ...d,
                  id: `${d[this.valueKey]}`,
                  text: d[this.labelKey],
                }));
                const hasMorePages = +headers.total > +headers['per-page'] * page;
                const footerResults = hasMorePages ? defaultResults : this.resultsWithFooters(defaultResults);
                const groupedResults = this.groupBy != undefined ? this.groupResults(footerResults) : null;
                const results = groupedResults || footerResults;
                const pagination = { more: hasMorePages };
                this.saveResults(footerResults);

                success({ results, pagination });
              })
              .catch((error: AxiosError) => {
                return error && !axios.isCancel(error) && failure();
              });
            return { abort: (): void => cancelTokenSource.cancel() };
          }
        },
        delay: 250,
        dataType: 'json',
      };
    }

    @Watch('allFilters', { deep: true })
    onAllFiltersChanged() {
      if (this.fetchAll && this.fetchMethod) {
        this.fetchAllMethod(this.fetchMethod);
      }
    }

    mounted(): void {
      if (this.fetchAll && this.fetchMethod) {
        this.fetchAllMethod(this.fetchMethod);
      } else {
        this.fetchData();
      }
    }

    notAccessibleFilters(data: T[]) {
      const arrayValue = castArray(this.value).filter((v) => v != undefined);
      if (this.followSortingOnInit) {
        const notAccessibleOptions = arrayValue
          .filter((v) => !data.find((item) => `${item[this.valueKey]}` === `${v}`))
          .map((v) => this.notAccessibleItem(`${v}`));
        return [...data, ...notAccessibleOptions];
      } else {
        return arrayValue.map((v) => {
          const item = data.find((item) => `${item[this.valueKey]}` === `${v}`);
          return item || this.notAccessibleItem(`${v}`);
        });
      }
    }

    fetchAllMethod(fetchMethod: (options: DonesafeIndexApiOptions<T>, config?: object) => AxiosPromise<T[]>): void {
      fetchMethod(
        {
          only: this.only,
          include: this.include,
          per_page: -1,
          sort: this.sort,
          filters: {
            ...this.getFilters(),
            ...this.getRequiredFilters(),
          } as DonesafeFilterOptions<T>,
        },
        { cache: this.cacheResults }
      ).then(({ data }) => {
        this.setOptions(data);
        this.saveResults(data);
      });
    }

    templateResultProxy(selection: { id: string; text: string }): ((selection: T) => JQuery) | string {
      const object = this.results.find((item) => `${item[this.valueKey]}` === selection.id);
      const template = this.templateResult;
      if (object && template != undefined) {
        return () => template({ ...selection, ...object });
      }
      return selection.text;
    }

    templateSelectionProxy(selection: { id: string; text: string }): ((selection: T) => JQuery) | string {
      const object = this.results.find((item) => `${item[this.valueKey]}` === selection.id);
      const template = this.templateSelection;
      if (object && template != undefined) {
        return () => template({ ...selection, ...object });
      }
      return selection.text;
    }

    getFilters(): Maybe<DonesafeFilterOptions<T>> {
      if (typeof this.filters === 'function') {
        return this.filters();
      }
      return this.filters;
    }

    getRequiredFilters(): Maybe<DonesafeFilterOptions<T>> {
      if (typeof this.requiredFilters === 'function') {
        return this.requiredFilters();
      }
      return this.requiredFilters;
    }

    notAccessibleItem(key: string): T {
      return {
        [this.labelKey]: this.missingPlaceholder || this.$t('app.labels.not_accessible').toString(),
        [this.valueKey]: key,
      } as unknown as T;
    }

    errorHandleItem(key: string, errorCode: string): T {
      return {
        [this.labelKey]:
          this.errorPlaceholder ||
          this.$t('app.labels.error_fetching_record_key', {
            key: key,
            error_code: errorCode,
          }).toString(),
        [this.valueKey]: key,
      } as unknown as T;
    }

    saveResults(results: T[]): void {
      const unifiedResults = [...this.results, ...results].map((item) => ({ ...item, [this.valueKey]: `${item[this.valueKey]}` }));
      this.results = uniqBy(unifiedResults, this.valueKey);
    }

    groupResults(results: T[]): Select2GroupedData<T>[] {
      const groupedResults = groupBy(
        results.map((d: T) => ({
          ...d,
          group: this.groupName(d),
        })),
        'group'
      );

      return Object.keys(groupedResults).map((k: string) => {
        return { text: k, children: groupedResults[k] as T[] };
      });
    }

    resultsWithFooters(results: T[]): T[] {
      const footers: T[] = [];
      if (this.footerItem && this.footerItem() && results.length) {
        footers.push(this.footerItem());
      }
      return [...results, ...footers];
    }

    groupName(item: T): string | undefined {
      if (typeof this.groupBy === 'function') {
        return this.groupBy(item);
      } else if (this.groupBy != undefined) {
        return `${get(item, this.groupBy)}`;
      }
    }

    fetchData(): void {
      // isEmpty returns false for string and number
      if (!this.value || !this.fetchMethod) {
        return;
      }

      const arrayValue = castArray(this.value);
      if (isEmpty(arrayValue)) {
        return;
      }

      const perPage = arrayValue.length;
      const fetchFilters = this.fetchFilters || { [this.valueKey]: arrayValue.join(',') };

      this.fetchMethod(
        {
          only: this.only,
          include: this.include,
          per_page: perPage,
          sort: this.sort,
          filters: {
            ...this.getRequiredFilters(),
            ...fetchFilters,
          } as DonesafeFilterOptions<T>,
        },
        { cache: this.cacheResults }
      )
        .then(({ data }: AxiosResponse<T[]>) => {
          const normalizedData = this.notAccessibleFilters(data);
          this.setOptions(normalizedData);
          this.saveResults(normalizedData);
        })
        .catch((error) => {
          console.error(error);
          this.setOptions(arrayValue.map((v) => this.errorHandleItem(`${v}`, error.status)));
        });
    }

    setOptions(options: T[] = []): void {
      const unifiedOptions = uniqBy(
        [...(this.staticOptions || []), ...options].map((option) => ({
          ...option,
          ...(!!this.groupByForSelect2 ? { [this.groupByForSelect2]: this.groupName(option) } : {}),
        })),
        this.valueKey
      );
      this.options = unifiedOptions;

      this.$emit('options-updated', this.options);
    }

    addOption(id: string, text: string, selected = false): void {
      this.select2.addOption(id, text, selected);
    }

    toggle(value: boolean): void {
      this.select2?.toggle(value);
    }

    close(): void {
      this.select2?.close();
    }

    focusSearch(): void {
      this.select2?.focusSearch();
    }

    // By default, options will be sorted according sort and sortable props
    // The unselected elements should be in the end of options against the initial order.
    onOpen(): void {
      if (!this.multiple || this.fetchAll) {
        return;
      }
      const values = (this.value as string[]) || [];
      const groupedOptions = groupBy(this.results, (r) => values.includes(`${r[this.valueKey]}`));
      if (!groupedOptions.true?.length || !groupedOptions.false?.length) {
        return;
      }
      this.setOptions([...sortBy(groupedOptions.true, this.sort || 'title'), ...groupedOptions.false]);
    }

    reFetch(): void {
      this.select2?.reFetch();
    }

    applySearch(search: string | null, options: T[]) {
      if (!search) return options;

      return options.filter((option) => {
        return JSON.stringify(option).toLowerCase().includes(search.toLowerCase());
      });
    }

    selectAll() {
      const values = this.options?.map((o) => o.id);
      values && this.select2?.populateSelect2AndEmitValue(values, true);
    }

    clearAll() {
      this.select2?.populateSelect2AndEmitValue([], true);
    }
  }
