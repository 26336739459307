import { useCurrentUserStore } from '@app/stores/currentUser';
import { Vue, Component, Prop } from 'vue-property-decorator';
import type { Activity } from '@app/models/activity';
import type { Attachment } from '@app/models/attachment';
import type { ModuleRecord } from '@app/models/module-record';
import type { SubFormResponse } from '@app/models/sub-form-response';
import { FieldType } from '@app/models/sub-form-question';

@Component
export default class WithRelatedObjects extends Vue {
  @Prop(Object) readonly response!: SubFormResponse;
  @Prop(Array) readonly newRelatedActivities?: Pick<Activity, 'id' | 'description'>[];
  @Prop(Array) readonly newRelatedRecords?: Pick<ModuleRecord, 'id' | 'title'>[];

  relatedAttachments: Pick<Attachment, 'id' | 'file_name' | 'metadata' | 'is_image'>[] = [];
  relatedRecords: Pick<ModuleRecord, 'id' | 'title'>[] = [];
  relatedActivities: Pick<Activity, 'id' | 'description'>[] = [];

  get currentUserStore() {
    return useCurrentUserStore();
  }

  get mergedRelatedActivities(): Pick<Activity, 'id' | 'description'>[] {
    return [...this.relatedActivities, ...(this.newRelatedActivities || [])];
  }

  get mergedRelatedRecords(): Pick<ModuleRecord, 'id' | 'title'>[] {
    return [...this.relatedRecords, ...(this.newRelatedRecords || [])];
  }

  get showRelatedObjects(): boolean {
    return !![...this.relatedAttachments, ...this.mergedRelatedRecords, ...this.mergedRelatedActivities].length;
  }

  get isFileUploadResponse(): boolean {
    return this.response?.sub_form_question_field_type === FieldType.file_upload;
  }

  get isPublic(): boolean {
    return !this.currentUserStore.data?.id;
  }

  activityLink(activity: Pick<Activity, 'id' | 'description'>): string {
    return `/actions/${activity.id}`;
  }

  recordLink(record: Pick<ModuleRecord, 'id' | 'title'>): string {
    return `/module_records/${record.id}`;
  }

  fetchRelatedItems(): void {
    if (this.response?.id && !this.isPublic) {
      !this.isFileUploadResponse &&
        this.$api
          .getAttachments(
            {
              filters: {
                attachable_type: 'SubFormResponse',
                attachable_sub_form_response: { sub_form_completion_id: this.response.sub_form_completion_id },
              },
              per_page: -1,
              only: ['id', 'file_name', 'url', 'attachable_id', 'attachable_type', 'metadata', 'preview_url', 'is_image'],
              preview_options: {
                resize_to_limit: [null, 400],
              },
            },
            { cache: true }
          )
          .then(({ data }) => {
            this.relatedAttachments = data.filter((attachment) => attachment.attachable_id === this.response.id);
          });
      this.$api
        .getActivities(
          {
            only: ['id', 'description', 'sub_form_response_id'],
            per_page: -1,
            filters: { sub_form_response: { sub_form_completion_id: this.response.sub_form_completion_id } },
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.relatedActivities = data.filter((activity) => activity.sub_form_response_id === this.response.id);
        });
      this.$api
        .getModuleRecords(
          {
            only: ['id', 'title', 'sub_form_response_id'],
            filters: { sub_form_response: { sub_form_completion_id: this.response.sub_form_completion_id } },
            per_page: -1,
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.relatedRecords = data.filter((record) => record.sub_form_response_id === this.response.id);
        });
    }
  }

  beforeMount(): void {
    this.fetchRelatedItems();
  }
}
