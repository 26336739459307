
  import { Component } from 'vue-property-decorator';
  import DateTimePicker from '@app/components/date-time-picker.vue';
  import { groupBy } from 'lodash';
  import type { Moment } from 'moment';
  import moment from 'moment';
  import BaseTimesheet from './base-timesheet';
  import type { Shift } from '@app/models/shift';
  import { ShiftType } from '@app/models/shift';

  @Component({ components: { DateTimePicker } })
  export default class ExpectedTimesheet extends BaseTimesheet {
    get momentStartDate(): Moment {
      return moment(this.startDate);
    }

    get momentEndDate(): Moment {
      return moment(this.endDate);
    }

    get dates(): Moment[] {
      return this.dateRange(this.momentStartDate, this.momentEndDate);
    }

    get validUserShifts() {
      return (
        this.user.shifts?.filter(
          (s) =>
            s.shift_type === ShiftType.expected &&
            moment(s.start_at).isBetween(this.momentStartDate.startOf('day'), this.momentEndDate.endOf('day'))
        ) || []
      );
    }

    initForm(shifts: Shift[]) {
      const shiftsByDay = groupBy(shifts, (s) => {
        return moment(s.start_at).format(this.dateFormat);
      });

      this.form = this.dates.reduce((memo, day: Moment) => {
        const dayStr = day.format(this.dateFormat);
        return {
          ...memo,
          [dayStr]: {
            start_at: this.toDate(shiftsByDay[dayStr]?.[0]?.start_at),
            end_at: this.toDate(shiftsByDay[dayStr]?.[0]?.end_at),
          },
        };
      }, {});

      this.shiftsLoaded = true;
    }

    beforeMount() {
      this.initForm(this.validUserShifts);
    }
  }
