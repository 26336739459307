
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { TenantUser } from '@app/models/tenant-user';
  import UserProfileModalInstance from '@app/components/admin/users/user-profile-modal-instance.vue';

  import UserImage from './user-image.vue';

  @Component({ components: { UserImage } })
  export default class UserImageLink extends Vue {
    @Prop(Object) user!: Pick<TenantUser, 'id' | 'can_be_opened' | 'full_name' | 'avatar_url' | 'first_name' | 'last_name'>;

    spawnVueModal() {
      new UserProfileModalInstance({ parent: this, propsData: { userId: this.user.id } }).$mount();
    }
  }
