
  import { Component } from 'vue-property-decorator';
  import type { LmsCourseEnrollment } from '@app/models/lms-course-enrollment';
  import { mixins } from 'vue-class-component';
  import WithUser from '@app/mixins/with-user';

  @Component({ components: {} })
  export default class UserEnrollmentsTab extends mixins(WithUser) {
    enrollments: LmsCourseEnrollment[] = [];

    beforeMount() {
      this.$api.getTenantUser(this.userId, { include: 'lms_course_enrollment_ids' }, { cache: true }).then(({ data }) => {
        const enrollmentIds = data.lms_course_enrollment_ids;
        if (!enrollmentIds) return;

        this.$api
          .getLmsCourseEnrollments(
            {
              only: ['id', 'start_date', 'end_date', 'current_score'],
              include: 'lms_course',
              filters: { id: enrollmentIds },
            },
            { cache: true }
          )
          .then(({ data }) => {
            this.enrollments = data;
          });
      });
    }
  }
