import { SuperReportService } from '@app/components/paper-trails/services/super-report-service';
import type { ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import { ScheduledEventService } from './scheduled-event-service';
import type { RecurringSetup } from '@app/models/recurring/recurring-setup';
import type { ScheduledEvent } from '@app/models/scheduled-event';
import type { SuperReport } from '@app/models/super-report';

export class RecurrableService extends BaseService<RecurringSetup, ScheduledEvent | SuperReport> {
  async fetchEntity(request: ServiceRequest<RecurringSetup>): Promise<Partial<ScheduledEvent | SuperReport>> {
    const type = this.extractValue('recurrable_type', request.version);
    const id = this.extractValue<number>('recurrable_id', request.version);
    if (id && type === 'ScheduledEvent') return ScheduledEventService.fetchEntity(this.api, id);
    if (id && type === 'SuperReport') return SuperReportService.fetchEntity(this.api, id);

    return super.fetchEntity(request);
  }

  entityToText({}: ServiceRequest<RecurringSetup>, entity?: Partial<ScheduledEvent | SuperReport>): string {
    return entity ? `${entity.name} [${entity.id}]` : '';
  }
}
