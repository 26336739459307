
  import { Component, Emit, Model, Prop, Vue } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import UserImage from '../user-image.vue';
  import FileUploader from '../file-uploader/file-uploader.vue';
  import type { Blob as ActiveStorageBlob } from '@rails/activestorage';
  import type { UserAvatarOnly } from './utils';
  import type { TenantUser } from '@app/models/tenant-user';
  import type { PartialNullBy } from '@app/utils/types/partial';

  @Component({ components: { DsModal, UserImage, FileUploader } })
  export default class UserAvatarFormModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Prop(Boolean) readonly loading?: boolean;
    @Prop(Object) user!: Partial<TenantUser>;

    form: Partial<PartialNullBy<Pick<TenantUser, UserAvatarOnly>, 'avatar_url'> & { avatar?: Nullable<string> }> = {};

    @Emit('submit')
    submitForm(): { avatar?: Nullable<string>; avatar_url?: Nullable<string> } {
      return { avatar: this.form.avatar, avatar_url: this.form?.avatar_url };
    }

    clearAvatar(): void {
      this.form = {
        ...this.form,
        avatar: null,
        avatar_url: null,
      };
      this.submitForm();
    }

    avatarUpload(file: ActiveStorageBlob & { blob: Blob }): void {
      this.form.avatar = file.signed_id;
      const urlCreator = window.URL || window.webkitURL;
      this.form.avatar_url = urlCreator.createObjectURL(file.blob);
    }

    beforeMount(): void {
      this.form = {
        ...this.user,
      };
    }
  }
