
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import { isEmpty } from 'lodash';
  import Select2 from '../select2.vue';
  import type { ToocsSection } from '@app/models/toocs/toocs-section';
  import type { ToocsValue } from '@app/models/toocs/toocs-value';
  import type { Dictionary } from '@app/models/dictionary';

  @Component({ components: { Select2 }, methods: { isEmpty } })
  export default class ToocsSubPicker extends Vue {
    @Prop(Boolean) required!: boolean;
    @Prop(Boolean) disabled!: boolean;
    @Prop(String) name!: string;
    @Prop(Object) section!: ToocsSection;
    @Prop(Number) sectionId!: number;
    @Prop(Object) readonly value!: ToocsValue;
    @Prop(Object) fullValue!: Dictionary<ToocsValue>;
    @Prop(Boolean) readonly quickMode?: boolean;

    localValue: string[] = [];

    @Watch('value', { immediate: true })
    onValueChange(value?: ToocsValue): void {
      this.localValue = Object.values(value || {});
    }

    get isSomeFieldPopulated(): boolean {
      return Object.keys(this.fullValue).some((k) => !isEmpty(this.fullValue[k]));
    }

    getChildren(level: number, value?: string) {
      const out = (options: any[]) => ({
        level,
        options: options.map((c) => ({
          value: c[0],
          label: c[1],
        })),
      });

      const dependencyFilter = (option: string[]): boolean => {
        const dependency = this.section.dependency;
        if (dependency && this.fullValue[dependency]) {
          const dependencyValues = Object.values(this.fullValue[dependency]);
          return option[3] ? option[3].split(',').some((i) => i === dependencyValues[dependencyValues.length - 1]) : false;
        }
        return true;
      };

      if (!level) {
        // first level values
        const options = this.section.options.filter((o) => !o[2]);
        const crossValidatedOptions = options.filter(dependencyFilter);
        return out(crossValidatedOptions.length ? crossValidatedOptions : options);
      } else {
        if (!value) {
          return out([]);
        }
        const options = this.section.options.filter((o) => o[2] === value);
        const crossValidatedOptions = options.filter(dependencyFilter);
        return out(crossValidatedOptions.length ? crossValidatedOptions : options);
      }
    }

    getOptions(): { level: number; options: { label: string; value: string }[] }[] {
      const localValue = this.localValue;
      const optionsForSelectedValues = localValue.map((v, i) => this.getChildren(i, localValue[i - 1]));

      const opt = this.getChildren(localValue.length, localValue[localValue.length - 1]);

      return opt.options.length ? optionsForSelectedValues.concat(opt) : optionsForSelectedValues;
    }

    toOutput(value: string[]): { [key: number]: string } {
      return value.reduce((m, v, i) => ({ ...m, [i]: v }), {});
    }

    onSelect(level: number, v?: string): void {
      const value = this.localValue.slice(0, level).concat(v ? [v] : []);
      this.localValue = value;
      this.$emit('input', this.toOutput(value));
    }
  }
