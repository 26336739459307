import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { Organization } from '@app/models/organization';
import { AdminPermissionFeature } from '@app/models/admin-permission';
import { featureEnabled } from '@app/utils/feature-enabled';

export class OrganizationService extends BaseService<Organization> {
  async fetchDetails(itemId: ServiceRequest<Organization>): Promise<DetailsServiceResponse> {
    const organization = await this.fetchEntity(itemId);
    return {
      links: [
        {
          link: this.organizationEditLink(organization),
          prefix: this.t('app.labels.organization'),
          title: organization.name,
          id: organization.id,
        },
      ],
    };
  }

  async fetchEntity({ itemId }: ServiceRequest<Organization>): Promise<Organization> {
    return await this.fetchOrganization(itemId);
  }

  entityToText({}: ServiceRequest<Organization>, entity?: Organization): string {
    return entity ? OrganizationService.toText(entity) : '';
  }

  async fetchOrganization(itemId: number | string): Promise<Organization> {
    const { data: organization } = await this.api.getOrganization(Number(itemId), { only: ['id', 'name'] }, { cache: true, join: true });
    return organization;
  }

  static toText(organization: Organization): string {
    return `${organization.name} [${organization.id}]`;
  }

  private organizationEditLink(organization: Pick<Organization, 'id' | 'name'>): string | undefined {
    if (featureEnabled(this.currentUser, AdminPermissionFeature.users_locations_organizations)) {
      return `/admin/settings/organizations/${organization.id}/edit`;
    }
  }
}
