import { useCurrentUserStore } from '@app/stores/currentUser';
import { useAccountStore } from '@app/stores/account';
import { Prop, Vue, Component } from 'vue-property-decorator';
import type { FieldType, QuestionTypeMap, SubFormQuestion } from '@app/models/sub-form-question';
import type { BaseRecord } from '@app/models/module-record';
import type { SubFormResponse } from '@app/models/sub-form-response';

@Component
export default class BaseFieldDisplay<F extends FieldType> extends Vue {
  @Prop(Object) readonly question!: SubFormQuestion<QuestionTypeMap<F>['options']>;
  @Prop(Object) readonly record!: Pick<
    BaseRecord,
    'id' | 'display_timezone' | 'sub_form_completion' | 'location_id' | 'organization_id' | 'user_id'
  >;
  @Prop(Object) readonly subFormResponse!: SubFormResponse<F>;
  @Prop(Boolean) readonly sidebarMode?: boolean;
  @Prop(Boolean) readonly modal!: boolean;
  @Prop(Boolean) readonly emptyFallback?: boolean;

  get currentUserStore() {
    return useCurrentUserStore();
  }

  get accountStore() {
    return useAccountStore();
  }

  moduleRecordLink(id: string | number): string {
    return `/module_records/${id}`;
  }

  get isPublic(): boolean {
    return !this.currentUserStore.data?.id;
  }

  get response(): QuestionTypeMap<F>['value'] {
    return this.subFormResponse.response;
  }

  get fallbackValue(): string {
    return this.emptyFallback ? '' : this.$t('app.labels.na').toString();
  }
}
