import { BaseService } from './base-service';
import type { ProcedureAcknowledgement } from '@app/models/procedure-acknowledgement';

export class ProcedureAcknowledgmentService extends BaseService<ProcedureAcknowledgement> {
  static readonly allowedAttributes = ['procedure_id'];
  static attributeNames($t: (key: string) => string): Partial<Record<keyof ProcedureAcknowledgement, string>> {
    return {
      procedure_id: $t('app.labels.acknowledged'),
    };
  }
  static readonly attributeServices: Partial<Record<keyof ProcedureAcknowledgement, string>> = {
    procedure_id: 'Procedure',
  };
  static serviceTitle = ($t: (key: string) => string): string => $t('app.labels.acknowledgment');
}
