
  import { debounce } from 'lodash';
  import type { Vue } from 'vue-property-decorator';
  import { Component, Prop } from 'vue-property-decorator';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import qs from 'qs';

  import { pageKeyToNumber } from './utils';
  import BaseTablePaginationMixin from './base-table-pagination-mixin';

  @Component({ components: { DsDropdown } })
  export default class BaseTablePagination extends BaseTablePaginationMixin {
    @Prop(String) readonly pageText?: string;

    changePageAsync = debounce(this.changePage, 500);

    get pageLabel(): string {
      return this.$t('app.labels.page');
    }

    get helperText(): Maybe<string> {
      const { from, to, total } = this.tablePagination || {};
      if (!!(from && to && total)) return this.$t('app.labels.pagination_helper', { from, to, total });
    }
    linkToPage(key: number): string {
      const parseOptions = { ignoreQueryPrefix: true, comma: false };
      const historyParams = qs.parse(window.location.search, parseOptions);

      // NOTE: pass pathname as a prop if reactivity issue occur
      return `${window.location.pathname}?${qs.stringify({
        ...historyParams,
        page: pageKeyToNumber(key, this.tablePagination?.current_page || 1),
      })}`;
    }

    changePage(page: number): void {
      const newPage = page > this.totalPage ? this.totalPage : page < 1 ? 1 : page;

      this.loadPage(newPage);
    }

    loadPageMethod(key: number | 'next' | 'prev'): void {
      this.changePage(pageKeyToNumber(key, this.tablePagination?.current_page || 1));
    }

    mounted(): void {
      const $deepDropdown = $((this.$refs.dropdownMenu as Vue)?.$refs.dropdown as Element);
      if (typeof $deepDropdown?.addClass === 'function') {
        $deepDropdown?.addClass('pre-scrollable');
      }
    }
  }
