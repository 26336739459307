
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import type { Subscription } from 'rxjs';
  import { filter } from 'rxjs/operators';
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import type { SinglePersonSelectorFormField } from '../admin/questions/extras/model';
  import UserSelector from './user-selector.vue';
  import RecordSelector from '../record-selector.vue';
  import DatePicker from '../date-picker.vue';
  import EntitySelector from '@app/components/entity-selector.vue';
  import { includes, chunk, get, setWith } from 'lodash';
  import { hasQuestionInFilter } from '@app/utils/has-question-in-filter';
  import FilterErrors from '../admin/questions/filter-configurator/filter-errors.vue';
  import type { TenantUser } from '@app/models/tenant-user';
  import type { ModuleRecord, BaseRecord } from '@app/models/module-record';
  import type { SubFormQuestion, ResponseValue } from '@app/models/sub-form-question';
  import type { ConfiguratorFilter } from '@app/models/configurator-filter';
  import type { SubFormCompletion } from '@app/models/sub-form-completion';
  import type { SinglePersonSelectorQuestionOptions } from '@app/models/question-options/single-person-selector-question-options';
  import { EmailMatchingBehaviour } from '@app/models/question-options/single-person-selector-question-options';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import type { FormObservers } from '@app/utils/types/form-observers';
  import { convertInFormFilters } from '@app/utils/convert-in-form-filters';
  import { incompleteMandatoryFilters } from '@app/utils/mandatory-filters';
  import type { Profile } from '@app/models/profile';
  import { useAccountStore } from '@app/stores/account';
  import Select2 from '@app/components/select2.vue';

  interface NewPersonData {
    user: Partial<TenantUser> & Partial<Profile>;
  }

  interface FormField extends SinglePersonSelectorFormField {
    hidden?: boolean;
    uniq?: boolean;
  }

  @Component({ components: { Select2, EntitySelector, DatePicker, UserSelector, RecordSelector, FilterErrors } })
  export default class NewPersonForm extends Vue {
    @Prop(String) name!: string;
    @Prop(Object) question!: SubFormQuestion<SinglePersonSelectorQuestionOptions>;
    @Prop(Boolean) isPublic!: boolean;
    @Prop(Object) userData!: Partial<TenantUser>;
    @Prop(Object) readonly completion?: Pick<SubFormCompletion, 'id' | 'record_id' | 'record_type'>;
    @Prop(Object) readonly record?: BaseRecord;
    @Prop(Object) readonly formObservers!: FormObservers;
    @Prop(Function) readonly getCurrentFormValueByQuestion!: (filterValue: string, key: 'id' | 'code') => Maybe<ResponseValue>;

    form: NewPersonData = { user: this.userData };
    incompleteFilters: ConfiguratorFilter[] = [];
    filterChangesSubscription: Nullable<Subscription> = null;

    get language(): Maybe<string> {
      return this.currentUserStore.data?.language?.includes('en') ? 'en' : this.currentUserStore.data?.language;
    }

    get accountStore() {
      return useAccountStore();
    }

    get languageOptions(): [string, string][] {
      return this.accountStore.languages.map((languageId) => [languageId, `${window.DONESAFE.LANGUAGES[languageId]} [${languageId}]`]);
    }

    get countryFilters() {
      return {
        language: this.language,
      };
    }

    get stateFilters() {
      return {
        language: this.language,
        country_code: this.form.user.country_code,
      };
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get required(): boolean {
      return this.question.required;
    }

    get fieldGroups(): FormField[][] {
      return chunk(
        this.fields.filter((field: FormField) => !field.hidden),
        2
      );
    }

    get fields(): FormField[] {
      let formFields: FormField[] = [
        {
          key: 'first_name',
          type: 'text',
          label: this.$t('app.labels.first_name') as string,
          name: `${this.name}[user][first_name]`,
          required: true,
        },
        {
          key: 'last_name',
          type: 'text',
          label: this.$t('app.labels.last_name') as string,
          name: `${this.name}[user][last_name]`,
          required: true,
        },
      ];
      if (this.question.config.fields) {
        formFields = formFields.concat(
          this.question.config.fields.map((field) => ({
            ...field,
            name: `${this.name}${field.name}`, // behind the response id eg: responses[1574][user][user_module_records][60]
            uniq:
              !this.isPublic &&
              field.key === 'email' &&
              this.question.config.email_matching_behaviour === EmailMatchingBehaviour.RequireUniqueEmail,
            hidden: this.isPublic ? includes(['user', 'record'], field.type) : false, // hide user and record pickers on public form
          }))
        );
      }
      return formFields;
    }

    get badConfig(): boolean {
      return !(this.form.user && this.form.user.type);
    }

    @Watch('userData', { immediate: true })
    userHiddenFieldsUpdated(): void {
      this.form = {
        ...this.form,
        user: {
          ...this.form.user,
          home_location_id: this.userData.home_location_id,
          home_organization_id: this.userData.home_organization_id,
        },
      };
    }

    @Watch('form', { deep: true })
    userFieldsUpdated(): void {
      this.$emit('data', this.form.user);
    }

    onCountrySelect() {
      this.form = {
        ...this.form,
        user: {
          ...this.form.user,
          state_code: undefined,
        },
      };
    }

    getFieldValueByName(field: FormField): string {
      return get(this.form, field.name.replace(this.name, ''));
    }

    onFieldInput(value: string, field: FormField): void {
      const form = { ...this.form };
      const newForm = setWith(form, field.name.replace(this.name, ''), value, Object);
      this.$set(this, 'form', newForm);
    }

    getFilters(filters: ConfiguratorFilter[] = []): () => DonesafeFilterOptions<ModuleRecord | TenantUser> {
      return (): DonesafeFilterOptions<ModuleRecord | TenantUser> =>
        convertInFormFilters<ModuleRecord | TenantUser>(filters, {
          user: this.currentUserStore.data,
          record: this.record,
          getCurrentFormValueByQuestion: this.getCurrentFormValueByQuestion,
        });
    }

    onOpen(filters: ConfiguratorFilter[] = []): (() => boolean) | undefined {
      return () => {
        this.incompleteFilters = [...incompleteMandatoryFilters<ModuleRecord | TenantUser>(filters, this.getFilters(filters)())];
        return !this.incompleteFilters.length;
      };
    }

    // as we only validate on submit to save api calls on remote validations
    // below clean the errors manually on input
    clearError(input: HTMLElement): void {
      $(input).parsley().reset();
    }

    fieldRequired(field: FormField): boolean {
      // when email input has value then add require attribute
      // to cause email format will trigger
      // and uniq validations will trigger when needed
      if (field.key === 'email' && !!this.form.user.email) {
        return true;
      }

      // if main question not required, and no fields filled in, then do not require any fields
      if (!this.required && this.fields.every((field) => !this.getFieldValueByName(field))) {
        return false;
      }

      return String(field.required) === 'true';
    }

    mounted(): void {
      this.filterChangesSubscription = this.formObservers.fieldUpdate$
        .pipe(filter(([question]) => hasQuestionInFilter(question, this.question.config.filters)))
        .subscribe(() => {
          this.incompleteFilters = [];
        });
    }

    beforeDestroy(): void {
      this.filterChangesSubscription?.unsubscribe();
    }
  }
