
  import { Component, Model, Prop, Ref } from 'vue-property-decorator';
  import type { Location } from '@app/models/location';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { Tuple } from '@app/utils/types/tuple';
  import { inactiveTitleTemplate } from '@app/utils/inactive-title-template';
  import WithSelect2Accessibility from '@app/mixins/with-select2-accessibility';

  import EntitySelector from '../entity-selector.vue';

  const only = Tuple(['id', 'name', 'location_id', 'active'] as const);
  type LocationOnly = (typeof only)[number];

  @Component({ components: { EntitySelector } })
  export default class LocationSelector extends WithSelect2Accessibility {
    @Prop(Boolean) multiple?: boolean;
    @Prop(Boolean) allowClear?: boolean;
    @Prop(Boolean) readonly?: boolean;
    @Prop([Object, Function]) filters?: DonesafeFilterOptions<Location> | (() => DonesafeFilterOptions<Location>);
    @Prop([Object, Function]) requiredFilters!: DonesafeFilterOptions<Location> | (() => DonesafeFilterOptions<Location>);
    @Model('input') value!: number | string;
    @Prop(String) readonly placeholder?: string;
    @Ref() readonly selector?: EntitySelector<Location>;
    @Prop(Array) readonly staticOptions?: string[];
    @Prop(String) readonly inputId?: string;

    only = only;

    inactiveTitleTemplate(org: Pick<Location, LocationOnly>) {
      return inactiveTitleTemplate<typeof org>(org);
    }

    toggle(value: boolean): void {
      this.selector?.toggle(value);
    }

    setOptions(options: Location[] = []): void {
      this.selector?.setOptions(options);
    }
  }
