
  import { useAccountStore } from '@app/stores/account';
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import moment from 'moment';
  import Select2 from '../select2.vue';

  import type { StringOption } from './models';
  import { formattedDate, localizedStringOptions } from './helper';
  import DatePicker from '../date-picker.vue';
  import SetupForm from './setup-form.vue';
  import type { RecurringSetup } from '@app/models/recurring/recurring-setup';
  import type { Schedule } from '@app/models/schedule';
  import { Frequency } from '@app/models/recurring/recurring-setup';
  import { IntervalType } from '@app/models/schedule';

  @Component({ components: { Select2, DatePicker, SetupForm } })
  export default class RecurringSchedulerForm extends Vue {
    @Prop({ type: String, default: () => 'schedule' }) readonly prefixName!: string;
    @Prop(Object) readonly schedule?: Partial<Schedule>;
    @Prop(Object) readonly recurringSetup?: RecurringSetup;
    @Prop({ type: Array, default: () => [] }) readonly ignoredIntervals!: string[];
    @Prop(Boolean) readonly defaultStartOnToday?: boolean;
    @Prop(Boolean) readonly scheduledEventActive?: boolean;

    scheduleForm: Partial<Schedule> = {};
    setupForm: Partial<RecurringSetup> = {};

    get startDate(): Maybe<Date> {
      if (this.scheduleForm.interval === IntervalType.Once && this.scheduledEventActive) {
        return moment().add(1, 'day').startOf('day').toDate();
      }
    }

    get intervalOptions(): StringOption[] {
      const options = localizedStringOptions(this.$t('tenant.schedules.intervals.options'));
      return options.filter((option) => !this.ignoredIntervals?.includes(option.code));
    }

    get intervalSplitOptions(): StringOption[] {
      return Array.from({ length: 30 }, (v, i) => i).map((code): StringOption => {
        const day = (code + 1).toString();
        return { code: day, label: day };
      });
    }

    get customDisabled(): boolean {
      return this.scheduleForm.interval !== IntervalType.Custom;
    }

    get dateFormat(): Maybe<string> {
      return this.accountStore.data.datetimepicker_date_format;
    }

    get accountStore() {
      return useAccountStore();
    }

    get startsOn(): Date | undefined {
      return (this.scheduleForm.starts_on && moment(this.scheduleForm.starts_on).toDate()) || undefined;
    }

    set startsOn(value: Date | undefined) {
      this.scheduleForm.starts_on = value && moment(value).format();
    }

    @Watch('scheduleForm', { deep: true, immediate: true })
    onScheduleFormChanged(val: Partial<Schedule>) {
      this.$emit('input-schedule-form', val);
    }

    @Watch('setupForm', { deep: true, immediate: true })
    onSetupFormChanged(val: Partial<RecurringSetup>) {
      this.$emit('input-setup-form', val);
    }

    beforeMount(): void {
      this.scheduleForm = {
        interval: this.schedule?.interval || IntervalType.Daily,
        interval_split: this.schedule?.interval_split || 1,
        starts_on: this.schedule?.starts_on || (this.defaultStartOnToday ? moment().startOf('day').format() : undefined),
      };

      this.setupForm = { enabled: this.scheduleForm.interval === IntervalType.Custom, ...this.recurringSetup };
    }

    formName(fieldName: string): string {
      return `${this.prefixName}[${fieldName}]`;
    }

    onIntervalChanged(interval: string): void {
      if (interval === IntervalType.Custom) {
        this.setupForm = { ...this.setupForm, enabled: true };
        return;
      }

      this.setupForm = { ...this.setupForm, frequency: Frequency.daily, enabled: false };
    }

    formattedDate(date: Date): string {
      return formattedDate(date);
    }
  }
