
  import { useAccountStore } from '@app/stores/account';
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import type { PayCalculatorTotalItem } from '@app/models/pay-calculator';
  import type { PiaweDetail } from '@app/models/tenant-user';
  import { extraPayDetailStrings } from '@app/utils/extra-pay-detail-strings';
  import { formatMoney } from '@app/utils/format-money';

  @Component
  export default class PayCalculatorTable extends Vue {
    @Model('input') readonly value!: Partial<PiaweDetail>;
    @Prop({ type: String, default: 'pay_detail_' }) readonly valuePrefix!: string;

    extraPayItems = [
      'overtime',
      'shift_allowance',
      'commission',
      'piece_tally_bonus',
      'motor_vehicle_allowance',
      'residential_allowance',
      'health_insurance',
      'education_fees',
    ];

    get extraPayDetailStrings(): Record<string, string> {
      return extraPayDetailStrings(this.accountStore.data);
    }

    get additionalItems(): PayCalculatorTotalItem[] {
      return this.extraPayItems.reduce<PayCalculatorTotalItem[]>((accum, itemKey) => {
        const value = Number(this.itemValue(itemKey));
        if (!isNaN(value) && value !== 0) {
          return [...accum, { amount: value, title: this.itemTitle(itemKey) }];
        }
        return accum;
      }, []);
    }

    get total(): number {
      const avg_hourly_rate = this.itemValue('avg_hourly_rate') || '0.0';
      const avg_hours_worked_per_week = this.itemValue('avg_hours_worked_per_week') || '0.0';
      const extraTotal = this.extraPayItems.reduce<number>((accum, itemKey) => accum + Number(this.itemValue(itemKey) || '0'), 0);
      const amount = Number(avg_hourly_rate) * Number(avg_hours_worked_per_week);
      if (!isNaN(extraTotal)) {
        return amount + extraTotal;
      }

      return amount;
    }

    get totalFormatted(): string {
      return this.formatMoney(this.total);
    }

    get accountStore() {
      return useAccountStore();
    }

    formatMoney(value: string | number): string {
      const numericValue = parseFloat(value as string);
      if (isNaN(numericValue)) {
        return formatMoney('0.00');
      } else {
        return formatMoney(value);
      }
    }

    itemValue(itemKey: string): string | undefined | number {
      return this.value[`${this.valuePrefix}${itemKey}` as keyof PiaweDetail];
    }

    itemTitle(itemKey: string): string | undefined {
      return this.extraPayDetailStrings[itemKey];
    }
  }
