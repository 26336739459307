
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { Tooltip } from 'uiv';
  import type { TenantUser } from '@app/models/tenant-user';
  import { userInitials } from '@app/utils/user-initials';

  @Component({ components: { Tooltip } })
  export default class UserImage extends Vue {
    @Prop(Object) user!: TenantUser;
    @Prop({ type: String, default: () => 'md' }) readonly size!: 'md' | 'lg';
    @Prop({ type: Boolean, default: () => true }) readonly enableTooltip!: boolean;

    get initials(): string {
      return userInitials(this.user);
    }

    get profileNameClass(): Record<string, boolean> {
      return {
        'profile-name--lg': this.size === 'lg',
      };
    }
  }
