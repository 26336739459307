import moment from 'moment';
import { ResponseType } from '../models';
import { versionDate, versionTime } from '../utils';
import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { Activity } from '@app/models/activity';
import type { PaperTrailChange } from '@app/models/paper-trail-version';
import type { DonesafeApi } from '@app/services/donesafe-api';

const DATE_TIME_ATTRIBUTES = ['closed_at', 'approved_at'];
const DATE_ATTRIBUTES = ['date'];

export class ActivityService extends BaseService<Activity> {
  static readonly allowedAttributes = [
    'approved',
    'approved_at',
    'approver_id',
    'assigned_approver_id',
    'action_priority_id',
    'actionable_id',
    'assignee_id',
    'closed_at',
    'comment',
    'completed_by_id',
    'date',
    'description',
    'requires_approval',
    'require_completion_comment',
    'state',
    'sub_form_completion_id',
  ];
  static attributeNames($t: (key: string) => string): Partial<Record<keyof Activity, string>> {
    return {
      date: $t('app.labels.due_date'),
      require_completion_comment: $t('tenant.activities.form.require_completion_comment'),
      description: $t('app.labels.title'),
      comment: $t('app.labels.description'),
      actionable_id: $t('app.labels.record'),
      approved_at: $t('app.labels.approved_at'),
      approved_by_id: $t('app.labels.approved_by'),
      approved: $t('tenant.comments.contexts.action_approved'),
      requires_approval: $t('app.labels.requires_approval'),
    };
  }

  static async fetchEntity(api: DonesafeApi, itemId: Activity['id']): Promise<Activity> {
    const { data: activity } = await api.getActivity(itemId, { only: ['id', 'description'] }, { join: true, cache: true });
    return activity;
  }

  async fetchDetails(itemId: ServiceRequest<Activity>): Promise<DetailsServiceResponse> {
    const activity = await this.fetchEntity(itemId);
    return {
      links: [
        {
          link: `/actions/${activity.id}`,
          prefix: this.t('app.labels.action'),
          title: activity.description,
          id: activity.id,
        },
      ],
    };
  }

  async fetchEntity({ itemId }: ServiceRequest<Activity>): Promise<Activity> {
    return ActivityService.fetchEntity(this.api, itemId);
  }

  normalizeText(change: Nullable<PaperTrailChange>, key: keyof Activity): Nullable<string> {
    if (change) {
      if (DATE_ATTRIBUTES.includes(key)) return moment(change).format(this.account?.datetimepicker_date_format);
      if (DATE_TIME_ATTRIBUTES.includes(key)) return moment(change).format(this.account?.datetimepicker_datetime_format);
    }
    return super.normalizeText(change, key);
  }

  normalizeValue(change: Nullable<PaperTrailChange>, key: keyof Activity): Nullable<PaperTrailChange> {
    if (change) {
      if (DATE_ATTRIBUTES.includes(key)) return versionDate(this.account, change as string);
      if (DATE_TIME_ATTRIBUTES.includes(key)) return versionTime(this.account, change as string);
    }
    return super.normalizeValue(change, key);
  }

  responseType(key: keyof Activity): ResponseType {
    if (DATE_ATTRIBUTES.includes(key)) return ResponseType.Date;
    if (DATE_TIME_ATTRIBUTES.includes(key)) return ResponseType.DateTime;
    if (key === 'sub_form_completion_id') return ResponseType.SubFormCompletion;

    return super.responseType(key);
  }
}
