
  import moment from 'moment';

  import { Component } from 'vue-property-decorator';
  import { BULK_EDIT_IGNORE_VALUE } from '../../utils';
  import BaseEditableColumn from './base-editable-column';
  import DatePicker from '@app/components/date-picker.vue';
  import type { TableCalculationColumnDate } from '@app/models/question-options/table-calculation-question-options';

  @Component({ components: { DatePicker } })
  export default class DateEditableColumn extends BaseEditableColumn<TableCalculationColumnDate> {
    get datePlaceholder(): string {
      return this.bulkEdit ? BULK_EDIT_IGNORE_VALUE : this.column?.placeholder || this.$t('app.labels.select_date');
    }

    dateValue(): Maybe<Date> {
      return (this.value && moment(this.value).toDate()) || undefined;
    }

    onDateChange(date: Maybe<Date>): void {
      const dateString = date && moment(date).format('format' in this.column ? this.column.format : '');
      this.$emit('input', dateString || null);
    }
  }
