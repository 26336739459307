
  import { Component } from 'vue-property-decorator';
  import TextHighlight from 'vue-text-highlight';
  import BaseDetails from '../details/base-details';
  import { DETAILS_COMPONENTS, DETAILS_COMPONENTS_MAPPING } from '../details/components';
  import { isTranslation } from '../mapping-utils';
  import type { DetailsLink } from '../models';
  import PaperTrailsTranslationDetails from './paper-trails-translation-details.vue';

  @Component({ components: { TextHighlight, ...DETAILS_COMPONENTS, PaperTrailsTranslationDetails } })
  export default class PaperTrailsDetails extends BaseDetails {
    loading = true;

    get detailsComponentName(): Nullable<string> {
      return DETAILS_COMPONENTS_MAPPING[this.type] || 'WithLinksDetails';
    }

    get detailsProps(): { links: DetailsLink[]; mainText: string; subText?: string } {
      return {
        links: this.links,
        mainText: this.mainText,
        subText: this.subText,
      };
    }

    get isTranslation(): boolean {
      return isTranslation(this.version);
    }
  }
