import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import { UserService } from './user-service';
import type { UserOrganization } from '@app/models/user-organization';

export class UserOrganizationService extends BaseService<UserOrganization> {
  static readonly allowedAttributes = ['organization_id'];
  static attributeNames($t: (key: string) => string): Partial<Record<keyof UserOrganization, string>> {
    return {
      organization_id: $t('app.labels.organization'),
      user_id: $t('app.labels.user'),
    };
  }
  static readonly attributeServices: Partial<Record<keyof UserOrganization, string>> = {
    organization_id: 'Organization',
  };

  async fetchDetails({ itemId, version }: ServiceRequest<UserOrganization>): Promise<DetailsServiceResponse> {
    const userId = this.extractValue<number>('user_id', version);
    if (!userId) return {};

    const userService = new UserService(this.config);
    const userDetails = await userService.fetchDetailsById(userId);
    return { ...userDetails, subText: `ID: ${itemId}` };
  }
}
