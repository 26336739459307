
  import { SimpleGridTableElement } from '@app/components/simple-grid-table/simple-grid-table-element';
  import type { BaseEntity } from '@app/models/base-entity';
  import { Component } from 'vue-property-decorator';
  import BaseTablePagination from '../base-table/base-table-pagination.vue';

  @Component({ components: { BaseTablePagination } })
  export default class SimpleGridTableFooter<T extends BaseEntity<number | string>> extends SimpleGridTableElement<T> {
    get showPagination() {
      return this.manager?.pagination?.total > 0;
    }
  }
