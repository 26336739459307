import type { ConfiguratorFilter } from '@app/models/configurator-filter';
import { ConfiguratorFilterSource } from '@app/models/configurator-filter';
import type { VisibilityStateUpdate } from './types/visibility-state';

export const excludeHiddenFilters = (filters: ConfiguratorFilter[], visibilityStates: VisibilityStateUpdate): ConfiguratorFilter[] => {
  const hiddenIds = visibilityStates.filter((state) => !state.state).map((state) => `${state.id}`);
  const hiddenCodes = visibilityStates.filter((state) => !state.state).map((state) => state.code);
  return filters.filter((f) => {
    switch (f.source) {
      case ConfiguratorFilterSource.question_id:
        return !hiddenIds.includes(f.value as string);
      case ConfiguratorFilterSource.question_code:
        return visibilityStates.some((s) => s.state && f.value === s.code) || !hiddenCodes.includes(f.value as string);
      default:
        return true;
    }
  });
};
