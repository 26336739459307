import type { BaseEntity } from '@app/models/base-entity';
import type { DetailsLink } from '../models';
import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { IndexFilter } from '@app/models/index-filter';

type IndexFilterWithModuleId = IndexFilter<BaseEntity, { module_name_id: string }>;

export class IndexFilterService extends BaseService<IndexFilter> {
  async fetchDetails({ itemId }: ServiceRequest<IndexFilterWithModuleId>): Promise<DetailsServiceResponse> {
    const { data: indexFilter } = await this.api.getIndexFilter(itemId, { only: ['id', 'index', 'filter'] }, { cache: true, join: true });

    const moduleNameId = this.getModuleID(indexFilter as IndexFilterWithModuleId);

    let link: DetailsLink = {
      prefix: this.getPrefix(indexFilter.index),
      title: this.getTitle(indexFilter.index),
      id: indexFilter.id,
    };

    if (moduleNameId) {
      link = {
        ...link,
        link: this.getLink(moduleNameId),
      };
    }

    return { links: [link] };
  }

  getPrefix(index: string): string {
    if (index.startsWith('actions_')) {
      return this.t('app.labels.action_filters');
    } else if (index.startsWith('module_records')) {
      return this.t('app.labels.module_record_default_filters');
    } else {
      return index;
    }
  }

  getTitle(index: string): string {
    if (index.startsWith('actions_') || index.startsWith('module_records')) {
      return this.t('app.labels.visual_options');
    } else {
      return '';
    }
  }

  getLink(moduleNameId: string): string {
    if (moduleNameId) {
      return `/admin/settings/module_names/${moduleNameId}/show_options`;
    } else {
      return '';
    }
  }

  getModuleID(indexFilter: IndexFilterWithModuleId): string | null {
    const actions_prefix = 'actions_';
    const module_record_prefix = 'module_records';

    if (indexFilter.index.startsWith(actions_prefix)) {
      return indexFilter.index.slice(actions_prefix.length);
    } else if (indexFilter.index.startsWith(module_record_prefix)) {
      return indexFilter.filter.module_name_id;
    } else {
      return null;
    }
  }
}
