
  import { Component, Prop } from 'vue-property-decorator';
  import type { VersionTime } from '../models';
  import BaseResponse from './base-response';
  import TextHighlight from 'vue-text-highlight';

  @Component({ components: { TextHighlight } })
  export default class DateTimeResponse extends BaseResponse {
    @Prop(Object) readonly raw?: VersionTime;
  }
