import type { ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { Signature } from '@app/models/signature';

export class SignatureService extends BaseService<Signature> {
  static readonly allowedAttributes = ['id', 'user_id'];
  static attributeNames($t: (key: string) => string): Partial<Record<keyof Signature, string>> {
    return {
      id: $t('app.labels.signature'),
      user_id: $t('app.labels.user'),
    };
  }
  static readonly attributeServices: Partial<Record<keyof Signature, string>> = {
    user_id: 'User',
  };

  async fetchEntity({ itemId }: ServiceRequest<Signature>): Promise<Signature> {
    const { data } = await this.api.getSignature(
      itemId,
      { only: ['id', 'url', 'user_id', { user: ['id', 'full_name'] }] },
      { cache: true }
    );
    return data;
  }

  entityToText({}: ServiceRequest<Signature>, entity?: Signature): string {
    const signatureUser = entity?.user;
    if (!signatureUser) {
      return '';
    }

    return `${this.t('app.labels.signed_by')}: ${signatureUser.full_name} [${signatureUser.id}]`;
  }
}
