import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import { NotificationService } from './notification-service';
import type { NotificationUser } from '@app/models/notification-user';
import type { PaperTrailChange } from '@app/models/paper-trail-version';

export class NotificationUserService extends BaseService<NotificationUser> {
  static readonly allowedAttributes = ['id'];
  static attributeNames($t: (key: string) => string): Partial<Record<keyof NotificationUser, string>> {
    return {
      id: $t('app.labels.enabled'),
    };
  }
  static readonly attributeServices: Partial<Record<keyof NotificationUser, string>> = {
    notification_id: 'Notification',
  };

  async fetchDetails({ version }: ServiceRequest<NotificationUser>): Promise<DetailsServiceResponse> {
    const itemId = this.extractValue<number>('notification_id', version);
    if (!itemId) throw Error('Required notification_id was missed');

    const notification = await NotificationService.fetchEntity(this.api, itemId);
    return {
      links: [
        {
          prefix: this.t('app.labels.notification.notification'),
          title: notification.name,
          id: notification.id,
        },
      ],
    };
  }

  normalizeValue(change: Nullable<PaperTrailChange>, key: keyof NotificationUser): Nullable<PaperTrailChange> {
    if (key === 'id') {
      return change === null ? 'false' : `${!!change}`;
    }
    return super.normalizeValue(change, key);
  }
}
