
  import { SimpleGridTableElement } from './simple-grid-table-element';
  import type { BaseEntity } from '@app/models/base-entity';
  import { isFunction } from 'lodash';
  import { Component } from 'vue-property-decorator';
  import BaseTableFieldCheckbox from '../base-table/base-table-field-checkbox.vue';
  import SortingHelper from '../base-table/sorting-helper';
  import type { ListManagerField } from '@app/services/list-manager/types';
  import dynamicWrap from './dynamic-wrap';

  @Component({ components: { BaseTableFieldCheckbox }, directives: { dynamicWrap } })
  export default class SimpleGridTableHeader<T extends BaseEntity<number | string>> extends SimpleGridTableElement<T> {
    sortingHelper!: SortingHelper;

    css = {
      ascendingIcon: 'fa fa-caret-up',
      descendingIcon: 'fa fa-caret-down',
    };

    getAriaSort(field: ListManagerField, i: number): Maybe<'ascending' | 'descending'> {
      return this.sortOrder?.[i]?.direction === 'asc' ? 'ascending' : 'descending';
    }

    fieldTitle(field: ListManagerField): string | undefined {
      if (typeof field.title === 'function') return field.title();
      return field.title;
    }

    isFieldComponent(name: string | typeof Component): boolean {
      return name instanceof Object;
    }

    isFilterable(field: ListManagerField): boolean {
      if (field.filter) {
        if (isFunction(field.filter)) {
          return field.filter();
        }
        return true;
      } else {
        return false;
      }
    }

    headerClass(field: ListManagerField): (string | object)[] {
      return [...(field.titleClass ? [field.titleClass] : []), ...(field.sticky ? ['column-sticky'] : [])];
    }

    onColumnHeaderClicked(field: ListManagerField, event: MouseEvent): void {
      this.orderBy(field, event);
    }

    orderBy(field: ListManagerField, event: MouseEvent) {
      if (!this.sortingHelper.isFieldSortable(field)) return;
      this.singleColumnSort(field);
      this.manager.page = 1; // reset page index
      if ('fetchDataFunction' in this.manager) {
        this.manager.fetchData(this.sortOrder || [], { current_page: 1, per_page: this.manager.per_page || -1 });
      } else if ('localData' in this.manager) {
        this.manager.refresh();
      }
    }

    singleColumnSort(field: ListManagerField) {
      if (this.manager.sortOrder?.length === 0) {
        this.clearSortOrder();
      }
      this.manager.sortOrder?.splice(1); //removes additional columns
      if (this.sortingHelper.fieldIsInSortOrderPosition(field, 0, this.manager.sortOrder)) {
        // change sort direction
        if (this.manager.sortOrder?.[0]) {
          this.manager.sortOrder[0].direction = this.manager.sortOrder[0].direction === 'asc' ? 'desc' : 'asc';
        }
      } else {
        // reset sort direction
        if (this.manager.sortOrder?.[0]) this.manager.sortOrder[0].direction = 'asc';
      }
      if (this.manager.sortOrder?.[0] && field.sortField) {
        this.manager.sortOrder[0].field = field.name;
        this.manager.sortOrder[0].sortField = field.sortField;
      }
    }

    clearSortOrder() {
      this.manager.sortOrder?.push({
        field: '',
        sortField: '',
        direction: 'asc',
      });
    }

    beforeMount(): void {
      this.sortingHelper = new SortingHelper();
    }
  }
