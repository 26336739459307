
  import { Component } from 'vue-property-decorator';
  import TextHighlight from 'vue-text-highlight';
  import BaseResponse from './base-response';
  import type { Role } from '@app/models/role';

  @Component({ components: { TextHighlight } })
  export default class RoleResponse extends BaseResponse<Role> {
    get link(): Nullable<string> {
      if (this.currentUserStore.hasRolesTagsPermission) {
        return `/admin/settings/roles/${this.entity?.id}/edit`;
      }

      return null;
    }
  }
