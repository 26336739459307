import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { Notification } from '@app/models/notification';
import type { DonesafeApi } from '@app/services/donesafe-api';

export class NotificationService extends BaseService<Notification> {
  async fetchEntity({ itemId }: ServiceRequest<Notification>): Promise<Notification> {
    return NotificationService.fetchEntity(this.api, itemId);
  }

  async fetchDetails(itemId: ServiceRequest<Notification>): Promise<DetailsServiceResponse> {
    const notification = await this.fetchEntity(itemId);
    return {
      links: [
        {
          link: `/admin/settings/notifications/${notification.id}`,
          prefix: this.t('app.labels.notification.notification'),
          title: notification.name,
          id: notification.id,
        },
      ],
    };
  }

  static async fetchEntity(api: DonesafeApi, itemId: Notification['id']): Promise<Notification> {
    const { data } = await api.getNotification(itemId, { only: ['id', 'name'] }, { join: true, cache: true });
    return data;
  }

  entityToText({}: ServiceRequest<Notification>, entity?: Notification): string {
    return entity ? `${entity.name} [${entity.id}]` : '';
  }
}
