
  import { debounceTime, filter } from 'rxjs/operators';
  import { Component } from 'vue-property-decorator';
  import BaseField from './base-field';
  import type { TimesheetSummaryFieldValue } from '@app/models/question-response-types';
  import type { Dictionary } from '@app/models/dictionary';
  import type { FieldType } from '@app/models/sub-form-question';
  import type { HourType } from '@app/models/hour-type';
  import type { Involvement } from '@app/models/involvement';
  import type { UserInvolvement } from '@app/models/user-involvement';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';

  interface SummaryData {
    normal_hours?: number;
    other_hours?: Dictionary<number>;
  }

  @Component({ components: {} })
  export default class TimesheetSummaryField extends BaseField<FieldType.timesheet_summary> {
    userInvolvement: Pick<UserInvolvement, 'id' | 'user_id'> | null = null;
    involvement: Involvement | null = null;
    localValue: Partial<TimesheetSummaryFieldValue> = {};
    hourTypes: HourType[] = [];

    get defaultLocalValue(): Partial<TimesheetSummaryFieldValue> {
      return this.hourTypes.reduce(
        (memo, type) => {
          return {
            ...memo,
            [`${type.id}_hours`]: 0,
            [`${type.id}_pay`]: 0,
          };
        },
        {
          total_pay: 0,
          total_hours: 0,
          normal_hours: 0,
          normal_pay: 0,
        }
      );
    }

    get hasResponse(): boolean {
      return !!this.response?.id;
    }

    get overrideHours(): boolean {
      return ['hours', 'hours_and_pay'].indexOf(this.question.config.allow_overrides) > -1;
    }

    get overridePay(): boolean {
      return ['pay', 'hours_and_pay'].indexOf(this.question.config.allow_overrides) > -1;
    }

    get shouldCalculatePay(): boolean {
      return this.question.config.calculate_pay === 'true';
    }

    fromDateValue(): string {
      return $(`[name="response_by_code[${this.question.config.date_from_question_code}]"]`).val() as string;
    }

    toDateValue(): string {
      return $(`[name="response_by_code[${this.question.config.date_to_question_code}]"]`).val() as string;
    }

    getRateValue(): number {
      // TODO: refactor this later
      const rate = ($(`[name="response_by_code[${this.question.config.hourly_rate_question_code}]"]`).val() as number) || 0;
      if (!rate || isNaN(rate)) {
        return (this.getAvgHourlyRatePayCalculatorField().val() as number) || 0;
      }
      return rate;
    }

    onHoursChange(): void {
      this.calculateTotalHours();
      this.calculatePay();

      this.updateValue();
    }

    onPayChange(): void {
      // this.calculateTotalHours();
      this.calculateTotalPay();
      this.updateValue();
    }

    calculateTotalHours(): void {
      let totalHours = 0;
      totalHours += parseFloat(`${this.localValue.normal_hours || 0}`);
      this.hourTypes.forEach((hourType) => {
        if (hourType.inclusivity_type === 'add') {
          totalHours += parseFloat(`${this.localValue[`${hourType.id}_hours`] || 0}`);
        } else if (hourType.inclusivity_type === 'subtract') {
          totalHours -= parseFloat(`${this.localValue[`${hourType.id}_hours`] || 0}`);
        }
      });
      this.localValue.total_hours = totalHours;

      this.localValue = { ...this.localValue };
    }

    calculateTotalPay(): void {
      let totalPay = 0;
      totalPay += parseFloat(`${this.localValue.normal_pay || 0}`);
      this.hourTypes.forEach((hourType) => {
        totalPay += parseFloat(`${this.localValue[`${hourType.id}_pay`] || 0}`);
      });
      this.localValue.total_pay = totalPay;
      this.localValue = { ...this.localValue };
    }

    calculatePay(): void {
      const normalHours = `${this.localValue.normal_hours || 0}`;
      const rate = this.getRateValue();
      this.localValue.normal_pay = parseFloat(normalHours) * rate;
      this.hourTypes.forEach((hourType) => {
        const hours = `${this.localValue[`${hourType.id}_hours`] || 0}`;
        let value = 0;
        const percentageString = hourType.percentage.toString();
        switch (hourType.inclusivity_type) {
          case 'add':
            value = (parseFloat(hours) * rate * parseFloat(percentageString)) / 100;
            break;
          case 'include_hours':
            value = (parseFloat(hours) * rate * parseFloat(`${hourType.percentage - 100}`)) / 100;
            break;
          case 'subtract':
            value = -((parseFloat(hours) * rate * parseFloat(percentageString)) / 100);
            break;
        }
        this.localValue[`${hourType.id}_pay`] = value;
      });

      this.calculateTotalPay();
    }

    fetchHours(): void {
      if (this.userInvolvement && this.toDateValue() && this.fromDateValue()) {
        // TODO: move to utils endpoint
        $.ajax({
          url: '/timesheet_shifts/summary',
          data: {
            user_id: this.userInvolvement?.user_id,
            from: this.fromDateValue(),
            to: this.toDateValue(),
          },
        }).done((response: SummaryData) => {
          this.localValue.normal_hours = response.normal_hours || 0;
          if (response.other_hours) {
            Object.keys(response.other_hours).forEach((id) => {
              this.localValue[`${id}_hours`] = response.other_hours ? response.other_hours[id] : 0;
            });
          }
          this.calculatePay();
          this.calculateTotalHours();

          this.updateValue();
        });
      }
    }

    updateValue(): void {
      this.sendUpdate(this.localValue as TimesheetSummaryFieldValue);
      this.$emit('input', this.localValue);
    }

    getAvgHourlyRatePayCalculatorField(): JQuery {
      return $(`.pay-detail-avg-hourly-rate[data-question-code="${this.question.config.hourly_rate_question_code}"]`);
    }

    mounted(): void {
      this.$nextTick(() => {
        this.getAvgHourlyRatePayCalculatorField().on(`change.${this.question.id}`, () => {
          this.calculatePay();
        });
      });
      this.addSubscription(
        this.formObservers.fieldUpdate$
          .pipe(
            filter(([question, , initial]) => {
              return (
                !initial &&
                (question.code === this.question.config.date_from_question_code ||
                  question.code === this.question.config.date_to_question_code ||
                  question.code === this.question.config.hourly_rate_question_code)
              );
            }),
            debounceTime(250)
          )
          .subscribe(() => {
            this.fetchHours();
          })
      );
      this.sendUpdate(this.localValue as TimesheetSummaryFieldValue, true);
    }

    beforeDestroy(): void {
      this.onBeforeDestroy();
      this.getAvgHourlyRatePayCalculatorField().off(`change.${this.question.id}`);
    }

    beforeMount(): void {
      this.localValue = { ...this.value };
      const hourTypeIds = Object.keys(this.localValue)
        .map((key) => {
          const match = key?.match(/([1-9]+)_hours/);
          if (match) {
            return match[1];
          }
          return false;
        })
        .filter(Boolean) as string[];

      const filters: DonesafeFilterOptions<HourType> = { active: true };
      if (hourTypeIds.length) {
        filters.id = hourTypeIds;
      }

      this.$api.getHourTypes({ filters, sort: 'created_at' }, { cache: true }).then(({ data }) => {
        this.hourTypes = data;
        this.localValue = { ...this.defaultLocalValue, ...this.localValue };
      });
      if (this.record) {
        this.$api
          .getUserInvolvements(
            {
              filters: {
                record_id: this.record.id,
                record_type: this.recordType,
                involvement_id: this.question.config.involvement_id,
              },
              only: ['id', 'user_id'],
            },
            { cache: true }
          )
          .then(({ data }) => {
            if (data[0]) {
              this.userInvolvement = data[0];
            }
            this.$api.getInvolvement(this.question.config.involvement_id).then(({ data }) => {
              this.involvement = data;
            });
            if (!this.hasResponse) {
              this.fetchHours();
            }
          });
      }
    }
  }
