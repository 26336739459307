
  import { isBoolean, isEmpty, isNumber } from 'lodash';
  import { Component, Prop } from 'vue-property-decorator';
  import TextHighlight from 'vue-text-highlight';
  import BaseResponse from './base-response';
  import type { PaperTrailChange } from '@app/models/paper-trail-version';

  @Component({ components: { TextHighlight } })
  export default class DirectResponse extends BaseResponse {
    @Prop() readonly raw?: PaperTrailChange;

    get text(): PaperTrailChange | undefined {
      return !isEmpty(this.raw) || isBoolean(this.raw) || isNumber(this.raw) || !!this.raw ? this.raw : this.entityText;
    }
  }
