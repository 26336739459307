
  import { filter } from 'rxjs/operators';
  import { Component, Ref } from 'vue-property-decorator';
  import { destroyVueComponent } from '@app/legacy/subform';
  import DsModal from '@app/components/ds-modal.vue';
  import { hasQuestionInFilter } from '@app/utils/has-question-in-filter';
  import { uniq, sortBy, get } from 'lodash';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import ModuleRecordModals from '@app/mixins/module-record-modals';
  import { mixins } from 'vue-class-component';
  import type { RecordRelationTempFieldValue } from '@app/models/question-response-types';
  import type { ModuleRecord } from '@app/models/module-record';
  import type { RecordRelation, RelationForeignKey } from '@app/models/record-relation';
  import type { Relationship } from '@app/models/relationship';
  import type { DefaultTemplate } from '@app/models/default-template';
  import type { ConfiguratorFilter } from '@app/models/configurator-filter';
  import type { FieldType } from '@app/models/sub-form-question';
  import { RecordRelationMode } from '@app/models/record-relation';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import type { ListManagerField } from '@app/services/list-manager/types';
  import { convertInFormFilters } from '@app/utils/convert-in-form-filters';
  import { buildLink } from '@app/utils/build-link';
  import { incompleteMandatoryFilters } from '@app/utils/mandatory-filters';

  import BaseTableCell from '../base-table/base-table-cell.vue';
  import RecordSelector from '../record-selector.vue';
  import FilterErrors from '../admin/questions/filter-configurator/filter-errors.vue';

  import BaseField from './base-field';

  type RelationModuleNameKey = 'from_module_name' | 'to_module_name';

  @Component
  class BaseRecordRelationField extends BaseField<FieldType.record_relation> {}

  @Component({
    name: 'RecordRelationField',
    components: {
      RecordSelector,
      DsModal,
      BaseTableCell,
      DsDropdown,
      FilterErrors,
      ModuleRecordsTable: () => import(/* webpackChunkName: "module-records-table" */ '../module-record/module-records-table.vue'),
    },
  })
  export default class RecordRelationField extends mixins(BaseRecordRelationField, ModuleRecordModals) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Ref() readonly remoteSelect!: any;

    defaultTemplate: Pick<DefaultTemplate, 'id' | 'system_code'> | null = null;
    localValue: RecordRelationTempFieldValue = {};
    recordRelationModalVisible = false;
    recordRelations: RecordRelation[] = [];
    relationship: Nullable<Relationship> = null;
    search = '';
    selectedRecordIds: string[] = [];

    get addNewDisabled(): boolean {
      return this.isModal;
    }

    get allowRemoveExisting(): boolean {
      return this.question.config.allow_remove_existing === 'true';
    }

    get allowRemoveProposed(): boolean {
      return this.question.config.allow_remove_proposed === 'true';
    }

    get allowSearch(): boolean {
      return this.question.config.allow_search === 'true';
    }

    get createIds(): number[] {
      return (this.localValue?.value || []).map((id) => parseInt(id as unknown as string)).filter((id) => !this.savedIds.includes(id));
    }

    get defaultTemplateSystemCode(): string | undefined {
      return this.defaultTemplate?.system_code;
    }

    get extraFields(): ListManagerField[] {
      return this.readonly ? [] : [{ title: '', name: 'remove_relation' }];
    }

    get foreignKey(): RelationForeignKey {
      return this.mode === RecordRelationMode.from ? 'from_id' : 'to_id';
    }

    get indexOptions(): string[] {
      return (
        (this.question.config.index_options?.length && this.question.config.index_options) || ['uniq_id', 'title', 'state', 'created_at']
      );
    }

    get linkFirstColumn(): boolean {
      return this.question.config?.link_first_column === 'true';
    }

    get mode(): RecordRelationMode {
      return this.question.config.relationship_mode || RecordRelationMode.from;
    }

    get presentIds(): number[] {
      return this.savedIds.concat(this.createIds).filter((id) => !this.removeIds.includes(id));
    }

    get relatedForeignKey(): RelationForeignKey {
      return this.mode === RecordRelationMode.from ? 'to_id' : 'from_id';
    }

    get relatedModuleNameKey(): RelationModuleNameKey {
      return this.mode === RecordRelationMode.from ? 'to_module_name' : 'from_module_name';
    }

    get relatedRecordIds(): number[] {
      return uniq(sortBy(this.savedIds.concat(this.createIds).concat(this.removeIds)));
    }

    get removeIds(): number[] {
      return (this.localValue?.remove || []).map((id) => parseInt(id as unknown as string));
    }

    get requiredSelectFilters(): DonesafeFilterOptions<ModuleRecord> {
      const moduleNameId = (this.relationship as Relationship)[this.relatedModuleNameKey]?.id;
      return { module_name_id: moduleNameId };
    }

    get reverse(): boolean {
      return this.question.config.sort ? !!this.question.config.reverse : true;
    }

    get savedIds(): number[] {
      return this.recordRelations.map((rr) => rr[this.relatedForeignKey]);
    }

    get showAddNew(): boolean {
      if (this.question.config.allow_add_new !== 'true') {
        return false;
      }
      const moduleName = get(this.relationship, [this.relatedModuleNameKey, 'name']);
      return !!(moduleName && get(this.currentUserStore.data?.acl, ['module', moduleName, 'create']));
    }

    get showPanelHeader(): boolean {
      return !!(this.question.config.panel_title || this.allowSearch || this.showAddNew || this.showSelectExisting);
    }

    get showSelectExisting(): boolean {
      return this.question.config.allow_select === 'true';
    }

    get sort(): string | undefined {
      return this.question.config.sort || this.indexOptions[0];
    }

    get tableFilters(): DonesafeFilterOptions<ModuleRecord, { _do_not_fetch?: boolean }> {
      if (!this.relationship || !this.relationship[this.relatedModuleNameKey]) {
        return {};
      }

      const baseFilter = {
        module_name_id: this.relationship[this.relatedModuleNameKey]?.id,
        ...this.getQuestionFilters(this.question.config.filters || []),
      };

      if (this.relatedRecordIds.length) {
        return { ...baseFilter, id: this.relatedRecordIds.join(',') };
      }

      return { ...baseFilter, _do_not_fetch: true };
    }

    addNewRelation(event: MouseEvent): void {
      if (!this.addNewDisabled && this.relationship && this.relationship[this.relatedModuleNameKey]) {
        this.openModalOrLink({
          link: buildLink('/module_records/new'),
          modal: true,
          event,
          modalProps: {
            mode: 'module-record-edit',
            moduleNameId: this.relationship[this.relatedModuleNameKey]?.id,
            mainFormId: this.record?.id,
            defaultTemplateId: this.defaultTemplateSystemCode,
            hideFooterLinks: true,
            title: this.moduleRecordEditTitle(this.relationship[this.relatedModuleNameKey]?.display),
            listeners: {
              'record-created': this.onRecordCreate,
            },
          },
        });
      }
    }

    canBeRemoved(id: number): boolean {
      if (this.createIds.indexOf(id) > -1) {
        return this.allowRemoveProposed;
      } else if (this.savedIds.indexOf(id) > -1) {
        return this.allowRemoveExisting;
      }
      return false;
    }

    cancelRemoval(moduleRecord: ModuleRecord): void {
      const newRemoveData = this.removeIds.filter((id) => id !== moduleRecord.id);
      this.updateValue({
        ...this.localValue,
        remove: newRemoveData,
      });
    }

    destroyModalVueComponents(): void {
      this.$refs.addModal &&
        $(this.$refs.addModal)
          .find('[data-behaviour="vue"]')
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .each(destroyVueComponent);
    }

    fetchRelations(): void {
      if (this.currentUserStore.data?.id && this.relationship && this.record?.id) {
        this.$api
          .getRecordRelations({
            filters: {
              relationship_code: this.relationship.code,
              [this.foreignKey]: this.record.id,
            },
            per_page: -1,
            only: ['id', 'from_id', 'to_id'],
          })
          .then(({ data }) => {
            this.recordRelations = data;
          });
      }
    }

    getQuestionFilters(filters: ConfiguratorFilter[]): DonesafeFilterOptions<ModuleRecord> {
      return convertInFormFilters(filters, {
        record: this.record,
        user: this.currentUserStore.data,
        getCurrentFormValueByQuestion: this.getCurrentFormValueByQuestion,
      });
    }

    idsToExclude(): number[] {
      const sameRecordId = this.record?.id ? [this.record.id] : [];
      return [...this.relatedRecordIds, ...sameRecordId];
    }

    onOpen(): boolean {
      !this.defaultTemplating &&
        this.setIncompleteFilters(
          incompleteMandatoryFilters(
            this.question.config.filters_select,
            this.getQuestionFilters(this.question.config.filters_select || [])
          )
        );
      return !this.incompleteFilters.length;
    }

    onRecordCreate(record: ModuleRecord): void {
      const newValue = {
        value: [...(this.localValue?.value || []), record.id],
        remove: [...(this.localValue?.remove || [])],
      };
      this.updateValue(newValue);
      this.fetchRelations();
    }

    prepareLocalValue(value: RecordRelationTempFieldValue): RecordRelationTempFieldValue {
      const localValue = Array.isArray(value) ? { value } : value || {};
      return {
        ...localValue,
        value: localValue.value ? (Array.isArray(localValue.value) ? localValue.value : [localValue.value]) : [],
      };
    }

    removeRelation(moduleRecord: ModuleRecord): void {
      if (this.readonly) {
        return;
      }
      const notSavedYet = this.createIds.find((id) => id === moduleRecord.id);
      if (notSavedYet) {
        const newCreateData = this.createIds.filter((id) => id !== moduleRecord.id);
        this.updateValue({
          ...this.localValue,
          value: newCreateData,
        });
      } else {
        const newRemoveData = [...this.removeIds, moduleRecord.id];
        this.updateValue({
          ...this.localValue,
          remove: newRemoveData,
        });
      }
    }

    rowClass(record: ModuleRecord): string {
      if (this.createIds.indexOf(record.id) > -1) {
        return 'row-create';
      } else if (this.removeIds.indexOf(record.id) > -1) {
        return 'row-remove';
      }
      return '';
    }

    selectFilters(): DonesafeFilterOptions<ModuleRecord> {
      return { ...this.getQuestionFilters(this.question.config.filters_select || []), '!id': this.idsToExclude().join(',') || undefined };
    }

    selectNewRelation(): void {
      this.recordRelationModalVisible = true;
    }

    submitForm(): void {
      if (this.relationship && !this.readonly) {
        this.updateValue({
          ...this.localValue,
          value: [...this.createIds, ...this.selectedRecordIds],
        });
        this.selectedRecordIds = [];
        this.recordRelationModalVisible = false;
      }
    }

    updateValue(newLocalData: RecordRelationTempFieldValue): void {
      this.localValue = newLocalData;
      this.$emit('input', this.localValue);
      this.sendUpdate(this.localValue);
    }

    beforeMount(): void {
      this.localValue = this.prepareLocalValue(this.value);
      if (this.question.config.default_template_id) {
        this.$api
          .getDefaultTemplates({ filters: { id: this.question.config.default_template_id }, only: ['id', 'system_code'] }, { cache: true })
          .then(({ data }) => {
            this.defaultTemplate = data[0] || null;
          });
      }
      if (this.question.config.relationship_code) {
        this.$api
          .getRelationships({
            filters: { code: this.question.config.relationship_code },
            only: [
              'id',
              'name',
              'code',
              'from_module',
              'to_module',
              { to_module_name: ['id', 'name', 'display'] },
              { from_module_name: ['id', 'name', 'display'] },
            ],
          })
          .then(({ data }) => {
            this.relationship = data[0];
            if (this.relationship) {
              const relatedModuleNameId = this.relationship[this.relatedModuleNameKey] && this.relationship[this.relatedModuleNameKey]?.id;
              const paramsRecordId = get(this.params, ['relationship', this.relationship.code]);
              if (relatedModuleNameId && paramsRecordId) {
                const relatedRecordId = parseInt(paramsRecordId);
                if (relatedRecordId && !this.localValue.value?.includes(relatedRecordId)) {
                  this.$api.getModuleRecord(relatedRecordId, { only: ['id', 'module_name_id'] }).then(({ data }) => {
                    if (data.module_name_id === relatedModuleNameId) {
                      // TODO: clear query param
                      this.updateValue({
                        ...this.localValue,
                        value: [...this.createIds, relatedRecordId],
                      });
                    }
                  });
                }
              }
            }
            this.fetchRelations();
          });
      }
    }

    mounted(): void {
      this.addSubscription(
        this.formObservers.fieldUpdate$
          .pipe(filter(([question]) => hasQuestionInFilter(question, this.question.config.filters_select)))
          .subscribe(() => {
            this.clearIncompleteFilters();
          })
      );
    }

    beforeDestroy(): void {
      this.onBeforeDestroy();
      this.destroyModalVueComponents();
    }
  }
