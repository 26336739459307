
  import { Component, Model, Prop, Ref, Vue } from 'vue-property-decorator';
  import type { INode } from 'svgson';
  import { parseSync, stringify } from 'svgson';
  import { v4 as generateUUID } from 'uuid';

  // This function is needed to typescript won't yell at us
  function mkNode(args: Partial<INode>): INode {
    return Object.assign(
      {} as INode,
      {
        name: '',
        type: '',
        attributes: {},
        value: '',
        children: [],
      },
      args
    );
  }

  @Component
  export default class SvgInteractiveTemplate extends Vue {
    @Prop(String) readonly content!: string;
    @Prop({ type: String, default: '#cccccc' }) readonly defaultColor?: string;
    @Prop(Object) readonly colors?: Record<string, string>;
    @Model('input') readonly value?: string;
    @Prop(Set) readonly selectable?: Set<string>;
    @Prop(Boolean) readonly readonly?: boolean;

    @Ref() readonly container?: HTMLDivElement;

    stylePrefixClass = `prefix-${generateUUID()}`;

    get svgContent(): string {
      const rawSvg = parseSync(this.content);
      const svg = this.postProcessSvg(rawSvg);
      if (!svg) {
        return '';
      }

      return stringify({
        ...svg,
        attributes: {
          ...svg.attributes,
          style: 'width: 100%; height: auto',
        },
        children: [this.buildSvgStyle(), this.buildFilters(), ...svg.children],
      });
    }

    postProcessSvg(node: INode): INode | null {
      if (['style', 'script'].includes(node.name)) {
        return null;
      }

      if (this.selectable && 'data-selectable' in node.attributes && !this.selectable.has(node.attributes.id)) {
        node.attributes['data-selectable'] = 'false';
      }

      if (node?.name === 'path' && node?.attributes?.['aria-label']) {
        node.attributes = { ...node.attributes, role: 'graphics-symbol' };
      }

      if (node.children) {
        node.children = node.children.map((child) => this.postProcessSvg(child)).filter(Boolean) as INode[];
      }
      return node;
    }

    onComponentMouseOver(event: Event): void {
      this.emitComponentEvent('component-over', event);
    }

    onComponentMouseOut(event: Event): void {
      this.emitComponentEvent('component-out', event);
    }

    onComponentClick(event: Event): void {
      if (this.readonly) {
        return;
      }

      const component = this.selectableOrParent(event.target as SVGElement);
      if (component) {
        this.$emit('component-click', { event, id: component.id });
        if (component.id === this.value) {
          this.$emit('input', null);
        } else {
          this.$emit('input', component.id);
        }
      }
    }

    emitComponentEvent(eventName: string, sourceEvent: Event): void {
      if (this.readonly) {
        return;
      }
      const component = this.selectableOrParent(sourceEvent.target as SVGElement);
      if (component) {
        this.$emit(eventName, component.id);
      }
    }

    selectableOrParent(component?: SVGElement): SVGElement | undefined {
      if (component) {
        return component.getAttribute('data-selectable') === 'true' && component.id
          ? component
          : this.selectableOrParent(component.parentElement as unknown as SVGElement);
      }
    }

    buildFilters(): INode {
      return mkNode({
        name: 'defs',
        type: 'tag',
        children: [
          mkNode({
            name: 'filter',
            type: 'tag',
            attributes: {
              id: 'component-hover-filter',
              filterUnits: 'userSpaceOnUse',
            },
            children: [
              mkNode({
                name: 'feBlend',
                type: 'tag',
                attributes: {
                  mode: 'multiply',
                  x: '0%',
                  y: '0%',
                  width: '100%',
                  height: '100%',
                  in: 'SourceGraphic',
                  in2: 'SourceGraphic',
                  result: 'blend',
                },
              }),
            ],
          }),
        ],
      });
    }

    // We can use CSS Variables instead of this approach when IE11 support is dropped
    buildSvgStyle(): INode {
      const prefix = `.${this.stylePrefixClass}`;
      const styles = [
        `${prefix} [data-selectable] {
        fill: ${this.defaultColor};
        transition: filter 0.3 ease;
      }`,
      ];

      if (!this.readonly) {
        styles.push(`${prefix} [data-selectable="true"]:hover { filter: url(#component-hover-filter) }`);
        styles.push(`${prefix} [data-selectable="true"] { cursor: pointer; }`);
      }

      if (this.colors) {
        Object.entries(this.colors).forEach(([id, color]) => {
          styles.push(`${prefix} #${id} { fill: ${color}; }`);
        });
      }

      if (this.value) {
        styles.push(`${prefix} #${this.value} { filter: url(#component-hover-filter) }`);
      }

      return mkNode({
        name: 'style',
        type: 'tag',
        children: [
          mkNode({
            type: 'text',
            value: styles.join('\n'),
          }),
        ],
      });
    }
  }
