import type { ModuleRecord } from '@app/models/module-record';
import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';

export class ModuleRecordService extends BaseService<ModuleRecord> {
  async fetchEntity({ itemId }: ServiceRequest<ModuleRecord>): Promise<Partial<ModuleRecord>> {
    return await this.fetchModuleRecord(itemId);
  }

  entityToText({}: ServiceRequest<ModuleRecord>, entity?: ModuleRecord): string {
    return entity ? ModuleRecordService.toText(entity) : '';
  }

  async fetchDetails({ itemId }: ServiceRequest<ModuleRecord>): Promise<DetailsServiceResponse> {
    const record = await this.fetchModuleRecord(itemId);
    return {
      links: [
        {
          link: `/module_records/${record.id}`,
          prefix: this.t('app.labels.record'),
          title: record.title,
          id: record.id,
        },
      ],
    };
  }

  async fetchModuleRecord(itemId: number | string): Promise<Pick<ModuleRecord, 'id' | 'title'>> {
    try {
      const { data: record } = await this.api.getModuleRecord(
        Number(itemId),
        { only: ['id', 'title'] },
        {
          cache: true,
          join: true,
        }
      );
      return record;
    } catch {
      return { id: itemId as number, title: this.t('app.labels.not_accessible') as string };
    }
  }

  static toText(moduleRecord: ModuleRecord): string {
    return `${moduleRecord.title} [${moduleRecord.id}]`;
  }
}
