
  import BaseFieldDisplay from './base-field-display';
  import SvgInteractiveTemplate from '@app/components/svg-template/svg-interactive-template.vue';
  import { Component } from 'vue-property-decorator';
  import { isEmpty } from 'lodash';
  import type { FieldType } from '@app/models/sub-form-question';
  import type { SvgLibraryTemplate } from '@app/models/svg-library/template';

  @Component({ components: { SvgInteractiveTemplate } })
  export default class SvgSelectorDisplay extends BaseFieldDisplay<FieldType.svg_selector> {
    svgContent = '';
    template: Nullable<SvgLibraryTemplate> = null;

    get hasResponse(): boolean {
      return !isEmpty(this.response);
    }

    get singularCategory(): boolean {
      return this.question.config.categories.length < 2;
    }

    get summary(): string {
      return this.response
        .map((v) => {
          const component = this.template?.components?.find((c) => c.code === v.value);
          const category = this.question.config.categories.find((cat) => cat.code === v.category);
          return [component?.label, category?.title].filter(Boolean).join(': ');
        })
        .join(', ');
    }

    beforeMount(): void {
      this.$api
        .getSvgTemplate(this.question.config.svg_template_id, { include: ['components'] }, { cache: true, join: true })
        .then(({ data }) => {
          this.template = data;
        });
      this.$api.getSvgTemplateFile(this.question.config.svg_template_id, { cache: true }).then(({ data }) => {
        this.svgContent = data;
      });
    }

    get componentColors(): Record<string, string> {
      const { config } = this.question;

      return this.response.reduce<Record<string, string>>((memo, response) => {
        const components = this.template?.components?.filter((c) => c.code === response.value);
        const color = config.categories.find((r) => r.code === response.category)?.color || config.categories[0]?.color;
        const result = components?.reduce((m, c) => ({ ...m, [c.path]: color }), {});

        return { ...memo, ...result };
      }, {});
    }
  }
