import moment from 'moment-timezone';
import type { StringOption } from './models';
import type { FrequencyData } from '@app/models/recurring/frequency-data';
import type { RecurringSetup } from '@app/models/recurring/recurring-setup';
import { Frequency } from '@app/models/recurring/recurring-setup';

const MAP_TO_FREQUENCY: { [key: string]: Frequency } = {
  hourly: Frequency.hourly,
  daily: Frequency.daily,
  weekly: Frequency.weekly,
  monthly_day_of_week: Frequency.monthlyDayOfWeek,
  monthly_day_of_month: Frequency.monthlyDayOfMonth,
};

export const parseFrequency = (rawValue: string): Frequency => {
  return MAP_TO_FREQUENCY[rawValue] || Frequency.daily;
};

export const parseFrequencyData = (data?: FrequencyData): FrequencyData => {
  return (
    data || {
      every: 1,
      dayOfWeek: {
        first: undefined,
        second: undefined,
        third: undefined,
        last: undefined,
      },
      weeks: undefined,
      dayOfMonths: undefined,
    }
  );
};

const defaultStartAt = (defaultHour: number, timezoneIdentifier = 'UTC'): Date => {
  return moment().tz(timezoneIdentifier).set({ h: defaultHour, m: 0, s: 0 }).toDate();
};

// TODO: pass timezone identifier into this method
export const buildRecurringSetup = (
  frequency: Frequency,
  frequency_data: FrequencyData,
  options: {
    default_hour?: number;
    enabled?: boolean;
    end_date?: Date | string;
    id?: number;
    start_date?: Date | string;
  } = {}
): Partial<RecurringSetup> => {
  const start_at = options.start_date
    ? moment(options.start_date).toDate()
    : defaultStartAt(options.default_hour || 0, window.DONESAFE.account?.timezone_identifier || 'UTC');
  start_at.setSeconds(0, 0);
  return {
    id: options.id,
    frequency,
    frequency_data,
    enabled: !!options.enabled,
    start_at,
    end_at: options.end_date ? moment(options.end_date).toDate() : undefined,
  };
};

export const localizedStringOptions = (values: string): StringOption[] => {
  const result: StringOption[] = [];
  for (let i = 0; i < values.length; i++) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [key, value] = Object.entries((values as any)[i])[0];
    result.push({ code: key, label: value as string });
  }
  return result;
};

export const formattedDate = (date: Date): string => {
  if (!date) {
    return '';
  }

  return moment(date).toISOString();
};
