import type { RecordIndicator } from '@app/models/record-indicator';
import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';

export class RecordIndicatorService extends BaseService<RecordIndicator> {
  async fetchDetails({ version }: ServiceRequest<RecordIndicator>): Promise<DetailsServiceResponse> {
    const indicatorId = this.extractValue('indicator_id', version);
    if (!indicatorId) throw Error('Required indicator_id was missed');

    const { data: indicator } = await this.api.getIndicator(
      indicatorId,
      { only: ['id', 'name', { indicator_set: ['name', 'id'] }] },
      { join: true, cache: true }
    );
    let mainText = '';
    if (indicator.indicator_set) {
      const indicatorSet = indicator.indicator_set;
      mainText = `${this.t('app.labels.indicator_set')}: ${indicatorSet.name} [${indicatorSet.id}]`;
    }
    return {
      links: [],
      mainText,
    };
  }
}
