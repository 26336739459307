import {
  FileUploadFieldResponse,
  MatrixFieldResponse,
  MultiRecordFieldResponse,
  MultiSelectFieldResponse,
  MultiUserFieldResponse,
  ReportFieldResponse,
  SignatureFieldResponse,
  SimpleFieldResponse,
  SingleSelectFieldResponse,
  SubFormRelationFieldResponse,
  SvgSelectorFieldResponse,
  TextFieldResponse,
  WorkflowStateFieldResponse,
} from './index';

export const FIELD_RESPONSE_COMPONENTS = {
  FileUploadFieldResponse,
  MatrixFieldResponse,
  MultiSelectFieldResponse,
  MultiRecordFieldResponse,
  MultiUserFieldResponse,
  ReportFieldResponse,
  SignatureFieldResponse,
  SimpleFieldResponse,
  SingleSelectFieldResponse,
  SubFormRelationFieldResponse,
  SvgSelectorFieldResponse,
  TextFieldResponse,
  WorkflowStateFieldResponse,
};
