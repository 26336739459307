import type { DetailsServiceResponse, ServiceRequest } from '@app/components/paper-trails/services/base-service';
import { BaseService } from '@app/components/paper-trails/services/base-service';
import type { UserCollection } from '@app/models/user-collection';

export class UserCollectionService extends BaseService<UserCollection> {
  static serviceTitle = ($t: (key: string) => string): string => $t('app.labels.user_collection');
  static readonly allowedAttributes: (keyof UserCollection)[] = ['active', 'name', 'rule_set_id'];

  async fetchDetails(itemId: ServiceRequest<UserCollection>): Promise<DetailsServiceResponse> {
    const userCollection = await this.fetchEntity(itemId);
    return {
      mainText: `${userCollection.name || ''}`,
    };
  }

  async fetchEntity({ itemId }: ServiceRequest<UserCollection>): Promise<UserCollection> {
    const { data: userCollection } = await this.api.getUserCollection(itemId);
    return userCollection;
  }
}
