import moment from 'moment';
import { ResponseType } from '../models';
import { versionTime } from '../utils';
import { BaseService } from './base-service';
import type { PaperTrailChange } from '@app/models/paper-trail-version';
import type { RecurringSetup } from '@app/models/recurring/recurring-setup';

const DATE_TIME_ATTRIBUTES = ['end_at', 'start_at'];

export class RecurringSetupService extends BaseService<RecurringSetup> {
  static serviceTitle = ($t: (key: string) => string): string => $t('app.labels.recurring_setup');
  static readonly allowedAttributes = ['enabled', 'end_at', 'frequency', 'frequency_data', 'recurrable_id', 'start_at'];
  static readonly attributeServices: Partial<Record<keyof RecurringSetup, string>> = { recurrable_id: 'Recurrable' };

  normalizeText(change: Nullable<PaperTrailChange>, key: keyof RecurringSetup): Nullable<string> {
    if (change) {
      if (DATE_TIME_ATTRIBUTES.includes(key)) {
        return moment(change).format(this.account?.datetimepicker_datetime_format);
      }
    }
    return super.normalizeText(change, key);
  }

  normalizeValue(change: Nullable<PaperTrailChange>, key: keyof RecurringSetup): Nullable<PaperTrailChange> {
    if (change) {
      if (DATE_TIME_ATTRIBUTES.includes(key)) {
        return versionTime(this.account, change as string);
      }
    }
    return super.normalizeValue(change, key);
  }

  responseType(key: keyof RecurringSetup): ResponseType {
    if (DATE_TIME_ATTRIBUTES.includes(key)) return ResponseType.DateTime;

    return super.responseType(key);
  }
}
