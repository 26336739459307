import { ResponseType } from '../models';
import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import { EventService, SCHEDULED_DETAILS_INCLUDE, SCHEDULED_DETAILS_ONLY } from './event-service';
import moment from 'moment';
import type { PaperTrailChange } from '@app/models/paper-trail-version';
import type { Schedule } from '@app/models/schedule';
import type { TenantUser } from '@app/models/tenant-user';

export class ScheduleService extends BaseService<Schedule> {
  async fetchDetails({ itemId }: ServiceRequest<Schedule>): Promise<DetailsServiceResponse> {
    const { data: scheduledEvents } = await this.api.getScheduledEventsWithSharedFilters(
      {
        only: SCHEDULED_DETAILS_ONLY,
        include: SCHEDULED_DETAILS_INCLUDE,
      },
      'schedule_id',
      itemId,
      { cache: true }
    );
    const { data: users } = await this.api.getTenantUsersWithSharedFilters(
      { include: ['secondary_information'], only: ['id', 'full_name', 'secondary_information', 'schedule_id'] },
      'schedule_id',
      itemId,
      { cache: true }
    );
    const scheduledEvent = scheduledEvents[0];
    const user = users[0];
    if (!scheduledEvent && !user) return {};

    if (scheduledEvent) {
      const mainText = EventService.mainText(this, scheduledEvent);
      return {
        links: [
          {
            link: `/admin/settings/scheduled_events/${scheduledEvent.id}/edit`,
            prefix: this.t('app.labels.schedule'),
            title: scheduledEvent.name,
            id: scheduledEvent.id,
          },
        ],
        mainText,
      };
    }

    if (user) {
      const links = [
        {
          link: this.userLink(user.id),
          id: user.id,
          title: user.full_name,
          prefix: 'User',
          subTitle: user.secondary_information,
        },
      ];
      return { links };
    }

    return {};
  }

  normalizeText(change: Nullable<PaperTrailChange>, key: keyof Schedule): Nullable<string> {
    if (key === 'starts_on' && change) {
      return moment(change).tz(`${this.account?.timezone}`).format(this.account?.datetimepicker_date_format);
    }
    return super.normalizeText(change, key);
  }

  responseType(key: keyof Schedule): ResponseType {
    if (key === 'starts_on') return ResponseType.Date;

    return super.responseType(key);
  }

  private userLink(userId: TenantUser['id']): Maybe<string> {
    if (this.currentUser?.technical_admin) return `/admin/settings/users/${userId}/edit`;
  }
}
