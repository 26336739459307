
  import { Model, Vue, Component, Prop } from 'vue-property-decorator';
  import type TabRoute from '@app/utils/types/tab-route';

  @Component({ components: {} })
  export default class TabMenu extends Vue {
    @Model('input', { type: String }) readonly activeTab?: string;
    @Prop(Array) readonly tabs!: TabRoute[];
    @Prop(Boolean) readonly darkTheme?: boolean;

    isActive(tab: TabRoute) {
      return this.activeTab === tab.key;
    }
  }
