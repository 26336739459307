
  import { Component } from 'vue-property-decorator';
  import type { FieldType } from '@app/models/sub-form-question';

  import BaseFieldDisplay from './base-field-display';

  @Component({ components: {} })
  export default class DisplayImageDisplay extends BaseFieldDisplay<FieldType.display_image> {
    get maxHeight(): string {
      return this.question.config.max_height || '400';
    }

    get source(): string | undefined {
      return this.question.attachments?.[this.question.attachments.length - 1]?.url;
    }

    get altText(): string {
      return this.question.config.alt_text || '';
    }
  }
