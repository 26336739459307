const formatLocale = (): string => {
  const localeString = window.DONESAFE.locale.toLowerCase();
  return localeString.startsWith('en') ? 'en' : localeString;
};

const formatCurrency = (): string => {
  return window.DONESAFE.account?.currency_code || 'USD';
};

export const formatMoney = (value: string | number, currency = formatCurrency(), locale = formatLocale()): string => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  });

  return formatter.format(Number(value));
};
