
  import type { SingleSelectFieldValue } from '@app/models/question-response-types';
  import { Component, Emit } from 'vue-property-decorator';
  import type { FieldType } from '@app/models/sub-form-question';

  import Select2 from '../select2.vue';

  import BaseField from './base-field';

  @Component({ components: { Select2 } })
  export default class ApprovalStateField extends BaseField<FieldType.approval_state> {
    localValue: SingleSelectFieldValue = {};

    get stages(): string[] {
      return this.question.config.stages || [];
    }

    @Emit('input')
    updateValue(value?: string, label?: string): SingleSelectFieldValue {
      if (value !== this.localValue.value) {
        this.localValue = { ...this.localValue, value, label };
        this.sendUpdate(this.localValue);
      }
      return this.localValue;
    }

    beforeMount(): void {
      this.localValue = this.value || {};
    }
  }
