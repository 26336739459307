import type { BaseEntity } from './base-entity';
import type { WithRelatedModuleName } from './mixins';

export enum PermissionAccess {
  delete_access = 'delete_access',
  edit_access = 'edit_access',
  view_access = 'view_access',
}

export interface Permission extends BaseEntity, WithRelatedModuleName {
  access: PermissionAccess;
  concept_name: string;
  name: string;
  relationship: string;
}
