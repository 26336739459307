import type { DetailsLink } from '../models';
import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { FeatureSet } from '@app/models/feature-set';

export class FeatureSetService extends BaseService<FeatureSet> {
  static readonly allowedAttributes: string[] = [
    'tabs',
    'actions',
    'relations',
    'attachments',
    'export_record_index_to_csv',
    'comments',
    'signatures',
    'workflow',
    'involvements',
    'indicators',
    'follow',
    'user_related',
    'user_related_required',
    'is_public',
    'in_place_update',
    'main_form_workflow_field_enabled',
    'mfql',
    'allow_embed',
    'create_main_form_in_modal',
    'quick_edit',
    'calendar_integration_enabled',
    'qr_code',
    'quick_add_own_signature',
  ];

  async fetchDetails({ itemId }: ServiceRequest<FeatureSet>): Promise<DetailsServiceResponse> {
    const { data: featureSet } = await this.api.getFeatureSet(
      itemId,
      { only: ['id', 'module_name_id', { module_name: ['name'] }], include: ['module_name'] },
      { join: true, cache: true }
    );
    let links: DetailsLink[] = [];
    if (featureSet.module_name) {
      links = [
        {
          link: `/admin/settings/module_names/${featureSet.module_name_id}/features`,
          prefix: this.t('app.labels.module'),
          title: featureSet.module_name.name,
          id: featureSet.module_name_id,
        },
      ];
    }
    return {
      links,
    };
  }
}
