import type { WithTenantUser } from './mixins';
import type { BaseEntity } from './base-entity';

export enum AvScanStatus {
  error = 'error',
  file_not_found = 'file_not_found',
  filesize_limit_exceeded = 'filesize_limit_exceeded',
  scanner_not_found = 'scanner_not_found',
  unknown = 'unknown',
  virus_detected = 'virus_detected',
  virus_not_detected = 'virus_not_detected',
}

export interface Attachment extends BaseEntity, WithTenantUser {
  attachable_id: number;
  attachable_type: string;
  extension: string;
  file_name: string;
  is_image: boolean;
  is_previewable: boolean;
  is_variable: boolean;
  metadata: {
    analyzed?: boolean;
    av_scan_status: AvScanStatus;
    identified: boolean;
    password_protected: boolean;
  };
  preview_url?: string;
  url: string;
  uuid: string;
  web_thumbnail_url?: string;
}
