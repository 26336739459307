
  import { Component } from 'vue-property-decorator';
  import BaseFieldDisplay from './base-field-display';
  import { mixins } from 'vue-class-component';
  import ModuleRecordModals from '@app/mixins/module-record-modals';
  import type { FieldType } from '@app/models/sub-form-question';
  import type { SubFormCompletion } from '@app/models/sub-form-completion';

  @Component
  class BaseSubFormRelationDisplay extends BaseFieldDisplay<FieldType.sub_form_relation> {}

  @Component({ components: {} })
  export default class SubFormRelationDisplay extends mixins(BaseSubFormRelationDisplay, ModuleRecordModals) {
    relatedCompletion: Nullable<SubFormCompletion> = null;

    relatedCompletionLink(id: number): string {
      return `/sub_form_completions/${id}`;
    }

    openModal(event: MouseEvent): void {
      if (this.relatedCompletion) {
        this.openModalOrLink({
          link: this.relatedCompletionLink(this.relatedCompletion.id),
          modal: !this.modal,
          event,
          modalProps: {
            mode: 'sub-form-completion-show',
            subFormCompletionId: this.relatedCompletion.id,
            title: this.relatedCompletion.title || this.$t('tenant.sub_form_completions.show.form_completion'),
          },
        });
      }
    }

    beforeMount(): void {
      if (!this.isPublic && this.response?.value) {
        const filters = this.question.config.sub_form_list_id
          ? { sub_form_list_id: this.question.config.sub_form_list_id, sub_form_id: this.question.config.sub_form_id }
          : { sub_form_id: this.question.config.sub_form_id, record_id: this.record?.id, record_type: 'ModuleRecord' };
        this.$api
          .getSubFormCompletionsWithSharedFilters({ only: ['id', 'title', 'record_id'], filters }, 'id', this.response?.value, {
            cache: true,
          })
          .then(({ data }) => {
            if (data.length) {
              this.relatedCompletion = data[0];
            }
          });
      }
    }
  }
