
  import { useAccountStore } from '@app/stores/account';
  import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
  import moment from 'moment';
  import { DatePickerUIOptions } from '@app/models/question-options/date-question-options';

  @Component({})
  export default class DatePicker extends Vue {
    @Model('input', { type: Date }) readonly value!: Date | null;
    @Prop(Boolean) readonly autoclose?: boolean;
    @Prop(Function) readonly beforeShowDay?: (date: Date) => boolean | [boolean, string, string];
    @Prop(String) readonly datepickerFormat?: string;
    @Prop(Boolean) readonly disabled?: boolean;
    @Prop(Date) readonly endDate?: Date;
    @Prop(String) readonly format?: string;
    @Prop({ type: String, default: 'date' }) readonly pickerType?: string;
    @Prop(String) readonly placeholder?: string;
    @Prop(Boolean) readonly readonly?: boolean;
    @Prop(Boolean) readonly showClear?: boolean;
    @Prop(Date) readonly startDate?: Date;
    @Prop(Boolean) readonly useAccountTimezone?: boolean;

    get accountStore() {
      return useAccountStore();
    }

    get isYearPicker(): boolean {
      return this.pickerType === DatePickerUIOptions.year;
    }

    get options(): object {
      return {
        autoclose: this.autoclose,
        startDate: this.startDate,
        endDate: this.endDate,
        clearBtn: this.showClear,
        beforeShowDay: this.beforeShowDay,
        format: this.datepickerFormat || this.accountStore.data.datepicker_date_format,
        ...this.yearOptions,
      };
    }

    get tabindex(): number | undefined {
      return (this.readonly && -1) || undefined;
    }

    get valueFormatted(): string {
      if (!this.value) return '';

      let formatted = moment(this.value);
      if (this.useAccountTimezone) formatted = formatted.tz(`${this.accountStore.data.timezone}`);

      const formatType = this.isYearPicker ? 'YYYY' : this.format || this.accountStore.data.datetimepicker_date_format;
      return formatted.format(formatType);
    }

    get yearOptions(): object {
      return this.isYearPicker ? { startView: 'years', minViewMode: 'years' } : {};
    }

    @Watch('endDate')
    changeEndDate(value: Maybe<Date>): void {
      this.updateLimit('setEndDate', value);
    }

    @Watch('startDate')
    changeStartDate(value: Maybe<Date>): void {
      this.updateLimit('setStartDate', value);
    }

    dateChanged(e: { date?: Date }): void {
      if (this.isYearPicker && this.startDate && this.startDate.getFullYear() === e.date?.getFullYear()) {
        this.$emit('input', this.startDate);
      } else {
        this.$emit('input', e.date);
      }
    }

    update(value?: string | Date): void {
      $(this.$el).datepicker('update', value);
    }

    updateLimit(method: 'setStartDate' | 'setEndDate', limit: Maybe<Date>): void {
      this.$nextTick(() => {
        $(this.$el).datepicker(method, limit);
      });
    }

    mounted(): void {
      this.$nextTick(() => {
        const $el = $(this.$el);
        $el.datepicker(this.options);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        $el.on('changeDate', (e: any) => this.dateChanged(e));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        $el.on('hide', (e: any) => this.dateChanged(e)); // patch for year picker
      });
    }

    beforeDestroy(): void {
      $(this.$el).off();
    }
  }
