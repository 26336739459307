import { Vue, Component } from 'vue-property-decorator';
import { compact, groupBy } from 'lodash';
import { isSortable } from '@app/services/model-helpers';
import type { ColumnOrder, OrderOptions } from '@app/models/sub-form-list';
import type { SubFormQuestion } from '@app/models/sub-form-question';
import { FieldType } from '@app/models/sub-form-question';

export interface GroupedOption {
  group?: string;
  label: string;
  value: string;
}

type Id = string | number;

const questionsToOptions = (
  questions: SubFormQuestion[],
  group: string,
  getValue: (code: string) => string = (code) => code
): GroupedOption[] => {
  const questionsByCode = groupBy(questions.filter(isSortable), 'code');

  return Object.keys(questionsByCode).map((code) => ({
    group: group,
    value: getValue(code),
    label: `${questionsByCode[code].map((question) => question.title).join(' | ')} (${code})`,
    isText: ![FieldType.date, FieldType.datetime].includes(questionsByCode[code][0]?.field_type),
  }));
};

export const wrapOrderOptions = (orderOptions: OrderOptions): string => {
  return orderOptions.columns
    .map(({ direction, method, field, question, source }) => {
      const prefix = (direction || '').toLowerCase() === 'desc' ? '-' : '';
      return [
        `${prefix}${field || `QUESTION:${question}`}`,
        method && method !== 'DEFAULT' ? `METHOD:${method}` : null,
        !!source ? `SOURCE:${source}` : null,
      ]
        .filter((v) => !!v)
        .join('|');
    })
    .join(',');
};

export const columnOrderKey = (co: Partial<ColumnOrder>) =>
  compact([co.field || `QUESTION:${co.question}`, co.source ? `SOURCE:${co.source}` : '']).join('|');

@Component
export class WithOrderOptions extends Vue {
  get baseRecordOptions() {
    return [
      ['id', this.$t('app.labels.ID')],
      ['title', this.$t('app.labels.title'), true],
      ['score', this.$t('app.labels.score')],
      ['created_at', this.$t('app.labels.created_at')],
      ['created_by', this.$t('app.labels.created_by')],
      ['score_band', this.$t('app.labels.score_band')],
      ['state', this.$t('app.labels.workflow')],
    ].map(([value, label, isText = false]) => ({
      group: this.$t('app.labels.record'),
      value,
      label,
      isText,
    }));
  }

  async loadSortingOptions({
    record = true,
    form = true,
    approvalForm = true,
    subFormId,
    subFormListId,
  }: {
    approvalForm?: boolean;
    form?: boolean;
    record?: boolean;
    subFormId?: Id;
    subFormListId?: Id;
  } = {}): Promise<GroupedOption[]> {
    const baseFormQuestions = async (subFormId?: Id) => {
      if (!subFormId) {
        return [] as SubFormQuestion[];
      }

      const { data: form } = await this.$api.getSubForm(
        Number(subFormId),
        { include: ['sub_form_sections', 'sub_form_questions'] },
        { cache: true }
      );

      return form.sub_form_sections?.map((s) => s.sub_form_questions || []).flat() || [];
    };

    const approvalFormQuestionOptions = async (subFormListId?: Id) => {
      if (subFormListId) {
        const {
          data: { approval_sub_form_id },
        } = await this.$api.getSubFormList(Number(subFormListId), { only: ['sub_form_ids', 'approval_sub_form_id'] }, { cache: true });

        if (!approval_sub_form_id) {
          return [] as GroupedOption[];
        }

        const {
          data: { sub_form_sections },
        } = await this.$api.getSubForm(
          Number(approval_sub_form_id),
          { include: ['sub_form_sections', 'sub_form_questions'] },
          { cache: true }
        );

        const approvalFormQuestions = sub_form_sections?.map((s) => s.sub_form_questions || []).flat() || [];

        return questionsToOptions(approvalFormQuestions, this.$t('app.labels.approval_form'), (code) =>
          columnOrderKey({ source: 'APPROVAL', question: code })
        );
      }

      return [] as GroupedOption[];
    };

    return (
      await Promise.all([
        (record ? this.baseRecordOptions : []) as GroupedOption[],
        form
          ? (async () =>
              questionsToOptions(await baseFormQuestions(subFormId), this.$t('app.labels.form'), (code) =>
                columnOrderKey({ question: code })
              ))()
          : [],
        approvalForm ? approvalFormQuestionOptions(subFormListId) : [],
      ])
    ).flat();
  }
}
