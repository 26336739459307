
  import { Component, Prop, Ref } from 'vue-property-decorator';
  import { ValidationObserver } from 'vee-validate';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import Select2 from '@app/components/select2.vue';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import { mixins } from 'vue-class-component';
  import Blocking from '@app/mixins/blocking';
  import type { DynamicDashboardSettings } from '@app/models/account';
  import { useAccountStore } from '@app/stores/account';
  import { debounce, isEmpty } from 'lodash';
  import DsButton from '@app/components/ds-button.vue';
  import DsIconText from '@app/components/ds-icon-text.vue';
  import WithBootbox from '@app/components/admin/user-collections/with-bootbox';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import reloadDynamicDashboard from '@app/reloadDynamicDashboard';
  import { toaster } from '@app/utils/toaster';
  import { DEFAULT_DYNAMIC_DASHBOARD_SETTINGS } from '../dashboards/utils';

  @Component({
    components: {
      DsCheckbox,
      FormField,
      ValidationObserver,
      Select2,
      DsButton,
      DsIconText,
    },
  })
  export default class DynamicDashboardSettingsForm extends mixins(Blocking, WithBootbox) {
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    @Prop(Number) userId?: number;

    form: DynamicDashboardSettings = { ...DEFAULT_DYNAMIC_DASHBOARD_SETTINGS };

    debounceSave = debounce(() => this.save(), 1000);

    get accountStore() {
      return useAccountStore();
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get userSettings() {
      return this.currentUserStore.data?.dynamic_dashboard_settings;
    }

    get accountSettings() {
      return this.accountStore.data.dynamic_dashboard_settings;
    }

    get isCustomized() {
      return this.currentUserStore.hasDynamicDashboardPersonalization;
    }

    get whiteSpaceOptions() {
      return [
        ['priority_order', this.$t('tenant.admin.dashboard_panes.white_space_types.reorder')],
        ['hide', this.$t('tenant.admin.dashboard_panes.white_space_types.hide')],
      ];
    }

    get whiteSpaceHelpText() {
      if (this.form.white_space === 'hide') return this.$t('tenant.admin.dashboard_panes.reorder_white_space_info');
    }

    get stretchPanesHelpText() {
      if (!this.form.stretch_panes) return this.$t('tenant.admin.dashboard_panes.stretch_panes_disabled_info');
    }

    async beforeMount() {
      this.form = await this.loadForm(false);
    }

    async loadForm(patchCurrentUser: boolean) {
      let initialData = this.accountSettings || {};

      if (this.userId) {
        const {
          data: { dynamic_dashboard_settings },
        } = await this.$api.getTenantUser(this.userId, { only: ['dynamic_dashboard_settings'] }, { cache: true });

        // this is necessary to properly update deeply nested empty object
        patchCurrentUser &&
          this.currentUserStore.$patch((state) => state.data && (state.data.dynamic_dashboard_settings = dynamic_dashboard_settings));

        if (!isEmpty(dynamic_dashboard_settings)) {
          initialData = dynamic_dashboard_settings;
        }
      }

      return { ...DEFAULT_DYNAMIC_DASHBOARD_SETTINGS, ...initialData };
    }

    async save(form?: DynamicDashboardSettings): Promise<void> {
      form ||= this.form;

      const update = { dynamic_dashboard_settings: { ...form } };

      this.blocking(async () => {
        if (this.userId) await this.$api.updateTenantUser(this.userId, update);
        else await this.$api.updateCurrentAccount(update);

        toaster(this.$t('app.labels.saved'));
        this.$api.cache.clear();
      });

      reloadDynamicDashboard.next();
    }

    async clearUserSettings() {
      if (await this.confirm(this.$t('tenant.admin.dashboard_panes.customization_reset_confirmation'), { backdrop: false })) {
        await this.save({} as DynamicDashboardSettings);
      }
    }
  }
