import type { StringBoolean } from '@app/utils/types/string-boolean';
import type { BaseQuestionOptions } from './base-question-options';

export enum MapSize {
  huge = '800px',
  minimal = '100px',
  normal = '500px',
  small = '300px',
}

export interface AddressQuestionOptions extends BaseQuestionOptions {
  map_display_height?: string;
  map_select_enabled?: StringBoolean;
  map_select_height?: string;
  validate?: {
    address?: StringBoolean;
    lat_lng?: StringBoolean;
  };
}
