export enum ResponseType {
  Actionable = 'Actionable',
  Attachment = 'Attachment',
  Dashboard = 'Dashboard',
  Date = 'Date',
  DateTime = 'DateTime',
  Description = 'Description',
  Direct = 'Direct',
  IndexFilter = 'IndexFilter',
  Indicator = 'Indicator',
  Location = 'Location',
  ModuleName = 'ModuleName',
  ModuleRecord = 'ModuleRecord',
  Organization = 'Organization',
  RecordSignature = 'RecordSignature',
  Response = 'Response',
  Role = 'Role',
  Signature = 'Signature',
  SubForm = 'SubForm',
  SubFormCompletion = 'SubFormCompletion',
  SubFormList = 'SubFormList',
  SubFormQuestion = 'SubFormQuestion',
  SubFormSection = 'SubFormSection',
  SuperReport = 'SuperReport',
  User = 'User',
  Workflow = 'Workflow',
}
