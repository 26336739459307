import type { Location } from '@app/models/location';
import type { TenantUser } from '@app/models/tenant-user';
import type { BaseEntity } from './base-entity';
import type { SubFormCompletion } from './sub-form-completion';
import type { Comment } from './comment';

export enum ActionType {
  Corrective = 'corrective',
  Preventative = 'preventative',
  Task = 'task',
}

export enum HazardControlType {
  Corrective = 'corrective',
  Preventative = 'preventative',
  Task = 'task',
}

export enum HazardControlCategory {
  Administration = 'administration',
  Elimination = 'elimination',
  Engineering = 'engineering',
  Isolation = 'isolation',
  PPE = 'ppe',
  Substitution = 'substitution',
}
export enum ActivityState {
  Closed = 'closed',
  Open = 'open',
}

export enum ActivityStatusCodes {
  ClosedApproved = 'closed_approved',
  ClosedNotRequiringApproval = 'closed_not_requiring_approval',
  CompletedPendingApproval = 'completed_pending_approval',
  DueToday = 'due_today',
  InProgress = 'in_progress',
  OverdueNotRequiringApproval = 'overdue_not_requiring_approval',
  OverduePendingApproval = 'overdue_pending_approval',
}

export interface Activity extends BaseEntity {
  action_priority_id: number | null;
  action_type?: ActionType;
  actionable_id: number;
  actionable_type: string;
  approved: boolean;
  approved_at?: string;
  approved_by?: TenantUser;
  approved_by_id: TenantUser['id'] | null;
  assigned_approver?: TenantUser;
  assigned_approver_id: TenantUser['id'] | null;
  assignee?: TenantUser;
  assignee_id: number;
  // TODO: proper model
  call_to_action?: { id: number; link: string; text: string };
  can_approve?: boolean;
  can_delete?: boolean;
  // is it possible that the action can be listed but can not be accessed or edited
  can_edit?: boolean;
  can_view?: boolean;
  category?: HazardControlCategory;
  closed_at: string | null;
  comment: string;
  // optional
  comments?: Comment[];
  completed_by?: TenantUser;
  completed_by_id: TenantUser['id'] | null;
  contributing_subtype_id?: number;
  contributing_type_id?: number;
  control_type?: HazardControlType;
  date: string;
  description: string;
  external_uuid: string;

  hazard_description?: string;
  location?: Location;
  related_record_type?: string;
  related_to?: string; // hack
  require_completion_comment: boolean;
  requires_approval: boolean;
  state: ActivityState;
  status?: ActivityStatusCodes;
  sub_form_completion?: SubFormCompletion;
  sub_form_completion_id?: number;
  sub_form_response_id?: number;
  // title?: string;
  user?: TenantUser;
  user_id: number | null;
}

export interface ActivitiesStats {
  accessible: number;
  accessible_open: number;
  my: number;
  my_open: number;
  total: number;
  total_open: number;
}
