import type { SinglePersonSelectorFormField } from '@app/components/admin/questions/extras/model';
import type { ExtraUserApiOptions } from '@app/services/api/tenant-users-api';
import type { BaseQuestionOptions } from './base-question-options';
import type { UserType, TenantUser } from '@app/models/tenant-user';
import type { ConfiguratorFilter } from '@app/models/configurator-filter';
import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
import type { StringBoolean } from '@app/utils/types/string-boolean';

export enum EmailMatchingBehaviour {
  RequireUniqueEmail = 'require_uniq_email',
  UseMatchingUser = 'use_matching_user',
}

export interface SinglePersonSelectorQuestionOptions extends BaseQuestionOptions {
  add_new_user_only?: StringBoolean;
  add_new_user_panel_title?: string;
  email_matching_behaviour?: EmailMatchingBehaviour;
  enable_add_new_user?: StringBoolean;
  fields: SinglePersonSelectorFormField[];
  filters?: ConfiguratorFilter[];
  home_location_question_id?: string;
  home_organization_question_id?: string;
  involvement_id?: string;
  new_user_type?: UserType;
  notifications_on?: StringBoolean;
  required_filters: DonesafeFilterOptions<TenantUser, ExtraUserApiOptions>;
  role_id: number;
}
