
  import { Component, Emit, Prop } from 'vue-property-decorator';
  import { flow, values, uniq, isNaN, mapValues, isString, isEqual } from 'lodash';
  import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
  import Big from 'big.js';
  import { Subject, tap } from 'rxjs';
  import type {
    LogicSetFormula,
    LogicSetFormulaResult,
    VariableFieldOption,
    CalculatorHistory,
    VariableQuestion,
    UserValues,
  } from '@app/models/calculator';
  import QuestionMapping from '@app/components/rule-builder/config/QuestionMapping';
  import type { CalculatorFieldValue } from '@app/models/question-response-types';
  import { FieldType } from '@app/models/sub-form-question';
  import type { DisplaySettings, OptionConfigs } from '@app/models/question-options/calculator-question-options';
  import type { VisibilityState } from '@app/utils/types/visibility-state';
  import ItemsToDisplay from '@app/components/calculator/items-to-display.vue';

  import ErrorMessage from '../calculator/error-message.vue';

  import BaseField from './base-field';

  @Component({ components: { ErrorMessage, ItemsToDisplay } })
  export default class CalculatorField extends BaseField<FieldType.calculator> {
    @Prop(Object) readonly blankValues!: Record<string, string>;

    recalculateEnabled = false;
    history: Partial<CalculatorHistory> = {};
    dependentFieldUpdated = false;

    calculationUpdate$ = new Subject<void>();

    get overrideValue(): string {
      if (!this.overrideQuestion) {
        return '';
      }

      // fallback to '' for overrideValue if question is not visible
      const isVisible = this.formObservers.questionShowHideState$?.value?.[this.question.id]?.state;
      if (!isVisible) {
        return '';
      }

      const calculationValue = `${this.observers.calculationValues$.value[this.overrideQuestion.id]}`;
      // if input field is blank and blank value option exists, use that value
      if (!calculationValue && this.blankValues[this.question.id]) {
        return this.blankValues[this.question.id];
      }

      return calculationValue;
    }

    // TODO: fix this later
    get localValue(): CalculatorFieldValue {
      return {
        value: this.overrideValue || this.history.databaseValue,
        original: this.history.databaseValue,
        override: this.overrideValue,
        history: this.historyValue,
      };
    }

    get getVisibleValuesOnly(): boolean {
      return this.question.config.legacy_show_hide_handling !== 'true';
    }

    get preserveQuotes(): boolean {
      return this.question.config.preserve_quotes === 'true';
    }

    get historyValue(): string {
      return JSON.stringify(this.history);
    }

    get displaySettings(): DisplaySettings {
      return JSON.parse(this.question.config.display_settings);
    }

    get complexInput(): boolean {
      return this.displaySettings.format === 'currency' || this.displaySettings.format === 'percentage' || this.loadingState;
    }

    get variablesMapping(): Record<string, VariableQuestion> {
      const { config } = this.question;
      const mapping = config.variables_mapping
        ? isString(config.variables_mapping)
          ? JSON.parse(config.variables_mapping)
          : config.variables_mapping
        : {};
      return mapValues(mapping, (item) => (isString(item) ? JSON.parse(item) : item));
    }

    get overrideQuestion(): VariableQuestion | undefined {
      return this.question.config.override_question ? JSON.parse(this.question.config.override_question) : undefined;
    }

    get optionConfigs(): OptionConfigs | undefined {
      return this.question.config.configs ? JSON.parse(this.question.config.configs) : undefined;
    }

    get itemsToDisplay(): string[] | undefined {
      return this.question.config.items_to_display ? JSON.parse(this.question.config.items_to_display) : undefined;
    }

    @Emit('input')
    updateValue(): CalculatorFieldValue {
      this.sendUpdate(this.localValue, !this.dependentFieldUpdated);
      return this.localValue;
    }

    mounted(): void {
      if (this.value?.history && !this.newMode) {
        this.history = JSON.parse(this.value.history);

        // hide Recalculate Value button when recalculateValueOnVariableChange = true
        this.recalculateEnabled = !this.optionConfigs?.react_recalculate_value_on_variable_change;
        this.loadingState = false;
      }
      this.initObservers(!this.recalculateEnabled);
    }

    recalculate(): void {
      this.recalculateEnabled = false;
      this.updateAndCalculate();
    }

    showError(error: string, newHistory: Partial<CalculatorHistory> = {}): void {
      this.history = {
        ...this.history,
        ...newHistory,
        displayValue: error,
        status: 'error',
      };
    }

    updateAndCalculate(shouldExecuteLogicSets = true): void {
      shouldExecuteLogicSets && this.requestCalculation(false);
      this.updateOverrideValue();
    }

    requestCalculation(updateOverrideValue = true): void {
      this.calculationUpdate$.next();
      updateOverrideValue && this.updateOverrideValue();
    }

    initObservers(shouldExecuteLogicSets = true): void {
      this.addSubscription(
        this.calculationUpdate$
          .pipe(
            tap(() => this.updateLoadingState(true)),
            debounceTime(200),
            switchMap(() => this.executeFormula())
          )
          .subscribe({
            next: (resultData) => {
              const displayAndDatabaseValue = this.generateDisplayAndDatabaseValue(resultData);

              this.updateLoadingState(false);
              this.history = {
                displayValue: displayAndDatabaseValue.displayValue,
                databaseValue: displayAndDatabaseValue.databaseValue,
                itemsToDisplay: resultData.items_to_display,
                definitions: resultData.definitions,
                status: resultData.status,
              };

              this.updateValue();
            },
            error: (error) => {
              console.error(error);
              this.updateLoadingState(false);
              this.showError(error.message, {
                databaseValue: '',
                itemsToDisplay: [],
                definitions: undefined,
              });
            },
          })
      );

      this.updateAndCalculate(shouldExecuteLogicSets);

      this.addSubscription(
        this.formObservers.fieldUpdate$
          .pipe(
            filter(([question]) => {
              return (
                !this.recalculateEnabled &&
                !!this.question.config.logic_set_id &&
                Object.values(this.variablesMapping).some(
                  (variable) =>
                    // we are handling 'calculation_select' and 'calculation_text in calculationValues$ observable
                    question.field_type !== FieldType.calculation_select &&
                    question.field_type !== FieldType.calculation_text &&
                    question.id === variable.id
                )
              );
            })
          )
          .subscribe(([, , init]) => {
            if (!init) {
              this.dependentFieldUpdated = true;
            }
            this.requestCalculation();
          })
      );

      if (this.overrideQuestion) {
        this.addSubscription(
          this.formObservers.fieldUpdate$
            .pipe(
              filter(
                ([question]) => !this.recalculateEnabled && !!this.question.config.logic_set_id && question.id === this.overrideQuestion?.id
              )
            )
            .subscribe(([, , init]) => {
              if (!init) {
                this.dependentFieldUpdated = true;
              }
              this.updateOverrideValue();
            })
        );

        this.formObservers.visibilityStateUpdate$
          .pipe(
            filter(() => !this.recalculateEnabled && !!this.question.config.logic_set_id),
            map((updates) => updates.find((u) => u.id === this.overrideQuestion?.id)),
            filter((question): question is VisibilityState => !!question),
            filter((question) => {
              return !question.state;
            })
          )
          .subscribe(() => this.updateOverrideValue());
      }

      this.addSubscription(
        this.formObservers.calculationValues$
          .pipe(
            distinctUntilChanged(isEqual),
            filter((updates) => {
              return (
                !this.recalculateEnabled &&
                !!this.question.config.logic_set_id &&
                // recalculate if any of calculation values changed, and it's one of the formula variables
                // hidden questions are handled here as well
                Object.values(this.variablesMapping).some(
                  (variable) => updates.hasOwnProperty(`${variable.id}`) && this.value?.value !== updates[`${variable.id}`]
                )
              );
            })
          )
          .subscribe(() => this.requestCalculation())
      );
      this.addSubscription(
        this.formObservers.visibilityStateUpdate$
          .pipe(
            distinctUntilChanged(isEqual),
            filter(() => !this.recalculateEnabled && !!this.question.config.logic_set_id)
          )
          .subscribe(() => this.requestCalculation())
      );
    }

    updateOverrideValue(): void {
      const overrideQuestion = this.overrideQuestion;
      if (!overrideQuestion) return;

      this.updateValue();
    }

    getFieldId(questionDetailObject: VariableQuestion): string {
      return QuestionMapping(questionDetailObject)[questionDetailObject.fieldType][questionDetailObject.fieldOption];
    }

    getInputValue(question: VariableQuestion): VariableFieldOption | undefined {
      const questionId = question.id;
      const fieldId = this.getFieldId(question);
      const calculationValue = this.formObservers.calculationValues$.value?.[questionId];
      const inputValue = calculationValue || this.$form.find(`#${fieldId}`).val();
      const isVisible = this.formObservers.questionShowHideState$?.value?.[questionId]?.state;
      const keepHiddenValue = question.fieldType === 'calculation_select';

      // fallback to '' for overrideValue if question is not visible
      if (!isVisible && (this.getVisibleValuesOnly || !keepHiddenValue)) {
        return '';
      }

      // if input field is blank and blank value option exists, use that value
      if (!inputValue && this.blankValues[questionId]) {
        return this.blankValues[questionId];
      }

      return inputValue?.toString();
    }

    async executeFormula(): Promise<LogicSetFormulaResult> {
      const logicSetId = this.question.config.logic_set_id;
      if (!logicSetId) throw new Error(this.$t('app.labels.no_logic_set_selected') as string);

      try {
        this.toggleFormDisability(true);
        const { data } = await this.$api.executeFormula(logicSetId, this.formulaPayload(), { cache: true });
        return data;
      } finally {
        this.toggleFormDisability(false);
      }
    }

    formulaPayload(): LogicSetFormula {
      return {
        variables_mapping: this.variablesMapping,
        user_input_values: this.userValues(),
        items_to_display: this.itemsToDisplay,
        preserve_quotes: this.preserveQuotes,
        missing_variable_handling: this.question.config.missing_variable_handling,
      };
    }

    toggleFormDisability(disabled: boolean): void {
      const triggerMethod = disabled ? window.triggerDsAjaxStart : window.triggerDsAjaxStop;
      disabled ? this.updateLoadingCount('start') : this.updateLoadingCount('stop');
      triggerMethod('execute_formula');
    }

    generateDisplayAndDatabaseValue(result: LogicSetFormulaResult): {
      databaseValue: string;
      displayValue: string;
    } {
      let displayValue: string;
      let databaseValue: string;

      if (result.status === 'error') {
        displayValue = result.result;
        databaseValue = '';
      } else if (result.status === 'success' && isNaN(result.result)) {
        // display string regardless of formatting
        displayValue = databaseValue = result.result;
      } else {
        const decimals = this.displaySettings.decimals;
        switch (this.displaySettings.format) {
          case 'percentage':
            databaseValue = decimals ? this.roundDecimalPlaces(result.result, parseInt(decimals) + 2) : result.result;

            displayValue = Big(databaseValue).times(Big(100)).toString();
            if (decimals) {
              displayValue = this.roundDecimalPlaces(displayValue, parseInt(decimals));
            }
            break;
          case 'number':
          case 'currency':
            databaseValue = decimals ? this.roundDecimalPlaces(result.result, parseInt(decimals)) : result.result;
            displayValue = databaseValue;
            break;
          case 'none':
          default:
            displayValue = databaseValue = result.result;
            break;
        }
      }

      return {
        displayValue,
        databaseValue,
      };
    }

    roundDecimalPlaces(numberString: string, decimalPlaces: number): string {
      let convertedNumber = '';
      const roundedNumber = Big(numberString).round(decimalPlaces).toString();
      const decimalIndex = roundedNumber.indexOf('.');

      if (decimalPlaces === 0) return roundedNumber;

      if (decimalIndex === -1) {
        convertedNumber = roundedNumber + '.' + Array(decimalPlaces + 1).join('0');
      } else {
        const digitsAfterDecimal = roundedNumber.length - (decimalIndex + 1);
        convertedNumber = roundedNumber + Array(decimalPlaces - digitsAfterDecimal + 1).join('0');
      }

      return convertedNumber;
    }

    userValues(): UserValues {
      const questions = flow(values, uniq)(this.variablesMapping);
      return questions.reduce<UserValues>((memo, question) => {
        const value = this.getInputValue(question);
        if (!memo[question.id]) memo[question.id] = {};
        memo[question.id][question.fieldOption] = value;
        return memo;
      }, {});
    }
  }
