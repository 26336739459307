
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import { addIcon, Icon } from '@iconify/vue2/dist/offline';
  import target from '@iconify-icons/mdi/target';
  import remove from '@iconify-icons/mdi/remove';

  import DsDropdown from './ds-dropdown.vue';

  addIcon('mdi:target', target);
  addIcon('mdi:remove', remove);

  @Component({ components: { Icon, DsDropdown } })
  export default class DsAddressPicker extends Vue {
    @Model('input') readonly value!: string;
    @Prop(Boolean) readonly showClearButton?: boolean;
    @Prop(Boolean) readonly disabled?: boolean;
    @Prop(Boolean) readonly selectLocationInProgress?: boolean;
    @Prop(String) readonly inputId!: string;
    @Prop(String) readonly inputClass!: string;
    @Prop(String) readonly inputName!: string;
    @Prop(String) readonly inputErrorsContainerId!: string;
    @Prop(Boolean) readonly inputReadonly?: boolean;
    @Prop(Boolean) readonly inputRequired?: boolean;
    @Prop(Boolean) readonly inputValidateAddress?: boolean;
    @Prop(Boolean) readonly inputValidateLatLng?: boolean;
    @Prop(Object) readonly inputOptions?: { fields: string[] };
    @Prop(Object) readonly attrs?: unknown;
    @Prop(Object) readonly listeners?: unknown;
    @Prop(Boolean) readonly showSelectNearestAddress?: boolean;
    @Prop(String) readonly labelledBy?: string;

    targetDropdownVisibility = false;
  }
