
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { bufferTime, concatMap, filter, map, scan, take } from 'rxjs/operators';
  import {
    camelCase,
    cloneDeep,
    debounce,
    differenceBy,
    differenceWith,
    flatten,
    groupBy,
    isEmpty,
    isEqual,
    keyBy,
    merge,
    pick,
    pickBy,
    sortBy,
    upperFirst,
    values,
  } from 'lodash';
  import { Collapse } from 'uiv';
  import { v4 as generateUUID } from 'uuid';
  import { Component, Emit, Model, Prop, Watch } from 'vue-property-decorator';
  import {
    areResponsesEqual,
    hiddenCalculation,
    hiddenQuestion,
    IGNORE_UNSAVED_FIELD_TYPES,
    isArrayTypeQuestion,
    isCompleted,
    isReadonlyLookup,
    isResponseEmpty,
  } from '@app/services/model-helpers';
  import type { SubFormDataAndId } from '@app/mixins/with-previous-completion-form';
  import type { RelatedItem, RelatedKey, SubFormCompletionExtraParameters, SubFormData } from '@app/services/api/sub-form-completions-api';
  import type { ParsedQs } from 'qs';
  import qs from 'qs';
  import type { SubFormListOnly } from '@app/pages/module-records/utils';
  import { SUB_FORM_LIST_ONLY } from '@app/pages/module-records/utils';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import { SECTION_SHOWN_EVENT_NAME } from '@app/legacy/subform';
  import Copy from '@app/components/admin/questions/edit/copy.vue';
  import type { Dictionary } from '@app/models/dictionary';
  import type { SubForm } from '@app/models/sub-form';
  import { SubFormType } from '@app/models/sub-form';
  import type { SubFormCompletion } from '@app/models/sub-form-completion';
  import { SubFormCompletionStage } from '@app/models/sub-form-completion';
  import type { SubFormList } from '@app/models/sub-form-list';
  import type { ResponseValue, SubFormQuestion } from '@app/models/sub-form-question';
  import { AUTO_SAVE_IGNORE_TYPES, FieldType, LOOKUP_TYPE_MAPPING, SELF_LOOKUP_KEY } from '@app/models/sub-form-question';
  import type { SubFormSection } from '@app/models/sub-form-section';
  import type { SubFormResponse } from '@app/models/sub-form-response';
  import type { Workflow } from '@app/models/workflow';
  import type { DetailQuestionOptions } from '@app/models/question-options/detail-question-options';
  import type { PartialBy } from '@app/utils/types/partial';
  import type { VisibilityState, VisibilityStateUpdate } from '@app/utils/types/visibility-state';
  import { customDispatchEvent } from '@app/utils/custom-dispatch-event';
  import { toaster } from '@app/utils/toaster';
  import { handleApiRequest } from '@app/utils/handle-api-request';
  import { MAIN_FORM_MODULE_NAME } from '@app/constants';
  import DsIconText from '@app/components/ds-icon-text.vue';
  import { useAccountStore } from '@app/stores/account';
  import { interval } from 'rxjs';

  import type {
    LookupResponseValue,
    QuestionSfcOnly,
    SubFormCompletionFormRecord,
    SubFormSfcFormOnly,
    SubFormSfsSfcFormOnly,
  } from '../utils';
  import { QUESTION_SFC_ONLY, SUB_FORM_SFC_FORM_ONLY } from '../utils';

  import QuestionHandler from './question-handler';
  import SubFormCompletionFormNavPanel from './sub-form-completion-form-nav-panel.vue';
  import { WithFormSubscriptions } from './with-form-subscriptions';
  import QuestionBlock from './question-block.vue';

  type Question = Pick<SubFormQuestion, QuestionSfcOnly>;
  type SectionGroup = Pick<SubFormSection, SubFormSfsSfcFormOnly>[];

  interface FormSubmitParams extends SubFormCompletionExtraParameters {
    addAnother?: boolean;
    event?: MouseEvent;
    inPlaceUpdate?: boolean;
    stage?: SubFormCompletionStage;
  }

  const DEFAULT_SCROLL_TO_OPTS = (modal = false) => ({
    offset: modal ? -100 : -80,
    easing: [0.02, 0.01, 0.47, 1],
    container: modal ? '.record-modal' : '#main-content-col',
    lazy: false,
  });

  // TODO: get rid of params
  @Component({
    components: {
      QuestionBlock,
      Collapse,
      DsDropdown,
      SubFormCompletionFormNavPanel,
      Copy,
      DsIconText,
    },
  })
  export default class SubFormCompletionForm extends WithFormSubscriptions {
    @Model('input', { default: {} }) readonly value!: SubFormData;
    @Prop(Boolean) readonly addAnother?: boolean;
    @Prop(Boolean) readonly allowDraft?: boolean;
    @Prop(Object) readonly approvalForSubFormCompletion?: SubFormCompletion;
    @Prop(String) readonly completeAddAnotherButtonText?: string;
    @Prop(String) readonly completeButtonText?: string;
    @Prop(Boolean) readonly defaultTemplating?: boolean;
    @Prop(String) readonly draftAddAnotherButtonText?: string;
    @Prop(String) readonly draftButtonText?: string;
    @Prop(Boolean) readonly enhanced?: boolean;
    @Prop(Boolean) readonly inPlaceUpdate!: boolean;
    @Prop({ type: Boolean, default: true }) readonly initialScrollTo!: boolean;
    @Prop(Boolean) readonly isPublic?: boolean;
    @Prop(Number) readonly mainFormId!: number;
    @Prop(Boolean) readonly mobile?: boolean;
    @Prop(Boolean) readonly modal?: boolean;
    @Prop(Object) readonly previousCompletionForm?: SubFormDataAndId;
    @Prop(Object) readonly record?: SubFormCompletionFormRecord;
    @Prop({ type: String, default: () => 'ModuleRecord' }) readonly recordType!: string;
    @Prop(Boolean) readonly shouldAutoSave?: boolean;
    @Prop(Boolean) readonly showCanAdd?: boolean;
    @Prop(Object) readonly subFormCompletion!: PartialBy<SubFormCompletion, 'id' | 'sub_form_responses'>;
    @Prop(Boolean) readonly submitting?: boolean;
    @Prop({ type: Boolean, default: false }) readonly wizard!: boolean;

    debouncedSubmitForm = debounce(this.submitForm, 250);
    debouncedTriggerLookupRequests = debounce(this.triggerLookupRequests, 200);

    activeSection: Nullable<Pick<SubFormSection, SubFormSfsSfcFormOnly>> = null;
    allQuestions: Question[] = [];
    busyCollapse = 0;
    calculationTextFormDisable = false;
    defaultValues: null | SubFormData = null;
    initValues: null | SubFormData = null;
    invalidState: Dictionary<Array<HTMLElement>> = {};
    loadingCountByKey: Record<string, number> = {};
    lookupHistory: Record<string, string> = {};
    questionHandler: Nullable<QuestionHandler> = null;
    questionKeys: Dictionary<string> = {};
    relatedItems: {
      attachments?: Record<string, RelatedItem[]>;
      related_activities?: Record<string, RelatedItem[]>;
      related_records?: Record<string, RelatedItem[]>;
    } = {};
    sectionCollapses: Record<number, boolean> = {};
    sectionGroups: SectionGroup[] = [];
    subForm: Nullable<Pick<SubForm, SubFormSfcFormOnly>> = null;
    subFormList: Nullable<Pick<SubFormList, SubFormListOnly>> = null;
    subFormReady = false;
    visibleQuestionsForThisValue: {
      ids: number[];
      questions: Question[];
    } = {
      ids: [],
      questions: [],
    };
    loadingStates: Record<number, boolean> = {};
    prevVisibilityUpdate: VisibilityStateUpdate = [];
    prevBufferedUpdates: { question: Pick<SubFormQuestion, QuestionSfcOnly>; value: ResponseValue }[] = [];

    get accountStore() {
      return useAccountStore();
    }

    get activeSectionGroup(): Maybe<SectionGroup> {
      if (!this.activeSection) return;

      const activeSectionGroup = this.visibleSectionGroups.find((sg) => sg.find((s) => s.id === this.activeSection?.id));
      return activeSectionGroup?.filter((s) => this.visibleSectionsHash[s.id] && !this.visuallyHiddenSectionsHash[s.id]);
    }

    get buttonsDisabled() {
      return (
        this.calculationTextFormDisable ||
        this.submitting ||
        Object.values(this.loadingCountByKey).some((count) => !!count) ||
        this.updateOccurred
      );
    }

    get calculatorHiddenSections(): Record<number, boolean> {
      const { ids } = this.visibleQuestionsForThisValue;

      return (this.subForm?.sub_form_sections || []).reduce((memo, section) => {
        return {
          ...memo,
          [section.id]: !section.sub_form_questions?.some(
            (q) => this.visibleQuestionsHash[q.id] && (ids.includes(q.id) || this.isCalculator(q))
          ),
        };
      }, {});
    }

    get canBeSavedAsDraft(): boolean {
      return (
        !!this.allowDraft &&
        this.subForm?.sub_form_type !== SubFormType.approval &&
        !this.isMainForm &&
        !!this.subFormList?.draft &&
        (this.subFormList.draft_after_complete ||
          this.subFormCompletion.stage == SubFormCompletionStage.Draft ||
          !this.subFormCompletion.stage)
      );
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get dataByCode(): Dictionary<ResponseValue> {
      const { questions } = this.visibleQuestionsForThisValue;
      return Object.keys(this.value).reduce((memo, key) => {
        const question = questions.find((q) => `${q.id}` === key);
        if (question) {
          const value = this.value[key];
          if (!isEmpty(value)) {
            return { ...memo, [question.code]: value };
          }
        }
        return memo;
      }, {});
    }

    get formChangeStatus() {
      if (this.shouldShowUnsavedResponses) {
        const { ids } = this.visibleQuestionsForThisValue;
        const idsSet = new Set(ids);
        const questionsStatus = Object.entries(this.questionHelper).reduce((acc, [key, value]) => {
          // exclude hidden questions by the show/hide logic
          if (idsSet.has(Number(key))) return [...acc, pick(value, ['unsaved', 'newUnsavedResponse'])];
          return acc;
        }, [] as { newUnsavedResponse: boolean; unsaved: boolean }[]);

        const hasNewUnsavedResponse = questionsStatus.some((q) => q.newUnsavedResponse);

        return {
          hasChanges: hasNewUnsavedResponse || questionsStatus.some((q) => q.unsaved),
          hasNewUnsavedResponse,
        };
      } else {
        return {
          hasChanges: false,
          hasNewUnsavedResponse: false,
        };
      }
    }

    get formLoaded(): boolean {
      return this.subFormReady && (!this.subFormCompletion.sub_form_list_id || !!this.subFormList);
    }

    get isMainForm(): boolean {
      return this.subForm?.module_name === MAIN_FORM_MODULE_NAME;
    }

    get params(): ParsedQs {
      return qs.parse(window.location.search, { ignoreQueryPrefix: true });
    }

    get questionHelper(): Record<
      string,
      {
        canAddAnything: boolean;
        canAddAttachment: boolean;
        description: Maybe<string>;
        fieldName: string;
        hideQuestion: boolean;
        hideUnsavedIndication: boolean;
        horizontalDivider: boolean;
        isHidden: boolean;
        isRequired: boolean;
        name: string;
        newUnsavedResponse: boolean;
        readonly: boolean;
        response: { response: ResponseValue };
        title: Maybe<string>;
        unsaved: boolean;
      }
    > {
      return this.allQuestions.reduce((acc, q) => {
        const { description, question, id } = q;
        return {
          ...acc,
          [id]: {
            description: description ? this.$sanitize(description) : undefined,
            title: question ? this.$sanitize(question) : undefined,
            canAddAnything: this.getCanAddAnything(q),
            canAddAttachment: this.getCanAddAttachment(q),
            readonly: this.updateOccurred || this.isReadonly(q) || this.isLocked(q),
            horizontalDivider: q.config?.horizontal_divider,
            isHidden: this.defaultTemplating ? false : hiddenQuestion(q),
            isRequired: this.defaultTemplating ? false : this.isRequired(q),
            name: `responses[${q.id}]`,
            fieldName: `${upperFirst(camelCase(q.field_type))}Field`,
            hideQuestionTitle: this.hideQuestionTitle(q),
            ...this.unsavedIndicationAndResponse(q),
            ...this.calculatorQuestionProps(q),
          },
        };
      }, {});
    }

    get recordCompletion(): Maybe<SubFormCompletion> {
      return this.record && 'sub_form_completion' in this.record ? this.record.sub_form_completion : undefined;
    }

    get renderedSectionGroups() {
      return this.sectionGroups.filter((sections) => this.hasSectionsToRender(sections));
    }

    get sectionCompletionCountHash(): Record<number, string> {
      return this.sectionGroups.reduce((m, sections) => {
        return sections.reduce((m, s) => ({ ...m, [s.id]: this.sectionCompletionCount(s) }), m);
      }, {});
    }

    get shouldShowUnsavedResponses() {
      return !this.defaultTemplating;
    }

    get systemCodeToIdMap(): Record<string, string> {
      return this.allQuestions.reduce((memo, q) => ({ ...memo, [q.system_code]: q.id }), {});
    }

    get visibleQuestionsBySection() {
      const visibleQuestionsBySection: Record<number, Question[]> = {};
      this.sectionGroups
        .reduce((acc, sections) => [...acc, ...sections], [])
        .forEach((section) => {
          visibleQuestionsBySection[section.id] = this.visibleQuestions(section);
        });
      return visibleQuestionsBySection;
    }

    get visibleQuestionsHash(): Record<number, boolean> {
      return this.allQuestions.reduce((memo, q) => {
        return {
          ...memo,
          [q.id]: this.isCalculator(q)
            ? !this.isQuestionVisuallyHidden(q) && this.visibleQuestionsForThisValue.ids.includes(q.id)
            : !this.isQuestionVisuallyHidden(q),
        };
      }, {});
    }

    get visibleSectionGroupSectionIds() {
      return this.visibleSectionGroups.map((sg) => sg.map(({ id }) => id));
    }

    get visibleSectionGroups() {
      return this.sectionGroups.filter((sections) => this.sectionGroupVisuallyShown(sections));
    }

    get visibleSectionsHash(): Record<number, boolean> {
      return (this.subForm?.sub_form_sections || []).reduce((memo, section) => {
        return {
          ...memo,
          [section.id]: this.isSectionVisible(section),
        };
      }, {});
    }

    get visuallyHiddenSectionsHash(): Record<number, boolean> {
      return (this.subForm?.sub_form_sections || []).reduce((memo, section) => {
        return {
          ...memo,
          [section.id]: this.isSectionVisuallyHidden(section),
        };
      }, {});
    }

    @Emit('loaded')
    emitFormLoaded(): void {
      return;
    }

    @Emit('submit')
    submit(submitParams: FormSubmitParams): FormSubmitParams {
      return submitParams;
    }

    @Emit('update')
    update(): SubFormCompletionExtraParameters & {
      onUpdateComplete: () => void;
    } {
      const spsNewUserIds = this.newUserResponses();
      const { questions } = this.visibleQuestionsForThisValue;

      return {
        responses: {
          ...this.emptyFormValue(questions),
          ...this.dataById(questions, AUTO_SAVE_IGNORE_TYPES, spsNewUserIds),
        },
        onUpdateComplete: this.clearRelatedItems,
        form_uuid: this.formUuid,
        ...this.relatedItems,
      };
    }

    @Emit('update-field')
    updateField(input: { question: Question; value: ResponseValue }): {
      question: Question;
      value: ResponseValue;
    } {
      return input;
    }

    @Emit('input')
    updateInput(value: SubFormData) {
      return value;
    }

    @Emit('validation')
    validation(sectionsValidation: Record<string, boolean>): Record<string, boolean> {
      return sectionsValidation;
    }

    @Emit('update-loading-states')
    updateLoadingStates(id: number, loading: boolean) {
      this.loadingStates[id] = loading;
      return this.loadingStates;
    }

    @Watch('formChangeStatus', { deep: true })
    onFormChangeStatusChanged(formChangeStatus: { hasChanges: boolean; hasNewUnsavedResponse: boolean }) {
      this.$emit('form-has-changes', formChangeStatus);
    }

    @Watch('formLoaded')
    onFormLoadedChanged(completed: boolean) {
      if (completed) {
        this.emitFormLoaded();
      }
    }

    @Watch('value')
    onValueChanged(form: SubFormData) {
      const prevSectionGroup = this.activeSectionGroup;
      this.setVisibleQuestionsForForm(form);
      const currentSectionGroup = this.activeSectionGroup;

      // if prevSectionGroup and currentSectionGroup different and currentSectionGroup changed, adjust sections visibility accordingly
      this.updateSectionGroupVisibility(prevSectionGroup, currentSectionGroup);
    }

    calculatorQuestionProps(question: Question) {
      if (question.field_type !== FieldType.calculator) {
        return {};
      }

      return {
        blankValues: this.questionHandler?.calculationHandler.blankValues,
      };
    }

    clearRelatedItems(): void {
      this.relatedItems = {};
      toaster({ text: this.$t('app.labels.form_saved'), hideAfter: 1500 });
    }

    conflictingQuestionCodes(qs: Question[]) {
      return Object.keys(pickBy(groupBy(qs, 'code'), (sameCode) => sameCode.length > 1));
    }

    createQuestionUpdates(questions: Question[], state: boolean) {
      return questions.map<VisibilityState>((q) => ({
        code: q.code,
        id: q.id,
        system_code: q.system_code,
        state,
      }));
    }

    dataById(visibleQuestions: Question[], excludeFieldTypes?: FieldType[], excludeQuestionIds?: number[]): SubFormData {
      return pick(
        this.value,
        visibleQuestions
          .filter((q) => !excludeFieldTypes || excludeFieldTypes.indexOf(q.field_type) === -1)
          .filter((q) => !excludeQuestionIds || excludeQuestionIds.indexOf(q.id) === -1)
          .map((q) => q.id)
      );
    }

    emptyFormValue(visibleQuestions: Question[]): SubFormData {
      return (
        visibleQuestions
          // we don't need to set the empty value for table calculations
          .filter((q) => q.field_type !== FieldType.table_calculation)
          .reduce((memo, q) => {
            if (isArrayTypeQuestion(q)) {
              return { ...memo, [q.id]: [] };
            } else {
              return { ...memo, [q.id]: {} };
            }
          }, {})
      );
    }

    fieldUpdateBatched(question: Question, value: ResponseValue): void {
      this.updateQueue$.next({ question, value: cloneDeep(value) });
    }

    fillQuestionsIntoSections(subForm: Pick<SubForm, SubFormSfcFormOnly>) {
      if (subForm.sub_form_sections?.length) {
        const sections = groupBy(this.allQuestions || [], 'sub_form_section_id');

        subForm.sub_form_sections = subForm.sub_form_sections.map((section) => {
          return {
            ...section,
            sub_form_questions: sections[section.id] || [],
          } as SubFormSection;
        });
      }

      this.sectionGroups = this.getSectionGroups(subForm);
      this.updateQuestionKeys(this.allQuestions.map((q) => `${q.id}`));
      this.subFormReady = true;
    }

    filterSectionsWithGroupId(sections: SectionGroup): SectionGroup {
      return sections.filter((section) => section.sub_form_section_group_id !== null);
    }

    getCanAddAction(question: Question): boolean {
      return (
        !!this.record &&
        'permissions' in this.record &&
        !!this.record.permissions?.edit_access &&
        question.config.add?.action === 'true' &&
        this.currentUserStore.canCreateActivity
      );
    }

    getCanAddAnything(question: Question): boolean {
      return (
        !!this.showCanAdd &&
        !this.defaultTemplating &&
        (this.getCanAddAttachment(question) ||
          this.getCanAddModuleRecord(question) ||
          this.getCanAddAction(question) ||
          this.getCanCreateLinkedMainFormRecords(question))
      );
    }

    getCanAddAttachment(question: Question): boolean {
      return question.config.add?.attachment === 'true';
    }

    getCanAddModuleRecord(question: Question): boolean {
      return !!question.config.add?.module_records?.length;
    }

    getCanCreateLinkedMainFormRecords(question: Question): boolean {
      return (
        this.subForm?.module_name === MAIN_FORM_MODULE_NAME &&
        !!question.config?.add &&
        'linked_main_form_records' in question.config.add &&
        question.config.add?.linked_main_form_records === 'true'
      );
    }

    getQuestionUpdates(oldQuestions: Question[], newQuestions: Question[]) {
      const shownQuestions = differenceBy(oldQuestions, newQuestions, 'id');
      const hiddenQuestions = differenceBy(newQuestions, oldQuestions, 'id');

      return [...this.createQuestionUpdates(shownQuestions, false), ...this.createQuestionUpdates(hiddenQuestions, true)];
    }

    getQuestionsToUpdateViaApiRequest(
      shownQuestionIds: (number | string)[],
      updatedQuestionIds: (number | string)[],
      visibleQuestions: Question[]
    ): string[] {
      const shownHash = shownQuestionIds.reduce<Dictionary<boolean>>((memo, id) => ({ ...memo, [`${id}`]: true }), {});
      const updatedHash = updatedQuestionIds.reduce<Dictionary<boolean>>((memo, id) => ({ ...memo, [`${id}`]: true }), {});
      return visibleQuestions
        .filter((q) => q.config.mode === 'api_request')
        .reduce<string[]>((memo, q) => {
          const questionId = `${q.id}`;
          // if an api_request question become visible
          if (shownHash[questionId]) {
            return memo.concat(questionId);
          }
          const dependentQuestionCodes = q.config.api_request?.dependent_question_codes || [];
          // if an api_request question has dependants
          if (dependentQuestionCodes.length) {
            const dependentCode = dependentQuestionCodes.find((code) => {
              const qId = visibleQuestions.find((q) => q.code === code)?.id?.toString() as string;
              return (qId && shownHash[qId]) || updatedHash[qId];
            });
            // if one of the dependants was changed or become visible
            if (dependentCode) {
              return memo.concat(questionId);
            }
          }
          return memo;
        }, []);
    }

    getResponse(question: Question): Maybe<SubFormResponse> {
      return this.subFormCompletion.sub_form_responses?.find((r) => r.sub_form_question_id === question.id);
    }

    // leave only section group id and sub_form_section_ids
    getSectionGroups(subForm: Pick<SubForm, SubFormSfcFormOnly>): SectionGroup[] {
      const { sub_form_sections, sub_form_section_groups } = subForm;
      const orphanedSectionsAndGroups = sortBy(
        [...(sub_form_sections?.filter((s) => !s.sub_form_section_group_id) || []), ...(sub_form_section_groups || [])],
        'index'
      );

      const sectionGroups = orphanedSectionsAndGroups
        .map((s) => {
          return [
            ...('sub_form_section_group_id' in s ? [s] : []),
            ...('sub_form_section_ids' in s
              ? sortBy(sub_form_sections?.filter((ss) => ss.sub_form_section_group_id === s.id) || [], 'index')
              : []),
          ];
        })
        .filter((s) => s.length);

      if (this.enhanced) {
        return sectionGroups;
      }
      return flatten(sectionGroups).map((s) => [s]);
    }

    getVisibleGroupIndex(sectionId: number) {
      return this.visibleSectionGroupSectionIds.findIndex((sg) => sg.includes(sectionId));
    }

    goToNextSection(section: Pick<SubFormSection, SubFormSfsSfcFormOnly>, sectionIndex: number, sectionGroupIndex: number): void {
      const valid = this.isSectionValid(section);
      valid && this.goToSection(this.nextSection(section, sectionIndex, sectionGroupIndex));
    }

    goToPreviousSection(section: Pick<SubFormSection, SubFormSfsSfcFormOnly>, sectionIndex: number, sectionGroupIndex: number): void {
      this.goToSection(this.previousSection(section, sectionIndex, sectionGroupIndex));
    }

    goToSection(section?: Pick<SubFormSection, SubFormSfsSfcFormOnly>, initial = false): void {
      if (this.activeSection) {
        if (this.enhanced) {
          this.activeSectionGroup?.map((s) => s.id)?.forEach((id) => this.updateSectionVisibility(id, false));
        } else {
          this.updateSectionVisibility(this.activeSection.id, false);
        }
      }
      this.activeSection = section || null;
      if (section) {
        this.updateSectionVisibility(section.id, true);
        if (this.enhanced) {
          this.activeSectionGroup?.forEach((s) => this.updateSectionVisibility(s.id, true));
        }
      }

      this.$nextTick(() => {
        requestAnimationFrame(() => {
          if (section) {
            if (initial) {
              const scrollToElement = this.$el.querySelector(`.section-${section.id}`);
              scrollToElement && this.$scrollTo(scrollToElement, 400, DEFAULT_SCROLL_TO_OPTS(this.modal));
            } else {
              const index = flatten(this.visibleSectionGroups).findIndex((s) => s.id === section.id);
              const scrollToElement = (this.$refs?.subFormSection as Element[])?.[index];
              scrollToElement && this.$scrollTo(scrollToElement, 250, DEFAULT_SCROLL_TO_OPTS(this.modal));
            }
          }
        });
      });
    }

    goToSectionGroup(panel?: SectionGroup): void {
      panel && this.goToSection(panel[0]);
    }

    hasDependentLookupFields(questionCode: string): boolean {
      return this.allQuestions.some((q) => q.config?.mode === 'lookup' && q.config?.lookup?.related_record_question_code === questionCode);
    }

    hasNextSectionGroup(section: Pick<SubFormSection, SubFormSfsSfcFormOnly>) {
      return !!this.visibleSectionGroups?.[this.getVisibleGroupIndex(section.id) + 1];
    }

    hasPreviousSectionGroup(section: Pick<SubFormSection, SubFormSfsSfcFormOnly>) {
      return !!this.visibleSectionGroups?.[this.getVisibleGroupIndex(section.id) - 1];
    }

    hasSectionsToRender(sections: SectionGroup): boolean {
      return sections.some((s) => this.visibleSectionsHash[s.id] || this.calculatorHiddenSections[s.id]);
    }

    hideQuestionTitle(question: Question): boolean {
      const hiddenCalc = hiddenCalculation(question);
      return hiddenCalc || question.config.hide_question === 'true';
    }

    hideUnsavedIndication(question: Pick<SubFormQuestion, QuestionSfcOnly>): boolean {
      return (
        this.shouldAutoSave || !this.subFormCompletion?.id || (IGNORE_UNSAVED_FIELD_TYPES as FieldType[]).includes(question.field_type)
      );
    }

    invalidSectionGroup(sections: SectionGroup): boolean {
      return sections.some((s) => this.invalidState[s.id]?.length);
    }

    isCalculator(question: Question): boolean {
      return question.field_type === FieldType.calculator;
    }

    isLocked(question: Question): boolean {
      if (this.defaultTemplating) {
        return false;
      }
      if (this.isMainForm) {
        const recordWorkflow = this.record && 'workflow' in this.record ? this.record.workflow : undefined;
        const workflow = !this.subFormCompletion.id ? this.record?.module_name?.start_workflow : recordWorkflow;
        return this.isLockedByWorkflow(question, workflow);
      }

      const workflow = this.approvalForSubFormCompletion?.workflow || this.subFormCompletion.workflow;
      return this.isLockedByWorkflow(question, workflow) || this.isLockedBySubFormStage(question);
    }

    isLockedBySubFormStage(question: Question): boolean {
      const isDraft = this.subFormCompletion.stage === SubFormCompletionStage.Draft || !this.subFormCompletion.id;
      if (isDraft) {
        const draftLockedCodes = this.subForm?.options?.question_codes_locked_in_draft_state || [];
        return draftLockedCodes.includes(question.code);
      } else {
        const completeLockedCodes = this.subForm?.options?.question_codes_locked_in_complete_state || [];
        return completeLockedCodes.includes(question.code);
      }
    }

    isLockedByWorkflow(question: Question, workflow?: Workflow): boolean {
      return !!workflow?.locked_sub_form_question_ids?.includes(question.id);
    }

    isQuestionVisuallyHidden(question: Question): boolean {
      return !this.defaultTemplating && hiddenQuestion(question);
    }

    isReadonly(question: Question): boolean {
      if (this.defaultTemplating) {
        return false;
      }

      return question.config.use_type === 'readonly' || isReadonlyLookup(question);
    }

    isSectionValid(section: Pick<SubFormSection, SubFormSfsSfcFormOnly>): boolean {
      // TODO: validate everything at once
      const { ids } = this.visibleQuestionsForThisValue;
      const visibleQuestions = sortBy(section.sub_form_questions, 'index')?.filter((q) => ids.includes(q.id)) || [];
      // validate only visible questions
      const invalidElements = visibleQuestions.reduce((memo, q) => {
        return [
          ...memo,
          ...$(`form[data-uuid="${this.formUuid}"] .sub-form-question-block-${q.id} .question-for-${section.id}`)
            .toArray()
            .filter((el: HTMLElement) => $(el).parsley().validate() !== true), // should be `true`
        ];
      }, [] as HTMLElement[]);

      // some hack
      this.invalidState = {
        ...this.invalidState,
        [section.id]: invalidElements,
      };
      return !invalidElements.length;
    }

    isSectionVisible(section: Pick<SubFormSection, SubFormSfsSfcFormOnly>): boolean {
      const { ids } = this.visibleQuestionsForThisValue;
      return (section.sub_form_questions || [])?.some((q) => ids.includes(q.id));
    }

    isSectionVisuallyHidden(section: Pick<SubFormSection, SubFormSfsSfcFormOnly>): boolean {
      return !(section.sub_form_questions || [])?.some((q) => this.visibleQuestionsHash[q.id]);
    }

    isRequired(question: Question): boolean {
      return [FieldType.display_image, FieldType.display_text].indexOf(question.field_type) < 0 && question.required;
    }

    isHidden(question: Question): boolean {
      return this.questionHelper[question.id].isHidden;
    }

    mainFormLookupRequest(): void {
      const subFormId = this.subFormCompletion.sub_form_id;
      const readOnlyLookup = !!this.subFormCompletion.id;

      // if on the subform or editing the main form, lookup the current record values
      const selfLookupRecordId = this.subFormCompletion.record_id || this.record?.id;
      selfLookupRecordId &&
        this.observers.lookupRequest$.next({
          subFormId,
          lookupRecordId: selfLookupRecordId,
          questionCode: SELF_LOOKUP_KEY,
          type: 'module_record',
          readOnlyLookup,
        });

      if (this.subFormCompletion.record_id) {
        this.recordCompletion?.sub_form_responses
          .filter(
            (
              r
            ): r is SubFormResponse<
              | FieldType.main_form_relation
              | FieldType.sub_form_relation
              | FieldType.location
              | FieldType.single_person_selector
              | FieldType.organization
            > => !!LOOKUP_TYPE_MAPPING[r.sub_form_question_field_type]
          )
          .filter((r) => r.response.value)
          .forEach((r) => {
            const type = LOOKUP_TYPE_MAPPING[r.sub_form_question_field_type];
            if (!r.response.value) return;

            this.observers.lookupRequest$.next({
              subFormId,
              lookupRecordId: r.response.value as number | string,
              questionCode: `~${r.sub_form_question_code}`,
              type,
              readOnlyLookup,
            });
          });
      }
    }

    responseValueBy(questionId: number, questionType: FieldType, subFormData: SubFormData): Maybe<ResponseValue> {
      if (subFormData[questionId]) {
        return subFormData[questionId];
      }

      if (this.questionHelper[questionId]?.response?.response) {
        return this.questionHelper[questionId].response.response;
      }

      if (questionType === FieldType.main_form_relation) {
        return (this.mainFormId || this.params?.main_form_id) && { value: this.mainFormId || `${this.params?.main_form_id}` };
      }
    }

    lookupRequests(readOnlyLookup: boolean = false, subFormData: SubFormData): void {
      const subFormId = this.subFormCompletion.sub_form_id;
      const questionIds = Object.keys(subFormData);
      this.renderedSectionGroups.forEach((sections) => {
        sections.forEach((section) => {
          const questions = this.visibleQuestionsBySection[section.id];
          questions.forEach((q) => {
            const type = LOOKUP_TYPE_MAPPING[q.field_type];
            if (!type) {
              return;
            }

            const needToRequest = !questionIds.length || questionIds.includes(`${q.id}`);
            if (!needToRequest) {
              return this.$$debug(`[${q.id || q.code}] lookupRequests ignored - no need`);
            }

            const responseValue = this.responseValueBy(q.id, q.field_type, subFormData) as LookupResponseValue;
            const defaultValue = !subFormData ? (this.defaultValues?.[q.id] as LookupResponseValue) : undefined;
            if (!responseValue && !defaultValue) {
              return this.$$debug(`[${q.id || q.code}] lookupRequests ignored - no response value or default value`);
            }
            if (!this.shouldQuestionRender(q)) {
              return this.$$debug(`[${q.id || q.code}] lookupRequests ignored - should not be rendered`);
            }

            this.$$info(
              `[${q.id} | ${q.code}] lookupRequests`,
              type,
              questionIds,
              subFormData,
              this.observers.questionShowHideState$.value,
              this.visibleQuestionsForThisValue
            );
            this.observers.lookupRequest$.next({
              subFormId,
              lookupRecordId: responseValue?.value || defaultValue?.value || undefined,
              questionCode: q.code,
              type,
              readOnlyLookup,
            });
          });
        });
      });
    }

    mergeFormData(newData: SubFormData) {
      const newValue = { ...this.value, ...newData };
      this.updateInput(newValue);

      const { questions } = this.visibleQuestionsForThisValue;
      const newValueQuestions = this.questionHandler?.allQuestionsFilteredVisible(newValue) || [];

      const apiRequestQuestionIds = this.getQuestionsToUpdateViaApiRequest(
        differenceBy(questions, newValueQuestions, 'id').map(({ id }) => id),
        Object.keys(newData),
        newValueQuestions
      );

      this.observers.apiRequestQueue$.next(apiRequestQuestionIds);
    }

    moveSectionTo(
      section: Pick<SubFormSection, SubFormSfsSfcFormOnly>,
      sectionIndex: number,
      sectionGroupIndex: number,
      direction: 1 | -1
    ): Maybe<Pick<SubFormSection, SubFormSfsSfcFormOnly>> {
      if (!this.activeSectionGroup || !this.activeSection) {
        return;
      }
      if (this.activeSectionGroup[sectionIndex + direction]) {
        return this.activeSectionGroup[sectionIndex + direction];
      } else {
        const filteredSg = this.sectionGroups.filter((sg) => this.sectionGroupVisible(sg) && this.sectionGroupVisuallyShown(sg));
        const visibleGroupsSectionIds = filteredSg.map((sg) => sg.map(({ id }) => id));
        const visibleGroupIndex = visibleGroupsSectionIds.findIndex((sg) => sg.includes(section.id));

        return filteredSg[visibleGroupIndex + direction]?.[0];
      }
    }

    async multiQuestionApiRequest(questionIds: string[]) {
      const data = this.dataByCode;
      return this.$api.executeApiRequest({ data, sub_form_question_ids: questionIds }, { cache: true });
    }

    newUserResponses(): number[] {
      const { questions } = this.visibleQuestionsForThisValue;
      return questions
        .filter((q) => {
          const value = this.value[q.id];
          return q.field_type === FieldType.single_person_selector && !!value && 'user' in value && !!value.user;
        })
        .map((q) => q.id);
    }

    nextPanel(section: Pick<SubFormSection, SubFormSfsSfcFormOnly>): void {
      this.goToSectionGroup(this.visibleSectionGroups[this.getVisibleGroupIndex(section.id) + 1]);
    }

    nextSection(
      section: Pick<SubFormSection, SubFormSfsSfcFormOnly>,
      sectionIndex: number,
      sectionGroupIndex: number
    ): Maybe<Pick<SubFormSection, SubFormSfsSfcFormOnly>> {
      return this.moveSectionTo(section, sectionIndex, sectionGroupIndex, 1);
    }

    nextSectionExistAndVisible(sections: SectionGroup, sectionIndex: number): boolean {
      const nextSectionId = sections?.[sectionIndex + 1]?.id;
      return !!this.visibleSectionsHash?.[nextSectionId];
    }

    onUpdateRelated(question: Question, { key, item, mode }: { item: RelatedItem; key: RelatedKey; mode: 'add' | 'remove' }): void {
      const existingItems = [...(this.relatedItems[key]?.[question.id] || [])];
      const newItems = mode === 'add' ? [...existingItems, item] : existingItems.filter((i) => i === item);

      this.relatedItems = {
        ...this.relatedItems,
        [key]: {
          ...(this.relatedItems[key] || {}),
          [question.id]: newItems,
        },
      };

      this.update();
    }

    previousPanel(section: Pick<SubFormSection, SubFormSfsSfcFormOnly>): void {
      this.goToSectionGroup(this.visibleSectionGroups[this.getVisibleGroupIndex(section.id) - 1]);
    }

    previousSection(
      section: Pick<SubFormSection, SubFormSfsSfcFormOnly>,
      sectionIndex: number,
      sectionGroupIndex: number
    ): Maybe<Pick<SubFormSection, SubFormSfsSfcFormOnly>> {
      return this.moveSectionTo(section, sectionIndex, sectionGroupIndex, -1);
    }

    removeFromLoadingCountByKey({ id }: Question) {
      this.$delete(this.loadingCountByKey, id);
    }

    scrollOnInitialLoad() {
      (this.visibleSectionGroups?.[0] || []).forEach((s) => this.updateSectionVisibility(s.id, false));
      !!this.visibleSectionGroups?.[0]?.[0] && this.goToSection(this.visibleSectionGroups[0][0], this.initialScrollTo);
    }

    sectionCompletionCount(section: Pick<SubFormSection, SubFormSfsSfcFormOnly>): string {
      const { ids } = this.visibleQuestionsForThisValue;
      const visibleQuestions = this.visibleQuestions(section).filter(
        (q) =>
          ids.includes(q.id) &&
          !hiddenCalculation(q) &&
          !hiddenQuestion(q) &&
          ![FieldType.display_text, FieldType.display_image].includes(q.field_type)
      );
      const completed = visibleQuestions.filter((q) => isCompleted(q, this.value[q.id])).length;

      return `${completed} / ${visibleQuestions.length}`;
    }

    sectionGroupActive(sectionGroup: SectionGroup): boolean {
      return sectionGroup.some((s) => s.id === this.activeSection?.id);
    }

    sectionGroupVisible(sections: SectionGroup): boolean {
      const hasVisibleSection = this.sectionGroupVisuallyShown(sections);

      if (!hasVisibleSection) {
        return false;
      }
      if (!this.enhanced) {
        return true;
      }
      return sections.some((s) => s.id === this.activeSection?.id);
    }

    sectionGroupVisuallyShown(sections: SectionGroup): boolean {
      return sections.some((s) => this.visibleSectionsHash[s.id] && !this.visuallyHiddenSectionsHash[s.id]);
    }

    sectionIncomplete(section: Pick<SubFormSection, SubFormSfsSfcFormOnly>): boolean {
      const questions = section.sub_form_questions;
      if (questions) {
        for (const question of questions) {
          const { ids } = this.visibleQuestionsForThisValue;

          if (ids.includes(question.id) && question.required) {
            if (!isCompleted(question, this.value[question.id])) {
              return true;
            }
          }
        }
      }
      return false;
    }

    setVisibleQuestionsForForm(form: SubFormData) {
      const newQuestions = this.questionHandler?.allQuestionsFilteredVisible(form) || [];
      const oldQuestions = this.visibleQuestionsForThisValue.questions;
      const questionUpdates = this.getQuestionUpdates(oldQuestions, newQuestions);

      const ids = newQuestions.map((q) => q.id);
      this.visibleQuestionsForThisValue = { questions: newQuestions, ids };
      this.questionHandler?.calculationHandler.updateCalcValues(form);

      if (questionUpdates.length) {
        this.$nextTick(() => this.observers.visibilityStateUpdate$.next(questionUpdates));
      }
    }

    // TODO: render all calculator dependent questions regardless of the show/hide logic
    shouldQuestionRender(q: Question) {
      return this.isCalculator(q) || this.visibleQuestionsForThisValue.ids.includes(q.id);
    }

    shouldRenderCollapse(id: number) {
      // TODO: remove this once we no longer need to rely on other sections being rendered
      if (this.accountStore.data.schema !== 'targethospitality' || this.subForm?.id !== 157) return true;

      return this.sectionCollapses?.[id] || false;
    }

    showSubFormCompletionFormNavPanel(
      section: Pick<SubFormSection, SubFormSfsSfcFormOnly>,
      sections: SectionGroup,
      sectionIndex: number,
      groupIndex: number
    ): boolean {
      return (
        this.sectionCollapses[section.id] &&
        !this.nextSectionExistAndVisible(sections, sectionIndex) &&
        (!this.enhanced ||
          !this.nextSection(section, sectionIndex, groupIndex) ||
          this.canBeSavedAsDraft ||
          this.invalidSectionGroup(sections))
      );
    }

    startSubscriptions(): void {
      if (!this.isPublic) {
        this.lookupRequestSubscription = this.observers.lookupRequest$
          .pipe(filter(({ questionCode }) => !!this.subForm && this.hasDependentLookupFields(questionCode)))
          .subscribe(({ subFormId, lookupRecordId, questionCode, type, readOnlyLookup }) => {
            this.updateLoadingCountByKey({ id: `lookup_${questionCode}`, type: 'start' });
            this.$api
              .lookupRequest({
                lookup_record_id: lookupRecordId,
                lookup_type: type,
                read_only_lookup: readOnlyLookup,
                related_record_question_code: questionCode,
                sub_form_id: subFormId,
              })
              .then(({ data }) => {
                const questionIds = Object.keys(data.responses);
                this.updateLookupHistory(questionIds);
                this.initValues = { ...this.initValues, ...data.responses };
                this.mergeFormData(data.responses);
                questionIds.length && this.lookupRequests(data.read_only_lookup, data.responses);
                // TODO: try to remove force-reloading for questions
                this.updateQuestionKeys(questionIds);
                // TODO: update calculator fields
              })
              .finally(() => {
                this.updateLoadingCountByKey({ id: `lookup_${questionCode}`, type: 'stop' });
              });
          });
      }

      this.detailsRequestSubscription = this.observers.detailsRequest$.subscribe(([question, recordId]) => {
        const detailQuestions = this.allQuestions.filter(
          (q) => q.field_type === FieldType.detail && q.config.sub_form_question_system_code === question.system_code
        ) as SubFormQuestion<DetailQuestionOptions>[];
        const methods = detailQuestions.map((q) => q.config.method);
        methods.length &&
          this.$api
            .getDetails({ methods, record_id: Number(recordId), type: question.field_type }, { cache: true })
            .then(({ data }) => this.observers.detailsResponse$.next([question.system_code, data]));
      });

      this.apiRequestSubscription = handleApiRequest(
        this.observers.apiRequestQueue$,
        this.multiQuestionApiRequest,
        this.observers.apiRequestResult$
      );

      this.observers.visibilityStateUpdate$
        .pipe(
          scan((currentState, updates) => {
            const newState = { ...currentState };
            updates.forEach((update) => {
              newState[update.id] = update;
            });
            return newState;
          }, {} as Record<number, VisibilityState>)
        )
        .subscribe(this.observers.questionShowHideState$);
      this.observers.visibilityStateUpdate$.subscribe((visibilityUpdate) => this.debouncedTriggerLookupRequests(visibilityUpdate));
    }

    submitForm(
      stage: SubFormCompletionStage = SubFormCompletionStage.Draft,
      addAnother = false,
      inPlaceUpdate = false,
      event?: MouseEvent
    ): void {
      const { questions } = this.visibleQuestionsForThisValue;
      const responses = { ...this.emptyFormValue(questions), ...this.dataById(questions) };
      this.submit({
        responses,
        stage,
        addAnother,
        inPlaceUpdate,
        event,
        form_uuid: this.formUuid,
        ...this.relatedItems,
      });
    }

    unsavedIndicationAndResponse(question: Question) {
      if (this.shouldShowUnsavedResponses) {
        const hideUnsaved = this.hideUnsavedIndication(question);
        const response = this.getResponse(question);

        if (hideUnsaved) {
          return {
            newUnsavedResponse: false,
            unsaved: false,
            response,
          };
        } else {
          const currentValue = this.value[question.id];
          const responseEmpty = isResponseEmpty(question, currentValue);
          const newUnsavedResponse = !response && !responseEmpty;
          const previousValue = this.previousCompletionForm?.[question.id]?.response;

          return {
            newUnsavedResponse,
            unsaved: !areResponsesEqual(question, previousValue, currentValue),
            response,
          };
        }
      } else {
        return {
          newUnsavedResponse: false,
          unsaved: false,
          response: this.getResponse(question),
        };
      }
    }

    updateLoadingCountByKey({ id, type }: { id: string | number; type: 'start' | 'stop' }): void {
      const newValue = type === 'start' ? (this.loadingCountByKey[id] || 0) + 1 : (this.loadingCountByKey[id] || 0) - 1;
      this.$set(this.loadingCountByKey, id, newValue);
    }

    updateLookupHistory(questionIds: string[]): void {
      this.$nextTick(() => {
        const requestId = generateUUID();
        const newLookupHistory = questionIds.reduce((memo, id) => ({ ...memo, [id]: requestId }), {});
        this.lookupHistory = { ...this.lookupHistory, ...newLookupHistory };
      });
    }

    // TODO: remove this later.
    // It is for question rerender if the value changes
    // need to remove after full vuejs migration
    updateQuestionKeys(questionIds: string[]): void {
      this.$nextTick(() => {
        const newKeys = questionIds.reduce((memo, id) => ({ ...memo, [id]: generateUUID() }), {});
        this.questionKeys = { ...this.questionKeys, ...newKeys };
      });
    }

    updateSectionGroupVisibility(prevSectionGroup?: SectionGroup, currentSectionGroup?: SectionGroup) {
      // if prevSectionGroup or currentSectionGroup are empty or equal, do nothing
      if (!prevSectionGroup || !currentSectionGroup || isEqual(prevSectionGroup, currentSectionGroup)) return;

      const prevFilteredSections = this.filterSectionsWithGroupId(prevSectionGroup);
      const currentFilteredSections = this.filterSectionsWithGroupId(currentSectionGroup);

      const sectionComparator = (a: Pick<SubFormSection, SubFormSfsSfcFormOnly>, b: Pick<SubFormSection, SubFormSfsSfcFormOnly>) =>
        a.id === b.id;

      const newSections = differenceWith(currentFilteredSections, prevFilteredSections, sectionComparator);
      const disappearedSections = differenceWith(prevFilteredSections, currentFilteredSections, sectionComparator);

      newSections.forEach((section) => this.updateSectionVisibility(section.id, true));
      disappearedSections.forEach((section) => this.updateSectionVisibility(section.id, false));
    }

    updateSectionVisibility(sectionId: number, visible: boolean) {
      this.sectionCollapses = { ...this.sectionCollapses, [sectionId]: visible };
      this.$nextTick(() => visible && customDispatchEvent(document, SECTION_SHOWN_EVENT_NAME(sectionId)));
    }

    scrollToAndFocusOnFirstNonHiddenQuestion(sectionInvalidQuestions: HTMLElement[]) {
      const firstInvalidQuestion = sectionInvalidQuestions.find((q) => !q.closest('.question-block')?.classList.contains('hidden'));

      firstInvalidQuestion &&
        this.$scrollTo(firstInvalidQuestion.closest('.question-block'), 400, {
          ...DEFAULT_SCROLL_TO_OPTS(this.modal),
          onDone: () => !firstInvalidQuestion.hidden && firstInvalidQuestion.focus(),
        });
    }

    validateAndSubmit(addAnother = false, inPlaceUpdate = false, event?: MouseEvent): void {
      const invalidSections = flatten(this.sectionGroups).filter((section) => !this.isSectionValid(section));
      if (invalidSections[0]) {
        this.validation(invalidSections.reduce((m, s) => ({ ...m, [s.id]: true }), {}));
        this.goToSection(invalidSections[0]);
        this.scrollToAndFocusOnFirstNonHiddenQuestion(this.invalidState[invalidSections[0].id]);
      } else {
        this.validation({});
        this.debouncedSubmitForm(SubFormCompletionStage.Complete, addAnother, inPlaceUpdate, event);
      }
    }

    visibleQuestions(section: Pick<SubFormSection, SubFormSfsSfcFormOnly>): Question[] {
      return (section.sub_form_questions || [])
        .filter((question) => !(this.isPublic && question.config.hide_if_public === 'true'))
        .sort((a, b) => a.index - b.index);
    }

    triggerLookupRequests(visibilityUpdate: VisibilityStateUpdate) {
      const previousVisibleQuestionIds = this.prevVisibilityUpdate.filter((u) => u.state).map((u) => u.id);
      this.prevVisibilityUpdate = values(merge(keyBy(this.prevVisibilityUpdate, 'id'), keyBy(visibilityUpdate, 'id')));
      this.$nextTick(() => {
        const questionIds = visibilityUpdate.filter((u) => u.state && !previousVisibleQuestionIds.includes(u.id)).map((u) => u.id);
        const responses = questionIds.reduce((memo, id) => ({ ...memo, [id]: this.value[`${id}`] }), {});
        this.$$debug('visibility changes applied');
        questionIds.length && this.lookupRequests(!!this.subFormCompletion.id, responses);
      });
    }

    beforeMount(): void {
      this.formUuid = generateUUID();
      this.defaultValues = { ...this.value };

      const bufferedUpdates$ = this.updateQueue$.pipe(
        bufferTime(200),
        filter((updates) => !!updates.length) // TODO: is this required?
      );

      this.combinedStatesAndValuesSubscription = this.observers.visibilityStateUpdate$
        .pipe(concatMap((visibilityUpdate) => bufferedUpdates$.pipe(map((bufferedUpdates) => ({ visibilityUpdate, bufferedUpdates })))))
        .subscribe(({ visibilityUpdate, bufferedUpdates }) => {
          this.$$debug('combinedStatesAndValues received', visibilityUpdate, bufferedUpdates);

          const prevVisibilityUpdate = this.prevVisibilityUpdate || [];
          const prevBufferedUpdates = this.prevBufferedUpdates || [];

          if (!isEmpty(bufferedUpdates) && !isEqual(prevBufferedUpdates, bufferedUpdates)) {
            const mergedUpdates = bufferedUpdates.reduce((acc, { question, value }) => ({ ...acc, [question.id]: value }), {});
            this.mergeFormData(mergedUpdates);

            this.prevBufferedUpdates = bufferedUpdates;
            this.$nextTick(() => {
              bufferedUpdates.forEach(({ question, value }) => {
                this.updateField({ question, value });
              });
              this.update();
              this.$$debug('values changes applied');
            });
          } else {
            this.$$debug('skip values changes');
          }

          if (!isEmpty(visibilityUpdate) && !isEqual(prevVisibilityUpdate, visibilityUpdate)) {
            this.debouncedTriggerLookupRequests.cancel();
            this.triggerLookupRequests(visibilityUpdate);
          } else {
            this.$$debug('skip visibility changes');
          }
        });

      if (this.subFormCompletion.sub_form_id) {
        Promise.all([
          this.$api
            .getSubFormQuestions(
              {
                filters: { sub_form_section: { sub_form_id: this.subFormCompletion.sub_form_id }, active: true },
                include: ['attachments'],
                url_options: { public: this.isPublic || undefined },
                per_page: -1,
                only: QUESTION_SFC_ONLY,
              },
              { cache: true }
            )
            .then(({ data }) => {
              this.allQuestions = data;
            }),
          this.$api
            .getSubForm(
              this.subFormCompletion.sub_form_id,
              { only: SUB_FORM_SFC_FORM_ONLY, url_options: { expirable: this.isPublic } },
              { cache: true }
            )
            .then(({ data }) => {
              this.subForm = data;
            }),
        ]).then(() => {
          this.questionHandler = new QuestionHandler({
            questions: this.allQuestions,
            form: this.value,
            approvalForSubFormCompletion: this.approvalForSubFormCompletion,
            recordCompletion: this.recordCompletion,
            observers: this.observers,
          });
          this.setVisibleQuestionsForForm(this.value);
          this.subForm && this.fillQuestionsIntoSections(this.subForm);
          const recordId = this.record && 'id' in this.record ? this.record.id : null;
          !this.defaultTemplating && recordId && this.subscribeToUpdates(recordId, this.subFormCompletion, this.wizard, this.subForm);
          this.startSubscriptions();
          const initialQuestionsForApiRequest = this.allQuestions
            .filter(
              (q) =>
                q.config.mode === 'api_request' &&
                ((q.field_type !== FieldType.single_select && q.field_type !== FieldType.text) ||
                  q.config.api_request?.self_referencing !== 'true')
            )
            .map((q) => `${q.id}`);
          this.observers.apiRequestQueue$.next(initialQuestionsForApiRequest);
          !this.defaultTemplating && this.mainFormLookupRequest();
          this.scrollSubscription = interval(1)
            .pipe(
              filter(() => !this.loadingCountByKey[`lookup_${SELF_LOOKUP_KEY}`]),
              take(1)
            )
            .subscribe(this.scrollOnInitialLoad);
        });
      }

      if (this.subFormCompletion.sub_form_list_id) {
        this.$api
          .getSubFormList(this.subFormCompletion.sub_form_list_id, { only: SUB_FORM_LIST_ONLY }, { cache: true })
          .then(({ data }) => {
            this.subFormList = data;
          });
      }
    }
  }
