import { Component, Vue } from 'vue-property-decorator';
import type { SubFormCompletion } from '@app/models/sub-form-completion';
import type { SubFormData } from '@app/services/api/sub-form-completions-api';
import { pick } from 'lodash';
import type { ModuleName } from '@app/models/module-name';

@Component
export default class WithCompletionDefaultValues extends Vue {
  get isInitialAutoSave(): boolean {
    return this.$route.query.auto_save === 'true';
  }

  getDefaultValuesPromise(
    completion: Partial<SubFormCompletion>,
    opts?: {
      defaultTemplateId?: string;
      moduleName?: ModuleName;
      params?: {
        responses?: SubFormData;
      };
    }
  ) {
    const { params, defaultTemplateId, moduleName } = opts || {};
    if (!!defaultTemplateId && !!moduleName) {
      return this.$api
        .getDefaultTemplates({
          filters: {
            active: true,
            module_name_id: moduleName?.id,
            system_code: defaultTemplateId,
          },
        })
        .then(({ data }) => {
          return this.getDefaults(
            {
              ...completion,
              id: data[0]?.sub_form_completion_id || completion?.id,
              sub_form_id: moduleName.sub_form_id,
            },
            params?.responses
          );
        });
    } else {
      return this.getDefaults(completion, params?.responses);
    }
  }

  private getDefaults(completion: Partial<SubFormCompletion>, responses?: Record<string, unknown>) {
    return this.$api.getSubFormCompletionDefaultValues(
      {
        skip_current_completion: this.isInitialAutoSave,
        sub_form_completion_id: completion.id,
        ...pick(completion, ['sub_form_id', 'sub_form_list_id', 'approval_for_sub_form_completion_id', 'record_id', 'record_type']),
        responses,
      },
      { cache: false }
    );
  }
}
