import { snakeCase } from 'lodash';
import { ACTIVITY_CONCEPT } from '@app/constants';
import qs from 'qs';

export const entityPathPrefix = (entityType: string): string => {
  switch (entityType) {
    case 'Procedure':
      return 'kb';
    case ACTIVITY_CONCEPT:
      return 'actions';
    default:
      return `${snakeCase(entityType)}s`;
  }
};

export const moduleRecordPath = (id: number | string, query?: object): string =>
  `/${entityPathPrefix('ModuleRecord')}/${id}${qs.stringify(query, { addQueryPrefix: true })}`;
