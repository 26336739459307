import type { DetailsLink } from '../models';
import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { WorkflowLink } from '@app/models/workflow-link';

export class WorkflowLinkService extends BaseService<WorkflowLink> {
  static readonly allowedAttributes = ['from_id', 'id', 'to_id', 'user_collection_id', 'rule_set_id'];
  static readonly attributeServices: Partial<Record<keyof WorkflowLink, string>> = {
    from_id: 'Workflow',
    to_id: 'Workflow',
  };

  async fetchDetails(itemId: ServiceRequest<WorkflowLink>): Promise<DetailsServiceResponse> {
    let links: DetailsLink[] = [];
    const workflowLink = await this.fetchEntity(itemId);
    if (workflowLink.from) {
      links = [
        ...links,
        {
          prefix: this.t('app.labels.from_state'),
          title: workflowLink.from?.name || '',
          id: workflowLink.from_id,
        },
      ];
    }
    if (workflowLink.to) {
      links = [
        ...links,
        {
          prefix: this.t('app.labels.to_state'),
          title: workflowLink.to?.name || '',
          id: workflowLink.to_id,
        },
      ];
    }

    return { links };
  }

  async fetchEntity({ itemId }: ServiceRequest<WorkflowLink>): Promise<WorkflowLink> {
    const { data: workflowLink } = await this.api.getWorkflowLink(
      itemId,
      { only: ['id', 'from_id', 'to_id', { from: ['name'], to: ['name'] }], include: ['from', 'to'] },
      { join: true, cache: true }
    );
    return workflowLink;
  }
}
