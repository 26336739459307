
  import type { ModalListeners, ModalModes } from '@app/pages/module-records/utils';
  import { camelCase, omit, upperFirst } from 'lodash';
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import type DsModal from '@app/components/ds-modal.vue';

  @Component({
    components: {
      ModuleRecordShowModal: () => import(/* webpackChunkName: "modal-instance" */ './module-record-show-modal.vue'),
      ModuleRecordEditModal: () => import(/* webpackChunkName: "modal-instance" */ './module-record-edit-modal.vue'),
      SubFormCompletionShowModal: () =>
        import(/* webpackChunkName: "modal-instance" */ '../sub-form-completion/sub-form-completion-show-modal.vue'),
    },
  })
  export default class ModalInstance extends Vue {
    @Ref() readonly componentRef!: Vue & { modal: InstanceType<typeof DsModal> };

    @Prop(String) readonly mode!: ModalModes;
    @Prop(String) readonly title!: string;
    @Prop(Object) readonly listeners?: ModalListeners;
    @Prop(Number) readonly moduleNameId?: number;
    @Prop(Number) readonly mainFormId?: number;
    @Prop(Object) readonly relationships?: Record<string, number>;
    @Prop(String) readonly defaultTemplateId?: string;
    @Prop(Number) readonly recordId?: number;
    @Prop(Boolean) readonly showEdit?: boolean;
    @Prop(Boolean) readonly hideFooterLinks?: boolean;
    @Prop(Number) readonly subFormCompletionId?: number;

    show = true;

    get componentProps() {
      return omit(this.$props, ['listeners', 'mode']);
    }

    get componentName(): string {
      return `${upperFirst(camelCase(this.mode))}Modal`;
    }

    onComponentMounted() {
      this.componentRef?.modal?.$on('hide', () => {
        this.$destroy();
      });
    }
  }
