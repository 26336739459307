import type { UserLocationTag } from '@app/models/user-location-tag';
import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import { UserService } from './user-service';

export class UserLocationTagService extends BaseService<UserLocationTag> {
  static readonly allowedAttributes = ['location_tag_id', 'user_id', 'location_id'];
  static readonly attributeServices: Partial<Record<keyof UserLocationTag, string>> = {
    user_id: 'User',
    location_id: 'Location',
    location_tag_id: 'LocationTag',
  };

  async fetchDetails({ itemId, version }: ServiceRequest<UserLocationTag>): Promise<DetailsServiceResponse> {
    const userId = this.extractValue<number>('user_id', version);
    if (!userId) return {};

    const userService = new UserService(this.config);
    const userDetails = await userService.fetchDetailsById(userId);
    return { ...userDetails, subText: `ID: ${itemId}` };
  }
}
