
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import type { SignatureResult } from '@app/models/signature';

  import SignatureCanvas from './signature-canvas.vue';

  @Component({ components: { SignatureCanvas } })
  export default class SignaturePad extends Vue {
    @Prop(String) readonly alternativeText?: string;
    @Prop(String) readonly backgroundImageUrl?: string;
    @Prop({ type: String }) readonly canvasTitle!: string;
    @Prop() readonly clearLabel?: string;
    @Prop(String) readonly height!: string;
    @Prop(String) readonly width!: string;
    @Ref() readonly signatureCanvas?: SignatureCanvas;

    isEmpty = true;

    get clearButtonLabel(): string {
      return this.clearLabel || this.$t('app.labels.clear');
    }

    get wrapperStyles(): { height: string; width: string } {
      if (this.backgroundImageUrl) {
        return { width: '100%', height: '100%' };
      }
      return { width: this.width, height: this.height };
    }

    backgroundLoad(): void {
      this.signatureCanvas?.resizeCanvas();
    }

    clear(): void {
      if (!this.signatureCanvas) return;

      this.signatureCanvas.clearSignature();
      this.save(this.signatureCanvas.updateSignatureResult());
    }

    resize(): void {
      this.signatureCanvas?.resizeCanvas();
    }

    save(signatureResult: SignatureResult): void {
      this.isEmpty = signatureResult.isEmpty;
      this.$emit('input', signatureResult.data);
    }

    mounted(): void {
      $(document).on('donesafe:signature-visibility-changed', () => this.resize());
    }

    beforeDestroy(): void {
      $(document).off('donesafe:signature-visibility-changed');
    }
  }
