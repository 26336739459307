
  import ActivityForm from '@app/components/activities/activity-form.vue';
  import DsModal from '@app/components/ds-modal.vue';
  import { Component, Model, Prop, Ref, Vue } from 'vue-property-decorator';
  import type { Activity } from '@app/models/activity';

  @Component({ components: { ActivityForm, DsModal } })
  export default class ActivityFormModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Prop(Object) readonly activity?: Partial<Activity>;
    @Prop(Boolean) readonly disabled?: boolean;
    @Prop(Boolean) readonly hideCompletionSelector?: boolean;
    @Ref() readonly form?: ActivityForm;

    get title(): string {
      return this.activity?.id ? this.$t('app.labels.edit_action').toString() : this.$t('app.labels.add_new_action').toString();
    }

    get formSubmitting(): boolean {
      return !!this.form?.submitting;
    }

    initForm(): void {
      this.form?.reset();
    }
  }
