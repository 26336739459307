import type { ScoreBandProfile } from '@app/models/score-band-profile';
import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';

export class ScoreBandProfileService extends BaseService<ScoreBandProfile> {
  static readonly allowedAttributes = [
    'active',
    'code',
    'display_method',
    'id',
    'module_name_id',
    'name',
    'options',
    'source_uuid',
    'title',
    'uuid',
  ];

  async fetchDetails(request: ServiceRequest<ScoreBandProfile>): Promise<DetailsServiceResponse> {
    const profile = await this.fetchEntity(request);
    return {
      links: [
        {
          link: `/admin/settings/module_names/${profile.module_name_id}/scoring/score_bands/${profile.id}/edit`,
          prefix: this.t('app.labels.scoring_profile'),
          title: profile.name,
          id: profile.id,
        },
      ],
      mainText: `${this.t('app.labels.scoring_profile_code')}: [${profile.code}]`,
    };
  }

  async fetchEntity({ itemId }: ServiceRequest<ScoreBandProfile>): Promise<ScoreBandProfile> {
    const { data: profile } = await this.api.getScoreBandProfile(
      itemId,
      { only: ['id', 'name', 'code', 'module_name_id'] },
      { join: true, cache: true }
    );
    return profile;
  }

  entityToText({}: ServiceRequest<ScoreBandProfile>, entity?: ScoreBandProfile): string {
    return entity ? `${entity.name} [${entity.id}]` : '';
  }
}
