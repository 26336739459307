
  import { Component, Prop } from 'vue-property-decorator';
  import { filterHighlightText } from '../../utils';
  import BaseQuestionResponse from './base-question-response';

  @Component({
    filters: { highlightText: filterHighlightText },
  })
  export default class TextFieldResponse extends BaseQuestionResponse {
    @Prop(String) readonly displayText?: string;
  }
