import { render, staticRenderFns } from "./radio-field.vue?vue&type=template&id=02343a2a&scoped=true&lang=pug"
import script from "./radio-field.vue?vue&type=script&lang=ts"
export * from "./radio-field.vue?vue&type=script&lang=ts"
import style0 from "./radio-field.vue?vue&type=style&index=0&id=02343a2a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02343a2a",
  null
  
)

export default component.exports