import type { BaseEntity } from '@app/models/base-entity';
import type { SelectOption } from '@app/models/question-options/shared';
import type { SvgSelectorFieldValue } from '@app/models/question-response-types';
import { filter, isEmpty, isInteger, map, reduce } from 'lodash';
import type { ChangesResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import { LocationService } from './location-service';
import { ModuleRecordService } from './module-record-service';
import { OrganizationService } from './organization-service';
import { SubFormCompletionService } from './sub-form-completion-service';
import { SubFormQuestionService } from './sub-form-question-service';
import { UserService } from './user-service';
import type { Attachment } from '@app/models/attachment';
import type { Location } from '@app/models/location';
import type { ModuleRecord } from '@app/models/module-record';
import type { Organization } from '@app/models/organization';
import type { PaperTrailChange, PaperTrailVersion } from '@app/models/paper-trail-version';
import type { Signature } from '@app/models/signature';
import type { SubFormCompletion } from '@app/models/sub-form-completion';
import type { SubFormQuestion } from '@app/models/sub-form-question';
import type { SubFormResponse } from '@app/models/sub-form-response';
import type { TenantUser } from '@app/models/tenant-user';
import type { Workflow } from '@app/models/workflow';
import { BASE_MULTI_SELECT_FIELD_TYPES, BASE_SINGLE_SELECT_TYPES, FieldType } from '@app/models/sub-form-question';

export const HANDLED_FIELD_TYPES = [
  ...BASE_SINGLE_SELECT_TYPES,
  ...BASE_MULTI_SELECT_FIELD_TYPES,
  FieldType.address,
  FieldType.approval_state,
  FieldType.calculation_text,
  FieldType.calculator,
  FieldType.date,
  FieldType.datetime,
  FieldType.detail,
  FieldType.display_image,
  FieldType.display_text,
  FieldType.file_upload,
  FieldType.knowledge_base,
  FieldType.location,
  FieldType.main_form_relation,
  FieldType.matrix,
  FieldType.multi_main_form_relation,
  FieldType.multi_person_selector,
  FieldType.organization,
  FieldType.report,
  FieldType.signature,
  FieldType.single_person_selector,
  FieldType.sub_form_relation,
  FieldType.svg_selector,
  FieldType.text,
  FieldType.textarea,
  FieldType.video,
  FieldType.workflow_state,
  FieldType.expense_budget_category_select,
  FieldType.expense_budget_select,
  FieldType.expense_budget_category_uuid,
  FieldType.expense_budget_uuid,
];

type SingleSelectEntity = SelectOption & { score?: string };
interface MatrixEntity {
  outcome: string;
  score: string;
}
type NestedEntity =
  | SingleSelectEntity
  | SingleSelectEntity[]
  | MatrixEntity
  | BaseEntity<number | string>
  | BaseEntity<number | string>[]
  | string;

export class ResponseService extends BaseService<SubFormResponse, SubFormQuestion> {
  static isHandledType(fieldType?: FieldType): boolean {
    return !!fieldType && HANDLED_FIELD_TYPES.includes(fieldType);
  }
  async fetchEntity({ version }: ServiceRequest<SubFormResponse>): Promise<SubFormQuestion> {
    const subFormQuestionId = this.extractValue<number>('sub_form_question_id', version);
    if (!subFormQuestionId) throw Error('Required sub_form_question_id was missed');

    const fieldType = this.extractValue('sub_form_question_field_type', version) as FieldType;
    if (!fieldType) throw Error('Required sub_form_question_field_type was missed');

    const subFormService = new SubFormQuestionService(this.config);
    return await subFormService.fetchEntity({ itemId: subFormQuestionId, version });
  }

  // TODO: Please move these code to service based, SingleSelectService, MultiSelectService and etc
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async fetchNestedEntities(request: ServiceRequest<SubFormResponse> & { change: any }): Promise<Record<string, NestedEntity | undefined>> {
    const { itemId, version, change } = request;
    const fieldType = this.subFormQuestionFieldType(version);
    if (ResponseService.isHandledType(fieldType)) {
      if (this.isSingleSelectResponse(fieldType) && itemId) {
        const question = await this.fetchEntity({ itemId, version });
        const value = Object.values(question.options?.values || [])?.find((i) => i.key === change?.value);
        return { value };
      } else if (this.isMultiSelectResponse(fieldType) && change && itemId) {
        const question = await this.fetchEntity({ itemId, version });
        const values = reduce<PaperTrailChange, SingleSelectEntity[]>(
          change,
          (memo, value) => {
            const qv = Object.values(question.options?.values || [])?.find((i) => i.key === value);
            return qv ? [...memo, qv] : memo;
          },
          [] as SingleSelectEntity[]
        );
        return { values: values };
      } else if (this.isSignatureResponse(fieldType) && change) {
        const value = change as { value: string };
        if (value.value) {
          if (isInteger(Number(value.value))) {
            const { data: signature } = await this.api.getSignature(
              Number(value.value),
              { only: ['id', 'url', 'user_id', { user: ['id', 'full_name'] }] },
              { cache: true }
            );
            return { signature };
          } else {
            return { content: value.value };
          }
        }
      } else if (this.isWorkflowStateResponse(fieldType) && change) {
        const value = change as { value: string };
        if (isInteger(value.value)) {
          const { data: workflow } = await this.api.getWorkflow(Number(value.value), { only: ['id', 'name'] }, { cache: true, join: true });
          return { workflow };
        }
      } else if (this.isFileUpload(fieldType)) {
        const attachmentIds = filter(change || [], Boolean).map((s: string | number) => `${s}`);
        const attachments: { id: string }[] = reduce<string, string[]>(attachmentIds, (accum, id) => [...accum, id], []).map((id) => ({
          id: id,
        }));
        return { attachments };
      } else if (this.isSubFormRelation(fieldType) && change) {
        const question = await this.fetchEntity({ itemId, version });
        const value = change as { value: string };
        if (value.value) {
          const { data: completions } = await this.api.getSubFormCompletionsWithSharedFilters(
            {
              only: ['id', 'title'],
              filters: {
                sub_form_id: question.config.sub_form_id,
                sub_form_list_id: question.config.sub_form_list_id,
              },
            },
            'id',
            value.value,
            {
              cache: true,
            }
          );
          return { completion: completions[0] };
        }
      } else if (this.isUserResponse(fieldType) && change) {
        const value = change as { value: number };
        if (value.value) {
          const user = await UserService.fetchEntity(this.api, value.value);
          return { entity: user };
        }
      } else if (this.isOrganizationResponse(fieldType) && change) {
        const value = change as { value: string };
        if (value.value) {
          const organization = await new OrganizationService(this.config).fetchOrganization(value.value);
          return { entity: organization };
        }
      } else if (this.isLocationResponse(fieldType) && change) {
        const value = change as { value: string };
        if (value.value) {
          const location = await LocationService.fetchEntity(this.api, Number(value.value));
          return { entity: location };
        }
      } else if (this.isRecordResponse(fieldType) && change) {
        const value = change as { value: string };
        if (value.value) {
          const record = await new ModuleRecordService(this.config).fetchModuleRecord(value.value);
          return { entity: record };
        }
      } else if (this.isMultiRecordResponse(fieldType) && change) {
        const value = change as string[];
        const service = new ModuleRecordService(this.config);
        if (!isEmpty(value)) {
          const records = await Promise.all(map(value, (id) => service.fetchModuleRecord(id)));
          return { records };
        }
      } else if (this.isMultiUserResponse(fieldType) && change) {
        const value = change as number[];
        if (!isEmpty(value)) {
          const users = await Promise.all(map(value, (id) => UserService.fetchEntity(this.api, id)));
          return { users };
        }
      } else if (this.isSvgSelector(fieldType)) {
        return { fieldValue: change };
      } else if (this.isMatrixResponse(fieldType)) {
        return { matrix: change };
      }
    }

    return {};
  }

  nestedTexts(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request: ServiceRequest<SubFormResponse> & { change: any },
    entities: Record<string, NestedEntity | undefined>
  ): Record<string, string | string[]> {
    const { version, change } = request;
    const fieldType = this.subFormQuestionFieldType(version);
    if (ResponseService.isHandledType(fieldType)) {
      if (this.isTextResponse(fieldType) || this.isSimpleResponse(fieldType)) {
        const value = change as { value: string };
        if (value) {
          return { display: value.value == null ? '' : value.value };
        }
      } else if (this.isSingleSelectResponse(fieldType) && entities['value']) {
        const questionValue = entities['value'] as SingleSelectEntity;
        return {
          value: `${questionValue.value} (${questionValue.key})`,
        };
      } else if (this.isMultiSelectResponse(fieldType) && entities['values']) {
        const questionValues = entities['values'] as SingleSelectEntity[];
        return {
          values: questionValues.map((questionValue) => `${questionValue.value} (${questionValue.key})`),
        };
      } else if (this.isSignatureResponse(fieldType)) {
        if (entities['signature']) {
          const signature = entities['signature'] as Signature;
          return {
            signature: signature.user ? `Signed by: ${signature.user.full_name} [${signature.user.id}]` : '',
            content: '',
          };
        }
        if (entities['content']) {
          return { content: 'base64', signature: '' };
        }
      } else if (this.isWorkflowStateResponse(fieldType)) {
        const workflow = entities['workflow'] as Workflow;
        return {
          workflow: workflow ? workflow?.name || '' : change.value == null ? '' : `${change.value}`,
        };
      } else if (this.isMatrixResponse(fieldType)) {
        const value = change as MatrixEntity;

        const matrix: string = value?.score
          ? value.outcome
            ? this.t('app.labels.matrix_with_score_and_outcome', {
                score: value.score,
                outcome: value.outcome,
              })
            : this.t('app.labels.matrix_with_score', { score: value.score })
          : '';
        return { matrix: matrix as string };
      } else if (this.isFileUpload(fieldType)) {
        const attachments = entities['attachments'] as Attachment[];
        return {
          attachments: attachments.map((attachment) => `ID: ${attachment.id}`),
        };
      } else if (this.isReportField(fieldType)) {
        const response = change || {};
        const { end_date, start_date } = response;
        if (start_date && end_date) {
          return {
            report: `${start_date} -> ${end_date}`,
          };
        }
      } else if (this.isSubFormRelation(fieldType) && entities['completion']) {
        const completion = entities['completion'] as SubFormCompletion;
        return {
          completion: SubFormCompletionService.toText(completion),
        };
      } else if (this.isOrganizationResponse(fieldType) && entities['entity']) {
        const organization = entities['entity'] as Organization;
        return {
          entity: OrganizationService.toText(organization),
        };
      } else if (this.isUserResponse(fieldType) && entities['entity']) {
        const user = entities['entity'] as TenantUser;
        return {
          entity: UserService.toText(user),
        };
      } else if (this.isLocationResponse(fieldType) && entities['entity']) {
        const location = entities['entity'] as Location;
        return {
          entity: LocationService.toText(location),
        };
      } else if (this.isRecordResponse(fieldType) && entities['entity']) {
        const moduleRecord = entities['entity'] as ModuleRecord;
        return {
          entity: ModuleRecordService.toText(moduleRecord),
        };
      } else if (this.isMultiRecordResponse(fieldType) && entities['records']) {
        const moduleRecords = entities['records'] as ModuleRecord[];
        return {
          records: moduleRecords.map((moduleRecord) => ModuleRecordService.toText(moduleRecord)),
        };
      } else if (this.isMultiUserResponse(fieldType) && entities['users']) {
        const users = entities['users'] as TenantUser[];
        return {
          users: users.map((user) => UserService.toText(user)),
        };
      } else if (this.isSvgSelector(fieldType) && !isEmpty(entities['fieldValue'])) {
        return { fieldValue: (entities['fieldValue'] as SvgSelectorFieldValue).map((s) => s.value).join(', ') };
      }
    }
    return {};
  }

  async fetchChanges(
    key: keyof SubFormResponse,
    version: PaperTrailVersion<SubFormResponse>
  ): Promise<ChangesResponse<Partial<SubFormQuestion>>> {
    const entityChanges = await super.fetchChanges(key, version);
    const changes = this.changes(version);
    let records: Record<string, NestedEntity | undefined> = {};
    let texts: Record<string, string | string[]> = {};
    let beforeRecords: Record<string, NestedEntity | undefined> = {};
    let beforeTexts: Record<string, string | string[]> = {};
    const before = changes[key][0];
    if (before) {
      beforeRecords = await this.fetchNestedEntities({ itemId: version.item_id, version, change: before });
      beforeTexts = this.nestedTexts({ itemId: version.item_id, version, change: before }, beforeRecords);
    }
    const after = changes[key][1];
    if (after) {
      records = await this.fetchNestedEntities({ itemId: version.item_id, version, change: after });
      texts = this.nestedTexts({ itemId: version.item_id, version, change: after }, records);
    }
    const nonEmptyTexts = isEmpty(texts) ? beforeTexts : texts;
    const nestedChanges: ChangesResponse<BaseEntity> = Object.entries(nonEmptyTexts).reduce((accum, [key]) => {
      return {
        ...accum,
        [key]: {
          entities: [beforeRecords[key], records[key]],
          itemIds: [before, after],
          texts: [beforeTexts[key], texts[key]],
        },
      };
    }, {});

    if (isEmpty(nestedChanges)) return {};

    return { ...entityChanges, ...nestedChanges };
  }

  entityToText({}: ServiceRequest<SubFormResponse>, entity?: SubFormQuestion): string {
    return entity ? `${entity.title} [${entity.id}]` : '';
  }

  private subFormQuestionFieldType(version: PaperTrailVersion<SubFormResponse>): Maybe<FieldType> {
    if (version.item_type !== 'SubFormResponse') return;

    return this.extractValue<FieldType>('sub_form_question_field_type', version);
  }

  private isSimpleResponse(fieldType?: FieldType): boolean {
    return (
      !!fieldType &&
      [
        FieldType.expense_budget_select,
        FieldType.expense_budget_category_select,
        FieldType.expense_budget_category_uuid,
        FieldType.expense_budget_uuid,
        FieldType.address,
        FieldType.approval_state,
        FieldType.calculation_text,
        FieldType.calculator,
        FieldType.date,
        FieldType.datetime,
        FieldType.detail,
        FieldType.display_image,
        FieldType.display_text,
        FieldType.knowledge_base,
        FieldType.video,
      ].includes(fieldType)
    );
  }

  private isTextResponse(fieldType?: FieldType): boolean {
    return !!fieldType && [FieldType.text, FieldType.textarea].includes(fieldType);
  }

  private isSingleSelectResponse(fieldType?: FieldType): boolean {
    return !!fieldType && (BASE_SINGLE_SELECT_TYPES as FieldType[]).includes(fieldType);
  }

  private isMultiSelectResponse(fieldType?: FieldType): boolean {
    return !!fieldType && (BASE_MULTI_SELECT_FIELD_TYPES as FieldType[]).includes(fieldType);
  }

  private isSignatureResponse(fieldType?: FieldType): boolean {
    return fieldType === FieldType.signature;
  }

  private isWorkflowStateResponse(fieldType?: FieldType): boolean {
    return fieldType === FieldType.workflow_state;
  }

  private isOrganizationResponse(fieldType?: FieldType): boolean {
    return fieldType === FieldType.organization;
  }

  private isMatrixResponse(fieldType?: FieldType): boolean {
    return fieldType === FieldType.matrix;
  }

  private isLocationResponse(fieldType?: FieldType): boolean {
    return fieldType === FieldType.location;
  }

  private isSvgSelector(fieldType?: FieldType): boolean {
    return fieldType === FieldType.svg_selector;
  }

  private isSubFormRelation(fieldType?: FieldType): boolean {
    return fieldType === FieldType.sub_form_relation;
  }

  private isMultiUserResponse(fieldType?: FieldType): boolean {
    return fieldType === FieldType.multi_person_selector;
  }

  private isUserResponse(fieldType?: FieldType): boolean {
    return fieldType === FieldType.single_person_selector;
  }

  private isMultiRecordResponse(fieldType?: FieldType): boolean {
    return fieldType === FieldType.multi_main_form_relation;
  }

  private isRecordResponse(fieldType?: FieldType): boolean {
    return fieldType === FieldType.main_form_relation;
  }

  private isFileUpload(fieldType?: FieldType): boolean {
    return fieldType === FieldType.file_upload;
  }

  private isReportField(fieldType?: FieldType): boolean {
    return fieldType === FieldType.report;
  }
}
