
  import FilterErrors from '@app/components/admin/questions/filter-configurator/filter-errors.vue';
  import type { LocationFieldValue } from '@app/models/question-response-types';
  import type { DonesafeLocationsApiExtraOptions } from '@app/services/api/locations-api';
  import { hasQuestionInFilter } from '@app/utils/has-question-in-filter';
  import type { Subscription } from 'rxjs';
  import { filter } from 'rxjs/operators';
  import { Component, Emit } from 'vue-property-decorator';
  import type { FieldType } from '@app/models/sub-form-question';
  import type { Location } from '@app/models/location';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { convertInFormFilters } from '@app/utils/convert-in-form-filters';
  import { incompleteMandatoryFilters } from '@app/utils/mandatory-filters';

  import MultiLevelLocationSelector from '../location/multi-level-location-selector.vue';
  import LocationSelector from '../location/location-selector.vue';

  import BaseField from './base-field';

  @Component({ components: { FilterErrors, MultiLevelLocationSelector, LocationSelector } })
  export default class LocationField extends BaseField<FieldType.location> {
    localValue: LocationFieldValue = { value: '' };
    filterChangesSubscription: Subscription | null = null;

    get multiLevel(): boolean {
      return this.question.config.multi_level === 'true';
    }

    get bottomRequired(): boolean {
      return this.question.config.required_bottom === 'true';
    }

    get filters(): DonesafeFilterOptions<Location, DonesafeLocationsApiExtraOptions> {
      return { active: true, with_restrictions: this.question.config.scope_to_current_user === 'true' || undefined };
    }

    @Emit('input')
    updateValue(value?: string): LocationFieldValue {
      this.localValue = { value };
      this.$nextTick(() => {
        this.sendUpdate(this.localValue);
        this.detailFieldRequest(value);
      });
      this.updateDependingMapCenters(value);
      const $locationDependentField = $(`*[data-location-question="${this.question.id}"]`);
      $locationDependentField.val('').trigger('change');
      this.lookupRequest({ lookupRecordId: value });

      return this.localValue;
    }

    onOpen(): boolean {
      !this.defaultTemplating && this.setIncompleteFilters(incompleteMandatoryFilters(this.question.config.filters, this.getFilters()));
      return !this.incompleteFilters.length;
    }

    getFilters(): DonesafeFilterOptions<Location, DonesafeLocationsApiExtraOptions> {
      return {
        ...this.filters,
        ...convertInFormFilters<Location, DonesafeLocationsApiExtraOptions>(this.question.config.filters, {
          record: this.record,
          user: this.currentUserStore.data,
          getCurrentFormValueByQuestion: this.getCurrentFormValueByQuestion,
        }),
      };
    }

    updateDependingMapCenters(value?: string): void {
      if (value) {
        const $mapSelectorCenterFields = $(`*[data-center-question-code="${this.question.code}"]`);
        if ($mapSelectorCenterFields.length) {
          this.$api.getLocation(Number(value), { only: ['id', 'map_information'] }).then(({ data }) => {
            if (data.map_information?.longitude && data.map_information?.latitude) {
              const params = {
                lat: data.map_information.latitude,
                lng: data.map_information.longitude,
              };
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              $mapSelectorCenterFields.trigger({ type: 'map:center_update', params } as any);
            }
          });
        }
      }
    }

    beforeMount(): void {
      const value = this.value?.value || '';
      this.localValue = { value };
    }

    beforeDestroy(): void {
      this.filterChangesSubscription?.unsubscribe();
    }

    mounted(): void {
      this.detailFieldRequest(this.localValue.value);
      this.updateDependingMapCenters(this.localValue.value);

      this.sendUpdate(this.localValue, true);

      this.filterChangesSubscription = this.formObservers.fieldUpdate$
        .pipe(filter(([question]) => hasQuestionInFilter(question, this.question.config.filters)))
        .subscribe(() => this.clearIncompleteFilters());
    }
  }
