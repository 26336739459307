import DOMPurify from 'dompurify';
import type { DetailsLink } from '../models';
import { ResponseType } from '../models';
import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { SubFormQuestion } from '@app/models/sub-form-question';
import type { PaperTrailChange } from '@app/models/paper-trail-version';
import type { SubFormResponse } from '@app/models/sub-form-response';
import type { OnlyOptions } from '@app/services/donesafe-api-utils';

export class SubFormQuestionService extends BaseService<SubFormQuestion> {
  static readonly allowedAttributes = [
    'active',
    'code',
    'config',
    'field_type',
    'id',
    'index',
    'required',
    'sub_form_section_id',
    'supports_mfql',
    'system_code',
    'title',
    'uuid',
  ];

  async fetchDetails(itemId: ServiceRequest<SubFormQuestion>): Promise<DetailsServiceResponse> {
    const subFormQuestion = await this.fetchEntity(itemId);
    const mainText = `${this.t('app.labels.output_code')}: [${subFormQuestion.code}], ${this.t('app.labels.question_type')}: [${
      subFormQuestion.field_type
    }]`;
    let links: DetailsLink[] = [{ prefix: this.t('app.labels.question'), title: subFormQuestion.title, id: subFormQuestion.id }];
    const subForm = subFormQuestion.sub_form_section?.sub_form;
    if (subForm) {
      links = [
        ...links,
        {
          link: `/admin/settings/sub_forms/${subForm.id}`,
          prefix: this.t('app.labels.sub_form'),
          title: subForm.title,
          id: subForm.id,
        },
      ];
    }
    return {
      mainText,
      links,
    };
  }

  async fetchEntity({ itemId, version }: ServiceRequest<SubFormQuestion | SubFormResponse>): Promise<SubFormQuestion> {
    if (!itemId) throw Error(`ItemId could not be blank for ${version}`);

    const only: OnlyOptions<SubFormQuestion> = [
      'code',
      'field_type',
      'title',
      'id',
      'options',
      'config',
      { sub_form_section: { sub_form: ['id', 'title'] } },
    ];
    const include = ['sub_form_section', 'sub_form'];
    const { data: subFormQuestion } = await this.api.getSubFormQuestion(itemId, { only, include }, { join: true, cache: true });
    return subFormQuestion;
  }

  entityToText({}: ServiceRequest<SubFormQuestion>, entity?: SubFormQuestion): string {
    return entity ? `${entity.title} [${entity.id}]` : '';
  }

  normalizeText(change: Nullable<PaperTrailChange>, key: keyof SubFormQuestion): Nullable<string> {
    if (['question', 'description'].includes(key) && change) {
      return DOMPurify.sanitize(String(change));
    }
    return super.normalizeText(change, key);
  }

  responseType(key: keyof SubFormQuestion): ResponseType {
    if (['question', 'description'].includes(key)) return ResponseType.Description;

    return super.responseType(key);
  }
}
