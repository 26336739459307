import type { RecordCalculationSubFormList } from '@app/models/record-calculation-sub-form-list';
import { BaseService } from './base-service';

export class RecordCalculationSubFormListService extends BaseService<RecordCalculationSubFormList> {
  static serviceTitle = ($t: (key: string) => string): string => $t('app.labels.record_calculation_sub_form_list');
  static readonly allowedAttributes = ['record_calculation_id', 'sub_form_list_id'];
  static attributeNames($t: (key: string) => string): Partial<Record<keyof RecordCalculationSubFormList, string>> {
    return {
      record_calculation_id: $t('app.labels.record_calculation'),
      sub_form_list_id: $t('app.labels.tab_section'),
    };
  }
  static readonly attributeServices: Partial<Record<keyof RecordCalculationSubFormList, string>> = {
    record_calculation_id: 'RecordCalculation',
    sub_form_list_id: 'SubFormList',
  };
}
