import { render, staticRenderFns } from "./record-relation-field.vue?vue&type=template&id=ee7e8b26&scoped=true&lang=pug"
import script from "./record-relation-field.vue?vue&type=script&lang=ts"
export * from "./record-relation-field.vue?vue&type=script&lang=ts"
import style0 from "./record-relation-field.vue?vue&type=style&index=0&id=ee7e8b26&prod&lang=scss&scoped=true"
import style1 from "./record-relation-field.vue?vue&type=style&index=1&id=ee7e8b26&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee7e8b26",
  null
  
)

export default component.exports