import type { ConfiguratorFilter, DateRangeFilterValue } from '@app/models/configurator-filter';
import { ConfiguratorFilterSource } from '@app/models/configurator-filter';

const isIdPresentInFilter = (ids: number[] | string[], filter: ConfiguratorFilter | DateRangeFilterValue): boolean => {
  if (filter.source === ConfiguratorFilterSource.range) {
    return !!filter.value?.some((f) => isIdPresentInFilter(ids, f));
  }
  return filter.source === ConfiguratorFilterSource.question_id && ids.map((id) => `${id}`).includes(`${filter.value}`);
};

export const hasQuestionIdsInFilter = (ids: number[] | string[], filterConfigs: ConfiguratorFilter[] = []): boolean => {
  return filterConfigs.some((f) => isIdPresentInFilter(ids, f));
};
