
  import { Component, Model, Prop, Ref, Vue } from 'vue-property-decorator';
  import { Modal } from 'uiv';
  import { dispatchers } from '@app/services/courier';
  import { useAccountStore } from '@app/stores/account';
  import type { FocusTrap } from 'focus-trap';
  import { createFocusTrap } from 'focus-trap';

  @Component({ components: { Modal } })
  export default class DsModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Ref() readonly modal!: InstanceType<typeof Modal>;
    @Prop({ default: '' }) readonly title!: string;
    @Prop({ type: String }) readonly size?: 'lg' | 'sm';
    @Prop({ type: Boolean, default: true }) readonly backdrop!: boolean;
    @Prop({ type: Boolean, default: true }) readonly footer!: boolean;
    @Prop({ type: Boolean, default: true }) readonly header!: boolean;
    @Prop({ type: Boolean, default: true }) readonly dismissBtn!: boolean;
    @Prop() readonly cancelText?: string;
    @Prop({ default: 'default' }) readonly cancelType!: string;
    @Prop() readonly okText?: string;
    @Prop({ default: 'primary' }) readonly okType!: string;
    @Prop({ type: Number, default: 150 }) readonly transition!: number;
    @Prop({ type: Boolean, default: false }) readonly autoFocus!: boolean;
    @Prop({ type: Boolean, default: true }) readonly keyboard!: boolean;
    @Prop({ type: Boolean, default: false }) readonly appendToBody!: boolean;
    @Prop() readonly beforeClose!: Function;
    @Prop({ type: Number, default: 20 }) readonly zOffset!: number;
    @Prop({ type: String, default: 'block' }) readonly displayStyle!: string;
    @Prop({ type: Boolean, default: false }) readonly loading?: boolean;

    focusTrap: Nullable<FocusTrap> = null;

    get accountStore() {
      return useAccountStore();
    }

    handleShow() {
      this.accountStore.data?.one_hsi_ui && dispatchers.modal.dispatch(true);
      this.$emit('show');
      this.focusTrap = createFocusTrap(this.modal.$el, {
        fallbackFocus: this.modal.$el,
        clickOutsideDeactivates: true,
        initialFocus: false,
        escapeDeactivates: false,
      });
      this.focusTrap.activate();
    }

    handleHide(msg: string) {
      this.focusTrap?.deactivate();
      this.$emit('hide', msg);
    }

    toggle(value: boolean) {
      this.modal.toggle(value);
    }

    beforeDestroy() {
      this.focusTrap?.deactivate();
    }
  }
