import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import { SubFormQuestionService } from './sub-form-question-service';
import type { SubFormQuestion } from '@app/models/sub-form-question';
import type { SubFormResponse } from '@app/models/sub-form-response';

export class SubFormResponseService extends BaseService<SubFormResponse> {
  async fetchDetails({ itemId, version }: ServiceRequest<SubFormResponse>): Promise<DetailsServiceResponse> {
    const subFormQuestionId = this.extractValue<number>('sub_form_question_id', version);
    if (!subFormQuestionId) throw Error('Required sub_form_question_id was missed');

    const question = await this.fetchQuestion({ itemId: subFormQuestionId, version });
    const subText = `ID: ${itemId} [${this.t('app.labels.output_code')}: ${question.code}] [${this.t('app.labels.question_type')}: ${
      question.field_type
    }]`;
    const mainText = `${this.t('app.labels.question')}: ${question.title} [${question.id}]`;
    return {
      subText,
      mainText,
    };
  }

  async fetchQuestion(request: ServiceRequest<SubFormResponse>): Promise<SubFormQuestion> {
    if (!request.itemId) throw Error(`ItemId could not be blank for ${request.version}`);

    const subFormService = new SubFormQuestionService(this.config);
    return await subFormService.fetchEntity(request);
  }

  entityToText({}: ServiceRequest<SubFormResponse>, entity?: SubFormResponse): string {
    return entity ? `${entity.sub_form_question?.title} [${entity.sub_form_question?.id}]` : '';
  }
}
