import type { DefaultTemplate } from '@app/models/default-template';
import type {
  LocationFieldValue,
  MainFormRelationFieldValue,
  OrganizationFieldValue,
  SinglePersonSelectorFieldValue,
  SubFormRelationFieldValue,
} from '@app/models/question-response-types';
import type { SfcFormRecordOnly, WizardRecordOnly } from '@app/pages/module-records/utils';
import type { BaseRecord, ModuleRecord } from '@app/models/module-record';
import type { VisitorAppVisitCourseRecord } from '@app/models/visitor-app-visit-course-record';
import type { FirstLevelKeyLiterals } from '@app/utils/types/first-level-literals';
import { Tuple } from '@app/utils/types/tuple';

export const SUB_FORM_COMPLETION_SHOW_ONLY = Tuple([
  'id',
  'sub_form_id',
  'workflow',
  {
    sub_form_responses: [
      'id',
      'sub_form_completion_id',
      'sub_form_question_id',
      'sub_form_question_code',
      'sub_form_question_system_code',
      'sub_form_question_field_type',
      'print_value',
      'response',
      {
        sub_form_question: [
          'id',
          'index',
          'question',
          'description',
          'short_title',
          'field_type',
          'config',
          'active',
          'attachments',
          'sub_form_section_id',
          'required',
          'title',
          'code',
          'options',
          { sub_form_section: ['absolute_index', 'title'] },
        ],
      },
    ],
  },
] as const);
export type SubFormCompletionShowOnly = FirstLevelKeyLiterals<(typeof SUB_FORM_COMPLETION_SHOW_ONLY)[number]>;

export const WITH_SFC_SUBMIT_ONLY = Tuple([
  ...SUB_FORM_COMPLETION_SHOW_ONLY,
  'sub_form_list_id',
  'title',
  'stage',
  'record_id',
  'record_type',
] as const);
export type WithSfcSubmitOnly = FirstLevelKeyLiterals<(typeof WITH_SFC_SUBMIT_ONLY)[number]>;

export const QUESTION_SFC_ONLY = Tuple([
  'id',
  'index',
  'title',
  'code',
  'config',
  'options',
  'field_type',
  'system_code',
  'supports_mfql',
  'required',
  'question',
  'description',
  'sub_form_section_id',
  'attachments',
  'uuid',
] as const);
const SUB_FORM_SFS_SFC_FORM_ONLY = Tuple(['id', 'title', 'description', 'attachments', 'sub_form_section_group_id', 'index'] as const);
const SUB_FORM_SFSG_SFC_FORM_ONLY = Tuple(['id', 'index', 'sub_form_section_ids'] as const);

export const SUB_FORM_SFC_FORM_ONLY = Tuple([
  'id',
  'title',
  'description',
  'sub_form_type',
  'options',
  'module_name',
  'free_navigation',
  {
    sub_form_sections: SUB_FORM_SFS_SFC_FORM_ONLY,
    sub_form_section_groups: SUB_FORM_SFSG_SFC_FORM_ONLY,
  },
] as const);
export type SubFormSfcFormOnly = FirstLevelKeyLiterals<(typeof SUB_FORM_SFC_FORM_ONLY)[number]>;
export type SubFormSfsSfcFormOnly = FirstLevelKeyLiterals<(typeof SUB_FORM_SFS_SFC_FORM_ONLY)[number]> | 'sub_form_questions';
export type QuestionSfcOnly = FirstLevelKeyLiterals<(typeof QUESTION_SFC_ONLY)[number]>;

export type SubFormCompletionFormRecord =
  // module-record-form in 'edit' mode, quick-edit, sub-form-completion-page
  | Pick<ModuleRecord, SfcFormRecordOnly>
  // module-record-form in 'new' mode
  | (Pick<ModuleRecord, 'permissions' | 'module_name' | 'module_name_id'> & { id: undefined })
  // module-records-wizard-page
  | Pick<ModuleRecord, WizardRecordOnly>
  // legacy-wizard-page
  | Pick<BaseRecord, WizardRecordOnly>
  // course-loop-page
  | VisitorAppVisitCourseRecord
  // default templating
  | Pick<DefaultTemplate, 'id' | 'module_name_id' | 'module_name'>;

export const NORMAL_TAB_SFC_ONLY = Tuple([
  'closed_actions_count',
  'confidential',
  'id',
  'print_score',
  'record_id',
  'record_type',
  'score_band',
  'stage',
  'title',
  'total_actions_count',
  'workflow',
  'created_at',
  'sub_form_id',
  {
    display_user: ['id', 'full_name', 'avatar_url', 'first_name', 'last_name', 'can_be_opened'],
    approval_form: SUB_FORM_COMPLETION_SHOW_ONLY,
  },
] as const);
export type NormalTabSfcOnly = FirstLevelKeyLiterals<(typeof NORMAL_TAB_SFC_ONLY)[number]>;

// TODO: reuse once all tabs migrated
const NORMAL_TAB_RECORD_ONLY = Tuple(['id', 'display_timezone', 'location_id'] as const);
export type NormalTabRecordOnly = FirstLevelKeyLiterals<(typeof NORMAL_TAB_RECORD_ONLY)[number]>;

export const NORMAL_TAB_SFL_ONLY = Tuple([
  'add_background_color',
  'add_icon',
  'add_text',
  'allow_confidentiality',
  'approval_button_background_color',
  'approval_sub_form_id',
  'approval_button_icon',
  'approval_button_text',
  'auto_save',
  'display_actions_count',
  'display_sub_form_question_codes',
  'draft',
  'id',
  'module_tab_id',
  'sub_form_ids',
  'sub_form_list_type',
  'sub_forms',
  'title',
  'user_avatar_option',
  {
    module_name: ['id', 'show_options', 'display', 'name'],
    sub_forms: ['id', 'title', 'score_band_profile', 'scoring', 'scoring_view_option'],
  },
] as const);

export type NormalTabSflOnly = FirstLevelKeyLiterals<(typeof NORMAL_TAB_SFL_ONLY)[number]>;

export interface LookupRequest {
  autoSelectInProgress?: boolean;
  lookupRecordId?: string | number;
  readOnlyLookup?: boolean;
}

export type LookupResponseValue =
  | SinglePersonSelectorFieldValue
  | MainFormRelationFieldValue
  | LocationFieldValue
  | OrganizationFieldValue
  | SubFormRelationFieldValue;
