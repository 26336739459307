
  import { Component, Model, Prop, Ref } from 'vue-property-decorator';
  import { ToggleButton } from 'vue-js-toggle-button';
  import WithToggleButtonDomEvents from '@app/mixins/with-toggle-button-dom-events';

  @Component({ components: { ToggleButton } })
  export default class AutoSelectToggle extends WithToggleButtonDomEvents {
    @Ref() readonly fetchingError?: HTMLElement;
    @Model('input', { type: Boolean }) readonly value!: boolean;
    @Prop(Boolean) readonly disabled?: boolean;
    @Prop(Boolean) readonly fetching?: boolean;
    @Prop(String) readonly errorsContainerId!: string;
    @Prop(Number) readonly subFormSectionId!: number;
  }
