import type I18n from '@app/i18n';
import type { ActionPriority } from '@app/models/action-priority';

const formatPriorityOption =
  ({ $t, showColor = false }: { $t: typeof I18n.translate; showColor?: boolean }) =>
  (option?: ActionPriority): string | JQuery<HTMLElement> => {
    const displayTitle = (ap?: ActionPriority) => {
      if (!ap) return $t('app.labels.na');

      return ap.active ? ap.title : $t('app.labels.archived_name', { name: ap.title });
    };

    const title = displayTitle(option);

    return showColor
      ? $(`
            <span class="priority-option">
              ${
                option?.color
                  ? `<span title="${title}"
                       class="priority-option-color-bubble"
                       style="background-color: ${option.color}">
                 </span>`
                  : ''
              }
              <span class="m-l-xs">${title}</span>
            </span>
        `)
      : title;
  };

export default formatPriorityOption;
