
  import { Component } from 'vue-property-decorator';
  import BaseResponse from './base-response';
  import TextHighlight from 'vue-text-highlight';
  import { entityPathPrefix } from '@app/services/helpers';
  import type { ModuleEntity } from '@app/models/module-entity';

  @Component({ components: { TextHighlight } })
  export default class ActionableResponse extends BaseResponse<ModuleEntity & { type: string }> {
    get moduleRecordEditLink(): Maybe<string> {
      if (this.entity?.type && this.entity?.type !== 'Location') {
        return `/${entityPathPrefix(this.entity.type)}/${this.entity.id}`;
      }
    }
  }
