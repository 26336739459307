
  import { Component, Emit } from 'vue-property-decorator';
  import type { CompanyRegisterFieldValue } from '@app/models/question-response-types';
  import type { CompanyRegister } from '@app/models/company-register';
  import type { FieldType } from '@app/models/sub-form-question';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';

  import EntitySelector from '../entity-selector.vue';
  import CompanyRegisterForm from '../company-register/company-register-form.vue';

  import BaseField from './base-field';

  @Component({ components: { CompanyRegisterForm, EntitySelector } })
  export default class CompanyRegisterField extends BaseField<FieldType.company_register> {
    showForm = false;
    localValue: CompanyRegisterFieldValue = { value: '' };

    get filters(): DonesafeFilterOptions<CompanyRegister> {
      return { service_category_id: this.question.config.service_category_id || undefined };
    }

    get requiredFilters(): DonesafeFilterOptions<CompanyRegister, { record_type: string }> {
      return { record_type: 'CompanyRegister' };
    }

    @Emit('input')
    onCompanyRegisterChanged(companyRegister: Partial<CompanyRegister>): CompanyRegisterFieldValue {
      this.localValue = { company_register: companyRegister };
      this.sendUpdate(this.localValue);
      return this.localValue;
    }

    @Emit('input')
    updateValue(value?: string): CompanyRegisterFieldValue {
      this.localValue = { value };
      this.sendUpdate(this.localValue);
      return this.localValue;
    }

    toggleForm(value: boolean): void {
      this.showForm = value;
    }

    beforeMount(): void {
      this.localValue = this.value || { value: '' };
    }
  }
