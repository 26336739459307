
  import type { MultiSelectFieldValue } from '@app/models/question-response-types';
  import { Component, Emit } from 'vue-property-decorator';

  import MultiSelectField from './multi-select-field.vue';

  @Component({ components: {} })
  export default class MultiCheckboxField extends MultiSelectField {
    @Emit('input')
    onChange(value: string): MultiSelectFieldValue {
      this.localValue = this.isSelected(value) ? this.localValue.filter((v) => v !== value) : [...this.localValue, value];
      this.sendUpdate(this.localValue);
      return this.localValue;
    }

    isSelected(key: string): boolean {
      return this.localValue.indexOf(key) > -1;
    }
  }
