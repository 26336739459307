import I18n from '@app/i18n';
import type { Activity } from '@app/models/activity';
import { ActivityStatusCodes, ActivityState } from '@app/models/activity';
import type { FirstLevelKeyLiterals } from '@app/utils/types/first-level-literals';
import { Tuple } from '@app/utils/types/tuple';
import { SpecialPermissionName } from '@app/models/extended-permission';
import { useCurrentUserStore } from '@app/stores/currentUser';

const commonLiterals = Tuple([
  'action_priority_id',
  'approved_at',
  'approved',
  'assigned_approver_id',
  'can_approve',
  'can_edit',
  'closed_at',
  'comment',
  'date',
  'description',
  'id',
  'requires_approval',
  'require_completion_comment',
  'state',
  {
    approved_by: ['id', 'full_name'],
    assigned_approver: ['id', 'full_name'],
    assignee: ['id', 'full_name', 'given_timezone'],
    completed_by: ['id', 'full_name'],
    user: ['id', 'full_name'],
  },
] as const);

export const ACTIVITIES_LIST_ACTIVITY_ONLY = Tuple([...commonLiterals, 'status', 'user_id'] as const);

export const ACTIVITY_SHOW_MODAL_ACTIVITY_ONLY = Tuple([
  ...commonLiterals,
  'actionable_id',
  'actionable_type',
  'assignee_id',
  'call_to_action',
  'can_delete',
  'comments',
  'external_uuid',
  'related_to',
  'status',
  'sub_form_completion_id',
  {
    location: ['id', 'name'],
    sub_form_completion: ['id', 'title'],
  },
] as const);

export const ACTIVITIES_TABLE_ACTIVITY_ONLY = Tuple([
  ...commonLiterals,
  'actionable_id',
  'actionable_type',
  'assignee_id',
  'created_at',
  'external_uuid',
  'related_record_type',
  'related_to',
  'sub_form_completion_id',
  'sub_form_response_id',
  'status',
  {
    location: ['id', 'name'],
    sub_form_completion: ['id', 'title'],
    completed_by: ['id', 'full_name'],
  },
] as const);

export const VIEW_ACTIVITY_PAGE_ACTIVITY_ONLY = Tuple([
  ...commonLiterals,
  'actionable_id',
  'actionable_type',
  'call_to_action',
  'comments',
  'external_uuid',
  'related_to',
  'status',
  {
    location: ['id', 'name'],
    sub_form_completion: ['id', 'title'],
  },
] as const);

export type ViewActivityPageActivityOnly = FirstLevelKeyLiterals<(typeof VIEW_ACTIVITY_PAGE_ACTIVITY_ONLY)[number]>;
export type ActivityTableActivityOnly = FirstLevelKeyLiterals<(typeof ACTIVITIES_TABLE_ACTIVITY_ONLY)[number]>;
export type ActivityShowModalActivityOnly = FirstLevelKeyLiterals<(typeof ACTIVITY_SHOW_MODAL_ACTIVITY_ONLY)[number]>;
export type ActivitiesListActivityOnly = FirstLevelKeyLiterals<(typeof ACTIVITIES_LIST_ACTIVITY_ONLY)[number]>;

export type BaseActivityAllowedKeys = ActivitiesListActivityOnly &
  ActivityShowModalActivityOnly &
  ActivityTableActivityOnly &
  ViewActivityPageActivityOnly;

export const canEditContent = (user_id?: Activity['user_id']) => {
  return (
    useCurrentUserStore().data?.id === user_id || useCurrentUserStore().hasExtendedPermission(SpecialPermissionName.edit_activity_content)
  );
};

export const getActivityUiState = (state_code?: ActivityStatusCodes) => {
  if (!state_code) return {};

  switch (state_code) {
    case ActivityStatusCodes.CompletedPendingApproval:
      return {
        status: I18n.t('activerecord.models.activities.status.completed_pending_approval'),
        color: '#d6a532',
        state: ActivityState.Open,
      };
    case ActivityStatusCodes.OverduePendingApproval:
      return {
        status: I18n.t('activerecord.models.activities.status.overdue_pending_approval'),
        color: '#bd2626',
        state: ActivityState.Open,
      };
    case ActivityStatusCodes.ClosedApproved:
      return {
        status: I18n.t('activerecord.models.activities.status.closed_and_approved'),
        color: '#60bd73',
        state: ActivityState.Closed,
      };
    case ActivityStatusCodes.DueToday:
      return {
        status: I18n.t('activerecord.models.activities.status.due_today'),
        color: '#01566d',
        state: ActivityState.Open,
      };
    case ActivityStatusCodes.OverdueNotRequiringApproval:
      return {
        status: I18n.t('activerecord.models.activities.status.overdue'),
        color: '#bd2626',
        state: ActivityState.Open,
      };
    case ActivityStatusCodes.ClosedNotRequiringApproval:
      return {
        status: I18n.t('activerecord.models.activities.status.closed'),
        color: '#60bd73',
        state: ActivityState.Closed,
      };
    case ActivityStatusCodes.InProgress:
      return {
        status: I18n.t('activerecord.models.activities.status.open'),
        color: '#01566d',
        state: ActivityState.Open,
      };
  }
};
