
  import {
    convertToDatabaseValue,
    getCalculationTextDefaultValue,
    generateCalculationTextConfig,
    processValue,
    invalidBigJsFormat,
  } from '@app/services/calculation';
  import { debounce } from 'lodash';
  import { filter, map } from 'rxjs/operators';
  import { Tooltip } from 'uiv';
  import { Component } from 'vue-property-decorator';
  import type { CalculationTextFieldValue } from '@app/models/question-response-types';
  import type { FieldType } from '@app/models/sub-form-question';
  import type { CalculationTextQuestionOptions } from '@app/models/question-options/calculation-text-question-options';
  import { safeEvaluate } from '@app/utils/safe-evaluate';

  import BaseField from './base-field';

  @Component({ components: { Tooltip } })
  export default class CalculationTextField extends BaseField<FieldType.calculation_text> {
    debounceUpdateValue = debounce(this.updateValue, 1500);
    localValue: CalculationTextFieldValue = { value: '' };
    savedValue = '';
    showSavedValue = false;

    get apiRequestShouldOverride(): boolean {
      const mode = this.question.config.api_request?.data_replace_mode || 'suggest_on_edit';
      if (mode === 'suggest_on_edit') {
        return !this.completionId;
      }
      return mode === 'override';
    }

    get calculationTextConfig() {
      return generateCalculationTextConfig(this.question);
    }

    get completionId(): Maybe<number> {
      return this.completion?.id;
    }

    get currencySymbol(): string {
      return this.question.config.currency_symbol || '$';
    }

    get databaseValue(): string | number {
      return convertToDatabaseValue(
        this.localValue?.value === 0 ? '0' : this.localValue.value || '',
        this.question.config.format_validation,
        this.question.config.decimal_places
      );
    }

    get decimalPlaces(): number {
      return this.calculationTextConfig.decimalPlaces;
    }

    get dependentQuestionCodes(): string {
      return (this.question.config.api_request?.dependent_question_codes || []).join('|');
    }

    get inputStep(): number | null {
      if (!this.question.config.decimal_places || !this.parsleyValidNumber) {
        return null;
      }
      return Number(Math.pow(0.1, this.decimalPlaces).toFixed(this.decimalPlaces));
    }

    get inputType(): string {
      if (this.invalidNumberFormat) {
        return 'text';
      }

      if (this.parsleyValidNumber && !this.question.config.decimal_places) {
        return 'text';
      }
      return this.trimSpaces ? 'number' : 'text';
    }

    get integerOrParsleyValidNumber(): boolean {
      return this.calculationTextConfig.integerOrParsleyValidNumber;
    }

    get invalidNumberFormat() {
      return this.integerOrParsleyValidNumber && invalidBigJsFormat(this.localValue.value);
    }

    get isApiLookup(): boolean {
      return this.question.config.mode === 'api_lookup';
    }

    get isApiRequest(): boolean {
      return this.question.config.mode === 'api_request';
    }

    get isAuto(): boolean {
      return this.calculationTextConfig.isAuto;
    }

    get isCurrency(): boolean {
      return this.calculationTextConfig.isCurrency;
    }

    get isInteger(): boolean {
      return this.calculationTextConfig.isInteger;
    }

    get isNumber(): boolean {
      return this.calculationTextConfig.isNumber;
    }

    get isPercentage(): boolean {
      return this.calculationTextConfig.isPercentage;
    }

    get isText(): boolean {
      return this.calculationTextConfig.isText;
    }

    get needInputGroup(): boolean {
      return this.isCurrency || this.isPercentage;
    }

    get needRefreshButton(): boolean {
      return !!this.savedValue && this.savedValue !== this.localValue.value;
    }

    get parsleyValidNumber(): boolean {
      return this.calculationTextConfig.parsleyValidNumber;
    }

    get requireSelection(): boolean {
      return this.question.config.api_request?.require_selection === 'true';
    }

    get trimSpaces(): boolean {
      return this.calculationTextConfig.trimSpaces;
    }

    get validateUniq(): boolean {
      return !this.isPublic && this.question.config?.validate?.uniq === 'true';
    }

    get visibilityOverride(): CalculationTextQuestionOptions['visibility_override'] {
      return this.question.config.visibility_override;
    }

    // 1500: slow typer friendly
    applySavedValue(): void {
      this.updateValue(this.savedValue);
      this.savedValue = '';
      this.showSavedValue = false;
    }

    onChange(value?: string): void {
      this.debounceUpdateValue.cancel();
      this.updateValue(value);
    }

    onKeyup(value?: string): void {
      this.$emit('calculation-text-disable-form', true);
      this.debounceUpdateValue(value);
    }

    updateValue(value?: string, sendUpdate = true): void {
      const resultValue = processValue(value, this.calculationTextConfig);
      this.localValue = { value: resultValue };
      this.$emit('calculation-text-disable-form', false);
      if (sendUpdate) {
        this.sendUpdate({ value: this.databaseValue });
        this.$emit('input', { value: this.databaseValue });
      }
    }

    beforeMount(): void {
      const dbValue = this.value?.value == null ? '' : this.value?.value;
      this.updateValue(getCalculationTextDefaultValue(dbValue, this.calculationTextConfig), false);
    }

    mounted(): void {
      if (this.isApiRequest) {
        this.addSubscription(
          this.formObservers.apiRequestResult$
            .pipe(
              map((lookupResult) => lookupResult[this.question.id]),
              // distinctUntilChanged(),
              filter((result) => !!result)
            )
            .subscribe((result) => {
              const apiValue = safeEvaluate(result, this.question.config.api_request?.result_path)?.toString();
              if (this.localValue.value && !this.apiRequestShouldOverride) {
                this.savedValue = apiValue;
              } else {
                this.$nextTick(() => {
                  this.debounceUpdateValue(apiValue);
                });
              }
            })
        );
      }
      if (this.isApiLookup) {
        const lookupQuestionCode = this.question.config.api_lookup?.api_request_question_code;
        if (lookupQuestionCode) {
          this.addSubscription(
            this.formObservers.apiLookupResult$.subscribe((lookupResult) => {
              if (lookupQuestionCode in lookupResult) {
                const rawSelection = lookupResult[lookupQuestionCode];
                const value = rawSelection ? safeEvaluate(rawSelection, this.question.config.api_lookup?.result_path) : undefined;
                this.updateValue(value);
              }
            })
          );
        }
      }
      this.listenOnCodeValueUpdate((value) => this.debounceUpdateValue(`${value}`));
      this.sendUpdate({ value: this.databaseValue }, true);
      this.$emit('input', { value: this.databaseValue });
    }
  }
