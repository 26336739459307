import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { Role } from '@app/models/role';

export class RoleService extends BaseService<Role> {
  static readonly allowedAttributes = [
    'active',
    'can_create_contacts',
    'dashboard_id',
    'id',
    'line_approval_percentage',
    'managerial_hierarchy_access_level',
    'max_overall_approval_cost',
    'name',
    'organization_access',
    'overall_approval_percentage',
    'profile_permissions',
    'report_builder_access',
    'system_code',
    'user_directory_access',
    'user_personal_details_access',
    'dynamic_dashboard_access',
    'dynamic_dashboard_customisation',
  ];

  async fetchDetails({ itemId }: ServiceRequest<Role>): Promise<DetailsServiceResponse> {
    const { data: role } = await this.api.getRole(itemId, { only: ['id', 'name'] }, { join: true, cache: true });
    const links = [
      {
        link: `/admin/settings/roles/${role.id}/edit`,
        prefix: this.t('app.labels.role'),
        title: role.name,
        id: role.id,
      },
    ];
    return { links };
  }

  fetchEntity(itemId: ServiceRequest<Role>): Promise<Partial<Role>> {
    return this.api.getRole(itemId.itemId, { only: ['id', 'name'] }, { join: true, cache: true }).then(({ data }) => data);
  }

  entityToText({}: ServiceRequest, entity?: Role): string {
    return entity ? `${decodeURI(entity.name)} [${entity.id}]` : '';
  }
}
