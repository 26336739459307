
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { textColor } from '@app/utils/text-color';

  @Component({ components: {} })
  export default class DsLabel extends Vue {
    @Prop(String) size?: 'large';
    @Prop(String) text?: string;
    @Prop(String) color?: string;
    @Prop(String) textColor?: string;

    get labelStyle(): Record<string, string> {
      if (this.color) {
        return {
          'background-color': this.color,
          color: this.computedTextColor,
          ...(this.size === 'large' ? { 'font-size': '100%' } : {}),
        };
      }
      return {};
    }

    get computedTextColor(): string {
      return this.textColor || textColor(this.color);
    }
  }
