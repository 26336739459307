import { BaseService } from './base-service';
import type { SubFormListSubForm } from '@app/models/sub-form-list-sub-form';

export class SubFormListSubFormService extends BaseService<SubFormListSubForm> {
  static serviceTitle = ($t: (key: string) => string): string => $t('app.labels.sub_form_list_sub_form');
  static readonly allowedAttributes = ['sub_form_id', 'sub_form_list_id'];
  static attributeNames($t: (key: string) => string): Partial<Record<keyof SubFormListSubForm, string>> {
    return {
      sub_form_id: $t('app.labels.sub_form'),
      sub_form_list_id: $t('app.labels.tab_section'),
    };
  }
  static readonly attributeServices: Partial<Record<keyof SubFormListSubForm, string>> = {
    sub_form_id: 'SubForm',
    sub_form_list_id: 'SubFormList',
  };
}
