import type { Relationship } from '@app/models/relationship';
import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';

export class RelationshipService extends BaseService {
  async fetchDetails({ itemId }: ServiceRequest<Relationship>): Promise<DetailsServiceResponse> {
    const { data: relationship } = await this.api.getRelationship(itemId, { only: ['id', 'name', 'code'] }, { join: true, cache: true });
    return {
      links: [
        {
          prefix: this.t('app.labels.relationship'),
          title: relationship.name,
          id: relationship.id,
        },
      ],
      mainText: `${this.t('app.labels.relationship_code')}: [${relationship.code}]`,
    };
  }
}
