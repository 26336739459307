import { render, staticRenderFns } from "./scorm-course-completion.vue?vue&type=template&id=40ab9f76&scoped=true&lang=pug"
import script from "./scorm-course-completion.vue?vue&type=script&lang=ts"
export * from "./scorm-course-completion.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40ab9f76",
  null
  
)

export default component.exports