
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import type { TableCalculationRequestError } from '@app/models/question-options/table-calculation-question-options';

  @Component({ components: { DsModal } })
  export default class FetchProgressModal extends Vue {
    @Model('input') readonly value!: boolean;

    @Prop(Boolean) readonly loading!: boolean;
    @Prop(Array) readonly errors!: TableCalculationRequestError;

    get title() {
      return this.loading ? 'Please Wait: Fetching Data' : 'Fetch Data';
    }

    close() {
      this.$emit('input', false);
    }
  }
