
  import { Component } from 'vue-property-decorator';
  import BaseFieldDisplay from './base-field-display';
  import { mixins } from 'vue-class-component';
  import HighlightColours from '@app/mixins/highlight-colours';
  import type { SelectOption } from '@app/models/question-options/shared';
  import type { Dictionary } from '@app/models/dictionary';
  import type { FieldType, SubFormQuestion } from '@app/models/sub-form-question';
  import type { BaseSelectQuestionOptions } from '@app/models/question-options/base-select-question-options';
  import type { ColorViewModeQuestionOptions } from '@app/models/question-options/color-view-mode-question-options';

  type StyleClasses = {
    class: string | string[] | Dictionary<boolean>;
    style: Dictionary<string>;
  };

  @Component
  class BaseSelectResponseOutput extends BaseFieldDisplay<FieldType.single_select> {}
  export default class SingleSelectDisplay extends mixins(BaseSelectResponseOutput, HighlightColours) {
    get activeItem(): SelectOption | undefined {
      return this.optionForKey(this.question.options.values || {}, this.subFormResponse.response.value);
    }

    get historicalItem(): SelectOption | undefined {
      return this.optionForKey(this.question.options.historical_values || {}, this.subFormResponse.response.value);
    }

    get definedLabel(): string | undefined {
      return this.subFormResponse.response.label;
    }

    get item(): SelectOption | undefined {
      return this.activeItem || this.historicalItem;
    }

    get itemLabel(): string {
      if (!this.item) {
        return '';
      }

      return this.activeItem ? this.item.value : this.$t('app.labels.archived_name', { name: this.item.value }).toString();
    }

    styleAndClasses(
      mode: ColorViewModeQuestionOptions['color_view_mode'],
      question: SubFormQuestion<BaseSelectQuestionOptions & ColorViewModeQuestionOptions>,
      option?: SelectOption
    ): Maybe<StyleClasses> {
      if (this.isOptionHighlighted(question, 'read', option)) {
        const styles = this.textAndBackgroundStyles('read', question, option);
        return {
          style: { '--highlight-background': styles['background-color'], '--highlight-text': styles.color },
          class: 'highlighted-output',
        };
      }
    }
  }
