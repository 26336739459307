
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { Component, Model, Prop, Ref, Vue } from 'vue-property-decorator';
  import { flatten } from 'lodash';
  import { BaseTableCell } from './base-table';
  import { geoLocationPosition, markerIcon, ZERO_LOCATION_LITERAL } from '@app/services/location';
  import type { MarkerClusterer, Cluster } from '@googlemaps/markerclusterer';
  import type { QuestionSfcOnly } from './sub-form-completion/utils';
  import type { BaseRecord, ModuleRecord } from '@app/models/module-record';
  import type { Dictionary } from '@app/models/dictionary';
  import type { MapInformation } from '@app/models/map-information';
  import type { SubFormQuestion, QuestionTypeMap, FieldType } from '@app/models/sub-form-question';
  import type { ModuleName } from '@app/models/module-name';
  import type { Location } from '@app/models/location';
  import type { LatLng } from '@app/models/geocoder-result';
  import type { DonesafeFilterOptions, OnlyOptions } from '@app/services/donesafe-api-utils';
  import { valueInResponse } from '@app/utils/value-in-response';

  interface MapSelectorMarker {
    mapInformation: MapInformation;
    position: LatLng;
    record: ModuleRecord;
  }

  type SelectorTypeValue = number | string | undefined | number[] | string[];

  @Component({
    components: {
      BaseTableCell,
      ModuleRecordsTable: () => import(/* webpackChunkName: "module-records-table" */ './module-record/module-records-table.vue'),
    },
  })
  export default class MapRecordSelector extends Vue {
    @Prop(Object) readonly question!: Pick<SubFormQuestion<QuestionTypeMap<FieldType.main_form_relation>['options']>, QuestionSfcOnly>;
    @Prop(Object) readonly record?: BaseRecord;
    @Prop(Boolean) readonly defaultTemplating?: boolean;
    @Prop(Boolean) readonly multiple?: boolean;
    @Prop(String) readonly name!: string;
    @Prop(Boolean) readonly allowClear?: boolean;
    @Prop(Boolean) readonly readonly?: boolean;
    @Prop([Object, Function]) readonly filters?: DonesafeFilterOptions<ModuleRecord> | (() => DonesafeFilterOptions<ModuleRecord>);
    @Prop(Object) readonly requiredFilters!: DonesafeFilterOptions<ModuleRecord>;
    @Model('input') readonly value!: SelectorTypeValue;
    @Ref() readonly map!: google.maps.Map & { $mapPromise: Promise<google.maps.Map> };
    @Ref() readonly cluster!: { $clusterPromise: Promise<MarkerClusterer> };
    @Ref() readonly recordsList?: any; // eslint-disable-line @typescript-eslint/no-explicit-any

    center: LatLng = ZERO_LOCATION_LITERAL;
    centerCopy: LatLng = { ...this.center };
    infoOptions: { records?: ModuleRecord[] } = {};
    infoWindowOpen = false;
    infoWindowPosition = ZERO_LOCATION_LITERAL;

    zoom = 6;
    search = '';

    records: ModuleRecord[] = [];
    markers: MapSelectorMarker[] = [];
    tableOnlyOptions: OnlyOptions<ModuleRecord> = [
      'id',
      'uniq_id',
      'title',
      { location: ['id', 'name', { map_information: ['id', 'longitude', 'latitude'] }] },
    ];

    initialized = false;

    locationSourceType: 'record' | 'address' | 'location' = 'record';

    mapOptions = {
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
    };

    mapsEventListener: google.maps.MapsEventListener | null = null;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    onMarkerClick(marker: MapSelectorMarker): void {
      if (this.readonly) {
        return;
      }
      this.onSelect(marker.record.id);
      this.infoWindowOpen = false;
    }

    showInfoWindow(latLng: LatLng, records: ModuleRecord[]): void {
      this.infoWindowOpen = true;
      this.infoWindowPosition = latLng;
      this.infoOptions.records = records;
    }

    onSearchKeyup(): void {
      this.setCenter(this.centerCopy, true);
      this.refreshTable();
    }

    refreshTable(): void {
      this.recordsList?.table?.debounceUpdate();
    }

    onDataFetch(records: ModuleRecord[]): void {
      this.records = records;
      this.updateMarkers();
    }

    setMarkers(markers: MapSelectorMarker[]): void {
      this.markers = markers;
      if (markers.length) {
        const bounds = new google.maps.LatLngBounds();
        markers.forEach((marker) => bounds.extend(marker.position));
        this.map.$mapPromise.then((map) => map.fitBounds(bounds));
      } else {
        this.getInitialPosition().then((position) => {
          this.setCenter(position, true);
        });
      }
    }

    updateMarkers(): void {
      if (this.locationSourceType === 'record') {
        this.setMarkers(
          this.records
            .filter((r) => r.location?.map_information?.longitude && r.location?.map_information?.longitude)
            .map((record) => {
              const mapInformation = record.location?.map_information as MapInformation;
              return { position: { lat: mapInformation.latitude, lng: mapInformation.longitude }, record, mapInformation };
            })
        );
      } else if (this.locationSourceType === 'address') {
        const responseMapIdHash = this.records.reduce((memo, record) => {
          const mapResponse = record.sub_form_completion.sub_form_responses.find((r) => r.sub_form_question_code === this.locationSource);

          if (!!mapResponse?.response && 'map_information_id' in mapResponse.response && !!mapResponse.response.map_information_id) {
            return { ...memo, [mapResponse.response.map_information_id]: record };
          }
          return memo;
        }, {}) as Dictionary<ModuleRecord>;
        const mapInformationIds = Object.keys(responseMapIdHash).sort();
        if (mapInformationIds.length) {
          this.$api
            .getMapInformations({ filters: { id: mapInformationIds }, per_page: -1, only: ['id', 'latitude', 'longitude', 'full_address'] })
            .then(({ data }) => {
              this.setMarkers(
                data.map((mapInformation) => ({
                  position: { lat: mapInformation.latitude, lng: mapInformation.longitude },
                  mapInformation,
                  record: responseMapIdHash[mapInformation.id],
                }))
              );
            });
        } else {
          this.setMarkers([]);
        }
      } else if (this.locationSourceType === 'location') {
        const responseLocationIdHash = this.records.reduce((memo, record) => {
          const mapResponse = record.sub_form_completion.sub_form_responses.find((r) => r.sub_form_question_code === this.locationSource);
          const locationId = valueInResponse(mapResponse?.response) as string;
          if (!!locationId) {
            if (!memo[locationId]) {
              memo[locationId] = [];
            }
            memo[locationId].push(record);
          }
          return memo;
        }, {} as Dictionary<ModuleRecord[]>) as Dictionary<ModuleRecord[]>;
        const locationIds = Object.keys(responseLocationIdHash).sort();
        if (locationIds.length) {
          this.$api
            .getLocations({
              only: ['id', 'map_information'],
              filters: { id: locationIds },
              per_page: -1,
            })
            .then(({ data }) => {
              this.setMarkers(
                flatten(
                  data
                    .filter((location) => location.map_information?.longitude && location.map_information?.latitude)
                    .map((location) => {
                      const mapInformation = location.map_information as MapInformation;
                      return responseLocationIdHash[location.id].map((record) => {
                        return {
                          position: { lat: mapInformation.latitude, lng: mapInformation.longitude },
                          record,
                          mapInformation,
                        };
                      });
                    })
                )
              );
            });
        } else {
          this.setMarkers([]);
        }
      }
    }

    get sort(): string {
      return `distance|${this.locationSource}|${this.center.lng}|${this.center.lat}`;
    }

    getTableFilters(): DonesafeFilterOptions<ModuleRecord> {
      const filters = typeof this.filters === 'function' ? this.filters() : this.filters;
      return {
        ...this.requiredFilters,
        ...filters,
        search: this.search,
      };
    }

    onCenterChanged(latlng: google.maps.LatLng): void {
      this.setCenter(latlng.toJSON());
    }

    get indexOptions(): string[] {
      return ['title'];
    }

    setCenter(center: LatLng, forceUpdate = false): void {
      this.centerCopy = center;
      if (forceUpdate) {
        this.center = center;
      }
    }

    markerIcon(marker: MapSelectorMarker): string {
      return markerIcon(!!this.value && `${marker.record.id}` === `${this.value}`);
    }

    rowClass(record: ModuleRecord): string {
      return this.isValueSelected(record.id) ? 'row-create selectable-row' : 'selectable-row';
    }

    isValueSelected(value: number | string): boolean {
      if (!this.value) {
        return false;
      }
      if (this.multiple) {
        return (this.value as string[]).map((v) => v.toString()).indexOf(`${value}`) > -1;
      } else {
        return `${this.value}` === `${value}`;
      }
    }

    onRowClick(event: { data: ModuleRecord; event: MouseEvent; index: number }): void {
      if (!this.readonly) {
        this.onSelect(event.data.id);
      }
      const marker = this.markers.find((m) => (m as MapSelectorMarker).record.id === event.data.id);
      if (marker) {
        // TODO: stop using protected methods and start using typescript.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.cluster.$clusterPromise.then((clusterObject: any) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const cluster = clusterObject.clusters.find((c: any) => {
            const markers = c.markers;
            if (markers.length > 1) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              return markers.some((cm: any) => {
                const p = cm.position.toJSON();
                return p.lat === marker.position.lat && p.lng === marker.position.lng;
              });
            }
            return false;
          });
          if (cluster) {
            this.onClusterClicked({} as google.maps.MapMouseEvent, cluster, this.map);
          } else {
            this.infoWindowOpen = false;
            this.map.panTo(marker.position);
          }
        });
      }
    }

    onClusterClicked(event: google.maps.MapMouseEvent, cluster: Cluster, map: google.maps.Map): void {
      cluster.bounds && map.fitBounds(cluster.bounds);
      const clusterMarkers: google.maps.Marker[] = cluster.markers || [];
      const markers = this.markers.filter((m) =>
        clusterMarkers.find((marker) => {
          const p = marker.getPosition()?.toJSON();
          return p && p.lng === m.position.lng && p.lat === m.position.lat;
        })
      );
      this.showInfoWindow(
        cluster.position.toJSON(),
        markers.map((m) => m.record)
      );
    }

    onSelect(value: string | number): void {
      if (this.isValueSelected(value)) {
        if (this.multiple) {
          this.$emit(
            'input',
            (this.value as number[]).filter((v) => `${v}` !== `${value}`)
          );
        } else {
          this.allowClear && this.$emit('input', undefined);
        }
      } else {
        if (this.multiple) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          this.$emit('input', [...((this.value as any[]) || []), value]);
        } else {
          this.$emit('input', value);
        }
      }
    }

    getRecordLocation(recordId: number | string, locationSourceType: 'record' | 'address' | 'location'): Promise<LatLng> {
      switch (locationSourceType) {
        case 'record':
          return this.$api.getModuleRecord(Number(recordId), { only: ['id', { location: ['map_information'] }] }).then(({ data }) => {
            if (data.location?.map_information?.longitude && data.location?.map_information?.latitude) {
              return {
                lat: data.location.map_information.latitude,
                lng: data.location.map_information.longitude,
              };
            }
            return ZERO_LOCATION_LITERAL;
          });
        case 'address':
        case 'location':
          const only: OnlyOptions<ModuleRecord> = ['id', { sub_form_completion: ['sub_form_responses'] }];
          return this.$api.getModuleRecord(Number(recordId), { only }).then(({ data }) => {
            const response = data.sub_form_completion.sub_form_responses.find((r) => r.sub_form_question_code === this.locationSource);
            if (response) {
              if (locationSourceType === 'address') {
                if (response?.response && 'map_information_id' in response?.response && !!response?.response?.map_information_id) {
                  return this.$api.getMapInformation(response?.response?.map_information_id).then(({ data }) => {
                    return {
                      lat: data.latitude,
                      lng: data.longitude,
                    };
                  });
                }
              } else {
                const locationId = valueInResponse(response?.response) as string;
                if (!!locationId) {
                  return this.$api.getLocation(Number(locationId), { only: ['id', 'map_information'] }).then(({ data }) => {
                    if (data.map_information) {
                      return {
                        lat: data.map_information.latitude,
                        lng: data.map_information.longitude,
                      };
                    }
                    return ZERO_LOCATION_LITERAL;
                  });
                }
              }
            }
            return ZERO_LOCATION_LITERAL;
          });
      }
    }

    getInitialPosition(): Promise<LatLng> {
      if (this.value) {
        return this.getRecordLocation(this.value as string, this.locationSourceType);
      }
      switch (this.centerSource) {
        case 'location':
          if (this.record) {
            return this.getRecordLocation(this.record.id, 'record');
          }
          return Promise.resolve(ZERO_LOCATION_LITERAL);
        case 'home_location':
          if (this.currentUserStore.data?.home_location_id) {
            const only: OnlyOptions<Location> = ['id', 'map_information'];
            return this.$api.getLocation(this.currentUserStore.data.home_location_id, { only }).then(({ data }) => {
              if (data.map_information?.longitude && data.map_information?.latitude) {
                return {
                  lat: data.map_information.latitude,
                  lng: data.map_information.longitude,
                };
              }
              return ZERO_LOCATION_LITERAL;
            });
          }
          return Promise.resolve(ZERO_LOCATION_LITERAL);
        case 'geolocation':
          return geoLocationPosition({ silent: true, nullLocation: ZERO_LOCATION_LITERAL });
        default:
          return Promise.resolve(ZERO_LOCATION_LITERAL);
      }
    }

    get locationSource(): 'location' | string {
      return this.question.config?.map_location_source || 'location';
    }

    get centerSource(): 'location' | 'home_location' | 'geolocation' | string {
      return this.question.config?.map_center_source || 'geolocation';
    }

    showClearButton(value: string | number): boolean {
      return !!this.allowClear && this.isValueSelected(value);
    }

    init(): void {
      this.getInitialPosition().then((position) => {
        this.setCenter(position, true);
        this.initialized = true;
      });
    }

    mounted(): void {
      $(this.$refs.helperInput as Element).on('change', () => {
        this.onSelect($(this.$refs.helperInput as Element).val() as string);
        this.$nextTick(() => this.init());
      });

      // TODO: use some event bus later. this is referenced in location-field.vue and address-field.vue
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      $(this.$refs.centerHelperInput as Element).on('map:center_update', (e: any) => {
        const params = typeof e.params === 'string' ? JSON.parse(e.params) : e.params;
        this.setCenter(params, true);
      });
      if (this.locationSource !== 'location') {
        this.tableOnlyOptions = ['id', 'uniq_id', 'title', { sub_form_completion: ['sub_form_responses'] }];
        const only: OnlyOptions<ModuleName> = [
          'id',
          { main_form: [{ sub_form_sections: [{ sub_form_questions: ['code', 'field_type'] }] }] },
        ];
        this.$api
          .getModuleName(this.requiredFilters.module_name_id as number, { only })
          .then(({ data }) => {
            const sections = data.main_form?.sub_form_sections || [];
            const locationSourceQuestion = sections[0]?.sub_form_questions?.find((q) => q.code === this.locationSource);
            if (locationSourceQuestion) {
              this.locationSourceType = locationSourceQuestion.field_type as 'location' | 'address';
            }
          })
          .finally(() => {
            this.init();
          });
      } else {
        this.init();
      }
    }

    beforeDestroy(): void {
      this.mapsEventListener && google.maps.event.removeListener(this.mapsEventListener);
    }
  }
