
  import FilterErrors from '@app/components/admin/questions/filter-configurator/filter-errors.vue';
  import type { DonesafeOrganizationsApiExtraOptions } from '@app/services/api/organizations-api';
  import { hasQuestionInFilter } from '@app/utils/has-question-in-filter';
  import type { Subscription } from 'rxjs';
  import { filter } from 'rxjs/operators';
  import { Component, Emit } from 'vue-property-decorator';
  import type { OrganizationFieldValue } from '@app/models/question-response-types';
  import type { FieldType } from '@app/models/sub-form-question';
  import type { Location } from '@app/models/location';
  import type { Organization } from '@app/models/organization';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { convertInFormFilters } from '@app/utils/convert-in-form-filters';
  import { incompleteMandatoryFilters } from '@app/utils/mandatory-filters';

  import OrganizationSelector from '../organization/organization-selector.vue';
  import MultiLevelOrganizationSelector from '../organization/multi-level-organization-selector.vue';

  import BaseField from './base-field';

  @Component({ components: { FilterErrors, OrganizationSelector, MultiLevelOrganizationSelector } })
  export default class OrganizationField extends BaseField<FieldType.organization> {
    localValue: OrganizationFieldValue = { value: '' };
    filterChangesSubscription: Subscription | null = null;

    get multiLevel(): boolean {
      return this.question.config.multi_level === 'true';
    }

    get bottomRequired(): boolean {
      return this.question.config.required_bottom === 'true';
    }

    get filters(): DonesafeFilterOptions<Organization, DonesafeOrganizationsApiExtraOptions> {
      return {
        active: true,
        with_restrictions: this.question.config.scope_to_current_user === 'true' || undefined,
      };
    }

    get requiredFilters(): DonesafeFilterOptions<Organization> {
      switch (this.question.config.organization_type) {
        case 'no_cost_centre':
          return { cost_centre: false };
        case 'cost_centre':
          return { cost_centre: true };
        default:
          return {};
      }
    }

    @Emit('input')
    updateValue(value?: string): OrganizationFieldValue {
      this.localValue = { value };
      this.$nextTick(() => {
        this.sendUpdate(this.localValue);
        this.detailFieldRequest(value);
      });
      this.lookupRequest({ lookupRecordId: value });

      return this.localValue;
    }

    onOpen(): boolean {
      !this.defaultTemplating && this.setIncompleteFilters(incompleteMandatoryFilters(this.question.config.filters, this.getFilters()));
      return !this.incompleteFilters.length;
    }

    getFilters(): DonesafeFilterOptions<Location, DonesafeOrganizationsApiExtraOptions> {
      return {
        ...this.filters,
        ...convertInFormFilters<Location, DonesafeOrganizationsApiExtraOptions>(this.question.config.filters, {
          record: this.record,
          user: this.currentUserStore.data,
          getCurrentFormValueByQuestion: this.getCurrentFormValueByQuestion,
        }),
      };
    }

    beforeMount(): void {
      const value = this.value?.value || '';
      this.localValue = { value };
    }

    beforeDestroy(): void {
      this.filterChangesSubscription?.unsubscribe();
    }

    mounted(): void {
      this.detailFieldRequest(this.localValue?.value);

      this.sendUpdate(this.localValue, true);

      this.filterChangesSubscription = this.formObservers.fieldUpdate$
        .pipe(filter(([question]) => hasQuestionInFilter(question, this.question.config.filters)))
        .subscribe(() => this.clearIncompleteFilters());
    }
  }
