
  import { last } from 'lodash';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { Attachment } from '@app/models/attachment';
  import { AvScanStatus } from '@app/models/attachment';

  @Component({ components: {} })
  export default class ThumbnailWrapper extends Vue {
    @Prop(Object) attachment!: Pick<
      Attachment,
      'is_image' | 'extension' | 'file_name' | 'url' | 'web_thumbnail_url' | 'metadata' | 'is_previewable' | 'is_variable'
    > & { blob?: Blob };

    get extension(): string | undefined {
      return this.attachment.extension || this.attachment.file_name?.includes('.') ? last(this.attachment.file_name?.split('.')) : '';
    }

    get previewStyle(): string {
      return this.showPreview
        ? `background-position: center; background-image: url("${this.attachment.web_thumbnail_url || this.attachmentUrl}")!important`
        : '';
    }

    get showPreview(): boolean {
      return this.isRepresentable && !this.isInfected && !!this.attachmentUrl;
    }

    get attachmentUrl(): string | undefined {
      if (this.attachment.url) {
        return this.attachment.url;
      } else if (this.attachment.blob) {
        const urlCreator = window.URL || window.webkitURL;
        return urlCreator?.createObjectURL(this.attachment.blob);
      }
    }

    get passwordProtected(): boolean {
      return this.attachment.metadata.password_protected;
    }

    get isRepresentable(): boolean {
      return this.attachment.is_variable || this.attachment.is_previewable;
    }

    get isInfected(): boolean {
      return this.attachment.metadata.av_scan_status === AvScanStatus.virus_detected;
    }
  }
