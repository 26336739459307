import type { RecordCalculationSubForm } from '@app/models/record-calculation-sub-form';
import { BaseService } from './base-service';

export class RecordCalculationSubFormService extends BaseService<RecordCalculationSubForm> {
  static serviceTitle = ($t: (key: string) => string): string => $t('app.labels.record_calculation_sub_form');
  static readonly allowedAttributes = ['record_calculation_id', 'sub_form_id'];
  static attributeNames($t: (key: string) => string): Partial<Record<keyof RecordCalculationSubForm, string>> {
    return {
      record_calculation_id: $t('app.labels.record_calculation'),
      sub_form_id: $t('app.labels.tab_section'),
    };
  }
  static readonly attributeServices: Partial<Record<keyof RecordCalculationSubForm, string>> = {
    record_calculation_id: 'RecordCalculation',
    sub_form_id: 'SubForm',
  };
}
