import type { BaseEntity } from '@app/models/base-entity';

import I18n from '../i18n';

import { select2ResponseTemplate } from './select2-response-template';
import type { Select2Placeholder } from './types/select2-placeholder';

export function inactiveTitleTemplate<T extends BaseEntity<number | string> & { active?: boolean }, F extends keyof T = keyof T>(
  entity: Pick<T, 'id' | F | 'active'>,
  field: F = 'name' as F,
  translationKey: string = 'app.labels.name_inactive'
): JQuery {
  return select2ResponseTemplate(entity, {
    primaryAttribute: (entity: Pick<T, 'id' | F | 'active'> | Select2Placeholder) => {
      if ('active' in entity && field in entity) {
        return entity.active ? entity[field] : I18n.t(translationKey, { [field]: entity[field] });
      } else {
        return 'text' in entity ? entity.text : '';
      }
    },
  });
}
