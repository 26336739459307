
  import { Component, Emit } from 'vue-property-decorator';
  import { ToggleButton } from 'vue-js-toggle-button';
  import type { ToocsFieldValue } from '@app/models/question-response-types';
  import type { FieldType } from '@app/models/sub-form-question';
  import { mixins } from 'vue-class-component';
  import WithToggleButtonDomEvents from '@app/mixins/with-toggle-button-dom-events';

  import ToocsPicker from '../toocs/toocs-picker.vue';

  import BaseField from './base-field';

  @Component({ components: { ToocsPicker, ToggleButton } })
  export default class ToocsField extends mixins(BaseField<FieldType.toocs>, WithToggleButtonDomEvents) {
    quickMode = false;

    @Emit('input')
    updateValue(value: ToocsFieldValue): ToocsFieldValue {
      this.sendUpdate(value);
      return value;
    }

    onQuickModeChanged(value: boolean): void {
      localStorage.setItem('toocs-field-quick-select', String(value));
    }

    beforeMount(): void {
      const quickSelect = localStorage.getItem('toocs-field-quick-select');
      if (quickSelect) {
        this.quickMode = JSON.parse(quickSelect);
      }
    }
  }
