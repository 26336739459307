import {
  CalculationTextDecimalHandlingBehaviour,
  CalculationTextFormatValidation,
} from '@app/models/question-options/calculation-text-question-options';
import type { CalculationSelectFieldValue, CalculationTextFieldValue } from '@app/models/question-response-types';
import type { FieldType, QuestionTypeMap, SubFormQuestion } from '@app/models/sub-form-question';
import Big from 'big.js';

export const roundDecimalPlaces = (
  numberString: number | string,
  decimalPlaces: number,
  decimalHandlingBehaviour?: CalculationTextDecimalHandlingBehaviour
): string => {
  if (!numberString) return '';
  if (invalidBigJsFormat(numberString)) return numberString.toString();

  let convertedNumber;
  const trimmedNumberString = `${numberString}`.replace(/\s/g, '');
  const roundingMethod = CalculationTextDecimalHandlingBehaviour.Round === decimalHandlingBehaviour ? Big.roundHalfUp : Big.roundDown;
  const roundedNumber = Big(trimmedNumberString).round(decimalPlaces, roundingMethod).toString();
  const decimalIndex = roundedNumber.indexOf('.');
  if (decimalIndex === -1) {
    convertedNumber = roundedNumber + '.' + Array(decimalPlaces + 1).join('0');
  } else {
    const digitsAfterDecimal = roundedNumber.length - (decimalIndex + 1);
    convertedNumber = roundedNumber + Array(decimalPlaces - digitsAfterDecimal + 1).join('0');
  }
  return Big(convertedNumber).toString(); // toString to remove trailing 0
};

export const convertToDatabaseValue = (
  displayValue: string | number,
  formatValidation: CalculationTextFormatValidation,
  decimalPlacesString?: string,
  decimalHandlingBehaviour?: CalculationTextDecimalHandlingBehaviour
): string | number => {
  let databaseValue = displayValue;

  switch (formatValidation) {
    case CalculationTextFormatValidation.percentage:
      if (databaseValue) {
        databaseValue = invalidBigJsFormat(databaseValue) ? databaseValue : Big(databaseValue).div(Big(100)).toString();
        if (displayValue && decimalPlacesString) {
          databaseValue = roundDecimalPlaces(databaseValue, parseInt(decimalPlacesString) + 2, decimalHandlingBehaviour);
        }
      }
      break;
    case CalculationTextFormatValidation.text:
    case CalculationTextFormatValidation.number:
    case CalculationTextFormatValidation.currency:
      if (displayValue && decimalPlacesString) {
        databaseValue = roundDecimalPlaces(databaseValue, parseInt(decimalPlacesString), decimalHandlingBehaviour);
      }
      break;
  }

  return databaseValue;
};

export const convertToDisplayValue = (databaseValue: string | undefined, formatValidation: CalculationTextFormatValidation): string => {
  return formatValidation === CalculationTextFormatValidation.percentage && databaseValue
    ? invalidBigJsFormat(databaseValue)
      ? databaseValue
      : Big(databaseValue).times(Big(100)).toString()
    : databaseValue;
};

export const safeStringQuotation = (input: string | number): string => {
  const stringInput = `${input}`;
  if (stringInput[0] === '"' && stringInput[stringInput.length - 1] === '"') {
    return stringInput;
  }
  return JSON.stringify(stringInput);
};

export const getCalculationSelectCalculationValue = (
  question: Pick<SubFormQuestion<QuestionTypeMap<FieldType.calculation_select>['options']>, 'config' | 'options'>,
  currentValue?: CalculationSelectFieldValue
) => {
  if (!question.options.values || !currentValue?.value) {
    return '';
  }
  const values = Object.values(question.options.values).find((option) => option.key === currentValue.value);
  // TODO: add missing types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const value = (values as any)?.calculation_value;
  if (value && question.config.format_validation === 'text') {
    return safeStringQuotation(value);
  }
  return value;
};

export const getCalculationTextCalculationValue = (
  question: Pick<SubFormQuestion<QuestionTypeMap<FieldType.calculation_text>['options']>, 'config'>,
  currentValue: CalculationTextFieldValue
) => {
  const databaseValue = convertToDatabaseValue(
    currentValue?.value === 0 ? '0' : currentValue?.value || '',
    question.config.format_validation,
    question.config.decimal_places
  );

  if (databaseValue && question.config.format_validation === CalculationTextFormatValidation.text) {
    return safeStringQuotation(databaseValue);
  }

  return databaseValue;
};

export const getCalculationTextDefaultValue = (
  value: string | number,
  calculationTextConfig: ReturnType<typeof generateCalculationTextConfig>
) => {
  const { defaultValueAmount, trimSpaces, formatValidation } = calculationTextConfig;
  if (trimSpaces) {
    return convertToDisplayValue(`${value}`.replace(/\s/g, '') || defaultValueAmount, formatValidation);
  }
  return convertToDisplayValue(`${value}` || defaultValueAmount, formatValidation);
};

export const valueInBounds = (value: Maybe<string>, calculationTextConfig: ReturnType<typeof generateCalculationTextConfig>) => {
  if (!value || treatAsText(value, calculationTextConfig)) {
    return value;
  }
  if (calculationTextConfig.minValue && +value < +calculationTextConfig.minValue) {
    return `${calculationTextConfig.minValue}`;
  }
  if (calculationTextConfig.maxValue && +value > +calculationTextConfig.maxValue) {
    return `${calculationTextConfig.maxValue}`;
  }
  return value;
};

export function treatAsText(value: string, calculationTextConfig: ReturnType<typeof generateCalculationTextConfig>) {
  const { isText, isAuto } = calculationTextConfig;
  return isText || (isAuto && isNaN(+value));
}

export function removeTrailingDecimalZeros(value?: string | number) {
  if (!value) return '';
  return value.toString();
}

export function addTrailingDecimalZeros(
  value: Maybe<string | number>,
  calculationTextConfig: ReturnType<typeof generateCalculationTextConfig>
) {
  if (!value) return '';
  return parseFloat(value.toString()).toFixed(calculationTextConfig.decimalPlaces);
}

export const handleDecimalPlaces = (
  value: Maybe<string | number>,
  calculationTextConfig: ReturnType<typeof generateCalculationTextConfig>
) => {
  return calculationTextConfig.removeDecimalZeros
    ? removeTrailingDecimalZeros(value)
    : addTrailingDecimalZeros(value, calculationTextConfig);
};

export const generateCalculationTextConfig = (
  question: Pick<SubFormQuestion<QuestionTypeMap<FieldType.calculation_text>['options']>, 'config'>
) => {
  const isInteger = question.config.format_validation === CalculationTextFormatValidation.integer;
  const isCurrency = question.config.format_validation === CalculationTextFormatValidation.currency;
  const isNumber = question.config.format_validation === CalculationTextFormatValidation.number;
  const isPercentage = question.config.format_validation === CalculationTextFormatValidation.percentage;
  const isText = question.config.format_validation === CalculationTextFormatValidation.text;
  const isAuto = question.config.format_validation === CalculationTextFormatValidation.auto;
  const parsleyValidNumber = isCurrency || isNumber || isPercentage;
  const integerOrParsleyValidNumber = isInteger || parsleyValidNumber;
  const trimSpaces = integerOrParsleyValidNumber;
  const decimalPlaces = Math.abs(parseInt(`${question.config.decimal_places}`));
  const roundingBehaviour = question.config.decimal_handling_behaviour || CalculationTextDecimalHandlingBehaviour.Floor;
  const rounding = parsleyValidNumber && decimalPlaces > 0;
  const removeDecimalZeros = question.config.remove_trailing_decimal_zeros === 'true';
  const minValue = question.config.min_value;
  const maxValue = question.config.max_value;
  const defaultValueAmount = question.config.default_value_amount;
  const formatValidation = question.config.format_validation;

  return {
    isInteger,
    isCurrency,
    isNumber,
    isPercentage,
    parsleyValidNumber,
    integerOrParsleyValidNumber,
    trimSpaces,
    decimalPlaces,
    roundingBehaviour,
    rounding,
    isText,
    isAuto,
    removeDecimalZeros,
    minValue,
    maxValue,
    defaultValueAmount,
    formatValidation,
  };
};

export function processValue(value: string | undefined, calculationTextConfig: ReturnType<typeof generateCalculationTextConfig>): string {
  const valueToUse = value || '';
  const rounded = calculationTextConfig.rounding
    ? roundDecimalPlaces(valueToUse, calculationTextConfig.decimalPlaces, calculationTextConfig.roundingBehaviour)
    : valueToUse;

  return treatAsText(rounded, calculationTextConfig)
    ? rounded
    : handleDecimalPlaces(valueInBounds(rounded, calculationTextConfig), calculationTextConfig);
}

export const invalidBigJsFormat = (value: Maybe<string | number>) => {
  return typeof value === 'string' && isNaN(value as unknown as number);
};
