
  import { Component, Emit, Ref } from 'vue-property-decorator';
  import { mixins } from 'vue-class-component';
  import type { MarkableFieldValue } from '@app/models/question-response-types';
  import WithSignatureEventListeners from '@app/mixins/with-signature-event-listeners';
  import type { FieldType } from '@app/models/sub-form-question';
  import type { Signature } from '@app/models/signature';

  import SignaturePad from '../signature/signature-pad.vue';

  import BaseField from './base-field';

  @Component
  class BaseMarkableField extends BaseField<FieldType.markable> {}

  @Component({ components: { SignaturePad } })
  export default class MarkableField extends mixins(BaseMarkableField, WithSignatureEventListeners) {
    @Ref() readonly signature?: SignaturePad;

    localSignature: Signature | null = null;
    localValue: MarkableFieldValue = { value: '' };

    get alternativeText(): string {
      return this.question.config.alt_text || '';
    }

    get backgroundImageUrl(): string | undefined {
      return this.question.attachments && this.question.attachments[0]?.url;
    }

    get showLocalSignature() {
      return this.localSignature && this.value.value && this.localValue.value === this.value.value;
    }

    @Emit('input')
    onUpdate(data: string): MarkableFieldValue {
      this.localValue = { value: data };
      this.sendUpdate(this.localValue);
      return this.localValue;
    }

    clearMarkable(): void {
      this.onUpdate('');
      this.localSignature = null;
    }

    mounted(): void {
      this.localValue = { ...this.value };
      if (this.localValue.value) {
        this.$api.getSignature(Number(this.localValue.value)).then(({ data }) => {
          this.localSignature = data;
        });
      }
    }
  }
