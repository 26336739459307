import type { SubFormCompletion } from '@app/models/sub-form-completion';
import type { DonesafeApi } from '@app/services/donesafe-api';
import type { OnlyOptions } from '@app/services/donesafe-api-utils';
import { MAIN_FORM_MODULE_NAME } from '@app/constants';

export const verifySubFormChanges = (
  api: DonesafeApi,
  args: { moduleName?: string; recordId: number; subFormCompletionId: number; updatedAt: string }
): Promise<boolean> => {
  const only: OnlyOptions<SubFormCompletion> = ['id', 'updated_at'];

  const check = (completion: SubFormCompletion) => {
    const outdated = completion?.updated_at !== args.updatedAt;
    if (outdated) console.warn('Sub form completion is outdated');
    return outdated;
  };
  const promise =
    args.moduleName === MAIN_FORM_MODULE_NAME
      ? api.getModuleRecord(args.recordId, { only: [{ sub_form_completion: only }] }).then(({ data }) => data?.sub_form_completion)
      : api.getSubFormCompletion(args.subFormCompletionId, { only }).then(({ data }) => data);

  return promise.then(check);
};
