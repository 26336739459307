import type { QuestionSfcOnly } from '@app/components/sub-form-completion/utils';
import type { SubFormData } from '@app/services/api/sub-form-completions-api';
import { isQuestionVisible } from '@app/services/model-helpers';
import type { Dictionary } from '@app/models/dictionary';
import type { SubFormCompletion } from '@app/models/sub-form-completion';
import type { SubFormQuestion } from '@app/models/sub-form-question';
import type { SubFormResponse } from '@app/models/sub-form-response';
import { FieldType } from '@app/models/sub-form-question';
import type { FormObservers } from '@app/utils/types/form-observers';

import { CalculationHandler } from './calculation-handler';

interface QuestionHandlerConfig {
  approvalForSubFormCompletion?: SubFormCompletion;
  form: SubFormData;
  observers: FormObservers;
  questions: Pick<SubFormQuestion, QuestionSfcOnly>[];
  recordCompletion?: SubFormCompletion;
}

// TODO: offload this to a worker
export default class QuestionHandler {
  questions: Pick<SubFormQuestion, QuestionSfcOnly>[];
  form: SubFormData;
  recordCompletion?: SubFormCompletion;
  approvalForSubFormCompletion?: SubFormCompletion;
  calculationHandler: CalculationHandler;
  observers: FormObservers;

  constructor(opts: QuestionHandlerConfig) {
    const { form, observers, questions, recordCompletion, approvalForSubFormCompletion } = opts;
    this.questions = questions;
    this.form = form;
    this.recordCompletion = recordCompletion;
    this.approvalForSubFormCompletion = approvalForSubFormCompletion;
    this.calculationHandler = new CalculationHandler(questions, this.allQuestionsById, observers.calculationValues$);
    this.observers = observers;
  }

  get mainFormResponsesBySystemCode(): Dictionary<SubFormResponse> {
    return (
      this.recordCompletion?.sub_form_responses.reduce((memo, r) => {
        return { ...memo, [r.sub_form_question_system_code]: r };
      }, {}) || {}
    );
  }

  get baseFormResponsesBySystemCode(): Dictionary<SubFormResponse> {
    return (
      this.approvalForSubFormCompletion?.sub_form_responses.reduce((memo, r) => {
        return { ...memo, [r.sub_form_question_system_code]: r };
      }, {}) || {}
    );
  }

  get mfqlResponse(): Maybe<SubFormResponse<FieldType.main_form_question_list>> {
    return this.recordCompletion?.sub_form_responses?.filter(
      (r) => r.sub_form_question_field_type === FieldType.main_form_question_list
    )[0] as SubFormResponse<FieldType.main_form_question_list>;
  }

  get allQuestionsBySystemCode(): Map<string, Pick<SubFormQuestion, QuestionSfcOnly>> {
    return new Map(this.questions.map((q) => [q.system_code, q]));
  }

  get allQuestionsById(): Map<number, Pick<SubFormQuestion, QuestionSfcOnly>> {
    return new Map(this.questions.map((q) => [q.id, q]));
  }

  allQuestionsFilteredVisible = (form: SubFormData) => {
    this.setForm(form);
    const { allQuestionsBySystemCode, mainFormResponsesBySystemCode, baseFormResponsesBySystemCode, mfqlResponse } = this;

    return this.questions.filter((q) =>
      isQuestionVisible(q, {
        allQuestionsBySystemCode,
        form,
        mainFormResponsesBySystemCode,
        baseFormResponsesBySystemCode,
        mfqlResponse,
      })
    );
  };

  setForm = (form: SubFormData) => {
    this.form = form;
  };
}
