import type { PaperTrailChange } from '@app/models/paper-trail-version';
import type { BaseEntity } from '../../../models/base-entity';

export * from './detail-link';
export * from './response-type';
export * from './version-account';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type VersionSearchMetaData = Record<string, any>;

export interface AttributeValue {
  changes: [Nullable<PaperTrailChange>, Nullable<PaperTrailChange>];
  entities: [Nullable<Partial<BaseEntity>>, Nullable<Partial<BaseEntity>>];
  responseType?: string;
  texts: [Nullable<string>, Nullable<string>];
}

export const TRANSLATION_POSTFIX = '::Translation';
export type AttributeOverrides = Record<string, string[]>;

export type AttributeValues = Record<string, Record<string, AttributeValue>>;
export type AttributeValuesMap = Record<string, AttributeValues>;

export interface VersionTime {
  date: string;
  tz: string;
}
