
  import { Component, Model, Prop, Ref, Vue } from 'vue-property-decorator';
  import { Popover } from 'uiv';

  const TRIGGERS = {
    CLICK: 'click',
    HOVER: 'hover',
    FOCUS: 'focus',
    HOVER_FOCUS: 'hover-focus',
    OUTSIDE_CLICK: 'outside-click',
    MANUAL: 'manual',
  };

  const PLACEMENTS = {
    TOP: 'top',
    RIGHT: 'right',
    BOTTOM: 'bottom',
    LEFT: 'left',
  };

  @Component({ components: { Popover } })
  export default class DsPopover extends Vue {
    @Model('input') readonly value!: boolean;
    @Ref() readonly popoverRef!: InstanceType<typeof Popover>;
    @Prop({ type: String, default: '' }) readonly title!: string;
    @Prop({ type: String, default: '' }) readonly content!: string;
    @Prop({ type: String, default: TRIGGERS.OUTSIDE_CLICK }) readonly trigger!: string;
    @Prop({ type: String, default: 'span' }) readonly tag!: string;
    @Prop({ type: String, default: PLACEMENTS.TOP }) readonly placement!: string;
    @Prop({ type: Boolean, default: true }) readonly autoPlacement!: boolean;
    @Prop({ type: String, default: 'body' }) readonly appendTo!: string;
    @Prop({ type: String, default: null }) readonly positionBy!: string | null;
    @Prop({ type: Number, default: 150 }) readonly transition!: number;
    @Prop({ type: Number, default: 0 }) readonly hideDelay!: number;
    @Prop({ type: Number, default: 0 }) readonly showDelay!: number;
    @Prop({ type: Boolean, default: true }) readonly enable!: boolean;
    @Prop({ type: Boolean, default: true }) readonly enterable!: boolean;
    @Prop() readonly target?: string;
    @Prop({ type: String }) readonly viewport?: string;
    @Prop({ type: String }) readonly customClass?: string;

    handleShow() {
      this.$emit('show');
    }

    handleHide() {
      this.$emit('hide');
    }

    resetPosition() {
      this.popoverRef.resetPosition();
    }
  }
