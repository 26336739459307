import type { SubFormSection } from '@app/models/sub-form-section';
import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';

export class SubFormSectionService extends BaseService<SubFormSection> {
  static readonly allowedAttributes = [
    'active',
    'code',
    'config',
    'field_type',
    'id',
    'index',
    'options',
    'required',
    'sub_form_section_id',
    'supports_mfql',
    'system_code',
    'uuid',
  ];

  async fetchDetails(itemId: ServiceRequest<SubFormSection>): Promise<DetailsServiceResponse> {
    const subFormSection = await this.fetchEntity(itemId);
    const { data: subForm } = await this.api.getSubForm(subFormSection.sub_form_id, { only: ['id', 'title'] }, { cache: true, join: true });
    return {
      links: [
        { prefix: this.t('app.labels.section'), title: subFormSection.title, id: subFormSection.id },
        { link: `/admin/settings/sub_forms/${subForm.id}`, prefix: this.t('app.labels.sub_form'), title: subForm.title, id: subForm.id },
      ],
    };
  }

  async fetchEntity({ itemId }: ServiceRequest<SubFormSection>): Promise<SubFormSection> {
    const { data: subFormSection } = await this.api.getSubFormSection(
      itemId,
      { only: ['id', 'title', 'sub_form_id'] },
      { join: true, cache: true }
    );
    return subFormSection;
  }

  entityToText({}: ServiceRequest<SubFormSection>, entity?: SubFormSection): string {
    return entity ? `${entity.title} [${entity.id}]` : '';
  }
}
