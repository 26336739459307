import {
  AddressDisplay,
  DisplayImageDisplay,
  DisplayTextDisplay,
  FileUploadDisplay,
  MainFormRelationDisplay,
  MarkableDisplay,
  MultiMainFormRelationDisplay,
  RecordRelationDisplay,
  ReportDisplay,
  SignatureDisplay,
  SingleSelectDisplay,
  SubFormRelationDisplay,
  SvgSelectorDisplay,
  TableCalculationDisplay,
  TextareaDisplay,
  TimesheetDisplay,
  MatrixDisplay,
} from './index';

export const FORM_FIELD_DISPLAY_COMPONENTS = {
  AddressDisplay,
  DisplayImageDisplay,
  DisplayTextDisplay,
  FileUploadDisplay,
  MainFormRelationDisplay,
  MarkableDisplay,
  MultiMainFormRelationDisplay,
  RecordRelationDisplay,
  ReportDisplay,
  SignatureDisplay,
  SingleSelectDisplay,
  SubFormRelationDisplay,
  SvgSelectorDisplay,
  TableCalculationDisplay,
  TextareaDisplay,
  TimesheetDisplay,
  MatrixDisplay,
};
