import type { SubForm } from '@app/models/sub-form';
import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';

export class SubFormService extends BaseService<SubForm> {
  static readonly allowedAttributes = [
    'active',
    'id',
    'module_name',
    'options',
    'score_band_profile_id',
    'scoring_group_id',
    'scoring_type',
    'scoring_view_option',
    'sub_form_type',
    'system_code',
  ];

  async fetchDetails(itemId: ServiceRequest<SubForm>): Promise<DetailsServiceResponse> {
    const subForm = await this.fetchEntity(itemId);
    return {
      links: [
        {
          link: `/admin/settings/sub_forms/${subForm.id}`,
          prefix: this.t('app.labels.sub_form'),
          title: subForm.title,
          id: subForm.id,
        },
      ],
    };
  }

  async fetchEntity({ itemId }: ServiceRequest<SubForm>): Promise<SubForm> {
    const { data: subForm } = await this.api.getSubForm(itemId, { only: ['id', 'title'] }, { cache: true, join: true });
    return subForm;
  }

  entityToText({}: ServiceRequest<SubForm>, entity?: SubForm): string {
    return entity ? `${entity.title} [${entity.id}]` : '';
  }
}
