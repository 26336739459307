
  import { Component } from 'vue-property-decorator';
  import BaseFieldDisplay from './base-field-display';
  import type { FieldType } from '@app/models/sub-form-question';
  import type { ModuleRecord } from '@app/models/module-record';

  @Component({ components: {} })
  export default class MultiMainFormRelationDisplay extends BaseFieldDisplay<FieldType.multi_main_form_relation> {
    records: Nullable<Record<string, Pick<ModuleRecord, 'id' | 'title'>>> = null;

    beforeMount(): void {
      !this.isPublic &&
        this.subFormResponse.response?.forEach((id) => {
          this.$api
            .getModuleRecord(Number(id), { only: ['id', 'title'] }, { cache: true, join: true })
            .then(({ data }) => {
              this.records = { ...(this.records || {}), [id]: data };
            })
            .catch((e) => console.warn(e));
        });
    }
  }
