import type { Involvement } from '@app/models/involvement';
import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { OnlyOptions } from '@app/services/donesafe-api-utils';

export const INVOLVEMENT_DETAILS_ONLY: OnlyOptions<Involvement> = ['id', 'name'];

export class InvolvementService extends BaseService<Involvement> {
  static readonly allowedAttributes = [
    'active',
    'can_add',
    'can_delete',
    'hide_empty',
    'id',
    'index',
    'involvement_type',
    'linking_question_id',
    'module_name',
    'multiple',
    'restrict_available_users',
    'source_involvement_id',
    'system_code',
    'user_filters',
  ];

  async fetchDetails({ itemId }: ServiceRequest<Involvement>): Promise<DetailsServiceResponse> {
    const { data: involvement } = await this.api.getInvolvement(itemId, { only: INVOLVEMENT_DETAILS_ONLY }, { join: true, cache: true });
    return {
      links: [
        {
          prefix: this.t('app.labels.involvement'),
          title: involvement.name,
          id: involvement.id,
        },
      ],
    };
  }
}
