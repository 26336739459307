
  import { Component } from 'vue-property-decorator';
  import BaseResponse from './base-response';
  import TextHighlight from 'vue-text-highlight';
  import type { Organization } from '@app/models/organization';

  @Component({ components: { TextHighlight } })
  export default class OrganizationResponse extends BaseResponse<Organization> {
    get organizationEditLink(): Nullable<string> {
      if (this.currentUserStore.hasUsersLocationsOrganizationsPermission) {
        return `/admin/settings/organizations/${this.entity?.id}/edit`;
      } else {
        return null;
      }
    }
  }
