
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import type { OptionalSignatureData } from '@app/services/api/record-signatures-api';
  import bootbox from 'bootbox';
  import { Component, Vue } from 'vue-property-decorator';
  import SignatureFormModal from '@app/components/signature/signature-form-modal.vue';
  import type { Signature } from '@app/models/signature';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { SignatureFormModal } })
  export default class UserPrimarySignature extends Vue {
    modalVisible = false;
    currentSignature: Nullable<Signature> = null;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get currentSignatureUrl(): string | undefined {
      return this.currentSignature?.url;
    }

    beforeMount(): void {
      if (this.currentUserStore.data?.primary_signature_id) {
        this.$api
          .getSignature(this.currentUserStore.data.primary_signature_id, {}, { cache: true })
          .then(({ data }) => (this.currentSignature = data));
      }
    }

    addSignature(): void {
      this.toggleModal(true);
    }

    deleteSignature(): void {
      bootbox.confirm({
        size: 'small',
        backdrop: false,
        message: 'Are you sure?',
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          result &&
            this.currentUserStore.data?.id &&
            this.$api.updateTenantUser(this.currentUserStore.data?.id, { primary_signature_id: null }).then(() => {
              this.currentUserStore.$patch({
                data: {
                  ...this.currentUserStore.data,
                  primary_signature_id: undefined,
                },
              });
              this.currentSignature = null;
              this.toggleModal(false);
            });
        },
      });
    }

    toggleModal(value: boolean): void {
      this.modalVisible = value;
    }

    submitForm(params: OptionalSignatureData): void {
      if (params.signature_data) {
        this.$api
          .createSignature({ signature_data: params.signature_data })
          .then(({ data }) => {
            this.currentUserStore.data?.id &&
              this.$api.updateTenantUser(this.currentUserStore.data?.id, { primary_signature_id: data.id }).then(() => {
                this.currentUserStore.$patch({
                  data: {
                    ...this.currentUserStore.data,
                    primary_signature_id: data.id,
                  },
                });
                this.onSignatureCreated(data);
              });
          })
          .catch(({ data }) => this.onSignatureError(data?.error));
      } else if (params.signature_id) {
        this.$api
          .getSignature(params.signature_id, {}, { cache: true })
          .then(({ data }) => this.onSignatureCreated(data))
          .catch(({ data }) => this.onSignatureError(data?.error));
      }
    }

    onSignatureCreated(signature: Signature): void {
      this.toggleModal(false);
      this.currentSignature = signature;
    }

    onSignatureError(error?: string): void {
      toaster({ text: error || 'N/A', position: 'top-right', icon: 'error' });
    }
  }
