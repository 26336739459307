import type { BaseEntity } from '@app/models/base-entity';
import type { VersionAccount, DetailsLink } from '../models';
import { ResponseType } from '../models';
import { extractPaperTrailVersionValue } from '../utils';
import type { CurrentUserStoreData } from '@app/stores/currentUser';
import type { PaperTrailChange, PaperTrailChanges, PaperTrailVersion } from '@app/models/paper-trail-version';
import type { DonesafeApi } from '@app/services/donesafe-api';

type TranslateFunction = (key: string, args?: object) => string;
export interface ServiceRequest<Entity extends BaseEntity = BaseEntity, Value = PaperTrailChange> {
  itemId: BaseEntity['id'];
  version: PaperTrailVersion<Entity, Value>;
}

export interface DetailsServiceResponse {
  links?: DetailsLink[];
  mainText?: string;
  subText?: string;
}

export interface AttributeResponse<Entity = BaseEntity> {
  entity: Nullable<Entity>;
  text: string;
}
export interface AttributeResponsePair<Entity = BaseEntity> {
  entities: [Nullable<Entity>, Nullable<Entity>];
  itemIds: [string | number | null, string | number];
  texts: [Nullable<string>, Nullable<string>];
}
export type ChangesResponse<Entity = BaseEntity> = Record<string, AttributeResponsePair<Entity>>;

export interface ServiceConfig {
  account: VersionAccount;
  api: DonesafeApi;
  currentUser: CurrentUserStoreData;
  t: TranslateFunction;
}

export interface NormalizeChangesResponse {
  changes: [Nullable<PaperTrailChange>, Nullable<PaperTrailChange>];
  responseType: ResponseType;
  texts: [Nullable<string>, Nullable<string>];
}

export class BaseService<Entity extends BaseEntity = BaseEntity, OutputEntity extends BaseEntity = Entity> {
  static readonly allowedAttributes: string[] = [];

  protected config: ServiceConfig;

  constructor(config: ServiceConfig) {
    this.config = config;
  }

  async fetchDetails({}: ServiceRequest<Entity>): Promise<DetailsServiceResponse> {
    return {};
  }

  async fetchEntity(request: ServiceRequest<Entity>): Promise<Partial<OutputEntity>> {
    throw new Error(`fetchEntity is not implemented for ${JSON.stringify(request.version)}`);
  }

  entityToText(request: ServiceRequest<Entity>, entity?: Partial<OutputEntity>): string {
    throw new Error(`entityToText is not implemented for ${request.version.item_type}, ${JSON.stringify(entity)}`);
  }

  async fetchAttribute(change: Entity['id'], version: PaperTrailVersion<Entity>): Promise<AttributeResponse<Partial<OutputEntity>>> {
    let entity: Maybe<Partial<OutputEntity>>;
    try {
      entity = await this.fetchEntity({ itemId: change, version });
    } catch {} // entity is optional for fetchAttribute if the record was deleted
    const text = this.entityToText({ itemId: change, version }, entity);
    return { entity: entity || null, text };
  }

  async fetchChanges(key: keyof Entity, version: PaperTrailVersion<Entity>): Promise<ChangesResponse<Partial<OutputEntity>>> {
    const changes = this.changes(version);
    let record: Nullable<Partial<OutputEntity>> = null;
    let beforeRecord: Nullable<Partial<OutputEntity>> = null;
    let textAfter = '';
    let beforeText = '';
    const before = changes[key][0] as Entity['id'];
    if (before) {
      const { entity, text } = await this.fetchAttribute(before, version);
      beforeRecord = entity;
      beforeText = text;
    }
    const after = changes[key][1] as Entity['id'];
    if (after) {
      const { entity, text } = await this.fetchAttribute(after, version);
      record = entity;
      textAfter = text;
    }

    return { entity: { entities: [beforeRecord, record], itemIds: [before, after], texts: [beforeText, textAfter] } };
  }

  async normalizeChanges(key: keyof Entity, version: PaperTrailVersion<Entity>): Promise<NormalizeChangesResponse> {
    const objectChanges = this.changes(version);
    const texts: [Nullable<string>, Nullable<string>] = [
      this.normalizeText(objectChanges[key][0], key),
      this.normalizeText(objectChanges[key][1], key),
    ];
    const changes: [Nullable<PaperTrailChange>, Nullable<PaperTrailChange>] = [
      this.normalizeValue(objectChanges[key][0], key),
      this.normalizeValue(objectChanges[key][1], key),
    ];
    return { texts, changes, responseType: this.responseType(key) };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  normalizeText(change: Nullable<PaperTrailChange>, _key: keyof Entity): Nullable<string> {
    return change == null ? '' : JSON.stringify(change);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  normalizeValue(change: Nullable<PaperTrailChange>, _key: keyof Entity): Nullable<PaperTrailChange> {
    return change; // raw value by default
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  responseType(_key: keyof Entity): ResponseType {
    return ResponseType.Direct;
  }

  protected extractValue<V = string>(key: keyof Entity, version: PaperTrailVersion<Entity>): Maybe<V> {
    return extractPaperTrailVersionValue(key, version) as V;
  }

  protected changes(version: PaperTrailVersion<Entity>): PaperTrailChanges<Entity> {
    const changes = version.object_changes;
    if (!changes) throw new Error(`No changes for ${version}`);

    return changes;
  }

  protected get api() {
    return this.config.api;
  }

  get t() {
    return this.config.t;
  }

  protected get currentUser() {
    return this.config.currentUser;
  }

  protected get account() {
    return this.config.account;
  }
}
