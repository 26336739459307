
  import RecurringSchedulerForm from '@app/components/recurring-setup/recurring-scheduler-form.vue';
  import { Component, Prop, Ref } from 'vue-property-decorator';
  import type { Schedule } from '@app/models/schedule';
  import { toaster } from '@app/utils/toaster';
  import UserShiftsList from '@app/components/admin/users/user-shifts-list.vue';
  import type { TenantUser } from '@app/models/tenant-user';
  import { mixins } from 'vue-class-component';
  import WithUser from '@app/mixins/with-user';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { useAccountStore } from '@app/stores/account';
  import { DatetimeToString } from '@app/services/datetime_to_string';

  @Component({ components: { RecurringSchedulerForm, UserShiftsList } })
  export default class UserTimesheetsPage extends mixins(WithUser) {
    @Ref() readonly recurringSchedulerForm?: RecurringSchedulerForm;
    @Prop(Boolean) readonly admin?: boolean;

    user: Nullable<TenantUser> = null;
    saving = false;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get accountStore() {
      return useAccountStore();
    }

    get canEdit() {
      return this.admin || this.currentUserStore.checkProfilePermission({ id: this.userId }, 'pay_details', 'edit');
    }

    get sixMonthsAgo(): Date {
      const currentDate = new Date();
      const sixMonthsAgo = new Date(currentDate);
      sixMonthsAgo.setMonth(currentDate.getMonth() - 6);

      return sixMonthsAgo;
    }

    get oneMonthFromNow(): Date {
      const currentDate = new Date();
      const oneMonthFromNow = new Date(currentDate);
      oneMonthFromNow.setMonth(currentDate.getMonth() + 1);

      return oneMonthFromNow;
    }

    datetimeToString(datetime: string): string | undefined {
      return new DatetimeToString({
        currentAccount: this.accountStore.data,
        datetime: datetime,
        timezone: this.currentUserStore.data?.timezone,
        reportFormat: 'default',
        datetimeFormat: this.$t('app.labels.standard_datetime_format', {
          date: this.accountStore.data.datetimepicker_date_format,
          time: this.accountStore.data.datetimepicker_time_format,
        }),
      }).getResult();
    }

    submit(): void {
      if (this.recurringSchedulerForm) {
        this.saving = true;
        this.$api
          .updateTenantUser(this.userId, { schedule: this.recurringSchedulerForm.scheduleForm as Schedule })
          .then(() => {
            toaster({
              text: 'Timesheet schedule updated',
              position: 'top-right',
            });
            window.location.reload();
          })
          .finally(() => {
            this.saving = false;
          });
      }
    }

    beforeMount() {
      this.$api.getTenantUser(this.userId, { include: 'schedule' }, { cache: true }).then((response) => {
        this.user = response.data;
      });
    }
  }
