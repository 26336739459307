
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import LocationSelector from '@app/components/location/location-selector.vue';
  import LocationTagSelector from '@app/components/location/location-tag-selector.vue';
  import UserSelector from '@app/components/user/user-selector.vue';
  import type { UserLocationTag } from '@app/models/user-location-tag';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
  import { v4 as generateUUID } from 'uuid';
  import type { LocationTag } from '@app/models/location-tag';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import DsCheckbox from '@app/components/ds-checkbox.vue';

  @Component({
    components: { DsCheckbox, LocationTagSelector, LocationSelector, UserSelector, ValidationObserver, ValidationProvider },
  })
  export default class UserLocationTagForm extends Vue {
    @Prop(Object) readonly userLocationTag?: Partial<UserLocationTag>;
    @Prop(Boolean) readonly noUserSelection?: boolean;
    @Prop(Boolean) readonly noLocationSelection?: boolean;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;
    @Ref() readonly tagSelector?: LocationTagSelector;

    form: Partial<UserLocationTag> = {};
    formKey: string = generateUUID();
    allowGlobal: boolean = false;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get locationTagFilters(): DonesafeFilterOptions<LocationTag> {
      const base = { active: true };
      if (this.currentUserStore.hasSystemAdministrationPermission) {
        return base;
      }

      return { ...base, admin_only: false };
    }

    get locationSelectorPlaceholder(): Maybe<string> {
      return this.allowGlobal ? this.$t('tenant.admin.users.user_location_tag_form.global') : undefined;
    }

    @Watch('form.location_tag_id')
    tagChanged(): void {
      this.allowGlobal = !!this.tagSelector?.selector?.results?.find((tag: LocationTag) => tag.id === this.form.location_tag_id)
        ?.allow_global;
    }

    beforeMount(): void {
      this.reset();
    }

    reset(): void {
      this.form = { ...this.userLocationTag };
      this.validator?.reset();
      this.formKey = generateUUID();
    }

    submit(): void {
      this.validator?.validate().then((result: boolean) => {
        result && this.$emit('submit', this.form);
      });
    }
  }
