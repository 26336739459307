
  import type { MaterialIconStyle } from '@app/utils/types/material-icon-style';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import DsIcon from './ds-icon.vue';

  @Component({ components: { DsIcon } })
  export default class DsIconText extends Vue {
    @Prop(String) readonly icon?: string;
    @Prop({ type: String, default: 'default' }) readonly iconStyle?: MaterialIconStyle;
    @Prop(String) readonly color?: string;
    @Prop(Object) readonly btnTextClasses?: object;
    @Prop(Boolean) readonly reverse?: boolean;
    @Prop(Boolean) readonly fa?: boolean;

    get wrapperClasses() {
      return {
        'flex-row-reverse': !!this.reverse,
      };
    }
  }
