import { AdminPermissionFeature } from '@app/models/admin-permission';
import type { Location } from '@app/models/location';
import type { LocationTag } from '@app/models/location-tag';
import type { LocationTagCount } from '@app/models/location-tag-count';
import type { UserLocationTag } from '@app/models/user-location-tag';
import type { DonesafeApi } from '@app/services/donesafe-api';
import { featureEnabled } from '@app/utils/feature-enabled';
import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';

export class LocationTagCountService extends BaseService<LocationTagCount> {
  static readonly allowedAttributes = ['id', 'location_id', 'location_tag_id'];
  static readonly attributeServices: Partial<Record<keyof UserLocationTag, string>> = {
    location_id: 'Location',
    location_tag_id: 'LocationTag',
  };

  async fetchDetails(itemId: ServiceRequest<LocationTagCount>): Promise<DetailsServiceResponse> {
    const entity = await this.fetchEntity(itemId);
    return {
      links: entity.location_tag
        ? [
            {
              link: this.locationTagEditLink(entity.location_tag),
              prefix: this.t('app.labels.location_tag'),
              title: entity.location_tag.name,
              id: entity.id,
            },
          ]
        : [],
    };
  }

  async fetchEntity({ itemId }: ServiceRequest<LocationTagCount>): Promise<LocationTagCount> {
    return await LocationTagCountService.fetchEntity(this.api, itemId);
  }

  static async fetchEntity(api: DonesafeApi, itemId: Location['id']): Promise<LocationTagCount> {
    const { data: locationTagCount } = await api.getLocationTagCount(itemId, { only: ['id', 'location_tag'] }, { cache: true, join: true });
    return locationTagCount;
  }

  private locationTagEditLink(locationTag: Pick<LocationTag, 'id' | 'name'>): string | undefined {
    if (featureEnabled(this.currentUser, AdminPermissionFeature.roles_and_tags)) {
      return `/admin/settings/location_tags/${locationTag.id}/edit`;
    }
  }
}
