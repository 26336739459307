
  import { Component } from 'vue-property-decorator';
  import type { Moment } from 'moment';
  import moment from 'moment';
  import DateTimePicker from '@app/components/date-time-picker.vue';
  import BaseTimesheet from './base-timesheet';
  import type { HourType } from '@app/models/hour-type';
  import type { Schedule } from '@app/models/schedule';
  import type { Shift } from '@app/models/shift';
  import { INTERVAL_TO_UNITS } from '@app/models/schedule';
  import { ShiftType } from '@app/models/shift';

  @Component({ components: { DateTimePicker } })
  export default class ActualTimesheet extends BaseTimesheet {
    hourTypes: HourType[] = [];

    get availableDays(): { actualShift?: Shift; dayFormatted: string; dayValue: string; expectedShift?: Shift; shift?: Shift }[] {
      return this.timesheetDates?.map((date) => {
        const actualShift = this.user.shifts?.find(
          (shift) => shift.shift_type === ShiftType.actual && moment(shift.start_at).startOf('day').isSame(date.startOf('day'))
        );
        const expectedShift = this.user.shifts?.find(
          (shift) => shift.shift_type === ShiftType.expected && moment(shift.start_at).startOf('day').isSame(date.startOf('day'))
        );
        const shift = actualShift || expectedShift;
        return {
          dayFormatted: this.prettyDay(date),
          dayValue: date.format(this.dateFormat),
          shift,
          actualShift,
          expectedShift,
        };
      });
    }

    get lastAvailableDay(): Maybe<{ actualShift?: Shift; dayFormatted: string; dayValue: string; expectedShift?: Shift; shift?: Shift }> {
      return this.availableDays?.[this.availableDays.length - 1];
    }

    get timesheetDates(): Moment[] {
      const start = moment(this.startDate);
      const end = this.user.schedule ? moment(this.endDateFrom(this.user.schedule, this.startDate)) : start;

      return this.dateRange(start, end);
    }

    endDateFrom(schedule: Schedule, startDate: Date): Date {
      return moment(startDate)
        .add(schedule.interval_split, INTERVAL_TO_UNITS[schedule.interval] || 'days')
        .subtract(1, 'day')
        .toDate();
    }

    initForm(days: { actualShift?: Shift; dayFormatted: string; dayValue: string; expectedShift?: Shift; shift?: Shift }[]) {
      this.form = days.reduce((memo, day) => {
        const { shift, dayValue } = day;
        return {
          ...memo,
          [dayValue]: {
            start_at: this.toDate(shift?.start_at),
            end_at: this.toDate(shift?.end_at),
            shift_hour: this.hourTypes.reduce((memo, hourType) => {
              return {
                ...memo,
                [hourType.id]: this.findHours(shift, hourType) || 0,
              };
            }, {}),
          },
        };
      }, {});

      this.shiftsLoaded = true;
    }

    hourTypeDescription(hourType: HourType): string {
      if (hourType.inclusivity_type === 'include_hours') {
        return this.$t('app.labels.included_in_recorded_hours');
      } else if (hourType.inclusivity_type === 'add') {
        return this.$t('app.labels.adds_to_recorded_hours');
      } else {
        return this.$t('app.labels.subtracts_from_recorded_hours');
      }
    }

    findHours(shift: Shift | undefined, hourType: HourType): number | undefined {
      return shift?.shift_hours?.find((hour) => hour.hour_type_id === hourType.id)?.hours;
    }

    beforeMount(): void {
      this.$api.getHourTypes({ filters: { active: true }, sort: 'created_at' }, { cache: true }).then(({ data }) => {
        this.hourTypes = data;
        this.initForm(this.availableDays);
      });
    }
  }
