import { upperFirst } from 'lodash';
import type { DetailsLink } from '../models';
import { AUTOMATION_DETAILS_ONLY } from './automation-definition-service';
import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import { automationRecordId, automationRecordPath } from '../utils';
import type { Event } from '@app/models/event';
import type { ScheduledEvent } from '@app/models/scheduled-event';
import type { OnlyOptions } from '@app/services/donesafe-api-utils';
import { isSubFormRecordCollection } from '@app/utils/is-sub-form-record-collection';
import { isRecordCollection } from '@app/utils/is-record-collection';
import { isUserCollection } from '@app/utils/is-user-collection';

export const SCHEDULED_DETAILS_ONLY: OnlyOptions<ScheduledEvent> = [
  'collection_description',
  'id',
  'name',
  'schedule_id',
  'event_id',
  { event: ['event_type', 'options'], recurring_setup: ['recurrable_id', 'recurrable_type'] },
];
export const SCHEDULED_DETAILS_INCLUDE = ['event', 'recurring_setup'];

export class EventService extends BaseService<Event> {
  static collectionType(per?: string | null): string | undefined {
    if (isUserCollection(per)) {
      return 'User';
    } else if (isSubFormRecordCollection(per)) {
      return 'SubForm Record';
    } else if (isRecordCollection(per)) {
      return 'Record';
    }

    return per !== null ? per && upperFirst(per) : undefined;
  }

  static mainText(service: BaseService, scheduledEvent: Partial<ScheduledEvent>): string {
    const per = scheduledEvent.event?.per;
    const collectionType = EventService.collectionType(per);
    const eventType = upperFirst(scheduledEvent.event?.event_type);
    return `${service.t('app.labels.type')}: ${eventType}, ${service.t('app.labels.collection_type')}: ${collectionType}`;
  }

  async fetchDetails({ itemId }: ServiceRequest<Event>): Promise<DetailsServiceResponse> {
    const { data: automations } = await this.api.getAutomationDefinitionsWithSharedFilters(
      {
        only: AUTOMATION_DETAILS_ONLY,
        include: ['event_type'],
      },
      'event_ids',
      itemId,
      { cache: true }
    );
    const automation = automations[0];
    const { data: scheduledEvents } = await this.api.getScheduledEventsWithSharedFilters(
      {
        only: SCHEDULED_DETAILS_ONLY,
        include: SCHEDULED_DETAILS_INCLUDE,
      },
      'event_id',
      itemId,
      { cache: true }
    );
    const scheduledEvent = scheduledEvents[0];
    let links: DetailsLink[] = [];
    let mainText = '';
    if (automation) {
      mainText = `${this.t('app.labels.type')}: ${automation.events?.map((e) => e.event_type).join(', ')}`;
      links = [
        {
          link: `/admin/settings/automation_definitions/${automation.id}/edit`,
          prefix: this.t('app.labels.automation'),
          title: automation.name,
          id: automation.id,
        },
        {
          link: automationRecordPath(automation),
          prefix: this.t('app.labels.triggered_from'),
          title: automation.concept_name,
          id: automationRecordId(automation),
        },
      ];
    }
    if (scheduledEvent) {
      mainText = EventService.mainText(this, scheduledEvent);
      links = [
        {
          link: `/admin/settings/scheduled_events/${scheduledEvent.id}/edit`,
          prefix: this.t('app.labels.schedule'),
          title: scheduledEvent.name,
          id: scheduledEvent.id,
        },
      ];
    }
    return {
      links,
      mainText,
    };
  }
}
