import type { DetailsServiceResponse, ServiceRequest } from '@app/components/paper-trails/services/base-service';
import { BaseService } from '@app/components/paper-trails/services/base-service';
import type { RecordCalculationResult } from '@app/models/record-calculation-result';

export class RecordCalculationResultService extends BaseService<RecordCalculationResult> {
  static readonly allowedAttributes: (keyof RecordCalculationResult)[] = ['value'];

  async fetchDetails({ itemId }: ServiceRequest<RecordCalculationResult>): Promise<DetailsServiceResponse> {
    const { data: result } = await this.api.getRecordCalculationResult(
      itemId,
      { only: ['id', 'value', { record_calculation: ['name'] }] },
      { join: true, cache: true }
    );
    return {
      links: [
        {
          prefix: this.t('app.labels.record_calculation'),
          title: `${result?.record_calculation?.name}`,
          id: result.record_calculation_id,
        },
      ],
    };
  }
}
