import type { BaseEntity } from './base-entity';
import type { Profile } from './profile';
import type { Role } from './role';
import type { Schedule } from './schedule';
import type { AdminPermission, AdminPermissionFeature } from './admin-permission';
import type { UserLocationTag } from './user-location-tag';
import type { UserModuleRecord } from './user-module-record';
import type { Shift } from './shift';
import type { Restriction } from './restriction';
import type { Organization } from './organization';
import type { Location } from './location';
import type { DynamicDashboardSettings } from '@app/models/account';

export enum UserType {
  ContactUser = 'ContactUser',
  ContractorUser = 'ContractorUser',
  MedicalUser = 'MedicalUser',
  TenantUser = 'TenantUser',
  VisitorUser = 'VisitorUser',
}

export interface PiaweDetail {
  avg_hourly_rate: number;
  avg_hours_worked_per_week: number;
  commission: number;
  education_fees: number;
  health_insurance: number;
  motor_vehicle_allowance: number;
  override_rate?: number;
  override_reason?: string;
  override_user_id?: number;
  overtime: number;
  piece_tally_bonus: number;
  residential_allowance: number;
  shift_allowance: number;
  vendor_code?: string;
}
export interface Password {
  current_password?: string;
  password?: string;
  password_confirmation?: string;
}

export interface TenantUser extends BaseEntity {
  active: boolean;
  admin?: boolean;
  admin_permission_features?: AdminPermissionFeature[];
  admin_permissions?: AdminPermission[];
  avatar_url?: string;
  can_be_changed_password?: boolean;
  can_be_opened?: boolean;
  can_change_password?: boolean;
  child_ids?: number[];
  company_register_id?: number;
  confirmation_sent_at?: string;
  confirmed_at?: string;
  current_sign_in_at?: string;
  date_of_birth?: string;
  dynamic_dashboard_settings?: DynamicDashboardSettings;
  email: string;
  employment_type_id?: number;
  end_date?: string;
  external_uuid: string;
  first_name: string;
  full_name: string;
  gender_identity_id?: number;
  generated_email?: boolean;
  given_timezone: string;
  has_active_panes?: boolean;
  home_location?: Location;
  home_location_id?: number;
  home_organization?: Organization;
  home_organization_id?: number;
  internal?: boolean;
  kb_acknowledgements?: boolean;
  language?: string;
  last_name: string;
  last_sign_in_at?: string;
  lms_course_enrollment_ids?: number[];
  location_ceiling_id?: number;
  location_ids?: number[];
  locked?: boolean;
  manager?: TenantUser;
  manager_id?: number;
  mfa_signed_in?: boolean;
  mobile: string;
  notification_ids?: number[];
  organization_ceiling_id?: number;
  organization_ids?: number[];
  partner_proxy?: boolean;
  password_changed_at?: string;
  payroll_identifier: string;
  piawe_detail?: PiaweDetail;
  position: string;
  primary_signature_id?: number;
  profile?: Profile;
  restrictions?: Restriction[];
  role?: Role;
  role_id: number;
  schedule?: Schedule;
  schedule_id?: number;
  secondary_information?: string;
  shifts?: Shift[];
  skip_explicit_location_restrictions: boolean;
  skip_explicit_organization_restrictions: boolean;
  start_date?: string;
  sub_form_response_id?: number;
  team_ids?: number[];
  technical_admin?: boolean;
  timezone?: string;
  title: string;
  type: UserType;
  unsubscribed_event_ids?: number[];
  unsubscribed_location_ids?: number[];
  unsubscription_collection_ids?: number[];
  user_location_tags?: UserLocationTag;
  user_module_records?: UserModuleRecord[];
  uuid?: string;
  valid_email: boolean;
  wider_team_ids?: number[];
  workplace_industry_id?: number;
}
