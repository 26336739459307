import { render, staticRenderFns } from "./main-form-relation-field.vue?vue&type=template&id=4c075d1c&scoped=true&lang=pug"
import script from "./main-form-relation-field.vue?vue&type=script&lang=ts"
export * from "./main-form-relation-field.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c075d1c",
  null
  
)

export default component.exports