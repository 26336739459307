
  import type { ApprovalExpenseFieldValue } from '@app/models/question-response-types';
  import { sumBy } from 'lodash';
  import { Component, Emit, Ref } from 'vue-property-decorator';
  import type { ApprovedExpense } from '@app/models/approved_expense';
  import type { ExpenseCategoryOverwrite } from '@app/models/expense-category-overwrite';
  import type { ExpenseCategory, ExpenseCategoryBase } from '@app/models/expense-category';
  import type { SubFormCompletion } from '@app/models/sub-form-completion';
  import type { SubFormResponse } from '@app/models/sub-form-response';
  import { FieldType } from '@app/models/sub-form-question';
  import type { OnlyOptions } from '@app/services/donesafe-api-utils';
  import { formatMoney } from '@app/utils/format-money';

  import BaseField from './base-field';

  type AlertClass = 'danger' | 'warning' | 'success';
  type Completion = Pick<SubFormCompletion, 'id' | 'record_type' | 'record_id' | 'sub_form_responses'>;

  @Component
  export default class ApprovalExpenseField extends BaseField<FieldType.approval_expense> {
    @Ref() readonly requiredError?: HTMLElement;

    amount = '';
    expenseCategory: Nullable<Pick<ExpenseCategory, 'id' | 'name' | keyof ExpenseCategoryBase>> = null;
    expenseCategoryOverwrite: Nullable<Pick<ExpenseCategoryOverwrite, 'id' | keyof ExpenseCategoryBase>> = null;
    fetching = false;
    approvedExpenses: Pick<ApprovedExpense, 'amount' | 'sub_form_response_id'>[] = [];
    baseCompletion: Nullable<Completion> = null;

    get unitAlertClass(): AlertClass {
      const amount = this.numericAmount;
      const spendPerUnit = this.spentPerUnit;
      if (amount > spendPerUnit * (((this.currentUserStore.data?.role?.line_approval_percentage || 0) + 100) / 100)) {
        return 'danger';
      } else if (amount > spendPerUnit) {
        return 'warning';
      } else {
        return 'success';
      }
    }

    get numericAmount(): number {
      return this.amount ? Number(this.amount) : 0;
    }

    get totalAlertClass(): AlertClass {
      const amount = this.total;
      const spendPerClaim = this.spentPerClaim;
      if (amount > spendPerClaim + (this.currentUserStore.data?.role?.max_overall_approval_cost || 0)) {
        return 'danger';
      } else if (amount > spendPerClaim) {
        return 'warning';
      } else {
        return 'success';
      }
    }

    get sessionAlertClass(): AlertClass {
      const amount = this.sessions;
      const maxSessions = this.maxSessions;
      if (amount > maxSessions * (((this.currentUserStore.data?.role?.overall_approval_percentage || 0) + 100) / 100)) {
        return 'danger';
      } else if (amount > maxSessions) {
        return 'warning';
      } else {
        return 'success';
      }
    }

    get spentPerClaim(): number {
      return this.expenseCategoryOverwrite?.spend_per_claim || this.expenseCategory?.spend_per_claim || 0;
    }

    get spentPerUnit(): number {
      return this.expenseCategoryOverwrite?.spend_per_unit || this.expenseCategory?.spend_per_unit || 0;
    }

    get maxSessions(): number {
      return this.expenseCategoryOverwrite?.default_max_sessions || this.expenseCategory?.default_max_sessions || 1;
    }

    get spentPerClaimFormatted(): string {
      return formatMoney(this.spentPerClaim);
    }

    get spentPerUnitFormatted(): string {
      return formatMoney(this.spentPerUnit);
    }

    get valueFormatted(): string {
      return formatMoney(this.amount);
    }

    get totalFormatted(): string {
      return formatMoney(this.total);
    }

    get othersApprovedExpenses(): Pick<ApprovedExpense, 'amount' | 'sub_form_response_id'>[] {
      return this.approvedExpenses.filter((expense) => expense.sub_form_response_id !== this.response?.id);
    }

    get total(): number {
      return sumBy(this.othersApprovedExpenses, 'amount') + this.numericAmount;
    }

    get sessions(): number {
      return this.othersApprovedExpenses.length + 1;
    }

    get showRequiredError(): boolean {
      return [this.unitAlertClass, this.totalAlertClass, this.sessionAlertClass].some((cls) => cls === 'danger');
    }

    @Emit('input')
    updateValue(value: string | number, initialValue = false): ApprovalExpenseFieldValue {
      this.amount = value ? `${value}` : '';
      if (!initialValue) {
        this.requiredError && $(this.requiredError).parsley().reset();
      }
      const newValue = { value: `${this.amount}` };
      this.sendUpdate(newValue, initialValue);
      return newValue;
    }

    beforeMount(): void {
      this.updateValue(this.value?.value, true);
      this.fetchExpenseCategory()
        .catch((error) => console.error(error))
        .finally(() => (this.fetching = false));
    }

    async fetchExpenseCategory(): Promise<void> {
      this.fetching = true;
      const baseCompletionId = this.completion?.approval_for_sub_form_completion_id;
      if (!baseCompletionId) throw new Error('approval_for_sub_form_completion_id is required');

      const subFormCompletionOnly: OnlyOptions<SubFormCompletion> = [
        'id',
        'record_type',
        'record_id',
        { sub_form_responses: ['sub_form_question_field_type', 'response', { sub_form_question: ['config'] }] },
      ];
      const subFormCompletionResult = await this.$api.getSubFormCompletion(baseCompletionId, {
        include: 'sub_form_question',
        only: subFormCompletionOnly,
      });
      this.baseCompletion = subFormCompletionResult.data;
      const baseResponse = this.baseCompletion?.sub_form_responses?.find(
        (response) => response.sub_form_question_field_type === FieldType.expense
      ) as SubFormResponse<FieldType.expense>;
      if (!baseResponse.response.category) throw new Error('approval_for_sub_form_completion_id is required');

      if (!this.amount) {
        this.updateValue(Number(baseResponse.response.amount), true);
      }
      const only: OnlyOptions<ExpenseCategory> = ['id', 'name', 'spend_per_unit', 'spend_per_claim', 'default_max_sessions'];
      const expenseCategoryResult = await this.$api.getExpenseCategory(baseResponse.response.category, { only }, { cache: true });
      this.expenseCategory = expenseCategoryResult.data;
      const overwritesResult = await this.$api.getExpenseCategoryOverwrites(
        {
          filters: {
            expense_category_id: this.expenseCategory.id,
            sub_form_list_id: baseResponse.sub_form_question?.config?.sub_form_list_id,
            record_id: this.baseCompletion.record_id || undefined,
            record_type: this.baseCompletion.record_type || undefined,
          },
        },
        { cache: true }
      );
      this.expenseCategoryOverwrite = overwritesResult.data[0];
      const approvedResult = await this.$api.getApprovedExpenses(
        {
          only: ['amount', 'sub_form_response_id'],
          filters: {
            expense_category_id: this.expenseCategory.id,
            record_type: this.baseCompletion.record_type || undefined,
            record_id: this.baseCompletion.record_id || undefined,
            sub_form_list_id: baseResponse.sub_form_question?.config?.sub_form_list_id,
          },
          per_page: -1,
        },
        { cache: true }
      );
      this.approvedExpenses = approvedResult.data;
    }
  }
