
  import { Component, Emit } from 'vue-property-decorator';
  import type { CalculationSelectFieldValue } from '@app/models/question-response-types';
  import type { FieldType } from '@app/models/sub-form-question';
  import type { CalculationSelectQuestionOptions } from '@app/models/question-options/calculation-select-question-options';

  import Select2 from '../select2.vue';

  import BaseField from './base-field';

  @Component({ components: { Select2 } })
  export default class CalculationSelectField extends BaseField<FieldType.calculation_select> {
    localValue: CalculationSelectFieldValue = { value: '' };

    get calculationSelectOptions(): { label: string; value: string }[] | undefined {
      if (!this.question.options.values) {
        return;
      }

      return Object.values(this.question.options.values).map((selectOption) => {
        return {
          value: selectOption.key,
          label: selectOption.value,
        };
      });
    }

    get visibilityOverride(): CalculationSelectQuestionOptions['visibility_override'] {
      return this.question.config.visibility_override;
    }

    get allowClearResponse(): boolean {
      return this.defaultTemplating || this.question.config.allow_clear_response === 'true';
    }

    @Emit('input')
    updateValue(value?: string): CalculationSelectFieldValue {
      const calculationValue =
        this.question.options.values &&
        Object.values(this.question.options.values).find((o) => `${o.key}` === `${value}`)?.calculation_value;
      this.localValue = { value, calculation_value: calculationValue };
      this.sendUpdate(this.localValue);

      return this.localValue;
    }

    mounted(): void {
      const value = this.value?.value || '';
      this.localValue = { value };
      this.sendUpdate(this.localValue, true);
    }
  }
