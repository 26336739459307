import { useCurrentUserStore } from '@app/stores/currentUser';
import { useAccountStore } from '@app/stores/account';
import { Vue, Prop, Ref } from 'vue-property-decorator';
import Component from 'vue-class-component';
import type { ValidationObserver } from 'vee-validate';
import { extend } from 'vee-validate';
import type { DonesafeLocationsApiExtraOptions } from '@app/services/api/locations-api';
import type { DonesafeOrganizationsApiExtraOptions } from '@app/services/api/organizations-api';
import { uniq } from 'lodash';
import moment from 'moment';
import type { EmploymentType } from '@app/models/employment-type';
import type { GenderIdentity } from '@app/models/gender-identity';
import type { ModuleRecord } from '@app/models/module-record';
import type { Organization } from '@app/models/organization';
import type { Password, TenantUser } from '@app/models/tenant-user';
import type { Location } from '@app/models/location';
import type { ModuleName } from '@app/models/module-name';
import type { UserModuleRecord } from '@app/models/user-module-record';
import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
import type { PartialNullBy } from '@app/utils/types/partial';
import { isPiiField } from '@app/utils/isPiiField';

type DateFieldKey = 'date_of_birth' | 'start_date' | 'end_date';
type FormTenantUser = Partial<
  PartialNullBy<
    TenantUser,
    | 'primary_signature_id'
    | 'organization_ceiling_id'
    | 'location_ceiling_id'
    | 'manager_id'
    | 'employment_type_id'
    | 'workplace_industry_id'
    | 'gender_identity_id'
    | 'timezone'
    | 'start_date'
    | 'end_date'
    | 'date_of_birth'
  >
> &
  Password & {
    avatar?: string;
    confirmed?: boolean;
    related_module_records?: Record<string, number | null>;
    unconfirmed?: boolean;
  };

@Component
export default class UserDetails extends Vue {
  @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;
  @Prop(Number) readonly userId?: number;

  form: FormTenantUser = {};
  relatedModuleNames: ModuleName[] = [];
  employmentTypes: Partial<EmploymentType>[] = [];
  genderIdentities: Partial<GenderIdentity>[] = [];
  relatedRecords: Record<number, ModuleRecord> = {};
  existingUserRelatedRecords: Record<string, number> = {};
  loading = false;
  submitting = false;
  user: Nullable<TenantUser> = null;
  locationAncestors: number[] = [];
  organizationAncestors: number[] = [];

  get currentUserStore() {
    return useCurrentUserStore();
  }

  get accountStore() {
    return useAccountStore();
  }

  get managerFilters(): DonesafeFilterOptions<TenantUser> {
    const idsToFilter = [this.userId, this.form.manager_id].filter(Boolean);

    if (!idsToFilter.length) return {};
    return { '!id': idsToFilter };
  }

  get userGeneratedEmail(): boolean {
    return !!this.user?.generated_email;
  }

  get languageOptions(): [string, string][] {
    return uniq(this.accountStore.data.all_languages).map((languageId) => [
      languageId,
      `${window.DONESAFE.LANGUAGES[languageId]} [${languageId}]`,
    ]);
  }

  get permissionFilters(): DonesafeFilterOptions<
    Organization | Location,
    DonesafeLocationsApiExtraOptions | DonesafeOrganizationsApiExtraOptions
  > {
    const base = {
      with_restrictions: this.currentUserStore.hasUsersLocationsOrganizationsPermission
        ? false
        : this.accountStore.data.apply_permission_to_selectors,
    };

    if (this.accountStore.data.hide_inactive_olu_for_filters) {
      return { ...base, active: true };
    }
    return base;
  }

  get passwordChangeRequired(): boolean {
    return !!this.form.password;
  }

  get valueDates(): Record<DateFieldKey, Maybe<Date>> {
    return {
      date_of_birth: this.dateValue('date_of_birth'),
      start_date: this.dateValue('start_date'),
      end_date: this.dateValue('end_date'),
    };
  }

  get showOrganizationCeilingWarning(): boolean {
    return !(
      !this.accountStore.data.limit_permissions_by_organization ||
      !this.form.home_organization_id ||
      !this.form.organization_ceiling_id ||
      Number(this.form.home_organization_id) === Number(this.form.organization_ceiling_id) ||
      this.organizationAncestors.includes(Number(this.form.organization_ceiling_id))
    );
  }

  get showLocationCeilingWarning(): boolean {
    return !(
      !this.accountStore.data.limit_permissions_by_location ||
      !this.form.home_location_id ||
      !this.form.location_ceiling_id ||
      Number(this.form.home_location_id) === Number(this.form.location_ceiling_id) ||
      this.locationAncestors.includes(Number(this.form.location_ceiling_id))
    );
  }

  checkHomeOrganizationCeiling(organizationId?: TenantUser['home_organization_id']) {
    if (!organizationId || !this.accountStore.data.limit_permissions_by_organization) {
      return (this.organizationAncestors = []);
    }

    this.$api.getOrganization(Number(organizationId), { only: ['ancestor_ids'] }, { cache: true }).then(({ data }) => {
      this.organizationAncestors = data.ancestor_ids || [];
    });
  }

  checkHomeLocationCeiling(locationId?: TenantUser['home_location_id']) {
    if (!locationId || !this.accountStore.data.limit_permissions_by_location) {
      return (this.locationAncestors = []);
    }

    this.$api.getLocation(Number(locationId), { only: ['ancestor_ids'] }, { cache: true }).then(({ data }) => {
      this.locationAncestors = data.ancestor_ids || [];
    });
  }

  getGenderIdentities(): Promise<void> {
    return this.$api.getGenderIdentities({ only: ['id', 'identity'], filters: { active: true } }, { cache: true }).then(({ data }) => {
      this.genderIdentities = data;
    });
  }

  getEmploymentTypes(): Promise<void> {
    return this.$api.getEmploymentTypes({ only: ['id', 'name'], filters: { active: true } }, { cache: true }).then(({ data }) => {
      this.employmentTypes = data;
    });
  }

  getRelatedModules(): Promise<void> {
    return this.$api
      .getModuleNames(
        {
          filters: { active: true, feature_set: { user_related: true } },
          only: ['id', 'display', { feature_set: ['user_related', 'user_related_required'] }],
        },
        { cache: true }
      )
      .then(({ data }) => {
        this.relatedModuleNames = data;
      });
  }

  getRelatedRecords(moduleRecordIds: number[]): Promise<void> {
    return this.$api
      .getModuleRecords({ filters: { id: moduleRecordIds }, only: ['id', 'title', 'module_name_id'] }, { cache: true })
      .then(({ data }) => {
        this.relatedRecords = data.reduce((acc, { module_name_id, ...record }) => ({ ...acc, [module_name_id]: record }), {});
      });
  }

  dateValue(key: DateFieldKey): Maybe<Date> {
    return (this.form[key] && moment(this.form[key]).toDate()) || undefined;
  }

  onDateChange(date: Maybe<Date>, key: DateFieldKey): void {
    const dateString = date && moment(date).format('YYYY-MM-DD');
    this.form = { ...this.form, [key]: dateString || null };
  }

  convertModuleRecordsUserToRelated(umr: UserModuleRecord[]): Record<string, number> {
    return umr.reduce((acc, { module_name_id, module_record_id }) => ({ ...acc, [module_name_id]: module_record_id }), {});
  }

  setExistingUserRelatedRecords(user: TenantUser): void {
    if (user.user_module_records) {
      this.existingUserRelatedRecords = this.convertModuleRecordsUserToRelated(user.user_module_records);
    }
  }

  pickRelatedRecords(user: FormTenantUser): Record<string, number | null> {
    return Object.entries(user.related_module_records || {})?.reduce((acc, [key, value]) => {
      if (`${this.existingUserRelatedRecords?.[key]}` !== `${value}`) {
        return { ...acc, [key]: value || null };
      } else {
        return { ...acc };
      }
    }, {});
  }

  initForm(user: TenantUser): void {
    this.form = {
      ...user,
      related_module_records: user.user_module_records ? this.convertModuleRecordsUserToRelated(user.user_module_records) : {},
    };
    this.setExistingUserRelatedRecords(user);
  }

  beforeMount(): void {
    extend('password', {
      params: ['target'],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      validate(value, { target }: any) {
        return value === target;
      },
      message: 'Password confirmation does not match',
    });
  }

  displayPossiblePiiField(field: string): boolean {
    const permitted = !isPiiField(field, this.accountStore.data);
    switch (field) {
      case 'employment_type_id':
        return !!this.employmentTypes.length && permitted;
      case 'gender_identity_id':
        return !!this.genderIdentities.length && permitted;
      case 'language':
        return (this.accountStore.data.all_languages || []).length > 1 && permitted;
      case 'date_of_birth':
        return !!this.accountStore.data.display_date_of_birth && permitted;
      case 'start_date':
        return !!this.accountStore.data.display_employment_start_date && permitted;
      case 'end_date':
        return !!this.accountStore.data.display_employment_separation_date && permitted;
      case 'mobile':
        return !!this.accountStore.data.display_mobile_number && permitted;
      default:
        return permitted;
    }
  }

  async fetchUser(cache = true): Promise<void> {
    if (!this.userId) {
      this.user = {} as TenantUser;
      return Promise.resolve();
    }

    const { data } = await this.$api.getTenantUser(
      this.userId,
      {
        // TODO: convert 'include' to 'only' options
        include: [
          'role',
          'manager',
          'home_location',
          'home_organization',
          'location_ceiling',
          'organization_ceiling',
          'user_module_records',
          'can_be_changed_password',
          'internal',
          'avatar_url',
          'mfa_signed_in',
          'locked',
          'generated_email',
          'valid_email',
        ],
      },
      { cache }
    );
    this.user = data;
  }
}
