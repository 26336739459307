import type { ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { Permission } from '@app/models/permission';
import { PermissionAccess } from '@app/models/permission';

export class PermissionService extends BaseService<Permission> {
  async fetchEntity({ itemId }: ServiceRequest<Permission>): Promise<Permission | Pick<Permission, 'id'>> {
    try {
      const { data: permission } = await this.api.getPermission(
        itemId,
        { only: ['id', 'name', 'access', 'concept_name', { related_module_name: ['id', 'name', 'display'] }] },
        { join: true, cache: true }
      );
      return permission;
    } catch (e) {
      return { id: itemId };
    }
  }

  entityToText({}: ServiceRequest<Permission>, entity?: Permission): string {
    if (entity?.id && !entity.name) {
      return 'Permission deleted';
    }
    const accessMapping = {
      [PermissionAccess.view_access]: 'Can View',
      [PermissionAccess.edit_access]: 'Can Edit',
      [PermissionAccess.delete_access]: 'Can Delete',
    };
    return entity ? `${entity.name} ${accessMapping[entity.access]} "${entity.related_module_name?.display || entity.concept_name}"` : '';
  }
}
