
  import { Component, Emit, Prop, Ref, Vue } from 'vue-property-decorator';
  import { Collapse, Typeahead, Tooltip } from 'uiv';
  import { evaluate } from 'mathjs';
  import Select2 from '@app/components/select2.vue';
  import EntitySelector from '@app/components/entity-selector.vue';
  import { ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';
  import BulkEditModal from '../bulk-edit-modal.vue';
  import type { BulkEditUpdateParams, VariableData } from '../utils';
  import { getEditableColumnComponent } from '../utils';
  import ExpandableRow from './expandable-row.vue';
  import { EDITABLE_COLUMNS_COMPONENTS } from './editable-columns/components';
  import type { QuestionSfcOnly } from '@app/components/sub-form-completion/utils';
  import type { CombinedRecord } from '@app/models/table-calculation';
  import type { FieldType, QuestionTypeMap, SubFormQuestion } from '@app/models/sub-form-question';
  import type {
    TableCalculationColumnConfig,
    TableCalculationColumnEditable,
    TableCalculationColumnFormula,
    TableCalculationConfig,
    TableCalculationDisplay,
    TableCalculationQuestionOptions,
    TableCalculationVariable,
  } from '@app/models/question-options/table-calculation-question-options';

  @Component({
    components: {
      Select2,
      EntitySelector,
      Typeahead,
      Tooltip,
      ValidationProvider,
      ValidationObserver,
      BulkEditModal,
      ExpandableRow,
      Collapse,
      ...EDITABLE_COLUMNS_COMPONENTS,
    },
  })
  export default class TableCalculationModalTable extends Vue {
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;
    @Ref() readonly bulkEditModal?: InstanceType<typeof BulkEditModal>;

    @Prop(Object) readonly question!: Pick<SubFormQuestion<QuestionTypeMap<FieldType.table_calculation>['options']>, QuestionSfcOnly>;
    @Prop(Boolean) readonly readonly?: boolean;
    @Prop(Object) readonly displayVariableData!: VariableData;
    @Prop(Array) readonly visibleColumns!: TableCalculationColumnConfig[];
    @Prop(Array) readonly computedRecords!: CombinedRecord[];
    @Prop(Object) readonly calculated!: Record<string, string>;
    @Prop(Boolean) readonly loading!: boolean;
    @Prop(Object) readonly sort!: {
      direction: 'asc' | 'desc';
      field: string;
    };

    @Emit('sort')
    toggleSort(columnCode: string) {
      return {
        field: columnCode,
        direction: this.sort.field === columnCode ? (this.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc',
      };
    }

    @Emit('update-record')
    updateRecord(index: number, pathToUpdate: string, value: boolean | string | number | null) {
      return {
        index,
        pathToUpdate,
        value,
      };
    }

    bulkEditModalShown = false;
    selectedIndexes: number[] = [];
    getEditableColumnComponent = getEditableColumnComponent;

    get allowBulkEdit(): boolean {
      return !this.readonly && this.visibleEditableColumns.length > 0;
    }

    get orderedCalculated(): (TableCalculationConfig & { result: string })[] {
      return (this.questionConfig?.table_calculation.calculations || []).reduce((acc, calculation) => {
        if (!calculation.hidden && calculation.code in this.calculated) {
          return [...acc, { ...calculation, result: this.calculated[calculation.code] }];
        }
        return acc;
      }, [] as (TableCalculationConfig & { result: string })[]);
    }

    get sortable(): boolean {
      return this.questionConfig.table_calculation?.sortable?.handle_visible || false;
    }

    get visibleEditableColumns(): TableCalculationColumnConfig[] {
      return this.visibleColumns.filter((column) => ('editable' in column ? column.editable && !column?.disabled : false));
    }

    get hasExpandableRecords(): boolean {
      return this.computedRecords.some((record) => this.isExpandableRecord(record));
    }

    get fullWidthColspan(): number {
      return this.visibleColumns.length + (Number(!this.readonly) || 0) + Number(this.hasExpandableRecords);
    }

    get expandableRowOptions(): TableCalculationQuestionOptions['table_calculation']['expandable'] {
      return this.questionConfig.table_calculation?.expandable;
    }

    get displays(): TableCalculationDisplay[] {
      return this.questionConfig.table_calculation.displays || [];
    }

    get variables(): TableCalculationVariable[] {
      return this.questionConfig.table_calculation.variables?.filter(({ hidden }) => !hidden) || [];
    }

    get questionConfig(): TableCalculationQuestionOptions {
      return this.question.config;
    }

    get allSelected(): boolean {
      return this.selectedIndexes.length === this.computedRecords.length;
    }

    onCollapseInput(index: number, expanded: boolean) {
      this.updateRecord(index, 'expanded', expanded);
    }

    toggleSelected(index: number): void {
      if (this.selectedIndexes.includes(index)) {
        this.selectedIndexes = this.selectedIndexes.filter((i) => i !== index);
      } else {
        this.selectedIndexes = [...this.selectedIndexes, index];
      }
    }

    calculatedFormulaTooltip(code: string): string {
      const calc = this.questionConfig.table_calculation.calculations?.find((c) => c.question_code === code);
      const formula = calc ? `<div>${calc?.method?.toUpperCase() || ''}(${'formula' in calc ? calc.formula : calc.variable})<div>` : '';
      const filter = calc?.filter ? `<br/><div>Filter - ${calc.filter}</div>` : '';
      return `<div style="word-break: break-word;">${formula}${filter}</div>`;
    }

    get indeterminate(): boolean {
      return !!this.selectedIndexes.length && !this.allSelected;
    }

    onBulkEditUpdate(data: BulkEditUpdateParams): void {
      this.bulkEditModal?.close();
      this.selectedIndexes.forEach((i) => {
        Object.keys(data).forEach((key) => {
          // allow bulk clearing of select fields
          if (data[key].type === 'select' && data[key].value === '') {
            this.updateRecord(i, `data.${key}`, '');
          } else {
            this.updateRecord(i, `data.${key}`, data[key].value);
          }
        });
      });
      this.toggleAllSelected(false);
    }

    toggleBulkEditModal(): void {
      this.bulkEditModalShown = !this.bulkEditModalShown;
    }

    isExpandableRecord(record: CombinedRecord): boolean {
      return typeof record.expanded !== 'undefined';
    }

    toggleExpandableRecord(index: number) {
      this.updateRecord(index, 'expanded', !this.computedRecords[index].expanded);
    }

    toggleAllSelected(selected: boolean): void {
      this.selectedIndexes = selected ? this.computedRecords.map((_, i) => i) : [];
    }

    evaluateColumnRules(data: CombinedRecord, column: TableCalculationColumnEditable): Record<string, boolean | object | string | number> {
      const rules = column?.rules;

      return (rules || [])?.reduce((acc, rule) => {
        if (rule.condition) {
          const condition = rule.condition;
          const conditionResult = evaluate(condition, { ...data.data });
          if (conditionResult) {
            return { ...acc, [rule.key]: rule.value };
          }
        } else {
          return { ...acc, [rule.key]: rule.value };
        }

        return acc;
      }, {} as Record<string, boolean | object | string | number>);
    }

    textStyle(column: TableCalculationColumnFormula, value: string | number | undefined): Record<string, string> {
      if (column.color) {
        if (typeof column.color === 'string') {
          return { color: column.color };
        } else if (typeof column.color === 'object') {
          // if (!value) {
          // }
          return { color: column.color[`${value}`] };
        }
      }

      return {};
    }

    cellStyle(column: TableCalculationColumnConfig, value: string | number | undefined): Record<string, string> {
      if (column.cell_color) {
        if (typeof column.cell_color === 'string') {
          return { color: column.cell_color };
        } else if (typeof column.cell_color === 'object') {
          // if (!value) {
          // }
          return { color: column.cell_color[`${value}`] };
        }
      }

      return {};
    }

    mounted() {
      setInteractionMode('eager');
    }
  }
