import type { PaginationData } from '@app/components/base-table/utils';
import { makePagination } from '@app/components/base-table/utils';
import type { BaseEntity } from '../../models/base-entity';
import { isFunction, uniq } from 'lodash';
import type { ListManagerBaseParams, ListManagerField, ListManagerSortItem } from './types';
import type { DonesafeFilterOptions, FilterValue } from '@app/services/donesafe-api-utils';

export class ListManagerBase<T = BaseEntity, F = {}> {
  fetching = false;
  items: T[] = [];
  per_page?: number;
  fields: ListManagerField<T>[];
  rowClass?: string | ((item: T, index: number) => string);
  allowFilters: boolean;
  page: number;
  sortOrder?: ListManagerSortItem[];
  total = 0;
  pagination: PaginationData = makePagination(0, 0, 0);
  trackBy: keyof T = 'id' as keyof T;
  selectedTo: number[] = [];
  customFilters: DonesafeFilterOptions<T, F> = {} as DonesafeFilterOptions<T, F>;

  constructor(params: ListManagerBaseParams<T, F>) {
    this.fields = params.fields;
    this.page = params.page || 1;
    this.per_page = params.per_page || 25;
    this.rowClass = params.rowClass;
    this.allowFilters = params.allowFilters || false;
    this.sortOrder = params.sortOrder || [];
    this.trackBy = (params?.trackBy || 'id') as keyof T;
  }

  get visibleFields(): ListManagerField<T>[] {
    return this.fields?.filter((field) => {
      if (field.hidden) {
        if (isFunction(field.filter)) {
          return !field.filter();
        }
        return false;
      }
      return true;
    });
  }

  get itemsIds(): number[] {
    return this.items.map((item) => item[this.trackBy] as number);
  }

  setFields(fields: ListManagerField<T>[]): void {
    this.fields = fields;
  }

  setData(data: T[]): void {
    this.items = data;
  }

  makePagination(total: number, perPage: number, currentPage: number): PaginationData {
    return makePagination(total, perPage, currentPage);
  }

  setSelectedTo(selectedTo: number[]): void {
    this.selectedTo = selectedTo;
  }

  clearSelectedTo(): void {
    this.selectedTo = [];
  }

  toggleSelectedTo(id: number): void {
    if (this.selectedTo.includes(id)) {
      this.selectedTo = this.selectedTo.filter((item) => item !== id);
    } else {
      this.selectedTo = uniq([...this.selectedTo, id]);
    }
  }

  isItemSelected(id: number): boolean {
    return this.selectedTo.includes(id);
  }

  togglePageSelectedAll(checked?: boolean): void {
    if (checked) {
      this.selectedTo = uniq([...this.selectedTo, ...this.itemsIds]);
    } else {
      const itemsIdsMap = this.itemsIds.reduce((acc, id) => ({ ...acc, [id]: true }), {} as Record<string, boolean>);
      this.selectedTo = this.selectedTo.filter((id) => !itemsIdsMap?.[id]);
    }
  }

  setFilter(key: keyof T | `!${string & keyof T}` | keyof F, value?: FilterValue): void {
    this.customFilters = { ...this.customFilters, [key]: value };
  }
}
