
  import { debounce } from 'lodash';
  import DropdownSelect from '@app/components/dropdown-select.vue';
  import SearchInput from '@app/components/search-input.vue';
  import { Component, Vue } from 'vue-property-decorator';
  import type { SvgSelectorFieldValue } from '@app/models/question-response-types';
  import type { Dictionary } from '@app/models/dictionary';
  import type { FieldType } from '@app/models/sub-form-question';
  import type { SvgLibraryComponent } from '@app/models/svg-library/component';
  import type { SvgLibraryTemplate } from '@app/models/svg-library/template';
  import DsPopover from '@app/components/ds-popover.vue';

  import Select2 from '../select2.vue';
  import SvgInteractiveTemplate from '../svg-template/svg-interactive-template.vue';

  import BaseField from './base-field';

  // TODO: validate categories that were selected and then the code changed
  @Component({
    components: { DropdownSelect, SearchInput, Select2, SvgInteractiveTemplate, DsPopover },
  })
  export default class SvgSelectorField extends BaseField<FieldType.svg_selector> {
    hoveringComponent: Nullable<string> = null;
    loading = false;
    localValue: SvgSelectorFieldValue = [];
    popoverDisplay: Record<string, boolean> = {};
    resizeTableDebounced = debounce(this.resizeTable, 1000);
    searchString = '';
    svgButtonCoordinates: Record<string, { left: number; top: number }> = {};
    svgContents: Nullable<string> = null;
    svgPopoverState: Record<string, boolean> = {};
    tableHeight = 0;
    template: Nullable<SvgLibraryTemplate> = null;

    get allowMultiple(): boolean {
      return this.question.config.allow_multiple === 'true';
    }

    get componentColors(): Dictionary<string> {
      const { config } = this.question;

      return Object.fromEntries(
        config.selectable_components
          .map((componentId) => {
            const component = this.template?.components?.find((c) => c.path === componentId);
            if (component && this.isComponentSelected(component)) {
              const category = this.responseCategories[component.code];
              const color = config.categories?.find((r) => r.code === category)?.color || config.categories[0].color;
              return [componentId, color];
            }
            return [];
          })
          .filter(([, color]) => !!color)
      );
    }

    get filteredTemplateComponents(): SvgLibraryComponent[] {
      const searchString = this.searchString.toLowerCase();
      if (!searchString) {
        return this.templateComponents;
      }
      return this.templateComponents.filter((c) => c.label.toLowerCase().includes(searchString));
    }

    get inputValue(): string {
      return this.localValue.map((v) => v.value).join('|');
    }

    get responseCategories(): Dictionary<string> {
      return this.localValue.reduce((memo, value) => ({ ...memo, [value.value]: value.category }), {});
    }

    get responseColors(): Dictionary<string> {
      const { config } = this.question;
      return this.localValue.reduce((memo, value) => {
        const color = config.categories.find((r) => r.code === value.category)?.color || config.categories?.[0]?.color;
        return { ...memo, [value.value]: color };
      }, {});
    }

    get selectableComponents(): Set<string> {
      return new Set(this.question.config.selectable_components);
    }

    get showSummary(): boolean {
      return this.question.config.show_summary === 'true';
    }

    get singularCategory(): boolean {
      return this.question.config.categories.length < 2;
    }

    get summary(): string {
      return this.localValue
        .map((v) => {
          const component = this.templateComponents.find((c) => c.code === v.value);
          const category = this.question.config.categories.find((r) => r.code === v.category);
          return [component?.label, category?.title].filter(Boolean).join(': ');
        })
        .join(', ');
    }

    get templateComponents(): SvgLibraryComponent[] {
      return (
        this.template?.components?.filter((c) => this.selectableComponents.has(c.path)).sort((a, b) => a.label.localeCompare(b.label)) || []
      );
    }

    componentRowIndex(component: SvgLibraryComponent): number {
      return this.filteredTemplateComponents.findIndex((c) => c.code === component.code);
    }

    deleteLabel(component: SvgLibraryComponent): string {
      return this.$t('app.labels.delete_name', { name: component.label });
    }

    fetchTemplate(): void {
      this.loading = false;
      Promise.all([
        this.$api.getSvgTemplate(this.question.config.svg_template_id, { include: 'components' }, { cache: true }),
        this.$api.getSvgTemplateFile(this.question.config.svg_template_id, { cache: true }),
      ])
        .then(([{ data: template }, { data: svgContents }]) => {
          this.template = template as SvgLibraryTemplate;
          this.svgContents = svgContents as string;
        })
        .finally(() => {
          this.loading = false;
          this.$nextTick(this.resizeTableDebounced);
        });
    }

    formatCategoriesOption(option: { id: string; text: string }): JQuery<HTMLElement> {
      const category = this.question.config.categories?.find((c) => c.code === option.id);
      return $(
        `<span class="svg-category-option">
           <span title="${option.text}"
                 class="svg-category-color-bubble"
                 style="background-color: ${category?.color || ''}"></span>
           <span class="m-l-xs">${category?.title || ''}</span>
        </span>`
      );
    }

    isComponentSelected(component: SvgLibraryComponent): boolean {
      return this.localValue.some((v) => v.value === component.code);
    }

    onComponentOut(id: string): void {
      if (id === this.hoveringComponent) {
        this.hoveringComponent = null;
      }
    }

    onComponentOver(id: string): void {
      this.hoveringComponent = id;
    }

    resizeTable(): void {
      const interactiveTemplateRef = this.$refs.interactiveTemplate as Maybe<SvgInteractiveTemplate>;
      const searchInputRef = this.$refs.searchInput as Maybe<SearchInput>;
      const selectLabelRef = this.$refs.selectLabel as Maybe<Element>;
      const legendRef = this.$refs.legend as Maybe<Element>;
      const svgHeight = (interactiveTemplateRef && $(interactiveTemplateRef.$el).height()) || 0;
      const searchInputHeight = (searchInputRef && $(searchInputRef.$el)?.outerHeight()) || 0;
      const labelHeight = (selectLabelRef && $(selectLabelRef)?.outerHeight()) || 0;
      const legendHeight = (legendRef && $(legendRef)?.outerHeight()) || 0;
      this.tableHeight = svgHeight + legendHeight - labelHeight - searchInputHeight;
    }

    rowClick(component: SvgLibraryComponent): void {
      if (this.readonly) {
        return;
      }
      if (this.singularCategory) {
        this.selectComponent(component);
      } else if (this.isComponentSelected(component)) {
        this.togglePopover(component.code, true);
      } else {
        this.selectComponent(component, true);
      }
    }

    scrollToComponent(component: SvgLibraryComponent): void {
      this.$nextTick(() => {
        const index = this.componentRowIndex(component);
        const options = { container: this.$refs.componentsTableWrapper as Element };
        this.$scrollTo((this.$refs.componentRow as Element[])[index], 250, options);
      });
    }

    selectComponent(component?: SvgLibraryComponent, togglePopover = false): void {
      if (!component || this.readonly) {
        return;
      }

      const { config } = this.question;
      const category = config.default_categories?.[component.code] || config.categories?.[0].code;
      const newValue = { value: component.code, category };
      const currentIndex = this.localValue.findIndex((v) => v.value === component.code);
      if (currentIndex > -1) {
        this.localValue = this.allowMultiple ? this.localValue.filter((v) => v.value !== component.code) : [];
        togglePopover && this.togglePopover(component.code, false);
      } else {
        this.localValue = this.allowMultiple ? [...this.localValue, newValue] : [newValue];
        togglePopover && this.togglePopover(component.code, true);
      }
      this.sendUpdate(this.localValue);
      this.$emit('input', this.localValue);
    }

    selectedComponentStyle(component: SvgLibraryComponent): Maybe<string> {
      if (this.isComponentSelected(component)) {
        const categoryCode = this.localValue.find((v) => v.value === component.code)?.category;
        const color =
          this.question.config.categories?.find((r) => r.code === categoryCode)?.color || this.question.config.categories[0]?.color;
        if (color) {
          return `box-shadow: 3px 0 0 0 inset ${color}`;
        }
      }
    }

    showCategorySelector(component: SvgLibraryComponent): void {
      this.$nextTick(() =>
        setTimeout(() => {
          const index = this.filteredTemplateComponents
            .filter(this.isComponentSelected)
            .filter((c) => this.popoverDisplay[c.code])
            .findIndex((c) => c.code === component.code);
          (this.$refs.categorySelector as Maybe<Select2[]>)?.[index]?.toggle(true);
        }, 1)
      );
    }

    svgPopoverButtonStyle(component: SvgLibraryComponent): object {
      return {
        left: `${this.svgButtonCoordinates[component.path]?.left || 0}px`,
        top: `${this.svgButtonCoordinates[component.path]?.top || 0}px`,
      };
    }

    svgPopoverHide(component: SvgLibraryComponent): void {
      Vue.delete(this.svgButtonCoordinates, component.path);
      Vue.delete(this.svgPopoverState, component.path);
    }

    svgPopoverShow(component: SvgLibraryComponent): void {
      const index = this.templateComponents.findIndex((c) => c.id === component.id);
      setTimeout(() => (this.$refs.svgPopoverSelect as Maybe<Select2[]>)?.[index]?.toggle(true), 1);
    }

    togglePopover(code: string, value: boolean): void {
      this.popoverDisplay = { ...this.popoverDisplay, [code]: value };
    }

    updateCategory(component: SvgLibraryComponent, category: string | undefined): void {
      this.localValue = this.localValue.map((v) => (v.value === component.code ? { ...v, category } : v));
      this.sendUpdate(this.localValue);
      this.$emit('input', this.localValue);
    }

    updateComponentSelection({ event, id }: { event: MouseEvent; id: string }): void {
      const templateComponent = this.template?.components?.find((c) => c.path === id);
      if (templateComponent) {
        if (!this.singularCategory) {
          const $wrapper = $(this.$refs.svgInteractiveTemplateWrapper as Element) as JQuery;
          const left = event.pageX - ($wrapper.offset()?.left as number);
          const top = event.pageY - ($wrapper.offset()?.top as number);
          this.svgButtonCoordinates[templateComponent.path] = { left, top };
          this.svgPopoverState[templateComponent.path] = true;
          if (!this.isComponentSelected(templateComponent)) {
            this.selectComponent(templateComponent);
            this.scrollToComponent(templateComponent);
          }
        } else {
          this.selectComponent(templateComponent);
          this.scrollToComponent(templateComponent);
        }
      }

      this.searchString = '';
    }

    beforeMount(): void {
      this.fetchTemplate();
      // TODO: should we handle plain array of strings as default?
      // For example: ['left_arm'] => [{ value: 'left_arm' }]
      this.localValue = [...(this.value || [])];
    }

    mounted(): void {
      window.addEventListener('resize', this.resizeTableDebounced);
    }

    beforeDestroy(): void {
      window.removeEventListener('resize', this.resizeTableDebounced);
    }
  }
