
  import { Component, Vue, Prop, Ref, Model, Emit, Watch } from 'vue-property-decorator';
  import { Dropdown } from 'uiv';

  import SearchInput from './search-input.vue';

  @Component({ components: { Dropdown, SearchInput } })
  export default class DsDropdown extends Vue {
    @Model('input') readonly value?: boolean;
    @Prop(Boolean) readonly menuRight?: boolean;
    @Prop(Boolean) readonly appendToBody?: boolean;
    @Prop(Boolean) readonly disabled?: boolean;
    @Prop(Boolean) readonly dropup?: boolean;
    @Prop(Boolean) readonly noButtonGroup?: boolean;
    @Prop(String) readonly tag?: string;
    @Prop(Array) readonly notCloseElements?: HTMLElement[];
    @Prop(HTMLElement) readonly positionElement?: HTMLElement;
    // in order for 'searchable' flag to work correctly, v-model must be set on the parent component
    @Prop(Boolean) readonly searchable?: boolean;
    @Prop(Boolean) readonly noMatch?: boolean;
    @Prop(Boolean) readonly showBehindHeader?: boolean;

    @Ref() readonly dropdown?: Vue;
    @Ref() readonly searchInputRef?: SearchInput;

    search = '';
    dropdownElements: (HTMLElement | Element)[] = [];

    get mergedNotCloseElements(): (HTMLElement | Element)[] {
      return [...(this.notCloseElements || []), ...this.dropdownElements];
    }

    get alteredTag(): string | undefined {
      if (this.noButtonGroup && (!this.tag || this.tag === 'div')) {
        return 'DIV'; // HACK not to add .btn-group class
      }
      return this.tag;
    }

    @Emit('search')
    updateSearch(value: string): string {
      return value;
    }

    @Watch('value', { immediate: true })
    onValueChanged(val: boolean) {
      this.$nextTick(() => {
        if (val) this.searchInputRef?.$el && this.dropdownElements.push(this.searchInputRef.$el);
        else this.dropdownElements = [];
      });
    }

    mounted() {
      if (this.showBehindHeader) (this.dropdown?.$refs?.['dropdown'] as HTMLElement)?.classList.add('show-behind-header');
    }
  }
