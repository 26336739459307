import type { Dictionary } from '@app/models/dictionary';
import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';

export function dependentFilterQuestionIds<T>(filters: DonesafeFilterOptions<T> = {}): Dictionary<boolean> {
  return Object.keys(filters).reduce<Dictionary<boolean>>((memo, key) => {
    if (key.startsWith('~')) {
      const filterValue = filters[key as keyof DonesafeFilterOptions<T>];
      return { ...memo, [filterValue as string]: true };
    }
    return memo;
  }, {});
}
