import type { ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { WorkplaceIndustry } from '@app/models/workplace-industry';

export class WorkplaceIndustryService extends BaseService<WorkplaceIndustry> {
  async fetchEntity({ itemId }: ServiceRequest<WorkplaceIndustry>): Promise<WorkplaceIndustry> {
    const { data: workplaceIndustry } = await this.api.getWorkplaceIndustry(itemId, { only: ['id', 'name'] }, { join: true, cache: true });
    return workplaceIndustry;
  }

  entityToText({}: ServiceRequest<WorkplaceIndustry>, entity?: WorkplaceIndustry): string {
    return entity ? `${entity.name} [${entity.id}]` : '';
  }
}
