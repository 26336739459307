import type { ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { SubFormCompletion } from '@app/models/sub-form-completion';

export class SubFormCompletionService extends BaseService<SubFormCompletion> {
  entityToText({ itemId }: ServiceRequest<SubFormCompletion>): string {
    return `ID: ${itemId}`;
  }

  async fetchEntity({ itemId }: ServiceRequest<SubFormCompletion>): Promise<Partial<SubFormCompletion>> {
    return { id: itemId as number };
  }

  static toText(completion: SubFormCompletion): string {
    return `${completion.title} [${completion.id}]`;
  }
}
