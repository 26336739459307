import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class BaseDsInput extends Vue {
  @Prop(String) readonly id?: string;
  @Prop(String) readonly vid?: string;
  @Prop(String) readonly label?: string;
  @Prop(String) readonly name?: string;
  @Prop(Boolean) readonly required?: boolean;
  @Prop(Boolean) readonly disabled?: boolean;
  @Prop(Object) readonly labelClass?: Record<string, boolean>;
  @Prop(Boolean) readonly horizontal?: boolean;
  @Prop(Boolean) readonly invertLabel?: boolean;
  @Prop(Boolean) readonly compactLabel?: boolean;
  @Prop(String) readonly domId?: string;

  get mergedLabelClasses(): Record<string, boolean> {
    return {
      'col-sm-3': !!this.horizontal,
      'control-label': true,
      'w-max-content p-sm-l-none': this.hasLabel && !!this.invertLabel,
      'p-t-sm': this.hasLabel && !!this.invertLabel && !this.compactLabel,
      'p-t-xs': this.hasLabel && !!this.invertLabel && !!this.compactLabel,
      ...this.labelClass,
    };
  }

  get inputWrapperClasses(): Record<string, boolean> {
    return {
      'input-wrapper': true,
      'col-sm-9': !!this.horizontal,
      'w-max-content': this.hasLabel && !!this.invertLabel,
    };
  }

  get hasLabel(): boolean {
    return !!this.label || !!this.$slots['default'];
  }

  get inputWrapperTag() {
    return this.horizontal ? 'div' : 'span';
  }
}
