
  import type { SelectOption } from '@app/models/question-options/shared';
  import { Component, Prop } from 'vue-property-decorator';
  import BaseQuestionResponse from './base-question-response';
  import TextHighlight from 'vue-text-highlight';

  @Component({ components: { TextHighlight } })
  export default class MultiSelectFieldResponse extends BaseQuestionResponse {
    @Prop(Array) readonly values?: (SelectOption & { score?: string })[];
    @Prop(Array) readonly valuesText?: string[];
  }
