import { isSubFormRecordCollection } from './is-sub-form-record-collection';

export const isRecordCollection = (value?: Nullable<string | number>): boolean => {
  const valueAsString = `${value || ''}`;
  return (
    !!valueAsString &&
    (valueAsString.includes('record_collection_') ||
      isSubFormRecordCollection(valueAsString) ||
      valueAsString.startsWith('#records_~records_') ||
      valueAsString.startsWith('~records_') ||
      valueAsString.startsWith('#records_~related_') ||
      valueAsString.startsWith('~related_'))
  );
};
