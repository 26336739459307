export interface TableCalculationFieldValue {
  async_populated?: boolean;
  calculated?: Record<string, string>;
  data_sources?: Record<string, string>;
  // just to lock the field into readonly mode
  posted?: boolean;
  stage: TableCalculationFieldStage;
  table_calculation_source_record_id_hash?: Record<string, string>;
  value?: string;
  variable_data?: Record<string, string>;
}

export enum TableCalculationFieldStage {
  complete = 'complete',
  draft = 'draft',
  empty = 'empty',
}
