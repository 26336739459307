
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import TextHighlight from 'vue-text-highlight';
  import type { DetailsLink } from './models';
  import { toText } from './utils';

  @Component({ components: { TextHighlight } })
  export default class PaperTrailsDetailLink extends Vue {
    @Prop(Object) readonly link!: DetailsLink;
    @Prop(Array) readonly queries!: string[];
    @Prop(Boolean) readonly showDivider?: boolean;
    @Prop(Boolean) readonly withoutPrefix?: boolean;
    @Prop({ type: String, default: () => ', ' }) readonly divider!: string;

    toText = toText;
  }
