
  import { toaster } from '@app/utils/toaster';
  import { Component } from 'vue-property-decorator';
  import { Tooltip } from 'uiv';
  import { orderBy } from 'lodash';
  import type { MatrixFieldValue } from '@app/models/question-response-types';
  import type { Matrix } from '@app/models/matrix/matrix';
  import type { MatrixAxis } from '@app/models/matrix/matrix-axis';
  import type { MatrixOutcome } from '@app/models/matrix/matrix-outcome';
  import type { Dictionary } from '@app/models/dictionary';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubFormResponse } from '@app/models/sub-form-response';
  import type { FieldType } from '@app/models/sub-form-question';
  import type { OnlyOptions } from '@app/services/donesafe-api-utils';

  import BaseField from './base-field';

  @Component({ components: { Tooltip } })
  export default class MatrixField extends BaseField<FieldType.matrix> {
    localValue?: MatrixFieldValue = {};
    matrix: Matrix | null = null;
    moduleName: Pick<ModuleName, 'id' | 'main_form'> | null = null;
    matrixLoading = false;

    get detailsOnTop(): boolean {
      return this.matrix?.options?.details_on_top === 'true';
    }

    get hideDetails(): boolean {
      return this.matrix?.options?.hide_details === 'true';
    }

    get localValueOutcome(): string {
      return this.matrixCellOutcome?.outcome || (this.$t('app.labels.na') as string);
    }

    get localValueScore(): string {
      return `${this.matrixCellOutcome?.score || (this.$t('app.labels.na') as string)}`;
    }

    get matrixCellOutcome() {
      return this.matrix?.outcomes?.find(
        ({ matrix_cell_outcome_id }) => `${matrix_cell_outcome_id}` === `${this.localValue?.matrix_cell_outcome_id}`
      );
    }

    get outcomesHash(): Dictionary<MatrixOutcome> {
      if (!this.matrix) {
        return {};
      }
      return this.matrix.outcomes.reduce<Dictionary<MatrixOutcome>>((m, o) => ({ ...m, [o.position]: o }), {});
    }

    get restrictResponse(): SubFormResponse | undefined {
      if (this.record && this.restrictType === 'x_axis' && 'sub_form_completion' in this.record) {
        return this.record.sub_form_completion?.sub_form_responses.find((r) => r.sub_form_question_field_type === 'matrix');
      }
    }

    get restrictType(): string | undefined {
      return this.question.config.restrict_to?.restrict_type;
    }

    get restrictValue(): MatrixOutcome | undefined {
      const { matrix_cell_outcome_id: restrictId } = (this.restrictResponse?.response || {}) as MatrixFieldValue;

      return this.matrix?.outcomes.find(({ matrix_cell_outcome_id }) => restrictId === matrix_cell_outcome_id);
    }

    get restrictionAxis(): MatrixAxis | undefined {
      const restrictValue = this.restrictValue;

      if (!restrictValue) return;

      return this.xAxes.find((x) => {
        return this.yAxes.find((y) => {
          const { matrix_cell_outcome_id } = this.getOutcome(x, y) || {};

          return `${matrix_cell_outcome_id}` === `${restrictValue.matrix_cell_outcome_id}`;
        });
      });
    }

    get xAxes(): MatrixAxis[] {
      return orderBy(
        (this.matrix?.matrix_axis || []).filter((a) => a.direction === 'x'),
        'score'
      );
    }

    get yAxes(): MatrixAxis[] {
      return orderBy(
        (this.matrix?.matrix_axis || []).filter((a) => a.direction === 'y'),
        'score',
        'desc'
      );
    }

    cellBackgroundStyle(outcome?: MatrixOutcome): string {
      return outcome ? `background-color:${outcome.color} !important` : '';
    }

    cellTextStyle(outcome?: MatrixOutcome): string {
      return outcome ? `color:${outcome.text_color}` : '';
    }

    fetchData(): void {
      this.matrixLoading = true;
      this.$api
        .getMatrix(this.question.config.matrix_id, {}, { cache: true })
        .then(({ data }) => {
          this.matrix = data;
        })
        .catch(({ data }) => {
          toaster({ text: data?.error, position: 'top-right', icon: 'error' });
        })
        .finally(() => {
          this.matrixLoading = false;
        });
      if (this.record?.module_name_id) {
        const only: OnlyOptions<ModuleName> = ['id', 'main_form'];
        const include = ['main_form'];
        this.$api.getModuleName(this.record.module_name_id, { only, include }, { cache: true }).then(({ data }) => {
          this.moduleName = data;
        });
      }
    }

    getOutcome(xAxis: MatrixAxis, yAxis: MatrixAxis): MatrixOutcome | undefined {
      return this.outcomesHash[`${xAxis.id},${yAxis.id}`];
    }

    isActive(outcome?: MatrixOutcome): boolean {
      return !!this.localValue && !!outcome && `${this.localValue.matrix_cell_outcome_id}` === `${outcome.matrix_cell_outcome_id}`;
    }

    selectCell(outcome: MatrixOutcome): void {
      if (this.readonly) return;

      const { matrix_cell_outcome_id } = outcome;
      this.localValue = this.isActive(outcome) ? {} : { matrix_cell_outcome_id };
      this.$emit('input', this.sendUpdate(this.localValue));
    }

    showCell(xAxis: MatrixAxis, yAxis: MatrixAxis): boolean {
      const outcome = this.getOutcome(xAxis, yAxis);

      if (!outcome) return false;

      const { score: restrictScore } = this.restrictValue || {};

      if (!restrictScore) return true;

      const restrictAxis = this.restrictionAxis;

      return !!restrictAxis && restrictAxis.id === xAxis.id && parseInt(`${outcome.score}`) <= parseInt(`${restrictScore}`);
    }

    beforeMount(): void {
      this.localValue = { ...this.value };
      this.fetchData();
    }
  }
