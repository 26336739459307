
  import { Component, Emit } from 'vue-property-decorator';
  import type { SelectOption } from '@app/models/question-options/shared';
  import type { MultiSelectFieldValue } from '@app/models/question-response-types';
  import type { FieldType } from '@app/models/sub-form-question';

  import Select2 from '../select2.vue';

  import BaseField from './base-field';

  @Component({ components: { Select2 } })
  export default class MultiSelectField extends BaseField<FieldType.multi_select> {
    localValue: MultiSelectFieldValue = [];
    options: SelectOption[] = [];

    @Emit('input')
    onInput(value: string[]): MultiSelectFieldValue {
      this.localValue = value;
      this.sendUpdate(this.localValue);
      return this.localValue;
    }

    beforeMount(): void {
      this.localValue = (Array.isArray(this.value) && this.value) || [];
      this.options = Object.values(this.question.options.values || {});
      if (this.localValue.length) {
        const historicalOptions = Object.values(this.question.options.historical_values || {});
        this.localValue.forEach((value) => {
          const selectedOption = this.options.find((o) => o.key == value);
          if (!selectedOption && historicalOptions.length) {
            const historicalOption = historicalOptions.find((o) => o.key == value);
            if (historicalOption) {
              historicalOption.value = String(this.$t('app.labels.archived_name', { name: historicalOption.value }));
              this.options.push(historicalOption);
            }
          }
        });
      }
    }
  }
