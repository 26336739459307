
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { FORM_FIELD_DISPLAY_COMPONENTS } from '../form-fields-display/components';
  import { camelCase, upperFirst } from 'lodash';
  import type { BaseRecord } from '@app/models/module-record';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { SubFormResponse } from '@app/models/sub-form-response';
  import { CUSTOM_RESPONSE_TEMPLATE_TYPES, FieldType } from '@app/models/sub-form-question';

  @Component({ components: { ...FORM_FIELD_DISPLAY_COMPONENTS } })
  export default class SubFormResponseOutput extends Vue {
    @Prop({ required: true, type: Object }) readonly subFormResponse!: SubFormResponse;
    @Prop({ required: true, type: Object }) readonly subFormQuestion!: SubFormQuestion;
    @Prop(Boolean) readonly modal?: boolean;
    @Prop(Object) readonly record?: Pick<BaseRecord, 'id' | 'location_id'>;
    @Prop(Boolean) readonly isPublic?: boolean;
    @Prop(Boolean) readonly emptyFallback?: boolean;
    @Prop(Boolean) readonly forcePrintValue?: boolean; // instead of using custom component
    @Prop({ type: String, default: () => 'div' }) readonly tag!: 'p' | 'span' | 'div';
    @Prop(Boolean) readonly sidebarMode?: boolean;

    get componentName(): string {
      if (this.singleSelectResponse) return 'SingleSelectDisplay';

      return `${upperFirst(camelCase(this.subFormResponse.sub_form_question_field_type))}Display`;
    }

    get isCustomTemplate(): boolean {
      return CUSTOM_RESPONSE_TEMPLATE_TYPES.includes(this.subFormResponse.sub_form_question_field_type);
    }

    get singleSelectResponse(): boolean {
      return [FieldType.button_select, FieldType.single_select, FieldType.radio].includes(
        this.subFormResponse.sub_form_question_field_type
      );
    }

    get fallbackContent(): string {
      return this.$sanitize(this.subFormResponse.print_value || this.fallbackValue);
    }

    get fallbackValue(): string {
      return this.emptyFallback ? '' : this.$t('app.labels.na').toString();
    }
  }
