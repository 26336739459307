
  import { Component } from 'vue-property-decorator';
  import type { FieldType, ResponseValue } from '@app/models/sub-form-question';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import type { ModuleRecord } from '@app/models/module-record';
  import { convertInFormFilters } from '@app/utils/convert-in-form-filters';
  import { valueInResponse } from '@app/utils/value-in-response';
  import { isEmpty } from 'lodash';
  import { ConfiguratorFilterSource } from '@app/models/configurator-filter';
  import type { SubFormResponse } from '@app/models/sub-form-response';
  import { MODULE_RECORD_SHOW_ONLY } from '@app/pages/module-records/utils';
  import type { SubFormCompletion } from '@app/models/sub-form-completion';

  import ReportTable from './report-table.vue';
  import BaseFieldDisplay from './base-field-display';

  @Component({ components: { ReportTable } })
  export default class ReportDisplay extends BaseFieldDisplay<FieldType.report> {
    recordResponses?: SubFormResponse[];
    loaded: boolean = false;

    get filter(): DonesafeFilterOptions<ModuleRecord> {
      return {
        ...this.dynamicFilter,
        ...convertInFormFilters(this.question.config.filters, {
          record: {
            ...this.record,
            sub_form_completion: {
              ...(this.record.sub_form_completion || {}),
              sub_form_responses: this.recordResponses || [],
            } as SubFormCompletion,
          },
          user: this.currentUserStore.data,
          getCurrentFormValueByQuestion: this.getCurrentFormValueByQuestion,
        }),
      };
    }

    get dynamicFilter(): object {
      const { start_date: startDate, end_date: endDate } = this.response;

      return { startDate, endDate };
    }

    getCurrentFormValueByQuestion(filterValue: string, key: 'id' | 'code'): Maybe<ResponseValue> {
      if (!this.recordResponses) {
        return;
      }

      const [first] = this.recordResponses
        .filter(
          ({ sub_form_question_id, sub_form_question_code }) =>
            `${key === 'code' ? sub_form_question_code : sub_form_question_id}` === `${filterValue}`
        )
        .map(({ response }) => {
          const value = valueInResponse(response);
          const formattedCodeValue = value ? `${value}` : undefined;
          return Array.isArray(response) ? response.filter(Boolean) : formattedCodeValue;
        })
        .filter((v) => !isEmpty(v));

      return first;
    }

    async beforeMount() {
      if (this.question.config.filters?.some(({ source }) => source === ConfiguratorFilterSource.question_code)) {
        this.recordResponses = this.record.sub_form_completion?.sub_form_responses || (await this.loadResponses());
      }

      this.loaded = true;
    }

    async loadResponses() {
      const {
        data: {
          sub_form_completion: { sub_form_responses },
        },
      } = await this.$api.getModuleRecord(this.record.id, { only: MODULE_RECORD_SHOW_ONLY }, { cache: true, join: true });

      return sub_form_responses;
    }
  }
