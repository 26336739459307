
  import { Component, Model, Prop, Ref, Vue } from 'vue-property-decorator';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import { v4 as generateUUID } from 'uuid';

  @Component({
    components: { DsDropdown },
  })
  export default class DropdownSelect extends Vue {
    @Model('input') readonly value?: boolean;
    @Ref() readonly dropdown?: Vue;

    @Prop(Boolean) readonly menuRight?: boolean;
    @Prop(Boolean) readonly disabled?: boolean;
    @Prop(Boolean) readonly datetimeSelector?: boolean;

    dropdownElements: Element[] = [];

    uuid = generateUUID();

    onInput(opened: boolean): void {
      this.$nextTick(() => {
        this.$emit('input', opened);
      });
    }

    mounted(): void {
      if (this.dropdown) {
        this.$nextTick(() => {
          this.dropdown?.$el && this.dropdownElements.push(this.dropdown.$el);
          const dropdownMenus = this.dropdown?.$el?.querySelectorAll('.dropdown-menu');
          dropdownMenus?.forEach((dropdownMenu) => dropdownMenu?.classList?.add('dropdown-select-menu'));
          this.datetimeSelector && dropdownMenus?.[0]?.classList?.add('datetime-selector-wrapper');
        });
      }

      $(window).on(`select2:open.${this.uuid}`, (e) => {
        const select2Instance = $(e.target).data('select2');
        if (select2Instance && select2Instance?.dropdown?.$dropdownContainer?.[0]) {
          this.dropdownElements.push(select2Instance?.dropdown?.$dropdownContainer?.[0]);
        }
      });
      $(window).on(`select2:close.${this.uuid}`, (e) => {
        const select2Instance = $(e.target).data('select2');
        if (select2Instance && select2Instance?.dropdown?.$dropdownContainer?.[0]) {
          this.dropdownElements = this.dropdownElements.filter((element) => element !== select2Instance?.dropdown?.$dropdownContainer?.[0]);
        }
      });
    }

    beforeDestroy(): void {
      $(window).off(`select2:open.${this.uuid}`);
      $(window).off(`select2:close.${this.uuid}`);
    }
  }
