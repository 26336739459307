
  import { Component, Prop } from 'vue-property-decorator';
  import { toaster } from '@app/utils/toaster';
  import { HARDCODED_MODULE_CODES } from '@app/models/module-name';
  import type { AutomationDefinition } from '@app/models/automation-definition';
  import type { UnsubscriptionCollection } from '@app/models/unsubcription-collection';
  import type { TenantUser } from '@app/models/tenant-user';
  import type { AxiosPromise } from 'axios';
  import EntitySelector from '@app/components/entity-selector.vue';
  import { mixins } from 'vue-class-component';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import WithUser from '@app/mixins/with-user';

  @Component({
    components: { EntitySelector },
  })
  export default class UserNotificationSubscriptions extends mixins(WithUser) {
    @Prop(Boolean) readonly admin?: boolean;

    userUnsubscriptionCollectionIds: number[] = [];
    unsubscribedEventIds: number[] = [];
    unsubscribedLocationIds: number[] = [];
    unsubscribedLocationNames: string[] = [];
    automationSubscriptions: Record<string, { automation: AutomationDefinition; checked: boolean }[]> = {};
    unsubscriptionCollections: { checked: boolean; collection: UnsubscriptionCollection }[] = [];
    loading = true;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get canEdit() {
      return this.admin || this.currentUserStore.checkProfilePermission({ id: this.userId }, 'pay_details', 'edit');
    }

    beforeMount(): void {
      const userPromise = this.$api.getTenantUser(
        this.userId,
        { include: ['unsubscription_collection_ids', 'unsubscribed_event_ids', 'unsubscribed_location_ids'] },
        { cache: true }
      );

      const unsubscriptionCollectionsPromise = this.fetchUnsubscriptionCollections(userPromise);
      const automationsPromise = this.fetchAutomations(userPromise);
      const locationsPromise = this.fetchUnsubscribedLocations(userPromise);

      Promise.all([locationsPromise, unsubscriptionCollectionsPromise, automationsPromise]).then(() => {
        this.loading = false;
      });
    }

    async fetchUnsubscribedLocations(userPromise: AxiosPromise<TenantUser>) {
      const [{ data: user }] = await Promise.all([userPromise]);
      this.unsubscribedLocationIds = user?.unsubscribed_location_ids || [];

      if (!this.canEdit && this.unsubscribedLocationIds.length) {
        const { data: locations } = await this.$api.getLocations(
          { filters: { id: this.unsubscribedLocationIds }, only: ['id', 'name'] },
          { cache: true }
        );
        this.unsubscribedLocationNames = locations.map((location) => location.name);
      }
    }

    async fetchUnsubscriptionCollections(userPromise: AxiosPromise<TenantUser>) {
      const activeCollectionsPromise = this.$api.getUnsubscriptionCollections(
        { filters: { active: true }, include: ['user_collection'], only: ['id', 'name', 'user_collection_id'] },
        { cache: true }
      );

      const userCollectionsPromise = this.$api.getUnsubscriptionCollections(
        { filters: { subscribed: true }, only: ['id', 'name'] },
        { cache: true }
      );
      const [{ data: user }, { data: activeCollections }, { data: userCollections }] = await Promise.all([
        userPromise,
        activeCollectionsPromise,
        userCollectionsPromise,
      ]);

      this.userUnsubscriptionCollectionIds = user?.unsubscription_collection_ids || [];
      this.unsubscriptionCollections = [
        ...activeCollections.filter((collection) => !collection.user_collection_id),
        ...userCollections,
      ].map((collection) => ({
        collection: collection,
        checked: this.userUnsubscriptionCollectionIds.includes(collection.id),
      }));
    }

    async fetchAutomations(userPromise: AxiosPromise<TenantUser>) {
      const { data: moduleNames } = await this.$api.getModuleNames(
        { filters: { active: true, '!name': HARDCODED_MODULE_CODES }, only: ['name', 'display'] },
        { cache: true }
      );
      const { data: subFormIds } = await this.$api.getSubForms(
        { filters: { active: true, module_name: moduleNames.map((m) => m.name) }, only: ['id', 'module_name'] },
        { cache: true }
      );
      const subFormAutomationsPromise = this.$api.getAutomationDefinitions(
        {
          filters: { sub_form_id: subFormIds.map((s) => s.id), event_types: ['Activity', 'NotificationMessage'] },
          only: ['name', 'event_ids', 'sub_form_id'],
          include: ['event_ids'],
        },
        { cache: true }
      );

      const moduleNameAutomationsPromise = this.$api.getAutomationDefinitions(
        {
          filters: { concept_name: moduleNames.map((m) => m.name), event_types: ['Activity', 'NotificationMessage'] },
          only: ['name', 'event_ids', 'concept_name'],
          include: ['event_ids'],
        },
        { cache: true }
      );

      const [{ data: user }, { data: subFormAutomations }, { data: moduleNameAutomations }] = await Promise.all([
        userPromise,
        subFormAutomationsPromise,
        moduleNameAutomationsPromise,
      ]);

      this.unsubscribedEventIds = user?.unsubscribed_event_ids || [];
      moduleNames.forEach((moduleName) => {
        const moduleNameSubFormsIds = subFormIds.filter((subForm) => subForm.module_name === moduleName.name).map((subForm) => subForm.id);
        const automationsBySubForm = subFormAutomations.filter(
          (automation) => automation.sub_form_id && moduleNameSubFormsIds.includes(automation.sub_form_id)
        );
        const automationsByModuleName = moduleNameAutomations.filter((automation) => automation.concept_name === moduleName.name);
        const resultAutomations = [...automationsBySubForm, ...automationsByModuleName];

        if (resultAutomations.length) {
          this.automationSubscriptions[moduleName.display] = resultAutomations.map((automation) => ({
            automation: automation,
            checked: !!automation.event_ids && !this.unsubscribedEventIds.includes(automation.event_ids[0]),
          }));
        }
      });
    }

    submitForm(): void {
      const params: Partial<TenantUser> = { unsubscribed_location_ids: this.unsubscribedLocationIds };
      if (this.unsubscriptionCollections.length) {
        params['unsubscription_collection_ids'] = this.unsubscriptionCollections
          .filter((item) => !item.checked)
          .map((item) => item.collection.id);
      } else {
        params['unsubscribed_event_ids'] = Object.values(this.automationSubscriptions).reduce(
          (acc, item) => [
            ...acc,
            ...item
              .filter((item) => !item.checked)
              .map((item) => item.automation?.event_ids?.[0])
              .filter((eventId): eventId is number => eventId !== undefined),
          ],
          [] as number[]
        );
      }

      this.$api
        .updateTenantUser(this.userId, params)
        .then(() => {
          toaster({
            text: this.$t('tenant.admin.users.sections.tab_notifications.success'),
            position: 'top-right',
          });
        })
        .catch(({ data }) => {
          toaster({ text: data?.error, position: 'top-right', icon: 'error' });
        });
    }
  }
