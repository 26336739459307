
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import { Pagination } from 'uiv';

  @Component({ components: { Pagination } })
  export default class PaperTrailsPagination extends Vue {
    @Model('input', { type: Number }) readonly currentPage!: number;
    @Prop(Number) readonly totalPage!: number;
    @Prop(Boolean) readonly disabled?: boolean;
  }
