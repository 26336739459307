
  import { Component, Prop, Vue, Ref, Model } from 'vue-property-decorator';
  import { ValidationProvider, ValidationObserver } from 'vee-validate';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import type { Password, TenantUser } from '@app/models/tenant-user';
  import DsModal from '@app/components/ds-modal.vue';
  import { toaster } from '@app/utils/toaster';

  @Component({
    components: {
      ValidationProvider,
      ValidationObserver,
      DsModal,
    },
  })
  export default class UserChangePasswordButton extends Vue {
    @Model('input') readonly value!: boolean;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;
    @Prop() readonly user!: TenantUser;
    submitting = false;
    modalVisible = false;

    form: Password = {
      password: '',
      current_password: '',
      password_confirmation: '',
    };

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get passwordChangeRequired(): boolean {
      return !!this.form.password;
    }

    valid(formErrors: Record<string, string[] | string>): boolean {
      return (
        this.form.password !== '' &&
        this.form.password_confirmation !== '' &&
        this.form.current_password !== '' &&
        !(formErrors && formErrors.password && formErrors.password.length) &&
        !(formErrors.password_confirmation && formErrors.password_confirmation.length) &&
        !(formErrors.current_password && formErrors.current_password.length)
      );
    }

    resetForm(): void {
      this.form = {
        password: '',
        current_password: '',
        password_confirmation: '',
      };
    }

    toggleModal(value: boolean = true): void {
      this.modalVisible = value;
    }

    onPasswordModalSubmit() {
      this.validator?.validate().then((valid) => {
        if (valid) {
          this.submitting = true;
          const params = this.form as Partial<TenantUser>;
          this.$api
            .updateTenantUser(this.user.id, { ...params })
            .then(() => {
              toaster({
                text: this.$t('app.user_details.password_changed_successfully'),
                position: 'top-right',
              });
              this.modalVisible = false;
            })
            .catch(({ data }) => {
              toaster({ text: data?.error, position: 'top-right', icon: 'error' });
            })
            .finally(() => {
              this.submitting = false;
            });
        }
      });
    }
  }
