import type { IndicatorSet } from '@app/models/indicator-set';
import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';

export class IndicatorSetService extends BaseService<IndicatorSet> {
  async fetchDetails({ itemId }: ServiceRequest<IndicatorSet>): Promise<DetailsServiceResponse> {
    const { data: indicatorSet } = await this.api.getIndicatorSet(itemId, { only: ['id', 'name'] }, { join: true, cache: true });
    return {
      links: [
        {
          link: `/admin/settings/indicator_sets/${indicatorSet.id}`,
          prefix: this.t('app.labels.indicator_set'),
          title: indicatorSet.name,
          id: indicatorSet.id,
        },
      ],
    };
  }
}
