import type { DurationInputArg2 } from 'moment';
import type { BaseEntity } from './base-entity';

export enum IntervalType {
  Custom = 'custom',
  Daily = 'daily',
  Monthly = 'monthly',
  Once = 'once',
  Weekly = 'weekly',
  Yearly = 'yearly',
}

export interface Schedule extends BaseEntity {
  interval: IntervalType;
  interval_split: number;
  next_schedule_date?: string;
  // legacy
  reoccurence?: string;
  schedule_type?: string;
  starts_on?: string;
}

export const INTERVAL_TO_UNITS: Record<Schedule['interval'], DurationInputArg2 | undefined> = {
  daily: 'days',
  weekly: 'weeks',
  monthly: 'months',
  yearly: 'years',
  custom: undefined,
  once: undefined,
};

const diffDays = (date1: Date, date2: Date): number => {
  const diff = Math.abs(date1.getTime() - date2.getTime());
  return Math.ceil(diff / (1000 * 60 * 60 * 24));
};

const activeDate = (schedule: Schedule, datetime: Date): boolean => {
  const date = datetime;
  const newStartsOn = schedule.starts_on ? new Date(schedule.starts_on) : new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);

  if (date < newStartsOn) return false;

  switch (schedule.interval) {
    case IntervalType.Once:
      return date.getTime() === newStartsOn.getTime();
    case IntervalType.Daily:
      return diffDays(date, newStartsOn) % schedule.interval_split === 0;
    case IntervalType.Weekly:
      return diffDays(date, newStartsOn) % (schedule.interval_split * 7) === 0;
    case IntervalType.Monthly:
      const monthDifference = date.getFullYear() * 12 + date.getMonth() - (newStartsOn.getFullYear() * 12 + newStartsOn.getMonth());
      return monthDifference % schedule.interval_split === 0 && date.getDate() === newStartsOn.getDate();
    case IntervalType.Yearly:
      return (date.getFullYear() - newStartsOn.getFullYear()) % schedule.interval_split === 0 && date.getDay() === newStartsOn.getDay();
    default:
      return false;
  }
};

export const allowedDatesBetween = (schedule: Schedule, startDate: Date, endDate: Date): Date[] | undefined => {
  if (!startDate || !endDate) return;

  let start = startDate;
  if (schedule.starts_on && new Date(schedule.starts_on) > startDate) start = new Date(schedule.starts_on);

  const result: Date[] = [];
  const current = new Date(start);

  while (current <= endDate) {
    if (activeDate(schedule, current)) {
      result.push(new Date(current));
    }
    current.setDate(current.getDate() + 1);
  }

  return result;
};
