
  import PaperTrailsModal from '@app/components/paper-trails/paper-trails-modal.vue';
  import WithAuditTrailModal from '@app/mixins/with-audit-trail-modal';
  import { mixins } from 'vue-class-component';
  import { Component, Prop } from 'vue-property-decorator';
  import DsIconText from '@app/components/ds-icon-text.vue';
  import type { PaperTrailsFilters } from '@app/models/paper-trail-version';
  import { DEFAULT_FILTERS } from '@app/models/paper-trail-version';

  @Component({
    components: { PaperTrailsModal, DsIconText },
  })
  export default class PaperTrailsModalLink extends mixins(WithAuditTrailModal) {
    @Prop({ type: String, default: () => 'div' }) readonly tag!: string;
    @Prop([String, Number, Array]) readonly recordId!: string | number | string[] | number[];
    @Prop(String) readonly recordType!: string;
    @Prop(String) readonly modalTitle?: string;
    @Prop(String) readonly linkClass?: string; // TODO: find another way
    @Prop(Array) readonly attributeScope?: string[];
    @Prop({ type: Object, default: () => DEFAULT_FILTERS }) readonly filters!: PaperTrailsFilters;
  }
