
  import { useAccountStore } from '@app/stores/account';
  import { Component, Emit, Model, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
  import moment from 'moment';
  import type DateTimePicker from '@app/components/date-time-picker.vue';
  import type YearPickerButton from '@app/components/year-picker-button.vue';

  type DateFilterFormat = 'all' | 'month' | 'week' | 'today' | string | [string | undefined, string | undefined];

  @Component
  export default class WithDateFilter extends Vue {
    @Model('input') readonly value!: DateFilterFormat;
    @Prop(Boolean) readonly disabled?: boolean;
    @Ref() readonly startDateElement?: DateTimePicker;
    @Ref() readonly yearPickerButton?: YearPickerButton;
    @Ref() readonly endDateElement?: DateTimePicker;

    dropdownElements: (Element | Vue)[] = [];
    localFilters: DateFilterFormat = 'all';
    dropdownVisible = false;
    showYearFilter = false;
    yearDate: Date | null = this.yearDateValue;

    get accountStore() {
      return useAccountStore();
    }

    get startDate(): Maybe<Date> {
      if (Array.isArray(this.localFilters) && this.localFilters[0]) {
        return this.toDate(this.localFilters[0]);
      }
    }

    get endDate(): Maybe<Date> {
      if (Array.isArray(this.localFilters) && this.localFilters[1]) {
        return this.toDate(this.localFilters[1]);
      }
    }

    get yearDateValue(): Date | null {
      if (this.yearDate) return this.yearDate;

      if (!Array.isArray(this.localFilters) && parseInt(this.localFilters as string)) {
        return new Date(parseInt(this.localFilters as string), 0, 1);
      }

      return null;
    }

    @Watch('value', { deep: true, immediate: true })
    onFiltersUpdate(value: DateFilterFormat): void {
      this.localFilters = value;
    }

    @Emit('input')
    submitDateForm(): DateFilterFormat {
      return Array.isArray(this.localFilters) ? (this.localFilters.some(Boolean) ? this.localFilters : 'all') : this.localFilters;
    }

    onDateChange(date: Maybe<Date>, isStartDate: boolean): void {
      this.yearDate = null; // clear reset year selection
      const newFilter = Array.isArray(this.localFilters) ? this.localFilters : ['', ''];
      const formattedDate = date ? this.accountStore.dateTimeFormatWithTimeZone(moment(date).format()) : '';
      this.localFilters = isStartDate ? [formattedDate, newFilter[1]] : [newFilter[0], formattedDate];
    }

    onYearDateChange(date: Date | null): void {
      this.yearDate = date;

      if (date) {
        this.localFilters = [this.getBeginningOfYear(date), this.getEndOfYear(date)];
        this.clearDropdownElements();
        this.submitDateForm();
        this.dropdownVisible = false;
        jQuery('body').trigger('click');
      }
    }

    getBeginningOfYear(date: Date): Maybe<string> {
      const beginningOfYear = moment(date).startOf('year').format();
      return this.accountStore.dateTimeFormat(beginningOfYear);
    }

    getEndOfYear(date: Date): Maybe<string> {
      const endOfYear = moment(date).endOf('year').format();
      return this.accountStore.dateTimeFormat(endOfYear);
    }

    isRangeAFullYear(startDate: Maybe<Date>, endDate: Maybe<Date>): boolean {
      if (startDate?.getFullYear() != endDate?.getFullYear()) return false;
      const startOfYear = moment(startDate).startOf('year').format();
      const endOfYear = moment(endDate).endOf('year').seconds(0).format();

      const localStartDate = moment(this.startDate).format();
      const localEndDate = moment(this.endDate).format();
      return localStartDate == startOfYear && localEndDate == endOfYear;
    }

    onStartDateChange(date: Maybe<Date>): void {
      this.onDateChange(date, true);
    }

    onEndDateChange(date: Maybe<Date>): void {
      this.onDateChange(date, false);
    }

    toDate(date: string | undefined): Maybe<Date> {
      return (date && moment(date, this.accountStore.dateTimePickerFormatWithTimeZone).toDate()) || undefined;
    }

    onDropdownInput(value: boolean): void {
      this.dropdownVisible = value;

      this.$nextTick(() => {
        if (value && !this.dropdownElements.length) this.setDropdownElements();
        if (!value) this.clearDropdownElements();
      });
    }

    clearDropdownElements(): void {
      this.dropdownElements = [];
    }

    setDropdownElements() {
      this.startDateElement && this.dropdownElements.push(this.startDateElement?.$el);
      this.endDateElement && this.dropdownElements.push(this.endDateElement?.$el);
      this.yearPickerButton && this.dropdownElements.push(this.yearPickerButton?.$el);
    }
  }
