import type { DetailsServiceResponse, ServiceRequest } from '@app/components/paper-trails/services/base-service';
import { BaseService } from '@app/components/paper-trails/services/base-service';
import type { Account } from '@app/models/account';

export class AccountService extends BaseService<Account> {
  static readonly authenticationAndSSOAttributes: (keyof Account)[] = [
    'mfa',
    'saml_as_default_login',
    'default_sso_page_enabled',
    'force_password_change',
    'expire_password_after',
    'password_archiving_count',
    'password_policy',
  ];

  static readonly restrictionMethodAttributes: (keyof Account)[] = [
    'limit_permissions_by_location',
    'limit_permissions_by_organization',
    'limit_permissions_by_explicit_locations',
  ];

  static readonly userSettingsAttributes: (keyof Account)[] = [
    'display_company_register_field',
    'display_position_field',
    'display_date_of_birth',
    'display_mobile_number',
    'display_employment_start_date',
    'display_employment_separation_date',
    'show_pay_details_tab',
    'user_information_options',
    'contractor_confirmation_email_subject',
    'contractor_confirmation_email',
  ];

  static readonly accountSettingsAttributes: (keyof Account)[] = [
    'timezone',
    'complex_timezones',
    'apply_permission_to_selectors',
    'hide_inactive_olu_for_filters',
    'user_selector_filter_restrictions',
    'fallback_location_id',
    'fallback_organization_id',
    'dynamic_dashboard_settings',
    'disable_location_tag_report',
  ];

  static readonly actionSettingsAttributes: (keyof Account)[] = ['action_options'];

  static readonly allowedAttributes: (keyof Account)[] = [
    ...AccountService.authenticationAndSSOAttributes,
    ...AccountService.restrictionMethodAttributes,
    ...AccountService.userSettingsAttributes,
    ...AccountService.accountSettingsAttributes,
    ...AccountService.actionSettingsAttributes,
  ];

  async fetchDetails({}: ServiceRequest<Account>): Promise<DetailsServiceResponse> {
    const account = { ...this.account } as Account;

    return {
      // TODO:
      mainText: `${account.name}`,
    };
  }
}
