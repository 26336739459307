import type { TabSectionGroup } from '@app/models/tab-section-group';
import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';

export class TabSectionGroupService extends BaseService<TabSectionGroup> {
  async fetchDetails({ itemId }: ServiceRequest<TabSectionGroup>): Promise<DetailsServiceResponse> {
    const { data: group } = await this.api.getTabSectionGroup(itemId, { only: ['id', 'name', 'code'] }, { join: true, cache: true });

    return {
      links: [
        {
          prefix: this.t('app.labels.tab_section_group'),
          title: group.name,
          id: group.id,
        },
      ],
    };
  }
}
