
  import WithToggleButtonDomEvents from '@app/mixins/with-toggle-button-dom-events';
  import { Component, Prop } from 'vue-property-decorator';
  import { ToggleButton } from 'vue-js-toggle-button';
  import type { DefinitionOptionKey, DefinitionOptions, DisplayItem, DisplayItemType } from '@app/models/calculator';

  import { LogicElementTypes } from '../rule-builder/models/types';

  @Component({
    components: {
      ToggleButton,
    },
  })
  export default class ItemsToDisplay extends WithToggleButtonDomEvents {
    @Prop(Array) readonly items!: DisplayItem[];
    @Prop() readonly definitions?: DefinitionOptions;

    displayElementType = false;

    get elementNameMap(): Record<DisplayItemType, { displayName: string; nameKey: DefinitionOptionKey }> {
      return {
        parentRule: {
          nameKey: 'parent_rule',
          displayName: this.$t('app.labels.parent_rule').toString(),
        },
        component: {
          nameKey: 'components',
          displayName: this.$t('app.labels.component').toString(),
        },
        variable: {
          nameKey: 'variable_definitions',
          displayName: this.$t('app.labels.variable').toString(),
        },
        rule: {
          nameKey: 'rules',
          displayName: this.$t('app.labels.rule').toString(),
        },
      };
    }

    toggleDisplayElementType(): void {
      this.displayElementType = !this.displayElementType;
    }

    getDisplayItemType(displayItem: string): DisplayItemType | undefined {
      if (displayItem === 'parent_rule') {
        return 'parentRule';
      } else if (displayItem[0] === '@') {
        return 'component';
      } else if (displayItem[0] === '#') {
        return 'variable';
      } else if (displayItem[0] === '~') {
        return 'rule';
      }
    }

    getItemName(displayItem: string): string {
      const displayItemType = this.getDisplayItemType(displayItem);
      if (!displayItemType) {
        return displayItem;
      }
      const map = this.elementNameMap[displayItemType];
      if (!map || !this.definitions) {
        return displayItem;
      }
      const options =
        displayItemType === LogicElementTypes.PARENT_RULE ? this.definitions[map.nameKey] : this.definitions[map.nameKey][displayItem];
      return `${options.name} ${this.displayElementType ? ` (${map.displayName})` : ''}`;
    }

    convertFormulaArrayToString(formulas: string[], topLevel: boolean): string {
      const decodedFormula = formulas.map((formulaItem) => {
        if (!formulaItem) {
          return this.$t('app.labels.no_value');
        }

        return formulaItem.constructor === Array ? this.convertFormulaArrayToString(formulaItem, false) : this.getItemName(formulaItem);
      });

      let formulaString = decodedFormula.join(' ');
      // don't include most outer brackets
      if (!topLevel) formulaString = `( ${formulaString} )`;

      return formulaString;
    }
  }
