
  import { Component } from 'vue-property-decorator';
  import BaseFieldDisplay from './base-field-display';
  import type { FieldType } from '@app/models/sub-form-question';
  import type { ModuleRecord } from '@app/models/module-record';

  @Component({
    components: {},
  })
  export default class MainFormRelationDisplay extends BaseFieldDisplay<FieldType.main_form_relation> {
    relatedRecord: Nullable<Pick<ModuleRecord, 'id' | 'title'>> = null;

    beforeMount(): void {
      if (this.subFormResponse.response.value) {
        const id = this.subFormResponse.response.value;
        if (!this.isPublic) {
          this.$api
            .getModuleRecord(Number(id), { only: ['id', 'title'] }, { cache: true, join: true })
            .then(({ data }) => {
              this.relatedRecord = data;
            })
            .catch(() => {
              this.relatedRecord = { id: id as number, title: '' };
            });
        } else {
          this.relatedRecord = { id: id as number, title: '' };
        }
      }
    }
  }
