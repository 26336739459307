
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { Component, Emit, Model, Prop, Ref, Vue } from 'vue-property-decorator';
  import type { OptionalSignatureData } from '@app/services/api/record-signatures-api';
  import DsModal from '@app/components/ds-modal.vue';
  import type { Signature } from '@app/models/signature';

  import SignaturePad from './signature-pad.vue';

  @Component({ components: { SignaturePad, DsModal } })
  export default class SignatureFormModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Prop(Boolean) readonly addToProfile?: boolean;
    @Prop(Boolean) readonly loading?: boolean;
    @Prop(Boolean) readonly quickAddOwnSignature?: boolean;
    @Ref() readonly signaturePad?: SignaturePad;

    currentSignature: Nullable<Signature> = null;
    fetching = false;
    form = { signature: '' };
    height = '150px';
    width = '300px';

    get currentSignatureUrl(): string | undefined {
      return this.currentSignature?.url;
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get insertAndSaveBtnSignaturePresent(): boolean {
      return !!(this.currentSignatureUrl || !this.form.signature);
    }

    get insertBtnSignaturePresent(): boolean {
      if (!this.quickAddOwnSignature && !!this.currentSignatureUrl) {
        return true;
      } else {
        return !(this.form.signature || this.currentSignatureUrl);
      }
    }

    @Emit('submit')
    submitForm(): OptionalSignatureData {
      return {
        signature_data: this.form.signature,
        signature_id: this.currentSignature?.id,
      };
    }

    clearData(): void {
      this.currentSignature = null;
      this.form.signature = '';
    }

    clearSignature(): void {
      this.clearData();
      this.signaturePad?.clear();
    }

    fetchSignature(): void {
      if (this.quickAddOwnSignature && this.currentUserStore.data?.primary_signature_id) {
        this.fetching = true;
        this.$api
          .getSignature(this.currentUserStore.data?.primary_signature_id)
          .then(({ data }) => {
            this.currentSignature = data;
          })
          .finally(() => (this.fetching = false));
      }
    }

    initForm(): void {
      this.clearData();
      this.fetchSignature();
      $(document).trigger('donesafe:signature-visibility-changed');
    }

    insertToProfile(): void {
      this.$api.createSignature({ signature_data: this.form.signature }).then(({ data }) =>
        this.$api.updateTenantUser(this.currentUserStore.data?.id as number, { primary_signature_id: data.id }).then(() => {
          this.currentUserStore.$patch({ data: { ...this.currentUserStore.data, primary_signature_id: data.id } });
          this.$emit('submit', { signature_id: data.id });
        })
      );
    }

    updateSignature(data: string): void {
      this.form.signature = data;
    }
  }
