import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { PlaceholderProfile } from '@app/models/placeholder-profile';

export class PlaceholderProfileService extends BaseService<PlaceholderProfile> {
  async fetchDetails({ itemId }: ServiceRequest<PlaceholderProfile>): Promise<DetailsServiceResponse> {
    const { data: placeholderProfile } = await this.api.getPlaceholderProfile(
      itemId,
      { only: ['id', 'name'] },
      { join: true, cache: true }
    );
    return {
      links: [
        {
          link: `/admin/settings/placeholder_profiles/${placeholderProfile.id}/edit`,
          prefix: this.t('app.labels.placeholder_profile'),
          title: placeholderProfile.name,
          id: placeholderProfile.id,
        },
      ],
    };
  }
}
