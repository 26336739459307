import type { RecordCalculation } from '@app/models/record-calculation';
import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';

export class RecordCalculationService extends BaseService<RecordCalculation> {
  static readonly allowedAttributes = [
    'calculation_class',
    'calculation_method',
    'data_source',
    'formula',
    'id',
    'index',
    'module_name_id',
    'name',
    'options',
    'permission_check',
    'sub_form_question_code',
    'variable_code',
  ];

  async fetchDetails({ itemId }: ServiceRequest<RecordCalculation>): Promise<DetailsServiceResponse> {
    const { data: calculation } = await this.api.getRecordCalculation(
      itemId,
      { only: ['id', 'name', 'module_name_id'] },
      { join: true, cache: true }
    );
    return {
      links: [
        {
          link: `/admin/settings/module_names/${calculation.module_name_id}/record_calculations/${calculation.id}/edit`,
          prefix: this.t('app.labels.record_calculation'),
          title: calculation.name,
          id: calculation.id,
        },
      ],
    };
  }

  async fetchEntity({ itemId }: ServiceRequest<RecordCalculation>): Promise<RecordCalculation> {
    const { data: recordCalculation } = await this.api.getRecordCalculation(
      itemId,
      { only: ['id', 'name', 'module_name_id'] },
      { cache: true, join: true }
    );
    return recordCalculation;
  }

  entityToText({ itemId }: ServiceRequest<RecordCalculation>, entity?: RecordCalculation): string {
    return entity ? `${entity.name} [${entity.id}]` : `${itemId}`;
  }
}
