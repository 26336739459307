import { API_NULL } from '@app/constants';
import type { AxiosPromise } from 'axios';
import { uniq } from 'lodash';
import type { BaseEntity } from '@app/models/base-entity';
import qs from 'qs';
import { ListManagerBase } from './list-manager-base';
import type { DonesafeFilterOptions, FilterValue } from '@app/services/donesafe-api-utils';
import type { StringBoolean } from '@app/utils/types/string-boolean';
import type {
  ListManagerFetchDataFunction,
  ListManagerFetchDataParams,
  ListManagerPaginationParams,
  ListManagerParams,
  ListManagerSortItem,
  SortDirection,
} from './types';

export class ListManager<T = BaseEntity, F = {}> extends ListManagerBase<T, F> {
  useHistory = false;
  filters: DonesafeFilterOptions<T, F>;
  fetchDataFunction: ListManagerFetchDataFunction<T, F>;
  afterFetch?: (data: T[]) => void;
  debounceTime: number;

  constructor(params: ListManagerParams<T, F>) {
    super({ ...params });
    this.useHistory = !!params.useHistory;
    this.fetchDataFunction = params.fetchDataFunction;
    this.afterFetch = params.afterFetch;
    this.filters = { ...params.filters } as DonesafeFilterOptions<T, F>;
    this.customFilters = { ...params.customFilters } as DonesafeFilterOptions<T, F>;
    this.debounceTime = params.debounceTime || 1000;

    if (this.useHistory) {
      this.fixQueryParams();
      this.updateFromQueryParams();
      window.onpopstate = this.updateFromQueryParams.bind(this);
    }
  }

  fixQueryParams(): void {
    if (window.location.search.indexOf('.html') > -1) {
      window.history.replaceState(null, '', window.location.search.replace('.html', ''));
    }
  }

  updateFromQueryParams(): void {
    const parseOptions = { ignoreQueryPrefix: true, comma: false };
    const historyParams = qs.parse(window.location.search, parseOptions);
    if (historyParams.per_page) {
      this.per_page = parseInt(historyParams.per_page as string);
    }
    if (historyParams.page) {
      this.page = parseInt(historyParams.page as string);
    }
    const newSortOrder = this.parseSort(historyParams.sort as string, historyParams.reverse as StringBoolean);
    if (newSortOrder && !!newSortOrder.length) {
      this.sortOrder = newSortOrder;
    }

    this.customFilters = {
      ...(historyParams.filter as object),
      ...(historyParams.filters as object),
    } as DonesafeFilterOptions<T, F>;
  }

  hasBlankFilterOption(key: keyof T | `!${string & keyof T}`): boolean {
    const value = this.customFilters[key];
    if (Array.isArray(value)) {
      return value.some((v) => v === API_NULL);
    }
    return value === API_NULL;
  }

  setBlankFilterOption(key: keyof T | `!${string & keyof T}`, blankValue: boolean): void {
    const oldValue = this.customFilters[key];
    const value = Array.isArray(oldValue) ? (oldValue as FilterValue[]) : oldValue ? [oldValue] : [];
    const newValue = blankValue ? [...value, API_NULL] : value.filter((v) => v !== API_NULL);
    this.customFilters = { ...this.customFilters, [key]: uniq(newValue) };
  }

  nonBlankFilters(key: keyof T | `!${string & keyof T}`): FilterValue | undefined {
    const value = this.customFilters[key];
    if (Array.isArray(value)) {
      return value.filter((v) => v !== API_NULL);
    }
    return value as FilterValue; // TODO
  }

  setNonBlankFilter(key: keyof T | `!${string & keyof T}`, value?: FilterValue): void {
    const oldValue = this.customFilters[key];
    if (Array.isArray(oldValue)) {
      const existingNull = oldValue.includes(API_NULL) ? [API_NULL] : [];
      const newValue = Array.isArray(value) ? value : value ? [value] : [];
      this.customFilters = { ...this.customFilters, [key]: [...existingNull, ...newValue] };
    } else {
      this.customFilters = { ...this.customFilters, [key]: value };
    }
  }

  getSort(sortOrder?: ListManagerSortItem[]): Maybe<string> {
    if (sortOrder) {
      return sortOrder
        .map((sort) => {
          return (sort.direction === 'desc' ? '-' : '') + sort.sortField;
        })
        .join(',');
    }
  }

  parseSort(sort?: string, reverse?: StringBoolean): Maybe<ListManagerSortItem[]> {
    if (sort) {
      return sort.split(',').reduce<ListManagerSortItem[]>((memo, sortString) => {
        if (sortString) {
          let column = sortString;
          let direction: SortDirection = 'asc';

          if (column[0] === '-' || reverse === 'true') {
            direction = 'desc';
            column = column.replace('-', '');
          }

          const field = this.fields.find((f) => f.sortField && f.sortField === column);
          if (field && typeof field.name === 'string') {
            memo.push({ sortField: field.sortField as string, field: field.name, direction });
          }
        }
        return memo;
      }, []);
    }
  }

  refresh(goToFirstPage?: boolean): void {
    this.fetchData(this.sortOrder || [], { current_page: (goToFirstPage && 1) || this.page, per_page: this.per_page as number });
  }

  changePage(page: number): void {
    this.page = page;
    this.fetchData(this.sortOrder || [], { current_page: page, per_page: this.per_page as number });
  }

  fetchData(sort: ListManagerSortItem[], pagination: ListManagerPaginationParams): AxiosPromise<T[]> {
    this.fetching = true;
    if (this.useHistory) {
      const query = qs.parse(window.location.search, { ignoreQueryPrefix: true, comma: true });
      delete query.filter;
      const queryString = qs.stringify(
        {
          ...query,
          filters: this.customFilters,
          page: pagination.current_page,
          sort: this.getSort(sort),
          per_page: pagination.per_page,
        },
        { addQueryPrefix: true, arrayFormat: 'brackets' }
      );
      if (queryString !== window.location.search) {
        window.history.replaceState(null, '', queryString);
      }
    }
    return this.fetchDataFunction({
      filters: { ...this.customFilters, ...this.filters },
      page: pagination.current_page,
      per_page: pagination.per_page,
      sort: this.getSort(sort),
    } as ListManagerFetchDataParams<T, F>)
      .then((data) => {
        this.total = +data.headers?.['total'] || 0;
        this.items = data.data;
        this.pagination = this.makePagination(this.total, pagination.per_page, pagination.current_page);
        this.afterFetch && this.afterFetch(this.items);
        return data;
      })
      .finally(() => {
        this.fetching = false;
      });
  }
}
