
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import { isEmpty } from 'lodash';
  import Select2 from '../select2.vue';
  import type { ToocsValue } from '@app/models/toocs/toocs-value';
  import type { Dictionary } from '@app/models/dictionary';
  import type { QuickModeOption, QuickModeOptions, ToocsSectionQuickMode } from './models';

  @Component({ components: { Select2 }, methods: { isEmpty } })
  export default class ToocsQuickSubPicker extends Vue {
    @Prop(Boolean) required!: boolean;
    @Prop(Boolean) disabled!: boolean;
    @Prop(String) name!: string;
    @Prop(Object) section!: ToocsSectionQuickMode;
    @Prop(Number) sectionId!: number;
    @Prop(Object) readonly value!: ToocsValue;
    @Prop(Object) fullValue!: Dictionary<ToocsValue>;
    @Prop(Boolean) readonly quickMode?: boolean;

    localValue: string[] = [];

    @Watch('value', { immediate: true })
    onValueChange(value?: ToocsValue): void {
      this.localValue = Object.values(value || {});
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    findPath(hash: Record<string, QuickModeOption>, key: string, path: string[] = []): any {
      if (!hash[key].parent) {
        return [hash[key].value, ...path];
      } else {
        return this.findPath(hash, hash[key].parent, [hash[key].value, ...path]);
      }
    }

    get isSomeFieldPopulated(): boolean {
      return Object.keys(this.fullValue).some((k) => !isEmpty(this.fullValue[k]));
    }

    get quickModeOptions(): QuickModeOptions {
      return this.section.quickModeOptions;
    }

    get options(): { label: string; value: string }[] {
      const dependencyFilter = (option: { dependency?: string[]; label: string; value: string }): boolean => {
        const dependency = this.section.dependency;
        if (dependency && this.fullValue[dependency]) {
          const dependencyValues = Object.values(this.fullValue[dependency]);
          return option.dependency ? option.dependency.some((i) => i === dependencyValues[dependencyValues.length - 1]) : false;
        }
        return true;
      };

      const options = this.flattenHelper(this.quickModeOptions.tree);
      const crossValidatedOptions = options.filter(dependencyFilter);

      return crossValidatedOptions.length ? crossValidatedOptions : options;
    }

    flattenHelper(
      quickModeOptionsTree: QuickModeOption[],
      data: { label: string; value: string }[] = []
    ): { label: string; value: string }[] {
      for (const key in quickModeOptionsTree) {
        const option = quickModeOptionsTree[key];
        // leave only the last level data with children empty and flatten them to array
        if (isEmpty(option.children)) {
          data.push(option);
        } else {
          this.flattenHelper(quickModeOptionsTree[key]['children'], data);
        }
      }

      return data.filter(Boolean);
    }

    toOutput(value: string[]): { [key: number]: string } {
      return value.reduce((m, v, i) => ({ ...m, [i]: v }), {});
    }

    onSelect(v: string): void {
      const result = this.findPath(this.quickModeOptions.hash, v);
      this.localValue = result;
      this.$emit('input', this.toOutput(result));
    }
  }
