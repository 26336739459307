
  import { Component } from 'vue-property-decorator';
  import BaseFieldDisplay from './base-field-display';
  import type { FieldType } from '@app/models/sub-form-question';

  @Component({ components: {} })
  export default class TextareaDisplay extends BaseFieldDisplay<FieldType.textarea> {
    get textAreaClasses() {
      return {
        'fr-view': this.richText,
        multiline: !this.richText,
      };
    }
    get richText(): boolean {
      return this.question.config.rich_text === 'true';
    }

    get sanitizedResponse(): string {
      return this.$sanitize(this.response.value || '');
    }
  }
