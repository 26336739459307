import type { BaseEntity } from './base-entity';
import type { RecordLinkBehaviour } from './module-name';
import type { ModuleTab } from './module-tab';
import type { SubForm } from './sub-form';
import type { RecordRelationMode } from './record-relation';
import type { SubFormListRestriction } from './sub-form-list-restriction';
import type { SubFormListRole } from './sub-form-list-role';
import type { SubFormListRoleProfile } from './sub-form-list-role-profile';
import type { ConfiguratorFilter } from '@app/models/configurator-filter';
import type { ExpensableExpensingTable } from '@app/models/expensable-expensing-table';
import type { Involvement } from '@app/models/involvement';
import type { DefaultTemplate } from '@app/models/default-template';
import type { RuleSet } from '@app/models/rule-set';
import type { StringBoolean } from '@app/utils/types/string-boolean';
import type { WithModuleName, WithWorkflows } from './mixins';

export enum SubFormListType {
  approval = 'approval',
  budgets = 'budgets',
  expense_estimates = 'expense_estimates',
  normal = 'normal',
  procedures = 'procedures',
  record_relations = 'record_relations',
  relationship = 'relationship',
  response_counter = 'response_counter',
  workflow = 'workflow',
}

export interface RelationshipOptions {
  allow_add_new?: StringBoolean;
  allow_search?: StringBoolean;
  allow_select?: StringBoolean;
  create_main_form_in_modal?: StringBoolean;
  filters?: ConfiguratorFilter[]; // filters for already linked records
  filters_select?: ConfiguratorFilter[]; // filters for records to be added to a relation
  index_options?: string[];
  link_first_column?: StringBoolean;
  relationship_code?: string;
  relationship_mode?: RecordRelationMode;
  reverse?: StringBoolean;
  // TODO; convert
  sort?: string;
}

export interface SubFormListResponseCounterOptionsRow {
  response_types: string[];
  title: string;
  value: string;
}

export interface SubFormListResponseCounterOptions {
  hide_percentage?: StringBoolean;
  hide_total?: StringBoolean;
  responses: Record<string, SubFormListResponseCounterOptionsRow>;
  sub_form_ids: string[];
}

type Direction = 'ASC' | 'DESC';
type SortingMethod = 'DEFAULT' | 'NATURAL';
type Source = 'APPROVAL';

export interface ColumnOrder {
  direction: Direction;
  field?: string;
  method?: SortingMethod;
  question?: string;
  source?: Source;
}

export interface OrderOptions {
  columns: ColumnOrder[];
}

export interface SubFormListOptions {
  record_link_behaviour?: RecordLinkBehaviour;
}

export const STORED_CALCULATION_ALLOWED_FILTERS = ['indicator', 'workflow'];

export interface SubFormList extends BaseEntity, WithModuleName, WithWorkflows {
  active: boolean;
  add_background_color?: string;
  add_icon?: string;
  add_text?: string;
  allow_confidentiality: boolean;
  approval_button_background_color: string;
  approval_button_icon?: string;
  approval_button_text?: string;
  approval_sub_form?: SubForm;
  approval_sub_form_id?: number;
  auto_save: boolean;
  custom_title?: string;
  default_template?: DefaultTemplate;
  default_template_id?: number;
  display_actions_count?: boolean;
  display_sub_form_question_codes: string[];
  draft: boolean;
  draft_after_complete: boolean;
  expensable_expensing_table?: ExpensableExpensingTable;
  expensable_expensing_table_id?: string;
  index: number;
  involvement?: Involvement;
  involvement_id?: number;
  module_tab?: ModuleTab;
  module_tab_id: number;
  multiple: boolean;
  number_of_questions?: number; // TODO: remove this field
  options: SubFormListOptions;
  order_options: OrderOptions;
  permissions?: {
    approve?: boolean;
    archive: boolean;
    can_add_more?: boolean;
    edit: boolean;
    view: boolean;
  };
  print_title?: string;
  record_relation_question_id?: number;
  record_relations_filters?: ConfiguratorFilter[];
  relationship_options: RelationshipOptions;
  response_counter_options: SubFormListResponseCounterOptions;
  rule_set?: RuleSet;
  rule_set_id?: number;
  show_in_list: boolean;
  sub_form_ids?: number[];
  sub_form_list_restrictions?: SubFormListRestriction[];
  sub_form_list_role_profiles?: SubFormListRoleProfile[];
  sub_form_list_roles?: SubFormListRole[];
  sub_form_list_type: SubFormListType;
  sub_forms?: SubForm[];
  system_code?: string;
  tab_section_group_ids?: string[];
  title: string;
  use_in_stored_calculations: boolean;
  user_avatar_option: 'none' | 'creator' | string;
  user_collection_id?: number;
}

export interface SubFormListResponseCounter {
  count: number;
  percentage: number;
  title: string;
}

export interface SubFormListResponseCounterData {
  responses: SubFormListResponseCounter[];
  total_responses: number;
}
