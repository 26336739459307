
  import { Component } from 'vue-property-decorator';
  import type { FieldType } from '@app/models/sub-form-question';

  import BaseField from './base-field';

  @Component({ components: {} })
  export default class DisplayImageField extends BaseField<FieldType.display_image> {
    get fileUrl(): string | undefined {
      return this.question.attachments && this.question.attachments[this.question.attachments.length - 1]?.url;
    }

    get maxHeight(): string {
      return this.question.config.max_height || '400';
    }

    get altText(): string {
      return this.question.config.alt_text || '';
    }
  }
