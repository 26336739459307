
  import type { BaseEntity } from '@app/models/base-entity';
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
  import { debounce } from 'lodash';
  import SimpleGridTable from '../simple-grid-table/simple-grid-table.vue';
  import type { BaseTableRowClickEvent } from './utils';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import type { ListManager } from '@app/services/list-manager/list-manager';

  @Component({
    components: {
      SimpleGridTable,
    },
  })
  export default class BaseTable<T extends BaseEntity<number | string>> extends Vue {
    @Prop(Object) manager!: ListManager<T>;
    @Prop(Boolean) loading?: boolean;
    @Prop(Boolean) responsive?: boolean;
    @Prop(String) tableHeight?: string;
    @Prop(String) noDataTemplate?: string;
    @Prop(Boolean) sortable?: boolean;
    @Prop(String) sortableHandle?: string;
    @Prop({ type: String, default: () => 'fixed' }) readonly loaderPosition!: 'fixed' | 'absolute' | 'relative' | 'static' | 'sticky';

    @Watch('allFilters', { deep: true })
    onFiltersUpdate(val: DonesafeFilterOptions<T>, oldVal: DonesafeFilterOptions<T>): void {
      // USEFUL FOR DEBUG
      // console.log(JSON.stringify(val), JSON.stringify(oldVal));
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.manager && this.debounceUpdate(true);
      }
    }

    get headerSlots(): string[] {
      return Object.keys(this.$scopedSlots).filter((slot) => slot.startsWith('header__') || slot.startsWith('filter__'));
    }

    get isLoading(): boolean {
      return !!this.manager?.fetching || !!this.loading;
    }

    get allFilters(): DonesafeFilterOptions<T> {
      return { ...this.manager.filters, ...this.manager.customFilters };
    }

    setData(data: T[]): void {
      this.manager.setData(data);
    }

    onRowClick(event: BaseTableRowClickEvent<T>): void {
      this.$emit('row-clicked', event);
    }

    css = {
      tableClass: `table table-hover ${[this.responsive && 'table-responsive'].filter(Boolean).join(' ')}`,
      ascendingIcon: 'fa fa-caret-up',
      descendingIcon: 'fa fa-caret-down',
    };

    debounceUpdate = debounce(
      (goToFirstPage?: boolean) => Vue.nextTick(() => this.manager?.refresh(goToFirstPage)),
      this.manager.debounceTime
    );

    reload(): void {
      this.debounceUpdate();
    }

    get noData() {
      return this.noDataTemplate || this.$t('app.labels.no_records_found');
    }
  }
