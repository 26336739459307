
  import { useAccountStore } from '@app/stores/account';
  import { Component, Emit, Prop } from 'vue-property-decorator';
  import PayCalculatorTable from '../../calculator/pay-calculator-table.vue';
  import type { PiaweDetail, TenantUser } from '@app/models/tenant-user';
  import { extraPayDetailStrings } from '@app/utils/extra-pay-detail-strings';
  import { toaster } from '@app/utils/toaster';
  import { extraPayItems } from '@app/constants';
  import { mixins } from 'vue-class-component';
  import WithUser from '@app/mixins/with-user';
  import { useCurrentUserStore } from '@app/stores/currentUser';

  @Component({
    components: {
      PayCalculatorTable,
    },
  })
  export default class UserPayDetailsTab extends mixins(WithUser) {
    @Prop(Boolean) readonly admin?: boolean;

    value: Partial<PiaweDetail> = {};
    user: Nullable<TenantUser> = null;

    get accountStore() {
      return useAccountStore();
    }

    get extraPayItems(): { key: string; title: string }[] {
      return extraPayItems.map((item) => ({
        key: item,
        title: extraPayDetailStrings(this.accountStore.data)[item] || 'N/A',
      }));
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get canEdit() {
      return this.admin || this.currentUserStore.checkProfilePermission({ id: this.userId }, 'pay_details', 'edit');
    }

    @Emit('input')
    updateValue(): Partial<PiaweDetail> {
      return this.value;
    }

    beforeMount(): void {
      this.$api.getTenantUser(this.userId, { include: ['piawe_detail'] }, { cache: true }).then(({ data }) => {
        this.user = data;
        this.value = { ...data.piawe_detail };
      });
    }

    submitForm(): void {
      this.$api
        .updateTenantUser(this.userId, { piawe_detail: { ...this.user?.piawe_detail, ...this.value } }, { include: ['piawe_detail'] })
        .then(() => {
          this.$api.cache.clear();
          toaster({
            text: 'Pay details successfully updated',
            position: 'top-right',
          });
        })
        .catch(({ data }) => {
          toaster({ text: data?.error, position: 'top-right', icon: 'error' });
        });
    }
  }
