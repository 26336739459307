import type { BaseEntity } from './base-entity';
import type { WithRelatedModuleName } from './mixins';
import type { SubFormList } from './sub-form-list';

export const HYBRID_MODULE_TAB_CODE = 'managed_forms';

export interface ModuleTab extends BaseEntity, WithRelatedModuleName {
  active: boolean;
  code: string;
  hybrid_module: boolean;
  index: number;
  module_name: string;
  rule_set_id?: number;
  sub_form_lists?: SubFormList[];
  system: boolean;
  title: string;
  user_collection_id?: number;
}
