
  import { Component, Prop } from 'vue-property-decorator';
  import BaseQuestionResponse from './base-question-response';
  import TextHighlight from 'vue-text-highlight';
  import type { Workflow } from '@app/models/workflow';

  @Component({ components: { TextHighlight } })
  export default class WorkflowStateFieldResponse extends BaseQuestionResponse {
    @Prop(Object) readonly workflow?: Workflow;
    @Prop(String) readonly workflowText?: string;
  }
