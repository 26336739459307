
  import { Component, Model, Prop, Ref, Vue } from 'vue-property-decorator';
  import Select2 from '../select2.vue';
  import EntitySelector from '../entity-selector.vue';
  import OrganizationSelector from './organization-selector.vue';
  import { last } from 'lodash';
  import type { Organization } from '@app/models/organization';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';

  @Component({ components: { OrganizationSelector, EntitySelector, Select2 } })
  export default class MultiLevelOrganizationSelector extends Vue {
    // TODO: remove later
    @Prop(String) readonly labelledBy?: string;
    @Ref() readonly parents?: OrganizationSelector[];

    @Model('input') value?: number;
    @Prop(Boolean) allowClear?: boolean;
    @Prop(Boolean) bottomRequired?: boolean;
    @Prop(Object) filters?: DonesafeFilterOptions<Organization>;
    @Prop(Number) parentId!: number;
    @Prop(Object) question!: SubFormQuestion;
    @Prop(Boolean) readonly?: boolean;
    @Prop(Boolean) required?: boolean;
    @Prop(Object) requiredFilters?: DonesafeFilterOptions<Organization>;

    parentIds: (number | undefined)[] = [];

    get isRequired(): boolean {
      if (this.required && !this.parentIds.length) {
        return true;
      }

      return !!this.bottomRequired && !!this.value && this.value !== last(this.parentIds);
    }

    onParentUpdate(index = 0, parentId?: number): void {
      Vue.set(this.parentIds, index, parentId);
      if (parentId) {
        this.$api
          .getOrganizations(
            {
              filters: { organization_id: parentId, ...this.filters },
              only: ['id'],
            },
            { cache: true }
          )
          .then(({ data }) => {
            if (data.length) {
              this.parentIds = [...this.parentIds, undefined];
            }
          });
      }
      this.parentIds = this.parentIds.slice(0, index + 1);
      // gets the last non-empty value
      const theValue = [...this.parentIds].reverse().find((v) => v);
      // TODO: fix later
      if (this.value || theValue) {
        this.$emit('input', theValue && String(theValue));
      }
    }

    mounted(): void {
      if (this.value) {
        this.$api
          .getOrganization(
            this.value,
            { only: ['id', 'organization_id', 'name', { ancestors: ['id', 'organization_id', 'name'] }] },
            { cache: true }
          )
          .then(({ data }) => {
            const parents = [data].concat(data.ancestors || []).reverse();
            this.parentIds = parents.map((a: Organization) => a.id);
            this.onParentUpdate(this.parentIds.length - 1, data.id);
            this.$nextTick(() => {
              this.parentIds.forEach((id, index) => {
                id && this.parents?.[index]?.setOptions([parents[index]]);
              });
            });
          })
          .catch(() => this.onParentUpdate());
      } else {
        this.onParentUpdate();
      }
    }
  }
