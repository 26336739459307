import { BaseService } from './base-service';
import type { RoleProfilePermission } from '@app/models/role-profile-permission';

export class RoleProfilePermissionService extends BaseService<RoleProfilePermission> {
  static serviceTitle = ($t: (key: string) => string): string => $t('app.labels.role_profile_permission');
  static readonly allowedAttributes = ['permission_id', 'role_profile_id'];
  static attributeNames($t: (key: string) => string): Partial<Record<keyof RoleProfilePermission, string>> {
    return {
      permission_id: $t('app.labels.permission'),
      role_profile_id: $t('app.labels.role_profile'),
    };
  }
  static readonly attributeServices: Partial<Record<keyof RoleProfilePermission, string>> = {
    permission_id: 'Permission',
    role_profile_id: 'RoleProfile',
  };
}
