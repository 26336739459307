import type { Indicator } from '@app/models/indicator';
import type { DetailsLink } from '../models';
import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';

export class IndicatorService extends BaseService<Indicator> {
  async fetchDetails(itemId: ServiceRequest<Indicator>): Promise<DetailsServiceResponse> {
    const indicator = await this.fetchEntity(itemId);
    let links: DetailsLink[] = [
      {
        link: `/admin/settings/indicators/${indicator.id}/edit`,
        prefix: this.t('app.labels.indicator'),
        title: indicator.name,
        id: indicator.id,
      },
    ];
    if (indicator.indicator_set) {
      links = [
        ...links,
        {
          link: `/admin/settings/indicator_sets/${indicator.indicator_set_id}`,
          prefix: this.t('app.labels.indicator_set'),
          title: indicator.indicator_set.name,
          id: indicator.indicator_set_id,
        },
      ];
    }
    return {
      links,
    };
  }

  async fetchEntity({ itemId }: ServiceRequest<Indicator>): Promise<Indicator> {
    const { data: indicator } = await this.api.getIndicator(
      itemId,
      { only: ['id', 'name', 'indicator_set_id', { indicator_set: ['name'] }], include: 'indicator_set' },
      { join: true, cache: true }
    );
    return indicator;
  }

  entityToText({}: ServiceRequest<Indicator>, entity?: Indicator): string {
    return entity ? `${entity.name} [${entity.id}]` : '';
  }
}
