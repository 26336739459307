
  import { processObjectChanges } from '../mapping-utils';
  import type { AttributeValue, AttributeValues } from '../models';
  import PaperTrailsAttribute from './paper-trails-attribute.vue';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { PaperTrailChanges, PaperTrailVersion } from '@app/models/paper-trail-version';

  @Component({ components: { PaperTrailsAttribute } })
  export default class PaperTrailsAttributes extends Vue {
    @Prop(Array) readonly queries!: string[];
    @Prop(Object) readonly version!: PaperTrailVersion;
    @Prop(Object) readonly attributeValues!: AttributeValues;

    get objectChanges(): Maybe<Partial<PaperTrailChanges>> {
      return processObjectChanges(this.version); // TODO: please do processObjectChanges in parent component
    }

    attributeValue(field: string): Record<string, AttributeValue> {
      return this.attributeValues[field] || {};
    }
  }
