import type { WithScoreData } from '@app/models/mixins';
import type { Workflow } from '@app/models/workflow';
import type { BaseEntity } from './base-entity';
import type { SubForm } from './sub-form';
import type { SubFormList } from './sub-form-list';
import type { SubFormResponse } from './sub-form-response';
import type { TenantUser } from './tenant-user';
import type { ScoreBand } from './score-band';

export enum SubFormCompletionStage {
  Complete = 'Complete',
  Draft = 'Draft',
}

// TODO: separate types for MainFormCompletion and SubFormCompletion
export interface SubFormCompletion extends BaseEntity, WithScoreData {
  active?: boolean; // maybe remove this one
  approval_for_sub_form_completion_id: number | null;
  approval_form?: SubFormCompletion;
  closed_actions_count: number;
  confidential?: boolean;
  display_user?: TenantUser;
  external_uuid: number;
  print_score?: string;
  record_id: number | null;
  record_type: string | null; // TODO: use 'ModuleRecord' | 'Hazard' | 'Incident'
  score_band?: ScoreBand;
  stage: SubFormCompletionStage | string;
  sub_form?: SubForm;
  sub_form_id: number;
  sub_form_list?: SubFormList;
  sub_form_list_id: number; // TODO: add || null
  sub_form_responses: SubFormResponse[];
  title: string;
  total_actions_count: number;
  user_id: number | null;
  workflow?: Workflow;
  workflow_id: number | null;
}
