
  import { useAccountStore } from '@app/stores/account';
  import DsModal from '@app/components/ds-modal.vue';
  import bootbox from 'bootbox';
  import { get, sum, mean, max, min, values, merge, keyBy, orderBy, size, isEmpty, set } from 'lodash';
  import { Component, Model, Prop, Ref, Vue } from 'vue-property-decorator';
  import type { TableCalculationFieldValue } from '@app/models/question-response-types';
  import { TableCalculationFieldStage } from '@app/models/question-response-types';
  import TableCalculationModalTable from './table/table-calculation-modal-table.vue';

  import ValidationErrorModal from './validation-error-modal.vue';
  import FetchProgressModal from './fetch-progress-modal.vue';
  import PostProgressModal from './post-progress-modal.vue';
  import type { VariableData } from './utils';
  import { constructTcfPostBody, filterFunction, saferEvaluate, substituteScope } from './utils';
  import moment from 'moment';
  import type { QuestionSfcOnly } from '../sub-form-completion/utils';
  import type { CombinedRecord, TableCalculation } from '@app/models/table-calculation';
  import type { FieldType, QuestionTypeMap, SubFormQuestion } from '@app/models/sub-form-question';
  import type {
    ApiResponseValidation,
    TableCalculationColumnConfig,
    TableCalculationColumnFormula,
    TableCalculationConfig,
    TableCalculationQuestionOptions,
    TableCalculationRequestError,
    TableCalculationSourceConfig,
    TableCalculationVariable,
  } from '@app/models/question-options/table-calculation-question-options';
  import { toaster } from '@app/utils/toaster';

  @Component({
    components: { DsModal, TableCalculationModalTable, ValidationErrorModal, FetchProgressModal, PostProgressModal },
  })
  export default class TableCalculationModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Ref() readonly table?: InstanceType<typeof TableCalculationModalTable>;
    @Ref() readonly fetchProgressModal!: InstanceType<typeof FetchProgressModal>;

    @Prop(String) readonly title?: string;
    @Prop(Boolean) readonly readonly?: boolean;
    @Prop(Object) readonly question!: Pick<SubFormQuestion<QuestionTypeMap<FieldType.table_calculation>['options']>, QuestionSfcOnly>;
    @Prop(Object) readonly variableData!: VariableData;
    @Prop(Boolean) readonly inputVariablesChanged!: boolean;
    @Prop(Object) readonly fieldValue!: TableCalculationFieldValue;
    @Prop(String) readonly stage!: TableCalculationFieldStage;
    @Prop(Number) readonly recordId?: number;
    @Prop(Number) readonly completionId?: number;

    loading = false;
    data: Record<string, any> = {};
    records: CombinedRecord[] = [];
    sourceRecordIdsHash: Record<string, string> = {};
    foundLocalData = false;
    fetchProgress = false;
    validationFailureLoaded: Record<number, boolean> = {};
    validationFailures: ApiResponseValidation[] = [];
    postProgress = false;
    sort: {
      direction: 'asc' | 'desc';
      field: string;
    } = {
      field: 'id',
      direction: 'desc',
    };
    postErrors: string[] = [];
    errors: TableCalculationRequestError[] = [];

    displayVariable(variable: TableCalculationVariable): string {
      const rawValue = this.variableData[variable.code];

      if (!!variable?.display_formula) {
        try {
          return saferEvaluate(variable.display_formula, { ...(this.additionalScope || {}), value: rawValue });
        } catch (e) {
          return rawValue;
        }
      }
      return rawValue;
    }

    onUpdateRecord(opts: { index: number; pathToUpdate: string; value: boolean | string | number | null }) {
      const { index, pathToUpdate, value } = opts;
      set(this.records[index], pathToUpdate, value);
    }

    onSort(sort: { direction: 'asc' | 'desc'; field: string }) {
      this.sort = sort;
      // TODO: special sort for date columns
      this.records = orderBy(this.records, `data.${sort.field}`, sort.direction);
    }

    calculateId(sourceRecord: any, source: TableCalculationSourceConfig): string {
      return `${this.getValueAtPath(sourceRecord, source.primary_path)}`;
    }

    getValueAtPath(data: any, path?: string): string | number | unknown {
      return path ? get(data, path) : data;
    }

    columnValue(data: any, column: TableCalculationColumnConfig, index?: number): string | number | undefined | unknown {
      const formula = (column as TableCalculationColumnFormula).formula;
      if (formula && !this.readonly) {
        try {
          return saferEvaluate(formula, { ...data, ...this.additionalScope, row: { index } }, column.default);
        } catch (e) {
          return column.default;
        }
      } else {
        return this.getValueAtPath(data, column.source_path || column.code) || column.default;
      }
    }

    fetchData(): void {
      if (this.fieldValue?.async_populated) {
        this.fetchTransformed(false, this.fieldValue?.table_calculation_source_record_id_hash || {});
        return;
      }
      TableCalculationFieldStage.empty === this.stage ? this.populateFromRemote(true) : this.mergeLocalData();
    }

    transform(mergeLocalData: boolean): void {
      this.$nextTick(() => {
        let idsToKeep: string[] = [];

        Object.keys(this.data).forEach((source) => {
          const sourceConfig = this.questionConfig.table_calculation.data_sources[source];
          if (sourceConfig && 'data' in this.data[source]) {
            let sourceData = this.getValueAtPath(this.data[source].data, sourceConfig.data_path);
            this.validationRecords(sourceData as any, sourceConfig.validations || []);

            if (Array.isArray(sourceData)) {
              if (sourceConfig.filter) {
                sourceData = sourceData.filter((r) =>
                  filterFunction(`${sourceConfig.filter}`, {
                    iterated: r,
                    ...this.additionalScope,
                  })
                );
              }
              (sourceData as []).forEach((sourceRecord) => {
                const calculatedId = this.calculateId(sourceRecord, sourceConfig);
                idsToKeep = [...idsToKeep, calculatedId];
                const existingRecordIndex = this.records.findIndex((record) => `${record.id}` === calculatedId);
                const existingRecord = this.records[existingRecordIndex];
                const record = {
                  id: calculatedId,
                  data_sources: { ...existingRecord?.data_sources, [source]: sourceRecord },
                  data: {},
                };
                if (existingRecord) {
                  this.records = [...this.records.map((per, index) => (index === existingRecordIndex ? record : per))];
                } else {
                  this.records = [...this.records, record];
                }
              });
            }
          }
        });

        this.records = this.records.filter((record) => idsToKeep.includes(`${record.id}`));
        this.records = orderBy(
          this.records.map((record) => {
            const data = this.columns
              .filter((c) => !(c as TableCalculationColumnFormula).formula)
              .reduce((memo, column) => ({ ...memo, [column.code]: this.columnValue(record.data_sources, column) }), {});
            return { ...record, data };
          }),
          'id'
        );
        this.records = this.getExpandableForRecords(this.records);

        if (mergeLocalData) {
          this.mergeLocalData(idsToKeep);
        }

        this.performDefaultSort();
      });
    }

    fetchTransformed(mergeLocalData: boolean, sourceRecordIdsHash: Record<string, string>): void {
      this.fetchProgress = true;
      this.loading = true;
      this.$api
        .getTableCalculationSourceData({
          sub_form_question_id: this.question.id,
          table_calculation_source_record_id_hash: sourceRecordIdsHash,
        })
        .then(({ data }) => {
          this.data = data;
          if (this.fetchProgress) {
            this.fetchProgressModal.close();
            this.transform(mergeLocalData);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }

    populateFromRemote(mergeLocalData: boolean, cache = true): void {
      this.fetchProgress = true;
      this.loading = true;
      this.$api.getTableCalculationData({ sub_form_question_id: this.question.id, data: this.variableData }, { cache }).then(({ data }) => {
        this.sourceRecordIdsHash = Object.keys(data).reduce(
          (memo, key) => ({ ...memo, [key]: data[key].table_calculation_source_record_id }),
          {}
        );
        this.fetchTransformed(mergeLocalData, this.sourceRecordIdsHash);
        this.checkErrors(data);
      });
    }

    closeAllValidationErrors(): void {
      this.validationFailureLoaded = {
        ...Object.keys(this.validationFailureLoaded).reduce((memo, key) => ({ ...memo, [key]: false }), {}),
      };
    }

    showValidationErrorByIndex(index: number): void {
      this.closeAllValidationErrors();
      this.validationFailureLoaded = { ...this.validationFailureLoaded, [index]: true };
    }

    onFilterValidations(formula: string): void {
      this.closeAllValidationErrors();
      this.records = this.records.filter((r) =>
        filterFunction(formula, {
          iterated: r,
          ...this.additionalScope,
        })
      );
    }

    onCloseValidations(): void {
      this.closeAllValidationErrors();
      this.$emit('input', false);
    }

    onInsertValidationError(data: Record<string, string>): void {
      this.onCloseValidations();
      this.$emit('broadcast', data);
      this.$emit('input', false);
    }

    onNextValidationError(nextIndex: number): void {
      this.showValidationErrorByIndex(nextIndex);
    }

    validationErrored(records: CombinedRecord[], validation: ApiResponseValidation): boolean {
      return saferEvaluate(validation.formula, {
        ...this.additionalScope,
        ...{
          data: validation.data_path ? (records as any)[validation.data_path] : records,
        },
      });
    }

    validationRecords(records: CombinedRecord[], validations: ApiResponseValidation[]): void {
      this.validationFailures = [];
      if ((records || []).length) {
        validations.forEach((validation) => {
          if (this.validationErrored(records, validation)) {
            this.validationFailures.push(validation);
          }
        });
        if (this.validationFailures.length) {
          this.validationFailures.forEach((_failure, index) => {
            this.validationFailureLoaded[index] = index === 0;
          });
        }
      }
    }

    mergeLocalData(idsToKeep?: string[]): void {
      if (this.fieldValue?.value) {
        this.loading = true;
        this.$api.getTableCalculation(this.fieldValue.value).then(({ data }) => {
          this.loading = false;
          this.foundLocalData = true;
          const savedRecords = data.data.records.filter((record) => !idsToKeep || idsToKeep.includes(`${record.id}`));
          if (isEmpty(this.data)) this.data = data.data.raw_data;
          const records = orderBy(values(merge(keyBy(this.records, 'id'), keyBy(savedRecords, 'id'))), 'id');
          this.records = this.getExpandableForRecords(records);
        });
      }
    }

    calculationValue({ filter, ...calculation }: TableCalculationConfig): string | number | undefined {
      const method = calculation.method ? { sum, average: mean, min, max, count: size }[calculation.method] : undefined;

      const filteredRecords = filter
        ? this.computedRecords.filter((r) => filterFunction(filter, { iterated: r, ...this.additionalScope }))
        : this.computedRecords;

      if (filteredRecords.length) {
        if (method && 'variable' in calculation) {
          return method(filteredRecords.map((record) => Number(record.data[calculation.variable]))) as number;
        } else if ('formula' in calculation) {
          let formula = this.finalisedCalculationFormulas[calculation.code];
          // calculate with one of the predefined methods if 'method' is specified
          if (method) {
            return method(
              filteredRecords.map((record) => {
                try {
                  return saferEvaluate(formula, { ...record.data, ...this.additionalScope });
                } catch (e) {
                  return calculation.default;
                }
              })
            );
          } else {
            // allow to pass more complex formula with the scope of 'filteredRecords.data' for more complex calculations
            try {
              return saferEvaluate(formula, { data: filteredRecords.map(({ data }) => data), ...this.additionalScope });
            } catch (e) {
              return calculation.default;
            }
          }
        }
      } else {
        return calculation.default;
      }
    }

    getExpandableData(record: CombinedRecord): Maybe<CombinedRecord[]> {
      if (this.questionConfig?.table_calculation.expandable) {
        const { filter, data_path } = this.questionConfig.table_calculation.expandable;
        const data = this.getValueAtPath(this.data, data_path);

        if (filter && Array.isArray(data)) {
          return data?.filter((i) => filterFunction(filter, { iterated: i, record, ...this.additionalScope }));
        }
      }
    }

    getExpandableForRecords(records: CombinedRecord[]): CombinedRecord[] {
      return records.map((record) => {
        const expandableData = this.getExpandableData(record);
        return {
          ...record,
          expandableData,
          ...(expandableData?.length ? { expanded: this.questionConfig.table_calculation?.expandable?.default || false } : {}),
        };
      });
    }

    checkErrors(responseData: TableCalculationModal['data']): boolean {
      this.errors = Object.keys(responseData).reduce((memo, source) => {
        if ('error' in responseData[source]) {
          return [...memo, responseData[source].error];
        }
        return memo;
      }, [] as { code: string; error: string }[]);
      return !this.errors.length;
    }

    get displayVariableData() {
      const validVariables = Object.keys(this.variableData);
      return this.questionConfig.table_calculation.variables?.reduce((acc, variable) => {
        if (validVariables.includes(variable.code)) acc[variable.code] = this.displayVariable(variable);
        return acc;
      }, {} as VariableData);
    }

    get finalisedCalculationFormulas(): Record<string, string> {
      return this.calculations.concat(this.saveAndInsertBroadcasts).reduce((memo, c) => {
        if (!('formula' in c)) return memo;

        let formula = c.formula;
        if (!formula.includes('raw_formula')) {
          return { ...memo, [c.code]: formula };
        } else {
          // replace 'raw_formula.code' with the raw formula value so we can use it in the evaluation
          // repeat until there are no more 'raw_formula.code' in the formula
          while (formula.includes('raw_formula')) formula = substituteScope(formula, { raw_formula: this.calculationFormulas });
          return { ...memo, [c.code]: formula };
        }
      }, {} as Record<string, string>);
    }

    get calculationFormulas(): Record<string, string> {
      return this.calculations.reduce((memo, c) => {
        if ('formula' in c) return { ...memo, [c.code]: c.formula };
        return memo;
      }, {} as Record<string, string>);
    }

    get additionalScope(): Maybe<Record<string, unknown>> {
      const additionalScopeConfig = this.questionConfig.table_calculation?.additional_scope;
      const variable = this.variableData;
      const baseScope = {
        moment,
        variable,
        $account: this.accountStore.data,
        completionId: this.completionId,
        recordId: this.recordId,
        sourceRecordIdsHash: this.sourceRecordIdsHash,
      };

      if (additionalScopeConfig && !!Object.keys(additionalScopeConfig).length) {
        return Object.keys(additionalScopeConfig).reduce((memo, key) => {
          const value = additionalScopeConfig[key];
          return { ...memo, [key]: this.getValueAtPath(this.data, value) };
        }, baseScope);
      } else {
        return baseScope;
      }
    }

    get requiredRequestsFailed(): boolean {
      return Object.keys(this.questionConfig.table_calculation.data_sources).some((source) => {
        const sourceConfig = this.questionConfig.table_calculation.data_sources[source];
        return sourceConfig.required && this.data[source] && 'error' in this.data[source];
      });
    }

    get closeIconStyles(): Record<string, string> {
      return { marginTop: this.showFetchButton ? '5px' : '' };
    }

    get fetchButtonText(): string {
      if (this.stage === TableCalculationFieldStage.draft || this.stage === TableCalculationFieldStage.empty) {
        return this.questionConfig?.table_calculation?.labels?.draft_fetch_button_label || 'Draft Fetch';
      } else {
        return this.questionConfig?.table_calculation?.labels?.fetch_button_label || 'Fetch';
      }
    }

    get showResetButton(): boolean {
      return this.stage !== TableCalculationFieldStage.empty;
    }

    get showFetchButton(): boolean {
      return !this.readonly && !this.loading;
    }

    get showFooter(): boolean {
      return (
        !this.readonly &&
        !this.loading &&
        !!this.computedRecords.length &&
        (this.showResetButton || this.showUpdateRemote || this.showDraft || this.showSaveAndInsert)
      );
    }

    get resetButtonText(): string {
      return this.questionConfig.table_calculation.labels?.reset_button_label || 'Reset';
    }

    get showUpdateRemote(): boolean {
      return !!this.postConfig?.endpoint && this.showSaveAndInsert && !this.questionConfig.table_calculation.should_post_on_save;
    }

    get showDraft(): boolean {
      const { can_save_as_draft } = this.questionConfig.table_calculation;
      return !!can_save_as_draft && this.stage !== TableCalculationFieldStage.complete;
    }

    get showSaveAndInsert(): boolean {
      const { variables } = this.questionConfig.table_calculation;
      return !isEmpty(variables) && !this.requiredRequestsFailed;
    }

    get computedRecords(): CombinedRecord[] {
      return this.readonly
        ? this.records
        : this.records.map((record, index) => {
            const data = this.columns
              .filter((c) => (c as TableCalculationColumnFormula).formula)
              .reduce((memo, column) => ({ ...memo, [column.code]: this.columnValue(memo, column, index + 1) }), record.data);
            return { ...record, data };
          });
    }

    get questionConfig(): TableCalculationQuestionOptions {
      return this.question.config;
    }

    get columns(): TableCalculationColumnConfig[] {
      return this.questionConfig.table_calculation.columns || [];
    }

    get visibleColumns(): TableCalculationColumnConfig[] {
      return this.columns.filter((c) => !c.hidden);
    }

    get calculations(): TableCalculationConfig[] {
      return this.questionConfig.table_calculation.calculations || [];
    }

    get saveAndInsertBroadcasts(): TableCalculationConfig[] {
      return this.questionConfig.table_calculation.save_and_inserts_broadcasts || [];
    }

    calculateNow(toBeCalculated: TableCalculationConfig[]): Record<string, string> {
      const computedCalculated = toBeCalculated
        .filter((c) => c.question_code)
        .reduce((memo, calculation) => ({ ...memo, [calculation.question_code as string]: this.calculationValue(calculation) }), {});

      return this.readonly ? this.fieldValue?.calculated || {} : computedCalculated;
    }

    get calculated(): Record<string, string> {
      return this.calculateNow(this.calculations);
    }

    get saveAndInsertBroadcastsCalculated(): Record<string, string> {
      return this.calculateNow(this.saveAndInsertBroadcasts);
    }

    get transientData(): TableCalculation['data'] {
      return { records: this.computedRecords.map(({ id, data }) => ({ id, data })), raw_data: this.data };
    }

    get mode() {
      if (this.stage === TableCalculationFieldStage.draft) {
        return this.$t('app.labels.editing_draft');
      }
    }

    get postConfig(): TableCalculationQuestionOptions['table_calculation']['post_config'] | undefined {
      return this.questionConfig.table_calculation.post_config;
    }

    get accountStore() {
      return useAccountStore();
    }

    shouldPostToRemote(): boolean {
      const { should_post_on_save } = this.questionConfig.table_calculation;

      if (typeof should_post_on_save === 'boolean') return should_post_on_save;
      if (typeof should_post_on_save === 'string') {
        try {
          return saferEvaluate(should_post_on_save, { ...this.additionalScope, calculated: this.calculated });
        } catch (e) {
          return false;
        }
      }

      return false;
    }

    createUpdateTableCalculation(insertCalculatedBackToForm: boolean, posted?: true): Promise<void> {
      this.loading = true;
      const persist =
        this.foundLocalData && this.fieldValue?.value
          ? this.$api.updateTableCalculation(this.fieldValue.value, {
              data: this.transientData,
              table_calculation_source_record_id_hash: this.sourceRecordIdsHash,
            })
          : this.$api.createTableCalculation({
              data: this.transientData,
              table_calculation_source_record_id_hash: this.sourceRecordIdsHash,
            });
      return persist
        .then(({ data }) => {
          this.foundLocalData = true;
          this.$emit(insertCalculatedBackToForm ? 'save' : 'draft', {
            value: data.id,
            sourceRecordIdsHash: this.sourceRecordIdsHash,
            calculated: this.calculated,
            ...(insertCalculatedBackToForm ? { saveAndInsertBroadcastsCalculated: this.saveAndInsertBroadcastsCalculated } : {}),
            ...(!!posted ? { posted } : {}),
          });
          this.$emit('input', false);
        })
        .catch((data) => {
          toaster({ text: data?.error || data, position: 'top-right', icon: 'error' });
        })
        .finally(() => (this.loading = false));
    }

    async postOnSaveAndInsert(): Promise<void> {
      try {
        await this.updateRemote();
      } catch (e) {
        // validation error or error from remote
        return;
      }

      // after POST completed without any errors
      // update/create table calculation and save calculated values back to form
      this.createUpdateTableCalculation(true, true);
    }

    async save(insertCalculatedBackToForm?: boolean): Promise<void> {
      if (!!insertCalculatedBackToForm && this.shouldPostToRemote()) {
        await this.postOnSaveAndInsert();
        return;
      }

      // only validate on 'save_insert'
      if (insertCalculatedBackToForm) {
        const result = await this.table?.validator?.validate();
        if (result) {
          this.createUpdateTableCalculation(true);
        }
      } else {
        this.createUpdateTableCalculation(false);
      }
    }

    reset(): void {
      if (this.stage === TableCalculationFieldStage.empty) {
        this.$emit('input', false);
      } else {
        bootbox.confirm({
          size: 'small',
          backdrop: false,
          message: this.$t('app.labels.are_you_sure'),
          buttons: {
            confirm: { label: this.$t('app.labels.yes'), className: 'btn-success' },
            cancel: { label: this.$t('app.labels.no'), className: 'btn-default' },
          },
          callback: (result: boolean) => {
            if (result) {
              this.$emit('reset');
              this.populateFromRemote(false);
            }
          },
        });
      }
    }

    async updateRemote(): Promise<void> {
      if (!this.completionId) return;

      const frontendBody = this.postConfig?.frontend_body;
      if (!frontendBody) return;

      const validationResult = await this.table?.validator?.validate();
      if (!validationResult) throw new Error('validation failed');

      this.loading = true;
      this.postProgress = true;

      const postBody = constructTcfPostBody(frontendBody, {
        computedRecords: this.computedRecords,
        calculated: this.calculated,
        additionalScope: this.additionalScope,
      });

      try {
        const { data } = await this.$api.updateRemotePaySystem({ data: postBody, sub_form_question_id: this.question.id });
        if (data) this.postProgress = false;
      } catch (error: any) {
        const errorMessage = error.data?.error || error;
        this.postErrors = [errorMessage];
        throw new Error(errorMessage);
      } finally {
        this.loading = false;
      }
    }

    performDefaultSort() {
      if (this.questionConfig.table_calculation.sortable?.field) {
        this.onSort({
          direction: this.questionConfig.table_calculation.sortable?.direction || 'desc',
          field: this.questionConfig.table_calculation.sortable?.field || 'id',
        });
      }
    }

    beforeMount(): void {
      this.sourceRecordIdsHash = this.fieldValue?.table_calculation_source_record_id_hash || {};
    }
  }
