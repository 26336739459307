
  import type { ToocsFieldValue } from '@app/models/question-response-types';
  import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
  import ToocsSubPicker from './toocs-sub-picker.vue';
  import ToocsQuickSubPicker from './toocs-quick-sub-picker.vue';
  import { omitBy, isEmpty } from 'lodash';
  import type { ToocsValue } from '@app/models/toocs/toocs-value';
  import type { Dictionary } from '@app/models/dictionary';
  import { TOOCS_INJURY_INJNATS } from '@app/models/toocs/toocs-constants';
  import type { QuickModeOption, QuickModeOptions, ToocsSectionQuickMode } from './models';

  @Component({ components: { ToocsSubPicker, ToocsQuickSubPicker }, methods: { isEmpty } })
  export default class ToocsPicker extends Vue {
    @Model('input') readonly value!: Dictionary<ToocsValue | undefined>;
    @Prop(Boolean) readonly?: boolean;
    @Prop(Boolean) required?: boolean;
    @Prop(Number) sectionId!: number;
    @Prop(String) name!: string;
    @Prop(Boolean) readonly quickMode?: boolean;

    toocsSections: ToocsSectionQuickMode[] = [];

    localValue: Dictionary<ToocsValue | undefined> = {};

    disableField(section: ToocsSectionQuickMode): boolean {
      if (section.dependency) {
        const lastDependencyValue = Object.values(this.localValue[section.dependency] || {})?.slice(-1)[0];
        const dependencySection = this.toocsSections.find((s) => s.key === section.dependency);

        return (
          isEmpty(this.localValue[section.dependency]) ||
          !lastDependencyValue ||
          !!dependencySection?.quickModeOptions?.hash?.[lastDependencyValue]?.children?.length
        );
      }

      return false;
    }

    @Watch('value', { immediate: true })
    onValueChanged(value: Dictionary<ToocsValue | undefined>): void {
      const newValue = value || {};
      this.localValue = omitBy(
        Object.keys(newValue).reduce((m, k) => {
          return { ...m, [k]: omitBy(newValue[k], isEmpty) };
        }, {}),
        isEmpty
      );
    }

    typeValue(): ToocsFieldValue['type'] | undefined {
      const injNat = this.localValue?.injnat?.[0];
      if (injNat) {
        return TOOCS_INJURY_INJNATS.includes(injNat) ? 'injury' : 'disease';
      }
    }

    setValue(key: string, value?: ToocsValue): void {
      const oldValue = this.localValue;
      this.localValue = omitBy(
        this.toocsSections.reduce(
          (m, s) => {
            s.dependency === key && Vue.delete(m, s.key);
            return m;
          },
          { ...oldValue, [key]: value }
        ),
        isEmpty
      );
      this.localValue = {
        ...this.localValue,
        type: this.typeValue(),
      };
      this.$emit('input', this.localValue);
    }

    clearAll(): void {
      this.localValue = {};
      this.$emit('input', this.localValue);
    }

    optionsToTreeAndHash(options: string[][]): QuickModeOptions {
      let tree: QuickModeOption[] = [];

      // Build a hash table and map options to objects
      const hash: Record<string, QuickModeOption> = options.reduce((memo, option: string[]) => {
        const id = option[0];
        if (!hash?.[id]) {
          return {
            ...memo,
            [id]: {
              label: option[1],
              value: option[0],
              dependency: option[3] ? option[3].split(',') : undefined,
              children: [],
              parent: option[2],
            },
          };
        }
        return memo;
      }, {});

      // Loop over hash table
      Object.keys(hash).forEach((id) => {
        const option = hash[id];

        // If the element is not at the root level, add it to its parent array of children. Note this will continue till we have only root level elements left
        if (option.parent) {
          const parentId = option.parent;
          hash[parentId].children.push(option);
        }
        // If the element is at the root level, directly push to the tree
        else {
          tree.push(option);
        }
      });

      return { tree, hash };
    }

    mounted(): void {
      this.$api.getToocs({ cache: true }).then(({ data }) => {
        this.toocsSections = data.map((i) => ({
          ...i,
          quickModeOptions: this.optionsToTreeAndHash(i.options),
        }));
      });
    }
  }
