import type { TimesheetTime } from '@app/models/question-response-types';
import { range } from 'lodash';
import type { Moment } from 'moment';
import moment from 'moment';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import type { QuestionSfcOnly } from '../sub-form-completion/utils';
import type { FieldType, QuestionTypeMap, SubFormQuestion } from '@app/models/sub-form-question';
import type { TenantUser } from '@app/models/tenant-user';

@Component
export default class BaseTimesheet extends Vue {
  @Prop(Date) readonly startDate!: Date;
  @Prop(Date) readonly endDate?: Date;
  @Prop(Object) readonly user!: Pick<TenantUser, 'shifts' | 'schedule'>;
  @Prop(String) readonly dateFormat!: string;
  @Prop(Object) readonly question!: Pick<SubFormQuestion<QuestionTypeMap<FieldType.timesheet>['options']>, QuestionSfcOnly>;

  @Watch('form', { deep: true })
  onFormChanged(shifts: Record<string, TimesheetTime>) {
    const formattedShifts = this.shiftsToTime(shifts);
    this.shiftsLoaded && this.shiftsChanged(formattedShifts);
  }

  @Emit('shifts-changed')
  shiftsChanged(shifts: Record<string, TimesheetTime>): Record<string, TimesheetTime> {
    return shifts;
  }

  form: Record<string, TimesheetTime> = {};
  shiftsLoaded = false;

  dateRange(start: Moment, end: Moment): Moment[] {
    const days = end.diff(start, 'days') + 1;
    return range(0, days).map((i) => start.clone().add(i, 'd'));
  }

  prettyDay(day: string | Moment) {
    return moment(day, this.dateFormat).format('ddd [the] Do [of] MMM, YYYY');
  }

  toDate(date: string | undefined): Date | undefined {
    return (date && new Date(date)) || undefined;
  }

  shiftsToTime(shifts: Record<string, TimesheetTime>) {
    return Object.keys(shifts).reduce((memo, day) => {
      return {
        ...memo,
        [day]: {
          ...shifts[day],
          start_at: moment(shifts[day].start_at).format('LT'),
          end_at: moment(shifts[day].end_at).format('LT'),
        },
      };
    }, {});
  }
}
