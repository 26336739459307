
  import { useAccountStore } from '@app/stores/account';
  import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
  import LocationSelector from '../location/location-selector.vue';
  import Select2 from '../select2.vue';
  import UserSelector from '../user/user-selector.vue';
  import type { ServiceCategory } from '@app/models/service-category';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { CompanyRegister } from '@app/models/company-register';
  import type { CompanyRegisterQuestionOptions } from '@app/models/question-options/company-register-question-options';

  @Component({ components: { UserSelector, Select2, LocationSelector } })
  export default class CompanyRegisterForm extends Vue {
    @Prop(Boolean) readonly readonly!: boolean;
    @Prop(Object) question!: SubFormQuestion<CompanyRegisterQuestionOptions>;
    @Prop(String) name!: string;

    serviceCategories: ServiceCategory[] = [];
    form: Partial<CompanyRegister> = {};
    numberTypes = [
      ['australia', 'Australia'],
      ['new_zealand', 'New Zealand'],
    ];

    beforeMount(): void {
      this.$api
        .getServiceCategories({ filters: { active: true }, per_page: -1, only: ['id', 'name'] }, { cache: true })
        .then(({ data }) => {
          this.serviceCategories = data;
        });
      const serviceCategoryId = this.question.config.service_category_id;
      this.form = {
        number_type: 'australia',
        service_category_id: (serviceCategoryId && parseInt(serviceCategoryId)) || undefined,
      };
    }

    get allFields(): boolean {
      return this.question.config.all_fields === 'true';
    }

    get showVendorCode(): boolean {
      return !!this.getServiceCategory(this.form.service_category_id)?.vendor_code;
    }

    get showProviderNumber(): boolean {
      return !!this.getServiceCategory(this.form.service_category_id)?.provider_number;
    }

    get accountStore() {
      return useAccountStore();
    }

    getServiceCategory(id?: number | string): ServiceCategory | undefined {
      return (id && this.serviceCategories.find((sc) => `${sc.id}` === `${id}`)) || undefined;
    }

    get terms(): string {
      return (
        this.accountStore.data.term ||
        this.$t('tenant.sub_form_completions.form_fields.edit.new_company_register.I_confirm_that').toString()
      );
    }

    onServiceCategoryChange(id?: string): void {
      const serviceCategory = this.getServiceCategory(id);
      this.onFormUpdated('vendor_code', serviceCategory?.vendor_code ? this.form.vendor_code : '');
      this.onFormUpdated('provider_number', serviceCategory?.provider_number ? this.form.provider_number : '');
      this.onFormUpdated('service_category_id', id);
    }

    @Emit('input')
    onFormUpdated(key: keyof CompanyRegister, value: CompanyRegister[keyof CompanyRegister]): Partial<CompanyRegister> {
      this.form = {
        ...this.form,
        [key]: value,
      };
      return this.form;
    }
  }
