
  import type { BaseEntity } from '@app/models/base-entity';
  import { get, groupBy, uniq } from 'lodash';
  import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
  import { filter, map } from 'rxjs/operators';
  import type { Subscription } from 'rxjs';
  import type { BaseRecord } from '@app/models/module-record';
  import { ConfiguratorFilterSource, type ConfiguratorFilter, type DateRangeFilterValue } from '@app/models/configurator-filter';
  import type { SubFormCompletion } from '@app/models/sub-form-completion';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { Dictionary } from '@app/models/dictionary';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import type { FormObservers } from '@app/utils/types/form-observers';

  @Component({ components: {} })
  export default class FilterErrors<T extends BaseEntity> extends Vue {
    @Model('input', { type: Array, default: () => [] }) readonly value!: ConfiguratorFilter[];
    @Prop(Object) readonly record?: BaseRecord;
    @Prop(Object) readonly completion?: Pick<SubFormCompletion, 'id' | 'record_id' | 'sub_form_id'>;
    @Prop([Object, Function]) filters?: DonesafeFilterOptions<T> | (() => DonesafeFilterOptions<T>);
    @Prop(Object) readonly fieldUpdate$?: FormObservers['fieldUpdate$'];

    @Watch('fieldUpdate$', { immediate: true })
    onFieldUpdate$Changed(val: Maybe<FormObservers['fieldUpdate$']>) {
      if (val && !this.fieldUpdateSubscription) {
        this.fieldUpdateSubscription = val
          .pipe(
            map((data) => data[0]),
            filter((question) => this.incompleteQuestionIds.includes(question.id))
          )
          .subscribe(() => this.$nextTick(() => this.onOpenDetails()));
      }
    }

    incompleteQuestions: SubFormQuestion[] = [];
    detailsOpened = false;
    fieldUpdateSubscription: Nullable<Subscription> = null;

    get incompleteQuestionIds(): number[] {
      return this.incompleteQuestions.map((q) => q.id);
    }

    get questionsGroupedByCode(): Dictionary<SubFormQuestion[]> {
      return groupBy<SubFormQuestion>(this.incompleteQuestions, 'code');
    }

    getFilters(): Maybe<DonesafeFilterOptions<T>> {
      if (typeof this.filters === 'function') {
        return this.filters();
      }
      return this.filters;
    }

    onOpenDetails(): void {
      const filters = this.getFilters();

      const rangeValues = this.value.reduce((memo, filter) => {
        const actualKey = filter.invert === 'true' ? `!${filter.key}` : filter.key;
        if (filter.source === ConfiguratorFilterSource.range) {
          const currentFilterValues = get(filters, actualKey);
          const range = filter.value?.filter((i, ind) => !currentFilterValues?.[ind]) || [];
          return [...memo, ...range];
        }
        return memo;
      }, [] as DateRangeFilterValue[]);

      const ids = [...rangeValues, ...this.value].filter((f) => f?.source === ConfiguratorFilterSource.question_id).map((f) => f?.value);
      const codes = [...rangeValues, ...this.value]
        .filter((f) => f?.source === ConfiguratorFilterSource.question_code)
        .map((f) => f?.value);

      this.$api
        .getSubFormQuestions(
          {
            filters: {
              active: true,
              sub_form_section: {
                sub_form_id: [this.completion?.sub_form_id, this.record?.sub_form_completion?.sub_form_id],
              },
            },
            per_page: -1,
            only: ['id', 'code', 'title'],
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.incompleteQuestions = data.filter((question) => {
            return ids.includes(`${question.id}`) || codes.includes(question.code);
          });
          this.detailsOpened = true;
        });
    }

    onCloseDetails(): void {
      this.detailsOpened = false;
    }

    printTitle(questions: SubFormQuestion[]): string {
      return uniq(questions.map((q) => q.title)).join(' | ');
    }

    beforeDestroy(): void {
      this.fieldUpdateSubscription?.unsubscribe();
    }
  }
