
  import type { MainFormRelationFieldValue } from '@app/models/question-response-types';
  import type { DonesafeModuleRecordExtraFilters } from '@app/services/api/module-records-api';
  import { omit } from 'lodash';
  import { filter, map } from 'rxjs/operators';
  import { Component, Ref, Watch } from 'vue-property-decorator';
  import type { FieldType } from '@app/models/sub-form-question';
  import type { ModuleRecord } from '@app/models/module-record';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { dependentFilterQuestionIds } from '@app/utils/dependent-filter-question-ids';

  import FilterErrors from '../admin/questions/filter-configurator/filter-errors.vue';
  import MapRecordSelector from '../map-record-selector.vue';
  import RecordSelector from '../record-selector.vue';
  import AutoSelectWarnings from '../record-auto-select/auto-select-warnings.vue';
  import AutoSelectToggle from '../record-auto-select/auto-select-toggle.vue';
  import WithRecordAutoSelect from '../../mixins/with-record-auto-select.vue';

  @Component({ components: { MapRecordSelector, RecordSelector, FilterErrors, AutoSelectWarnings, AutoSelectToggle } })
  export default class MainFormRelationField extends WithRecordAutoSelect<FieldType.main_form_relation> {
    @Ref() readonly mapRecordSelector?: MapRecordSelector;

    localValue: MainFormRelationFieldValue = { value: '' };
    initialized = false;

    get requiredFilters(): DonesafeFilterOptions<ModuleRecord, DonesafeModuleRecordExtraFilters> {
      return { module_name_id: this.question.config.main_form_id };
    }

    get listenOnExpensableBroadcastRequest(): boolean {
      return this.accountStore.expensingEnabled && this.question.config.expensable_mode === 'true';
    }

    get mapSelectionEnabled(): boolean {
      // TODO: support mandatory field
      return this.question.config.map_selection === 'true';
    }

    get showClearButton(): boolean {
      return this.defaultTemplating || this.question.config.allow_clear_response === 'true';
    }

    @Watch('autoSelectRecord')
    onAutoSelectRecordChanged(record: Nullable<ModuleRecord>): void {
      if (!!record) {
        this.updateValue(record.id);
        this.recordSelector?.addOption(String(record.id), record.title, true);
      }
    }

    onOpen(): boolean {
      !this.defaultTemplating && this.checkIncompleteFilters(this.getFilters());
      return !this.incompleteFilters.length;
    }

    initSubscription(): void {
      this.addSubscription(
        this.formObservers.fieldUpdate$
          .pipe(
            map((data) => data[0]),
            filter((question) => dependentFilterQuestionIds(this.question.config.filters)[question.id])
          )
          .subscribe(() => this.$nextTick(() => this.mapRecordSelector?.refreshTable()))
      );

      if (this.listenOnExpensableBroadcastRequest) {
        this.addSubscription(
          this.formObservers.requestExpensableMainFormRelationBroadcast$.subscribe(() =>
            this.$nextTick(() => this.sendUpdate(this.localValue))
          )
        );
        this.addSubscription(
          this.formObservers.clearExpensableMainFormRelation$.subscribe(() => {
            this.updateValue(''); // this does not always sendUpdate
            this.$nextTick(() => this.sendUpdate(this.localValue));
          })
        );
      }

      if (this.mapSelectionEnabled || !this.hasAutoSelect) {
        return;
      }

      this.initAutoSelectSubscriptions();
    }

    updateValue(value?: string | number): void {
      const newValue = (value && `${value}`) || undefined;
      if (this.localValue.value === newValue) {
        return;
      }

      this.lookupRequest({ lookupRecordId: newValue, autoSelectInProgress: this.autoSelectInProgress });
      this.localValue = { value: newValue };
      this.sendUpdate(this.localValue);
      this.$emit('input', this.localValue);
    }

    onInit(): void {
      this.initialized = true;
      this.hasAutoSelect && this.onAutoSelectInit();
      this.sendUpdate(this.localValue, true);
    }

    setMainFormIdAsValue() {
      this.$api
        .getModuleRecords({
          only: ['id', 'title'],
          filters: { module_name_id: this.question.config.main_form_id, id: this.mainFormId },
        })
        .then(({ data }) => {
          if (data.length) {
            this.localValue = { ...this.value, value: this.mainFormId };
            this.sendUpdate(this.localValue);
            this.$emit('input', this.localValue);
          }
          this.onInit();
        })
        .catch(() => {
          this.onInit();
        });
    }

    mounted(): void {
      if (!this.value?.value && this.mainFormId) {
        this.setMainFormIdAsValue();
      } else {
        this.localValue = { ...this.value };
        this.onInit();
      }

      this.initSubscription();
    }

    reFetchData(): void {
      const filters = this.getFilters();
      const allowAutoSelect = this.checkIncompleteFilters(filters, false);
      if (this.incompleteFilters.length || !allowAutoSelect) {
        this.updateValue();
        return;
      }
      this.autoSelectInProgress = true;
      this.updateLoadingState(true);
      this.$api
        .getModuleRecords(
          {
            only: ['id', 'title'],
            per_page: 1,
            sort: this.question.config.sort,
            filters: {
              ...this.requiredFilters,
              ...filters,
            },
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.recordSelector?.setOptions(data);
          if (data.length) {
            this.updateValue(data[0].id);
            this.warnings = omit(this.warnings, 'not_found');
          } else {
            this.updateValue();
            this.warnings = { ...this.warnings, not_found: this.$t('app.labels.no_records_found') };
          }
        })
        .finally(() => {
          this.autoSelectInProgress = false;
          this.updateLoadingState(false);
        });
    }
  }
