
  import { LOCAL_STORE_MAP_TYPE, MapTypeId } from '@app/models/question-response-types';
  import { Component, Prop } from 'vue-property-decorator';
  import { defaultLatLngZoom } from '@app/services/location';
  import BaseFieldDisplay from './base-field-display';
  import type { FieldType } from '@app/models/sub-form-question';
  import type { LatLng } from '@app/models/geocoder-result';
  import { MapSize } from '@app/models/question-options/address-question-options';
  import qs from 'qs';

  @Component
  export default class AddressDisplay extends BaseFieldDisplay<FieldType.address> {
    @Prop(String) readonly action?: string;

    mapOptions: google.maps.MapOptions = {
      gestureHandling: 'none',
      disableDefaultUI: true,
      keyboardShortcuts: false,
      clickableIcons: false,
    };

    get mapSelectEnabled(): boolean {
      return this.accountStore.allowMapSelection && this.question.config.map_select_enabled === 'true';
    }

    get mapHeight(): string {
      return this.question.config.map_display_height || MapSize.small;
    }

    get mapTypeId(): MapTypeId {
      return MapTypeId[localStorage.getItem(LOCAL_STORE_MAP_TYPE) as keyof typeof MapTypeId] || MapTypeId.terrain;
    }

    get mapZoom(): number {
      return (this.response.zoom && +this.response.zoom) || defaultLatLngZoom().zoom;
    }

    get mapCenter(): LatLng | undefined {
      if (!this.response.latitude || !this.response.longitude) return;

      return { lat: +this.response.latitude, lng: +this.response.longitude };
    }

    get staticMapUrl(): Maybe<string> {
      if (!this.mapCenter) return;

      return `/api/utils/static_map?${qs.stringify({
        latitude: this.mapCenter.lat,
        longitude: this.mapCenter.lng,
        zoom: this.mapZoom,
        map_type: this.mapTypeId,
        height: this.mapHeight,
        scale: 2, // to have better quality of the bitmap
      })}`;
    }

    // TODO: check read lookup values in app/models/sub_form_response.rb:810 from the call sanitize(print_value_current_user(response))
    get responseText(): string {
      return this.$sanitize(this.response.value || this.fallbackValue);
    }
  }
