import type { VNodeDirective } from 'vue';

let cachedSvgElement: SVGSVGElement | null = null;

export default {
  inserted: (el: HTMLElement, binding: VNodeDirective) => {
    updateFn(el, binding);
  },
  updated(el: HTMLElement, binding: VNodeDirective) {
    updateFn(el, binding);
  },
};

const updateFn = (el: HTMLElement, binding: VNodeDirective) => {
  if (binding.value.html) {
    el.innerHTML = binding.value.html;
  }

  if (binding.value.required) {
    const lastChild = el.lastElementChild;

    const space = document.createTextNode(' ');

    const svgElement = createRequiredIconSvg();
    const appendTo = lastChild?.tagName.toLowerCase() === 'p' ? lastChild : el;

    [space, svgElement].forEach((elt) => appendTo.appendChild(elt));
  }
};

const createRequiredIconSvg = () => {
  if (cachedSvgElement) {
    return cachedSvgElement.cloneNode(true);
  }

  const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  svgElement.setAttribute('class', 'required-icon');
  svgElement.setAttribute('viewBox', '0 0 128 128');

  const svgPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  svgPath.setAttribute(
    'd',
    'M110.1,16.4L75.8,56.8l0.3,1l50.6-10.2v32.2l-50.9-8.9l-0.3,1l34.7,39.1l-28.3,16.5L63.7,78.2L63,78.5   l-18.5,49L17.2,111l34.1-39.8v-0.6l-50,9.2V47.6l49.3,9.9l0.3-0.6L17.2,16.7L45.5,0.5l17.8,48.7H64L82.1,0.5L110.1,16.4z'
  );

  svgElement.appendChild(svgPath);

  cachedSvgElement = svgElement;

  return svgElement;
};
