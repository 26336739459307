import moment from 'moment-timezone';
import type { Account } from '@app/models/account';

type ReportFormat = 'default' | 'utc' | 'local' | 'mail_merge';

export class DatetimeToString {
  private currentAccount: Account;
  private datetime: string;
  private reportFormat: ReportFormat;
  private timezone: string;
  private datetimeFormat?: string;
  private noGmtForDefaultReportFormat: boolean;

  constructor(data: {
    currentAccount: Account;
    datetime: string;
    datetimeFormat?: string;
    noGmtForDefaultReportFormat?: boolean;
    reportFormat?: ReportFormat;
    timezone?: string;
  }) {
    this.datetime = data.datetime;
    this.reportFormat = data.reportFormat || 'utc';
    this.datetimeFormat = data.datetimeFormat;
    this.noGmtForDefaultReportFormat = data.noGmtForDefaultReportFormat || false;
    this.currentAccount = data.currentAccount;
    this.timezone = data.timezone || this.currentAccount.timezone;
  }

  public getResult(): string {
    return this.parseDateTime();
  }

  private parseDateTime(): string {
    if (!this.datetime) return '';

    let parsedDatetime: moment.Moment = moment(this.datetime);
    parsedDatetime = parsedDatetime.tz(this.timezone);

    if (!parsedDatetime) return '';

    switch (this.reportFormat) {
      case 'utc':
        return parsedDatetime.utc().toISOString();
      case 'local':
        return this.outputDatetime(parsedDatetime, true);
      default:
        return this.outputDatetime(
          parsedDatetime,
          this.noGmtForDefaultReportFormat ? false : this.currentAccount ? !!this.currentAccount?.complex_timezones : false
        );
    }
  }

  private outputDatetime(datetime: moment.Moment, includeGmt: boolean): string {
    return `${this.basicFormat(datetime)}${includeGmt ? this.gmt(datetime) : ''}`;
  }

  private gmt(datetime: moment.Moment): string {
    const date = moment.tz(datetime, this.timezone);

    if (this.currentAccount?.schema === 'suncorp') return ` (GMT ${date.format('Z')})`;
    // TODO: hard coding :00 will be a problem if offset is eg: 09:30
    // also there is no 0 at the start of the offset if it is less than 10
    return ` (GMT ${date.utcOffset() >= 0 ? '+' : '-'}${Math.abs(date.utcOffset() / 60)}:00)`;
  }

  private basicFormat(datetime: moment.Moment): string {
    return this.datetimeFormat
      ? datetime.format(this.datetimeFormat)
      : datetime.format(this.currentAccount ? this.currentAccount.datetimepicker_datetime_format : '');
  }
}
