
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { useAccountStore } from '@app/stores/account';
  import bootbox from 'bootbox';
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import UserDocumentFormModal from './user-document-form-modal.vue';
  import { BaseTable } from '../base-table';
  import type { Document } from '@app/models/document';
  import type { ListManagerField } from '@app/services/list-manager/types';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';

  @Component({
    components: { UserDocumentFormModal, BaseTable },
  })
  export default class UserDocumentsTable extends Vue {
    @Ref() readonly table?: BaseTable<Document>;
    @Ref() readonly modal?: UserDocumentFormModal;
    @Prop(Number) readonly userId!: number;

    modalVisible = false;
    manager: Nullable<ListManager<Document>> = null;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get accountStore() {
      return useAccountStore();
    }

    get fields(): ListManagerField<Document>[] {
      return [
        { title: '', name: 'attachment' },
        { title: this.$t('app.labels.name'), name: 'name', sortField: 'name' },
        { title: this.$t('app.labels.description'), name: 'description', sortField: 'description' },
        { title: this.$t('app.labels.valid_until'), name: 'valid_until', sortField: 'valid_until' },
        ...(this.permissionForEdit ? [{ title: '', name: 'delete' }] : []),
      ];
    }

    get permissionForEdit(): boolean {
      return this.currentUserStore.checkProfilePermission({ id: this.userId }, 'documents', 'edit');
    }

    getManager(): ListManager<Document> {
      return new ListManager<Document>({
        fetchDataFunction: (params) => {
          return this.$api.getDocuments({ ...params, filters: { user_id: this.userId }, include: ['user', 'attachment'] }, { cache: true });
        },
        useHistory: false,
        per_page: 25,
        sortOrder: [{ direction: 'asc', field: 'name', sortField: 'name' }],
        fields: this.fields,
      });
    }

    beforeMount(): void {
      this.manager = this.getManager();
    }

    reloadData(): void {
      this.$api.cache.clear();
      this.table?.reload();
    }

    toggleModal(value: boolean, document?: Partial<Document>): void {
      const documentToEdit = document || { user_id: this.userId };
      value && this.modal?.reset(documentToEdit);
      this.modalVisible = value;
    }

    formatValidUntil(date: string) {
      return this.accountStore.dateFormat(date);
    }

    createOrUpdateDocument(form: Partial<Document & { file: string }>): void {
      const promise = form.id ? this.$api.updateDocument(form.id as number, form) : this.$api.createDocument(form);
      const message = form.id ? this.$t('tenant.documents.update.document_updated') : this.$t('tenant.documents.create.document_created');
      promise
        .then(() => {
          toaster(message);
          this.toggleModal(false);
          this.reloadData();
        })
        .catch(({ data }) => toaster({ text: data?.error, icon: 'error' }));
    }

    deleteDocument(document: Document): void {
      bootbox.confirm({
        backdrop: false,
        title: this.$t('app.labels.are_you_sure'),
        message: this.$t('tenant.documents.destroy.will_be_deleted'),
        callback: (result: boolean) => {
          if (result) {
            this.$api
              .deleteDocument(document.id)
              .then(() => {
                toaster(this.$t('tenant.documents.destroy.document_deleted'));
                this.reloadData();
              })
              .catch(({ data }) => toaster({ text: data?.error, position: 'top-right', icon: 'error' }));
          }
        },
      });
    }
  }
