import moment from 'moment';
import type { BaseEntity } from '../../models/base-entity';
import type { DetailsLink, VersionAccount, VersionTime } from './models';
import type { CurrentUserStoreData } from '@app/stores/currentUser';
import type { AutomationDefinition } from '@app/models/automation-definition';
import type { PaperTrailChange, PaperTrailVersion } from '@app/models/paper-trail-version';
import type { TenantUser } from '@app/models/tenant-user';
import { AdminPermissionFeature } from '@app/models/admin-permission';
import { featureEnabled } from '@app/utils/feature-enabled';

export type ToTextFunction = (link: DetailsLink, withPrefix?: boolean) => string;

export const toText: ToTextFunction = (link, withPrefix) => {
  let title: string;
  if (withPrefix) {
    title = `${link.prefix}: ${link.title}`;
  } else {
    title = link.title;
  }

  return link.id ? `${title} [${link.id}]` : title;
};

export const versionTime = (account: VersionAccount, string?: string): VersionTime => {
  const timeFormat = account.datetimepicker_time_format === 'h:mm A' ? 'h:mm:ss A' : 'HH:mm:ss';
  const date = moment(string).tz(`${account?.timezone}`);

  return {
    date: date.format(`${account.datetimepicker_date_format} ${timeFormat}`),
    tz: date.format('Z z'),
  };
};

export const versionDate = (account: VersionAccount, change?: PaperTrailChange): string => {
  const value = `${change}`.replace('T00:00:00.000Z', '');
  return moment(value).format(account.datetimepicker_date_format);
};

export const automationRecordId = (automation: AutomationDefinition): string | number | undefined => {
  return automation.sub_form_id || automation.involvement_id || automation.regulatory_report_config_id;
};

export const automationRecordPath = (automation: AutomationDefinition): string | undefined => {
  if (automation.sub_form_id) {
    return `/admin/settings/sub_forms/${automation.sub_form_id}`;
  } else if (automation.regulatory_report_config_id) {
    return `/admin/settings/regulatory_reports/${automation.regulatory_report_config_id}`;
  }
};

export const linksToString = (links?: DetailsLink[]): string => {
  return links?.map((link) => toText(link, true)).join(', ') || '';
};

export const filterHighlightText = (str: string, queries: string[]): string => {
  const query = queries[0];
  if (query) {
    return str.toString().replaceAll(query, (matchedText) => {
      return `<mark class="text__highlight">${matchedText}</mark>`;
    });
  }

  return str;
};

export const userEditLink = (user: TenantUser, currentUser: CurrentUserStoreData): Maybe<string> => {
  if (featureEnabled(currentUser, AdminPermissionFeature.users_locations_organizations)) {
    return `/admin/settings/users/${user.id}/edit`;
  }
};

export const extractPaperTrailVersionValue = <T extends BaseEntity = BaseEntity, V = PaperTrailChange>(
  key: keyof T,
  version: PaperTrailVersion<T, V>
): Maybe<V> => {
  if (version.event === 'create') {
    return version.object_changes?.[key]?.[1] || undefined;
  } else {
    if (version.object && key in version.object) {
      return version.object[key] as unknown as V;
    }
  }
};
