import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import { SUB_FORM_LIST_DETAILS_ONLY } from './sub-form-list-restriction-service';
import type { SubFormList } from '@app/models/sub-form-list';

export class SubFormListService extends BaseService<SubFormList> {
  static readonly allowedAttributes = [
    'active',
    'add_background_color',
    'add_icon',
    'allow_confidentiality',
    'approval_button_background_color',
    'approval_button_icon',
    'approval_button_text',
    'approval_sub_form_id',
    'auto_save',
    'custom_title',
    'default_template_id',
    'display_actions_count',
    'display_sub_form_question_codes',
    'draft',
    'draft_after_complete',
    'index',
    'involvement_id',
    'module_tab_id',
    'multiple',
    'options',
    'order',
    'record_relation_question_id',
    'record_relations_filters',
    'relationship_options',
    'rule_set_id',
    'start_workflow_id',
    'sub_form_ids',
    'sub_form_list_type',
    'use_in_stored_calculations',
    'user_avatar_option',
    'user_collection_id',
  ];

  async fetchDetails(itemId: ServiceRequest<SubFormList>): Promise<DetailsServiceResponse> {
    const list = await this.fetchEntity(itemId);
    return {
      links: [
        {
          link: `/admin/settings/module_tabs/${list.module_tab_id}`,
          prefix: this.t('app.labels.tab_section'),
          title: list.title,
          id: list.id,
        },
      ],
    };
  }

  async fetchEntity({ itemId }: ServiceRequest<SubFormList>): Promise<SubFormList> {
    const { data: list } = await this.api.getSubFormList(itemId, { only: SUB_FORM_LIST_DETAILS_ONLY }, { cache: true, join: true });
    return list;
  }

  entityToText({ itemId }: ServiceRequest<SubFormList>, entity?: SubFormList): string {
    return entity ? `${entity.title} [${entity.id}]` : `${itemId}`;
  }
}
