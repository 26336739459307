import type { BaseEntity } from '@app/models/base-entity';
import { FieldType } from '@app/models/sub-form-question';

import {
  AccountService,
  ActionableService,
  ActivityService,
  AttachmentService,
  AuditReportTemplateService,
  AutomationDefinitionService,
  AutomatedEventService,
  AutomationEventGroupService,
  ConfidentialityService,
  ConfidentialityTypeService,
  DashboardService,
  DashboardPaneService,
  DefaultTemplateService,
  DocumentService,
  EmploymentTypeService,
  EventService,
  FeatureSetService,
  FollowService,
  GenderIdentityService,
  IndexFilterService,
  IndicatorService,
  IndicatorSetService,
  InvolvementService,
  LocationService,
  LocationTagCountService,
  LocationTagService,
  ModuleNameService,
  ModuleRecordService,
  ModuleTabService,
  NotificationService,
  NotificationUserService,
  OrganizationService,
  PermissionService,
  PlaceholderProfileService,
  ProcedureAcknowledgmentService,
  ProcedureService,
  ProfileService,
  RecordCalculationResultService,
  RecordCalculationService,
  RecordCalculationSubFormListService,
  RecordCalculationSubFormService,
  RecordIndicatorService,
  RecordRelationService,
  RecordSignatureService,
  RecurrableService,
  RecurringSetupService,
  RelationshipService,
  ResponseService,
  RoleModuleNameService,
  RoleProfilePermissionService,
  RoleProfileService,
  RoleService,
  RuleSetService,
  SamlService,
  ScheduleService,
  ScheduledEventService,
  ScoreBandProfileService,
  ScoringGroupService,
  ShiftService,
  SignatureService,
  SubFormCompletionService,
  SubFormListRestrictionService,
  SubFormListRoleService,
  SubFormListService,
  SubFormListSubFormService,
  SubFormListsTabSectionGroupService,
  SubFormQuestionService,
  SubFormResponseService,
  SubFormSectionService,
  SubFormService,
  SuperReportService,
  TabSectionGroupService,
  UserCollectionService,
  UserInvolvementService,
  UserLocationService,
  UserLocationTagService,
  UserModuleRecordService,
  UserOrganizationService,
  UserService,
  WidgetService,
  WorkflowLinkService,
  WorkflowService,
  WorkplaceIndustryService,
} from './services';
import type { BaseService } from './services/base-service';
import type { ResponseType } from './models';

export const SERVICES_MAPPING: Record<string, typeof BaseService<BaseEntity>> = {
  Account: AccountService,
  Actionable: ActionableService,
  Activity: ActivityService,
  Attachment: AttachmentService,
  AuditReportTemplate: AuditReportTemplateService,
  AutomationDefinition: AutomationDefinitionService,
  AutomatedEvent: AutomatedEventService,
  AutomationEventGroup: AutomationEventGroupService,
  Confidentiality: ConfidentialityService,
  ConfidentialityType: ConfidentialityTypeService,
  Dashboard: DashboardService,
  DashboardPane: DashboardPaneService,
  DefaultTemplate: DefaultTemplateService,
  Document: DocumentService,
  EmploymentType: EmploymentTypeService,
  Event: EventService,
  FeatureSet: FeatureSetService,
  Follow: FollowService,
  GenderIdentity: GenderIdentityService,
  IndexFilter: IndexFilterService,
  Indicator: IndicatorService,
  IndicatorSet: IndicatorSetService,
  Involvement: InvolvementService,
  Location: LocationService,
  LocationTag: LocationTagService,
  LocationTagCount: LocationTagCountService,
  ModuleName: ModuleNameService,
  ModuleRecord: ModuleRecordService,
  ModuleTab: ModuleTabService,
  Notification: NotificationService,
  NotificationUser: NotificationUserService,
  Organization: OrganizationService,
  Permission: PermissionService,
  PlaceholderProfile: PlaceholderProfileService,
  Procedure: ProcedureService,
  Profile: ProfileService,
  RecordCalculation: RecordCalculationService,
  RecordCalculationResult: RecordCalculationResultService,
  RecordCalculationSubForm: RecordCalculationSubFormService,
  RecordCalculationSubFormList: RecordCalculationSubFormListService,
  RecordIndicator: RecordIndicatorService,
  RecordRelation: RecordRelationService,
  RecordSignature: RecordSignatureService,
  Recurrable: RecurrableService,
  'Recurring::Setup': RecurringSetupService,
  Relationship: RelationshipService,
  Response: ResponseService,
  Role: RoleService,
  RoleModuleName: RoleModuleNameService,
  RoleProfile: RoleProfileService,
  RoleProfilePermission: RoleProfilePermissionService,
  RuleSet: RuleSetService,
  SAML: SamlService,
  Schedule: ScheduleService,
  ScheduledEvent: ScheduledEventService,
  ScoreBandProfile: ScoreBandProfileService,
  ScoringGroup: ScoringGroupService,
  Shift: ShiftService,
  Signature: SignatureService,
  SubForm: SubFormService,
  SubFormCompletion: SubFormCompletionService,
  SubFormList: SubFormListService,
  SubFormListRestriction: SubFormListRestrictionService,
  SubFormListRole: SubFormListRoleService,
  SubFormListSubForm: SubFormListSubFormService,
  SubFormListsTabSectionGroup: SubFormListsTabSectionGroupService,
  SubFormQuestion: SubFormQuestionService,
  SubFormResponse: SubFormResponseService,
  SubFormSection: SubFormSectionService,
  SuperReport: SuperReportService,
  TabSectionGroup: TabSectionGroupService,
  User: UserService,
  UserCollection: UserCollectionService,
  UserInvolvement: UserInvolvementService,
  UserLocation: UserLocationService,
  UserLocationTag: UserLocationTagService,
  UserOrganization: UserOrganizationService,
  Widget: WidgetService,
  Workflow: WorkflowService,
  WorkflowLink: WorkflowLinkService,
  WorkplaceIndustry: WorkplaceIndustryService,
};

const user_id = 'User';
const workflow_id = 'Workflow';
const sub_form_id = 'SubForm';
const sub_form_list_id = 'SubFormList';
const sub_form_section_id = 'SubFormSection';

export const ATTRIBUTES_MAPPING: Record<string, Record<string, string>> = {
  Account: {
    fallback_location_id: 'Location',
    fallback_organization_id: 'Organization',
  },
  Activity: {
    user_id,
    assignee_id: 'User',
    actionable_id: 'Actionable',
  },
  Attachment: {
    user_id,
    id: 'Attachment',
  },
  DefaultTemplate: {
    module_name_id: 'ModuleName',
  },
  RecordRelation: {
    from_id: 'ModuleRecord',
    to_id: 'ModuleRecord',
    user_id,
  },
  RecordIndicator: {
    indicator_id: 'Indicator',
  },
  ModuleRecord: {
    user_id,
    location_id: 'Location',
    organization_id: 'Organization',
    sub_form_completion_id: 'SubFormCompletion',
    workflow_id,
  },
  ModuleName: {
    sub_form_id,
    start_workflow_id: 'Workflow',
    location_sub_form_question_id: 'SubFormQuestion',
    organization_sub_form_question_id: 'SubFormQuestion',
    scheme_sub_form_question_id: 'SubFormQuestion',
    score_band_profile_id: 'ScoreBandProfile',
  },
  Comment: { user_id },
  SubFormCompletion: {
    user_id,
    sub_form_id,
    sub_form_list_id,
    workflow_id,
  },
  AutomationDefinition: {
    sub_form_id,
    involvement_id: 'Involvement',
  },
  SubFormList: {
    approval_sub_form_id: 'SubForm',
  },
  Workflow: { sub_form_list_id },
  AuditReportTemplate: { sub_form_list_id },
  SubFormListRestriction: { sub_form_list_id },
  SubFormListRole: { sub_form_list_id },
  RecordSignature: { id: 'RecordSignature' },
  SubFormQuestion: {
    sub_form_section_id,
    sub_form_question_id: 'SubFormQuestion',
  },
  SubFormListsTabSectionGroup: SubFormListsTabSectionGroupService.attributeServices,
  SubFormSection: { sub_form_section_id },
  SubFormResponse: { response: 'Response' },
  PermissionRole: { permission_id: 'Permission' },
  LocationTagPermission: { permission_id: 'Permission' },
  Location: { location_id: 'Location' },
  Organization: { organization_id: 'Organization' },
  AccessibleDashboard: {
    role_id: 'Role',
    dashboard_id: 'Dashboard',
  },
  Role: { dashboard_id: 'Dashboard' },
  RoleConfidentialityType: { confidentiality_type_id: 'ConfidentialityType' },
  SuperReportRole: {
    role_id: 'Role',
    super_report_id: 'SuperReport',
  },
  Widget: { dashboard_id: 'Dashboard' },
  'Recurring::Setup': RecurringSetupService.attributeServices,
  UserInvolvement: { user_id },
  Follow: { user_id },
  User: UserService.attributeServices,
  UserLocation: UserLocationService.attributeServices,
  UserLocationTag: UserLocationTagService.attributeServices,
  LocationTagCount: LocationTagCountService.attributeServices,
  Document: DocumentService.attributeServices,
  UserModuleRecord: UserModuleRecordService.attributeServices,
  NotificationUser: NotificationUserService.attributeServices,
  UserOrganization: UserOrganizationService.attributeServices,
  Signature: SignatureService.attributeServices,
  ModuleTab: ModuleTabService.attributeServices,
  ProcedureAcknowledgment: ProcedureAcknowledgmentService.attributeServices,
  WorkflowLink: WorkflowLinkService.attributeServices,
  RoleProfile: RoleProfileService.attributeServices,
  RoleProfilePermission: RoleProfilePermissionService.attributeServices,
  RoleModuleName: RoleModuleNameService.attributeServices,
  SubFormListSubForm: SubFormListSubFormService.attributeServices,
  RecordCalculationSubForm: RecordCalculationSubFormService.attributeServices,
  RecordCalculationSubFormList: RecordCalculationSubFormListService.attributeServices,
  DashboardPane: DashboardPaneService.attributeServices,
};

export const RESPONSES_MAPPING: Record<ResponseType, string> = {
  Actionable: 'ActionableResponse',
  Attachment: 'AttachmentResponse',
  Dashboard: 'DashboardResponse',
  Date: 'DateResponse',
  DateTime: 'DateTimeResponse',
  Description: 'DescriptionResponse',
  Direct: 'DirectResponse',
  Indicator: 'IndicatorResponse',
  Location: 'LocationResponse',
  ModuleName: 'ModuleNameResponse',
  IndexFilter: 'IndexFilterResponse',
  ModuleRecord: 'RecordResponse',
  Organization: 'OrganizationResponse',
  RecordSignature: 'RecordSignatureResponse',
  Response: 'QuestionResponse',
  Role: 'RoleResponse',
  Signature: 'SignatureResponse',
  SubForm: 'SubFormResponse',
  SubFormCompletion: 'SubFormCompletionResponse',
  SubFormList: 'SubFormListResponse',
  SubFormQuestion: 'SubFormQuestionResponse',
  SubFormSection: 'SubFormSectionResponse',
  SuperReport: 'SuperReportResponse',
  User: 'UserResponse',
  Workflow: 'WorkflowResponse',
};

// TODO: register presenter for toocs
// TODO: register presenter for markable
// TODO: register presenter for company register and scheme
export const RESPONSES_QUESTION_MAPPING: Record<FieldType, string> = {
  [FieldType.address]: 'SimpleFieldResponse',
  [FieldType.approval_expense]: '',
  [FieldType.approval_state]: 'TextFieldResponse',
  [FieldType.area]: '',
  [FieldType.button_select]: 'SingleSelectFieldResponse',
  [FieldType.calculation_select]: 'SingleSelectFieldResponse',
  [FieldType.calculation_text]: 'SimpleFieldResponse',
  [FieldType.calculator]: 'SimpleFieldResponse',
  [FieldType.company_register]: '',
  [FieldType.date]: 'SimpleFieldResponse',
  [FieldType.datetime]: 'SimpleFieldResponse',
  [FieldType.detail]: 'SimpleFieldResponse',
  [FieldType.display_image]: 'SimpleFieldResponse',
  [FieldType.display_text]: 'SimpleFieldResponse',
  [FieldType.expense]: '',
  [FieldType.expense_budget_category_select]: 'SimpleFieldResponse',
  [FieldType.expense_budget_category_uuid]: 'SimpleFieldResponse',
  [FieldType.expense_budget_select]: 'SimpleFieldResponse',
  [FieldType.expense_budget_uuid]: 'SimpleFieldResponse',
  [FieldType.file_upload]: 'FileUploadFieldResponse',
  [FieldType.knowledge_base]: 'SimpleFieldResponse',
  [FieldType.location]: 'LocationResponse',
  [FieldType.main_form_question_list]: '',
  [FieldType.main_form_relation]: 'RecordResponse',
  [FieldType.markable]: '',
  [FieldType.matrix]: 'MatrixFieldResponse',
  [FieldType.multi_checkbox]: 'MultiSelectFieldResponse',
  [FieldType.multi_main_form_relation]: 'MultiRecordFieldResponse',
  [FieldType.multi_person_selector]: 'MultiUserFieldResponse',
  [FieldType.multi_select]: 'MultiSelectFieldResponse',
  [FieldType.organization]: 'OrganizationResponse',
  [FieldType.pay_calculator]: '',
  [FieldType.radio]: 'SingleSelectFieldResponse',
  [FieldType.record_relation]: '',
  [FieldType.report]: 'ReportFieldResponse',
  [FieldType.scheme]: '',
  [FieldType.scorm]: '',
  [FieldType.signature]: 'SignatureFieldResponse',
  [FieldType.single_person_selector]: 'UserResponse',
  [FieldType.single_select]: 'SingleSelectFieldResponse',
  [FieldType.sub_form_relation]: 'SubFormRelationFieldResponse',
  [FieldType.svg_selector]: 'SvgSelectorFieldResponse',
  [FieldType.text]: 'TextFieldResponse',
  [FieldType.textarea]: 'TextFieldResponse',
  [FieldType.timesheet]: '',
  [FieldType.timesheet_summary]: '',
  [FieldType.toocs]: '',
  [FieldType.video]: 'SimpleFieldResponse',
  [FieldType.workflow_state]: 'WorkflowStateFieldResponse',
  [FieldType.table_calculation]: '',
};

export const eventNameMapping = ($t: (key: string) => string) => {
  return {
    create: $t('tenant.shared.audit_trail.created'),
    update: $t('tenant.shared.audit_trail.updated'),
    destroy: $t('tenant.shared.audit_trail.deleted'),
  };
};

export const typeTranslationMapping = ($t: (key: string) => string): Record<string, string> => {
  return {
    Event: $t('app.labels.event_config'),
  };
};
