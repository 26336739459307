
  import BaseQuestionResponse from './base-question-response';
  import { Component, Prop } from 'vue-property-decorator';
  import TextHighlight from 'vue-text-highlight';
  import type { Attachment } from '@app/models/attachment';

  @Component({ components: { TextHighlight } })
  export default class FileUploadFieldResponse extends BaseQuestionResponse {
    @Prop(Array) readonly attachments?: Attachment[];
    @Prop(Array) readonly attachmentsText?: string[];
  }
