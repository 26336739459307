
  import { Prop, Component, Vue } from 'vue-property-decorator';

  @Component
  export default class UuidField extends Vue {
    @Prop(String) readonly name!: string;
    @Prop(String) readonly value?: string;

    get uuidGenerated(): boolean {
      return !!this.value;
    }
  }
