import { capitalize } from 'lodash';
import type { DetailsLink } from '../models';
import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import { INVOLVEMENT_DETAILS_ONLY } from './involvement-service';
import type { SubFormList } from '@app/models/sub-form-list';
import type { SubFormListRestriction } from '@app/models/sub-form-list-restriction';
import type { OnlyOptions } from '@app/services/donesafe-api-utils';

export const SUB_FORM_LIST_DETAILS_ONLY: OnlyOptions<SubFormList> = ['id', 'title', 'module_tab_id'];

export class SubFormListRestrictionService extends BaseService {
  async fetchDetails({ itemId }: ServiceRequest<SubFormListRestriction>): Promise<DetailsServiceResponse> {
    const { data: restriction } = await this.api.getSubFormListRestriction(
      itemId,
      { only: ['id', 'permission_type', 'sub_form_list_id', 'relationship'] },
      { join: true, cache: true }
    );
    const { data: list } = await this.api.getSubFormList(
      restriction.sub_form_list_id,
      { only: SUB_FORM_LIST_DETAILS_ONLY },
      { join: true, cache: true }
    );
    const relationshipMapping: Record<string, string> = {
      user: this.t('app.labels.creator'),
      activity_assignees: this.t('app.labels.activity_assignees'),
    };

    let relationshipLink: DetailsLink = {
      prefix: this.t('app.labels.involvement'),
      title: relationshipMapping[restriction.relationship] || capitalize(restriction.relationship),
    };
    if (!isNaN(Number(restriction.relationship))) {
      const { data: involvement } = await this.api.getInvolvement(
        restriction.relationship,
        { only: INVOLVEMENT_DETAILS_ONLY },
        {
          join: true,
          cache: true,
        }
      );
      relationshipLink = {
        prefix: this.t('app.labels.involvement'),
        title: involvement.name,
        id: involvement.id,
      };
    }
    const mainText = `${this.t('app.labels.restriction_type')}: ${capitalize(restriction.permission_type)}`;
    return {
      links: [{ prefix: this.t('app.labels.tab_section'), title: list.title, id: list.id }, relationshipLink],
      mainText,
    };
  }
}
