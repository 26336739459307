
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import type { TableCalculationFieldConfig } from '@app/models/question-options/table-calculation-question-options';

  @Component({ components: { DsModal } })
  export default class PostProgressModal extends Vue {
    @Model('input') readonly value!: boolean;

    @Prop(Boolean) readonly loading!: boolean;
    @Prop(Array) readonly errors!: { code: string; error: string }[];
    @Prop(Object) readonly config!: TableCalculationFieldConfig;

    get title() {
      return this.loading ? 'Please Wait: Posting Data' : 'Post Data';
    }

    get closeButtonText() {
      if (this.loading) return this.$t('app.buttons.cancel');
      if (!!this.errors.length) return this.config?.labels.acknowledge_update_error_button_label || this.$t('app.buttons.close');

      return this.$t('app.buttons.close');
    }

    close() {
      this.$emit('input', false);
    }
  }
