import { render, staticRenderFns } from "./base-table-field-checkbox.vue?vue&type=template&id=1c6b6d2c&scoped=true&lang=pug"
import script from "./base-table-field-checkbox.vue?vue&type=script&lang=ts"
export * from "./base-table-field-checkbox.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c6b6d2c",
  null
  
)

export default component.exports