
  import { Component, Prop } from 'vue-property-decorator';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import PaperTrailsModalLink from '@app/components/paper-trails/paper-trails-modal-link.vue';
  import { mixins } from 'vue-class-component';
  import WithUser from '@app/mixins/with-user';

  @Component({ components: { DsDropdown, PaperTrailsModalLink } })
  export default class UserActions extends mixins(WithUser) {
    @Prop({ type: String, default: () => 'div' }) readonly tag!: string;
  }
