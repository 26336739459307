
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { useAccountStore } from '@app/stores/account';
  import EntitySelector from '@app/components/entity-selector.vue';
  import { Component, Prop } from 'vue-property-decorator';
  import moment from 'moment/moment';
  import DatePicker from '@app/components/date-picker.vue';
  import Select2 from '@app/components/select2.vue';
  import type { Profile } from '@app/models/profile';
  import type { TenantUser } from '@app/models/tenant-user';
  import type { EmploymentType } from '@app/models/employment-type';
  import type { GenderIdentity } from '@app/models/gender-identity';
  import { AdminPermissionFeature } from '@app/models/admin-permission';
  import { toaster } from '@app/utils/toaster';
  import { isPiiField } from '@app/utils/isPiiField';
  import { mixins } from 'vue-class-component';
  import WithUser from '@app/mixins/with-user';
  import { POSSIBLE_PII_FIELDS } from '@app/constants';

  type DateFieldKey = 'date_of_birth' | 'start_date' | 'end_date';

  @Component({
    components: {
      DatePicker,
      EntitySelector,
      Select2,
    },
  })
  export default class UserPersonalDetailsTab extends mixins(WithUser) {
    @Prop(Boolean) readonly admin?: boolean;

    employmentTypes: Partial<EmploymentType>[] = [];
    genderIdentities: Partial<GenderIdentity>[] = [];

    form: Partial<Omit<TenantUser, 'profile'>> & { profile: Partial<Profile> } = { profile: {} };
    user: Nullable<TenantUser> = null;
    loading = false;
    submitting = false;
    countryName = '';
    stateName = '';

    get userGenderIdentity(): Nullable<string> {
      return this.genderIdentities.find((item) => item.id === this.user?.gender_identity_id)?.identity || null;
    }

    get userEmploymentType(): Nullable<string> {
      return this.employmentTypes.find((item) => item.id === this.user?.employment_type_id)?.name || null;
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get accountStore() {
      return useAccountStore();
    }

    get countryFilters() {
      return {
        language: this.language,
      };
    }

    get stateFilters() {
      return {
        language: this.language,
        country_code: this.form.profile.country_code,
      };
    }

    get showClassicCountryFields(): boolean {
      return !!this.accountStore.data.show_classic_country_fields;
    }

    get language(): Maybe<string> {
      return this.currentUserStore.data?.language?.includes('en') ? 'en' : this.currentUserStore.data?.language;
    }

    get canEditProfile(): boolean {
      if (this.admin) {
        return this.currentUserStore.featureEnabled(AdminPermissionFeature.users_locations_organizations);
      }
      return this.currentUserStore.checkProfilePermission({ id: this.userId }, 'personal_details', 'edit');
    }

    get valueDates(): Record<DateFieldKey, Maybe<Date>> {
      return {
        date_of_birth: this.dateValue('date_of_birth'),
        start_date: this.dateValue('start_date'),
        end_date: this.dateValue('end_date'),
      };
    }

    get languageOptions(): [string, string][] {
      return this.accountStore.languages.map((languageId) => [languageId, `${window.DONESAFE.LANGUAGES[languageId]} [${languageId}]`]);
    }

    beforeMount(): void {
      this.loading = true;
      this.$api.getTenantUser(this.userId, { include: ['profile'] }, { cache: true }).then(({ data }) => {
        this.user = data;
        this.form = { ...this.user, profile: { ...this.user?.profile } };
        this.languageAndCountry();
        (POSSIBLE_PII_FIELDS as (keyof Omit<TenantUser, 'profile'>)[]).forEach((field) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (isPiiField(field, this.accountStore.data) && this.user) this.form[field] = this.user[field] as any;
        });
      });
      Promise.all([this.getGenderIdentities(), this.getEmploymentTypes()]).finally(() => (this.loading = false));
    }

    async languageAndCountry() {
      const { data: country } = await this.$api.getCountries(
        { filters: { language: this.language, code: this.form.profile.country_code } },
        { cache: true }
      );
      this.countryName = country[0]?.name;
      const { data: state } = await this.$api.getStates(
        { filters: { code: this.form.profile.state_code, country_code: this.form.profile.country_code } },
        { cache: true }
      );
      this.stateName = state[0]?.name;
    }

    onCountrySelect() {
      this.form = {
        ...this.form,
        profile: {
          ...this.form.profile,
          state_code: undefined,
        },
      };
    }

    getGenderIdentities(): Promise<void> {
      return this.$api.getGenderIdentities({ only: ['id', 'identity'], filters: { active: true } }, { cache: true }).then(({ data }) => {
        this.genderIdentities = data;
      });
    }

    getEmploymentTypes(): Promise<void> {
      return this.$api.getEmploymentTypes({ only: ['id', 'name'], filters: { active: true } }, { cache: true }).then(({ data }) => {
        this.employmentTypes = data;
      });
    }

    includePiiField(field: string): boolean {
      const permitted = isPiiField(field, this.accountStore.data);
      switch (field) {
        case 'employment_type_id':
          return !!this.employmentTypes.length && permitted;
        case 'gender_identity_id':
          return !!this.genderIdentities.length && permitted;
        case 'language':
          return (this.accountStore.data.all_languages || []).length > 1 && permitted;
        case 'date_of_birth':
          return !!this.accountStore.data.display_date_of_birth && permitted;
        case 'start_date':
          return !!this.accountStore.data.display_employment_start_date && permitted;
        case 'end_date':
          return !!this.accountStore.data.display_employment_separation_date && permitted;
        case 'mobile':
          return !!this.accountStore.data.display_mobile_number && permitted;
        default:
          return permitted;
      }
    }

    dateValue(key: DateFieldKey): Maybe<Date> {
      return (this.form[key] && moment(this.form[key]).toDate()) || undefined;
    }

    onDateChange(date: Maybe<Date>, key: DateFieldKey): void {
      const dateString = date && moment(date).format('YYYY-MM-DD');
      this.form = { ...this.form, [key]: dateString || null };
    }

    submitForm(): void {
      this.submitting = true;
      this.$api
        .updateTenantUser(this.userId, {
          ...this.form,
          profile: {
            ...this.form.profile,
            country_code: this.form.profile.country_code || null,
            state_code: this.form.profile.state_code || null,
          },
        })
        .then(() => {
          this.$api.cache.clear();
          toaster({
            text: this.$t('tenant.admin.users.sections.tab_personal_details.success'),
            position: 'top-right',
          });
        })
        .catch(({ data }) => {
          let message = data?.error;
          if (data?.code == '403') {
            message = this.$t('tenant.admin.users.sections.tab_personal_details.no_permissions');
          }
          toaster({ text: message, position: 'top-right', icon: 'error' });
        })
        .finally(() => {
          this.submitting = false;
        });
    }
  }
