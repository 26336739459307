
  import { Vue, Component, Ref } from 'vue-property-decorator';
  import { titleize } from '@app/utils/titleize';
  import type { OnlyOptions } from '@app/services/donesafe-api-utils';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { BaseTable } from '@app/components/base-table';
  import JoinEntities from '@app/components/join-entities.vue';
  import type DashboardPaneGroup from '@app/models/dashboard-pane-group';
  import DynamicDashboardSettingsForm from '@app/components/admin/dashboard-panes/dynamic-dashboard-settings-form.vue';
  import SearchInput from '@app/components/search-input.vue';
  import DsIconText from '@app/components/ds-icon-text.vue';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import PageLayout from '@app/components/admin/dashboard-panes/page-layout.vue';
  import { isEmpty } from 'lodash';
  import { useAccountStore } from '@app/stores/account';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import reloadDynamicDashboard from '@app/reloadDynamicDashboard';
  import { DEFAULT_DYNAMIC_DASHBOARD_SETTINGS } from '../admin/dashboards/utils';

  @Component({
    components: {
      DynamicDashboardSettingsForm,
      BaseTable,
      PageLayout,
      JoinEntities,
      SearchInput,
      DsIconText,
      DsCheckbox,
    },
  })
  export default class UserDynamicDashboardSettings extends Vue {
    @Ref() readonly table?: BaseTable<DashboardPaneGroup>;

    manager: Nullable<ListManager<DashboardPaneGroup>> = null;
    refreshed = 0;

    titleize = titleize;

    get joinedDashboardPanesParams() {
      return { only: ['name', 'id', 'role_ids', 'active'], filters: { accessible: true } };
    }

    get dragDisabled() {
      return !!this.manager?.customFilters?.search;
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get accountStore() {
      return useAccountStore();
    }

    getManager(): ListManager<DashboardPaneGroup> {
      return new ListManager<DashboardPaneGroup>({
        fetchDataFunction: (params) => {
          const only = ['id', 'priority', 'user_priority', 'module_name_id', 'dashboard_pane_ids'] as OnlyOptions<DashboardPaneGroup>;

          return this.$api.getDashboardPaneGroups(
            {
              ...params,
              only,
              filters: { ...(params.filters || {}), accessible: true, with_accessible_panes: true },
            },
            { cache: true }
          );
        },
        afterFetch: () => (this.refreshed += 1),
        sortOrder: [{ direction: 'asc', field: 'user_priority', sortField: 'user_priority' }],
        per_page: -1,
        fields: [
          { title: '', name: 'sortable_handle' },
          { title: this.$t('tenant.admin.dashboard_pane_groups.priority'), name: 'priority' },
          { title: this.$t('tenant.admin.dashboard_pane_groups.type'), name: 'type' },
          { title: this.$t('tenant.admin.dashboard_pane_groups.module'), name: 'module_name' },
          { title: this.$t('tenant.admin.dashboard_pane_groups.display_name'), name: 'display_name' },
          { title: this.$t('tenant.admin.dashboard_pane_groups.panes_count'), name: 'panes_count' },
        ],
      });
    }

    beforeMount(): void {
      this.manager = this.getManager();
    }

    async saveIndexes() {
      if (!this.manager) return;

      await Promise.all([
        this.$api.updateDashboardPaneGroupsUserIndexes({ data: this.manager.items.map(({ id }, i) => ({ id, index: i + 1 })) }),
        ...[!this.currentUserStore.hasDynamicDashboardPersonalization ? this.setUserDynamicDashboardSettings() : []],
      ]);

      this.refresh();
    }

    async setUserDynamicDashboardSettings() {
      const dynamic_dashboard_settings = isEmpty(this.accountStore.data.dynamic_dashboard_settings)
        ? DEFAULT_DYNAMIC_DASHBOARD_SETTINGS
        : this.accountStore.data.dynamic_dashboard_settings;

      if (this.currentUserStore.data?.id) {
        await this.$api.updateTenantUser(this.currentUserStore.data.id, { dynamic_dashboard_settings });
        this.currentUserStore.$patch({ data: { ...this.currentUserStore.data, dynamic_dashboard_settings } });
      }
    }

    refresh() {
      this.$api.cache.clear();
      this.table?.debounceUpdate();
      reloadDynamicDashboard.next();
    }
  }
