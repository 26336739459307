
  import { useAccountStore } from '@app/stores/account';
  import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
  import type { Moment } from 'moment';
  import moment from 'moment';

  const DATE_TIME_PICKER_NAME = 'DateTimePicker';

  @Component({})
  export default class DateTimePicker extends Vue {
    @Model('input', { type: Date }) readonly value!: Date;
    @Prop(String) readonly format?: string;
    @Prop(String) readonly placeholder?: string;
    @Prop(Boolean) readonly autofocus?: boolean;
    @Prop(Date) readonly minDate?: Date;
    @Prop(Date) readonly maxDate?: Date;
    @Prop(Date) readonly defaultDate?: Date;
    @Prop(Boolean) readonly readonly?: boolean;
    @Prop(Boolean) readonly useCurrent?: boolean;
    @Prop(String) readonly timeZone?: string;
    @Prop(Array) readonly disabledHours?: number[];
    @Prop(Array) readonly enabledHours?: number[];
    @Prop(Number) readonly stepping?: number;
    @Prop(Boolean) readonly disabled?: boolean;
    @Prop(String) readonly viewMode?: string;

    get accountStore() {
      return useAccountStore();
    }

    mounted(): void {
      this.initTimePickerElement(true);
    }

    get tabindex(): number | undefined {
      return (this.readonly && -1) || undefined;
    }

    beforeDestroy(): void {
      this.destroyTimePickerElement();
    }

    dateTimeChanged(event: { date: Moment | false }): void {
      if (event.date) {
        this.$emit('input', event.date.seconds(0).toDate());
      } else {
        this.$emit('input', undefined);
      }
    }

    get valueFormatted(): string {
      if (!this.value) {
        return '';
      }
      return moment(this.value).format(this.format || this.accountStore.data.datetimepicker_datetime_format);
    }

    @Watch('minDate')
    changeMinDate(value: Maybe<Date>): void {
      this.updateLimit('minDate', value);
    }

    @Watch('maxDate')
    changeEndDate(value: Maybe<Date>): void {
      this.updateLimit('maxDate', value);
    }

    updateLimit(method: 'minDate' | 'maxDate', limit: Maybe<Date>): void {
      this.dateTimePickerElement()[method](limit || false);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dateTimePickerElement(): any {
      return $(this.$el).data(DATE_TIME_PICKER_NAME);
    }

    initTimePickerElement(keepInvalid = false): void {
      const $input = $(this.$el);
      const options = keepInvalid ? { ...this.options, keepInvalid: true } : { ...this.options };
      $input.datetimepicker(options);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      $input.on('dp.change', (event: any) => this.dateTimeChanged(event));
      keepInvalid && this.dateTimePickerElement().keepInvalid(false);
      if (this.autofocus) {
        $input.trigger('focus');
      }
    }

    destroyTimePickerElement(): void {
      $(this.$el).off();
      this.dateTimePickerElement().destroy();
    }

    get options(): object {
      return {
        minDate: this.minDate,
        maxDate: this.maxDate,
        useCurrent: this.useCurrent || false,
        format: this.format || this.accountStore.data.datetimepicker_datetime_format,
        timeZone: this.timeZone,
        disabledHours: this.disabledHours || false,
        enabledHours: this.enabledHours || false,
        stepping: this.stepping || 1,
        defaultDate: this.defaultDate,
        viewMode: this.viewMode || 'days',
      };
    }
  }
