
  import { Component, Emit } from 'vue-property-decorator';
  import { debounce } from 'lodash';
  import { filter } from 'rxjs';
  import type { SingleSelectFieldValue } from '@app/models/question-response-types';
  import { getRelatedModuleNameFromSubForm } from '@app/utils/get-related-module-name-from-sub-form';
  import type { ExpensableRecordBudget } from '@app/models/expensable-record-budget';
  import type { ExpensableExpensingTable } from '@app/models/expensable-expensing-table';
  import type { ModuleName } from '@app/models/module-name';
  import { ExpensingMethod, FieldType } from '@app/models/sub-form-question';
  import { ModuleType } from '@app/models/module-name';
  import type { OnlyOptions } from '@app/services/donesafe-api-utils';
  import { RE_FETCH_DEBOUNCE_TIME } from '@app/constants';

  import Select2 from '../select2.vue';

  import BaseField from './base-field';

  @Component({ components: { Select2 }, methods: { getRelatedModuleNameFromSubForm } })
  export default class ExpenseBudgetSelectField extends BaseField<FieldType.expense_budget_select> {
    localValue: SingleSelectFieldValue = {};
    loading = false;

    options: (ExpensableExpensingTable | undefined)[] = [];

    linkedMainRecordId?: number;
    expensableMethodSelectValue?: string;

    recordBudgetSource?: number;

    // give some time for expensableMethodSelectValue and linkedMainRecordId to initialized first
    debouncedTryGetOptions = debounce(this.tryGetOptions, RE_FETCH_DEBOUNCE_TIME);

    @Emit('input')
    sendBudgetUpdate(): SingleSelectFieldValue {
      this.sendUpdate(this.localValue);

      // let ExpenseBudgetCategorySelectField know
      this.formObservers.expensableBudgetSelectUpdate$.next({
        recordBudgetSource: this.recordBudgetSource,
        expensingTableName: this.localValue.value,
      });
      return this.localValue;
    }

    getOptions(crossModuleBudgetLookup: boolean, linkedMainRecordId: number): void {
      this.parsleyReset();
      this.loading = true;
      this.$api
        .getRecordBudgetSource({ record_id: linkedMainRecordId, cross_module_budget_lookup: crossModuleBudgetLookup }, { cache: true })
        .then(({ data }) => {
          const moduleRecordId = data;
          this.recordBudgetSource = moduleRecordId; // remember record budget source
          if (moduleRecordId) {
            const only: OnlyOptions<ExpensableRecordBudget> = [{ expensing_table: ['name'] }];
            // TODO: use getExpensableExpensingTables instead ?
            return this.$api
              .getExpensableRecordBudgets(
                {
                  filters: {
                    active: true,
                    ready: true,
                    budgetable_id: moduleRecordId,
                    budgetable_type: 'ModuleRecord',
                  },
                  per_page: -1,
                  only,
                },
                { cache: true }
              )
              .then(({ data }) => {
                this.options = data.map((expensingRecordBudget) => expensingRecordBudget.expensing_table);
                if (!this.options.length) {
                  this.clearValue();
                } else {
                  this.sendBudgetUpdate();
                }
              });
          } else {
            this.options = [];
            this.clearValue();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }

    clearValue(): void {
      this.updateValue('');
    }

    updateValue(value?: string) {
      if (value !== this.localValue.value) {
        this.localValue = { ...this.localValue, value };
        this.sendBudgetUpdate();
      }
    }

    tryGetOptions(): void {
      if (this.expensableMethodSelectValue && this.linkedMainRecordId) {
        this.getOptions(this.expensableMethodSelectValue === 'multi_level', this.linkedMainRecordId);
      }
    }

    initSubscription(): void {
      const only: OnlyOptions<ModuleName> = ['module_type'];
      this.completion?.sub_form_id &&
        getRelatedModuleNameFromSubForm(this.completion.sub_form_id, this.$api, only).then((moduleName) => {
          if (moduleName?.module_type !== ModuleType.transactor) {
            this.expensableMethodSelectValue = ExpensingMethod.single_level;
            this.debouncedTryGetOptions();
          }
        });
      this.addSubscription(
        this.formObservers.fieldUpdate$
          .pipe(filter(([question]) => question.code === ExpensingMethod.question_code))
          .subscribe(([, { value }]) => {
            this.expensableMethodSelectValue = value;
            this.debouncedTryGetOptions();
          })
      );
      this.addSubscription(
        this.formObservers.fieldUpdate$
          .pipe(filter(([question]) => question.field_type === FieldType.main_form_relation && question.config.expensable_mode === 'true'))
          .subscribe(([, { value }]) => {
            // ignore blank broadcast due to multiple expense_budget_select in the form
            if (value) {
              this.linkedMainRecordId = value;
              this.debouncedTryGetOptions();
            }
          })
      );
      this.addSubscription(
        this.formObservers.clearExpensableMainFormRelation$.subscribe(() => {
          this.updateValue('');
        })
      );
    }

    beforeMount(): void {
      this.localValue = this.value || {};
    }

    mounted(): void {
      if (this.defaultTemplating) {
        this.loading = true;
        this.$api
          .getExpensableExpensingTables(
            {
              only: ['name'],
              filters: {
                module_budgets: { active: true, module_name_id: this.record?.module_name_id },
              },
              per_page: -1,
            },
            { cache: true }
          )
          .then(({ data }) => {
            this.options = data;
            this.loading = false;
          });
      } else {
        this.initSubscription();
        this.$nextTick(() => this.formObservers.requestExpensableMainFormRelationBroadcast$.next());
      }
    }
  }
