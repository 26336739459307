
  import type { BaseEntity } from '@app/models/base-entity';
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import type { ListManager } from '@app/services/list-manager/list-manager';

  @Component({ components: {} })
  export default class BaseTableFieldCheckbox<T extends BaseEntity<number | string>> extends Vue {
    @Prop({ type: Object, default: () => null }) readonly rowData!: Nullable<T>;
    @Prop({ type: Number }) readonly rowIndex!: number;
    @Prop({ type: Object }) readonly rowField!: any;
    @Prop({ type: Boolean, default: false }) readonly isHeader!: boolean;
    @Prop({ type: Object }) readonly manager!: ListManager<T>;

    indeterminate = false;
    checked = false;

    @Watch('manager.items')
    onPageUpdate(items: T[]): void {
      this.isAllItemsInCurrentPageSelected();
    }

    @Watch('manager.selectedTo')
    onSelectedToUpdate(selectedTo: number[]): void {
      this.isAllItemsInCurrentPageSelected();
    }

    get trackBy() {
      return this.manager.trackBy;
    }

    get perPageToCompare() {
      return this.manager.pagination.last_page === this.manager.pagination.current_page
        ? this.manager.pagination.total % this.manager.pagination.per_page
        : this.manager.pagination.per_page;
    }

    toggleCheckbox(dataItem: T, event: InputEvent & { target: HTMLInputElement }) {
      this.manager.toggleSelectedTo(dataItem[this.trackBy] as number);
    }

    toggleAllCheckbox(event: InputEvent & { target: HTMLInputElement }) {
      this.manager.togglePageSelectedAll(event.target.checked);
    }

    isSelected(rowData: T) {
      return this.manager.isItemSelected(rowData[this.trackBy] as number);
    }

    isAllItemsInCurrentPageSelected() {
      if (!this.manager.items.length || !this.isHeader) return;

      this.$nextTick(() => {
        const selected = this.manager.items.filter((item: T) => this.manager.selectedTo.includes(item[this.trackBy] as number));

        // count == 0, clear the checkbox
        if (selected.length <= 0) {
          this.indeterminate = false;
          this.checked = false;
        }
        // count > 0 and count < perPage, set checkbox state to 'indeterminate'
        else if (selected.length < Math.min(this.perPageToCompare, this.manager.total)) {
          this.indeterminate = true;
          this.checked = true;
        }
        // count == perPage, set checkbox state to 'checked'
        else {
          this.indeterminate = false;
          this.checked = true;
        }
      });
    }
  }
