import type { ModuleRecordExtraParameters } from '@app/services/api/module-records-api';
import type { PartialBy } from '@app/utils/types/partial';
import type { AxiosResponse } from 'axios';
import { SUB_FORM_COMPLETION_SHOW_ONLY } from '@app/components/sub-form-completion/utils';
import type { SubFormList } from '@app/models/sub-form-list';
import type { WizardOptionsItem, WizardShowLinkOptions } from '@app/models/module-name';
import type { ModuleRecord } from '@app/models/module-record';
import type { FirstLevelKeyLiterals } from '@app/utils/types/first-level-literals';
import { Tuple } from '@app/utils/types/tuple';
import { valueInResponse } from '@app/utils/value-in-response';
import { changeLocation } from '@app/utils/change-location';

export interface ModuleRecordSubmitParams extends ModuleRecordExtraParameters {
  event?: MouseEvent;
  form_uuid?: string;
  inPlaceUpdate?: boolean;
  relationships?: Record<string, number | string>;
}

export const SFC_FORM_RECORD_ONLY = Tuple([
  'id',
  'title',
  'module_name_id',
  'location_id',
  'organization_id',
  'user_id',
  'uniq_id',
  {
    module_name: ['id', 'name', 'display', 'sub_form_id', 'start_workflow', 'workflows', 'show_options', 'feature_set'],
    sub_form_completion: [
      'id',
      {
        sub_form_responses: [
          'id',
          'sub_form_completion_id',
          'sub_form_question_field_type',
          'sub_form_question_id',
          'print_value',
          'response',
          {
            sub_form_question: ['index', 'question'],
          },
          'sub_form_question_system_code',
          'sub_form_question_code',
        ],
      },
      'sub_form_id',
      'updated_at',
    ],
  },
  'permissions',
  'workflow_id',
  'workflow',
] as const);
export type SfcFormRecordOnly = FirstLevelKeyLiterals<(typeof SFC_FORM_RECORD_ONLY)[number]>;

export type CustomPageOptions = {
  custom_link?: WizardOptionsItem['custom_link'] | false;
  description?: string;
  icon: string;
  text: string;
} & WizardShowLinkOptions;

export type ModalModes = 'module-record-edit' | 'module-record-show' | 'sub-form-completion-show';
export interface ModalListeners {
  'record-created'?: (record: ModuleRecord) => void;
}

export const SUB_FORM_LIST_ONLY = Tuple([
  'id',
  'sub_form_list_type',
  'sub_form_ids',
  'auto_save',
  'title',
  'order_options',
  'add_icon',
  'multiple',
  'draft',
  'draft_after_complete',
] as const);
export type SubFormListOnly = (typeof SUB_FORM_LIST_ONLY)[number];

export const FORM_COMPONENT_ONLY = Tuple(['id', 'can_skip', 'component_type', 'component_id', 'complete_multiple', 'order'] as const);
export type FormComponentOnly = (typeof FORM_COMPONENT_ONLY)[number];

export const extractHybridSelectedData = (
  moduleRecord: PartialBy<Pick<ModuleRecord, 'sub_form_completion' | 'module_name'>, 'sub_form_completion'>,
  subFormLists: Pick<SubFormList, SubFormListOnly>[]
): {
  subFormId: Maybe<string | number>;
  subFormList: Maybe<Pick<SubFormList, SubFormListOnly>>;
} => {
  const auditOrCourseResponse = moduleRecord.sub_form_completion?.sub_form_responses.find(
    (response) => moduleRecord.module_name?.hybrid_question_code === response.sub_form_question_code
  );

  const subFormId = valueInResponse(auditOrCourseResponse?.response);

  return {
    subFormId: subFormId as string | number,
    subFormList: subFormLists.find((subFormList) => subFormList.sub_form_ids?.some((id) => `${id}` === subFormId)),
  };
};

export const moduleRecordErrorHandler = (response: AxiosResponse, recordId: string | number): void => {
  if (response?.status === 403) {
    changeLocation(`/module_records/${recordId}`);
  }
};

export const WIZARD_RECORD_ONLY = Tuple([
  'id',
  'title',
  'module_name_id',
  'module_name',
  'workflow',
  'workflow_id',
  'permissions',
  'location_id',
  {
    sub_form_completion: SUB_FORM_COMPLETION_SHOW_ONLY,
    sub_form_completions: [...SUB_FORM_COMPLETION_SHOW_ONLY, 'sub_form_list_id', 'title', 'stage'],
  },
] as const);

export type WizardRecordOnly = FirstLevelKeyLiterals<(typeof WIZARD_RECORD_ONLY)[number]>;

export const MODULE_RECORD_SHOW_ONLY = Tuple([
  'id',
  'organization_id',
  'location_id',
  'user_id',
  'workflow_id',
  'calculations',
  'module_name_id',
  {
    sub_form_completion: [
      {
        sub_form_responses: [
          'id',
          'sub_form_question_id',
          'sub_form_question_code',
          'sub_form_question_field_type',
          'print_value',
          'response',
        ],
      },
    ],
  },
  { record_indicators: ['indicator_id'] },
  { user_involvements: ['involvement_id', { user: ['full_name'] }] },
] as const);

export const RECORD_DETAILS_MODULE_ONLY = Tuple([
  'id',
  'record_calculations',
  'show_options',
  { involvements: ['active', 'hide_empty', 'id', 'name'] },
  {
    indicator_sets: ['active', 'id', 'multi', 'name', { indicators: ['color', 'description', 'id', 'name'] }],
  },
  {
    main_form: [
      {
        sub_form_sections: [
          {
            sub_form_questions: [
              'id',
              'field_type',
              'config',
              'index',
              'question',
              'active',
              'options',
              'short_title',
              'title',
              { attachments: ['url'] },
            ],
          },
        ],
      },
    ],
  },
] as const);

interface ModuleRecordPageQueryParams {
  modal_view?: 'true';
}

interface ModuleRecordNewPageQueryParams extends ModuleRecordPageQueryParams {
  default_template_id?: string;
  main_form_id?: string;
  module_name_id?: string;
  relationships?: Record<string, string>;
}

interface ModuleRecordEditPageQueryParams extends ModuleRecordPageQueryParams {
  go_to_wizard_after_complete?: 'true';
}
