
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import isMobile from 'ismobilejs';

  @Component({})
  export default class ScormCourseCompletion extends Vue {
    @Prop(String) readonly scormCourseId!: number;
    @Prop(String) readonly registrationId!: string;

    launchUrl = '';
    showLoading = false;
    errorMessage = '';

    beforeMount(): void {
      this.fetchLaunchUrl();
    }

    fetchLaunchUrl(): void {
      if (!this.scormCourseId) {
        this.errorMessage = this.$t('tenant.sub_form_completions.form_fields.edit.scored_scorm_field.course_does_not_exist').toString();
        return;
      }

      this.showLoading = true;
      this.$api
        .performScormCloudRequest({
          action: 'launch_url',
          scorm_course_id: this.scormCourseId,
          registration_id: this.registrationId,
        })
        .then(({ data }) => {
          this.launchUrl = data.result;
          this.errorMessage = '';
          this.showLoading = false;
        })
        .catch(({ data }) => {
          this.errorMessage = data.error;
          this.showLoading = false;
        });
    }

    fullScreen(): void {
      const scormFrame = this.$refs.scormFrame as any; // eslint-disable-line @typescript-eslint/no-explicit-any
      const requestFullScreen =
        scormFrame.requestFullscreen ||
        scormFrame.msRequestFullscreen ||
        scormFrame.mozRequestFullScreen ||
        scormFrame.webkitRequestFullscreen;
      requestFullScreen.call(scormFrame);
    }

    get isMobile(): boolean {
      return isMobile(window.navigator).any;
    }
  }
