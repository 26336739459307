
  import { useAccountStore } from '@app/stores/account';
  import { versionTime } from '@app/components/paper-trails/utils';
  import { eventName, recordName } from '../mapping-utils';
  import type { DetailsServiceResponse } from '../services/base-service';
  import PaperTrailsAttributes from './paper-trails-attributes.vue';
  import TextHighlight from 'vue-text-highlight';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import PaperTrailsDetails from './paper-trails-details.vue';
  import PaperTrailsUser from './paper-trails-user.vue';
  import type { AttributeValues, DetailsLink, VersionTime } from '../models';
  import type { PaperTrailsFilters, PaperTrailVersion } from '@app/models/paper-trail-version';

  @Component({ components: { PaperTrailsAttributes, TextHighlight, PaperTrailsDetails, PaperTrailsUser } })
  export default class PaperTrailsItem extends Vue {
    @Prop(Object) readonly version!: PaperTrailVersion;
    @Prop(Object) readonly customFilters!: PaperTrailsFilters;
    @Prop(Object) readonly attributeValues!: AttributeValues;
    @Prop(Object) readonly userLink!: DetailsLink;
    @Prop(Object) readonly details?: DetailsServiceResponse;

    get accountStore() {
      return useAccountStore();
    }

    get searchString(): string {
      return this.customFilters.search;
    }

    get queries(): string[] {
      return this.searchString ? [this.searchString] : [];
    }

    get createdAt(): VersionTime {
      return versionTime(this.accountStore.data, this.version.created_at);
    }

    get event(): string {
      return eventName(this.version, this.$t);
    }

    get recordName(): string {
      return recordName(this.version, this.$t);
    }
  }
