
  import { Component, Model, Prop, Ref, Vue } from 'vue-property-decorator';
  import type { LocationTag } from '@app/models/location-tag';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';

  import EntitySelector from '../entity-selector.vue';

  @Component({ components: { EntitySelector } })
  export default class LocationTagSelector extends Vue {
    @Prop(Boolean) multiple?: boolean;
    @Prop(Boolean) allowClear?: boolean;
    @Prop(Boolean) readonly?: boolean;
    @Prop(Object) filters?: DonesafeFilterOptions<LocationTag>;
    @Prop(Object) requiredFilters?: DonesafeFilterOptions<Location>;
    @Prop(String) readonly inputId?: string;
    @Model('input') value!: number | string;
    @Ref() readonly selector?: EntitySelector<LocationTag>;

    only: (keyof LocationTag)[] = ['id', 'name', 'allow_global'];

    toggle(value: boolean): void {
      this.selector?.toggle(value);
    }

    setOptions(options: LocationTag[] = []): void {
      this.selector?.setOptions(options);
    }
  }
