
  import { useAccountStore } from '@app/stores/account';
  import { isSupported, openDefaultEditor } from '@pqina/pintura';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { Attachment } from '@app/models/attachment';
  import { AvScanStatus } from '@app/models/attachment';

  import ThumbnailWrapper from './thumbnail-wrapper.vue';

  @Component({ components: { ThumbnailWrapper } })
  export default class AttachmentItem extends Vue {
    @Prop(Object) attachment!: Pick<
      Attachment,
      | 'id'
      | 'is_variable'
      | 'is_image'
      | 'user'
      | 'extension'
      | 'file_name'
      | 'url'
      | 'web_thumbnail_url'
      | 'created_at'
      | 'metadata'
      | 'is_previewable'
    > & { blob?: Blob };
    @Prop(Boolean) canEdit?: boolean;
    @Prop(Boolean) allowImageEditing?: boolean;
    @Prop(Boolean) small!: boolean;

    get editButtonClass(): string {
      return 'fa-pencil';
    }

    get userName(): string {
      return this.attachment.user?.full_name || 'unknown';
    }

    get showLink(): boolean {
      return !this.isInfected && !!this.attachment.url;
    }

    get isInfected(): boolean {
      return this.attachment.metadata.av_scan_status === AvScanStatus.virus_detected;
    }

    get fileName(): string {
      return this.attachment.file_name || (this.$t('app.labels.na') as string);
    }

    get isIE(): boolean {
      const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
      const msie = ua.indexOf('MSIE '); // IE 10 or older
      const trident = ua.indexOf('Trident/'); //IE 11

      return msie > 0 || trident > 0;
    }

    get showEditButton(): boolean {
      return !this.isIE && isSupported() && !!this.allowImageEditing && !!this.canEdit && this.attachment.is_image;
    }

    get accountStore() {
      return useAccountStore();
    }

    editImage(): void {
      const src = this.attachment.blob || this.attachment.url;
      const editor = openDefaultEditor({ src });
      editor.on('process', ({ dest }: { dest: File }) => {
        const editedFile = new File([dest], this.fileName, { type: dest.type });
        this.$emit('edit', editedFile);
      });
      editor.show();
    }

    deleteLabel(name: string) {
      return this.$t('app.labels.delete_name', { name });
    }

    editLabel(name: string) {
      return this.$t('app.labels.edit_name', { name });
    }
  }
