
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { useAccountStore } from '@app/stores/account';
  import { Component, Prop, Ref, Vue, Emit } from 'vue-property-decorator';
  import FileUploader from '../file-uploader/file-uploader.vue';
  import type { Blob as ActiveStorageBlob } from '@rails/activestorage';
  import AttachmentItem from './attachment-item.vue';
  import { includes } from 'lodash';
  import type { FilePondFile } from 'filepond';
  import type { Attachment } from '@app/models/attachment';
  import type { TenantUser } from '@app/models/tenant-user';

  type CustomBlob = ActiveStorageBlob & { blob: Blob; metadata: object };

  @Component({
    components: { AttachmentItem, FileUploader },
  })
  export default class AttachmentsUploader extends Vue {
    @Prop(Boolean) readonly multiple!: boolean;
    @Prop(String) readonly fieldId!: string;
    @Prop(String) readonly accept!: string;
    @Prop(String) readonly toggleOn!: string;
    @Prop(String) readonly fieldName!: string;
    @Prop(Boolean) readonly hideButton!: boolean;
    @Prop(Boolean) readonly small!: boolean;
    @Prop(Boolean) readonly imageEditing?: boolean;
    @Prop(String) readonly appendInputsTo!: string;
    @Prop(Array) readonly paramFiles!: Partial<Attachment>[];
    @Prop(Boolean) readonly required?: boolean;
    @Prop(String) readonly name?: string;
    @Prop(Boolean) readonly allowFileTypeValidation?: boolean;
    @Prop(String) readonly attachmentItemClass!: string;
    @Ref() readonly uploader!: FileUploader;

    files: Partial<Attachment>[] = this.paramFiles || [];

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get accountStore() {
      return useAccountStore();
    }

    get userFullName(): string {
      return this.currentUserStore.data?.full_name || (this.$t('app.labels.guest') as string);
    }

    get toggleOnEventKey(): string {
      return this.toggleOn && `click.file-uploader${btoa(this.toggleOn)}`;
    }

    get appendElement(): JQuery {
      return $(this.appendInputsTo);
    }

    get fieldElement(): JQuery {
      return $(`#${this.fieldId}`);
    }

    get rawFieldElement(): JQuery {
      return $(`#raw_${this.fieldId}`);
    }

    get allowImageEditing(): boolean {
      return !!this.imageEditing && !!this.accountStore.allowImageEditing;
    }

    @Emit('blob-create')
    onUpload(blob: CustomBlob): CustomBlob {
      const urlCreator = window.URL || window.webkitURL;
      this.files = this.files.concat({
        id: blob.signed_id as unknown as number,
        user: { full_name: this.userFullName } as TenantUser,
        file_name: blob.filename,
        url: urlCreator?.createObjectURL(blob.blob),
        is_image: blob.content_type.startsWith('image'),
        metadata: blob.metadata,
      } as never as Attachment);

      this.addFieldValue(blob.signed_id);

      return blob;
    }

    @Emit('blob-delete')
    onDelete(attachment: Partial<Attachment>): Partial<Attachment> {
      this.files = this.files.filter((f) => f.id !== attachment.id);
      this.removeFieldValue(attachment.id as number);

      return attachment;
    }

    reset(): void {
      this.files = [];
    }

    onEdit(pseudoAttachment: Attachment, newBlob: Blob): void {
      this.uploader.addFile(newBlob)?.then(() => {
        this.onDelete(pseudoAttachment);
      });
    }

    getNewFileInput(value: string): JQuery {
      const attributes = {
        type: 'text',
        class: 'hidden',
        name: this.getAttachmentInputName(),
        value,
      };
      return $('<input/>', attributes);
    }

    getAttachmentInputName(): string {
      return `${this.fieldName}[]`;
    }

    mounted(): void {
      if (this.toggleOn) {
        $(document)
          .off(this.toggleOnEventKey)
          .on(this.toggleOnEventKey, this.toggleOn, () => this.uploader?.openBrowser());
      }

      if (this.paramFiles?.length) {
        this.fieldElement.val(JSON.stringify(this.files.map((f) => f.id))).trigger('change');
      }
    }

    beforeDestroy(): void {
      this.toggleOnEventKey && $(document).off(this.toggleOnEventKey, this.toggleOn);
    }

    getFieldValue(): string[] {
      try {
        return JSON.parse(`${this.fieldElement.val()}`) || [];
      } catch (e) {
        return [];
      }
    }

    addFile(blob: Blob): Promise<FilePondFile> | undefined {
      return this.uploader.addFile(blob);
    }

    addFieldValue(signedId: string): void {
      // do not add duplicate
      if (includes(this.getFieldValue(), signedId)) {
        return;
      }

      const value = this.multiple ? this.getFieldValue().concat(signedId) : [signedId];

      if (this.fieldId) {
        this.fieldElement.val(JSON.stringify(value)).trigger('change');
      } else if (this.fieldName && this.appendInputsTo) {
        this.appendElement.append(this.getNewFileInput(signedId));
      }

      this.rawFieldElement.val(JSON.stringify(this.files));
      this.$emit('input', value);
    }

    removeFieldValue(signedId: string | number): void {
      const value = this.getFieldValue().filter((v) => v != signedId);

      if (this.fieldId) {
        this.fieldElement.val(JSON.stringify(value)).trigger('change');
      } else if (this.fieldName && this.appendInputsTo) {
        this.appendElement.find(`[name='${this.getAttachmentInputName()}'][value='${signedId}']`).remove();
      }

      this.rawFieldElement.val(JSON.stringify(this.files));
      this.$emit('input', value);
    }
  }
