
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { Alert } from 'uiv';

  @Component({
    components: { Alert },
  })
  export default class AutoSelectWarnings extends Vue {
    @Prop(Object) readonly warnings!: Record<string, string>;
  }
