
  import { Component, Prop } from 'vue-property-decorator';
  import BaseQuestionResponse from './base-question-response';
  import TextHighlight from 'vue-text-highlight';
  import { userEditLink } from '../../utils';
  import type { TenantUser } from '@app/models/tenant-user';

  @Component({ components: { TextHighlight } })
  export default class MultiRecordFieldResponse extends BaseQuestionResponse {
    @Prop(Array) readonly users?: TenantUser[];
    @Prop(Array) readonly usersText?: string[];

    userEditLink(user?: TenantUser): Maybe<string> {
      return user && userEditLink(user, this.currentUserStore.data);
    }
  }
