import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { DefaultTemplate } from '@app/models/default-template';

export class DefaultTemplateService extends BaseService<DefaultTemplate> {
  async fetchDetails({ itemId }: ServiceRequest<DefaultTemplate>): Promise<DetailsServiceResponse> {
    const { data: defaultTemplate } = await this.api.getDefaultTemplate(itemId, { only: ['id', 'name'] }, { join: true, cache: true });
    return {
      links: [
        {
          link: `/admin/settings/default_templates/${defaultTemplate.id}/edit`,
          prefix: this.t('app.labels.template'),
          title: defaultTemplate.name,
          id: defaultTemplate.id,
        },
      ],
    };
  }
}
