import { Component, Vue } from 'vue-property-decorator';
import type { ListManagerField, ListManagerSortItem } from '@app/services/list-manager/types';

@Component
export default class SortingHelper extends Vue {
  sortClass(field: ListManagerField, sortOrder: ListManagerSortItem[], css: Record<string, string>): string {
    let cls = '';
    const i = this.currentSortOrderPosition(field, sortOrder);

    if (i !== false) {
      cls = sortOrder[i].direction === 'asc' ? css.ascendingClass : css.descendingClass;
    }

    return cls;
  }

  currentSortOrderPosition(field: ListManagerField, sortOrder: ListManagerSortItem[]): false | number {
    if (!this.isFieldSortable(field)) {
      return false;
    }

    for (let i = 0; i < sortOrder.length; i++) {
      if (this.fieldIsInSortOrderPosition(field, i, sortOrder)) {
        return i;
      }
    }

    return false;
  }

  fieldIsInSortOrderPosition(field: ListManagerField, i: number, sortOrder?: ListManagerSortItem[]): boolean {
    return sortOrder?.[i]?.field === field.name && sortOrder?.[i]?.sortField === field?.sortField;
  }

  showSortableIcon(field: ListManagerField, sortOrder: ListManagerSortItem[], css: Record<string, string>): boolean {
    return this.isInCurrentSortGroup(field, sortOrder) || this.hasSortableIcon(field, css);
  }

  isInCurrentSortGroup(field: ListManagerField, sortOrder: ListManagerSortItem[]): boolean {
    return this.currentSortOrderPosition(field, sortOrder) !== false;
  }

  hasSortableIcon(field: ListManagerField, css: Record<string, string>): boolean {
    return this.isFieldSortable(field) && !!css.sortableIcon;
  }

  getSortableIcon(field: ListManagerField, sortOrder: ListManagerSortItem[], css: Record<string, string>, showSortIcons: boolean): string {
    const style = `opacity:${this.sortIconOpacity(field, sortOrder)};`;
    return showSortIcons ? this.renderIconTag(['sort-icon', this.sortIcon(field, sortOrder, css)], `style="${style}"`, css) : '';
  }

  sortIcon(field: ListManagerField, sortOrder: ListManagerSortItem[], css: Record<string, string>): string {
    let cls = css.sortableIcon;
    const i = this.currentSortOrderPosition(field, sortOrder);

    if (i !== false) {
      cls = sortOrder[i].direction === 'asc' ? css.ascendingIcon : css.descendingIcon;
    }

    return cls;
  }

  sortIconOpacity(field: ListManagerField, sortOrder: ListManagerSortItem[]): number {
    /*
     * fields with stronger precedence have darker color
     *
     * if there are few fields, we go down by 0.3
     * ex. 2 fields are selected: 1.0, 0.7
     *
     * if there are more we go down evenly on the given spectrum
     * ex. 6 fields are selected: 1.0, 0.86, 0.72, 0.58, 0.44, 0.3
     */
    const max = 1.0,
      min = 0.3;
    let step = 0.3;

    const count = sortOrder.length;
    const current = this.currentSortOrderPosition(field, sortOrder) || 0;

    if (max - count * step < min) {
      step = (max - min) / (count - 1);
    }

    return max - current * step;
  }

  renderIconTag(
    classes: string[],
    options = '',
    css: { renderIcon: (classes: string[], options: string) => string } | Record<string, string>
  ): string {
    return typeof css.renderIcon === 'function' ? css.renderIcon(classes, options) : `<i class="${classes.join(' ')}" ${options}/>`;
  }

  isFieldSortable(field: ListManagerField): boolean {
    return !(typeof field?.sortField === 'undefined' || field?.sortField === null);
  }
}
