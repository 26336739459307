import type { BaseEntity } from '@app/models/base-entity';
import type { ListManager } from '@app/services/list-manager/list-manager';
import type { ListManagerStatic } from '@app/services/list-manager/list-manager-static';
import type { ListManagerField } from '@app/services/list-manager/types';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export class SimpleGridTableElement<T extends BaseEntity<number | string>> extends Vue {
  @Prop(Object) manager!: ListManager<T> | ListManagerStatic<T>;
  @Prop(String) readonly scrollName?: string;
  @Prop(Object) readonly gridWrapperCss?: Record<string, string>;

  get trStyles() {
    return this.gridWrapperCss;
  }

  get trAttributes() {
    return {
      ...(this.scrollName && { name: this.scrollName, class: { syncscroll: true } }),
    };
  }

  get allowFilters(): boolean {
    return this.manager.allowFilters;
  }

  get fields(): ListManagerField<T>[] {
    return this.manager.visibleFields;
  }

  get sortOrder() {
    return this.manager.sortOrder;
  }
  get tableData() {
    return this.manager?.items || [];
  }
}
