
  import SvgInteractiveTemplate from '@app/components/svg-template/svg-interactive-template.vue';
  import type { SvgSelectorFieldValue } from '@app/models/question-response-types';
  import { Component, Prop } from 'vue-property-decorator';
  import TextHighlight from 'vue-text-highlight';
  import BaseQuestionResponse from './base-question-response';
  import type { SvgLibraryTemplate } from '@app/models/svg-library/template';
  import type { SvgSelectorQuestionOptions } from '@app/models/question-options/svg-selector-question-config';

  @Component({ components: { SvgInteractiveTemplate, TextHighlight } })
  export default class SvgSelectorFieldResponse extends BaseQuestionResponse<SvgSelectorQuestionOptions> {
    @Prop(Array) readonly fieldValue?: SvgSelectorFieldValue;

    svgContent = '';
    template: Nullable<SvgLibraryTemplate> = null;

    beforeMount(): void {
      const id = this.question.config.svg_template_id;
      this.$api.getSvgTemplate(id, { include: ['components'] }, { cache: true, join: true }).then(({ data }) => {
        this.template = data;
      });
      this.$api.getSvgTemplateFile(id, { cache: true }).then(({ data }) => {
        this.svgContent = data;
      });
    }

    get componentColors(): Record<string, string> {
      const { config } = this.question;

      return (this.fieldValue || []).reduce<Record<string, string>>((memo, response) => {
        const components = this.template?.components?.filter((c) => c.code === response.value);
        const color = config.categories.find((r) => r.code === response.category)?.color || config.categories[0]?.color;
        const result = components?.reduce((m, c) => ({ ...m, [c.path]: color }), {});

        return { ...memo, ...result };
      }, {});
    }
  }
