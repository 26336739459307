import type { ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { LocationTag } from '@app/models/location-tag';

export class LocationTagService extends BaseService<LocationTag> {
  fetchEntity(itemId: ServiceRequest<LocationTag>): Promise<Partial<LocationTag>> {
    return this.api.getLocationTag(itemId.itemId, { only: ['id', 'name'] }, { join: true, cache: true }).then(({ data }) => data);
  }

  entityToText({}: ServiceRequest, entity?: LocationTag): string {
    return entity ? `${decodeURI(entity.name)} [${entity.id}]` : '';
  }
}
