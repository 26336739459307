
  import { Component } from 'vue-property-decorator';
  import UserDetails from '@app/mixins/user-details';
  import { mixins } from 'vue-class-component';
  import UserChangePasswordButton from '@app/components/admin/users/user-change-password-button.vue';
  import UserPrimarySignature from '@app/components/admin/users/user-primary-signature.vue';
  import UserPrimaryLanguage from '@app/components/admin/users/user-primary-language.vue';

  @Component({
    components: {
      UserChangePasswordButton,
      UserPrimarySignature,
      UserPrimaryLanguage,
    },
  })
  export default class UserPreferencesTab extends mixins(UserDetails) {
    get showLanguageSelect(): boolean {
      return (this.accountStore.data.all_languages || []).length > 1;
    }

    beforeMount(): void {
      if (this.userId) {
        this.$api.getTenantUser(this.userId, { include: 'can_be_changed_password' }, { cache: true }).then(({ data }) => {
          this.user = data;
        });
      }
    }
  }
