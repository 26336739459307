import type { GridStackWidget } from 'gridstack';
import type { BaseEntity } from './base-entity';
import type { ModuleName } from './module-name';
import type { Widget } from './widget';

export enum DashboardPaneSize {
  half = '6x6',
  standard = 'standard',
}

export default interface DashboardPane extends BaseEntity {
  active: boolean;
  config: GridStackWidget[];
  dashboard_pane_group_id?: number;
  description?: string;
  module_name?: ModuleName;
  module_name_id?: number;
  name: string;
  priority: number;
  role_ids?: number[];
  role_profile_ids?: number[];
  size: DashboardPaneSize;
  widgets?: Widget[];
}
