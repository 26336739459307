
  import { Component, Emit } from 'vue-property-decorator';
  import type { ScormFieldValue } from '@app/models/question-response-types';
  import type { FieldType } from '@app/models/sub-form-question';

  import ScormCourseCompletion from '../scorm-course/scorm-course-completion.vue';

  import BaseField from './base-field';

  @Component({ components: { ScormCourseCompletion } })
  export default class ScormField extends BaseField<FieldType.scorm> {
    localValue: ScormFieldValue = {
      state: 'UNKNOWN',
      value: '0', // maybe change to '' ?
    };
    errorMessage = '';
    showLoading = false;
    scormCourseExists = true;
    showScormCourseCompletion = false;

    get registrationCompletion(): string {
      return JSON.stringify([this.localValue.registration_id || '0', this.scormCourseId.toString(), this.question.required]);
    }

    get description(): string {
      switch (this.localValue.state) {
        case 'PASSED':
          return this.$t('tenant.sub_form_completions.form_fields.edit.scored_scorm_field.course_completed').toString();
        case 'UNKNOWN':
          return '';
        case 'FAILED':
          return this.$t('tenant.sub_form_completions.form_fields.edit.scored_scorm_field.course_completed').toString();
        default:
          return this.$t('tenant.sub_form_completions.form_fields.edit.scored_scorm_field.in_progress').toString();
      }
    }

    get buttonLabel(): string {
      switch (this.localValue.state) {
        case 'PASSED':
          return this.$t('tenant.sub_form_completions.form_fields.edit.scored_scorm_field.course_completed_button').toString();
        case 'UNKNOWN':
          return this.$t('tenant.sub_form_completions.form_fields.edit.scored_scorm_field.not_started_button').toString();
        case 'FAILED':
          return this.$t('tenant.sub_form_completions.form_fields.edit.scored_scorm_field.course_completed_button').toString();
        default:
          return this.$t('tenant.sub_form_completions.form_fields.edit.scored_scorm_field.in_progress_button').toString();
      }
    }

    get scormCourseId(): number {
      return parseInt(this.question.config.scorm_course_id, 10);
    }

    @Emit('input')
    updateValue(value: ScormFieldValue): ScormFieldValue {
      this.localValue = { ...value };
      return this.localValue;
    }
    beforeMount(): void {
      if (this.value?.registration_id) {
        this.localValue = { ...this.localValue, ...this.value };
        this.fetchScormRegistration();
      }
    }
    launchCourse(): void {
      if (this.localValue.registration_id) {
        this.showScormCourseCompletion = true;
      } else {
        this.createScormRegistration();
      }
    }

    async fetchScormRegistration(): Promise<void> {
      this.showLoading = true;
      this.$api
        .getScormRegistrations({
          filters: {
            registration_id: this.localValue.registration_id,
            scorm_course_id: this.scormCourseId,
          },
        })
        .then((result) => {
          if (!result.data.length) {
            return this.$api
              .performScormCloudRequest({
                action: 'registration_results',
                scorm_course_id: this.scormCourseId,
                registration_id: this.localValue.registration_id,
              })
              .then(({ data }) => {
                if (data.result.registrationCompletion === 'COMPLETED') {
                  this.updateValue({ ...this.localValue, state: data.result.registrationSuccess });
                } else {
                  this.updateValue({ ...this.localValue, state: data.result.registrationCompletion });
                }
                this.errorMessage = '';
              })
              .catch(({ data }) => {
                console.warn(data?.error);
                this.updateValue({ ...this.localValue, registration_id: undefined, state: 'UNKNOWN' });
              });
          }
        })
        .catch(({ data }) => {
          this.errorMessage = data?.error;
        })
        .finally(() => {
          this.showLoading = false;
          this.sendUpdate(this.localValue);
        });
    }

    async createScormRegistration(): Promise<void> {
      this.showLoading = true;
      this.$api
        .performScormCloudRequest({
          action: 'create_registration',
          scorm_course_id: this.scormCourseId,
        })
        .then(({ data }) => {
          this.updateValue({ state: 'UNKNOWN', value: '0', registration_id: data.registration_id });
          this.errorMessage = '';
        })
        .catch(({ data }) => {
          this.errorMessage = data?.error;
          this.scormCourseExists = !data?.error.includes('code 404');
        })
        .finally(() => {
          this.showLoading = false;
          this.showScormCourseCompletion = true;
          this.sendUpdate(this.localValue);
        });
    }
  }
