
  import { Component, Emit } from 'vue-property-decorator';
  import type { SchemeFieldValue } from '@app/models/question-response-types';
  import type { FieldType } from '@app/models/sub-form-question';
  import type { Scheme } from '@app/models/scheme';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';

  import EntitySelector from '../entity-selector.vue';

  import BaseField from './base-field';

  @Component({ components: { EntitySelector } })
  export default class SchemeField extends BaseField<FieldType.scheme> {
    localValue: SchemeFieldValue = { value: '' };

    get filters(): DonesafeFilterOptions<Scheme> {
      return { active: true };
    }

    @Emit('input')
    updateValue(value?: string): SchemeFieldValue {
      this.localValue = { value };
      this.sendUpdate(this.localValue);
      return this.localValue;
    }

    beforeMount(): void {
      const value = this.value?.value || '';
      this.localValue = { value };
    }
  }
