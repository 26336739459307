
  import { Component } from 'vue-property-decorator';
  import BaseEditableColumn from './base-editable-column';
  import type { TableCalculationColumnText } from '@app/models/question-options/table-calculation-question-options';

  @Component({})
  export default class TextEditableColumn extends BaseEditableColumn<TableCalculationColumnText> {
    get textPlaceholder(): string {
      return this.bulkEdit ? 'Type to bulk edit text value' : this.column?.placeholder || '';
    }
  }
