import type { BaseEntity } from './base-entity';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PaperTrailChange = string | number | string[] | number[] | Record<string, any>;
export type PaperTrailChanges<T extends BaseEntity = BaseEntity, V = PaperTrailChange> = Record<keyof T, [Nullable<V>, Nullable<V>]>;
export interface PaperTrailVersion<Type extends BaseEntity = BaseEntity, Value = PaperTrailChange> extends BaseEntity<string> {
  event: 'create' | 'update' | 'destroy';
  event_group_uuid?: string;
  impersonation_grant?: ImpersonationGrant;
  item_id: Type['id'];
  item_type: string;
  object?: Type;
  object_changes?: PaperTrailChanges<Type, Value>;
  whodunnit: string;
}

interface ImpersonationGrant {
  requested_by: string;
  requested_from: string;
}

export interface PaperTrailsFilters {
  attributes: string[];
  created_at?: string | [string, string];
  event: ('create' | 'update' | 'destroy')[];
  item_type: string[];
  search: string;
  user: string[];
}

export interface Whodunnit {
  id: string;
  name: string;
}

export const DEFAULT_FILTERS: PaperTrailsFilters = {
  event: [],
  user: [],
  item_type: [],
  attributes: [],
  search: '',
};

export interface PaperTrailAttribute {
  item_types: string[];
  key: string;
}

export interface PaperTrailItemType {
  attribute_keys: string[];
  item_type: string;
}
