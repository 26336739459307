
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { Component, Model, Prop, Ref, Vue } from 'vue-property-decorator';
  import type { TenantUser } from '@app/models/tenant-user';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { select2ResponseTemplate } from '@app/utils/select2-response-template';

  import EntitySelector from '../entity-selector.vue';

  @Component({ components: { EntitySelector } })
  export default class UserSelector extends Vue {
    @Model('input') value!: number | number[] | string;
    @Prop(Boolean) multiple?: boolean;
    @Prop(Boolean) readonly?: boolean;
    @Prop(Boolean) noCache?: boolean;
    @Prop(Boolean) allowClear?: boolean;
    @Prop(Boolean) currentUserFirst?: boolean;
    @Prop(String) readonly placeholder?: string;
    @Prop(String) readonly missingPlaceholder?: string;
    @Prop(String) readonly sort?: string;
    @Prop(Object) readonly fetchFilters?: DonesafeFilterOptions<TenantUser>;
    @Prop([Object, Function]) filters?: DonesafeFilterOptions<TenantUser> | (() => DonesafeFilterOptions<TenantUser>);
    @Prop([Object, Function]) requiredFilters!: DonesafeFilterOptions<TenantUser> | (() => DonesafeFilterOptions<TenantUser>);
    @Prop(Function) readonly openMethod?: () => boolean;
    @Prop({
      default() {
        return ['id', 'full_name', 'secondary_information', 'active'];
      },
    })
    readonly only?: (keyof TenantUser)[];
    @Prop() readonly templateResult?: (result?: Record<string, string>) => JQuery;
    @Ref() readonly selector?: EntitySelector<TenantUser>;
    @Prop(String) readonly inputId?: string;

    include = ['secondary_information'];

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get sortColumn(): string {
      const sortColumn = this.sort || 'full_name';
      if (this.currentUserFirst && this.currentUserStore.data?.id) {
        return `first_record:${this.currentUserStore.data?.id},${sortColumn}`;
      }
      return sortColumn;
    }

    setOptions(options: TenantUser[] = []): void {
      this.selector?.setOptions(options);
    }

    toggle(value: boolean): void {
      this.selector?.toggle(value);
    }

    close(): void {
      this.selector?.close();
    }

    defaultPrimaryAttribute(u: { active?: boolean; full_name: string; id: string; text: string }): string {
      if (this.currentUserFirst && `${this.currentUserStore.data?.id}` == u.id) {
        return this.$t('app.labels.name_yourself', { name: u.text });
      } else if ('active' in u && 'full_name' in u) {
        return u?.active ? u.full_name : this.$t('app.labels.title_inactive', { title: u.full_name });
      } else {
        return u?.text || '';
      }
    }

    defaultTemplateSelection(result: { [key: string]: string } = {}): JQuery {
      return select2ResponseTemplate(result, {
        primaryAttribute: this.defaultPrimaryAttribute,
      });
    }

    defaultTemplateResult(result: { [key: string]: string } = {}): JQuery {
      return select2ResponseTemplate(result, {
        secondaryAttribute: 'secondary_information',
        primaryAttribute: this.defaultPrimaryAttribute,
      });
    }

    addOption(id: string, text: string, selected = false): void {
      this.selector?.addOption(id, text, selected);
    }
  }
