import moment from 'moment/moment';
import type { DetailsLink } from '../models';
import { ResponseType } from '../models';
import { versionDate, versionTime } from '../utils';
import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { PaperTrailChange, PaperTrailVersion } from '@app/models/paper-trail-version';
import type { TenantUser } from '@app/models/tenant-user';
import { AdminPermissionFeature } from '@app/models/admin-permission';
import type { DonesafeApi } from '@app/services/donesafe-api';
import { extractIdFromString } from '@app/utils/extract-id-from-string';
import { featureEnabled } from '@app/utils/feature-enabled';

type AuthorType = 'raw' | 'user' | 'automation' | 'super_import' | 'bundle_installation' | 'scheduled_event';
const DATE_ATTRIBUTES = ['date_of_birth', 'start_date', 'end_date'];
const DATE_TIME_ATTRIBUTES = ['password_changed_at', 'confirmed_at', 'confirmation_sent_at'];

export class UserService extends BaseService<TenantUser> {
  static readonly allowedAttributes = [
    'active',
    'admin',
    'can_change_password',
    'company_register_id',
    'confirmation_sent_at',
    'confirmed_at',
    'data',
    'date_of_birth',
    'email',
    'employment_type_id',
    'end_date',
    'external_uuid',
    'first_name',
    'full_name',
    'gender_identity_id',
    'generated_email',
    'home_location_id',
    'home_organization_id',
    'internal',
    'kb_acknowledgements',
    'language',
    'last_name',
    'location_ceiling_id',
    'manager_id',
    'mfa_signed_in',
    'mobile',
    'organization_ceiling_id',
    'partner_proxy',
    'password_changed_at',
    'payroll_identifier',
    'position',
    'primary_signature_id',
    'privacy_statement_agreed_at',
    'profile_id',
    'role_id',
    'skip_explicit_location_restrictions',
    'skip_explicit_organization_restrictions',
    'start_date',
    'technical_admin',
    'timezone',
    'title',
    'type',
    'uuid',
    'valid_email',
    'workplace_industry_id',
  ];
  static attributeNames($t: (key: string) => string): Partial<Record<keyof TenantUser, string>> {
    return {
      employment_type_id: $t('app.labels.employment_type'),
      gender_identity_id: $t('app.labels.gender_identity'),
      home_location_id: $t('app.labels.home_location'),
      home_organization_id: $t('app.labels.home_organization'),
      manager_id: $t('app.labels.manager'),
      primary_signature_id: $t('app.labels.signature'),
      role_id: $t('app.labels.role'),
      skip_explicit_location_restrictions: $t('tenant.admin.users.sections.tab_locations.skip_explicit_location_restrictions'),
      skip_explicit_organization_restrictions: $t('tenant.admin.users.sections.tab_organizations.skip_explicit_organization_restrictions'),
      workplace_industry_id: $t('app.labels.workplace_industry'),
    };
  }
  static readonly attributeServices: Partial<Record<keyof TenantUser, string>> = {
    employment_type_id: 'EmploymentType',
    gender_identity_id: 'GenderIdentity',
    home_location_id: 'Location',
    home_organization_id: 'Organization',
    manager_id: 'User',
    primary_signature_id: 'Signature',
    role_id: 'Role',
    workplace_industry_id: 'WorkplaceIndustry',
  };

  async fetchDetails({ itemId }: ServiceRequest<TenantUser>): Promise<DetailsServiceResponse> {
    return this.fetchDetailsById(itemId);
  }

  async fetchDetailsById(id: TenantUser['id']): Promise<DetailsServiceResponse> {
    const user = await UserService.fetchEntity(this.api, id);
    const links = [
      {
        link: this.userLink(user.id),
        id: user.id,
        title: user.full_name,
        prefix: 'User',
        subTitle: user.secondary_information,
      },
    ];
    const secondary = user.secondary_information ? `, ${user.secondary_information}` : '';
    const subText = `ID: ${id}${secondary}`;
    return { links, subText };
  }

  async fetchWhodunnitDetails(version: PaperTrailVersion): Promise<DetailsServiceResponse> {
    const authorType = UserService.authorType(version.whodunnit);
    let links: DetailsLink[];
    let subText = `ID: ${version.item_id}`;
    if (authorType === 'user' && version.whodunnit) {
      const user = await UserService.fetchEntity(this.api, Number(version.whodunnit));
      links = [
        {
          link: this.whodunnitLink(version),
          id: version.whodunnit,
          title: UserService.whodunnit(version.whodunnit, { user }),
          prefix: 'User',
          subTitle: user.secondary_information,
        },
      ];
      subText = `${subText}, ${user.secondary_information}`;
    } else if (authorType === 'raw') {
      links = [{ title: 'System' }];
    } else {
      links = [
        {
          id: UserService.whodunnitId(version.whodunnit),
          title: UserService.whodunnit(version.whodunnit, { withoutId: true }),
          link: this.whodunnitLink(version),
        },
      ];
    }
    return { links, subText };
  }

  async fetchEntity({ itemId }: ServiceRequest<TenantUser>): Promise<TenantUser> {
    return await UserService.fetchEntity(this.api, itemId);
  }

  static async fetchEntity(api: DonesafeApi, itemId: TenantUser['id']): Promise<TenantUser> {
    const { data: user } = await api.getTenantUser(
      itemId,
      { include: ['secondary_information'], only: ['id', 'full_name', 'secondary_information'] },
      { cache: true, join: true }
    );
    return user;
  }

  entityToText({}: ServiceRequest<TenantUser>, entity?: TenantUser): string {
    return entity ? UserService.toText(entity) : '';
  }

  normalizeText(change: Nullable<PaperTrailChange>, key: keyof TenantUser): Nullable<string> {
    if (change) {
      if (DATE_ATTRIBUTES.includes(key)) {
        return versionDate(this.account, change);
      } else if (DATE_TIME_ATTRIBUTES.includes(key)) {
        return moment(change).format(this.account?.datetimepicker_datetime_format);
      }
    }
    return super.normalizeText(change, key);
  }

  normalizeValue(change: Nullable<PaperTrailChange>, key: keyof TenantUser): Nullable<PaperTrailChange> {
    if (change) {
      if (DATE_TIME_ATTRIBUTES.includes(key)) {
        return versionTime(this.account, change as string);
      }
    }
    return super.normalizeValue(change, key);
  }

  responseType(key: keyof TenantUser): ResponseType {
    if (DATE_ATTRIBUTES.includes(key)) return ResponseType.Date;
    if (DATE_TIME_ATTRIBUTES.includes(key)) return ResponseType.DateTime;

    return super.responseType(key);
  }

  static toText(user: TenantUser): string {
    return `${user.full_name} [${user.id}]`;
  }

  public static authorType(whodunnit: string): AuthorType {
    if (whodunnit) {
      if (`${parseInt(whodunnit)}` === whodunnit) {
        return 'user';
      } else if (whodunnit.includes('AutomationDefinition')) {
        return 'automation';
      } else if (whodunnit.includes('SuperImport')) {
        return 'super_import';
      } else if (whodunnit.includes('AppBundle::Installation #')) {
        return 'bundle_installation';
      } else if (whodunnit.includes('ScheduledEvent #')) {
        return 'scheduled_event';
      }
    }
    return 'raw';
  }

  public static whodunnitId(whodunnit: string): Maybe<string> {
    const authorType = this.authorType(whodunnit);
    return authorType === 'user' ? whodunnit : extractIdFromString(whodunnit);
  }

  public static whodunnit(whodunnit: string, options: { user?: Pick<TenantUser, 'id' | 'full_name'>; withoutId?: boolean } = {}): string {
    const authorType = this.authorType(whodunnit);
    if (authorType === 'user') {
      return options.user ? `${options.user.full_name}` : '';
    } else if (authorType === 'automation') {
      return options?.withoutId ? 'Automation' : `Automation [${this.whodunnitId(whodunnit)}]`;
    } else if (authorType === 'super_import') {
      return options?.withoutId ? 'Import' : `Import [${this.whodunnitId(whodunnit)}]`;
    } else if (authorType === 'bundle_installation') {
      return options?.withoutId ? 'Bundle Installation' : `Bundle Installation [${this.whodunnitId(whodunnit)}]`;
    } else if (authorType === 'scheduled_event') {
      return options?.withoutId ? 'Schedule' : `Schedule [${this.whodunnitId(whodunnit)}]`;
    } else {
      return whodunnit;
    }
  }

  private userLink(userId: TenantUser['id']): Maybe<string> {
    if (this.currentUser?.technical_admin) return `/admin/settings/users/${userId}/edit`;
  }

  private whodunnitLink(version: PaperTrailVersion): Maybe<string> {
    const authorType = UserService.authorType(version.whodunnit);
    const isTechnicalAdmin = !!this.currentUser?.technical_admin;
    if (authorType === 'user' && isTechnicalAdmin) {
      return this.userLink(+version.whodunnit);
    } else if (authorType === 'automation' && featureEnabled(this.currentUser, AdminPermissionFeature.module_config)) {
      return `/admin/settings/automation_definitions/${UserService.whodunnitId(version.whodunnit)}/edit`;
    } else if (authorType === 'bundle_installation' && isTechnicalAdmin) {
      return '/admin/settings/app_bundles/installations';
    } else if (authorType === 'scheduled_event' && featureEnabled(this.currentUser, AdminPermissionFeature.module_config)) {
      return `/admin/settings/scheduled_events/${UserService.whodunnitId(version.whodunnit)}/edit`;
    }
  }
}
