
  import type { AddressFieldValue } from '@app/models/question-response-types';
  import { LOCAL_STORE_MAP_TYPE, MapTypeId } from '@app/models/question-response-types';
  import { omit } from 'lodash';
  import { Component, Emit, Ref } from 'vue-property-decorator';
  import {
    ZERO_LOCATION_LITERAL,
    ZERO_VIEW_PORT_LITERAL,
    defaultLocationPosition,
    geoLocationPosition,
    latLngText,
    parseGeometry,
    parseLocationZoom,
    defaultLatLngZoom,
    removePlusCode,
  } from '@app/services/location';
  import DsAddressPicker from '../ds-address-picker.vue';
  import DsDropdown from '../ds-dropdown.vue';
  import BaseField from './base-field';
  import type { FieldType } from '@app/models/sub-form-question';
  import type { GeocoderResult, LatLng } from '@app/models/geocoder-result';
  import { MapSize } from '@app/models/question-options/address-question-options';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { DsDropdown, DsAddressPicker } })
  export default class AddressField extends BaseField<FieldType.address> {
    @Ref() readonly map?: google.maps.Map & { $mapPromise: Promise<google.maps.Map> };

    localValue: AddressFieldValue = {};
    addressDataBlobOptions = {
      fields: [
        'address_components',
        'formatted_address',
        'adr_address',
        'geometry',
        'icon',
        'name',
        'place_id',
        'reference',
        'types',
        'url',
        'vicinity',
      ],
    };
    mapOptions: google.maps.MapOptions = {
      zoomControl: true,
      scaleControl: true,
    };
    locationClipboardIcon: Nullable<'copy' | 'check'> = null;
    placeService: Nullable<google.maps.places.PlacesService> = null;
    selectLocationInProgress = false;
    mapTypeListener: Nullable<google.maps.MapsEventListener> = null;
    mapCenter: LatLng = ZERO_LOCATION_LITERAL;
    googleReady = false;

    get validateAddress(): boolean {
      return this.question.config?.validate?.address === 'true';
    }

    get validateLatLng(): boolean {
      return this.question.config?.validate?.lat_lng === 'true' || this.validateAddress;
    }

    get dataBlobInputValue(): string {
      if (this.localValue.data_blob) {
        try {
          return JSON.stringify(this.localValue.data_blob);
        } catch {
          return '';
        }
      }
      return '';
    }

    get allowMapSelection(): boolean {
      return this.accountStore.allowMapSelection;
    }

    get mapSelectEnabled(): boolean {
      return this.allowMapSelection && this.question.config.map_select_enabled === 'true';
    }

    get mapHeight(): string {
      return this.question.config.map_select_height || MapSize.normal;
    }

    get mapTypeId(): MapTypeId {
      return MapTypeId[localStorage.getItem(LOCAL_STORE_MAP_TYPE) as keyof typeof MapTypeId] || MapTypeId.terrain;
    }

    get mapZoom(): number {
      return (this.localValue.zoom && +this.localValue.zoom) || defaultLatLngZoom().zoom;
    }

    get latLngText(): string | null {
      if (!this.latLng) return null;

      return latLngText(this.latLng);
    }

    get showClearButton(): boolean {
      return !this.readonly && (!!this.localValue.value || !!this.latLng);
    }

    get markerLabel(): object {
      return {
        text: '\ue55c',
        fontFamily: 'Material Icons',
        color: '#ffffff',
        fontSize: '18px',
      };
    }

    get latLng(): LatLng | null {
      return this.localValue.latitude && this.localValue.longitude
        ? { lat: +this.localValue.latitude, lng: +this.localValue.longitude }
        : null;
    }

    @Emit('input')
    updateLocalValue(value: AddressFieldValue): AddressFieldValue {
      this.localValue = {
        ...this.localValue,
        skip_geocoding: undefined,
        ...value,
      };
      this.sendUpdate(this.localValue);
      return this.localValue;
    }

    async initialLocation(latLng: Nullable<LatLng>): Promise<LatLng> {
      if (latLng) return Promise.resolve(latLng);

      const nullLocation = defaultLatLngZoom().latLng;
      const defaultLocation = this.currentUserStore.data?.home_location_id
        ? await defaultLocationPosition({ api: this.$api, locationIds: [this.currentUserStore.data.home_location_id], nullLocation })
        : nullLocation;
      return geoLocationPosition({ nullLocation: defaultLocation, silent: true });
    }

    updateDependingMapCenters(placeResult: google.maps.places.PlaceResult | GeocoderResult): void {
      this.$nextTick(() => {
        const $mapSelectorCenterFields = $(`*[data-center-question-code="${this.question.code}"]`);
        if ($mapSelectorCenterFields.length) {
          const location = placeResult.geometry?.location;
          if (location) {
            const params = JSON.stringify(location);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            $mapSelectorCenterFields.trigger({ type: 'map:center_update', params } as any);
          }
        }
      });
    }

    updateGoogleMapCenter(latLng?: LatLng): void {
      this.updateLocalValue({ longitude: latLng?.lng || this.localValue.longitude, latitude: latLng?.lat || this.localValue.latitude });
      this.mapCenter = latLng || ZERO_LOCATION_LITERAL;
      this.parsleyReset();
    }

    clearResponse(): void {
      this.localValue = {};
      this.initDefaults();
      this.updateLocalValue({});
    }

    dataBlobFromPlaceResult(placeResult: Nullable<google.maps.places.PlaceResult>): Maybe<GeocoderResult> {
      if (!placeResult) return;

      const location = placeResult.geometry?.location
        ? {
            lat: placeResult.geometry.location.lat(),
            lng: placeResult.geometry.location.lng(),
          }
        : undefined;
      return {
        geometry: {
          location,
          viewport: {
            northeast: placeResult.geometry?.viewport?.getNorthEast().toJSON(),
            southwest: placeResult.geometry?.viewport?.getSouthWest().toJSON(),
          },
        },
        formatted_address: placeResult.formatted_address || '',
        place_id: placeResult.place_id,
        address_components: placeResult.address_components,
      };
    }

    onInputChanged(value?: string) {
      const locationZoom = parseLocationZoom(value);
      if (locationZoom?.latLng) {
        this.updateLocalValue({
          ...this.localValue,
          value: locationZoom?.value,
          latitude: locationZoom?.latLng?.lat,
          longitude: locationZoom?.latLng?.lng,
          zoom: locationZoom?.zoom || this.mapOptions.zoom || undefined,
        });
      } else {
        if (this.accountStore.allowMapSelection && this.validateLatLng) return;

        this.updateLocalValue({ ...this.localValue, value, skip_geocoding: true });
      }
    }

    onPlaceChanged(placeResult: google.maps.places.PlaceResult): void {
      const locationZoom = parseLocationZoom(placeResult.formatted_address, placeResult);
      const geometry = parseGeometry(placeResult.geometry);
      if (!locationZoom?.latLng && !geometry) return this.clearResponse();

      const value = locationZoom?.value;
      if (geometry) {
        this.updateLocalValue({
          value,
          latitude: geometry?.location?.lat,
          longitude: geometry?.location?.lng,
          data_blob: {
            ...(omit(placeResult, 'geometry') as GeocoderResult),
            geometry: {
              ...this.localValue.data_blob?.geometry,
              location: geometry?.location,
              viewport: geometry?.viewport || ZERO_VIEW_PORT_LITERAL,
            },
          },
        });
        geometry.location && this.updateGoogleMapCenter(geometry.location);
      } else if (locationZoom?.latLng) {
        this.updateLocalValue({
          value,
          zoom: locationZoom.zoom || this.localValue.zoom,
          latitude: locationZoom.latLng.lat,
          longitude: locationZoom.latLng.lng,
        });
        this.updateGoogleMapCenter(locationZoom.latLng);
      }

      this.updateDependingMapCenters(placeResult);
    }

    onMapMarkerMoved({ latLng, placeId }: { latLng: google.maps.LatLng; placeId?: string }): void {
      if (this.readonly || this.selectLocationInProgress) return;

      if (placeId) {
        this.fetchDetails(placeId);
      } else {
        this.geocodeResult(latLng.lat(), latLng.lng()).then((result) => {
          const dropPinLatLng = { lat: latLng.lat(), lng: latLng.lng() };
          if (result?.place_id) {
            this.fetchDetails(result.place_id, dropPinLatLng);
          } else {
            const value = removePlusCode(result?.formatted_address) || latLngText(latLng.toJSON());
            this.updateLocalValue({ value, latitude: dropPinLatLng.lat, longitude: dropPinLatLng.lng });
          }
        });
      }
      this.updateGoogleMapCenter(latLng.toJSON());
    }

    onMapZoomChanged(zoom: number) {
      this.updateLocalValue({
        ...this.localValue,
        zoom,
      });
    }

    onMapTypeIdChanged(typeId: MapTypeId) {
      localStorage.setItem(LOCAL_STORE_MAP_TYPE, typeId);
    }

    onLocationPositionError(error: GeolocationPositionError): void {
      const text =
        error.message === 'User denied Geolocation'
          ? this.$t('app.labels.location_service_disabled')
          : this.$t('app.labels.no_coordinates_found');
      toaster({ text, icon: 'error' });
      throw error;
    }

    selectNearestAddress(): void {
      this.selectLocationInProgress = true;
      geoLocationPosition()
        .then((latLng) => {
          this.geocodeResult(latLng.lat, latLng.lng).then((result) => {
            if (!result) return;

            this.updateLocalValue({
              ...this.localValue,
              value: result.formatted_address,
              data_blob: {
                ...this.localValue.data_blob,
                formatted_address: result.formatted_address,
                geometry: {
                  ...this.localValue.data_blob?.geometry,
                  ...result.geometry,
                },
              } as GeocoderResult,
            });
            this.updateGoogleMapCenter(result.geometry.location);
          });
        })
        .catch((error) => this.onLocationPositionError(error))
        .finally(() => (this.selectLocationInProgress = false));
    }

    selectLatLong(): void {
      this.selectLocationInProgress = true;
      geoLocationPosition()
        .then((latLng) => {
          this.updateLocalValue({ ...this.localValue, value: latLngText(latLng) });
          this.updateGoogleMapCenter(latLng);
        })
        .catch((error) => this.onLocationPositionError(error))
        .finally(() => (this.selectLocationInProgress = false));
    }

    copyLatLng(): void {
      this.$copyText(this.latLngText || '');
      this.locationClipboardIcon = 'check';
    }

    async geocodeResult(latitude: number | string, longitude: number | string): Promise<GeocoderResult | undefined> {
      const { data } = await this.$api?.geocode({ latitude, longitude });
      if (!data.results) return;

      return data.results[0];
    }

    fetchDetails(placeId: string, customLatLng?: LatLng) {
      this.placeService?.getDetails({ placeId: placeId }, (placeResult) => {
        this.updateLocalValue({
          value: removePlusCode(placeResult?.formatted_address),
          data_blob: this.dataBlobFromPlaceResult(placeResult),
          latitude: customLatLng?.lat || placeResult?.geometry?.location?.lat(),
          longitude: customLatLng?.lng || placeResult?.geometry?.location?.lng(),
        });
      });
    }

    initLocalValue(defaultLatLng?: LatLng): void {
      const locationZoom = parseLocationZoom(this.localValue?.value);
      if (this.mapSelectEnabled) {
        const anyLatLng = defaultLatLng || locationZoom?.latLng || this.localValue.data_blob?.geometry.location || this.latLng;
        this.mapCenter = anyLatLng ? { lat: +anyLatLng.lat, lng: +anyLatLng.lng } : ZERO_LOCATION_LITERAL;
      }

      this.localValue = {
        ...this.localValue,
        latitude: locationZoom?.latLng?.lat || this.latLng?.lat,
        longitude: locationZoom?.latLng?.lng || this.latLng?.lng,
      };
    }

    initMapSelect(): void {
      this.$nextTick(() =>
        this.map?.$mapPromise?.then((map) => {
          this.mapTypeListener = map.addListener('maptypeid_changed', () => this.onMapTypeIdChanged(map.getMapTypeId() as MapTypeId));

          this.placeService = new google.maps.places.PlacesService(map);
        })
      );
    }

    initDefaults(): void {
      if (this.mapSelectEnabled) {
        this.selectLocationInProgress = true;
        this.initialLocation(this.latLng)
          .then((latLng) => this.initLocalValue(latLng))
          .finally(() => (this.selectLocationInProgress = false));
      } else {
        this.initLocalValue();
      }
    }

    beforeMount(): void {
      this.localValue = { ...this.value };
      this.initDefaults();
    }

    async mounted(): Promise<void> {
      await this.$gmapApiPromiseLazy();
      this.googleReady = true;
      this.localValue.data_blob && this.updateDependingMapCenters(this.localValue.data_blob);
      this.mapSelectEnabled && this.initMapSelect();
    }

    beforeDestroy(): void {
      this.mapTypeListener?.remove();
    }
  }
