
  import { Component } from 'vue-property-decorator';
  import TextHighlight from 'vue-text-highlight';
  import BaseResponse from './base-response';
  import type { Location } from '@app/models/location';

  @Component({ components: { TextHighlight } })
  export default class LocationResponse extends BaseResponse<Location> {
    get locationEditLink(): Nullable<string> {
      if (this.currentUserStore.hasUsersLocationsOrganizationsPermission) {
        return `/admin/settings/locations/${this.entity?.id}/edit`;
      } else {
        return null;
      }
    }
  }
