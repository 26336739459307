import type { Subscription } from 'rxjs';
import type { Subscription as ActionCableSubscription } from '@rails/actioncable';
import consumer from '@app/channels/consumer';
import { BehaviorSubject, Subject } from 'rxjs';
import { Component, Vue } from 'vue-property-decorator';
import bootbox from 'bootbox';
import type { DebouncedFunc } from 'lodash';
import type { ResponseValue, SubFormQuestion } from '@app/models/sub-form-question';
import type { SubForm } from '@app/models/sub-form';
import type { SubFormCompletion, SubFormCompletionStage } from '@app/models/sub-form-completion';
import type { FormObservers } from '@app/utils/types/form-observers';
import type { PartialBy } from '@app/utils/types/partial';
import { verifySubFormChanges } from '@app/utils/verify-sub-form-changes';
import { changeLocation } from '@app/utils/change-location';

import type { QuestionSfcOnly, SubFormSfcFormOnly } from '../utils';

@Component
export class WithFormSubscriptions extends Vue {
  debouncedSubmitForm!: DebouncedFunc<
    (stage: SubFormCompletionStage, addAnother: boolean, inPlaceUpdate: boolean, event?: MouseEvent) => void
  >;
  lookupRequestSubscription: Nullable<Subscription> = null;
  detailsRequestSubscription: Nullable<Subscription> = null;
  apiRequestSubscription: Nullable<Subscription> = null;
  updatesSubscription: Nullable<ActionCableSubscription> = null;
  scrollSubscription: Nullable<Subscription> = null;
  combinedStatesAndValuesSubscription: Nullable<Subscription> = null;
  updateQueue$ = new Subject<{ question: Pick<SubFormQuestion, QuestionSfcOnly>; value: ResponseValue }>();

  observers: FormObservers = {
    codeValueData$: new Subject(),
    fieldUpdate$: new Subject(),
    requestExpensableMainFormRelationBroadcast$: new Subject(),
    expensableBudgetSelectUpdate$: new Subject(),
    clearExpensableMainFormRelation$: new Subject(),
    detailsResponse$: new Subject(),
    dateUpdate$: new Subject(),
    apiLookupResult$: new Subject(),
    apiRequestResult$: new Subject(),
    apiRequestQueue$: new Subject(),
    lookupRequest$: new Subject(),
    detailsRequest$: new Subject(),
    visibilityStateUpdate$: new Subject(),
    questionShowHideState$: new BehaviorSubject({}),
    calculationValues$: new BehaviorSubject({}),
  };

  updateOccurred = false;
  formUuid = '';

  updateOccuredDialog: Nullable<{ modal: (params: 'hide') => void }> = null;

  verifySubFormChanges(
    recordId: number,
    subFormCompletion: PartialBy<SubFormCompletion, 'id' | 'sub_form_responses' | 'updated_at'>,
    subForm: Nullable<Pick<SubForm, SubFormSfcFormOnly>>
  ): void {
    if (!subFormCompletion?.id || !subFormCompletion.updated_at) return;

    verifySubFormChanges(this.$api, {
      recordId,
      subFormCompletionId: subFormCompletion.id,
      updatedAt: subFormCompletion.updated_at,
      moduleName: subForm?.module_name,
    }).then((outdated) => outdated && document.location.reload());
  }

  subscribeToUpdates(
    recordId: number,
    subFormCompletion: PartialBy<SubFormCompletion, 'id' | 'sub_form_responses' | 'updated_at'>,
    wizard: boolean,
    subForm: Nullable<Pick<SubForm, SubFormSfcFormOnly>>
  ): void {
    this.updatesSubscription = consumer.subscriptions.create(
      {
        channel: 'WebNotificationsChannel',
        record_type: 'SubFormCompletion',
        record_id: subFormCompletion.id,
      },
      {
        connected: () => this.verifySubFormChanges(recordId, subFormCompletion, subForm),
        disconnected: () => this.updatesSubscription?.unsubscribe(),
        received: (data: { form_uuid?: string }) => {
          if (!this.updateOccurred && !wizard && this.formUuid !== data.form_uuid) {
            this.updateOccurred = true;
            this.debouncedSubmitForm.cancel();
            this.updateOccuredDialog = bootbox.dialog({
              size: 'small',
              onEscape: false,
              backdrop: true,
              message: this.$t('tenant.sub_form_completions.form.update_occurred_message'),
              buttons: {
                refresh: {
                  label: this.$t('app.buttons.refresh'),
                  className: 'btn-primary',
                  callback: (): void => window.location.reload(),
                },
                close: {
                  label: this.$t('app.buttons.close'),
                  className: 'btn-secondary',
                  callback: (): void => {
                    const path = `/module_records/${recordId}`;
                    const subFormListId = subFormCompletion.sub_form_list_id;
                    changeLocation(subFormListId ? `${path}?open_completion_id=${subFormCompletion.id}` : path);
                  },
                },
              },
            });
          }
        },
      }
    );
  }

  beforeDestroy(): void {
    this.lookupRequestSubscription?.unsubscribe();
    this.detailsRequestSubscription?.unsubscribe();
    this.apiRequestSubscription?.unsubscribe();
    this.updatesSubscription?.unsubscribe();
    this.scrollSubscription?.unsubscribe();
    this.combinedStatesAndValuesSubscription?.unsubscribe();
    this.updateQueue$.unsubscribe();
    this.updateOccuredDialog?.modal('hide');
  }
}
