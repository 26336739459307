import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import { UserService } from './user-service';
import type { Profile } from '@app/models/profile';

export class ProfileService extends BaseService<Profile> {
  static readonly allowedAttributes = [
    'country_code',
    'home_number',
    'native_language',
    'other_names',
    'personal_email',
    'postal_address_1',
    'postal_address_2',
    'postcode',
    'require_interpreter',
    'residential_address_1',
    'residential_address_2',
    'special_needs',
    'state_code',
    'suburb',
  ];

  async fetchDetails({ itemId, version }: ServiceRequest<Profile>): Promise<DetailsServiceResponse> {
    const userId = this.extractValue<number>('user_id', version);
    if (!userId) return {};

    const userService = new UserService(this.config);
    const userDetails = await userService.fetchDetailsById(userId);
    return { ...userDetails, subText: `ID: ${itemId}` };
  }
}
