
  import { Component, Emit } from 'vue-property-decorator';
  import type { KnowledgeBaseFieldValue } from '@app/models/question-response-types';
  import type { FieldType } from '@app/models/sub-form-question';
  import type { Procedure } from '@app/models/procedure';

  import BaseField from './base-field';

  @Component({ components: {} })
  export default class KnowledgeBaseField extends BaseField<FieldType.knowledge_base> {
    procedure?: Nullable<Procedure> = null;
    localValue: KnowledgeBaseFieldValue = { value: '' };
    loading = false;

    @Emit('input')
    updateValue(value: string): KnowledgeBaseFieldValue {
      this.localValue = { ...this.localValue, value };
      this.sendUpdate(this.localValue);
      return this.localValue;
    }

    beforeMount(): void {
      this.localValue = { ...this.value };
      this.fetchData();
    }

    fetchData(): void {
      this.loading = true;
      this.$api
        .getProcedure(Number(this.question.config.procedure_id), { only: ['name', 'body'] })
        .then(({ data }) => {
          this.procedure = data;
        })
        .finally(() => (this.loading = false));
    }
  }
