
  import { Component, Model, Prop, Ref, Vue } from 'vue-property-decorator';
  import DatePicker from '@app/components/date-picker.vue';

  @Component({ components: { DatePicker } })
  export default class YearPickerButton extends Vue {
    @Model('input', { type: Date }) readonly value!: Date | null;
    @Prop({ type: Date }) readonly yearDateValue?: Date;
    @Ref() readonly yearSelectElement?: DatePicker;

    showPicker() {
      this.openYearPicker();
    }

    openYearPicker() {
      this.yearSelectElement && $(this.yearSelectElement.$el).datepicker('show');
    }

    onYearPicked(date: Date): void {
      this.$emit('input', date);
      this.yearSelectElement && $(this.yearSelectElement.$el).datepicker('hide');
    }
  }
