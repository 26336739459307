import { extractPaperTrailVersionValue } from '@app/components/paper-trails/utils';
import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { Widget } from '@app/models/widget';

export class WidgetService extends BaseService<Widget> {
  async fetchDetails({ itemId, version }: ServiceRequest<Widget>): Promise<DetailsServiceResponse> {
    let widgetType = extractPaperTrailVersionValue('widget_type', version);
    let title = extractPaperTrailVersionValue('title', version) as string;
    let dashboardPane = '';
    try {
      const { data: widget } = await this.api.getWidget(
        itemId,
        { only: ['id', 'title', 'widget_type', { dashboard_pane: ['id', 'name'] }] },
        { join: true, cache: true }
      );
      widgetType = widget.widget_type;
      title = widget.title;
      dashboardPane = widget.dashboard_pane?.name || '';
    } catch {}

    const links = title ? [{ prefix: this.t('app.labels.widget'), title, id: itemId }] : [];

    const subText = [
      `ID: ${itemId}`,
      widgetType ? `${this.t('app.labels.widget_type')}: ${widgetType}` : '',
      dashboardPane ? `${this.t('app.labels.dashboard_pane')}: ${dashboardPane}` : '',
    ]
      .filter(Boolean)
      .join(' ');
    return { subText, links };
  }

  entityToText({}: ServiceRequest, entity?: Widget): string {
    return entity ? `${decodeURI(entity.title)} [${entity.id}]` : '';
  }

  fetchEntity({ itemId }: ServiceRequest<Widget>): Promise<Partial<Widget>> {
    return this.api.getWidget(itemId, { only: ['id', 'title'] }, { join: true, cache: true }).then(({ data }) => data);
  }
}
