
  import { Component, Prop } from 'vue-property-decorator';
  import BaseQuestionResponse from './base-question-response';
  import TextHighlight from 'vue-text-highlight';
  import type { SubFormCompletion } from '@app/models/sub-form-completion';

  @Component({ components: { TextHighlight } })
  export default class SubFormRelationFieldResponse extends BaseQuestionResponse {
    @Prop(Object) readonly completion?: Pick<SubFormCompletion, 'id' | 'title'>;
    @Prop(String) readonly completionText?: string;
  }
