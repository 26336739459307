import { debounce, groupBy } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ResizeObserverMixin extends Vue {
  maxColumnWidths: Record<string, number> = {};
  resizeObserver: Nullable<ResizeObserver> = null;

  debouncedHandleWindowResize = debounce(this.calcMaxColumnWidths, 100);

  syncResizeObserver() {
    this.resizeObserver?.disconnect();

    const elements = this.$el.querySelectorAll(
      `.simple-grid-thead .simple-grid-tr .simple-grid-th,
            .simple-grid-tbody .simple-grid-tr .simple-grid-td`
    );

    elements.forEach((element) => {
      this.resizeObserver?.observe(element);
    });

    this.calcMaxColumnWidths();
  }

  calcMaxColumnWidths() {
    const headerCells = this.$el.querySelectorAll('.simple-grid-th');
    const columnCellsByIndex = groupBy(this.$el.querySelectorAll('.simple-grid-td'), (cell) => cell.getAttribute('data-index'));

    headerCells.forEach((cell) => {
      // Find the max width from header and column cells.
      let maxWidth = this.getMaxWidthOfElement(cell);

      const index = cell.getAttribute('data-index');
      if (index && columnCellsByIndex[index]) {
        columnCellsByIndex[index].forEach((columnCell) => {
          const columnWidth = this.getMaxWidthOfElement(columnCell);
          if (columnWidth > maxWidth) {
            maxWidth = columnWidth;
          }
        });
      }

      // Save the max width
      if (index !== null) this.$set(this.maxColumnWidths, index, maxWidth);
    });
  }

  getMaxWidthOfElement(element: Element) {
    // Clone the element
    const clone = element.cloneNode(true) as HTMLElement;

    // Apply some style to make the clone out of flow and not visible
    clone.style.visibility = 'hidden';
    clone.style.position = 'absolute';

    // Temporarily append to the body
    document.body.appendChild(clone);

    // Now it should have its natural width
    const naturalWidth = clone.offsetWidth;

    // Remove the clone from the body
    document.body.removeChild(clone);

    // Calculate total width considering padding
    const padding = this.getPadding(element);
    const totalWidth = naturalWidth + padding;

    return totalWidth;
  }

  getPadding(element: Element) {
    const style = window.getComputedStyle(element);
    const paddingLeft = parseFloat(style.paddingLeft);
    const paddingRight = parseFloat(style.paddingRight);

    return paddingLeft + paddingRight;
  }

  initResizeObserver() {
    this.resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        // Extract the column index from the data-index attribute of the target element
        const index = entry.target.getAttribute('data-index');
        const width = entry.contentRect.width;
        const padding = this.getPadding(entry.target);
        const totalWidth = width + padding;

        if (index === null) continue;

        if (!this.maxColumnWidths?.[index] || totalWidth > this.maxColumnWidths?.[index]) {
          this.$set(this.maxColumnWidths, index, totalWidth);
        }
      }
    });

    const elements = this.$el.querySelectorAll(
      '.simple-grid-thead .simple-grid-tr .simple-grid-th, .simple-grid-tbody .simple-grid-tr .simple-grid-td'
    );

    elements.forEach((element) => this.resizeObserver?.observe(element));
  }
}
