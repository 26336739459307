import type { DonesafeApi } from './donesafe-api';
import type { Geometry, LatLng } from '@app/models/geocoder-result';

export const ZERO_LOCATION_LITERAL = { lat: 0, lng: 0 };
export const ZERO_VIEW_PORT_LITERAL = { west: 0, east: 0, north: 0, south: 0 };
export const DEFAULT_ZOOM = 16; // 100m
export interface GeoLocationPositionRequest {
  nullLocation: LatLng;
  silent?: boolean;
}
export interface DefaultLocationRequest {
  api: DonesafeApi;
  locationIds: number[];
  nullLocation: LatLng;
}
export interface LatLngZoom {
  latLng: LatLng;
  zoom: number;
}

const LAT_LNG_ZOOM_REGEXP = /(?<lat>[0-9.-]+),(?<lng>[0-9.-]+)(,(?<zoom>[0-9.]+)z)?/;

export const geoLocationPosition = (request: GeoLocationPositionRequest = { nullLocation: ZERO_LOCATION_LITERAL }): Promise<LatLng> => {
  if ('geolocation' in navigator) {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({ lat: position.coords.latitude, lng: position.coords.longitude });
        },
        (positionError) => (request.silent ? resolve(request.nullLocation) : reject(positionError))
      );
    });
  }
  return Promise.resolve(request.nullLocation);
};

export const defaultLocationPosition = async (request: DefaultLocationRequest): Promise<LatLng> => {
  const { data: locations } = await request.api.getLocations({
    filters: { location_id: request.locationIds },
    only: ['id', 'map_information'],
  });
  if (!locations.length) {
    return request.nullLocation;
  }

  const info = locations.filter((l) => l.map_information?.latitude && l.map_information?.latitude)?.[0]?.map_information;
  return (info && { lat: info.latitude, lng: info.longitude }) || request.nullLocation;
};

export const markerIcon = (selected = false): string => {
  if (selected) {
    return 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png';
  }
  return 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png';
};

// Value examples:
// -33.8711378,151.2059809,17.52z
// 45.655039,-122.593344,14.9z
export const parseLocationZoom = (
  value?: string,
  placeResult?: google.maps.places.PlaceResult
): { latLng?: LatLng; value: string; zoom?: number } | null => {
  const val = value || placeResult?.name;
  if (!val) return null;

  const result = val.match(LAT_LNG_ZOOM_REGEXP);
  if (!result?.groups) return { value: removePlusCode(val) };

  const latLng = {
    lat: result.groups.lat ? Number(result.groups.lat) : ZERO_LOCATION_LITERAL.lat,
    lng: result.groups.lng ? Number(result.groups.lng) : ZERO_LOCATION_LITERAL.lng,
  };
  return {
    latLng,
    zoom: result.groups.zoom ? Number(result.groups.zoom) : DEFAULT_ZOOM,
    value: latLngText(latLng),
  };
};

export const latLngText = (location: LatLng): string => {
  return [location?.lat, location?.lng].join(',');
};

export const parseGeometry = (geometry?: google.maps.places.PlaceGeometry | google.maps.GeocoderGeometry): Geometry | undefined => {
  if (!geometry?.location || !geometry?.viewport) return;

  return {
    location: geometry.location.toJSON(),
    viewport: geometry.viewport.toJSON(),
  };
};

export const defaultLatLngZoom = (): LatLngZoom => {
  const latLngZoom = parseLocationZoom(window.DONESAFE.DEFAULT_ADDRESS_LAT_LNG_ZOOM);
  return {
    latLng: latLngZoom?.latLng || ZERO_LOCATION_LITERAL,
    zoom: latLngZoom?.zoom || DEFAULT_ZOOM,
  };
};

export const distance = (latLng1: LatLng, latLng2: LatLng): number => {
  const p = Math.PI / 180;
  const r = 6371; // km
  const cos = Math.cos;
  const a =
    0.5 -
    cos((latLng2.lat - latLng1.lat) * p) / 2 +
    (cos(latLng1.lat * p) * cos(latLng2.lat * p) * (1 - cos((latLng2.lng - latLng1.lng) * p))) / 2;

  return 2 * r * Math.asin(Math.sqrt(a));
};

// Removes local plus codes from address https://github.com/google/open-location-code/wiki/Supporting-plus-codes-in-your-app
// Examples: 677H+35, 5P5X+X8
export const removePlusCode = (address?: string): string => {
  if (!address) return '';

  return address.replace(/(^|\s)([23456789CFGHJMPQRVWX]{4,6}\+[23456789CFGHJMPQRVWX]{2,3})(,?)(\s|$)/, '');
};

export const areAddressesEqual = (a1?: string, a2?: string): boolean => {
  return removePlusCode(a1) === removePlusCode(a2);
};
