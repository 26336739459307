
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import type DsModal from '@app/components/ds-modal.vue';
  import UserProfileModal from '@app/components/admin/users/user-profile-modal.vue';

  @Component({
    components: { UserProfileModal },
  })
  export default class UserProfileModalInstance extends Vue {
    @Ref() readonly componentRef!: Vue & { modal: InstanceType<typeof DsModal> };

    @Prop(Number) readonly userId?: number;
    @Prop(String) readonly tabName?: string;

    show = true;

    onHide() {
      this.$destroy();
    }
  }
