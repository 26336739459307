import { render, staticRenderFns } from "./location-field.vue?vue&type=template&id=1ec44475&scoped=true&lang=pug"
import script from "./location-field.vue?vue&type=script&lang=ts"
export * from "./location-field.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ec44475",
  null
  
)

export default component.exports