
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { useAccountStore } from '@app/stores/account';
  import ActivityFormModal from '@app/components/activities/activity-form-modal.vue';
  import qs from 'qs';
  import { Component, Prop, Emit } from 'vue-property-decorator';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import ModuleRecordModals from '@app/mixins/module-record-modals';
  import type { SubFormCompletionFormRecord, QuestionSfcOnly } from '../utils';
  import type { Activity } from '@app/models/activity';
  import type { DefaultTemplate } from '@app/models/default-template';
  import type { ModuleName } from '@app/models/module-name';
  import type { ModuleRecord } from '@app/models/module-record';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { ActivityFormModal, DsDropdown } })
  export default class QuestionAddRelated extends ModuleRecordModals {
    @Prop(Boolean) readonly mainForm?: boolean;
    @Prop(Boolean) readonly modal?: boolean;
    @Prop(Object) readonly question!: Pick<SubFormQuestion, QuestionSfcOnly>;
    @Prop(Object) readonly record?: SubFormCompletionFormRecord;

    activityFormModalState = false;
    moduleNames: Pick<ModuleName, 'id' | 'display' | 'name'>[] = [];
    newActivity: Partial<Activity> = {
      actionable_type: 'ModuleRecord',
      actionable_id: this.record?.id,
    };
    submittingActivity = false;

    get accountStore() {
      return useAccountStore();
    }

    // TODO: remove from here and pass as prop or use slots once forms migrated
    get canAddAction(): boolean {
      return (
        !!this.record &&
        'permissions' in this.record &&
        !!this.record.permissions?.edit_access &&
        this.question.config.add?.action === 'true' &&
        this.currentUserStore.canCreateActivity
      );
    }

    get canAddAnything(): boolean {
      return this.canAddAction || this.canAddAttachments || !!this.moduleNamesToAdd.length;
    }

    get canAddAttachments(): boolean {
      return this.question.config.add?.attachment === 'true';
    }

    get createLinkedMainRecords(): boolean {
      return (
        !!this.mainForm &&
        !!this.question.config?.add &&
        'linked_main_form_records' in this.question.config.add &&
        this.question.config?.add?.linked_main_form_records === 'true'
      );
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get icon(): string {
      return this.question?.config?.add?.icon || 'add_circle_outline';
    }

    get moduleNamesToAdd(): Pick<ModuleName, 'id' | 'display' | 'name'>[] {
      return this.moduleNames.filter((module) => this.currentUserStore.data?.acl?.module?.[module.name]?.create);
    }

    @Emit('activity-created')
    activityCreated(activity: Activity): Activity {
      return activity;
    }

    @Emit('record-created')
    recordCreated(record: ModuleRecord): ModuleRecord {
      return record;
    }

    addNewRecordModal(event: MouseEvent, moduleName: Pick<ModuleName, 'id' | 'display' | 'name'>): void {
      this.getDefaultTemplate(this.question.config.add?.module_records_default?.[`${moduleName.id}`]).then((defaultTemplate) => {
        const queryString = qs.stringify({
          module_name_id: moduleName.id,
          modal: true,
          sub_form_question_id: this.question.id,
          main_form_id: this.record?.id,
          record_id: this.record?.id,
          default_template_id: defaultTemplate?.system_code,
          quick_add: true,
        });

        const newLink = `/module_records/new?${queryString}`;

        this.openModalOrLink({
          link: newLink,
          modal: true,
          event,
          modalProps: {
            mode: 'module-record-edit',
            moduleNameId: moduleName.id,
            mainFormId: this.record?.id,
            defaultTemplateId: defaultTemplate?.system_code,
            hideFooterLinks: true,
            title: this.moduleRecordEditTitle(moduleName.display),
            listeners: {
              'record-created': this.onRecordCreate,
            },
          },
        });
      });
    }

    createNewAction(): void {
      this.activityFormModalState = true;
    }

    async getDefaultTemplate(defaultTemplateId?: number | string): Promise<Pick<DefaultTemplate, 'id' | 'system_code'> | undefined> {
      if (defaultTemplateId) {
        const { data } = await this.$api.getDefaultTemplates(
          { filters: { id: defaultTemplateId }, only: ['id', 'system_code'] },
          { cache: true }
        );

        return data[0];
      }
    }

    //TODO: move out of here once forms migrated
    onActivityCreate(form: Partial<Activity>): void {
      this.submittingActivity = true;
      this.$api
        .createActivity(form)
        .then(({ data }) => {
          const activity = data[0];
          if (activity) {
            this.activityFormModalState = false;
            this.activityCreated(activity);
          }
        })
        .catch(({ data }) => toaster({ text: data.error, icon: 'error' }))
        .finally(() => (this.submittingActivity = false));
    }

    onRecordCreate(record: ModuleRecord): void {
      this.recordCreated(record);
    }

    beforeMount(): void {
      const moduleNameIdsToFetch = this.createLinkedMainRecords
        ? [this.question.config.main_form_id]
        : this.question.config.add?.module_records || [];
      moduleNameIdsToFetch.length &&
        this.$api
          .getModuleNames({ filters: { id: moduleNameIdsToFetch }, only: ['id', 'display', 'name'] }, { cache: true })
          .then(({ data }) => {
            this.moduleNames = data;
          });
    }
  }
