import { BaseService } from './base-service';
import type DashboardPane from '@app/models/dashboard-pane';

export class DashboardPaneService extends BaseService<DashboardPane> {
  static readonly allowedAttributes = ['active', 'config', 'description', 'module_name_id', 'name', 'priority', 'size'];

  static readonly attributeServices: Partial<Record<keyof DashboardPane, string>> = {
    module_name_id: 'ModuleName',
  };
}
