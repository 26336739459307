import type { BaseEntity } from '@app/models/base-entity';
import { Component, Prop, Vue } from 'vue-property-decorator';
import type { DetailsLink } from '../models';
import type { PaperTrailVersion } from '@app/models/paper-trail-version';

@Component
export default class BaseDetails<T extends BaseEntity = BaseEntity> extends Vue {
  @Prop(Array) readonly queries!: string[];
  @Prop(Object) readonly version!: PaperTrailVersion;
  @Prop({ type: Array, default: () => [] }) readonly links!: DetailsLink[];
  @Prop({ type: String, default: () => '' }) readonly mainText!: string;
  @Prop({ type: String }) readonly subText?: string;

  get type(): string {
    return this.version.item_type;
  }

  get itemId(): T['id'] {
    return this.version.item_id;
  }
}
