import { Vue, Component } from 'vue-property-decorator';
import type { Signature } from '@app/models/signature';
import type { SubFormQuestion } from '@app/models/sub-form-question';

import I18n from '../i18n';

@Component
export default class SignatureDisplayMethods extends Vue {
  displaySignatureAddedBy(question: SubFormQuestion): boolean {
    return question.config.display_signature_added_by === 'true';
  }

  addedBy(signature: Signature): string {
    return signature.user?.full_name || I18n.t('app.labels.na');
  }
}
