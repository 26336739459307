
  import Select2 from '@app/components/select2.vue';
  import type { ExpenseCategoryOverwrite } from '@app/models/expense-category-overwrite';
  import type { ExpenseFieldValue } from '@app/models/question-response-types';
  import { Component, Emit, Prop } from 'vue-property-decorator';
  import { isEmpty } from 'lodash';
  import type { ExpenseCategory } from '@app/models/expense-category';
  import type { FieldType } from '@app/models/sub-form-question';
  import type { ModuleRecord } from '@app/models/module-record';

  import BaseField from './base-field';

  @Component({ components: { Select2 } })
  export default class ExpenseField extends BaseField<FieldType.expense> {
    @Prop(Object) readonly record!: ModuleRecord;

    expenseCategories: ExpenseCategory[] = [];
    expenseCategoryOverwrites: ExpenseCategoryOverwrite[] = [];
    localValue: ExpenseFieldValue = { category: '', amount: '' };

    get expenseCategoriesOptionsWithOverwrites(): [string, string][] {
      const overwrites: Record<number, ExpenseCategoryOverwrite> = this.expenseCategoryOverwrites.reduce((memo, eco) => {
        return { ...memo, [eco.expense_category_id]: { active: eco.active } };
      }, {});

      return this.expenseCategories.reduce<[string, string][]>((memo, ec) => {
        const match = overwrites[ec.id];

        if (!match || match.active) {
          return [...memo, [`${ec.id}`, ec.name]];
        }
        return memo;
      }, []);
    }

    @Emit('input')
    updateValue(value: string, key: 'amount' | 'category'): ExpenseFieldValue {
      this.localValue = { ...this.localValue, [key]: value };
      return this.localValue;
    }

    beforeMount(): void {
      if (!isEmpty(this.value)) {
        this.localValue = { ...this.value };
      }
      this.getExpenseCategoryOverwrites();
      this.getExpenseCategories();
    }

    getExpenseCategories(): void {
      this.$api
        .getExpenseCategories(
          {
            only: ['id', 'name'],
            filters: { active: true },
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.expenseCategories = data;
        });
    }

    getExpenseCategoryOverwrites(): void {
      this.$api
        .getExpenseCategoryOverwrites(
          {
            only: ['expense_category_id', 'active'],
            filters: {
              sub_form_list_id: this.question.config.sub_form_list_id,
              record_id: this.record.id,
              record_type: 'ModuleRecord',
            },
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.expenseCategoryOverwrites = data;
        });
    }
  }
