
  import type { Blob as ActiveStorageBlob } from '@rails/activestorage';
  import bootbox from 'bootbox';
  import type { File as FilePondFile } from 'filepond';
  import { Component, Emit, Ref } from 'vue-property-decorator';
  import { get, isEmpty } from 'lodash';
  import { v4 as generateUUID } from 'uuid';
  import type { FileUploadFieldValue } from '@app/models/question-response-types';
  import type { Attachment } from '@app/models/attachment';
  import type { Dictionary } from '@app/models/dictionary';
  import type { FieldType } from '@app/models/sub-form-question';

  import AttachmentsUploader from '../attachment/attachments-uploader.vue';
  import AttachmentItem from '../attachment/attachment-item.vue';

  import BaseField from './base-field';

  @Component({ components: { AttachmentItem, AttachmentsUploader } })
  export default class FileUploadField extends BaseField<FieldType.file_upload> {
    @Ref() readonly attachmentUploader!: AttachmentsUploader;

    localValue: FileUploadFieldValue = [];

    loadingHash: { [key: number]: boolean } = {};

    oldAttachments: Attachment[] = [];

    validateUnsavedFiles = false;

    // TODO: get rid of object response or use only object instead of an array
    get paramFiles(): Partial<Attachment>[] {
      const files = (get(this.params, ['responses', `${this.question.id}`, '_attachments_raw']) || []).filter(Boolean);

      return JSON.parse(`${files}` || '[]');
    }

    get isReadonly(): boolean {
      // do not allow file uploads while creating or editing a default template
      return this.readonly || !!this.defaultTemplating;
    }

    // for modal on top of a existing form of the same module to work
    get responseValueInputId(): string {
      return `responses-${this.question.id}-attachments-${generateUUID()}`;
    }

    get validateFile(): boolean {
      return this.isRequired && !this.localValue.length;
    }

    @Emit('input')
    updateValue(newValue: FileUploadFieldValue): FileUploadFieldValue {
      this.localValue = newValue;
      this.sendUpdate(this.localValue);
      return this.localValue;
    }

    onEdit(attachment: Attachment, newBlob: Blob): void {
      this.attachmentUploader.uploader.addFile(newBlob)?.then((fpf: FilePondFile) => {
        fpf.setMetadata('attachmentId', attachment.id);
      });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    createBlob(blob: ActiveStorageBlob & { metadata: Dictionary<any> }): void {
      this.updateValue([...this.localValue, blob.signed_id]);
      if (blob.metadata.attachmentId) {
        const attachment = this.oldAttachments.find((a) => a.id === blob.metadata.attachmentId);
        if (attachment) {
          bootbox.confirm({
            size: 'small',
            message: this.$t('app.labels.delete_original_attachment'),
            buttons: {
              confirm: { label: this.$t('app.labels.yes'), className: 'btn-success' },
              cancel: { label: this.$t('app.labels.no'), className: 'btn-default' },
            },
            callback: (result: boolean) => {
              if (result) {
                this.deleteAttachment(attachment);
              }
            },
          });
        }
      }
    }

    beforeMount(): void {
      this.localValue = isEmpty(this.value) ? [] : this.value;

      this.fetchAttachments();
    }

    deleteAttachment(attachment: Attachment): void {
      this.loadingHash[attachment.id] = true;

      this.attachmentUploader.removeFieldValue(attachment.id);
      this.updateValue([...this.localValue.filter((id) => `${id}` !== `${attachment.id}`)]);

      this.oldAttachments = this.oldAttachments.filter((oldAttachment) => oldAttachment.id !== attachment.id);

      delete this.loadingHash[attachment.id];
    }

    onUploaderFilesChange(files: File[]): void {
      this.validateUnsavedFiles = !!files.length;
    }

    fetchAttachments(): void {
      if (this.localValue.length) {
        this.$api
          .getAttachments({
            include: ['user', 'web_thumbnail_url'],
            filters: {
              id: this.localValue,
              attachable_type: 'SubFormResponse',
            },
          })
          .then(({ data }) => {
            this.oldAttachments = data;
            data.forEach((x) => this.attachmentUploader?.addFieldValue(`${x.id}`));
          });
      }
    }
  }
