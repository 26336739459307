
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { Component, Emit, Model, Prop, Vue } from 'vue-property-decorator';
  import { FORM_FIELD_COMPONENTS } from '@app/components/form-fields/components';
  import type { SubFormData } from '@app/services/api/sub-form-completions-api';
  import type { Dictionary } from '@app/models/dictionary';
  import type { SubFormCompletion } from '@app/models/sub-form-completion';
  import type { SubFormQuestion, ResponseValue } from '@app/models/sub-form-question';
  import type { SubFormResponse } from '@app/models/sub-form-response';
  import type { ModuleRecord } from '@app/models/module-record';
  import type { FormObservers } from '@app/utils/types/form-observers';
  import type { SubFormDataAndId } from '@app/mixins/with-previous-completion-form';
  import { hiddenQuestion } from '@app/services/model-helpers';

  import type { SubFormCompletionFormRecord, QuestionSfcOnly } from '../utils';

  import QuestionBlockRelated from './question-block-related.vue';
  import htmlWithRequired from './html-with-required';

  @Component({
    components: { ...FORM_FIELD_COMPONENTS, QuestionBlockRelated },
    directives: { htmlWithRequired },
  })
  export default class QuestionBlock<V extends ResponseValue = ResponseValue> extends Vue {
    @Model('input') readonly value?: V;
    @Prop(Object) readonly blankValues?: Record<string, string>;
    @Prop(Boolean) readonly canAddAnything?: boolean;
    @Prop(Boolean) readonly canAddAttachment?: boolean;
    @Prop(Object) readonly completion!: SubFormCompletion;
    @Prop(Object) readonly dataByCode!: SubFormData;
    @Prop(Object) readonly dataById!: SubFormData;
    @Prop(Boolean) readonly defaultTemplating?: boolean;
    @Prop(Object) readonly defaultValues?: SubFormData;
    @Prop(String) readonly description?: string;
    @Prop(String) readonly fieldName!: string;
    @Prop(String) readonly formUuid!: string;
    @Prop(Boolean) readonly hideQuestionTitle?: boolean;
    @Prop(String) readonly horizontalDivider?: string;
    @Prop(Object) readonly initValues?: SubFormData;
    @Prop(Boolean) readonly isPublic?: boolean;
    @Prop(Boolean) readonly isRequired?: boolean;
    @Prop(Object) readonly lookupHistory!: Record<string, string>;
    @Prop(Boolean) readonly mainForm!: boolean;
    @Prop(Number) readonly mainFormId?: number;
    @Prop(Boolean) readonly modal?: boolean;
    @Prop(String) readonly name!: string;
    @Prop(Boolean) readonly newUnsavedResponse!: boolean;
    @Prop(Object) readonly observers?: FormObservers;
    @Prop(Object) readonly params!: Dictionary<string | (string | null)[]>;
    @Prop(Object) readonly previousCompletionForm?: SubFormDataAndId;
    @Prop(Object) readonly question!: Pick<SubFormQuestion, QuestionSfcOnly>;
    @Prop(Boolean) readonly readonly?: boolean;
    @Prop(Object) readonly record?: SubFormCompletionFormRecord;
    @Prop(String) readonly recordType?: string;
    @Prop(Object) readonly response?: SubFormResponse;
    @Prop(Object) readonly systemCodeToIdMap?: Record<string, string>;
    @Prop(String) readonly title?: string;
    @Prop(Boolean) readonly unsaved!: boolean;

    autoSelectRecord: Nullable<ModuleRecord> = null;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get isHidden(): boolean {
      return hiddenQuestion(this.question);
    }

    @Emit('update-loading-count')
    updateLoadingCount(type: 'start' | 'stop'): 'start' | 'stop' {
      return type;
    }

    onAutoSelect(record: ModuleRecord): void {
      this.autoSelectRecord = record;
    }
  }
