
  import BaseDsInput from '@app/mixins/base-ds-input';
  import { Component, Model, Prop } from 'vue-property-decorator';
  import { v4 as generateUUID } from 'uuid';

  @Component({ components: {} })
  export default class DsCheckbox extends BaseDsInput {
    @Model('input', {}) readonly value!: boolean | string;
    @Prop(Boolean) readonly stringBoolean?: boolean;
    @Prop(Boolean) readonly inlineLabel?: boolean;
    @Prop(Boolean) readonly required?: boolean;
    @Prop({ type: String, default: () => 'form-group' }) readonly rowClass!: string;
    @Prop({ type: String, default: 'true' }) readonly trueValue!: string;
    @Prop({ type: String, default: 'false' }) readonly falseValue!: string;

    uniqId: string = generateUUID();

    get wrapperClasses(): Record<string, boolean> {
      return { [this.rowClass]: !this.inlineLabel };
    }

    get checkboxLabelClasses(): Record<string, boolean> {
      return {
        ...this.mergedLabelClasses,
        pointer: !this.disabled,
      };
    }

    get inputId() {
      return this.id || this.uniqId;
    }

    get checked(): boolean {
      return this.stringBoolean ? '' + this.value === this.trueValue : !!this.value;
    }

    onChange($event: InputEvent & { target: HTMLInputElement }) {
      const checked = $event.target.checked;
      this.$emit('input', this.stringBoolean ? (checked ? this.trueValue : this.falseValue) : checked);
    }
  }
