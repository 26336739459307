
  import { Vue, Emit, Prop, Ref, Component } from 'vue-property-decorator';
  import SignaturePad from 'signature_pad';
  import type { SignatureData, SignatureResult } from '@app/models/signature';

  const SAVE_TYPE = ['image/png', 'image/jpeg', 'image/svg+xml'];

  const checkSaveType = (type: string) => SAVE_TYPE.includes(type);

  const DEFAULT_OPTIONS = {
    dotSize: (0.5 + 2.5) / 2,
    minWidth: 0.5,
    maxWidth: 2.5,
    throttle: 16,
    minDistance: 5,
    backgroundColor: 'rgba(0,0,0,0)',
    penColor: 'black',
    velocityFilterWeight: 0.7,
  };

  const TRANSPARENT_PNG: SignatureData = {
    src: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
    x: 0,
    y: 0,
  };

  @Component
  export default class SignatureCanvas extends Vue {
    @Prop({ type: String }) readonly canvasTitle!: string;
    @Prop({ type: String, default: '100%' }) readonly height!: string;
    @Prop({ type: String, default: 'image/png' }) readonly saveType!: string;
    @Prop({ type: String, default: '100%' }) readonly width!: string;
    @Ref() readonly signaturePadCanvas?: HTMLCanvasElement;

    signatureData: SignatureData | string | null = TRANSPARENT_PNG;
    signaturePad: Nullable<SignaturePad> = null;

    get style(): object {
      return {
        width: this.width,
        height: this.height,
      };
    }

    @Emit('result')
    onResult(): SignatureResult {
      return this.updateSignatureResult();
    }

    clearSignature(): void {
      return this.signaturePad?.clear();
    }

    isEmpty(): boolean {
      return !!this.signaturePad?.isEmpty();
    }

    resizeCanvas(): void {
      if (!this.signaturePadCanvas) return;

      const data = this.signaturePad?.toData();
      if (!data) return;

      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      const width = this.signaturePadCanvas.offsetWidth;
      const height = this.signaturePadCanvas.offsetHeight;
      if (!width || !height) return;

      this.signaturePadCanvas.width = width * ratio;
      this.signaturePadCanvas.height = height * ratio;
      this.signaturePadCanvas.getContext('2d')?.scale(ratio, ratio);

      this.clearSignature();
      this.signatureData = TRANSPARENT_PNG;
      this.signaturePad?.fromData(data);
    }

    updateSignatureResult(): SignatureResult {
      const { signaturePad, saveType } = this;
      const status: SignatureResult = { isEmpty: false, data: null };

      if (!checkSaveType(saveType)) {
        throw new Error('Image type is incorrect!');
      }

      if (signaturePad?.isEmpty()) {
        return {
          ...status,
          isEmpty: true,
        };
      } else {
        this.signatureData = signaturePad?.toDataURL(saveType) || null;

        return {
          ...status,
          data: this.signatureData,
        };
      }
    }

    mounted(): void {
      if (!this.signaturePadCanvas) return;

      this.signaturePad = new SignaturePad(this.signaturePadCanvas, { ...DEFAULT_OPTIONS });

      this.canvasTitle && (this.signaturePadCanvas.title = this.canvasTitle);
      this.signaturePadCanvas.tabIndex = 0;
      window.addEventListener('resize', this.resizeCanvas);
      this.signaturePad.addEventListener('endStroke', this.onResult);
      this.resizeCanvas();
    }

    beforeDestroy(): void {
      window.removeEventListener('resize', this.resizeCanvas);
      this.signaturePad?.removeEventListener('endStroke', this.onResult);
    }
  }
