
  import { Component } from 'vue-property-decorator';
  import type { FieldType } from '@app/models/sub-form-question';
  import type { Signature } from '@app/models/signature';
  import SignatureDisplayMethods from '@app/mixins/signature-display-methods';
  import { mixins } from 'vue-class-component';

  import BaseFieldDisplay from './base-field-display';

  @Component
  export default class SignatureDisplay extends mixins(BaseFieldDisplay<FieldType.signature>, SignatureDisplayMethods) {
    signature: Nullable<Signature> = null;

    get signatureId(): Maybe<number> {
      return Number(this.response?.value) || undefined;
    }

    beforeMount(): void {
      if (this.signatureId && !this.isPublic) {
        this.$api.getSignature(this.signatureId, { include: ['user'] }, { cache: true }).then(({ data }) => {
          this.signature = data;
        });
      }
    }
  }
