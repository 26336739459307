
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { CombinedRecord } from '@app/models/table-calculation';
  import type {
    TableCalculationQuestionOptions,
    ExpandableTableCalculationColumnConfig,
  } from '@app/models/question-options/table-calculation-question-options';

  @Component({})
  export default class ExpandableRow extends Vue {
    @Prop(Object) readonly record!: CombinedRecord;
    @Prop(Object) readonly options?: TableCalculationQuestionOptions['table_calculation']['expandable'];

    get expandableData(): CombinedRecord[] {
      return this.record.expandableData || [];
    }

    get visibleColumns(): ExpandableTableCalculationColumnConfig[] {
      return this.options?.columns?.filter((c) => !c.hidden) || [];
    }

    get title(): Maybe<string> {
      return this.options?.title;
    }
  }
