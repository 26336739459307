
  import { API_NULL } from '@app/constants';
  import type { AxiosPromise } from 'axios';
  import { Component, Emit } from 'vue-property-decorator';
  import type { AreaFieldValue, LocationFieldValue } from '@app/models/question-response-types';
  import type { Area } from '@app/models/area';
  import type { FieldType } from '@app/models/sub-form-question';
  import type { ApiRequestConfig, DonesafeFilterOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

  import EntitySelector from '../entity-selector.vue';

  import BaseField from './base-field';

  @Component({ components: { EntitySelector } })
  export default class AreaField extends BaseField<FieldType.area> {
    localValue: AreaFieldValue = { value: '' };

    get filters(): DonesafeFilterOptions<Area> {
      return { active: true };
    }

    @Emit('input')
    updateValue(value?: string): AreaFieldValue {
      this.localValue = { value };
      this.sendUpdate(this.localValue);
      return this.localValue;
    }

    fetchMethod(options: DonesafeIndexApiOptions<Area>, config?: ApiRequestConfig): AxiosPromise<Area[]> {
      const filters = options.filters || {};

      const locationId = this.locationId();
      if (locationId) {
        const locationIds = [API_NULL, locationId];
        return this.$api.getAreas({ ...options, filters: { ...filters, location_id: locationIds } }, config);
      } else {
        return Promise.resolve({
          data: [{ name: this.$t('app.labels.not_location_selected') }],
          headers: {},
        } as never);
      }
    }

    locationId(): string | undefined {
      return (
        this.question.config.sub_form_question_system_code &&
        (this.dataByCode[this.question.config.sub_form_question_system_code] as LocationFieldValue)?.value
      );
    }

    beforeMount(): void {
      const value = this.value?.value || '';
      this.localValue = { value };
    }
  }
