
  import moment from 'moment';
  import { Component, Emit } from 'vue-property-decorator';
  import type { ReportFieldValue } from '@app/models/question-response-types';
  import type { FieldType } from '@app/models/sub-form-question';
  import LocationSelector from '@app/components/location/location-selector.vue';
  import { mixins } from 'vue-class-component';
  import Blocking from '@app/mixins/blocking';
  import { convertInFormFilters } from '@app/utils/convert-in-form-filters';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import type { ModuleRecord } from '@app/models/module-record';
  import { filter } from 'rxjs/operators';
  import { hasQuestionInFilter } from '@app/utils/has-question-in-filter';
  import type { Subscription } from 'rxjs';

  import OrganizationSelector from '../organization/organization-selector.vue';
  import ReportTable from '../form-fields-display/report-table.vue';
  import RecordSelector from '../record-selector.vue';
  import DatePicker from '../date-picker.vue';

  import BaseField from './base-field';

  @Component({ components: { DatePicker, RecordSelector, ReportTable, LocationSelector, OrganizationSelector } })
  export default class ReportField extends mixins(Blocking, BaseField<FieldType.report>) {
    localValue: ReportFieldValue = {};
    filterChangesSubscription: Subscription | null = null;

    get filter(): DonesafeFilterOptions<ModuleRecord> {
      return {
        ...this.dynamicFilter,
        ...convertInFormFilters(this.question.config.filters, {
          record: this.record,
          user: this.currentUserStore.data,
          getCurrentFormValueByQuestion: this.getCurrentFormValueByQuestion,
        }),
      };
    }

    get dynamicFilter(): object {
      const { start_date: startDate, end_date: endDate } = this.localValue;

      return { startDate, endDate };
    }

    get dateFormat(): Maybe<string> {
      return this.accountStore.data.datetimepicker_date_format;
    }

    get showReport(): boolean {
      return this.canShowReport && !!this.localValue.start_date && !!this.localValue.end_date;
    }

    get canShowReport(): boolean {
      if (this.defaultTemplating) {
        return false;
      }

      // filter does not depend on record or we have a record
      return !!this.record?.id || (this.question.config.filters || []).some(({ source }) => source === 'current_record');
    }

    @Emit('input')
    onDateChange(date: Date | undefined, key: 'start_date' | 'end_date'): ReportFieldValue {
      const dateString = date && moment(date).format(this.dateFormat);
      this.localValue = { ...this.localValue, [key]: dateString };
      this.sendUpdate(this.localValue);
      return this.localValue;
    }

    dateValue(key: 'start_date' | 'end_date'): Date | undefined {
      return (this.localValue[key] && moment(this.localValue[key], this.dateFormat).toDate()) || undefined;
    }

    beforeMount(): void {
      this.localValue = { ...this.value };
    }

    mounted(): void {
      this.filterChangesSubscription = this.formObservers.fieldUpdate$
        .pipe(filter(([question]) => hasQuestionInFilter(question, this.question.config.filters)))
        .subscribe(() => this.clearIncompleteFilters());
    }

    beforeDestroy(): void {
      this.filterChangesSubscription?.unsubscribe();
    }
  }
