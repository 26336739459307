import type { RuleSet } from '@app/models/rule-set';

import type { ServiceRequest } from './base-service';
import { BaseService } from './base-service';

export class RuleSetService extends BaseService<RuleSet> {
  static readonly allowedAttributes = ['description', 'name', 'query_builder_blob'];
  static serviceTitle = ($t: (key: string) => string): string => $t('app.labels.rule_set');

  entityToText(itemId: ServiceRequest<RuleSet>): string {
    return `ID: ${itemId}`;
  }

  async fetchEntity({ itemId }: ServiceRequest<RuleSet>): Promise<Partial<RuleSet>> {
    return { id: itemId as number };
  }
}
