
  import { Component, Emit, Prop } from 'vue-property-decorator';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import WithDateFilter from '@app/mixins/with-date-filter.vue';
  import { Collapse } from 'uiv';
  import DatePicker from '@app/components/date-picker.vue';
  import YearPickerButton from '@app/components/year-picker-button.vue';
  import { useAccountStore } from '@app/stores/account';

  import DateTimePicker from '../date-time-picker.vue';

  @Component({ components: { DateTimePicker, DsDropdown, Collapse, DatePicker, YearPickerButton } })
  export default class GlobalDateFilter extends WithDateFilter {
    @Prop(Boolean) readonly dueDate?: boolean;
    @Prop(String) readonly format?: string;

    get accountStore() {
      return useAccountStore();
    }

    get options(): { [key: string]: string } {
      if (this.dueDate) {
        return {
          week: this.$t('app.labels.due_within_week'),
          today: this.$t('app.labels.due_today'),
          month: this.$t('app.labels.due_within_month'),
          year: this.$t('app.labels.due_within_year'),
          all: this.$t('app.labels.due_anytime'),
        };
      }

      return {
        week: this.$t('app.labels.created_this_week'),
        today: this.$t('app.labels.created_today'),
        month: this.$t('app.labels.created_within_a_month'),
        year: this.$t('app.labels.created_within_a_year'),
        all: this.$t('app.labels.created_anytime'),
      };
    }

    get label(): string {
      if (Array.isArray(this.value)) {
        if (this.isRangeAFullYear(this.startDate, this.endDate) && this.startDate?.getFullYear()) {
          return this.startDate?.getFullYear().toString();
        }
        const startLabel = this.formattedDate(this.value[0]) || 'Anytime';
        const endLabel = this.formattedDate(this.value[1]) || 'Anytime';
        return [startLabel, endLabel].join(' to ');
      }
      return this.options[this.value || 'all'];
    }
    @Emit('input')
    selectOption(option: string) {
      return option;
    }
    formattedDate(date: string | undefined) {
      if (!date) return;

      return this.accountStore.dateFormat(date, { dateOnly: true });
    }
  }
