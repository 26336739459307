
  import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component({ components: {} })
  export default class DsButton extends Vue {
    @Prop() readonly buttonClasses!: Record<string, boolean>;

    @Prop(Boolean) readonly loading?: boolean;
    @Prop(Boolean) readonly danger?: boolean;
    @Prop(Boolean) readonly primary?: boolean;
    @Prop(Boolean) readonly default?: boolean;
    @Prop(Boolean) readonly success?: boolean;

    loadingColor = '';

    getBgColor() {
      return window.getComputedStyle(this.$el).backgroundColor;
    }

    get classes() {
      return {
        'btn-danger': !!this.danger,
        'btn-primary': !!this.primary,
        'btn-default': !!this.default,
        'btn-success': !!this.success,
        ...(this.buttonClasses || {}),
      };
    }

    mounted() {
      this.$nextTick(() => (this.loadingColor = this.getBgColor()));
    }
  }
