
  import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
  import { v4 as generateUUID } from 'uuid';
  import type { BaseEntity } from '@app/models/base-entity';
  import SimpleGridTableHeader from '../simple-grid-table/simple-grid-table-header.vue';
  import SimpleGridTableFooter from '../simple-grid-table/simple-grid-table-footer.vue';
  import SimpleGridTableBody from '../simple-grid-table/simple-grid-table-body.vue';
  import type { BaseTableRowClickEvent } from '../base-table/utils';
  import WithSortingHandle from '../base-table/with-sorting-handle';
  import ResizeObserverMixin from './resize-observer-mixin';
  import type { ListManagerStatic } from '@app/services/list-manager/list-manager-static';
  import type { ListManager } from '@app/services/list-manager/list-manager';
  import type { ListManagerField } from '@app/services/list-manager/types';

  @Component({ components: { SimpleGridTableHeader, SimpleGridTableBody, SimpleGridTableFooter }, mixins: [WithSortingHandle] })
  export default class SimpleGridTable<T extends BaseEntity<number | string>> extends Mixins(ResizeObserverMixin) {
    @Prop(Object) manager!: ListManager<T> | ListManagerStatic<T>;
    @Prop(Boolean) readonly sticky?: boolean;
    @Prop(Boolean) readonly striped?: boolean;
    @Prop(Boolean) readonly renderAsCell?: boolean;
    @Prop(Boolean) readonly baseTable!: boolean;
    @Prop(Boolean) readonly hover?: boolean;
    @Prop(String) readonly tableHeight?: string;
    @Prop(String) noDataTemplate?: string;
    @Prop([Function, String]) rowClass?: string | ((item: T, index: number) => string);
    @Prop(Boolean) sortable?: boolean;
    @Prop(String) sortableHandle?: string;

    uuid = generateUUID();

    get headerBindings() {
      return {
        ...(this.syncScroll && { 'grid-wrapper-css': this.gridWrapperCss, scrollName: this.scrollName }),
        manager: this.manager,
      };
    }

    get bodyBindings() {
      return {
        ...(this.syncScroll ? { 'grid-wrapper-css': this.gridWrapperCss, scrollName: this.scrollName } : {}),
        class: this.tableBodyClasses,
        manager: this.manager,
        noDataTemplate: this.noDataTemplate,
        rowClass: this.rowClass,
      };
    }

    get showHeader() {
      return !this.renderAsCell;
    }

    get showFooter() {
      return this.baseTable && !('localData' in this.manager);
    }

    get tableBodyClasses() {
      return {
        'hide-tbody-overflow': this.renderAsCell,
        syncscroll: this.syncScroll,
      };
    }

    get scrollName() {
      return `syncScroll-${this.uuid}`;
    }

    get items() {
      return this.manager?.items || [];
    }

    get gridWrapperCss() {
      return {
        display: 'grid',
        'grid-template-columns': this.gridTemplateColumns,
        'align-items': 'start',
      };
    }

    get gridTableClass() {
      return {
        'header-sticky': this.sticky,
        'disable-outer-border': this.renderAsCell,
        'take-full-height': this.renderAsCell,
        transparent: this.renderAsCell,
        striped: this.striped,
        'base-table': this.baseTable,
        'table-hover': this.hover,
        'without-sync-scroll': !this.syncScroll,
      };
    }

    get gridTableCss() {
      return {
        ...(this.tableHeight && { 'max-height': this.tableHeight }),
        ...(!this.syncScroll && { ...this.gridWrapperCss }),
      };
    }

    get visibleFieldsWithoutWidth() {
      return this.manager?.visibleFields?.filter((field) => !field?.width) || [];
    }

    get gridTemplateColumns() {
      const visibleFields = this.manager?.visibleFields || [];
      const calculatedWidths = visibleFields.map((field, i) => field?.width || this.getBaseWidth(field, i));
      return calculatedWidths.join(' ');
    }

    get syncScroll() {
      return this.sticky || this.sortable;
    }

    @Watch('manager.items', { immediate: true })
    onItemsChanged() {
      this.$nextTick(() => {
        // we need to update the table columns width when syncScroll is enabled and the items loaded / changed
        if (this.syncScroll && this.resizeObserver) this.syncResizeObserver();
        this.initSyncScroll();
      });
    }

    getBaseWidth(field: ListManagerField<T>, index: number) {
      const defaultMinWidth = '100px';

      if (!this.syncScroll) return 'auto';

      const calcMinWidth = this.maxColumnWidths?.[index] ? `${this.maxColumnWidths[index]}px` : defaultMinWidth;

      return `minmax(${calcMinWidth}, auto)`;
    }

    setData(data: T[]) {
      this.manager.setData(data);
    }

    onRowClick(data: BaseTableRowClickEvent<T>) {
      this.$emit('row-clicked', data);
    }

    reload() {
      this.manager.refresh();
    }

    created() {
      this.manager.fetchData(this.manager.sortOrder || [], { current_page: this.manager.page, per_page: this.manager.per_page || 25 });
    }

    async mounted() {
      this.initSyncScroll();
    }

    async initSyncScroll(): Promise<void> {
      if (!this.syncScroll) {
        return;
      }

      // window.removeEventListener('resize', this.debouncedHandleWindowResize)
      await this.$nextTick();
      this.calcMaxColumnWidths();
      this.initResizeObserver();
      window.addEventListener('resize', this.debouncedHandleWindowResize);
      const syncscroll = await import('sync-scroll');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      syncscroll.reset();
    }

    beforeDestroy() {
      if (this.resizeObserver) this.resizeObserver.disconnect();
      if (this.syncScroll) window.removeEventListener('resize', this.debouncedHandleWindowResize);
    }
  }
