
  import type { MultiMainFormRelationFieldValue } from '@app/models/question-response-types';
  import type { DonesafeModuleRecordExtraFilters } from '@app/services/api/module-records-api';
  import { isEmpty, omit, isEqual } from 'lodash';
  import { Component, Ref, Watch } from 'vue-property-decorator';
  import type { FieldType } from '@app/models/sub-form-question';
  import type { ModuleRecord } from '@app/models/module-record';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { hasIncompleteNotMandatoryFilters } from '@app/utils/mandatory-filters';

  import FilterErrors from '../admin/questions/filter-configurator/filter-errors.vue';
  import RecordSelector from '../record-selector.vue';
  import WithRecordAutoSelect from '../../mixins/with-record-auto-select.vue';
  import AutoSelectWarnings from '../record-auto-select/auto-select-warnings.vue';
  import AutoSelectToggle from '../record-auto-select/auto-select-toggle.vue';

  @Component({ components: { RecordSelector, FilterErrors, AutoSelectWarnings, AutoSelectToggle } })
  export default class MultiMainFormRelationField extends WithRecordAutoSelect<FieldType.multi_main_form_relation> {
    @Ref() readonly autoSelectToggle!: AutoSelectToggle;

    localValue: MultiMainFormRelationFieldValue = [];

    get requiredFilters(): DonesafeFilterOptions<ModuleRecord, DonesafeModuleRecordExtraFilters> {
      return { module_name_id: this.question.config.main_form_id };
    }

    @Watch('autoSelectRecord')
    onAutoSelectRecordChanged(record: Nullable<ModuleRecord>): void {
      if (!!record) {
        const newValue = [...(this.value || []), String(record.id)];
        this.updateValue(newValue);
        this.recordSelector?.addOption(String(record.id), record.title, true);
      }
    }

    onOpen(): boolean {
      !this.defaultTemplating && this.checkIncompleteFiltersWarning(this.getFilters());
      return !this.incompleteFilters.length;
    }

    initSubscription(): void {
      if (!this.hasAutoSelect) return;

      this.initAutoSelectSubscriptions();
    }

    updateValue(value: MultiMainFormRelationFieldValue): void {
      if (isEqual(this.localValue, value)) {
        return;
      }

      this.localValue = value;
      this.sendUpdate(this.localValue);
      this.$emit('input', this.localValue);
    }

    beforeMount(): void {
      if (Array.isArray(this.value)) {
        this.localValue = this.value;
      } else if (typeof this.value === 'object') {
        const response = this.value as { value: number | string[] | number[] };
        const arrayValue = (Array.isArray(response.value) ? response.value : (response.value && [response.value]) || []).map((s) => `${s}`);
        this.updateValue(arrayValue);
      } else {
        this.localValue = this.value ? [this.value] : [];
      }
    }

    mounted(): void {
      this.initSubscription();
      this.hasAutoSelect && this.onAutoSelectInit();
      this.sendUpdate(this.localValue, true);
    }

    reFetchData(): void {
      const filters = this.getFilters();
      this.checkIncompleteFiltersWarning(filters, false);
      if (this.incompleteFilters.length || isEmpty(filters)) {
        this.warnings = omit(this.warnings, 'not_found');
        this.updateValue([]);
        return;
      }
      this.autoSelectInProgress = true;
      this.updateLoadingState(true);
      this.$api
        .getModuleRecords(
          {
            only: ['id', 'title'],
            per_page: -1,
            sort: this.question.config.sort,
            filters: {
              ...this.requiredFilters,
              ...filters,
            },
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.recordSelector?.setOptions(data);
          if (data.length) {
            this.updateValue([...data.map((r) => `${r.id}`)]);
            this.warnings = omit(this.warnings, 'not_found');
          } else {
            this.updateValue([]);
            this.warnings = { ...this.warnings, not_found: this.$t('app.labels.no_records_found') };
          }
        })
        .finally(() => {
          this.autoSelectInProgress = false;
          this.updateLoadingState(false);
        });
    }

    checkIncompleteFiltersWarning(filters: DonesafeFilterOptions<ModuleRecord>, validate = true): void {
      this.setAndValidateFilters(filters, validate);
      const hasNoIncompleteFilters =
        !this.incompleteFilters.length && hasIncompleteNotMandatoryFilters(this.question.config.filters, filters);

      if (this.autoRecordSelectEnabled && hasNoIncompleteFilters) {
        this.warnings = { ...this.warnings, filter_missed: this.$t('app.labels.auto_select_missing_filter') };
      } else {
        this.warnings = omit(this.warnings, 'filter_missed');
      }
    }
  }
