import type { GeocoderResult } from '../geocoder-result';

export enum MapTypeId {
  hybrid = 'hybrid',
  roadmap = 'roadmap',
  satellite = 'satellite',
  terrain = 'terrain',
}

export interface AddressFieldValue {
  data_blob?: GeocoderResult;
  latitude?: number;
  longitude?: number;
  map_information_id?: number | string;
  skip_geocoding?: boolean;
  value?: string;
  zoom?: number;
}

export const LOCAL_STORE_MAP_TYPE = 'address-field-map-type';
