
  import { Component } from 'vue-property-decorator';
  import BaseFieldDisplay from './base-field-display';
  import TableCalculationModal from '@app/components/table-calculation/table-calculation-modal.vue';
  import { isEmpty } from 'lodash';
  import type { FieldType } from '@app/models/sub-form-question';

  @Component({ components: { TableCalculationModal } })
  export default class TableCalculationDisplay extends BaseFieldDisplay<FieldType.table_calculation> {
    modalOpen = false;

    get button_label(): string {
      return this.question.config.table_calculation.labels?.view_calculation_data_label || 'View Calculation Data (readonly)';
    }

    get hasResponse(): boolean {
      return !isEmpty(this.subFormResponse.response);
    }

    get variableData(): Record<string, string> {
      return this.response.variable_data || {};
    }

    openModal() {
      this.modalOpen = true;
    }
  }
