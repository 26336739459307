
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { IntervalType } from '@app/models/schedule';
  import type { TenantUser } from '@app/models/tenant-user';
  import type { Shift } from '@app/models/shift';
  import { DatetimeToString } from '@app/services/datetime_to_string';
  import { useAccountStore } from '@app/stores/account';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import moment from 'moment-timezone';
  import UserImage from '@app/components/user-image.vue';

  export interface ShiftData {
    date: Date;
    shifts: Shift[];
  }

  @Component({
    components: { UserImage },
  })
  export default class UserShiftsList extends Vue {
    @Prop(Object) readonly user!: TenantUser;
    @Prop(Date) readonly startDate!: Date;
    @Prop(Date) readonly endDate!: Date;

    shiftsByDate: ShiftData[] = [];

    get shiftName(): string {
      return this.$t('tenant.timesheet_shifts.show.name_timesheet', { name: this.user.full_name });
    }

    get allowedShitDates(): Date[] {
      return this.allowedDatesBetween(this.startDate, this.endDate) || [];
    }

    get newTimesheetShiftsPath(): string {
      return `/timesheet_shifts/new?user_id=${this.user.id}`;
    }

    get accountStore() {
      return useAccountStore();
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    groupShiftsByDate(dates: Date[], shifts: Shift[]): ShiftData[] {
      const schedule = this.user.schedule;
      if (!schedule) return [];

      return dates.reduce((acc: ShiftData[], date: Date) => {
        const shiftsForDate = shifts.filter((shift) => {
          const endDate = this.scheduleEndDateFrom(date);
          if (!endDate) return false;

          const startAt = new Date(shift.start_at);
          const endAt = new Date(shift.end_at);
          return this.onlyDate(startAt) >= this.onlyDate(date) && this.onlyDate(endDate) > this.onlyDate(endAt);
        });

        if (shiftsForDate.length === 0) {
          return acc;
        } else {
          return [...acc, { date: date, shifts: shiftsForDate }];
        }
      }, []);
    }

    monthShort(date: Date): string {
      return moment.monthsShort()[date.getMonth()];
    }

    onlyDate(date: Date): Date {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }

    editTimesheetShiftsPath(start_date: Date) {
      const momentDate: moment.Moment = moment(start_date);
      return `/timesheet_shifts/edit?user_id=${this.user.id}&start_date=${momentDate.format(
        this.accountStore.data.datetimepicker_date_format
      )}`;
    }

    totalHours(shifts: Shift[]): number {
      return shifts.reduce((acc, shift) => (!!shift.hours_worked ? acc + Number(shift.hours_worked) : acc), 0);
    }

    datetimeToString(datetime: string): string | undefined {
      return new DatetimeToString({
        currentAccount: this.accountStore.data,
        datetime: datetime,
        timezone: this.currentUserStore.data?.timezone,
        reportFormat: 'default',
        datetimeFormat: this.$t('app.labels.standard_datetime_format', {
          date: this.accountStore.data.datetimepicker_date_format,
          time: this.accountStore.data.datetimepicker_time_format,
        }),
      }).getResult();
    }

    beforeMount(): void {
      this.fetchShifts();
    }

    fetchShifts() {
      const lastDate = this.scheduleEndDateFrom(this.allowedShitDates[this.allowedShitDates.length - 1]);
      if (lastDate) {
        const startAt = [this.allowedShitDates[0], ''];
        const endAt = ['', lastDate];

        this.$api
          .getShifts({
            filters: {
              shift_type: 'actual',
              user_id: this.user.id,
              start_at: startAt,
              end_at: endAt,
            },
            include: 'hours_worked',
          })
          .then((response) => {
            this.shiftsByDate = this.groupShiftsByDate(this.allowedShitDates, response.data);
          });
      }
    }

    diffDays(date1: Date, date2: Date): number {
      const diff = Math.abs(date1.getTime() - date2.getTime());
      return Math.ceil(diff / (1000 * 60 * 60 * 24));
    }

    activeDate(date: Date): boolean {
      const schedule = this.user.schedule;
      if (!schedule) return false;

      const newStartsOn = schedule.starts_on ? new Date(schedule.starts_on) : new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);

      if (date < newStartsOn) return false;

      switch (schedule.interval) {
        case IntervalType.Once:
          return date.getTime() === newStartsOn.getTime();
        case IntervalType.Daily:
          return this.diffDays(date, newStartsOn) % schedule.interval_split === 0;
        case IntervalType.Weekly:
          return this.diffDays(date, newStartsOn) % (schedule.interval_split * 7) === 0;
        case IntervalType.Monthly:
          const monthDifference = date.getFullYear() * 12 + date.getMonth() - (newStartsOn.getFullYear() * 12 + newStartsOn.getMonth());
          return monthDifference % schedule.interval_split === 0 && date.getDate() === newStartsOn.getDate();
        case IntervalType.Yearly:
          return (date.getFullYear() - newStartsOn.getFullYear()) % schedule.interval_split === 0 && date.getDay() === newStartsOn.getDay();
        default:
          return false;
      }
    }

    allowedDatesBetween(startDate: Date, endDate: Date): Date[] | undefined {
      const schedule = this.user.schedule;
      if (!schedule) return;

      let start = startDate;
      if (schedule.starts_on && new Date(schedule.starts_on) > startDate) start = new Date(schedule.starts_on);

      const result: Date[] = [];
      const current = new Date(start);

      while (current <= endDate) {
        if (this.activeDate(current)) {
          result.push(new Date(current));
        }
        current.setDate(current.getDate() + 1);
      }
      return result;
    }

    scheduleEndDateFrom(startDate: Date): Date | undefined {
      const schedule = this.user.schedule;
      if (!schedule) return;

      const newDate = new Date(startDate);

      switch (schedule.interval) {
        case IntervalType.Daily:
          newDate.setDate(startDate.getDate() + schedule.interval_split);
          break;
        case IntervalType.Weekly:
          newDate.setDate(startDate.getDate() + schedule.interval_split * 7);
          break;
        case IntervalType.Monthly:
          newDate.setMonth(startDate.getMonth() + schedule.interval_split);
          break;
        case IntervalType.Yearly:
          newDate.setFullYear(startDate.getFullYear() + schedule.interval_split);
          break;
      }

      return newDate;
    }
  }
