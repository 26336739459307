
  import { Component, Prop } from 'vue-property-decorator';
  import type { Attachment } from '@app/models/attachment';
  import type { FieldType } from '@app/models/sub-form-question';
  import { AvScanStatus } from '@app/models/attachment';

  import ThumbnailWrapper from '../attachment/thumbnail-wrapper.vue';

  import BaseFieldDisplay from './base-field-display';

  @Component({ components: { ThumbnailWrapper } })
  export default class FileUploadDisplay extends BaseFieldDisplay<FieldType.file_upload> {
    @Prop(String) readonly action?: string;

    attachments: Attachment[] = [];

    get loadingAttr(): string {
      return this.action === 'print' ? 'eager' : 'lazy';
    }

    isInfected(attachment: Attachment): boolean {
      return attachment.metadata.av_scan_status === AvScanStatus.virus_detected;
    }

    showImage(attachment: Attachment): boolean {
      return this.question.config.display_full === 'true' && attachment.is_image;
    }

    beforeMount(): void {
      !this.isPublic &&
        this.response.length &&
        this.$api
          .getAttachmentsWithSharedFilter(
            {
              only: [
                'id',
                'file_name',
                'url',
                'extension',
                'is_image',
                'preview_url',
                'web_thumbnail_url',
                'metadata',
                'is_variable',
                'is_previewable',
              ],
              preview_options: {
                resize_to_limit: [null, 400],
              },
            },
            'id',
            this.response,
            { cache: true }
          )
          .then(({ data }) => {
            this.attachments = [...this.attachments, ...data];
          });
    }
  }
