
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { Component, Emit, Prop, Ref, Vue } from 'vue-property-decorator';
  import { Tooltip } from 'uiv';
  import UserAvatarFormModal from './user/user-avatar-form-modal.vue';
  import type { Blob as ActiveStorageBlob } from '@rails/activestorage';
  import FileUploader from './file-uploader/file-uploader.vue';
  import type { UserAvatarOnly } from './user/utils';
  import { USER_AVATAR_ONLY } from './user/utils';
  import type { TenantUser } from '@app/models/tenant-user';
  import { AdminPermissionFeature } from '@app/models/admin-permission';
  import { userInitials } from '@app/utils/user-initials';

  @Component({ components: { Tooltip, UserAvatarFormModal, FileUploader } })
  export default class UserAvatar extends Vue {
    @Ref() readonly modal!: UserAvatarFormModal;

    @Prop(Number) readonly userId?: number;
    @Prop({ type: String, default: () => 'md' }) readonly size!: 'md' | 'lg';

    form: Partial<Pick<TenantUser, UserAvatarOnly> & { avatar?: string }> = {
      avatar_url: '',
    };
    modalVisible = false;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get canEditAvatar(): boolean {
      return (
        !this.userId ||
        this.userId === this.currentUserStore.data?.id ||
        this.currentUserStore.featureEnabled(AdminPermissionFeature.users_locations_organizations)
      );
    }

    get initials(): string {
      return userInitials(this.form);
    }

    get profileNameClass(): Record<string, boolean> {
      return {
        'profile-name--lg': this.size === 'lg',
      };
    }

    @Emit('avatar-upload')
    updateAvatar(avatar?: Nullable<string>) {
      return avatar;
    }

    submitForm(user: Partial<Pick<TenantUser, UserAvatarOnly> & { avatar?: string }>): void {
      if (!!this.userId) {
        this.$api.updateTenantUser(this.userId, { avatar: user.avatar }).then(() => {
          this.reset(user);
          // unless it's add new user page
          // need full-page refresh to update the avatar properly in all places
          window.location.reload();
        });
      } else {
        this.reset(user);
      }
    }

    reset(user: Partial<Pick<TenantUser, UserAvatarOnly> & { avatar?: string }>): void {
      this.form = {
        ...this.form,
        ...user,
      };
      this.toggleModal(false);
      this.updateAvatar(user.avatar);
    }

    onAvatarUpload(file: ActiveStorageBlob & { blob: Blob }): void {
      this.form.avatar = file.signed_id;
      const urlCreator = window.URL || window.webkitURL;
      this.form.avatar_url = urlCreator.createObjectURL(file.blob);
      this.updateAvatar(file.signed_id);
    }

    toggleModal(value: boolean): void {
      this.modalVisible = value;
    }

    beforeMount(): void {
      this.userId &&
        this.$api.getTenantUser(this.userId, { only: USER_AVATAR_ONLY }, { cache: true }).then(({ data }) => {
          this.form = {
            ...data,
          };
        });
    }
  }
