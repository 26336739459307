import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';
import type { Dashboard } from '@app/models/dashboard';

export class DashboardService extends BaseService<Dashboard> {
  async fetchDetails({ itemId, version }: ServiceRequest<Dashboard>): Promise<DetailsServiceResponse> {
    const { data: dashboard } = await this.api.getDashboard(itemId, { only: ['id', 'name'] }, { join: true, cache: true });
    const links =
      version.item_type === 'Dashboard'
        ? []
        : [
            {
              link: `/admin/settings/dashboards/${dashboard.id}/edit`,
              prefix: this.t('app.labels.dashboard'),
              title: dashboard.name,
              id: dashboard.id,
            },
          ];
    return { links };
  }

  entityToText({}: ServiceRequest, entity?: Dashboard): string {
    return entity ? `${decodeURI(entity.name)} [${entity.id}]` : '';
  }

  fetchEntity({ itemId }: ServiceRequest<Dashboard>): Promise<Partial<Dashboard>> {
    return this.api.getDashboard(itemId, { only: ['id', 'name'] }, { join: true, cache: true }).then(({ data }) => data);
  }
}
