import type { Follow } from '@app/models/follow';
import type { DetailsLink } from '../models';
import type { DetailsServiceResponse, ServiceRequest } from './base-service';
import { BaseService } from './base-service';

export class FollowService extends BaseService<Follow> {
  async fetchDetails(obj: ServiceRequest<Follow>): Promise<DetailsServiceResponse> {
    const link: DetailsLink = {
      title: obj.version.event == 'create' ? 'Followed' : 'Unfollowed',
    };

    return { links: [link] };
  }
}
