import type { QuestionSfcOnly } from '@app/components/sub-form-completion/utils';
import { SECTION_SHOWN_EVENT_NAME } from '@app/legacy/subform';
import { Component, Vue } from 'vue-property-decorator';
import type { FieldType, QuestionTypeMap, SubFormQuestion } from '@app/models/sub-form-question';

@Component
export default class WithSignatureEventListeners extends Vue {
  signature?: { resize(): void };
  question!: Pick<SubFormQuestion<QuestionTypeMap<FieldType.signature | FieldType.markable>['options']>, QuestionSfcOnly>;

  onResized(): void {
    this.signature?.resize();
  }

  mounted(): void {
    document.addEventListener(SECTION_SHOWN_EVENT_NAME(this.question.sub_form_section_id), this.onResized);
  }

  beforeDestroy(): void {
    document.removeEventListener(SECTION_SHOWN_EVENT_NAME(this.question.sub_form_section_id), this.onResized);
  }
}
