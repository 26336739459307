import type { BaseEntity } from '@app/models/base-entity';
import type { Dictionary } from '@app/models/dictionary';

export const DEFAULT_DISPLAY_SETTINGS = JSON.stringify({
  format: 'none',
  decimals: '',
  currency: '',
});

export enum LogicElementTypes {
  OPERATOR = 'operator',
  COMPARISON = 'comparison',
  IFELSE = 'ifelse',
  NEW_COMPONENT = 'newComponent',
  COMPONENT = 'component',
  PARENT_RULE = 'parentRule',
  NEW_RULE = 'newRule',
  RULE = 'rule',
  NEW_VARIABLE = 'newVariable',
  VARIABLE = 'variable',
  NUMBER = 'number',
  TEXT = 'text',
  BRACKET = 'bracket',
  INVALID = 'invalid',
}

export type ElementType = Exclude<
  LogicElementTypes,
  LogicElementTypes.NEW_VARIABLE | LogicElementTypes.NEW_COMPONENT | LogicElementTypes.NEW_RULE | LogicElementTypes.PARENT_RULE
>;

export type Formula = FormulaElement[];
export type FormulaElement = string | Formula;

export type RuleBuilderModes =
  | 'selectLogicSet'
  | 'variableMapping'
  | 'displaySettings'
  | 'displayItems'
  | 'logicSetEditor'
  | 'formsUsingLogicSet';

export type ComponentRuleOrVariable = LogicElementTypes.COMPONENT | LogicElementTypes.RULE | LogicElementTypes.VARIABLE;

export interface LogicSetDefinitions {
  parentRule: ParentRule;
  rules: Rules;
  variables: Variables;
  components: Components;
  configs: Config;
}

export interface CurrentTab {
  type:
    | ComponentRuleOrVariable
    | LogicElementTypes.PARENT_RULE
    | LogicElementTypes.NEW_RULE
    | LogicElementTypes.NEW_COMPONENT
    | LogicElementTypes.NEW_VARIABLE;
  value: string;
}

export interface Config {
  react_recalculate_value_on_variable_change?: boolean;
}

export interface VariableQuestion {
  id: number;
  fieldType: string;
  fieldOption: string;
}

export type VariableMap = Dictionary<string>;

export interface BasicObject {
  color: string;
  index: number;
  name: string;
  formula?: Formula;
}

export interface TemplateItemObject extends BasicObject {
  canDrag: boolean;
}

export interface ParentRule extends Omit<BasicObject, 'index'> {
  formula: Formula;
}

export type Rules = Dictionary<BasicObject>;
export type Variables = Dictionary<BasicObject>;
export type Components = Dictionary<BasicObject>;

export interface DisplaySettingsObject {
  currency?: string;
  decimals?: string;
  format?: 'none' | 'number' | 'percentage' | 'currency';
}

export type ValidPrefixes = '#' | '~' | '@';

export type DisplayItemValue = string | 'parent_rule';

export interface Validation {
  name: string[];
  formula: string[];
}

export interface FormulaErrors {
  parentRule: string[];
  rules: Dictionary<string[]>;
  components: Dictionary<string[]>;
}

export interface LogicSet extends BaseEntity {
  active: boolean;
  definitions: LogicSetDefinitions;
}

export interface DragAndDropFormulaObject {
  startingId: number;
  parentRuleTemplateItem: ParentRule;
  ruleTemplateItems: Dictionary<TemplateItemObject>;
  componentTemplateItems: Dictionary<TemplateItemObject>;
  variableTemplateItems: Dictionary<TemplateItemObject>;
  logicElements: { id: number; value: Formula }[];
}
export interface TemplateItemProps {
  key: number;
  index: number | string;
  type: LogicElementTypes | 'nullIcon';
  value: string;
  canDrag: boolean;
  highlighted?: boolean;
  updateDragging?: (state: boolean) => void;
  onClick?: () => void;
  deleteLogic?: () => void;
  displayItem?: boolean;
  toggleDisplayItem?: () => void;
  dragging?: boolean;
  shiftElementOrder?: (elementId: string, rightOrLeft: 'right' | 'left') => void;
  errors?: string[];
  color?: string;
  templateItems?: Dictionary<TemplateItemObject>;
}
